import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, MenuItem, TextField, Tooltip, Typography } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { DocumentAttachmentResponseDto } from "../../models/IUser";
import { dateToYMD, timeDiference, toTimestamp } from "../../helpers/dateHelper";
import { setLastError } from "../../store/reducers/cnfSlice";
import { useDispatch } from "react-redux";
import { ObjectToValueArray } from "../../helpers/generalHelper";
import React from "react";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import FilterIcon from '@mui/icons-material/Filter';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import SecureDialogComponent from "../CustomComponentModule/SecureDialogComponent";
import { EButtonColor } from "../../models/EnumGeneral";
import FileViewComponent from "../CustomComponentModule/FileViewComponent";
import FileDownloadComponent from "../CustomComponentModule/FileDownloadComponent";
import { EDocumentCustomerType, ICustomerDetails, ICustomerDocumentCreateDTO } from "../../models/CustomerModels";
import CustomerService from "../../services/CustomerService";

interface IProps {
    customer: ICustomerDetails | null;
    refreshAfretSave: () => void;
    snackMsg: (text: string) => void;
    handleStep: (step: number) => void;
}

const newDocument = (): ICustomerDocumentCreateDTO => {
    return {
        uuid: '#' + Math.random() * 1000 + Math.random() * 1000,
        type: '',
        number: '',
        expirationDate: 0,
        caption: ''
    }
}

const DocumentsCustomerComponent = (props: IProps) => {

    const dispatch = useDispatch();
    const { customer } = props;
    const [stateDocument, setStateDocument] = useState<ICustomerDocumentCreateDTO | null>()
    const [documents, setDocuments] = useState<ICustomerDocumentCreateDTO[] | null>()

    const [open, setOpen] = React.useState(false);

    const [localError, setLocalError] = useState(true);
    const [error, setError] = useState(0);
    const [, setErrors] = useState([false, false]);

    const [save, setSave] = useState<boolean>(false);
    const [deleteDocument, setDeleteDocument] = useState<boolean>(false);

    const [expanded, setExpanded] = React.useState<string | false>(false);
    const [expirationDate, setExpirationDate] = useState('');
    const [refreshLocal, setRefreshLocal] = useState<boolean>(true);

    const hendlerRefreshLocal = () => {
        setRefreshLocal(st => st = !st)
    }

    const handleChange = (panel: string) => {
        setExpanded(panel);
    };

    const handlerEdit = (item: ICustomerDocumentCreateDTO) => {
        setStateDocument({
            type: item.type,
            number: item.number,
            expirationDate: item.expirationDate,
            caption: item.caption,
            uuid: item.uuid
        })
        setOpen(true)
        setExpirationDate(dateToYMD(item.expirationDate))
    }

    useEffect(() => {
        if (customer) {
            (async () => {
                const [error, res] = await CustomerService.getDocuments(customer.uuid);
                if (res) {
                    let arr: ICustomerDocumentCreateDTO[] = res.data.body as ICustomerDocumentCreateDTO[];
                    setDocuments(arr);
                }
                if (error) {
                    dispatch(setLastError(error));
                }
            })()
        }
    }, [customer, refreshLocal]);

    useEffect(() => {
        if (save) {
            (async () => {
                if (customer && stateDocument) {

                    let isNewDocument = false;
                    if (stateDocument.uuid[0] === '#') { isNewDocument = true; }

                    let bodyC = {
                        customerId: customer.uuid,
                        type: stateDocument.type,
                        expirationDate: stateDocument.expirationDate,
                        number: stateDocument.number,
                        caption: stateDocument.caption
                    }

                    let bodyU = {
                        uuid: stateDocument.uuid ? stateDocument.uuid : '',
                        type: stateDocument.type,
                        expirationDate: stateDocument.expirationDate,
                        number: stateDocument.number,
                        caption: stateDocument.caption
                    }

                    const [error, data] = (isNewDocument)
                        ? await CustomerService.createDocument(bodyC)
                        : await CustomerService.updateDocument(bodyU);

                    if (data) {
                        hendlerRefreshLocal();
                        props.snackMsg('Document was successfully saved for ' + customer.name + '.')
                    }
                    if (error) {
                        dispatch(setLastError(error));
                    }
                    setSave(false);
                }
            })()
            setSave(false);
        }
    }, [save])

    useEffect(() => {
        if (deleteDocument) {
            (async () => {
                if (customer && stateDocument) {
                    const [error, data] = await CustomerService.deleteDocument(stateDocument?.uuid);
                    if (data) {
                        hendlerRefreshLocal()
                        props.snackMsg('Document was successfully removed for ' + customer.name + '.')
                    }
                    if (error) {
                        dispatch(setLastError(error));
                    }
                }
            })()
            setDeleteDocument(false);
            setOpen(false);
        }
    }, [deleteDocument])

    useEffect(() => {
        if (stateDocument) {
            let er = false;
            if (stateDocument.type.trim() === '') er = true;
            setLocalError(er);
        }
    }, [stateDocument])

    const handleClickOpen = () => {
        let n = newDocument();
        setExpirationDate('');
        setOpen(true);
        setStateDocument(n);
        setLocalError(true);
        setErrors([false, false])
    };

    const handleClose = () => { setOpen(false); };

    const handleChangeType = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        setStateDocument((st) => { if (st) return { ...st, type: value } });
    };

    const handlerExpirationDate = (event: any) => {
        let value: string = event.target.value as string;
        setExpirationDate(value);
        setStateDocument((st) => { if (st) return { ...st, expirationDate: toTimestamp(value) } });
    }

    const handlerEditNumber = (event: any) => {
        let value: string = event.target.value as string;
        setStateDocument((st) => { if (st) return { ...st, number: value } });
    }

    const handlerEditCaption = (event: any) => {
        let value: string = event.target.value as string;
        setStateDocument((st) => { if (st) return { ...st, caption: value } });
    }

    const handlerSaveDocument = () => { setSave(true); setOpen(false); };

    const visibleDeleteButton = useCallback((): boolean => {
        return stateDocument?.uuid[0] !== '#';
    }, [stateDocument]);

    return (
        <>
            <div style={{ display: "flex", width: "620px", justifyContent: "space-between" }}>
                <div style={{ display: "flex", flexDirection: "column", gap: "10px", maxWidth: "545px" }}>
                    <div>
                        <Button
                            onClick={handleClickOpen}
                            color={error === -1 ? 'error' : 'info'} variant='contained' size={'small'} startIcon={<AddCircleOutlineIcon fontSize={'small'} />}>
                            document
                        </Button>
                    </div>
                    <div style={{ marginTop: "5px", marginBottom: "5px" }}> <Divider /> </div>
                    <div>
                        {documents &&
                            <div style={{ width: "550px" }}>
                                {
                                    documents.map((d: ICustomerDocumentCreateDTO, i) =>
                                        <DocumentComponent
                                            key={i}
                                            doc={d}
                                            expanded={expanded === d.uuid}
                                            handleChange={handleChange}
                                            handlerEdit={handlerEdit}
                                            refresh={hendlerRefreshLocal}
                                            snackMsg={props.snackMsg}
                                        />
                                    )}
                            </div>
                        }
                    </div>
                </div>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>{((stateDocument?.uuid[0] === '#') ? ` ADD` : "EDIT") + " DOCUMENT"}</DialogTitle>
                <DialogContent>
                    <div style={{ display: "flex" }}>
                        <div style={{
                            width: "450px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "25px",
                            marginTop: "15px",
                            marginBottom: "5px"
                        }}>
                            <TextField
                                disabled={stateDocument?.uuid[0] !== '#'}
                                error={stateDocument?.type === ''}
                                select
                                inputProps={{ sx: { fontSize: "12px", } }}
                                label="Document type"
                                onChange={handleChangeType}
                                value={stateDocument?.type}
                            >
                                {ObjectToValueArray(EDocumentCustomerType).map((option: string, i) =>
                                    <MenuItem key={i} value={option}>
                                        {option.split('_').join(' ')}
                                    </MenuItem>)}
                            </TextField>

                            <TextField
                                inputProps={{ sx: { fontSize: "12px", } }}
                                label="Number"
                                autoComplete={'off'}
                                value={stateDocument?.number}
                                onChange={handlerEditNumber}>
                            </TextField>

                            <TextField
                                autoComplete={'off'}
                                label={"Caption"}
                                value={stateDocument?.caption}
                                onChange={handlerEditCaption}
                                inputProps={{ sx: { fontSize: "12px", widht: "35px" } }}
                            />

                            <div style={{ display: "flex", width: "370px", gap: "25px", marginRight: "40px" }}>
                                <TextField
                                    label={"Expiration date"}
                                    type="date"
                                    value={expirationDate}
                                    onChange={handlerExpirationDate}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ sx: { fontSize: "12px", widht: "185px" } }}
                                />
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: "20px" }}>
                        <div>
                            {visibleDeleteButton() &&
                                <SecureDialogComponent
                                    answerYES={() => setDeleteDocument(true)}
                                    text={''}
                                    icon={<DeleteForeverIcon fontSize='small' sx={{ marginRight: "5px", marginLeft: "5px" }} />}
                                    color={EButtonColor.error}
                                />
                            }
                        </div>
                        <div style={{ display: "flex", gap: "10px" }}>
                            <Button
                                startIcon={<SaveAsIcon />}
                                disabled={localError}
                                variant={'contained'}
                                size={'small'}
                                onClick={handlerSaveDocument} >
                                {((stateDocument?.uuid[0] === '#') ? ` ADD` : "SAVE")}
                            </Button>
                            <Button
                                startIcon={<CloseIcon />}
                                color={'warning'}
                                variant={'contained'}
                                size={'small'}
                                onClick={handleClose}>
                                Close
                            </Button>
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default DocumentsCustomerComponent;

interface IPropDocumentComponent {
    doc: ICustomerDocumentCreateDTO;
    expanded: boolean;
    handleChange: (uuid: string) => void
    handlerEdit: (item: ICustomerDocumentCreateDTO) => void
    refresh: () => void;
    snackMsg: (text: string) => void;
}
const DocumentComponent = (props: IPropDocumentComponent) => {
    const { doc, expanded, handleChange, handlerEdit } = props;
    const filePick = useRef<HTMLInputElement | null>(null);
    const [vewFiles, setViewFile] = useState<DocumentAttachmentResponseDto>();
    const [nrViewFiles, setNrViewFiles] = useState(0);
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handlePick = () => {
        filePick.current?.click()
    }

    const hendlerNextFile = () => {
        if (vewFiles && doc.attachments) {
            let n = doc.attachments.indexOf(vewFiles);
            if (n > -1) {
                setViewFile(doc.attachments[n + 1]);
                setNrViewFiles(n + 1);
            }
        }
    }

    const hendlerPrevFile = () => {
        if (vewFiles && doc.attachments) {
            let n = doc.attachments.indexOf(vewFiles);
            if (n > -1) {
                setViewFile(doc.attachments[n - 1]);
                setNrViewFiles(n - 1);
            }
        }
    }

    const handleView = (e: DocumentAttachmentResponseDto) => {
        setOpen(true);
        setViewFile(e);
        let n = doc.attachments ? doc.attachments.indexOf(e) : 0;
        setNrViewFiles(n);
    }

    const hendlerDeleteFile = (e: DocumentAttachmentResponseDto) => {
        (async () => {
            const [error, response] = await CustomerService.deleteFileInDocuments(e.uuid);
            if (response) { props.refresh(); props.snackMsg('For document type ' + doc.type.split('_').join(' ') + ' an attachment was successfully removed') }
            if (error) { }
        })()
    }

    const uploadHandler = (event: any) => {
        let file = event.target.files[0];
        if (!file) return;
        (async () => {
            let requestData = {
                'documentId': doc.uuid,
                'type': 'MAIN'
            };
            const formData = new FormData();
            formData.append('request', new Blob([JSON.stringify(requestData)], { type: "application/json" }));
            formData.append('file', file);

            const [error, response] = await CustomerService.updateFileInDocument(formData);
            if (response) {
                event.target.value = null;
                props.refresh();
                props.snackMsg(' For document type ' + doc.type.split('_').join(' ') + ' an attachment was successfully added')
            }
            if (error) { }
        })()

    }

    return (
        <>
            <Accordion
                expanded={expanded}
                onChange={() => handleChange(doc.uuid)}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography variant={'body2'} sx={{ flexShrink: 0, marginRight: "10px" }}>
                        {doc.type.split('_').join(' ').toUpperCase()}
                    </Typography>
                    <Typography variant={'body2'} sx={{ color: 'red' }}>
                        {
                            doc.expirationDate && doc.expirationDate > 0 && Date.now() > Number(doc.expirationDate)
                                ? ' [' + timeDiference(Number(doc.expirationDate), Date.now()) + ' expired] '
                                : ''
                        }
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                        <div style={{ display: "flex" }}>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "20px",
                                width: "50%",
                                paddingBottom: "10px",
                                paddingRight: "20px",
                                borderRight: "1px dashed silver"
                            }}>
                                <TextField
                                    disabled={true}
                                    inputProps={{ sx: { fontSize: "12px", } }}
                                    label="Number"
                                    value={doc.number}
                                >
                                </TextField>

                                <TextField
                                    disabled={true}
                                    inputProps={{ sx: { fontSize: "12px", } }}
                                    label="Caption"
                                    value={doc.caption || ''}
                                >
                                </TextField>

                                <div style={{ display: "flex", gap: "10px" }}>
                                    <TextField
                                        disabled={true}
                                        size="small"
                                        label={"Expiration date"}
                                        type="date"
                                        value={dateToYMD(Number(doc.expirationDate))}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{ sx: { fontSize: "12px", widht: "185px" } }}
                                    />
                                </div>
                            </div>
                            <div style={{
                                paddingLeft: "20px",
                                display: "flex",
                                justifyContent: "center"
                            }}>
                                <div>
                                    <div style={{ display: "flex" }}>
                                        <Tooltip
                                            title="Add attachment to the document"
                                            arrow={true}
                                            placement="top"
                                        >
                                            <Button
                                                color={'warning'}
                                                variant='contained'
                                                sx={{ marginBottom: "10px", width: "95px" }}
                                                onClick={handlePick}
                                            >
                                                <AttachFileIcon fontSize='small' />
                                            </Button>
                                        </Tooltip>
                                        {
                                            doc.attachments && (doc.attachments.length > 0) &&
                                            <div style={{ display: "flex", paddingLeft: "20px", paddingTop: "5px" }}>
                                                <Typography variant={'body2'} color={'GrayText'}>
                                                    files: {doc.attachments.length}
                                                </Typography>
                                            </div>
                                        }

                                        <input type="file" onChange={uploadHandler}
                                            accept=".png, .jpg, .jpeg, .txt, .pdf"
                                            ref={filePick}
                                            style={{
                                                width: "0px",
                                                height: "0px",
                                                margin: "0px",
                                                padding: "0px",
                                                overflow: "hidden"
                                            }} />
                                    </div>

                                    <div style={{
                                        maxHeight: "200px",
                                        overflowY: "auto",
                                    }}>
                                        {
                                            doc.attachments && doc.attachments.map((e: DocumentAttachmentResponseDto, i) =>
                                                <div style={{}} key={i}>
                                                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                        <Tooltip
                                                            title="Open the file to view"
                                                            arrow={true}
                                                            placement={"top"}
                                                        >
                                                            <Button
                                                                color={'info'}
                                                                variant='outlined'
                                                                size={'small'}
                                                                onClick={() => handleView(e)}
                                                            >
                                                                {e.fileDetails.extension.toLocaleLowerCase() === 'pdf' && <PictureAsPdfIcon fontSize='small' />}
                                                                {['png', 'jpg', 'jpeg'].includes(e.fileDetails.extension.toLocaleLowerCase()) && <FilterIcon fontSize='small' />}
                                                                {e.fileDetails.extension.toLocaleLowerCase() === 'txt' && <FilterNoneIcon fontSize='small' />}
                                                                <span style={{ marginLeft: "10px" }}> file - {i + 1} </span>
                                                            </Button>
                                                        </Tooltip>

                                                        <div >
                                                            <Tooltip
                                                                title="Download file"
                                                                arrow={true}
                                                                placement="top"
                                                            >

                                                                <Box>
                                                                    <FileDownloadComponent fileName={doc.type + '.' + e.fileDetails.extension} ent={e.fileDetails} />
                                                                </Box>
                                                            </Tooltip>
                                                        </div>

                                                        <Tooltip
                                                            title="Remove file"
                                                            arrow={true}
                                                            placement="top"
                                                        >
                                                            <Box>
                                                                <SecureDialogComponent
                                                                    answerYES={() => hendlerDeleteFile(e)}
                                                                    text={''}
                                                                    icon={<DeleteForeverIcon fontSize='medium' sx={{ marginRight: "5px", marginLeft: "5px" }} />}
                                                                    color={EButtonColor.error}
                                                                    iconButton={true}
                                                                />
                                                            </Box>
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{
                            marginTop: "20px",
                            width: "250px",
                            display: "flex",
                            gap: "20px"
                        }} >
                            <Tooltip
                                title="Open the document for editing"
                                placement="top"
                                arrow={true}
                            >
                                <Button
                                    color={'info'}
                                    variant={'contained'}
                                    size={'small'}
                                    onClick={() => handlerEdit(doc)}
                                    style={{ width: "95px", height: "32px" }}
                                >
                                    <AutoAwesomeIcon fontSize='small' />
                                </Button>
                            </Tooltip>
                        </div>

                        <Dialog
                            sx={{
                                "& .MuiDialog-container": {
                                    "& .MuiPaper-root": {
                                        width: "100%",
                                        maxWidth: "75%",
                                        minHeight: "83%",
                                    },
                                },
                            }}
                            open={open}
                            onClose={handleClose}
                        >
                            <DialogTitle>
                                {doc.type.split('_').join(' ')}
                            </DialogTitle>
                            <DialogContent>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100%" }}>
                                    {vewFiles &&
                                        <FileViewComponent ent={vewFiles.fileDetails} width={300} heigth={300} />}
                                </div>
                            </DialogContent>
                            <DialogActions
                                style={{
                                    marginBottom: "20px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    paddingLeft: "20px",
                                    paddingRight: "20px"
                                }}>
                                {vewFiles &&
                                    <FileDownloadComponent fileName={doc.type + '.' + vewFiles.fileDetails.extension} ent={vewFiles.fileDetails} />
                                }
                                <div style={{ display: "flex", gap: "10px" }}>
                                    <Button
                                        onClick={hendlerPrevFile}
                                        disabled={nrViewFiles <= 0}
                                        variant='contained'
                                        size='small'>
                                        <KeyboardArrowLeftIcon fontSize='small' />
                                    </Button>

                                    <div> {nrViewFiles + 1} of {doc.attachments && doc.attachments.length} </div>

                                    <Button
                                        onClick={hendlerNextFile}
                                        disabled={doc.attachments && nrViewFiles === doc.attachments.length - 1}
                                        variant='contained'
                                        size='small'>
                                        <KeyboardArrowRightIcon fontSize='small' />
                                    </Button>
                                </div>
                                <Button onClick={handleClose}>Close</Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </AccordionDetails>
            </Accordion >
        </>
    )
}