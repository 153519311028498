import { useEffect, useState } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StarIcon from '@mui/icons-material/Star';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { Button, Divider, IconButton, Tooltip } from "@mui/material";
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { deleteFavoritesAutos, getFavoritesTransport } from "../../store/reducers/favoritesSlice";
import { useNavigate } from "react-router";
import { IFavoritesAuto } from "../../models/TransportModels";
import AvatarComponent from "../CustomComponentModule/AvatarComponent";
import { AnyPhoto } from "../StafModule/GridStafComponent";

const TransportFavoritesComponent = () => {

    const [expanded, setExpanded] = useState<string | false>('favorites');
    const { transports } = useSelector((state: RootState) => state.favoritesSlice);
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    useEffect(() => {
        dispatch(getFavoritesTransport())
    }, [])

    const handlerDelete = (u: IFavoritesAuto) => {
        dispatch(deleteFavoritesAutos([u]));
    }

    const handlerDeleteAll = () => {
        dispatch(deleteFavoritesAutos(transports));
    }

    const hendlerLink = (u: IFavoritesAuto) => {
        navigate('/transport/' + u.uuid);
    }

    useEffect(() => {
        if (transports.length > 0) {
            setExpanded('favorites');
        }
    }, [transports])

    return (
        <>
            {transports.length > 0 &&
                <Accordion expanded={expanded === 'favorites'} onChange={handleChange('favorites')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                    >
                        <StarIcon />
                        <Typography variant={'subtitle2'} sx={{ width: '33%', flexShrink: 0, marginLeft: "10px" }}>FAVORITES</Typography>

                    </AccordionSummary>
                    <AccordionDetails>
                        <Divider />
                        {transports.length > 0 &&
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "5px" }} >
                                <div></div>
                                <Tooltip
                                    title={'Remove all favorites'}
                                    arrow
                                    placement="right"
                                >
                                    <Button onClick={handlerDeleteAll} sx={{ margin: "5px" }} size='small' endIcon={<DeleteSweepIcon />}>
                                        Clear list
                                    </Button>
                                </Tooltip>
                            </div>}
                        <Divider />
                        {transports &&
                            transports.map((u, i) =>
                                <div key={i} style={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "5px" }}>
                                    <div
                                        onClick={() => hendlerLink(u)}
                                        className="dataItem"
                                        style={{
                                            marginTop: "4px",
                                            marginBottom: "4px",
                                            cursor: "pointer",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "start",
                                            gap: "15px",
                                            paddingLeft: "10px",
                                            paddingRight: "10px",
                                            minWidth: "200px",
                                            overflow: "hidden"
                                        }}
                                    >
                                        <div style={{ width: "5px", height: "30px", background: u.color }}></div>
                                        {(u.mainImage)
                                            ? <AvatarComponent link={u.mainImage} w={30} h={30} />
                                            : <AnyPhoto />}
                                        <Typography
                                            variant={"caption"}> {u.licensePlate} [{u.brand} {u.model}] </Typography>
                                    </div>
                                    <div>
                                        <Tooltip
                                            title={'Remove from favorites'}
                                            arrow
                                            placement="right"
                                        >
                                            <IconButton aria-label="fingerprint" color='primary' onClick={() => handlerDelete(u)}>
                                                <LinkOffIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </div>
                            )
                        }
                        <Divider />

                    </AccordionDetails>
                </Accordion>}
        </>
    )
}

export default TransportFavoritesComponent;