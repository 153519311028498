import { settings } from "../configs/settings";

export const updateTokens = (tokenData: any) => { 
   if (tokenData.accessToken && tokenData.refreshToken && tokenData.expiryDuration && tokenData.tokenType) {
      sessionStorage.setItem('access_token', tokenData.accessToken);
      sessionStorage.setItem('refresh_token', tokenData.refreshToken);
      sessionStorage.setItem('access_token_expiry_duration', tokenData.expiryDuration);
      sessionStorage.setItem('access_token_type', tokenData.tokenType);
  }    
}

export const resetTokens = () => {
  sessionStorage.removeItem('access_token');
  sessionStorage.removeItem('refresh_token');
  sessionStorage.removeItem('access_token_expiry_duration');
  sessionStorage.removeItem('access_token_type');
}

export const getTokens = ()=> {

  const accessToken = sessionStorage.getItem('access_token'),
      refreshToken = sessionStorage.getItem('refresh_token'),
      expiryDuration = sessionStorage.getItem('access_token_expiry_duration'),
      accessTokenType = sessionStorage.getItem('access_token_type');

  return {
      accessToken: accessToken,
      refreshToken: refreshToken,
      expiryDuration: expiryDuration,
      accessTokenType: accessTokenType,
      token: accessTokenType + ' ' + accessToken,
      isValid: accessToken && refreshToken && expiryDuration && accessTokenType
  }
}  
export const processLoginWithOneTimeUseToken = () => {
  const params = window.location.search,
      urlParams = new URLSearchParams(params),
      accessToken = urlParams.get('accessToken'),
      refreshToken = urlParams.get('refreshToken'),
      tokenType = urlParams.get('tokenType'),
      expiryDuration = urlParams.get('expiryDuration');

  if (accessToken && tokenType && refreshToken && expiryDuration) {
      const tokenData = {
          accessToken: accessToken,
          refreshToken: refreshToken,
          expiryDuration: expiryDuration,
          tokenType: tokenType
      };

      updateTokens(tokenData);
  }
}
export const logoutTokens= () => {
  
  const url =settings.baseURL+'auth/logout';

  var xmlHttp = new XMLHttpRequest();
  xmlHttp.open("POST", url, false);

  xmlHttp.onreadystatechange = function () {
      resetTokens();
      window.location.href = '/login';
  };

  const tokenData = getTokens();

  xmlHttp.setRequestHeader('Content-Type', 'application/json');
  xmlHttp.setRequestHeader('Authorization', tokenData.token);
  xmlHttp.send();

}