import { Alert, Button, LinearProgress, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import GoogleIcon from '@mui/icons-material/Google';
import { useTranslation } from "react-i18next";
import GeneralService from '../../services/GeneralService';
import { settings } from '../../configs/settings';

interface IProps {
  activateLink: () => void;
}
const GoogleAuthButtonComponent = () => {

  const { t } = useTranslation();

  const [time_string, setTime_string] = useState<string>("");
  const [timeS, setTimeS] = useState<number>(0);
  const [state, setState] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [linked, setLinked] = useState(false);

  useEffect(() => {
    let isSubscribed = true;
    setLinked(false);
    (async () => {
      const [error, response] = await GeneralService.getServerTime();
      if (error) { setWaiting(true) }
      if (response) {
        if (isSubscribed) {
          setTimeS(t => t + Number(response.data.body.serverTime));
          setTime_string(tick(response.data.body.serverTime));
          setState(true);
        }
      }
    })();
    return () => { isSubscribed = false };
  }, []);

  useEffect(() => {
    let isSubscribed = true;
    let timerID = setInterval(() => {
      if (waiting) {
        (async () => {
          const [error, response] = await GeneralService.getServerTime();
          if (error) { setWaiting(true) }
          if (response) {
            handledLink()
          }
        })();
      }
    }, 5000);
    return () => { isSubscribed = false; clearInterval(timerID); };
  }, [waiting]);

  useEffect(() => {
    let timerID = setInterval(() => {
      if (timeS > 0) {
        setTimeS(t => t + 1000);
        setTime_string(tick(timeS));
      }
    }, 1000);
    return function cleanup() {
      clearInterval(timerID);
    };
  }, [timeS]);

  const tick = (n: number): string => {
    let d = new Date(n);
    let h = d.getHours().toString();
    let m = d.getMinutes().toString();
    let s = d.getSeconds().toString();
    return " | " + digitsClock(h) + ':' + digitsClock(m) + ':' + digitsClock(s) + " |";
  }

  const digitsClock = (s: string): string => {
    return (s.length < 2) ? '0' + s : s;
  }

  const handledLink = () => {
    try {
      window.location.href = settings.baseURL + "oauth2/google?redirect_uri=" + settings.redirectURL;
      setLinked(true);

    }
    catch (e) { console.error(e) }
  }

  return (
    <>
      {state &&
        <div style={{ display: "flex", justifyContent: "center" }}>
          {
            !linked ?
              <Button variant="contained" startIcon={<GoogleIcon />} size="large" onClick={handledLink}>
                {t("button_google")} {time_string}
              </Button> :
              <div style={{ display: "flex", flexDirection: "column", gap: "10px", alignItems: "center" }}>
                <LinearProgress sx={{ width: "200px" }}></LinearProgress>
                <Typography sx={{ fontSize: "11px" }}>Login ...</Typography>
              </div>
          }
        </div>
      }

      {(!state) && <div style={{ display: "flex", flexDirection: "column", gap: "20px", width: "100%", alignItems: "center", justifyContent: "center" }} >
        <LinearProgress sx={{ width: "200px" }}></LinearProgress>
        {(waiting) &&
          <Alert severity="error">
            <strong>{(t("Network Error")).toUpperCase()}</strong>
          </Alert>
        }
      </div>
      }
    </>
  )
}
export default GoogleAuthButtonComponent;