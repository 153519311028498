import MainPage from "./pages/MainPage";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./store/store";
import { modifyStateJSLoaded } from "./store/reducers/mapSlice";
import {  useLoadScript } from "@react-google-maps/api";

import GeneralService from "./services/GeneralService";

const libraries: any = ["places"];

const darkTheme = createTheme({ palette: { mode: 'dark', }, });
const lightTheme = createTheme({ palette: { mode: 'light', }, });

const App = () => {

    const dispatch = useDispatch();
    const { darkMode } = useSelector((state: RootState) => state.cnfSlice.interfaceState);
    const { auth } = useSelector((state: RootState) => state.cnfSlice);
    const [mode, setMode] = useState(darkMode);
    const [key, setKey] = useState<string>('')

    useEffect(() => { setMode(darkMode); }, [darkMode])

    useEffect(() => {
        if (auth) {            
            (async () => {
                const response = await GeneralService.getUserProfile();
                if (response) {
                    let key = response[1].data.body.systemPreference.keys[0].value as string;
                    setKey(key);
                }
            })()
        }
    }, [auth])
    
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: key,
        libraries,
    });

    useEffect(() => {
        dispatch(modifyStateJSLoaded(isLoaded))
    }, [isLoaded])

    return (
        <>
            <ThemeProvider theme={mode ? darkTheme : lightTheme}>
                <MainPage></MainPage>
            </ThemeProvider>
        </>
    );
}

export default App;

