import { useEffect, useState } from "react";
import { ELoadFragilityType, ILoadItemRequestDto, } from "../../models/LoadModels";
import { TextField, MenuItem, Autocomplete, IconButton, useTheme, Divider, Typography, Box, InputAdornment, } from '@mui/material';
import { ObjectToValueArray } from "../../helpers/generalHelper";
import AutoModeIcon from '@mui/icons-material/AutoMode';

import HTMLRedactor from "../CustomComponentModule/HTMLRedactor";
import React from "react";
import LoadService from "../../services/LoadService";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { setLastError } from "../../store/reducers/cnfSlice";
import SCViewList from "../../styledComponents/sharedComponent/SCViewList";
import { curency, isNumber } from "../../helpers/textHelper";

interface IProps {
    item: ILoadItemRequestDto | null;
    editableMode: boolean;
    setError: (n: number) => void;
    onSave: (name: string, item: string) => void;
    save: boolean
}

interface fragilityType {
    inputValue?: string;
    name: string;
    uuid?: string;
    caption?: string;
}

interface palletType {
    inputValue?: string;
    allowDelete?: boolean
    height?: number
    length?: number
    name: string
    uuid?: string
    weight?: number
    width?: number,
}

const EditItemComponent = (props: IProps) => {
    const { item, editableMode, setError, save, onSave } = props
    const theme = useTheme();
    const { measureSize, measureMass } = useSelector((state: RootState) => state.cnfSlice.globalPreferenceState);

    const [description, setDescription] = useState<string>("");
    const [fragilityType, setFragilityType] = React.useState<fragilityType | null>(null)
    const [palletType, setPalletType] = React.useState<palletType | null>(null)
    const [typesP, setTypesP] = useState<palletType[]>([])
    const [types, setTypes] = useState<fragilityType[]>([])
    const [typeUuid, setTypeUuid] = useState<string>("")
    const [snapshot, setSnapshot] = useState<string>("");
    const dispatch = useDispatch<AppDispatch>();
    const [state, setState] = useState<ILoadItemRequestDto>(newLoad())

    const [statistic, setStatistic] = useState({
        unitVolume: '',
        itemVolume: '',
        unitAmount: '',
        itemAmount: '',
        unitWg: '',
        itemWg: '',
    })

    const [errorIdno, setErrorIdno] = useState<boolean>(false);

    useEffect(() => {
        if (item) {
            let p = typesP.filter(e => e.uuid === item.palletTypeId)[0];
            let f = types.filter(e => e.uuid === item.fragilityTypeId)[0];
            setPalletType(p ? p : null);
            setFragilityType(f ? f : null);
            setState(item)
            setSnapshot(JSON.stringify(item))
            setError(0);
            setDescription(item.description)
            if (item.uuid === "*" && item.idno !== '' && item.idno) {
                handleCheckIDNO(item.idno);
            }
        }
    }, [item])


    const handlerName = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        let b: string = event.target.value as string;
        if (state) {
            setState((st) => { return { ...st, name: b } })
        }
    }

    const handleChangeIdno = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        let b: string = event.target.value as string;
        if (state) {
            setState((st) => { return { ...st, idno: b } });

            (async () => {
                const [error, response] = await LoadService.isAvailableLoadItemIDNO(b)
                if (response) {
                    let ras = response.data.body;
                    setErrorIdno(!ras);
                    if (!ras) setError(-1)
                }
                if (error) { }
            })()
        }
    }

    const handlerAmount = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        let b: string = event.target.value as string;
        const regex = /^-?\d+(\.\d*)?$/;
        if (b === "" || regex.test(b) && state) {
            setState((st) => { return { ...st, amount: b } })
        }
    }
    const handlerNum = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        let b: string = event.target.value as string;
        if (state) {
            setState((st) => { return { ...st, units: b ? parseInt(b, 10) : 0 } })
        }
    }
    const handlerHeight = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        let b: string = event.target.value as string;
        if (state) {
            setState((st) => { return { ...st, unitHeight: b ? parseInt(b, 10) : 0 } })
        }
    }
    const handlerLength = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        let b: string = event.target.value as string;
        if (state) {
            setState((st) => { return { ...st, unitLength: b ? parseInt(b, 10) : 0 } })
        }
    }
    const handlerWidth = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        let b: string = event.target.value as string;
        if (state) {
            setState((st) => { return { ...st, unitWidth: b ? parseInt(b, 10) : 0 } })
        }
    }
    const handlerWeight = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        let b: string = event.target.value;
        if (state) {
            setState((st) => { return { ...st, unitWeight: b ? parseInt(b, 10) : 0 } })
        }
    }
    const handlerFragil = (event: any) => {
        let b: string = event.target.value as string;
        if (state) {
            setState((st) => { return { ...st, fragilityLevel: b } })
        }
    }
    const handleAddIDNO = (event: any) => {
        (async () => {
            let b = Date.now()
            const [error, response] = await LoadService.isAvailableLoadItemIDNO(b.toString())
            if (response) {
                let ras = response.data.body;
                if (!ras) {
                    getAvailableIdno();
                } else {
                    setState((st) => { return { ...st, idno: b.toString() } })
                }
            }
            if (error) { }
        })()
    }

    const handleCheckIDNO = (s: string) => {
        (async () => {
            const [error, response] = await LoadService.isAvailableLoadItemIDNO(s)
            if (response) {
                let ras = response.data.body;
                setErrorIdno(!ras);
                if (!ras) setError(-1)
            }
            if (error) { }
        })()
    }

    const getAvailableIdno = () => {
        let check: boolean = true;
        (async () => {
            while (check) {
                let b = Date.now();
                const [error, response] = await LoadService.isAvailableLoadItemIDNO(b.toString())
                if (response) {
                    let ras = response.data.body;
                    if (ras) {
                        check = false;
                        setState((st) => { return { ...st, idno: b.toString() } })
                    }
                }
                if (error) { }
            }
        })()

    }

    const handlerDescription = (s: string) => {
        setState((st) => { return { ...st, description: s } })
    }

    useEffect(() => {
        if (fragilityType) {
            setState((st) => { return { ...st, fragilityTypeId: fragilityType.uuid ? fragilityType.uuid : '' } })
        } else { setState((st) => { return { ...st, fragilityTypeId: '', fragilityLevel: '' } }) }
    }, [fragilityType])

    useEffect(() => {
        if (palletType) {
            setState((st) => {
                return {
                    ...st,
                    palletTypeId: palletType.uuid ? palletType.uuid : '',
                }
            })
        } else {
            setState((st) => {
                return {
                    ...st,
                    palletTypeId: ''
                }
            })
        }
    }, [palletType])

    useEffect(() => {
        let er: boolean = false;
        let ter: Array<string> = [];

        if ((state.name.trim().length < 1)) {
            er = true;
        }
        if ((state.idno.trim().length < 1)) {
            er = true;
        }
        if ((state.amount.length === 0)) {
            er = true;
        }
        if ((state.units === 0)) {
            er = true;
        }
        if ((state.unitHeight === 0)) {
            er = true;
        }
        if ((state.unitLength === 0)) {
            er = true;
        }
        if ((state.unitWidth === 0)) {
            er = true;
        }
        if ((!state.palletTypeId)) {
            er = true;
        }
        if ((!state.fragilityTypeId)) {
            er = true;
        }
        if ((state.unitWeight === 0)) {
            er = true;
        }
        if ((state.fragilityLevel.length < 1)) {
            er = true;
        }
        if (state && !er) {
            if (snapshot === JSON.stringify(state)) { setError(0) } else { setError(1) }
        } else { setError(-1) }

    }, [item, state, snapshot])

    useEffect(() => {
        if (state) {
            setStatistic({
                unitVolume: (state.unitWidth * state.unitHeight * state.unitWidth).toString(),
                itemVolume: (state.unitWidth * state.unitHeight * state.unitWidth * state.units).toString(),
                unitAmount: (state.units ? (isNumber(parseFloat(state.amount)) ? (parseFloat(state.amount) / state.units) : 0) : 0).toFixed(2).toString(),
                itemAmount: (state.units ? (isNumber(parseFloat(state.amount)) ? (parseFloat(state.amount)) : 0) : 0).toFixed(2).toString(),
                unitWg: state.unitWeight.toFixed(2).toString(),
                itemWg: (state.units ? state.unitWeight * state.units : 0).toFixed(2).toString(),
            })
        }
    }, [state])

    useEffect(() => {

        (async () => {
            const [error, response] = await LoadService.getAllFragilityType();
            if (response) { setTypes(response.data.body) }
            if (error) { dispatch(setLastError(error)) }
        })();

        (async () => {
            const [er, rs] = await LoadService.getAllPalletType();
            if (rs) { setTypesP(rs.data.body) }
            if (er) { dispatch(setLastError(er)) }
        })();
    }, [])

    useEffect(() => {
        (async () => {
            if (save) {
                let body: ILoadItemRequestDto = (typeUuid)
                    ? { ...state, fragilityTypeId: typeUuid, uuid: (state.uuid === "*") ? "" : state.uuid }
                    : { ...state, uuid: (state.uuid === "*") ? "" : state.uuid }
                const [error, response] = (!body.uuid)
                    ? await LoadService.createItemForLoad(body)
                    : await LoadService.updateItemForLoad(body);
                if (response) {
                    onSave(body.name, body.idno);
                }
                if (error) { dispatch(setLastError(error)) }
            }
        })()
    }, [save])

    return (
        <>
            <div style={{ display: "flex", flexDirection: "column", marginTop: "20px" }} >
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", flexDirection: "column", maxWidth: "530px", gap: "20px" }}>

                        <div style={{ display: "flex", gap: "20px" }}>
                            <TextField
                                error={!state.name.trim()}
                                autoComplete="off"
                                label={"Name"}
                                value={state.name}
                                sx={{ width: "300px", marginTop: "5px" }}
                                disabled={!editableMode}
                                onChange={handlerName}
                            />
                            <Box sx={{ display: "flex" }}>
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <IconButton onClick={handleAddIDNO} disabled={!editableMode}>
                                        <AutoModeIcon />
                                    </IconButton>
                                </Box>

                                <TextField
                                    error={errorIdno}
                                    autoComplete="off"
                                    label={"IDNO"}
                                    disabled={!editableMode}
                                    value={state.idno}
                                    onChange={handleChangeIdno}
                                    sx={{ width: "170px", marginTop: "5px" }}
                                />
                            </Box>
                        </div>
                        <Divider />
                        <div style={{ display: "flex", gap: "20px" }}>

                            <Autocomplete
                                value={palletType}
                                onChange={(event, newValue) => {
                                    if (typeof newValue === 'string') {
                                        setPalletType({
                                            name: newValue,
                                        });
                                    } else if (newValue && newValue.inputValue) {
                                        setPalletType({
                                            name: newValue.inputValue,
                                        });
                                    } else {
                                        setPalletType(newValue);
                                    }
                                }}
                                selectOnFocus
                                clearOnBlur
                                disabled={!editableMode}
                                handleHomeEndKeys
                                options={typesP}
                                getOptionLabel={(option) => {
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    if (option) {
                                        if (option.inputValue) {
                                            return option.inputValue;
                                        }
                                        return option.name;
                                    } else return ""
                                }}
                                renderOption={(props, option) =>
                                    <li {...props}>
                                        <Typography fontSize={"12px"} fontWeight={"bold"}>
                                            {option?.name} [{option?.width} {measureSize.toLocaleUpperCase()} x {option?.height} {measureSize.toLocaleUpperCase()} x {option?.length} {measureSize.toLocaleUpperCase()}]
                                        </Typography>
                                    </li>}
                                sx={{ width: 300 }}
                                freeSolo
                                renderInput={(params) =>
                                (
                                    <TextField
                                        error={!state.palletTypeId}
                                        {...params}
                                        label="Pallet type"
                                        sx={{ width: "300px" }}
                                    />
                                )}
                            />

                            <TextField
                                error={!state.units}
                                autoComplete="off"
                                value={state.units}
                                disabled={!editableMode}
                                sx={{ width: "145px" }}
                                label={"Units"}
                                onChange={handlerNum}
                            />

                            <TextField
                                error={!state.unitWeight}
                                autoComplete="off"
                                disabled={!editableMode}
                                value={state.unitWeight}
                                sx={{ width: "145px" }}
                                label={"Unit weight"}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <div style={{ fontSize: "12px" }}>{measureMass.toLocaleUpperCase()}</div>
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={handlerWeight}
                            />
                        </div>

                        <div style={{ display: "flex", gap: "20px" }}>
                            <TextField
                                error={!state.unitWidth}
                                autoComplete="off"
                                disabled={!editableMode}
                                value={state.unitWidth}
                                sx={{ width: "86px" }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <div style={{ fontSize: "12px" }}>{measureSize.toLocaleUpperCase()}</div>
                                        </InputAdornment>
                                    ),
                                }}
                                label={"Unit width"}
                                onChange={handlerWidth}
                            />
                            <TextField
                                error={!state.unitHeight}
                                autoComplete="off"
                                disabled={!editableMode}

                                value={state.unitHeight}
                                sx={{ width: "86px" }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <div style={{ fontSize: "12px" }}>{measureSize.toLocaleUpperCase()}</div>
                                        </InputAdornment>
                                    ),
                                }}
                                label={"Unit height"}
                                onChange={handlerHeight}
                            />
                            <TextField
                                error={!state.unitLength}
                                autoComplete="off"
                                disabled={!editableMode}
                                value={state.unitLength}
                                sx={{ width: "86px" }}
                                label={"Unit length"}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <div style={{ fontSize: "12px" }}>{measureSize.toLocaleUpperCase()}</div>
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={handlerLength}
                            />

                            <TextField
                                error={!state.amount}
                                autoComplete="off"
                                label={"Total amount"}
                                disabled={!editableMode}
                                value={state.amount}
                                sx={{ width: "210px" }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <div style={{ fontSize: "12px" }}>$</div>
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={handlerAmount}
                            />
                        </div>
                        <Divider />
                        <div style={{ display: "flex", gap: "5px", border: "1px dashed silver", padding: "10px", flexDirection: "column" }}>
                            <SCViewList minWidth={500} spaceBetween>
                                <div style={{ minWidth: "50px" }}></div>
                                <Divider orientation="vertical" />
                                <Typography sx={{ width: "80px", fontWeight: "bold" }}>WEIGHT</Typography>
                                <Divider orientation="vertical" />
                                <Typography sx={{ width: "80px", fontWeight: "bold" }}>VOLUME</Typography>
                                <Divider orientation="vertical" />
                                <Typography sx={{ width: "100px", fontWeight: "bold" }}>AMOUNT</Typography>
                                <Divider orientation="vertical" />
                            </SCViewList>
                            <Divider />
                            <SCViewList minWidth={500} spaceBetween>
                                <Typography sx={{ minWidth: "50px", fontWeight: "bold" }}>1 UNIT</Typography>
                                <Divider orientation="vertical" />
                                <Typography sx={{ width: "80px" }}>{statistic.unitWg} {measureMass}</Typography>
                                <Divider orientation="vertical" />
                                <Typography sx={{ width: "80px" }}>{statistic.unitVolume}</Typography>
                                <Divider orientation="vertical" />
                                <Typography sx={{ width: "120px" }}>{statistic.unitAmount} {curency}</Typography>
                                <Divider orientation="vertical" />
                            </SCViewList>
                            <Divider />
                            <SCViewList minWidth={500} spaceBetween>
                                <Typography sx={{ minWidth: "50px", fontWeight: "bold" }}>TOTAL</Typography>
                                <Divider orientation="vertical" />
                                <Typography sx={{ width: "80px" }}>{statistic.itemWg} {measureMass}</Typography>
                                <Divider orientation="vertical" />
                                <Typography sx={{ width: "80px" }}>{statistic.itemVolume}</Typography>
                                <Divider orientation="vertical" />
                                <Typography sx={{ width: "120px" }}>{statistic.itemAmount} {curency}</Typography>
                                <Divider orientation="vertical" />
                            </SCViewList>
                            <Divider />
                        </div>
                    </div>

                    <div style={{ borderLeft: "1px dashed silver", width: "1px" }}></div>

                    <div style={{
                        flex: "1 1 auto",
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "5px",
                        maxWidth: "530px",
                        gap: "20px",

                    }}>

                        <div style={{ width: "530px", display: "flex", gap: "20px" }}>

                            <Autocomplete
                                value={fragilityType}
                                onChange={(event, newValue) => {
                                    if (typeof newValue === 'string') {
                                        setFragilityType({
                                            name: newValue,
                                        });

                                    } else if (newValue && newValue.inputValue) {
                                        setFragilityType({
                                            name: newValue.inputValue,
                                        });
                                    } else {
                                        let b = "";
                                        if (newValue?.uuid) b = newValue?.uuid;
                                        setTypeUuid(b);
                                        setFragilityType(newValue);
                                    }
                                }}

                                selectOnFocus
                                clearOnBlur
                                disabled={!editableMode}
                                handleHomeEndKeys
                                options={types}
                                getOptionLabel={(option) => {
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    if (option) {
                                        if (option.inputValue) {
                                            return option.inputValue;
                                        }
                                        return option.name;
                                    } else return ""

                                }}
                                renderOption={(props, option) => <li {...props}>{option?.name}</li>}
                                sx={{ width: 320 }}
                                freeSolo
                                renderInput={(params) =>
                                (
                                    <TextField
                                        error={!state.fragilityTypeId}
                                        {...params}
                                        label="Fragility type"
                                        sx={{ width: "320px" }}
                                    />
                                )}
                            />

                            <TextField
                                error={!state.fragilityLevel}
                                select
                                id="demo-select-small"
                                disabled={!editableMode}
                                value={state.fragilityLevel}
                                onChange={handlerFragil}
                                sx={{ width: "190px" }}
                                label={"Fragility level"}
                                inputProps={{ sx: { fontSize: "15px" } }}
                            >
                                {ObjectToValueArray(ELoadFragilityType).map((option: string, i) =>
                                    <MenuItem key={i} value={option}>
                                        {option.split('_').join(' ')}
                                    </MenuItem>)}
                            </TextField>

                        </div>
                        <Divider />
                        <HTMLRedactor
                            getValue={handlerDescription}
                            initValue={description}
                            readOnly={!editableMode}
                        />
                        {!editableMode &&
                            <div style={{ marginTop: "-150px", width: "530px", opacity: "0.2", height: "180px" }}>
                            </div>
                        }
                    </div>
                </div>
            </div >
        </>
    )

}
export default EditItemComponent

const newLoad = (): ILoadItemRequestDto => {
    return {
        loadId: "",
        name: "",
        units: 0,
        unitWidth: 0,
        unitLength: 0,
        amount: '',
        unitHeight: 0,
        unitWeight: 0,
        fragilityLevel: "",
        fragilityTypeId: "",
        palletTypeId: "",
        idno: "",
        description: ""
    }
}