import { useEffect, useState } from 'react'
import { useTheme } from "@mui/material";

interface IProps {
      width: number,
      heigth: number,
      color?: string
}

const LogoViewComponent = (props: IProps) => {
      const [styleColor, setStyleColor] = useState({})
      const theme = useTheme();
      const supportedColors = ['white', 'black'];

      const style = {
            fill: theme.palette.mode === 'light' ? 'black' : 'white'
      };

      if (props.color && supportedColors.includes(props.color)) {
            style.fill = props.color;
      }

      useEffect(() => {
            setStyleColor(style);
      }, [theme, props])

      return (
            <div style={{ width: props.width, height: props.heigth }}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2520 1822">
                        <title>KLMB Logo 2</title>
                        <g id="TRANSPORTATION_INC" data-name="TRANSPORTATION INC." style={styleColor}>
                              <path id="_." data-name="."
                                    d="M2573.06,1890.72a6.85,6.85,0,0,0,4.42-1.63,6.59,6.59,0,0,0,2.43-4,4.84,4.84,0,0,0-.94-4,4.28,4.28,0,0,0-3.56-1.72,6.74,6.74,0,0,0-4.44,1.72,6.85,6.85,0,0,0-2.44,4.13,4.53,4.53,0,0,0,.95,3.87,4.39,4.39,0,0,0,3.58,1.63Z"
                                    transform="translate(-230 -590)" />
                              <path id="C"
                                    d="M2485.13,1890.9a81.3,81.3,0,0,0,26.11-4.22,70.21,70.21,0,0,0,21.75-11.61l-2.68-4.3q-18.43,14.79-43.72,14.79-23.93,0-34.55-11.87t-7.09-32a82.28,82.28,0,0,1,14.11-33.72,80.91,80.91,0,0,1,27.62-24.43,75.24,75.24,0,0,1,36.53-9.11q25.29,0,34.84,14.1l4.77-3.61q-4.7-7.56-14.63-11.7t-23.7-4.13a82.76,82.76,0,0,0-40.1,9.9,87.12,87.12,0,0,0-30.06,26.57,89,89,0,0,0-15.26,36.3q-2.58,14.63,1.83,25.72a33.23,33.23,0,0,0,15.63,17.2q11.22,6.11,28.6,6.11Z"
                                    transform="translate(-230 -590)" />
                              <path id="N"
                                    d="M2406.2,1769.79l-41.59,111-43.91-111h-4.82l-45.31,120.42h5.85l41.58-111,43.91,111h4.82L2412,1769.79Z"
                                    transform="translate(-230 -590)" />
                              <path id="I" d="M2199.7,1890.21h5.85l45.31-120.42H2245Z" transform="translate(-230 -590)" />
                              <path id="N-2" data-name="N"
                                    d="M2080,1769.79l-41.58,111-43.91-111h-4.82l-45.32,120.42h5.85l41.59-111,43.91,111h4.81l45.32-120.42Z"
                                    transform="translate(-230 -590)" />
                              <path id="O"
                                    d="M1832.77,1890.9a81.1,81.1,0,0,0,39.59-9.9,86.73,86.73,0,0,0,29.8-26.57,89.56,89.56,0,0,0,15.17-36.3q2.58-14.62-1.82-25.72a33.32,33.32,0,0,0-15.55-17.2q-11.13-6.11-28.51-6.11a81.1,81.1,0,0,0-39.59,9.9,87.57,87.57,0,0,0-29.89,26.57,89.17,89.17,0,0,0-15.26,36.3q-2.58,14.63,1.84,25.72a33.15,33.15,0,0,0,15.63,17.2q11.22,6.11,28.59,6.11Zm1.46-5.34q-23.74,0-34.45-12t-7.19-31.91a82.41,82.41,0,0,1,14.11-33.63,81.3,81.3,0,0,1,27.37-24.52,73.07,73.07,0,0,1,35.92-9.11q23.74,0,34.38,11.87t7.09,32a82.53,82.53,0,0,1-14.1,33.63,81.32,81.32,0,0,1-27.38,24.52,72.65,72.65,0,0,1-35.75,9.11Z"
                                    transform="translate(-230 -590)" />
                              <path id="I-2" data-name="I" d="M1708.52,1890.21h5.85l45.32-120.42h-5.85Z"
                                    transform="translate(-230 -590)" />
                              <path id="T" d="M1614.77,1890.21h5.85L1664,1775.13h44l2.15-5.34h-94.1l-2,5.34h44Z"
                                    transform="translate(-230 -590)" />
                              <path id="A"
                                    d="M1547.86,1890.21h5.85l-10.42-120.42h-5.68l-101,120.42h6.54l29.44-35.27H1545ZM1476.69,1850l61.69-73.79,6.08,73.79Z"
                                    transform="translate(-230 -590)" />
                              <path id="T-2" data-name="T" d="M1369.64,1890.21h5.85l43.35-115.08h44l2.15-5.34h-94.1l-2,5.34h44Z"
                                    transform="translate(-230 -590)" />
                              <path id="R"
                                    d="M1326,1802.3q2.71-15.48-6.52-24t-28.69-8.52h-41.28l-45.32,120.42H1210l15.24-40.6h37.5a99.59,99.59,0,0,0,10.24-.52l16.15,41.12h6.36l-16.34-42q19-3.6,31.26-15.31T1326,1802.3Zm-62.48,42.15h-36.3l26.16-69.32h36.3q17,0,24.88,7.13t5.53,20.39q-3.61,20.47-18.31,31.13t-38.26,10.67Z"
                                    transform="translate(-230 -590)" />
                              <path id="O-2" data-name="O"
                                    d="M1092.57,1890.9a81.13,81.13,0,0,0,39.59-9.9,86.82,86.82,0,0,0,29.8-26.57,89.7,89.7,0,0,0,15.17-36.3q2.58-14.62-1.83-25.72a33.28,33.28,0,0,0-15.55-17.2q-11.13-6.11-28.5-6.11a81.07,81.07,0,0,0-39.59,9.9,87.57,87.57,0,0,0-29.89,26.57,89,89,0,0,0-15.26,36.3q-2.58,14.63,1.83,25.72a33.23,33.23,0,0,0,15.63,17.2q11.22,6.11,28.6,6.11Zm1.45-5.34q-23.73,0-34.44-12t-7.19-31.91a82.26,82.26,0,0,1,14.1-33.63,81.4,81.4,0,0,1,27.37-24.52,73.11,73.11,0,0,1,35.93-9.11q23.73,0,34.37,11.87t7.09,32a82.26,82.26,0,0,1-14.1,33.63,81.29,81.29,0,0,1-27.37,24.52,72.69,72.69,0,0,1-35.76,9.11Z"
                                    transform="translate(-230 -590)" />
                              <path id="P"
                                    d="M982.15,1769.79H941l-45.32,120.42h5.85l15.22-40.43h37.32q25.82,0,42.51-12.3t20.74-35.18q2.76-15.65-6.42-24.08t-28.79-8.43Zm-27.09,74.66h-36.3l26.16-69.32h36.29q16.86,0,24.8,7.13t5.61,20.39q-3.61,20.47-18.3,31.13t-38.26,10.67Z"
                                    transform="translate(-230 -590)" />
                              <path id="S"
                                    d="M803.06,1890.9q20.3,0,34.89-9.12t17.5-25.63q1.53-8.61-2.17-14a23.54,23.54,0,0,0-9.89-8.26,130.42,130.42,0,0,0-16.68-5.93,141.23,141.23,0,0,1-15.44-5.59,22,22,0,0,1-8.82-7.06q-3.32-4.55-2.05-11.78,2.57-13.59,14.87-21.42t29.85-7.82a54.19,54.19,0,0,1,17.25,2.66,37.15,37.15,0,0,1,13.44,7.66l4-4.82a37.46,37.46,0,0,0-14.52-7.91,63.82,63.82,0,0,0-19-2.76q-20.13,0-34.31,9.21A37.14,37.14,0,0,0,794.81,1804q-1.59,9,2.09,14.45a23.15,23.15,0,0,0,9.88,8.35,143.11,143.11,0,0,0,16.82,6.1,137.36,137.36,0,0,1,15.21,5.42,21.6,21.6,0,0,1,8.84,7q3.26,4.47,2,11.53-2.4,13.59-14.84,21.24t-30.51,7.65a53.49,53.49,0,0,1-21.59-4.38q-10.07-4.39-14-11.1l-4.17,4.13q3.91,7.06,14.78,11.78a58.76,58.76,0,0,0,23.77,4.74Z"
                                    transform="translate(-230 -590)" />
                              <path id="N-3" data-name="N"
                                    d="M745.28,1769.79l-41.58,111-43.91-111H655l-45.32,120.42h5.85l41.59-111,43.91,111h4.81l45.32-120.42Z"
                                    transform="translate(-230 -590)" />
                              <path id="A-2" data-name="A"
                                    d="M559.42,1890.21h5.85l-10.42-120.42h-5.67l-101,120.42h6.53l29.44-35.27h72.42ZM488.25,1850l61.7-73.79L556,1850Z"
                                    transform="translate(-230 -590)" />
                              <path id="R-2" data-name="R"
                                    d="M446.25,1802.3q2.73-15.48-6.52-24t-28.68-8.52H369.76l-45.32,120.42h5.85l15.24-40.6H383a99.65,99.65,0,0,0,10.25-.52l16.14,41.12h6.37l-16.34-42q19-3.6,31.25-15.31t15.55-30.62Zm-62.48,42.15H347.48l26.16-69.32h36.29q17,0,24.89,7.13t5.52,20.39q-3.61,20.47-18.3,31.13t-38.27,10.67Z"
                                    transform="translate(-230 -590)" />
                              <path id="T-3" data-name="T" d="M230.69,1890.21h5.85l43.35-115.08h44l2.15-5.34H232l-2,5.34h44Z"
                                    transform="translate(-230 -590)" />
                        </g>
                        <g id="KLMB" style={styleColor}>
                              <path id="B"
                                    d="M2684.38,1481.21a130.4,130.4,0,0,0,27.62-21.6,139.25,139.25,0,0,0,22.63-30.69,128.74,128.74,0,0,0,13.64-40.08q8.59-48.84-25.54-73.58t-102.41-24.74H2342.89l-126.93,109H2586.7q12.51,0,21.4,4.69t6.67,17.23q-3.32,18.78-17.1,26a64.06,64.06,0,0,1-30.08,7.2H2196.85l-90,254.88h368.23q60.12,0,99.85-12.21t64.8-31.94q25.1-19.72,37.28-44.46a187.1,187.1,0,0,0,16.72-50.42q5.4-30.67-5.88-50.1a56.83,56.83,0,0,0-32.63-26.3A137.84,137.84,0,0,0,2684.38,1481.21Zm-150.75,62.94q36.32,0,32.13,23.8-2.88,16.29-15.69,25t-36.61,8.76h-238.6l20.18-57.61Z"
                                    transform="translate(-230 -590)" />
                              <path id="M"
                                    d="M1652.47,1284.77m-77,163.56q1.24,10.67,2.71,21.92t3.46,24.74q2,13.47,5,30.37t6.73,38.2l18.91,105.84q2.88,15.66,7.85,24.74a40.26,40.26,0,0,0,12.23,14.09,40.85,40.85,0,0,0,16.69,6.57,131.55,131.55,0,0,0,21.33,1.57,148.49,148.49,0,0,0,22.2-1.57,80.07,80.07,0,0,0,21.19-6.57,107.46,107.46,0,0,0,22.21-14.09q11.31-9.08,25.35-24.74l93.07-105.21q18.06-20.67,32.51-37t26.64-30.37q12.2-14.08,22.07-25.68t18.75-22.86q-3.91,25.7-6.77,52.61t-6,62.62l-11.32,145.92h129l23.6-368.23q2.05-29.43-12.68-43.53T2031,1283.63q-26.3,0-47.55,12.21a159.19,159.19,0,0,0-39.19,32.26l-109.29,119q-22.47,24.42-39.13,42.59t-29.6,32.87q-12.93,14.73-23.11,26.31t-19,22.85q-3.15-21.27-9.07-51.66t-14.57-73.58l-39-183"
                                    transform="translate(-230 -590)" />
                              <path id="L" d="M988.55,1601.76l110-311.24h-129l-148.4,419h349.73l80.86-107.72Z"
                                    transform="translate(-230 -590)" />
                              <path id="K"
                                    d="M653.91,1571.08q-29.22-33.18-48.15-52t-32.35-31.31q12.36-6.26,24.86-13.15T626.39,1459q15.63-8.76,35.89-20.66t46.82-27.56l202.19-120.24H723.42l-157,102.08q-36.76,23.81-60.67,40.39t-40.29,27.87q6-16.27,13.7-35.07t14.88-38.2l34-97.07H398.4l-148.4,419H380.26l42.5-120.24,19.36-60.12a272.76,272.76,0,0,0,18.22,26.3q11.94,15.66,34.54,43.84l87.66,110.22h196Z"
                                    transform="translate(-230 -590)" />
                        </g>
                        <g id="Figures" style={styleColor}>
                              <path id="Internal_bottom" className="cls-1"
                                    d="M760.54,1952.28H940.91c-113.24,119-599.71,452.62-627.26,1.35,7.49,44.74,25,80.21,53.84,110.38C475.76,2177.3,698.37,2024.94,760.54,1952.28Z"
                                    transform="translate(-230 -590)"
                              />
                              <path id="External_bottom" style={{ fill: " #d44d5c" }}
                                    d="M1031.2,1952.15c62.36.45,120.31-.31,182.68.13C782.09,2424,281.17,2610.33,269.23,2135.35c-1.16-46,6.24-105.12,16.15-141.34,3.19,63.34,12.13,121.46,41.73,166.91C488.72,2409.09,933.93,2055.11,1031.2,1952.15Z"
                                    transform="translate(-230 -590)" />
                              <path id="Internal_top"
                                    d="M1484.65,1154.07c-8.83,28.27,194.88-650.93-358.18-408.85-66.64,29.16-199.39,113.85-201.78,115.41,7.72-7.94,31.87-26.86,52.86-44.18C1295.85,573.05,1703.15,496.25,1484.65,1154.07Z"
                                    transform="translate(-230 -590)" />
                              <path id="External_top" style={{ fill: " #d44d5c" }}
                                    d="M1506.19,590.07c517.9-8.55,115.71,794.65,13.46,958q-53.16,80.73-106.34,161.46H1250.44c46.14-63.46,260.24-309.72,356.71-696.94,51.91-208.39,27.55-384.69-158.84-414.4C1467.81,593.08,1486,590.08,1506.19,590.07Z"
                                    transform="translate(-230 -590)" />
                        </g>
                  </svg>
            </div>
      )
}

export default LogoViewComponent;