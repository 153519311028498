import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField } from "@mui/material";
import { IUserInfoResponseDto, IUserMetadata, UserPersonalDataRequestDto, UserRegistrationResponseDto, UserStepRequestDto } from "../../models/IUser";
import AddressComponent from "../MapModule/AddressComponent";
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import { realNA, realStringInfoCoverFace } from "../../helpers/textHelper";
import { dateToYMD, timeDiference, toTimestamp } from "../../helpers/dateHelper";
import { compareValue } from "../../helpers/generalHelper";
import StafService from "../../services/StafService";
import ControlStateInCRUDComponent from "../CustomComponentModule/ControlStateInCRUDComponent";
import EditColorComponent from "../CustomComponentModule/EditColorComponent/EditColorComponent";
import { AddressRequestDto, AddressResponseDto } from "../../models/AddressModels";
import AddressService from "../../services/AddressService";

interface IProps {
  user: IUserInfoResponseDto | null;
  userMetadata: IUserMetadata | null;
  userRegisterData: UserRegistrationResponseDto | null;
  refreshAfretSave: (us: IUserInfoResponseDto) => void;
  snackMsg: (text: string) => void;
  handleStep: (step: number) => void;
}

const EditPersonalInfoUserComponent = (props: IProps) => {

  const { t } = useTranslation();
  const { user, userMetadata, userRegisterData } = props;

  const [error, setError] = useState<number>(0);
  const [errors, setErrors] = useState<boolean[]>([false, false]);
  const [textErrors, setTextErrors] = useState<Array<string>>([])
  const [colorError, setColorError] = useState(false);

  const [save, setSave] = useState<boolean>(false);
  const [description, setDescription] = useState<string>('');
  const [age, setAge] = useState<string>('27 years 2 moths');
  const [address, setAddress] = useState<AddressResponseDto | null>(null);
  const [brithday, setBirthday] = useState<string>('');
  const [color, setColor] = useState('#000000');

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => { setOpen(true); };

  const handleClose = () => {
    setOpen(false);
    setAddress(userRegisterData ? userRegisterData.address : null);
  };

  const handlerSaveUser = () => { setSave(true); }
  const hendleBirthday = (event: React.ChangeEvent<HTMLInputElement>) => { setBirthday(event.target.value); }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length <= 255) { setDescription(event.target.value); }
  };

  const handleAddress = (address: AddressResponseDto) => {
    setAddress(address);
  }

  const handleActivateAddress = () => {
    if (address) {
      (async () => {
        const { uuid, fullAddress, ...rest } = address;
        const request: AddressRequestDto = rest;
        const [, data] = await AddressService.getOrCreateAddress(request);
        if (data) {
          const response: AddressResponseDto = data as AddressResponseDto;
          setAddress(response);
          setOpen(false);
        }
      })();
    }
  }

  const disableAddressApplyButton = (): boolean => {
    const userRegisterDataAddressId = userRegisterData?.address ? userRegisterData.address.uuid : null;
    return !address || address.zipcode.length < 4 || address.uuid === userRegisterDataAddressId;
  }

  useEffect(() => {
    if (save) {
      (async () => {
        let body: UserPersonalDataRequestDto;
        if (user) {
          body = {
            uuid: user.uuid,
            birthday: brithday ? toTimestamp(brithday) : 0,
            color: color,
            description: description,
            addressUuid: address ? address.uuid : '',
          }
          const [, data] = await StafService.saveUserpersonalData(body);
          if (data) {
            if (!userMetadata?.completed) {
              const [, response] = await StafService.getUserMetadata(user.uuid);
              if (response) {
                if (response.data.body.step !== 6) {
                  let bd: UserStepRequestDto = {
                    uuid: user.uuid,
                    step: 3
                  }
                  await StafService.saveUserStep(bd);
                } else { props.handleStep(5) }
              }
            }

            setSave(false); setError(0);
            props.refreshAfretSave(user);
            let snackMessText = ((realStringInfoCoverFace(user?.firstName) === '') || (realStringInfoCoverFace(user?.firstName) === ''))
              ? `${user.email}`
              : `${user.firstName} ${user.lastName}`
            props.snackMsg('Personal data was successfully saved for ' + snackMessText + '.')
          }
        }
      })();
    }
    setSave(false);
  }, [save])

  const handleChengeColor = (s: string) => {
    setColor(s);
  }

  const handlerReset = () => {
    if (user) { props.refreshAfretSave(user); }
  }

  useEffect(() => {
    if (userRegisterData) {
      setAddress(userRegisterData.address);
      setDescription(realNA(userRegisterData?.description ? userRegisterData?.description : ""));
      setAge(timeDiference(userRegisterData?.birthday ? userRegisterData?.birthday : 0, Date.now()))
      setBirthday(dateToYMD(userRegisterData?.birthday ? userRegisterData?.birthday : 0))
      setColor(userRegisterData?.color === 'N/A' ? '#000000' : userRegisterData?.color ? userRegisterData?.color : '');
    }
  }, [userRegisterData]);

  useEffect(() => {
    setAge(timeDiference(toTimestamp(brithday), Date.now()))
    let er: boolean = false;
    let errs: boolean[] = [...errors];
    let md: boolean = false;
    let ter: Array<string> = [];

    if (colorError) {
      er = true;
      if (errs[0] !== undefined) { errs[0] = true; } else { errs[0] = false };
      ter.push("* The color selected from the color palette must be unique. The default color is black")
    }

    if (toTimestamp(brithday) > Date.now()) {
      er = true
      ter.push("* Birthday couldn't be more today");
      if (errs[1] !== undefined) { errs[1] = true; } else { errs[1] = false; }
    } else {
      errs[1] = false;
    }

    if (!address) {
      er = true
      ter.push("* Address is requared");
    }

    if (user && !er) {
      const userRegisterDataFullAddress = userRegisterData?.address ? userRegisterData?.address.fullAddress : null;
      if (address && address.uuid && !compareValue(userRegisterDataFullAddress, address.fullAddress)) { md = true; }
      if (!compareValue(realNA(userRegisterData?.description ? userRegisterData?.description : ""), description)) { md = true; }
      if (brithday !== dateToYMD(userRegisterData?.birthday ? userRegisterData?.birthday : 0)) { md = true; }
      if (!compareValue(userRegisterData?.color, color) && (color !== '#000000')) { md = true; }
    }

    setTextErrors(ter);
    if (er) { setError(-1) }
    if (!er) { setErrors([false, false]) } else { setErrors(errs) }
    if ((!er) && (md)) setError(1)
    if ((!er) && (!md)) setError(0)
  }, [brithday, description, address, colorError, color])

  useEffect(() => {
    if (color === '#000000') {
      setColorError(true);
    } else {
      if (color === userRegisterData?.color) {
        setColorError(false)
      } else {
        (async () => {
          if (user) {
            const [, data] = await StafService.isValidUserColor(color);
            if (data !== null) {
              setColorError(!data);
            }
          }
        })()
      }
    }
  }, [color])

  return (
    <>
      <div style={{ flex: "1 1 auto", display: "flex", gap: 10, justifyContent: "space-between", width: "625px" }}>
        <div style={{ width: "240px", display: "flex", flexDirection: "column", gap: "20px", marginTop: "5px" }}>
          {address && address.fullAddress ?
            <TextField
              label="Address"
              disabled
              multiline
              rows={2}
              value={address.fullAddress.replaceAll('**', ' ') || ''}
              inputProps={{ sx: { fontSize: "11px", padding: "1px", lineHeight: "1.5" } }}
              InputLabelProps={{ sx: { fontSize: "17px" } }}
            /> : <div style={{ marginTop: "-6px" }}> </div>}

          <Button variant={'contained'} onClick={handleClickOpen} color={address ? 'info' : 'error'}>
            <AddLocationAltIcon /> Address
          </Button>

          <Divider />
          <div style={{ display: "flex", gap: "30px", alignItems: "start", justifyContent: "" }}>
            <EditColorComponent
              currentColor={color}
              getColor={handleChengeColor}
            ></EditColorComponent>

            <TextField
              error={colorError}
              disabled
              id="standard-basic"
              label="Color"
              value={color || ''}
              style={{ width: "200px" }}
              inputProps={{ sx: { fontSize: "11px" } }}
              InputLabelProps={{ sx: { fontSize: "17px" } }}
              helperText={(colorError) ? "* invalid color ..." : ''}
            />
          </div>
        </div>

        <div style={{
          flex: "1 1 auto",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          marginLeft: "15px",
          marginTop: "7px",
          paddingLeft: "23px",
          justifyContent: "center",
          borderLeft: "1px dashed silver"
        }}>
          <div style={{ display: "flex", gap: "15px", marginBottom: "4px" }}>
            <TextField
              error={errors[1]}
              label={t("Birthday")}
              value={brithday || ''}
              onChange={hendleBirthday}
              type="date"
              InputLabelProps={{ shrink: true }}
              inputProps={{ sx: { fontSize: "11px" } }}
            />
            <TextField
              disabled
              id="standard-basic"
              label="Age"
              value={age}
              inputProps={{ sx: { fontSize: "11px" } }}
              InputLabelProps={{ sx: { fontSize: "17px" } }}
            />
          </div>
          <div style={{ marginBottom: "4px" }}> <Divider /> </div>
          <TextField
            label="Short description"
            value={description || ''}
            onChange={handleChange}
            multiline
            rows={7}
            inputProps={{ sx: { fontSize: "12px", padding: "1px", lineHeight: "1.5" } }}
            InputLabelProps={{ sx: { fontSize: "17px" } }}
            helperText={description.length > 0 ? (255 - description.length) + t(' characters available') : ''}
          />
        </div>

        <div style={{ display: "flex", minWidth: "53px" }} >
          <ControlStateInCRUDComponent
            error={error}
            xObject={user}
            textErrors={textErrors}
            handlerReset={handlerReset}
            handlerSave={handlerSaveUser}
          />
        </div>
      </div>

      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={open}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle></DialogTitle>
        <DialogContent>
          <AddressComponent current={address ? address : null} getPlace={handleAddress} />
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px", marginRight: "20px" }}>
          <Button variant={'contained'} color={'info'}
            onClick={handleActivateAddress} disabled={disableAddressApplyButton()}> Apply/Select
          </Button>
          <Button variant={'contained'} color={'warning'} onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default EditPersonalInfoUserComponent;