import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Divider, Box, ListItem, ListItemIcon, ListItemButton, Checkbox, ListItemText, List } from '@mui/material';
import { ELoadStatus } from "../../models/LoadModels";

interface IProps {
  getList: (list: string[]) => void
  list?: string[]
}

export default function LoadFilterStatusComponent(props: IProps) {

  const { t } = useTranslation();

  const [checked, setChecked] = useState<number[]>([]);
  const [item, setItem] = useState<string[]>([]);
  const [types, setTypes] = useState<string[]>([]);
  const [completelist, setCompletelist] = useState<boolean>(false);

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    const newItem = [...item];

    if (currentIndex === -1) {
      newChecked.push(value);
      newItem.push(types[value]);
    } else {
      newChecked.splice(currentIndex, 1);
      newItem.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    setItem(newItem);
    props.getList(newItem);
  };

  useEffect(() => {
    let tab: string[] = []
    for (let el of Object.keys(ELoadStatus)) { tab.push(el) }
    tab = [...tab];
    setTypes(tab);
  }, [])

  useEffect(() => {
    let ch: number[] = [];
    let it: string[] = [];
    if (props.list) {
      props.list.map(e => {
        let i = types.findIndex(el => el === e);
        it.push(e);
        ch.push(i);
      })
    }
    setChecked(ch);
    setItem(it);
  }, [completelist, props.list])

  return (
    <>
      <Divider sx={{ paddingTop: "5px", fontSize: "16px" }} textAlign="left">{t('STATUS')}</Divider>
      <Box sx={{ display: "flex", justifyContent: "center", minWidth: "100%" }}>
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
          {
            types.map((value, i) => {
              const labelId = `checkbox-list-label-${value}`;

              return (
                <ListItem dense
                  key={value}
                  disablePadding
                >
                  <ListItemButton role={undefined} onClick={handleToggle(i)} dense>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={checked.indexOf(i) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={`${value.split('_').join(' ')}`} />
                  </ListItemButton>
                </ListItem>
              );
            })}
        </List>
      </Box>
    </>
  );
}