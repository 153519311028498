import { useTranslation } from "react-i18next";
import { Box, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import StarsIcon from '@mui/icons-material/Stars';
import EmailIcon from '@mui/icons-material/Email';
import DownloadIcon from '@mui/icons-material/Download';
import { AppDispatch, RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { modifyRightFlip } from "../../store/reducers/cnfSlice";
import { useNavigate } from "react-router";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {  useRef } from "react";
import { ECustomerReputationLevel, ICustomerDetails } from "../../models/CustomerModels";
import { addFavoritesCustomers } from "../../store/reducers/favoritesSlice";
import VIPViewComponent from "../CustomComponentModule/VIPViewComponent";
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import GenericCRUDService from "../../services/GenericCRUDService";

const styled = {
  container: { display: "flex", gap: "20px" },
  leftPart: { width: "200px", padding: "0px 10px 50px 10px", height: "1060px" },
  centerPart: { width: "100%", padding: "20px 10px 50px 10px" },
  rigthPart: { width: "450px", padding: "5px 10px 50px 10px" },
  item: { width: "40px" },
  box: { border: "1px solid silver", textAlign: "center", borderRadius: "3px" },
  icon: { fontSize: "28px", cursor: "pointer" },
  button: { border: "1px solid silver" },
  info: {},
  rows: { display: "flex", gap: "2px" },
  boxis: { width: "200px", padding: "4px", fontSize: "12px", overflow: "hidden" },
}

interface IPropsCoverFaceUserComponent {
  customer: ICustomerDetails | null
}
const CoverFaceCustomerComponent = (
  props: IPropsCoverFaceUserComponent
) => {

  const { t } = useTranslation();
  const { hints } = useSelector((state: RootState) => state.cnfSlice.interfaceState);
  const componentRef = useRef<HTMLDivElement | null>(null);
  const { auth } = useSelector((state: RootState) => state.cnfSlice);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleClose = () => {
    dispatch(modifyRightFlip(false));
    navigate('/customer');
  }

  const handlerAddToFavorite = () => {
    if (props.customer) {
      let id = props.customer.uuid
      dispatch(addFavoritesCustomers([id]));
    }
  }

  const hendlerExcel = () => {
    let body = {
      criteria: [
        {
          property: "uuid",
          value: props.customer?.uuid,
          expression: "EQUALS"
        }
      ]
    }

    GenericCRUDService.downloadReport('customer/report', body)
  }

  const handlerCreateEmail = () => {
    let url = 'https://mail.google.com/mail/?view=cm&fs=1';
    let to = '&to=' + props.customer?.email;
    let bcc = '&bcc=';
    if (auth) {
      bcc += auth.email
    }
    window.open(url + to + bcc);
  }

  return (
    <>
      <Box sx={{ padding: 2, marginTop: "1", display: "flex" }}>
        <div style={{ display: "flex", width: "300px", flexDirection: "row", marginLeft: "15px", alignItems: "center" }}>

          {props.customer?.reputationLevel === ECustomerReputationLevel.VIP &&
            <Paper elevation={0} sx={{ minWidth: "50px", height: "50px", display: "flex", justifyContent: "center", alignItems: "center", marginLeft: "-10px", marginRight: "10px" }}>
              <Box sx={{ paddingLeft: "10px", paddingTop: "10px" }}>
                <VIPViewComponent width={40} heigth={40} />
              </Box>
            </Paper>}

          {props.customer?.reputationLevel === ECustomerReputationLevel.POOR &&
            <Paper elevation={0} sx={{ minWidth: "50px", height: "50px", display: "flex", justifyContent: "center", alignItems: "center", marginLeft: "-10px", marginRight: "10px" }}>
              <Box sx={{ paddingLeft: "10px", paddingTop: "10px" }}>
                <ErrorOutlineIcon color="error" fontSize="large" />
              </Box>
            </Paper>}
          {props.customer?.reputationLevel === ECustomerReputationLevel.STANDARD &&
            <Paper elevation={0} sx={{ minWidth: "50px", height: "50px", display: "flex", justifyContent: "center", alignItems: "center", marginLeft: "-10px", marginRight: "10px" }}>
              <Box sx={{ paddingLeft: "10px", paddingTop: "10px" }}>
                <VerifiedUserOutlinedIcon color="success" fontSize="large" />
              </Box>
            </Paper>}

          <Box style={styled.rows}>
            <Typography variant="subtitle1" align="left" >
              <b>{(props.customer?.name)?.toLocaleUpperCase()}</b>
            </Typography>
          </Box>
        </div>

        <div style={{ display: "flex", alignItems: "center", marginLeft: "40px" }}>
          <div style={{ marginLeft: "70px", paddingLeft: "10px", display: "flex", gap: "10px", height: "40px", borderLeft: "1px dashed silver", justifyContent: "space-between", width: "350px" }}>
            {!props.customer?.metadata.completed ?
              <Typography variant='subtitle2' sx={{ padding: "3px", paddingTop: "9px" }} color='red'>INCOMPLETE CUSTOMER</Typography>
              : <div>

                <Tooltip
                  title={(hints) ? t("Add customer to favorites") : ''}
                  placement={"top"}
                  arrow={true}
                >
                  <IconButton onClick={handlerAddToFavorite}>
                    <StarsIcon style={styled.icon} />
                  </IconButton>
                </Tooltip>

                <Tooltip
                  title={(hints) ? t("Send email to selected customer") : ''}
                  placement={"top"}
                  arrow={true}
                >
                  <IconButton
                    onClick={handlerCreateEmail}
                  >
                    <EmailIcon style={styled.icon} /></IconButton>
                </Tooltip>

                <Tooltip
                  title={(hints) ? t("Generate PDF report for selected customer") : ''}
                  placement={"top"}
                  arrow={true}
                >
                  <IconButton disabled><PictureAsPdfIcon style={styled.icon} /></IconButton>
                </Tooltip>

                <Tooltip
                  title={(hints) ? t("Download XLS report for selected customer") : ''}
                  placement={"top"}
                  arrow={true}
                >
                  <IconButton
                    onClick={hendlerExcel}
                  >
                    <DownloadIcon style={styled.icon} />
                  </IconButton>
                </Tooltip>

              </div>}

            <div style={{ width: "0px", height: "0px", padding: 0, margin: 0, overflow: "hidden" }}>

              <div ref={componentRef}>
                {/* <ReportUser /> */}
              </div>
            </div>
            <Tooltip
              title={(hints) ? t("Close and return to the list of customers") : ''}
              placement={"top"}
              arrow={true}
            >
              <IconButton onClick={handleClose} >
                <HighlightOffIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </Box>
    </>
  )
}

export default CoverFaceCustomerComponent;