import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import PermissionService from "../../services/PermissionsService";
import ControlStateInCRUDComponent from "../CustomComponentModule/ControlStateInCRUDComponent";
import PrivilegeTable from "./PrivilegesTable";

interface ItemOptionType {
    uuid?: string,
    name: string,
    inputValue?: string
}

interface IProps {
    snackMsg: (text: string) => void,
}
const ChoosePermissionForRoleCrud = (props: IProps) => {

    const [value, setValue] = React.useState<ItemOptionType | null>(null);
    const [listItem, setListItem] = React.useState<ItemOptionType[]>([]);



    const [error, setError] = React.useState<number>(-1)
    const [reset, setReset] = React.useState<boolean>(false);
    const [save, setSave] = React.useState<boolean>(false);
    const [search, setSeacrh] = React.useState<string>("");



    React.useEffect(() => {
        (async () => {
            const [error, data] = await PermissionService.getAllRole();
            if (data) {
                let arr = data.data.body as ItemOptionType[];
                setValue(null);
                setListItem(arr);

            }
            if (error) { }
        })()
    }, [])


    const handleResetSeacrhBar = () => {
        setSeacrh("");
    }

    const handleSearch = (e: any) => {
        let s: string = e.target.value as string;
        setSeacrh(s);
    }
    const handleGetState = (n: number) => {
        setError(n)
    }
    const handleReset = () => {
        if (value) {
            if (error === -1) {
                setValue(null);
                setReset(true);
            } else {
                setReset(true);
            }
        }

    }

    const handleGetSave = (b: boolean) => {
        setSave(b);
    }

    const getSnackMess = (s: string) => {
        props.snackMsg(s)
    }
    const getReset = (b: boolean) => {
        setReset(false);
    }
    return (
        <>
            <div style={{ display: "flex", flexDirection: "row", marginTop: "5px", marginBottom: "10px", width: "650px", height: "500px" }}>
                <div style={{ display: "flex", flexDirection: "column", marginTop: "5px", marginBottom: "10px", maxWidth: "750px" }}>


                    <Autocomplete
                        value={value}
                        onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {
                                setValue({
                                    name: newValue,
                                });
                            } else if (newValue && newValue.inputValue) {
                                setValue({
                                    name: newValue.inputValue,
                                });
                            } else {
                                setValue(newValue);
                            }
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        options={listItem}
                        getOptionLabel={(option) => {
                            if (typeof option === 'string') {
                                return option;
                            }

                            if (option.inputValue) {
                                return option.inputValue;
                            }

                            return option.name;
                        }}
                        renderOption={(props, option) => <li {...props}>{option.name}</li>}
                        sx={{ width: 600 }}
                        freeSolo
                        renderInput={(params) => (
                            <TextField {...params} label="SELECT ROLE" />
                        )}
                    />

                    <PrivilegeTable name={(value?.name) ? value?.name : ""} snackMess={getSnackMess} sentReset={getReset} reset={reset} getSave={handleGetSave} save={save} getState={handleGetState}  uuid={value?.uuid ? value.uuid : ""} />
                </div>
                {error !== 0 &&
                    <div style={{ display: "flex", minWidth: "53px" }} >
                        <ControlStateInCRUDComponent
                            error={error}
                            textErrors={[(value?.uuid) ? "* Check privileges for selected role" : "* Choose any role"]}
                            handlerReset={handleReset}
                            disabledReset={(error === -1)}
                            handlerSave={() => { setSave(true) }}
                        />
                    </div>}
            </div>

        </>
    )
}; export default ChoosePermissionForRoleCrud;