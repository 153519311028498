import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { EStatusStafServer, IUserInfoResponseDto, IUserMetadata, UserStatusRequestDto, UserStatusResponseDto, UserStepRequestDto, UserUpdateRequestDto } from "../../models/IUser";
import { TRoles } from "../../models/IStaf";

import { dateToYMD, toTimestamp } from "../../helpers/dateHelper";
import { compareValue } from "../../helpers/generalHelper";
import StafService from "../../services/StafService";
import ControlStateInCRUDComponent from "../CustomComponentModule/ControlStateInCRUDComponent";
import { useDispatch } from "react-redux";
import { modifyStafRefresh } from "../../store/reducers/cnfSlice";
import { realStringInfoCoverFace } from "../../helpers/textHelper";

interface IPropsRoleAndSrtaus {
  allRole: TRoles,
  userMetadata: IUserMetadata | null,
  user: IUserInfoResponseDto | null,
  userStatus: UserStatusResponseDto | null,
  refreshAfretSave: (us: IUserInfoResponseDto) => void;
  snackMsg: (text: string) => void
}

const EditStatusUserComponent = (props: IPropsRoleAndSrtaus) => {

  const { t } = useTranslation();
  const { allRole, user, userStatus, userMetadata } = props;
  const [reqBody, setReqBody] = useState<UserUpdateRequestDto>();

  const [role, setRole] = useState<string>((): string => {
    if (user?.role) {
      return user.role.name
    } else return '';
  });

  const [enabledRole, setEnabledRole] = useState(false);
  const dispatch = useDispatch();

  const [status, setStatus] = useState<string>(() => (userStatus?.status) ? userStatus?.status : "");
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [error, setError] = useState<number>(0);
  const [errors, setErrors] = useState<Array<boolean>>([false, false, false]);
  const [textErrors, setTextErrors] = useState<Array<string>>([])
  const [save, setSave] = useState<boolean>(false);

  const handleChange = (event: any) => {
    setRole(event.target.value as string);
  };

  const handleChangeStatus = (event: any) => {
    setStatus(event.target.value as string);
  };

  const handleChangeStartDate = (event: any) => {
    let value: string = event.target.value as string;
    setStartDate(value);

  }

  const handleChangeEndtDate = (event: any) => {
    let value: string = event.target.value as string;
    setEndDate(value);
  }

  const handlerSaveUser = () => { setSave(true); }

  const getUUIDRole = (s: string) => {
    let uuid = allRole?.filter(e => e.name === s)[0].uuid;
    return uuid ? uuid : "";
  }

  useEffect(() => {

    if (save) {
      (async () => {
        let body: UserStatusRequestDto;
        if (user) {
          body = {
            uuid: user?.uuid,
            status: status,
            activeFromDate: toTimestamp(startDate),
            activeToDate: toTimestamp(endDate)
          }

          const [error, data] = await StafService.saveUserSatus(body);
          if (data) {

            if (!userMetadata?.completed) {
              let bd: UserStepRequestDto = {
                uuid: user.uuid,
                step: 6
              }
              const [err, dt] = await StafService.saveUserStep(bd);
              if (dt) {
                props.refreshAfretSave(user);


                dispatch(modifyStafRefresh());
              }
              if (err) { }
            } else {
              props.refreshAfretSave(user);

              dispatch(modifyStafRefresh());
            }
            let snackMessText = ((realStringInfoCoverFace(user?.firstName) === '') || (realStringInfoCoverFace(user?.firstName) === ''))
              ? `${user.email}`
              : `${user.firstName} ${user.lastName}`
            props.snackMsg('Status was successfully saved for ' + snackMessText + '.');
          }
          if (error) {
            setSave(false);
          }
        }
      })()
    }
    setSave(false);
  }, [save])

  useEffect(() => {
    setSave(false);
    if (userStatus && user) {
      if (!user.role) { setEnabledRole(true) }
      setRole((): string => {
        if (user?.role) {
          return user.role.name
        } else return '';
      });
      setStatus({ ...userStatus }?.status)
      setStartDate(dateToYMD(userStatus.activeFromDate ? userStatus.activeFromDate : 0))
      setEndDate(dateToYMD(userStatus.activeToDate ? userStatus.activeToDate : 0))
      setError(0);
    }
  }, [userStatus, user]);

  const handlerReset = () => {
    if (user) {
      props.refreshAfretSave(user);
    }
  }


  useEffect(() => {

    let er: boolean = false;
    let md: boolean = false;
    let ter: string[] = [];
    let errs: boolean[] = [];
    if (userStatus) {
      if (!compareValue(status, userStatus?.status)) {
        md = true;
      }
    }

    let va1: number = (startDate) ? toTimestamp(startDate) : 0;
    let vb1: number = (endDate) ? toTimestamp(endDate) : 0;


    let va2: number = 0;
    let vb2: number = 0;

    if (status === 'TEMPORARY') {

      if (userStatus) {
        va2 = userStatus.activeFromDate ? userStatus.activeFromDate : 0;
        vb2 = userStatus.activeToDate ? userStatus.activeToDate : 0;
      }

      if ((va1 === 0) || (vb1 === 0)) { er = true; ter.push('* start and expiration is requared') };
      if (vb1 - va1 < 0) { er = true; ter.push('* the start must be less than the expiration') }

      if ((!er) && ((dateToYMD(va1) !== dateToYMD(va2)) || dateToYMD(vb1) !== dateToYMD(vb2))) { md = true }
      if ((!er) && ((dateToYMD(va1) !== dateToYMD(va2)) || dateToYMD(vb1) !== dateToYMD(vb2))) { md = true }
    }

    if (role === '') { er = true; ter.push('* role is requared') }
    if (role !== user?.role?.name) { md = true }


    setTextErrors(ter);
    if (er) {
      setError(-1);
      errs = [(va1 === 0) && status === 'TEMPORARY', (vb1 === 0) && status === 'TEMPORARY', role === ''];
      setErrors(errs);
    }
    if (!er) setErrors([false, false, false]);
    if ((!er) && (md)) setError(1)
    if ((!er) && (!md)) setError(0)

  }, [role, status, startDate, endDate])
  /** --------- */
  return (
    <>
      <div style={{ flex: "1 1 auto", display: "flex", gap: 10, width: "620px", padding: 0 }}>
        {<div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "30px",
            marginLeft: "14px",
            justifyContent: "center",
            paddingTop: "10px"
          }}
        >
          {userMetadata?.completed &&
            <ToggleButtonGroup
              color="primary"
              value={status}
              exclusive
              onChange={handleChangeStatus}
            >
              <ToggleButton style={{ width: "90px", fontSize: "12px" }} value={EStatusStafServer.ACTIVE} disabled={enabledRole}>{EStatusStafServer.ACTIVE}</ToggleButton>
              <ToggleButton style={{ width: "90px", fontSize: "12px" }} value={EStatusStafServer.TEMPORARY}>{EStatusStafServer.TEMPORARY}</ToggleButton>
              <ToggleButton style={{ width: "90px", fontSize: "12px" }} value={EStatusStafServer.INACTIVE}>{EStatusStafServer.INACTIVE}</ToggleButton>
            </ToggleButtonGroup>
          }
          {userMetadata?.completed &&
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <TextField
                error={errors[0]}
                disabled={!["TEMPORARY"].includes(status)}
                size="small"
                label={t("Start")}
                type="date"
                value={startDate}
                onChange={handleChangeStartDate}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ sx: { fontSize: "11px" } }}
              />
              <TextField
                error={errors[1]}
                disabled={!["TEMPORARY"].includes(status)}
                size="small"
                value={endDate}
                onChange={handleChangeEndtDate}
                label={t("Expiration")}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ sx: { fontSize: "11px" } }}
              />
            </div>

          }
        </div>
        }
        <div style={{ display: "flex", minWidth: "320px" }} >
          <ControlStateInCRUDComponent
            error={error}
            xObject={user}
            textErrors={textErrors}
            handlerReset={handlerReset}
            handlerSave={handlerSaveUser}
          />
        </div>

      </div>
      <br />
      <div style={{ display: "flex" }}>
      </div>
    </>
  )
}

export default EditStatusUserComponent;