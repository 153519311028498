import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { modifyLeftFlip, modifyPresentFlip, modifyPresentMenu, modifyRightFlip } from '../../store/reducers/cnfSlice';
import { RootState } from '../../store/store';
import { menuContent } from './HeadMenuComponent';

export default function ShortCutControlComponent() {

    const dispatch = useDispatch();
    const { auth } = useSelector((state: RootState) => state.cnfSlice);
    const navigate = useNavigate()

    const [timeST, setTimeST] = useState(0);


    const getMSH = (key: string) => {
        return menuContent.filter((e) => e.short === key)[0];
    }

    useEffect(() => {
        document.addEventListener("keydown", (e) => {
            if (auth) {
                let key = '';
                if (e.ctrlKey) key += 'Ctrl+';
                if (key === 'Ctrl+') {
                    let ob = getMSH(e.key);
                    if (ob) {
                        if (ob.to === '/') { navigate('/'); } else { navigate('/' + ob.to); }
                    }
                }
                if (e.shiftKey) key += 'Shift+';
                key += e.key;

                const tm = Math.floor(Date.now() / 1000);
                if (tm - timeST > 500) {
                    setTimeST(tm);
                    if (key.toUpperCase() === 'CTRL+SHIFT+A') {
                        dispatch(modifyPresentMenu())
                    }
                    if (key.toUpperCase() === 'CTRL+SHIFT+B') {
                        dispatch(modifyPresentFlip())
                    }
                    if (key.toUpperCase() === 'CTRL+SHIFT+L') {                      
                        dispatch(modifyLeftFlip());
                    }

                    if (key.toUpperCase() === 'CTRL+SHIFT+R') {
                        dispatch(modifyRightFlip(false))
                    }

                    if (key.toUpperCase() === 'CTRL+SHIFT+N') {
                        navigate('/notification');
                    }
                }
            }
        })
    }, [auth])

    return (<></>);
}