import { Typography } from '@mui/material';
import { IVehicleDetails } from '../../../models/TransportModels';
import styles from './transport.module.scss'
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import SCViewList from '../../../styledComponents/sharedComponent/SCViewList';

interface IProps {
    vehicle: IVehicleDetails | null;
}

export default function VeivVehicleTableComponent(props: IProps) {
    const { vehicle } = props;
    const { measureSize, measureMass } = useSelector((state: RootState) => state.cnfSlice.globalPreferenceState);

    return (
        <div>
            <table style={{ width: "100%" }}>
                <tbody>
                    <tr>
                        <td className={styles.celllabell}>
                            <Typography color={'silver'} variant='subtitle2' style={{ fontSize: "10px" }} >
                                BRAND
                            </Typography>
                        </td>
                        <td className={styles.cellValls}>
                            <Typography variant='subtitle2' style={{ fontSize: "10px" }}> {vehicle?.brand.name} </Typography>
                        </td>
                    </tr>

                    <tr>
                        <td className={styles.celllabell}>
                            <Typography color={'silver'} variant='subtitle2' style={{ fontSize: "10px" }} >
                                MODEL
                            </Typography>
                        </td>
                        <td className={styles.cellValls}>
                            <Typography variant='subtitle2' style={{ fontSize: "10px" }}> {vehicle?.model} </Typography>
                        </td>
                    </tr>

                    <tr>
                        <td className={styles.celllabell}>
                            <Typography color={'silver'} variant='subtitle2' style={{ fontSize: "10px" }} >
                                YEAR
                            </Typography>
                        </td>
                        <td className={styles.cellValls}>
                            <Typography variant='subtitle2' style={{ fontSize: "10px" }}> {vehicle?.year} </Typography>

                        </td>
                    </tr>

                    <tr>
                        <td className={styles.celllabell}>
                            <Typography color={'silver'} variant='subtitle2' style={{ fontSize: "10px" }} >
                                LENGTH
                            </Typography>
                        </td>
                        <td className={styles.cellValls}>
                            <SCViewList spaceBetween>
                                <Typography variant='subtitle2' style={{ fontSize: "10px" }}> {vehicle?.length} </Typography>
                                <Typography variant='subtitle2' color={'silver'} style={{ fontSize: "10px" }}> {measureSize.toLocaleUpperCase()} </Typography>
                            </SCViewList>
                        </td>
                    </tr>

                    <tr>
                        <td className={styles.celllabell}>
                            <Typography color={'silver'} variant='subtitle2' style={{ fontSize: "10px" }} >
                                WIDTH
                            </Typography>
                        </td>
                        <td className={styles.cellValls}>
                            <SCViewList spaceBetween>
                                <Typography variant='subtitle2' style={{ fontSize: "10px" }}> {vehicle?.width} </Typography>
                                <Typography variant='subtitle2' color={'silver'} style={{ fontSize: "10px" }}> {measureSize.toLocaleUpperCase()} </Typography>
                            </SCViewList>
                        </td>
                    </tr>

                    <tr>
                        <td className={styles.celllabell}>
                            <Typography color={'silver'} variant='subtitle2' style={{ fontSize: "10px" }} >
                                HEIGHT
                            </Typography>
                        </td>
                        <td className={styles.cellValls}>
                            <SCViewList spaceBetween>
                                <Typography variant='subtitle2' style={{ fontSize: "10px" }}> {vehicle?.height} </Typography>
                                <Typography variant='subtitle2' color={'silver'} style={{ fontSize: "10px" }}> {measureSize.toLocaleUpperCase()} </Typography>
                            </SCViewList>

                        </td>
                    </tr>

                    <tr>
                        <td className={styles.celllabell}>
                            <Typography color={'silver'} variant='subtitle2' style={{ fontSize: "10px" }} >
                                WEIGHT
                            </Typography>
                        </td>
                        <td className={styles.cellValls}>
                            <SCViewList spaceBetween>

                                <Typography variant='subtitle2' style={{ fontSize: "10px" }}> {vehicle?.weight} </Typography>
                                <Typography variant='subtitle2' color={'silver'} style={{ fontSize: "10px" }}> {measureMass.toLocaleUpperCase()} </Typography>
                            </SCViewList>
                        </td>
                    </tr>

                    <tr>
                        <td className={styles.celllabell}>
                            <Typography color={'silver'} variant='subtitle2' style={{ fontSize: "10px" }} >
                                LOADING WEIGHT
                            </Typography>
                        </td>
                        <td className={styles.cellValls}>
                            <SCViewList spaceBetween>

                                <Typography variant='subtitle2' style={{ fontSize: "10px" }}> {vehicle?.loadingWeight} </Typography>
                                <Typography variant='subtitle2' color={'silver'} style={{ fontSize: "10px" }}> {measureMass.toLocaleUpperCase()} </Typography>
                            </SCViewList>
                        </td>
                    </tr>

                    <tr>
                        <td className={styles.celllabell}>
                            <Typography color={'silver'} variant='subtitle2' style={{ fontSize: "10px" }} >
                                GROSS WEIGHT
                            </Typography>
                        </td>
                        <td className={styles.cellValls}>
                            <SCViewList spaceBetween>
                                <Typography variant='subtitle2' style={{ fontSize: "10px" }}> {vehicle?.grossWeight} </Typography>
                                <Typography variant='subtitle2' color={'silver'} style={{ fontSize: "10px" }}> {measureMass.toLocaleUpperCase()} </Typography>
                            </SCViewList>
                        </td>
                    </tr>

                    <tr>
                        <td className={styles.celllabell}>
                            <Typography color={'silver'} variant='subtitle2' style={{ fontSize: "10px" }} >
                                GROSS COMBINATION WEIGHT
                            </Typography>
                        </td>
                        <td className={styles.cellValls}>
                            <SCViewList spaceBetween>

                                <Typography variant='subtitle2' style={{ fontSize: "10px" }}> {vehicle?.grossCombinationWeight} </Typography>
                                <Typography variant='subtitle2' color={'silver'} style={{ fontSize: "10px" }}> {measureMass.toLocaleUpperCase()} </Typography>
                            </SCViewList>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}