import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import ErrorProcessingComponent from '../components/NotificationModule/ErrorProcessingComponent';
import AuthComponent from '../components/LoginModule/AuthComponent';
import AuthControlComponent from '../components/LoginModule/AuthControlComponent';
import { AppDispatch, RootState } from '../store/store';
import LayoutPage from './LayoutPage';
import LogOutPage from './LogOutPage';
import SettingsPage from './SettingsPage';
import ShortCutControlComponent from '../components/NavigateModule/ShortCutControlComponent';
import StafPage from './StafPage';
import NotificationPage from './NotificationPage';
import TransportPage from './TransportPage';
import AuditPage from './AuditPage';
import LoadPage from './LoadPage';
import CustomerPage from './CustomerPage';
import WarehousePage from './WarehousesPage';
import RoutePage from './RoutePage';
import SocketsComponents from '../components/SocketsModule/SocketsComponents';
import PermissionPage from './PermissionsPage';
import ProcessingSocketCommandsComponent from '../components/SocketsModule/ProcessingSocketCommandsComponent';
import { modifyNotificationStateCount } from '../store/reducers/cnfSlice';
import NotificationService from '../services/NotificationService';
import { EChannelsType, ECommandType } from '../models/SocketModels';
import ActiveLoadPage from './ActiveLoadPage';



export default function MainPage() {
    const dispatch = useDispatch<AppDispatch>();



    const [socketProps, setSocketProps] = useState({})
    const [socketCommand, setSocketCommand] = useState<ECommandType | null>(null)
    const [socketChannels, setSocketChannels] = useState<EChannelsType | null>(null)



    const { auth } = useSelector((state: RootState) => state.cnfSlice);
    const [active, setActive] = useState(false);

    useEffect(() => {
        if (auth) {
            setActive(true)
            setSocketProps({ email: auth.email, callbackChannels: getChanel, callbackCommand: getCommand })
            NotificationService.checkAllNotificationData();
            dispatch(modifyNotificationStateCount(true));
        }
    }, [auth, dispatch])

    const getCommand = (s: ECommandType) => {
        setSocketCommand(s);
    }
    const getChanel = (obj: EChannelsType) => {
        setSocketChannels(obj);
    }
   
    return (
        <>
            {active && <SocketsComponents {...socketProps} />}
            <ProcessingSocketCommandsComponent socketCommand={socketCommand} socketChannels={socketChannels} />
            <AuthControlComponent />
            <ErrorProcessingComponent />
            <ShortCutControlComponent />
            {
                (auth)
                    ?
                    <Routes>
                        <Route path="/" element={<LayoutPage />}>

                            <Route index element={<div></div>} />
                            <Route path="settings/:id" element={<SettingsPage />} />
                            <Route path="audit" element={<AuditPage />} />
                            <Route path="logout" element={<LogOutPage />} />
                            <Route path="user" element={<StafPage />} />
                            <Route path="user/:id" element={<StafPage />} />
                            <Route path="transport" element={<TransportPage />} />
                            <Route path="transport/:id" element={<TransportPage />} />
                            <Route path="load" element={<LoadPage />} />
                            <Route path="load/:id" element={<LoadPage />} />
                            <Route path="activeLoad" element={<ActiveLoadPage />} />
                            <Route path="activeLoad/:id" element={<ActiveLoadPage />} />
                            <Route path="notification" element={<NotificationPage />} />
                            <Route path="customer" element={<CustomerPage />} />
                            <Route path="customer/:id" element={<CustomerPage />} />
                            <Route path="warehouse" element={<WarehousePage />} />
                            <Route path="warehouse/:id" element={<WarehousePage />} />
                            <Route path="routes" element={<RoutePage />} />
                            <Route path="routes/:id" element={<RoutePage />} />
                            <Route path="permissions" element={<PermissionPage />} />
                        </Route>
                        <Route path="*" element={<Navigate to="/" replace />} />
                    </Routes>
                    : <AuthComponent />
            }
        </>
    );
}