import React from 'react';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import { compareValue } from '../../helpers/generalHelper';


interface IProps {
    wh?: {
        w: number;
        h: number;
    }
    details?: '';
    placeholder?: '';
    getValue: (s: string) => void;
    initValue: string,
    readOnly?: boolean
}

const HTMLRedactor = (props: IProps) => {

    const { initValue, readOnly } = props
    const theme = 'snow';

    const modules_full = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ align: [] }],

            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ size: ['small', false, 'large', 'huge'] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['link', 'image', 'video'],
            [{ color: [] }, { background: [] }],
            ['clean'],
        ],
    };

    const formats_full = [
        'bold', 'italic', 'underline', 'strike',
        'align', 'list', 'indent',
        'size', 'header',
        'link', 'image', 'video',
        'color', 'background',
        'clean',
    ];

    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['link'],
            ['clean'],
        ],
    };

    const formats = [
        'bold', 'italic', 'underline', 'strike',
        'list', 'header', 'link', 'clean',
    ];

    const placeholder = '...';

    const { quill, quillRef } = useQuill({ theme, modules, formats, placeholder });

    React.useEffect(() => {
        if (quill) { quill.clipboard.dangerouslyPasteHTML(initValue); }
    }, [initValue, quill]);

    React.useEffect(() => {
        if (quill) {
            quill.on('text-change', (delta, oldDelta, source) => {
                if (compareValue(quill.root.innerHTML, "<p><br></p>")) {
                    props.getValue("");
                } else { props.getValue(quill.root.innerHTML); }
            });
        }
    }, [quill]);

    return (
        <>
            <div>
                <div style={{ minHeight: "80px", opacity: (readOnly ? "0.5" : "1") }} ref={quillRef} />
            </div>
        </>
    )
}

export default HTMLRedactor;

