import { useEffect, useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import './../../SearchBar.scss';
import { Paper } from "@mui/material";
import InputBase from '@mui/material/InputBase';
import { useDebounce } from "../../hooks/useDebounce";
import LogoViewComponent from "../CustomComponentModule/LogoViewComponent";
import ItemTransportInSearchListComponent from "./ItemTransportInSearchListComponent";
import { IResponseAutocompleteAuto } from "../../models/TransportModels";
import TransportService from "../../services/TransportService";

export interface IProps {
  placeholder: any,
  hendlerActiveElement: (item: IResponseAutocompleteAuto) => void
}

function SearchTransportBarComponent(props: IProps) {

  const { placeholder, hendlerActiveElement } = props;
  const [filteredData, setFilteredData] = useState<IResponseAutocompleteAuto[]>([]);
  const [wordEntered, setWordEntered] = useState("");
  const debouncedSerchValue = useDebounce(wordEntered, 300);

  const handlerBlur = () => {
    setTimeout(() => {
      clearInput();
    }, 500)
  }

  const handleFilter = (event: any) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);
    if (searchWord === "") {
      setFilteredData([]);
    }
  };

  const clearInput = () => {
    setFilteredData([]);
    setWordEntered("");
  };

  useEffect(() => {
    if ((wordEntered.trim() !== "") && (debouncedSerchValue)) {
      (async () => {
        const [error, response] = await TransportService.getAutocomplete(wordEntered.trim());
        if (response) {
          setFilteredData(response);
        }
        if (error) { }
      })()
    }
  }, [debouncedSerchValue])

  return (
    <div className="search">
      <div
        className="searchInputs"
        style={{ border: "1px solid silver", width: "390px", borderRadius: "4px" }} >
        <InputBase
          autoFocus
          type="text"
          placeholder={placeholder}
          value={wordEntered}
          onChange={handleFilter}
          sx={{ minWidth: "340px" }}
          onBlur={handlerBlur}
        />
        <div className="searchIcon">
          {filteredData.length === 0 ? (
            <SearchIcon />
          ) : (
            <CloseIcon id="clearBtn" onClick={clearInput} />
          )}
        </div>
      </div>
      {filteredData.length !== 0 ?
        <Paper className="dataResult">
          {filteredData.slice(0, 15).map((value: IResponseAutocompleteAuto, key) => {
            return (<ItemTransportInSearchListComponent
              key={key}
              item={value}
              hendlerActiveElement={hendlerActiveElement}
            />);
          })}
        </Paper> : <div style={{ display: "flex", justifyContent: "center", marginTop: "100px" }}>  <LogoViewComponent width={150} heigth={150} /> </div>
      }
    </div>
  );
}

export default SearchTransportBarComponent;