import axios from "axios";
import { settings } from "../configs/settings";
import { getTokens } from "./TokensService";

class GenericCRUDService {

    public static downloadReport = (url: string, data: object) => {
        const { accessToken } = getTokens();

        axios({
            baseURL: settings.baseURL,
            url: url,
            method: 'POST',
            headers: {
                "Authorization": accessToken?.trim() !== '' ? "Bearer " + accessToken : '',
                "Content-Type": "application/json"
            },
            data: JSON.stringify(data),
            responseType: 'blob',
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data])),
                fileName = response.headers['content-disposition'].split('filename=')[1].replaceAll('"', ''),
                link = document.createElement('a');

            link.href = url;
            link.setAttribute('download', fileName);

            document.body.appendChild(link);

            link.click();
            link.remove();
        });
    }
}

export default GenericCRUDService;