import { IResponseRoutesAutocomplete, IRouteCreateDto, IRouteStatus } from "../models/RoutesModels";
import { API } from "./APIService";

class RouteServices {

  public static checkRouteName = async (name: string): Promise<any> => {
    const [error, response] = await API('GET', 'route/name/available/' + name)
    return [error, response]
  }
  public static createRoute = async (body: IRouteCreateDto): Promise<any> => {
    const [error, response] = await API('POST', 'route', JSON.stringify(body));
    return [error, response]
  }
  public static getRouteInfo = async (uuid: string): Promise<any> => {
    const [error, response] = await API('GET', 'route/' + uuid)
    return [error, response]
  }

  public static getAllRoute = async (body: Object): Promise<any> => {
    const [error, response] = await API('POST', 'route/all', JSON.stringify(body));
    return [error, response];
  }
  public static getHotLoad = async (): Promise<any> => {
    const [error, response] = await API('GET', 'route/load/hot');
    return [error, response];
  }
  public static getStatisticsStatus = async (): Promise<any> => {
    const [error, response] = await API('GET', 'route/statistics/status');
    let st: IRouteStatus | undefined;
    if (response) {
      st = response.data.body;
    }
    return [error, st]
  }

  public static getRoutes = async (state: string, status: string, page: number, size: number): Promise<any> => {

    let criteria: any[] = [];
    if (state.toLocaleLowerCase() !== 'all') {
      criteria.push({
        property: "actionStatus",
        value: state,
        expression: "EQUALS",
      })
    }
    criteria.push({
      property: "status",
      value: status,
      expression: "EQUALS"
    })

    let body = {
      criteria: criteria,
      paging: {
        page: page,
        size: size
      }
    }



    const [error, response] = await API('POST', 'route/all', JSON.stringify(body));
    return [error, response]
  }
  public static getAutocomplete = async (s: string): Promise<any> => {
    const [error, response] = await API('GET', 'route/autocomplete/' + s,);
    let st: IResponseRoutesAutocomplete[] = [];
    if (response) {
      st = response.data.body;
    }
    return [error, st]
  }


}
export default RouteServices;