import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Typography, Tooltip, Box } from '@mui/material';
import { modifyAutoPart, setLastError } from "../../store/reducers/cnfSlice";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import { RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import TransportService from "../../services/TransportService";
import { ETransportStatus } from "../../models/TransportModels";

export default function TabsFiltersComponent() {

  const { hints } = useSelector((state: RootState) => state.cnfSlice.interfaceState);
  const { part, refresh } = useSelector((state: RootState) => state.cnfSlice.auto);
  const [p, setP] = useState('ACTIVE')

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handlerModifyTab = (s: string): void => {
    setP(s);
    dispatch(modifyAutoPart(s));
  }

  const [countPar, setCountPar] = useState({ active: 0, inactive: 0, temporary: 0, incomplete: 0 });
  useEffect(() => {
    (async () => {
      const [error, response] = await TransportService.getStatisticsAuto();
      if (response) {
        setCountPar(response.data.body)
      }
      if (error) { dispatch(setLastError(error)) }
    })()
  }, [refresh])

  const styles = {
    item: { width: "40px" },
    box: { border: "1px solid silver", textAlign: "center", borderRadius: "3px", marginLeft: "2px", marginRight: "2px", marginBottom: "4px" },
    icon: { fontSize: "25px", cursor: "pointer", marginLeft: "8px" },
    button: { border: "1px solid silver" }
  };

  return (
    <>
      <div style={{
        display: "flex",
        gap: "2px",
        marginTop: "5px",
        justifyContent: "center",
        marginBottom: "10px"
      }}>
        <div style={styles.item}>
          <Box sx={styles.box}>
            <Typography sx={{ fontSize: "10px" }}>{countPar?.active}</Typography>
          </Box>
          <Tooltip
            title={(hints) ? t(ETransportStatus.ACTIVE) : ''}
            placement={"bottom"}
            arrow={true}
          >
            <AssignmentIndIcon
              onClick={() => { handlerModifyTab(ETransportStatus.ACTIVE) }}
              color={(part === ETransportStatus.ACTIVE) ? "primary" : "disabled"}
              sx={styles.icon}
            />
          </Tooltip>
        </div>

        <div style={styles.item}>
          <Box sx={styles.box}>
            <Typography sx={{ fontSize: "10px" }}>{countPar?.temporary}</Typography>
          </Box>
          <Tooltip
            title={(hints) ? t(ETransportStatus.TEMPORARY) : ''}
            placement={"bottom"}
            arrow={true}
          >
            <AssignmentTurnedInIcon
              onClick={() => { handlerModifyTab(ETransportStatus.TEMPORARY) }}
              color={(part === ETransportStatus.TEMPORARY) ? "primary" : "disabled"}
              sx={styles.icon}
            />
          </Tooltip>
        </div>

        <div style={styles.item}>
          <Box sx={styles.box}>
            <Typography sx={{ fontSize: "10px" }}>{countPar?.inactive}</Typography>
          </Box>
          <Tooltip
            title={(hints) ? t(ETransportStatus.INACTIVE) : ''}
            placement={"bottom"}
            arrow={true}
          >
            <AssignmentLateIcon
              onClick={() => { handlerModifyTab(ETransportStatus.INACTIVE) }}
              color={(part === ETransportStatus.INACTIVE) ? "primary" : "disabled"}
              sx={styles.icon} />
          </Tooltip>
        </div>

        <div style={styles.item}>
          <Box sx={styles.box}>
            <Typography sx={{ fontSize: "10px" }}>{countPar?.incomplete}</Typography>
          </Box>
          <Tooltip
            title={(hints) ? t(ETransportStatus.INCOMPLETE) : ''}
            placement={"bottom"}
            arrow={true}
          >
            <IntegrationInstructionsIcon
              onClick={() => { handlerModifyTab(ETransportStatus.INCOMPLETE) }}
              color={(part === ETransportStatus.INCOMPLETE) ? "primary" : "disabled"}
              sx={styles.icon} />
          </Tooltip>
        </div>
      </div>
    </>
  );
}