import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Tooltip, Typography, Link, styled, TooltipProps, tooltipClasses, IconButton } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { AppDispatch, RootState } from "../../../store/store";
import { useDispatch, useSelector } from "react-redux";

import AuditService from "../../../services/AuditService";
import { modifyAuditFilter, modifyAuditPage, modifyAuditRefresh, modifyAuditSizePage } from "../../../store/reducers/auditSlice";
import { IAuditResponseDTO } from "../../../models/AuditModels";
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import DownloadIcon from '@mui/icons-material/Download';
import { dateToYMDHM, getDateFormat, getTimeFormat } from "../../../helpers/dateHelper";
import React from "react";
import styles from './AuditComponent.module.scss'
import { generateUniqueId } from "../../../helpers/generalHelper";

export default function AuditGridComponent() {

  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { filter } = useSelector((state: RootState) => state.auditSlice);
  const { page, size, refresh } = useSelector((state: RootState) => state.auditSlice.grid);
  const [loading, setLoading] = useState(false);
  const { hints, dateFormat, timeFormat } = useSelector((state: RootState) => state.cnfSlice.interfaceState);
  const [rowFilterAudit, setRowFilterAudit] = useState<IAuditResponseDTO[]>([]);
  const [pageSizeC, setPageSizeC] = useState(size);
  const [pageC, setPageC] = useState(page);
  const [totalRows, setTtalRows] = useState(0);

  const onRefreshHandler = () => {
    let filterC = { ...filter, times: { ...filter.times, t2: Date.now(), v2: dateToYMDHM(Date.now()) } };
    dispatch(modifyAuditFilter(filterC))
    dispatch(modifyAuditRefresh());
  }

  const onPageChangeHandler = (newPage: number) => {
    dispatch(modifyAuditPage(newPage));
    setPageC(newPage);
  }

  const onPageSizeChangeHandler = (newPageSize: number) => {
    dispatch(modifyAuditSizePage(newPageSize));
    setPageSizeC(newPageSize);
  }

  const onDownloadXLSHandler = () => {
    const criteriaFilter = AuditService.buildCriteriaFilter(page, size, filter);
    AuditService.downloadAuditReport(criteriaFilter);
  }

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip placement={"right"} {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 350,
      background: '#FFFFFF',
      border: '1px solid #000000',
      padding: 0,
      margin: 0
    }
  }));

  useEffect(() => {
    setLoading(true);
    (async () => {
      const criteriaFilter = AuditService.buildCriteriaFilter(page, size, filter);
      const [, response] = await AuditService.auditFilter(criteriaFilter);
      if (response && response.data) {
        let responseData = response.data;
        setTtalRows(responseData.total);
        if (responseData.body) {
          const st: IAuditResponseDTO[] = responseData.body.map((e: IAuditResponseDTO) => (
            {
              ...e,
              id: (Math.floor(Math.random() * 1000)).toString() + '-' + e.date.toString()
            })
          )
          setRowFilterAudit(st);
        }
      }
      setLoading(false);
    })()
  }, [page, size, refresh])

  const onRenderDateTimeColumn = (cellValues: any) => {
    let date = getDateFormat(cellValues.row.date, dateFormat),
      time = getTimeFormat(cellValues.row.date, timeFormat),
      valueTooltip = date + ' ' + time;
    return (
      <>
        <Tooltip title={valueTooltip} placement={"left"} arrow={true}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant={'subtitle2'} sx={{ marginTop: "3px", fontWeight: "normal", fontSize: "12px" }} color={'primary'} >
              {time}
            </Typography>
            <Typography variant={'button'} sx={{ marginTop: "-7px", fontWeight: "normal", fontSize: "11px" }} color={'silver'}>
              {date}
            </Typography>
          </div>
        </Tooltip>
      </>
    );
  };

  const onRenderUserColumn = (cellValues: any) => {
    const value = AuditService.getUserFullNameOrEmail(cellValues.row.user).toUpperCase();
    return (
      <>
        <Tooltip title={value} placement={"right"} arrow={true}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant={'button'} sx={{ marginTop: "3px", fontWeight: "normal", fontSize: "12px" }} >
              {value}
            </Typography>
          </div>
        </Tooltip>
      </>
    );
  };

  const onRenderActionColumn = (cellValues: any) => {
    const value = cellValues.row.action.toUpperCase();
    return (
      <>
        <Tooltip title={value} placement={"right"} arrow={true}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant={'button'} sx={{ marginTop: "3px", fontWeight: "normal", fontSize: "12px" }} >
              {value}
            </Typography>
          </div>
        </Tooltip>
      </>
    );
  };

  const onRenderModuleColumn = (cellValues: any) => {
    const value = cellValues.row.module.toUpperCase();
    return (
      <>
        <Tooltip title={value} placement={"right"} arrow={true}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant={'button'} sx={{ marginTop: "3px", fontWeight: "normal", fontSize: "12px" }} >
              {value}
            </Typography>
          </div>
        </Tooltip>
      </>
    );
  };

  const onRenderTargetColumn = (cellValues: any) => {
    const value = cellValues.row.value,
      businessItemId = cellValues.row.businessItemId;
    return (
      <>
        <Tooltip title={value.toUpperCase()} placement={"right"} arrow={true}>
          {
            businessItemId
              ?
              <Link href={AuditService.getAuditValueAsLink(cellValues.row.module, businessItemId)} underline="hover" target="_blank" rel="noreferrer">
                {value.toUpperCase()}
              </Link>
              :
              <Typography variant={'button'} sx={{ marginTop: "3px", fontWeight: "normal", fontSize: "12px" }} >
                {value.toUpperCase()}
              </Typography>
          }
        </Tooltip>
      </>
    );
  };

  const onRenderValueColumn = (cellValues: any) => {
    const records: { fieldName: string, leftValue: string, rightValue: string }[] | null = AuditService.buildDiffsValue(cellValues.row.oldValue, cellValues.row.newValue);
    return (
      <>
        {
          records
            ?
            <HtmlTooltip title=
              {
                <React.Fragment>
                  {buildItemDiffsValueAsHTML(records)}
                </React.Fragment>
              }
            >
              <Typography variant={'button'} sx={{ marginTop: "3px", fontWeight: "normal", fontSize: "12px" }} >
                {cellValues.row.businessItemValue}
              </Typography>
            </HtmlTooltip>
            :
            <Typography variant={'button'} sx={{ marginTop: "3px", fontWeight: "normal", fontSize: "12px" }} >
              {cellValues.row.businessItemValue}
            </Typography>
        }
      </>
    );
  };

  const onRenderDeviceColumn = (cellValues: any) => {
    const value = cellValues.row.deviceType.toUpperCase();
    return (
      <>
        <Tooltip title={value} placement={"right"} arrow={true}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant={'button'} sx={{ marginTop: "3px", fontWeight: "normal", fontSize: "12px" }} >
              {value}
            </Typography>
          </div>
        </Tooltip>
      </>
    );
  };

  const columns: GridColDef[] = [{
    field: 'date',
    headerName: t('DATE'),
    width: 100,
    sortable: false,
    headerAlign: "center",
    renderCell: onRenderDateTimeColumn
  }, {
    field: 'user',
    headerName: t('USER'),
    width: 200,
    sortable: false,
    headerAlign: "center",
    renderCell: onRenderUserColumn
  }, {
    field: 'action',
    headerName: t('ACTION'),
    width: 150,
    sortable: false,
    headerAlign: "center",
    renderCell: onRenderActionColumn
  }, {
    field: 'module',
    headerName: t('MODULE'),
    width: 180,
    sortable: false,
    headerAlign: "center",
    renderCell: onRenderModuleColumn
  }, {
    field: 'value',
    headerName: t('TARGET'),
    width: 220,
    sortable: false,
    headerAlign: "center",
    renderCell: onRenderTargetColumn
  }, {
    field: 'businessItemValue',
    headerName: t('VALUE'),
    width: 400,
    sortable: false,
    headerAlign: "center",
    renderCell: onRenderValueColumn
  }, {
    field: 'deviceType',
    headerName: t('DEVICE'),
    width: 80,
    sortable: false,
    headerAlign: "center",
    renderCell: onRenderDeviceColumn
  }];

  const customStyle = {
    icon: { fontSize: "28px", cursor: "pointer" }
  }

  const buildItemDiffsValueAsHTML = (records: { fieldName: string, leftValue: string, rightValue: string }[]) => {
    return (
      <table className={styles.tooltipAuditTable}>
        <thead>
          <tr>
            <th>FIELD</th>
            <th>OLD VALUE</th>
            <th>NEW VALUE</th>
          </tr>
        </thead>
        <tbody>
          {records.map(record => (
            <tr key={generateUniqueId()}>
              <td key={generateUniqueId()} className={styles.tooltipAuditTableField}>{record.fieldName}</td>
              <td key={generateUniqueId()} className={styles.tooltipAuditTableOldValue}>{record.leftValue}</td>
              <td key={generateUniqueId()} className={styles.tooltipAuditTableNewValue}>{record.rightValue}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <>
      <div>
        <Tooltip
          title={(hints) ? t("Refresh") : ''}
          placement={"top"}
          arrow={true}
          onClick={onRefreshHandler}
        >
          <IconButton size='small'>
            <ReplayCircleFilledIcon style={customStyle.icon} />
          </IconButton>
        </Tooltip>
        {!(rowFilterAudit.length < 1) && <Tooltip
          title={(hints) ? t("Download XLS report for audit") : ''}
          placement={"top"}
          arrow={true}
          onClick={onDownloadXLSHandler}
          key={"key"}
        >
          <IconButton>
            <DownloadIcon style={customStyle.icon} />
          </IconButton>
        </Tooltip>}
      </div>

      <div style={{ height: "100%" }}>
        <DataGrid
          density={'compact'}
          paginationMode="server"
          loading={loading}
          rows={rowFilterAudit}
          columns={columns}

          rowCount={totalRows}
          page={pageC}
          pageSize={pageSizeC}

          rowsPerPageOptions={[5, 10, 15, 20, 25]}
          onPageSizeChange={onPageSizeChangeHandler}
          onPageChange={onPageChangeHandler}
        />
      </div>
    </>
  );
}