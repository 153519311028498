import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import { EButtonColor } from '../../models/EnumGeneral';
import { IconButton, Tooltip } from '@mui/material';

interface IProps {
    iconButton?: boolean;
    icon?: JSX.Element;
    text: string;
    color: EButtonColor;
    disabled?: boolean | null;
    answerYES: () => void;
    tooltip?: boolean,
    tooltipText?: string,
    placement?:boolean
}

const SecureDialogComponent = (props: IProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const [value, setValue] = useState<string>('');

    const handlerValue = (event: any) => {
        const v = event.target.value;
        setValue(v);
        if (v.trim() === cod) { props.answerYES(); handleClose() }
    }
    const createCode = (): string => {
        let cd: string = '' + Math.floor(Math.random() * 1000);
        return cd;
    }

    useEffect(() => {
        setValue('');
        setCode(createCode());
    }, [open])

    const [cod, setCode] = useState(createCode())

    return (
        <>
            {props.tooltip
                ? !props.iconButton
                    ? <Tooltip
                        placement={props.placement?"top":"bottom"}
                        title={props.tooltipText}
                    >
                        <Button
                            aria-describedby={id}
                            variant="contained"
                            onClick={handleClick}
                            disabled={props.disabled ? true : false}
                            color={props.color}
                        >
                            {props.icon}
                            {props.text}
                        </Button>
                    </Tooltip>
                    :
                    <Tooltip
                        title={props.tooltipText}
                    >
                        <IconButton
                            aria-describedby={id}
                            disabled={props.disabled ? true : false}
                            onClick={handleClick}
                            color={props.color}
                        >
                            {props.icon}
                        </IconButton>
                    </Tooltip>
                : !props.iconButton ?
                    <Button
                        aria-describedby={id}
                        variant="contained"
                        onClick={handleClick}
                        disabled={props.disabled ? true : false}
                        color={props.color}
                    >
                        {props.icon}
                        {props.text}
                    </Button> :
                    <IconButton
                        aria-describedby={id}
                        disabled={props.disabled ? true : false}
                        onClick={handleClick}
                        color={props.color}
                    >
                        {props.icon}
                    </IconButton>
            }
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
            >
                <div style={{ display: "flex", width: "180px", padding: "20px", flexDirection: "column", gap: "10px" }}>
                    <div><span style={{ fontSize: "12px" }}>to confirm the action, enter the red text in the field below: <b style={{ color: "red" }}>{cod}</b></span></div>
                    <div><input value={value} onChange={handlerValue} autoFocus style={{ width: "100px" }} /></div>
                </div>

            </Popover>
        </>
    )
}
export default SecureDialogComponent;