import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, TextField } from '@mui/material';

import { modifyWarehouseRefresh, setLastError } from "../../store/reducers/cnfSlice";
import { AppDispatch } from "../../store/store";
import { useDispatch } from "react-redux";

import { IWarehouseContact, IWarehouseDetails } from "../../models/WarehouseModels";
import WarehouseService from "../../services/WarhouseService";
import ControlStateInCRUDComponent from "../CustomComponentModule/ControlStateInCRUDComponent";
import { isValidEmail } from "../../helpers/generalHelper";
import { IUserMetadata } from "../../models/IUser";


interface IProps {
  w: IWarehouseDetails | null,
  contact: IWarehouseContact | null,
  metadata: IUserMetadata | null,
  handleStep: (step: number) => void,
  snackMsg: (s: string) => void,
  refreshAfretSave: () => void
}
export default function ContactWarehouseComponent(props: IProps) {

  const { contact, metadata } = props

  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const [snapshot, setSnapshot] = useState<string>('');
  const [err, setErr] = useState<number>(0);
  const [txEr, setTxEr] = useState<string[]>([]);
  const [save, setSave] = useState<boolean>(false);

  const [state, setState] = useState<IWarehouseContact>({
    uuid: ' ',
    email: ' ',
    phone: ' ',
    cell: ' ',
    fax: ' '
  })




  const handlerChangePhone = (e: any) => {
    let value: string = (e.target.value as string);
    setState(st => ({ ...st, phone: value }))
  }

  const handlerChangeEmail = (e: any) => {
    let value: string = (e.target.value as string);
    setState(st => ({ ...st, email: value }))
  }

  const handlerChangeCell = (e: any) => {
    let value: string = (e.target.value as string);
    setState(st => ({ ...st, cell: value }))
  }

  const handlerChangeFax = (e: any) => {
    let value: string = (e.target.value as string);
    setState(st => ({ ...st, fax: value }))
  }

  const refreshState = () => {
    let st: IWarehouseContact | undefined = JSON.parse(snapshot) as IWarehouseContact;
    if (st) {
      setState(st);
    }
  }

  useEffect(() => {
    if (contact) {
      setSnapshot(JSON.stringify(contact));
      setState(contact);
    }
  }, [contact])

  useEffect(() => {
    if (save) {
      (async () => {
        let body: IWarehouseContact = {
          ...state,
          cell: state.cell?.trim() ? state.cell.trim() : null,
          fax: state.fax?.trim() ? state.fax.trim() : null
        };
        const [error, response] = await WarehouseService.saveWarehouseContact(body);
        if (response) {
          setSnapshot(JSON.stringify(state));
          props.snackMsg('Contact data was successfully saved for ' + props.w?.name);
          if (!metadata?.completed) {
            let body = { uuid: contact?.uuid, step: 4 }
            const [er, rs] = await WarehouseService.saveWarehouseStep(body);
            if (rs) {
              props.handleStep(4);

            }
            if (er) { dispatch(setLastError(error)) }
          }
        }
        if (error) { dispatch(setLastError(error)) }
        setSave(false);
        props.refreshAfretSave();
        dispatch(modifyWarehouseRefresh())
      })()
    }
  }, [save])



  useEffect(() => {
    let e: boolean = false;
    let txe: string[] = [];


    if (!state.email || state.email.trim().length < 6) {
      e = true;
      txe.push('* email is requered field');
    }

    if (!state.phone || state.phone.trim().length < 6) {
      e = true;
      txe.push('* phone is requered field');
    }

    if (!e && snapshot !== JSON.stringify(state)) { setErr(1) }
    if (!e && snapshot === JSON.stringify(state)) { setErr(0) }
    if (e) { setErr(-1); setTxEr(txe) }

  }, [state, snapshot])


  return (
    <>
      <div>
        <Box sx={{
          width: "620px",
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "row"

        }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "5px", }} >
            <TextField
              autoComplete={'off'}
              value={state.email}
              label="Email"
              onChange={handlerChangeEmail}
              style={{ width: "400px", marginBottom: "25px" }}
              inputProps={{ sx: { fontSize: "16px" } }}
              InputLabelProps={{ sx: { fontSize: "17px" } }}
            />

            <TextField
              autoComplete={'off'}
              error={!state.phone || state.phone.length <= 6}
              value={state.phone}
              label="Phone"
              onChange={handlerChangePhone}
              style={{ width: "400px", marginBottom: "25px" }}
              inputProps={{ sx: { fontSize: "16px" } }}
              InputLabelProps={{ sx: { fontSize: "17px" } }}
              helperText={state.phone && state.phone.length <= 6 ? '* you need to enter the correct phone ' : ''}
            />

            <TextField
              autoComplete={'off'}
              value={state.cell}
              label="Cell"
              onChange={handlerChangeCell}
              style={{ width: "400px", marginBottom: "25px" }}
              inputProps={{ sx: { fontSize: "16px" } }}
              InputLabelProps={{ sx: { fontSize: "17px" } }}
            />

            <TextField
              autoComplete={'off'}
              value={state.fax}
              label="Fax"
              onChange={handlerChangeFax}
              style={{ width: "400px", marginBottom: "25px" }}
              inputProps={{ sx: { fontSize: "16px" } }}
              InputLabelProps={{ sx: { fontSize: "17px" } }}
            />

          </Box>

          <div style={{ flex: "1 1 auto", display: "flex", alignItems: "center" }}>
            <ControlStateInCRUDComponent
              error={err}
              textErrors={txEr}
              handlerReset={refreshState}
              handlerSave={() => { setSave(true) }}
            />
          </div>

        </Box>
      </div>
    </>
  );
}