import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./assets/locales/en/translation.json";
import translationRU from "./assets/locales/ru/translation.json";
import translationES from "./assets/locales/es/translation.json";

const fallbackLng = ["en"];
const availableLanguages = ["en", "ru", "es"];

const resources = {
    en: {translation: translationEN},
    ru: {translation: translationRU},
    es: {translation: translationES}
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng,
        detection: { checkWhitelist: true },
        debug: false,
        whitelist: availableLanguages,
        interpolation: {escapeValue: false}
    });

export default i18n;