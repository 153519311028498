import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, InputAdornment, MenuItem, Slider, TextField, Tooltip, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { EIncomeType, IUserInfoResponseDto, IUserMetadata, UserStepRequestDto } from "../../models/IUser";
import ControlStateInCRUDComponent from "../CustomComponentModule/ControlStateInCRUDComponent";
import { EmployeeUpdateRequestDto, IncomeResponseDto } from "../../models/EmployeeModels";
import { dateToYMD, toTimestamp } from "../../helpers/dateHelper";
import StafService from "../../services/StafService";
import { setLastError } from "../../store/reducers/cnfSlice";
import { useDispatch, useSelector } from "react-redux";
import { compareValue, isRealNumber, ObjectToValueArray } from "../../helpers/generalHelper";
import React from "react";
import PercentIcon from '@mui/icons-material/Percent';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import './staf.scss';
import HTMLRedactor from "../CustomComponentModule/HTMLRedactor";
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import CloseIcon from '@mui/icons-material/Close';
import { RootState } from "../../store/store";
import SecureDialogComponent from "../CustomComponentModule/SecureDialogComponent";
import { EButtonColor } from "../../models/EnumGeneral";
import { realStringInfoCoverFace } from "../../helpers/textHelper";

interface IState {
    uuid: string;
    startDate: string;
    endDate: string,
    description: string,
    numberOfHoursPerDay: number,
    incomes: IncomeResponseDto[] | null
}

interface IProps {
    user: IUserInfoResponseDto | null;
    userMetadata: IUserMetadata | null;
    employee: IState | null;
    refreshAfretSave: (us: IUserInfoResponseDto) => void;
    snackMsg: (text: string) => void;
    handleStep: (step: number) => void;
}

const newIncome = (): IncomeResponseDto => {
    return {
        uuid: '#' + Math.random() * 1000 + Math.random() * 1000,
        type: '',
        amount: 0,
        caption: ''
    }
}

const newState = () => {
    return {
        uuid: '#' + Math.random() * 1000 + Math.random() * 1000,
        startDate: '',
        endDate: '',
        description: '<p><br/><p>',
        numberOfHoursPerDay: 8,
        incomes: null
    }
}

const EmployeeUserComponent = (props: IProps) => {

    const dispatch = useDispatch();
    const { user, userMetadata, employee } = props;
    const { hints } = useSelector((state: RootState) => state.cnfSlice.interfaceState);

    const [state, setState] = useState<IState>(newState());
    const [stateIncome, setStateIncome] = useState<IncomeResponseDto>(newIncome())

    const [disabled, setDisabled] = useState(true);
    const [open, setOpen] = React.useState(false);
    const [editable, setEditable] = useState(false);
    const [error, setError] = useState(0);
    const [errors, setErrors] = useState([false, false]);
    const [snpShot, setSnpShot] = useState<IState>();
    const [JSONSnp, setJSONSnp] = useState('');


    const [refresh, setRefresh] = useState(true);
    const [save, setSave] = useState<boolean>(false);
    const [isNewEmployee, setIsNewEmployee] = useState<boolean>(false);
    const [textErrors, setTextErrors] = useState<string[]>([]);

    useEffect(() => {
        (async () => {
            if (employee) {
                setSnpShot({ ...employee });
                setJSONSnp(JSON.stringify(employee.incomes))
                setState(employee);
                setIsNewEmployee(false);
            } else {
                let ns = newState();
                setSnpShot({ ...ns });
                setJSONSnp(JSON.stringify(ns.incomes))
                setState(ns);
                setIsNewEmployee(true);
            }
        })()
    }, [employee]);


    const putErrorState = () => {

        let er = 0;
        let ers = [false, false, false];
        let txtErs: string[] = [];

        if (state.startDate === '' ) {
            er = -1;
            ers[0] = true;
            txtErs.push('- start date is requared ');
        }

        if (state.endDate !== '') {
            if (toTimestamp(state.endDate) < toTimestamp(state.startDate)) {
                er = -1;
                ers[1] = true;
                txtErs.push('- start date must be less than or equal to end date');
            }
        }


        if ((er === 0) && state && snpShot && !compareValue(snpShot, state)) {
            er = 1;
        }


        if (!open) {
            if ((er === 0) && state && snpShot && !compareValue(JSONSnp, JSON.stringify(state.incomes))) { er = 1; }
        }

        setError(er);
        setErrors(ers);
        setTextErrors(txtErs);
    }
    useEffect(() => {
        putErrorState();
    }, [state, open])




    useEffect(() => {
        if (save) {
            (async () => {
                if (user) {

                    let incomes = state.incomes?.map(e => {
                        if (e.uuid[0] === '#') {
                            const { uuid, ...rest } = e;
                            return rest
                        } else return e;
                    });

                    let obj: any = {
                        startDate: (state.startDate !== '') ? toTimestamp(state.startDate) : 0,
                        endDate: (state.endDate !== '') ? toTimestamp(state.endDate) : 0,
                        description: state.description,
                        numberOfHoursPerDay: state.numberOfHoursPerDay,
                        incomes: incomes ? incomes : []
                    }



                    let body: EmployeeUpdateRequestDto = {
                        userId: user.uuid,
                        ...obj
                    }

                    const [error, data] = (isNewEmployee)
                        ? await StafService.createUserEmployee(body)
                        : await StafService.updateUserEmployee(body);

                    if (data) {
                        if (!userMetadata?.completed) {
                            const [error, response] = await StafService.getUserMetadata(user.uuid);
                            if (response) {
                                if (response.data.body.step !== 6) {
                                    let bd: UserStepRequestDto = {
                                        uuid: user.uuid,
                                        step: 5
                                    }
                                    const [err, dt] = await StafService.saveUserStep(bd);
                                } else { props.handleStep(5) }
                            }
                        }
                        setError(0);
                        setSnpShot({ ...state });
                        setIsNewEmployee(false);
                        props.refreshAfretSave(user);
                        let snackMessText = ((realStringInfoCoverFace(user?.firstName) === '') || (realStringInfoCoverFace(user?.firstName) === ''))
                            ? `${user.email}`
                            : `${user.firstName} ${user.lastName}`
                        props.snackMsg('Employee data was successfully saved for ' + snackMessText + '.')
                    }
                    if (error) { dispatch(setLastError(error)); }
                }
            })()
        }
        setSave(false);
    }, [save])

    const handlerEndDate = (event: any) => {
        let value = event.target.value;
        setState((st) => { return { ...st, endDate: value } })
    }
    const handlerStartDate = (event: any) => {
        let value = event.target.value;
        setState((st) => { return { ...st, startDate: value } })
    }
    const handlerDescription = (s: string) => {
        setState((st) => { return { ...st, description: s } })
    }
    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        if (typeof newValue === 'number') {
            setState((st) => {
                return { ...st, numberOfHoursPerDay: newValue }
            })
        }
    };
    const handleClickOpen = () => {
        setDisabled(true);
        setStateIncome(newIncome);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setEditable(false);
    };

    const handlerReset = () => {
        if (user) {
            setSnpShot(newState());
            props.refreshAfretSave(user);
        }
    }
    const handlerSaveUser = () => { setSave(true); }
    const handleChangeType = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        setDisabled(false);
        setStateIncome((st) => { return { ...st, type: value } });
    };
    const handleChangeValue = (s: any) => {
        if (isRealNumber(s)) setStateIncome((st) => { return { ...st, amount: parseFloat(s) } });
    }
    const handleChangeCaption = (event: any) => {
        let value = event.target.value;
        setStateIncome((st) => { return { ...st, caption: value } });
    }

    const handlerSaveIncomes = () => {

        let icms: IncomeResponseDto[] = ((state.incomes === null) ? [] : state.incomes);
        let f = icms.find((e) => e.uuid === stateIncome.uuid);
        if (f) {
            f.amount = stateIncome.amount;
            f.caption = stateIncome.caption;
        } else {
            let part: IncomeResponseDto[] = [{ ...stateIncome }];
            icms = [...icms, ...part];
        }
        setState((st: IState) => { return { ...st, incomes: icms } })
        setOpen(false);
    };

    const handlerDelete = () => {
        let icms: IncomeResponseDto[] | null = ((state.incomes === null) ? [] : state.incomes);
        icms = icms.filter(e => e.uuid !== stateIncome.uuid);
        setState((st: IState) => { return { ...st, incomes: icms } })
        setOpen(false);
    }

    const existCaption = useCallback((): boolean => {
        let f = state.incomes?.find((e) => e.caption === stateIncome.caption);
        if (f && f.uuid === stateIncome.uuid) return false;
        return f?.caption !== undefined
    }, [state, stateIncome]);

    const notExistUUID = useCallback((): boolean => {
        let f = state.incomes?.find((e) => e.uuid === stateIncome.uuid);
        return !f;
    }, [state, stateIncome]);

    return (
        <>
            <div style={{ display: "flex", width: "620px", justifyContent: "space-between" }}>
                <div style={{ display: "flex", flexDirection: "column", gap: "10px", maxWidth: "545px" }}>
                    <div style={{ display: "flex", flexDirection: "row", gap: "10px", minWidth: "540px", marginBottom: "-10px" }}>
                        <div style={{ display: "flex", marginTop: "15px", width: "250px", gap: "5px", marginRight: "40px" }}>
                            <TextField
                                error={errors[0]}
                                size="small"
                                label={"Start"}
                                type="date"
                                value={state.startDate}
                                onChange={handlerStartDate}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{ sx: { fontSize: "11px", widht: "145px" } }}
                            />
                            <TextField
                                error={errors[1]}
                                size="small"
                                label={"End"}
                                type="date"
                                onChange={handlerEndDate}
                                value={state.endDate}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{ sx: { fontSize: "11px", widht: "145px" } }}
                            />
                        </div>

                        <div >
                            <div  >
                                <div style={{ marginBottom: "15px", width: "240px" }}>

                                    <Typography variant={'caption'}> Hours per day: {state.numberOfHoursPerDay}h</Typography>
                                    <Box sx={{ marginTop: "5px" }}>
                                        <Slider
                                            value={state.numberOfHoursPerDay}
                                            onChange={handleSliderChange}
                                            max={12}
                                            min={1}
                                            step={1}
                                        />
                                    </Box>


                                </div>
                            </div>
                        </div>


                    </div>
                    <Divider />
                    <div style={{ display: "flex", flexDirection: "row", gap: "10px", paddingTop: "10px", paddingBottom: "10px" }}>

                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Tooltip
                                title={(hints) ? "Add new income" : ''}
                                arrow={true}
                                placement={"top"}
                            >


                                <Button
                                    onClick={handleClickOpen}
                                    color={errors[2] ? 'error' : 'info'} variant='contained' size={'small'} startIcon={<AddCircleOutlineIcon fontSize={'small'} />}>
                                    Incomes
                                </Button>
                            </Tooltip>
                        </div>

                        {(state.incomes) &&
                            <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                                {state.incomes.map((e: IncomeResponseDto) =>
                                    <Tooltip
                                        arrow={true}
                                        title={((hints) ? (e.caption + " " + e.amount + " " + e.type) : "")}
                                        key={e.uuid}
                                    >

                                        <div
                                            className={"cips"}
                                            key={e.uuid}
                                            onClick={(() => {
                                                setStateIncome(e)
                                                setDisabled(false);
                                                setOpen(true);
                                                setEditable(true);
                                            })}
                                        >
                                            <div className='amount'>{e.amount}<br /></div>
                                            <div className='texts'>
                                                <div className='caption'>{e.caption.toUpperCase()}</div>
                                                <div className='type'>{e.type.split('_').join(' ')}</div>
                                            </div>


                                        </div>
                                    </Tooltip>
                                )}
                            </div>
                        }
                    </div>

                    <Divider />
                    <div style={{ marginTop: "15px" }}>
                        <HTMLRedactor
                            getValue={handlerDescription}
                            initValue={(isNewEmployee) ? '<p><br/></p>' : snpShot ? { ...snpShot }.description : '<p><br/><p>'}
                        />
                    </div>
                </div>

                <div style={{ display: "flex", minWidth: "53px" }} >
                    <ControlStateInCRUDComponent
                        error={error}
                        textErrors={textErrors}
                        handlerReset={handlerReset}
                        handlerSave={handlerSaveUser}
                    />
                </div>

            </div>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>{notExistUUID() ? ` ADD INCOME ` : ` EDIT INCOME `}</DialogTitle>
                <DialogContent>
                    <div style={{ display: "flex" }}>
                        <div style={{ width: "450px", display: "flex", flexDirection: "column", gap: "15px", marginTop: "15px", marginBottom: "5px", paddingBottom: "10px" }}>
                            <TextField
                                disabled={!notExistUUID()}
                                select
                                defaultValue={" "}
                                value={stateIncome.type}
                                inputProps={{ sx: { fontSize: "12px", } }}
                                label="Income type"
                                onChange={handleChangeType}
                            >
                                {ObjectToValueArray(EIncomeType).map((option: string, i) =>
                                    <MenuItem key={i} value={option}>
                                        {option.split('_').join(' ')}
                                    </MenuItem>)}
                            </TextField>

                            {!disabled && <RealTextField
                                uuid={state.uuid}
                                stateIncome={stateIncome}
                                handleChangeValue={handleChangeValue}
                            />}

                            {!disabled && <TextField
                                inputProps={{ sx: { fontSize: "12px", } }}
                                error={stateIncome.caption.trim().length < 5 || existCaption()}
                                value={stateIncome.caption}
                                label="Caption"
                                onChange={handleChangeCaption}
                                helperText={existCaption() ? ' * such income exists in the list ' : ''}
                            />}

                        </div>


                    </div>

                </DialogContent>
                <DialogActions>

                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: "20px" }}>

                        <div>
                            {!notExistUUID() &&
                                <SecureDialogComponent
                                    answerYES={handlerDelete}
                                    text={''}
                                    icon={<DeleteSweepIcon fontSize='small' sx={{ marginRight: "5px", marginLeft: "5px" }} />}
                                    color={EButtonColor.error}
                                    iconButton={false}
                                />
                            }
                        </div>
                        <div style={{ display: "flex", gap: "10px" }}>

                            <Button
                                variant={'contained'}
                                disabled={((stateIncome.amount === 0) || (stateIncome.caption.trim().length < 5) || existCaption())}
                                size={'small'}
                                onClick={handlerSaveIncomes} startIcon={<SaveAsIcon />}>{!editable ? "ADD" : "SAVE"}</Button>
                            <Button
                                startIcon={<CloseIcon />}
                                color={'warning'}
                                variant={'contained'}
                                size={'small'}
                                onClick={handleClose}>Close</Button>
                        </div>
                    </div>
                </DialogActions>
            </Dialog>

        </>
    )
}
export default EmployeeUserComponent;


interface IPropsREalTextField {
    uuid: string;
    stateIncome: IncomeResponseDto;
    handleChangeValue: (s: string) => void;

}
const RealTextField = (props: IPropsREalTextField) => {
    const { stateIncome, handleChangeValue, uuid } = props;
    const [value, setValue] = useState('');

    const handleLocalValue = (event: any) => {
        let value = event.target.value
        if (isRealNumber(value)) {
            setValue(value);
            handleChangeValue(value);
        }
    }

    useEffect(() => {
        setValue((stateIncome.amount).toString())
    }, [uuid]);

    return (
        <>
            <TextField
                error={parseFloat(value) === 0 || value === ''}
                value={value}
                inputProps={{ sx: { fontSize: "12px", } }}
                style={{ width: "30%" }}
                label="Amount"
                onChange={handleLocalValue}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            {(stateIncome.type === EIncomeType.PERCENTAGE) && <PercentIcon fontSize={'small'} />}
                            {(stateIncome.type === EIncomeType.CENTS_PER_MILE) && <div>cents:</div>}
                            {(stateIncome.type === EIncomeType.HOURLY) && <AttachMoneyIcon fontSize={'small'} />}
                            {(stateIncome.type === EIncomeType.DROP_PICKUPS) && <AttachMoneyIcon fontSize={'small'} />}
                        </InputAdornment>
                    ),
                }}
            />
        </>
    )
}

