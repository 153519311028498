import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Divider, Button } from '@mui/material';
import { IRole, TRoles } from "../../models/IStaf";
import React from "react";

interface IProps {
    allRoles: TRoles,
    getRole: (s: string) => void,
    reset: boolean,
}

export default function ListRolesComponentPermission(props: IProps) {

    const { allRoles, getRole, reset } = props
    const { t } = useTranslation();
    const [role, setRole] = useState<string>("all")
    const handleFilterRole = (r: IRole) => {
        getRole(r.name);
        setRole(r.name);
    }
    React.useEffect(() => {
        setRole("all");
        getRole("all");
    }, [reset])
    return (
        <>

            <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                {(role !== 'all') ?
                    <Button
                        color='inherit'
                        onClick={() => handleFilterRole({ uuid: '', name: "all" })}
                        size="small"

                        sx={{ width: "100%", fontSize: "12px" }}>All
                    </Button>
                    :
                    <Button
                        color='inherit'
                        size="small"
                        variant="contained"
                        disabled={true}
                        sx={{ width: "100%", fontSize: "12px" }}
                    >
                        All
                    </Button>}
                <Divider />
                {(allRoles) && allRoles.map((r, i) =>
                    <div key={i}>
                        {(role !== r.name) ?
                            <Button
                                color='inherit'
                                onClick={() => handleFilterRole(r)}
                                size="small"
                                sx={{ width: "100%", marginBottom: "5px", fontSize: "12px" }}
                            >
                                {t(r.name)}
                            </Button>
                            :
                            <Button
                                color="secondary"
                                variant="contained"
                                disabled={true}
                                size="small"
                                sx={{ width: "100%", marginBottom: "5px", fontSize: "12px" }}
                            >
                                {t(r.name)}
                            </Button>}
                        <Divider />
                    </div>

                )}
            </div>
        </>
    );
}

