import { Rating, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLastError } from '../../store/reducers/cnfSlice';
import { RootState } from '../../store/store';
import { getDateFormat } from '../../helpers/dateHelper';
import SCViewList from '../../styledComponents/sharedComponent/SCViewList';
import { useNavigate } from 'react-router-dom';
import { ILoadDetailsDTO, IReviewLoadModel } from '../../models/LoadModels';
import LoadService from '../../services/LoadService';

interface IProps {
    load: ILoadDetailsDTO | null
}
const ReviewLoadComponent = (props: IProps) => {
    const { load } = props
    const { dateFormat } = useSelector((state: RootState) => state.cnfSlice.interfaceState);

    const [state, setState] = useState<IReviewLoadModel | null>(null)
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            if (load) {
                const [error, response] = await LoadService.getLoadReview(load.uuid);
                if (response) { setState(response.data.body); }
                if (error) { dispatch(setLastError(error)) }
            }
        })()
    }, [load]);


    const handleRedirectLoad = (s: string) => {
        navigate('/load/' + s)
    }
    return (
        <>
            <Box sx={{ display: "flex" }}>

                {(state)
                    ? <Box sx={{ display: "flex", flexDirection: "column", gap: "20px", width: "450px", paddingLeft: "30px" }}>
                        <SCViewList gap={10} spaceBetween>
                            <SCViewList column>
                                <Typography>{(state.email) ? state.email : ""}</Typography>
                                {
                                    (state.mark)
                                        ? <Rating value={state.mark} precision={0.5} readOnly></Rating>
                                        : <div></div>
                                }
                            </SCViewList>
                            <div style={{ display: "flex", flexDirection: "column", minWidth: "100px", paddingLeft: "10px", borderLeft: "1px solid silver" }}>
                                {
                                    (state.createdDate)
                                        ? getDateFormat(state.createdDate, dateFormat)
                                        : ''
                                }
                            </div>
                        </SCViewList>
                        <div style={{ border: "1px solid silver", padding: "10px", borderRadius: "3px", minHeight: "75px", fontSize: "10px" }}>
                            {
                                (state.message)
                                    ? state.message
                                    : ''
                            }
                        </div>
                    </Box>
                    : <div style={{ border: "1px dashed silver", display: "flex", padding: "10px", borderRadius: "3px", minHeight: "50px", width: "500px", fontSize: "15px", textAlign: "center", alignItems: "center", justifyContent: "center" }}>There are no reviews.</div>

                }
            </Box >
        </>
    )
}
export default ReviewLoadComponent;