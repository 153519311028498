import './staf.scss';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { IconButton, Typography, useTheme } from '@mui/material';
import { EDocumentType, IUserMetadata, IUserSummary } from '../../models/IUser';
import { getDateFormat, timeDiference } from '../../helpers/dateHelper';
import { useEffect, useState } from 'react';
import StafService from '../../services/StafService';
import HelpProperHtmlComponent from '../CustomComponentModule/HelpProperHtmlComponent';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

interface IProps {
    uuid: string;
    toStep: (step: number) => void;
    userMetadata: IUserMetadata | null;

}

export default function VeiwTableReportComponent(props: IProps) {
    const { uuid, userMetadata } = props;
    const theme = useTheme();
    const [summary, setSummary] = useState<IUserSummary | null>(null);
    const { dateFormat } = useSelector((state: RootState) => state.cnfSlice.interfaceState);

    useEffect(() => {
        if (uuid) {
            (async () => {
                const [error, data] = await StafService.getSummary(uuid);
                if (data) {
                    let ras: IUserSummary = data.data.body;
                    setSummary(ras);
                }
                if (error) { }
            })()
        }
    }, [uuid]);

    const hendlerToEdit = (step: number) => {
        if (summary && !summary.role) { props.toStep(0) }
        else { props.toStep(step - 1) }
    }

    const boxSX = {
        color: theme.palette.action.disabled,
        "&:hover": {
            color: theme.palette.primary.main
        },
    }

    return (
        <div>

            <table style={{ width: "100%", marginTop: "10px" }} key='table'>
                <tbody key={'tBody'}>

                    <tr key='roleTr'>
                        <td className={'cell but'} key='role1'>
                            <IconButton size={'small'} onClick={() => hendlerToEdit(1)}>
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={'cell label'} key='role2'>
                            <Typography color={summary?.role ? 'silver' : 'error'} variant='subtitle2' key='name2Typography'>ROLE</Typography>
                        </td>
                        <td className={'cell vals'} key='role3'>
                            <Typography variant='subtitle2' key='name3Typography'>{summary?.role ? summary.role : 'N/A'}</Typography>
                        </td>
                    </tr>

                    <tr key='userAvailable'>
                        <td className={'cell but'}>
                        </td>
                        <td className={'cell label'}>
                            <Typography color={'silver'} variant='subtitle2'>AVAILABLE</Typography>
                        </td>
                        <td className={'cell vals'}>
                            <Typography variant='subtitle2'>{summary?.available ? 'YES' : 'NO'}</Typography>
                        </td>
                    </tr>

                    <tr key='userStatus'>
                        <td className={'cell but'} key='status1'>
                            <IconButton key='iconButton2' size={'small'} onClick={() => hendlerToEdit(6)} disabled={!userMetadata?.completed}>
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={'cell label'} key='status2'>
                            <Typography color={'silver'} variant='subtitle2' key='status1Typograpy' >
                                STATUS
                            </Typography>
                        </td>
                        <td className={'cell vals'} key='status3'>
                            <Typography variant='subtitle2' key='status2Typohrapy'>
                                {summary?.status ? summary?.status : 'N/A'}
                                {summary?.status === 'TEMPORARY'
                                    && <span style={{ marginLeft: "5px" }} key='statusSpan'>
                                        [{summary?.statusActiveFromDate ? getDateFormat(summary?.statusActiveFromDate, dateFormat) : 'N/A'} - {summary?.statusActiveToDate ? getDateFormat(summary?.statusActiveToDate, dateFormat) : 'N/A'}]
                                    </span>}
                            </Typography>
                        </td>
                    </tr>


                    <tr key='color'>
                        <td className={'cell but'} key='color1'>
                            <IconButton size={'small'} onClick={() => hendlerToEdit(2)} >
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={'cell label'} key='color2'>
                            <Typography color={summary?.color !== "N/A" ? 'silver' : 'error'} variant='subtitle2' key='color1Typo'>
                                COLOR
                            </Typography>
                        </td>
                        <td className={'cell vals'} key='color3'>
                            <Typography variant='subtitle2' key='color2Typo'>
                                {summary?.color !== "N/A"
                                    ? <div style={{ width: "25px", height: "25px", background: summary?.color }}></div>
                                    : 'N/A'}
                            </Typography>
                        </td>
                    </tr>

                    <tr key='userName'>
                        <td className={'cell but'} key='username1'>
                            <IconButton size={'small'} onClick={() => hendlerToEdit(1)}>
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={'cell label'} key='username2'>
                            <Typography key='typoUsername1' color={summary?.username ? 'silver' : 'error'} variant='subtitle2'>USERNAME</Typography>
                        </td>
                        <td className={'cell vals'} key='username3'>
                            <Typography key='typoUsername2' variant='subtitle2'>{summary?.username ? summary.username : '*'}</Typography>
                        </td>
                    </tr>

                    <tr key='birthday'>
                        <td className={'cell but'} key='birth1'>
                            <IconButton key='iconButton3' size={'small'} onClick={() => hendlerToEdit(2)}>
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={'cell label'} key='birth2'>
                            <Typography color={summary?.status ? 'silver' : 'error'} variant='subtitle2' key='birth1Typo' >
                                BIRTHDAY
                            </Typography>
                        </td>
                        <td className={'cell vals'} key='birth3'>
                            <Typography variant='subtitle2' key='birth2Typo'>
                                {summary?.birthday !== 0 && summary?.birthday ? getDateFormat(summary?.birthday, dateFormat) : 'N/A'}
                                {<span style={{ marginLeft: "10px" }} key='birthSpan'></span>}
                                {summary?.birthday !== 0 && summary?.birthday ? '[ ' + timeDiference(summary?.birthday ? summary?.birthday : 0, Date.now()) + ' ]' : ''}
                            </Typography>
                        </td>
                    </tr>

                    <tr key='primaryEmail'>
                        <td className={'cell but'} key='emails1'>
                            <IconButton size={'small'} onClick={() => hendlerToEdit(3)} >
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={'cell label'} key='emails2'>
                            <Typography key='typoPrimEmail1' color={'silver'} variant='subtitle2' >
                                PRIMARY EMAIL
                            </Typography>
                        </td>
                        <td className={'cell vals'} key='emails3'>
                            <Typography variant='subtitle2' key='primarryEmail2Typo'>
                                {summary?.primaryEmail !== null
                                    ? summary?.primaryEmail
                                    : 'N/A'}
                            </Typography>
                        </td>
                    </tr>
                    <tr key='additionalEmails'>
                        <td className={'cell but'} key='adDemails1'>
                            <IconButton size={'small'} onClick={() => hendlerToEdit(3)} >
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={'cell label'} key='adDemails2'>
                            <Typography key='typoAddEmail1' color={'silver'} variant='subtitle2' >
                                ADDITIONAL EMAILS
                            </Typography>
                        </td>
                        <td className={'cell vals'} key='adDemails3'>
                            <Typography variant='subtitle2' key='addMailTypography'>
                                {(summary?.additionalEmails && summary?.additionalEmails.length > 0)
                                    ? <div style={{ display: 'flex' }}>
                                        {summary?.additionalEmails.map((m, key) =>
                                            <Typography key={key} variant={'subtitle2'}> {m.toLowerCase()}{(key !== summary.additionalEmails.length - 1) ? ',' : ''} &nbsp;</Typography>
                                        )}
                                    </div>
                                    : 'N/A'}
                            </Typography>
                        </td>
                    </tr>

                    <tr key='primaryPhone'>
                        <td className={'cell but'} key='phone1'>
                            <IconButton size={'small'} onClick={() => hendlerToEdit(3)} >
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={'cell label'} key='phone2'>
                            <Typography key='phoneTypo1' color={(summary?.primaryPhone) ? 'silver' : 'error'} variant='subtitle2' >
                                PRIMARY PHONE
                            </Typography>
                        </td>
                        <td className={'cell vals'} key='phone3'>
                            <Typography variant='subtitle2' key='phone2Typo'>
                                {summary?.primaryPhone !== null
                                    ? summary?.primaryPhone
                                    : 'N/A'}
                            </Typography>
                        </td>
                    </tr>
                    <tr key='additionalPhone'>
                        <td className={'cell but'} key='addPhone1'>
                            <IconButton size={'small'} onClick={() => hendlerToEdit(3)} >
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={'cell label'} key='addPhone2'>
                            <Typography key='typoAddPhone1' color={'silver'} variant='subtitle2' >
                                ADDITIONAL PHONES
                            </Typography>
                        </td>
                        <td className={'cell vals'} key='addPhone3'>
                            <Typography key='typoAddPhone1' variant='subtitle2'>
                                {(summary?.additionalPhones && summary?.additionalPhones.length > 0)
                                    ? <div style={{ display: 'flex' }}>
                                        {summary?.additionalPhones.map((m, key) =>
                                            <Typography key={key} variant={'subtitle2'}>{m.toLowerCase()}{(key !== summary.additionalPhones.length - 1) ? ', ' : ''} &nbsp;</Typography>
                                        )}
                                    </div>
                                    : 'N/A'}
                            </Typography>
                        </td>
                    </tr>

                    <tr key='address'>
                        <td className={'cell but'} key='address1'>
                            <IconButton size={'small'} onClick={() => hendlerToEdit(2)} >
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={'cell label'} key='address2'>
                            <Typography color={summary?.address !== null ? 'silver' : 'error'} variant='subtitle2' key='addressTypo' >
                                ADDRESS
                            </Typography>
                        </td>
                        <td className={'cell vals'} key='address3'>
                            <Typography variant='subtitle2' key='address2Typo'>
                                {summary?.address !== null
                                    ? summary?.address
                                    : 'N/A'}
                            </Typography>
                        </td>
                    </tr>

                    <tr key='employeePeriode'>
                        <td className={'cell but'} key='employeePeriode1'>
                            <IconButton size={'small'} onClick={() => hendlerToEdit(4)} >
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={'cell label'} key='employeePeriode2'>
                            <Typography color={summary?.employee || summary?.role === 'OWNER' ? 'silver' : 'error'} variant='subtitle2' key='employeePeriode1Typo'>
                                EMPLOYEE
                            </Typography>
                        </td>
                        <td className={'cell vals'} key='employeePeriode3'>
                            <Typography variant='subtitle2' key='employeePeriode2Typo'>
                                {summary?.employee
                                    ? <div>
                                        [{getDateFormat(summary?.employeeStartDate ? summary?.employeeStartDate : 0, dateFormat)} - {(summary?.employeeEndDate) ? getDateFormat(summary?.employeeEndDate, dateFormat) : "unlimited"}]
                                    </div>
                                    : 'N/A'}
                            </Typography>
                        </td>
                    </tr>

                    <tr key='employeeDescription'>
                        <td className={'cell but'} key='employeeDescription1'>
                            <IconButton size={'small'} onClick={() => hendlerToEdit(4)} >
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={'cell label'} key='employeeDescription2'>
                            <Typography color={'silver'} variant='subtitle2' key='employeeDescriptionTypo'>
                                EMPLOYEE DESCRIPTION
                            </Typography>
                        </td>
                        <td className={'cell vals'} key='employeeDescription3'>
                            <Typography variant='subtitle2' key='employeeDescriptionTypo2'>
                                {summary?.employeeDescription && summary?.employeeDescription !== 'N/A'
                                    ? <HelpProperHtmlComponent texts={summary.employeeDescription} />
                                    : 'N/A'}
                            </Typography>
                        </td>
                    </tr>

                    <tr key='employee'>
                        <td className={'cell but'} key='employee1'>
                            <IconButton size={'small'} onClick={() => hendlerToEdit(4)} >
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={'cell label'} key='employee2'>
                            <Typography color={'silver'} variant='subtitle2' key='employee1Typo'>
                                EMPLOYEE HOURS PER DAY
                            </Typography>
                        </td>
                        <td className={'cell vals'} key='employee3'>
                            <Typography variant='subtitle2' key='employee2Typo'>
                                {summary?.employeeNumberOfHoursPerDay
                                    ? <div>
                                        <Typography variant={'subtitle2'} key='employee3Typo'> {summary.employeeNumberOfHoursPerDay} </Typography>
                                    </div>
                                    : 'N/A'}
                            </Typography>
                        </td>
                    </tr>


                    <tr key='documents'>
                        <td className={'cell but'} key='documents1'>
                            <IconButton size={'small'} onClick={() => hendlerToEdit(5)} >
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={'cell label'} key='documents2'>
                            <Typography
                                color={summary?.socialSecurityNumber || summary?.role === "OWNER" ? 'silver' : 'error'} variant='subtitle2' >
                                {EDocumentType.SOCIAL_SECURITY_CARD.split('_').join(' ')}
                            </Typography>
                        </td>
                        <td className={'cell vals'} key='documents3'>
                            <Typography variant='subtitle2'>
                                {summary?.socialSecurityNumber
                                    ? 'YES'
                                    : 'N/A'}
                                {summary?.socialSecurityNumber &&
                                    <span style={{ marginLeft: "5px" }} key='ssnSpan'>
                                        [{summary?.socialSecurityNumberIssueDate ? getDateFormat(summary?.socialSecurityNumberIssueDate, dateFormat) : 'N/A'} - {summary?.socialSecurityNumberExpirationDate ? (summary?.socialSecurityNumberExpirationDate < Date.now() ? ' expired on ' : '') + getDateFormat(summary?.socialSecurityNumberExpirationDate, dateFormat) : 'unlimited'}]
                                    </span>}
                            </Typography>
                        </td>
                    </tr>

                    {(summary?.role === 'DRIVER') &&
                        <tr key='docDriver'>
                            <td className={'cell but'} key='docDriver1'>
                                <IconButton key='iconButton13' size={'small'} onClick={() => hendlerToEdit(5)}>
                                    <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                                </IconButton>
                            </td>
                            <td className={'cell label'} key='docDriver2'>
                                <Typography
                                    color={summary.driverLicense ? 'silver' : 'error'} variant='subtitle2' >
                                    {EDocumentType.DRIVER_LICENSE.split('_').join(' ')}
                                </Typography>
                            </td>
                            <td className={'cell vals'} key='docDriver3'>
                                <Typography variant='subtitle2'>
                                    {summary?.driverLicense
                                        ? 'YES'
                                        : 'N/A'}
                                    {summary?.driverLicense &&
                                        <span style={{ marginLeft: "5px" }} key='dlSpan'>
                                            [{summary?.driverLicenseIssueDate ? getDateFormat(summary?.driverLicenseIssueDate, dateFormat) : 'N/A'} - {summary?.driverLicenseExpirationDate ? (summary?.driverLicenseExpirationDate < Date.now() ? ' expired on ' : '') + getDateFormat(summary?.driverLicenseExpirationDate, dateFormat) : 'unlimited'}]
                                        </span>}
                                </Typography>
                            </td>
                        </tr>
                    }

                    {(summary?.role === 'DRIVER') &&
                        <tr key='docDrug' >
                            <td className={'cell but'} key='docDrug1'>
                                <IconButton key='iconButton14' size={'small'} onClick={() => hendlerToEdit(5)}>
                                    <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                                </IconButton>
                            </td>
                            <td className={'cell label'} key='docDrug2'>
                                <Typography
                                    color={summary?.drugTest ? 'silver' : 'error'} variant='subtitle2' >
                                    {EDocumentType.DRUG_TEST.split('_').join(' ')}
                                </Typography>
                            </td>
                            <td className={'cell vals'} key='docDrug3'>
                                <Typography variant='subtitle2'>
                                    {summary?.drugTest
                                        ? 'YES'
                                        : 'N/A'}
                                    {summary?.drugTest &&
                                        <span style={{ marginLeft: "5px" }} key='dtSpan'>
                                            [{summary?.drugTestIssueDate ? getDateFormat(summary?.drugTestIssueDate, dateFormat) : 'N/A'} - {summary?.drugTestExpirationDate ? (summary?.drugTestExpirationDate < Date.now() ? ' expired on ' : '') + getDateFormat(summary?.drugTestExpirationDate, dateFormat) : 'unlimited'}]
                                        </span>}
                                </Typography>
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </div >
    );
}