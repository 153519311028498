
import to from 'await-to-js';
import axios, { AxiosResponse } from 'axios';
import { getTokens } from './TokensService';

export const APIFile = async ( 
  url: string    
): Promise<[Error | null, AxiosResponse<any> | undefined]> => {

  const { accessToken } = getTokens();  
  
  let [error, response] = await
    to(
      axios(
        {
          method: 'GET',
          baseURL: '',
          url: url,
          headers: {
            "Authorization": accessToken?.trim() !== '' ? "Bearer " + accessToken : '',
            "Content-Type": "application/json"
          }          
        }
      )
    )

  return [error, response]
}