import { useEffect, useState } from "react";
import { Box, TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

import { modifyWarehouseRefresh, setLastError } from "../../store/reducers/cnfSlice";
import { AppDispatch } from "../../store/store";
import { useDispatch } from "react-redux";

import { ITypeWarehouse, IWarehouseRegistrationDTO, IWarehouseRegistrationUpdate } from "../../models/WarehouseModels";
import WarehouseService from "../../services/WarhouseService";
import ListWarehouseTempComponent from "./ListWarehouseTempComponent";
import ControlStateInCRUDComponent from "../CustomComponentModule/ControlStateInCRUDComponent";
import AddressComponent from "../MapModule/AddressComponent";
import { AddressRequestDto, AddressResponseDto } from "../../models/AddressModels";
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import { useDebounce } from "../../hooks/useDebounce";
import { IUserMetadata } from "../../models/IUser";
import { isBlank } from "../../helpers/textHelper";
import AddressService from "../../services/AddressService";

interface IProps {
  registration: IWarehouseRegistrationDTO,
  metadata: IUserMetadata | null,
  handleStep: (step: number) => void,
  snackMsg: (s: string) => void,
  refreshAfretSave: () => void
}

export default function RegisterWarehouseComponent(props: IProps) {
  const { registration, metadata } = props
  const dispatch = useDispatch<AppDispatch>();

  const [addressDialogShow, setAddressDialogShow] = useState(false);
  const [address, setAddress] = useState<AddressResponseDto | null>(null);
  const [retrieveAddress, setRetrieveAddress] = useState(false);

  const [isValidName, setIsValidName] = useState<boolean>(true);
  const [snapshot, setSnapshot] = useState<string>('');
  const [err, setErr] = useState<number>(0);
  const [txEr, setTxEr] = useState<string[]>([]);
  const [save, setSave] = useState<boolean>(false);

  const [state, setState] = useState<IWarehouseRegistrationDTO>(registration)

  const debouncedName = useDebounce(state.name, 300);

  const onAddressChange = (address: AddressResponseDto) => {
    setAddress(address);
  }

  const handlerChangeName = (e: any) => {
    let value: string = (e.target.value as string);
    setState(st => ({ ...st, name: value }))
  }

  const handlerChangeContact = (e: any) => {
    let value: string = (e.target.value as string);
    setState(st => ({ ...st, contactName: value }))
  }

  const hendlerList = (list: ITypeWarehouse[]) => {
    setState(st => ({ ...st, types: list }))
  }

  const onAddressDialogClose = () => {
    setAddressDialogShow(false);
  }

  const onAddressDialogShow = () => {
    setAddressDialogShow(true);
  };

  const onApplyAddress = () => {
    setRetrieveAddress(true);
    checkIfWarehouseNameAvailable();
  }

  const refreshState = () => {
    let st: IWarehouseRegistrationDTO | undefined = JSON.parse(snapshot) as IWarehouseRegistrationDTO;
    if (st) {
      setState(st);
      setAddress(st.address);
    }
  }

  useEffect(() => {
    setSnapshot(JSON.stringify(registration));
    setState(registration);
    setAddress(registration.address)
  }, [registration])

  const checkIfWarehouseNameAvailable = () => {
    const warehouseName = registration.name.trim();
    const newWarehouseName = state.name.trim();
    const addressId = registration.address ? registration.address.uuid : '';
    const newAddressId = state.address ? state.address.uuid : '';

    if (isBlank(newWarehouseName) || isBlank(newAddressId)) {
      return;
    }

    if (warehouseName.length < 3 || isBlank(addressId) || (newWarehouseName === warehouseName && addressId === newAddressId)) {
      setIsValidName(true);
      return;
    }

    (async () => {
      const [, response] = await WarehouseService.warehouseNameAviable(newWarehouseName, newAddressId);
      if (response) {
        setIsValidName(response.data.body as boolean);
        return;
      }
      setIsValidName(false);
    })();
  };

  useEffect(() => {
    if (debouncedName) {
      checkIfWarehouseNameAvailable();
    }
  });

  useEffect(() => {
    if (retrieveAddress && address) {
        (async () => {
            const { uuid, fullAddress, ...rest } = address;
            const body: AddressRequestDto = rest;
            const [, data] = await AddressService.getOrCreateAddress(body);
            if (data) {
                const addressResponse: AddressResponseDto = data as AddressResponseDto;
                setState(st => ({ ...st, address: addressResponse }));
                setAddress(addressResponse);
                setAddressDialogShow(false);
            }
        })();
        setRetrieveAddress(false);
    }
}, [retrieveAddress, address, state]);

  useEffect(() => {
    if (save) {
      (async () => {
        let { address, types, ...rest } = state;
        let body: IWarehouseRegistrationUpdate = {
          ...rest,
          addressId: address?.uuid ? address?.uuid : '',
          typeIds: types.reduce((a, e) => a.concat([e.uuid]), [] as string[])
        }
        const [error, response] = await WarehouseService.saveWarehouseRegister(body);
        if (response) {
          setSnapshot(JSON.stringify(state));
          props.snackMsg('Registration data was successfully saved for ' + registration.name);
          props.refreshAfretSave();
          if (!metadata?.completed) {
            let body = { uuid: registration.uuid, step: 1 }
            const [er, rs] = await WarehouseService.saveWarehouseStep(body);
            if (er) { dispatch(setLastError(error)) }
          }
        }

        setSave(false);
        dispatch(modifyWarehouseRefresh())

      })()
    }
  }, [save])

  useEffect(() => {
    let invalidForm: boolean = false;
    let txe: string[] = [];

    if (!state.address) {
      invalidForm = true;
      txe.push('* Address not defined');
    }

    if (state.name.trim().length < 3) {
      invalidForm = true;
      txe.push('* Name is requared');
    }

    if (state.name.length > 160) {
      invalidForm = true;
      txe.push('* The limit for name is 160 symbols');
    }

    if (state.contactName.trim().length < 2) {
      invalidForm = true;
      txe.push('* Contact name is requared');
    }

    if (state.contactName.length > 56) {
      invalidForm = true;
      txe.push('* The limit for contact name is 56 symbols');
    }

    if (state.types.length === 0) {
      invalidForm = true;
      txe.push('* Type list is empty');
    }

    if (!isValidName) {
      invalidForm = true;
      txe.push('* Warehouse with specified name and address already exists');
    }

    if (!invalidForm && snapshot !== JSON.stringify(state)) { setErr(1) }
    if (!invalidForm && snapshot === JSON.stringify(state)) { setErr(0) }
    if (invalidForm) { setErr(-1); setTxEr(txe) }
  }, [state, isValidName, snapshot])

  return (
    <>
      <div style={{ width: "100%" }}>
        <Box sx={{ display: "flex", justifyContent: "flex-start", flexDirection: "row" }}>
          {((state === null) || (state !== null && (state.name !== undefined))) &&
            <Box style={{ width: "275px", display: "flex", flexDirection: "row" }}>
              <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "30px",
                minWidth: "250px"
              }}>
                <Box sx={{ width: "100%" }}>
                  <TextField label="" disabled multiline rows={2} value={state.address?.fullAddress}
                    inputProps={{ sx: { fontSize: "11px", padding: "1px", lineHeight: "1.5" } }}
                    InputLabelProps={{ sx: { fontSize: "17px" } }}
                    sx={{ minWidth: "250px" }}
                  />
                  <Button sx={{ minWidth: "250px", marginTop: "5px" }} variant={'contained'} onClick={onAddressDialogShow} color={'info'}>
                    <AddLocationAltIcon /> Address
                  </Button>
                </Box>

                <Box >
                  <TextField
                    autoComplete={'off'}
                    error={state.name.length < 3 || !isValidName || state.name.length > 160}
                    value={state.name}
                    label="Warehouse Name"
                    onChange={handlerChangeName}
                    style={{ width: "250px", marginBottom: "25px" }}
                    inputProps={{ sx: { fontSize: "16px" } }}
                    InputLabelProps={{ sx: { fontSize: "17px" } }}
                    helperText={!isValidName ? 'Warehouse with specified name and address already exists.' : ''}
                  />
                  <TextField
                    autoComplete={'off'}
                    error={state.contactName.length < 2 || state.contactName.length > 56}
                    value={state.contactName}
                    label="Contact Name"
                    onChange={handlerChangeContact}
                    style={{ width: "250px" }}
                    inputProps={{ sx: { fontSize: "16px" } }}
                    InputLabelProps={{ sx: { fontSize: "17px" } }}
                  />
                </Box>
              </div>
            </Box>
          }
          <div style={{ paddingLeft: "20px", borderLeft: "1px dashed silver" }}>
            <ListWarehouseTempComponent getList={hendlerList} list={state.types} />
          </div>

          <div style={{ display: "flex", flex: "1 1 auto", minWidth: "30px", paddingRight: "20px" }} >
            <ControlStateInCRUDComponent
              error={err}
              textErrors={txEr}
              handlerReset={refreshState}
              handlerSave={() => { setSave(true) }}
            />
          </div>
        </Box>
      </div>

      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={addressDialogShow}
        onClose={onAddressDialogClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle></DialogTitle>
        <DialogContent>
          <AddressComponent current={address ? address : null} getPlace={onAddressChange} />
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px", marginRight: "20px" }}>
          <Button variant={'contained'} color={'info'}
            onClick={onApplyAddress} disabled={!address || address.zipcode.length < 4}> Apply/Select
          </Button>
          <Button variant={'contained'} color={'warning'} onClick={onAddressDialogClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}