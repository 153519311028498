import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, MenuItem, TextField, Tooltip, Typography } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { DocumentAttachmentResponseDto, EDocumentType, ICountryLocationDto, IDocumentCreateDto, IDocumentRequestDto, IDocumentResponseDto, IUserInfoResponseDto, IUserMetadata } from "../../models/IUser";
import ControlStateInCRUDComponent from "../CustomComponentModule/ControlStateInCRUDComponent";
import { dateToYMD, timeDiference, toTimestamp } from "../../helpers/dateHelper";
import StafService from "../../services/StafService";
import { setLastError } from "../../store/reducers/cnfSlice";
import { useDispatch } from "react-redux";
import { ObjectToValueArray } from "../../helpers/generalHelper";
import React from "react";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import './staf.scss';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import FilterIcon from '@mui/icons-material/Filter';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import SecureDialogComponent from "../CustomComponentModule/SecureDialogComponent";
import { EButtonColor } from "../../models/EnumGeneral";
import FileViewComponent from "../CustomComponentModule/FileViewComponent";
import FileDownloadComponent from "../CustomComponentModule/FileDownloadComponent";
import AddressService from "../../services/AddressService";

interface IProps {
    user: IUserInfoResponseDto | null;
    userMetadata: IUserMetadata | null;
    refreshAfretSave: (us: IUserInfoResponseDto) => void;
    snackMsg: (text: string) => void;
    handleStep: (step: number) => void;
    documents: IDocumentResponseDto[] | null;
}

const newDocument = (): IDocumentRequestDto => {
    return {
        uuid: '#' + Math.random() * 1000 + Math.random() * 1000,
        type: '',
        number: '',
        dateOfIssue: '',
        expirationDate: '',
        issueCountryUuid: ''
    }
}

const DocumentsUserComponent = (props: IProps) => {
    const dispatch = useDispatch();
    const { user, documents } = props;
    const [stateDocument, setStateDocument] = useState<IDocumentRequestDto | null>()
    const [open, setOpen] = React.useState(false);

    const [localError, setLocalError] = useState(true);
    const [error, setError] = useState(0);
    const [errIssueDate, setErrIssueDate] = useState<boolean>(false);
    const [errExpDate, setErrExpDate] = useState<boolean>(false);
    const [errorExpDate, setErrorExpDate] = useState<boolean>(false);

    const [save, setSave] = useState<boolean>(false);
    const [deleteDocument, setDeleteDocument] = useState<boolean>(false);
    const [textErrors, setTextErrors] = useState<string[]>([]);

    const [countrys, setCountrys] = useState<ICountryLocationDto[]>([]);
    const [inputValue, setInputValue] = React.useState('');
    const [value, setValue] = React.useState<ICountryLocationDto | null>(null);
    const [expanded, setExpanded] = React.useState<string | false>(false);

    const hendlerRefresh = () => {
        if (props.user) {
            props.refreshAfretSave(props.user);
        }
    }

    const handleChange = (panel: string) => {
        setExpanded(panel);
    };

    const handlerEdit = (item: IDocumentResponseDto) => {
        setStateDocument(
            {
                uuid: item.uuid,
                type: item.type,
                number: item.number,
                dateOfIssue: dateToYMD(Number(item.dateOfIssue)),
                expirationDate: dateToYMD(Number(item.expirationDate)),
                issueCountryUuid: item.issueCountry.uuid
            }
        )

        const f = countrys.find(e => e.country === item.issueCountry.country);
        if (f) {
            setValue(f);
            setInputValue(f.country);
        }
    }

    const validateState = (d: IDocumentResponseDto[] | null) => {
        if (!d) {
            if (user?.role.name !== 'OWNER') {
                setError(-1);
                setTextErrors(['* ' + EDocumentType.SOCIAL_SECURITY_CARD.toLowerCase().split('_').join(' ') + ' is requared'])
            }
        } else {
            let er = 0;
            let erTxt: string[] = [];

            if (d.filter(e => e.type === EDocumentType.SOCIAL_SECURITY_CARD).length < 1) {
                er = -1;
                erTxt.push('* ' + EDocumentType.SOCIAL_SECURITY_CARD.toLowerCase().split('_').join(' ') + ' is requared')
            }

            if (
                user?.role.name === 'DRIVER' &&
                d.filter(e => e.type === EDocumentType.DRIVER_LICENSE).length < 1) {
                er = -1;
                erTxt.push('* ' + EDocumentType.DRIVER_LICENSE.toLowerCase().split('_').join(' ') + ' is requared')
            }

            if (
                user?.role.name === 'DRIVER' &&
                d.filter(e => e.type === EDocumentType.DRUG_TEST).length < 1) {
                er = -1;
                erTxt.push('* ' + EDocumentType.DRUG_TEST.toLowerCase().split('_').join(' ') + ' is requared')
            }

            setError(er);
            setTextErrors(erTxt)
        }
    }

    useEffect(() => {
        if (documents && !(documents?.length < 1)) {
            validateState(documents)
        }
        else {
            validateState(null);
        };
    }, [documents]);

    useEffect(() => {
        if (save) {
            (async () => {
                if (user && stateDocument) {

                    let isNewDocument = false;
                    let bodyU: IDocumentRequestDto = {
                        ...stateDocument,
                        dateOfIssue: stateDocument.dateOfIssue !== "" ? '' + toTimestamp(stateDocument.dateOfIssue) : "" + 0,
                        expirationDate: stateDocument.expirationDate !== "" ? '' + toTimestamp(stateDocument.expirationDate) : "" + 0
                    };
                    const { uuid, ...rest } = bodyU;
                    let bodyC: IDocumentCreateDto = { ...rest, userId: user.uuid }

                    if (stateDocument.uuid[0] === '#') {
                        isNewDocument = true;
                    }

                    const [error, data] = (isNewDocument)
                        ? await StafService.createDocument(bodyC)
                        : await StafService.updateDocument(bodyU);

                    if (data) {
                        props.snackMsg(`Document was successfully saved for ${user.fullNameOrEmail}.`);
                        props.refreshAfretSave(user);
                    }
                    if (error) {
                        dispatch(setLastError(error));
                    }
                    setSave(false);
                }
                hendlerRefresh();
            })()
        }
    }, [save])

    useEffect(() => {
        if (deleteDocument) {
            (async () => {
                if (user && stateDocument) {
                    const [error, data] = await StafService.deleteDocuments(stateDocument?.uuid);
                    if (data) {
                        props.snackMsg(`Document was successfully removed for ${user.fullNameOrEmail}.`);
                        props.refreshAfretSave(user);
                    }
                    if (error) {
                        dispatch(setLastError(error));
                    }
                }
                hendlerRefresh();
            })()
            setDeleteDocument(false);
            setOpen(false);
        }
    }, [deleteDocument])

    useEffect(() => {
        (async () => {
            if (user && countrys && (countrys.length < 1)) {
                const [error, res] = await AddressService.getAllCountry();
                if (res) {
                    setCountrys(res.data.body);
                }
                if (error) { dispatch(setLastError(error)) }
            }
        })()
    }, [])

    useEffect(() => {
        if (stateDocument) {
            let er = false;

            if (stateDocument.type.trim() === '') er = true;
            if (stateDocument.number.trim() === '') er = true;
            if (stateDocument.issueCountryUuid.trim() === '') er = true;
            if (stateDocument.dateOfIssue.trim() === '') er = true;
            if (stateDocument.type === EDocumentType.DRUG_TEST && (stateDocument.expirationDate.trim() === '')) {
                er = true;
                setErrorExpDate(true)
            } else (setErrorExpDate(false))
            if (
                toTimestamp(stateDocument.dateOfIssue.trim()) >
                toTimestamp(stateDocument.expirationDate.trim())
            ) {
                er = true;
                setErrExpDate(true);
            } else {
                setErrExpDate(false);
            }
            if (stateDocument.dateOfIssue.trim() === '') {
                er = true;
                setErrIssueDate(true);
            } else {
                setErrIssueDate(false);
            }
            setLocalError(er);
            setOpen(true);
        }
    }, [stateDocument])

    const handleClickOpen = () => {
        let n = newDocument();
        setValue(null);
        setInputValue('');
        setStateDocument(n);
        setLocalError(true);
        setErrExpDate(false);
        setErrIssueDate(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handlerReset = () => {
        if (user) {
            props.refreshAfretSave(user);
        }
    }

    const handlerSaveUser = () => { setSave(true); }

    const handleChangeType = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        setStateDocument((st) => { if (st) return { ...st, type: value } });
    };

    const handlerIssueDate = (event: any) => {
        let value: string = event.target.value as string;
        setStateDocument((st) => { if (st) return { ...st, dateOfIssue: value } });
    }

    const handlerExpirationDate = (event: any) => {
        let value: string = event.target.value as string;
        setStateDocument((st) => { if (st) return { ...st, expirationDate: value } });
    }

    const handlerCountry = (n: ICountryLocationDto | null) => {
        setStateDocument((st) => { if (st) return { ...st, issueCountryUuid: n ? n.uuid : '' } });
    }

    const handlerEditNumber = (event: any) => {
        let value: string = event.target.value as string;
        setStateDocument((st) => { if (st) return { ...st, number: value } });

    }

    const handlerSaveDocument = () => {
        setSave(true);
        setOpen(false);
    };

    const visibleDeleteButton = useCallback((): boolean => {
        return stateDocument?.uuid[0] !== '#';
    }, [stateDocument]);

    return (
        <>
            <div style={{ display: "flex", width: "620px", justifyContent: "space-between" }}>
                <div style={{ display: "flex", flexDirection: "column", gap: "10px", maxWidth: "545px" }}>
                    <div>
                        <Tooltip
                            title="Add new document"
                            arrow={true}
                            placement="top"
                        >
                            <Button
                                onClick={handleClickOpen}
                                color={error === -1 ? 'error' : 'info'} variant='contained' size={'small'} startIcon={<AddCircleOutlineIcon fontSize={'small'} />}>
                                document
                            </Button>
                        </Tooltip>

                    </div>
                    <div style={{ marginTop: "5px", marginBottom: "5px" }}> <Divider /> </div>
                    <div>
                        {documents &&
                            <div style={{ width: "550px" }}>
                                {
                                    documents.map((d: IDocumentResponseDto, i) =>
                                        <DocumentComponent
                                            key={i}
                                            doc={d}
                                            expanded={expanded === d.uuid}
                                            handleChange={handleChange}
                                            handlerEdit={handlerEdit}
                                            refresh={hendlerRefresh}
                                            snackMsg={props.snackMsg}
                                        />
                                    )}
                            </div>
                        }
                    </div>
                </div>

                <div style={{ display: "flex", minWidth: "53px" }} >
                    <ControlStateInCRUDComponent
                        error={error}
                        textErrors={textErrors}
                        handlerReset={handlerReset}
                        handlerSave={handlerSaveUser}
                    />
                </div>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>{((stateDocument?.uuid[0] === '#') ? ` ADD` : "EDIT") + " DOCUMENT"}</DialogTitle>
                <DialogContent>
                    <div style={{ display: "flex" }}>
                        <div style={{
                            width: "450px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "25px",
                            marginTop: "15px",
                            marginBottom: "5px"
                        }}>

                            <TextField
                                disabled={stateDocument?.uuid[0] !== '#'}
                                error={stateDocument?.type === ''}
                                select
                                inputProps={{ sx: { fontSize: "12px", } }}
                                label="Document type"
                                onChange={handleChangeType}
                                value={stateDocument?.type}
                            >
                                {ObjectToValueArray(EDocumentType).map((option: string, i) =>
                                    <MenuItem key={i} value={option}>
                                        {option.split('_').join(' ')}
                                    </MenuItem>)}
                            </TextField>

                            <TextField
                                error={(stateDocument ? stateDocument?.number.trim().length < 5 : false)}
                                inputProps={{ sx: { fontSize: "12px", } }}
                                label="Number"
                                autoComplete={'off'}
                                value={stateDocument?.number}
                                onChange={handlerEditNumber}>
                            </TextField>

                            <Autocomplete
                                value={value}
                                onChange={(event: any, newValue: ICountryLocationDto | null) => {
                                    setValue(newValue);
                                    handlerCountry(newValue)
                                }}
                                inputValue={inputValue}
                                onInputChange={(event, newInputValue) => {
                                    setInputValue(newInputValue);
                                }}
                                id="country-select-demo"
                                options={countrys}
                                autoHighlight
                                getOptionLabel={(option) => option.country.toUpperCase()}
                                renderOption={(props, option) => (
                                    <Box component="li" {...props} style={{ fontSize: "12px" }}>
                                        {option.country.toUpperCase()}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        error={!value}
                                        {...params}
                                        label="Country"
                                        style={{ fontSize: "12px" }}
                                        inputProps={{
                                            ...params.inputProps,
                                            sx: { fontSize: "12px" },
                                            autoComplete: 'off'
                                        }}
                                    />
                                )}
                            />

                            <div style={{ display: "flex", width: "370px", gap: "25px", marginRight: "40px" }}>
                                <TextField
                                    error={errIssueDate}
                                    size="small"
                                    label={"Date of issue"}
                                    type="date"
                                    value={stateDocument?.dateOfIssue}
                                    onChange={handlerIssueDate}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{ sx: { fontSize: "12px", widht: "185px" } }}
                                />
                                <TextField
                                    error={errExpDate || errorExpDate}
                                    size="small"
                                    label={"Expiration date"}
                                    type="date"
                                    value={stateDocument?.expirationDate}
                                    onChange={handlerExpirationDate}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{ sx: { fontSize: "12px", widht: "185px" } }}
                                    helperText={errExpDate ? '* expiration date is less than ' : ''}
                                />
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: "20px" }}>
                        <div>
                            {visibleDeleteButton() &&
                                <SecureDialogComponent
                                    answerYES={() => setDeleteDocument(true)}
                                    text={''}
                                    icon={<DeleteForeverIcon fontSize='small' sx={{ marginRight: "5px", marginLeft: "5px" }} />}
                                    color={EButtonColor.error}
                                />
                            }
                        </div>
                        <div style={{ display: "flex", gap: "10px" }}>
                            <Button
                                disabled={localError}
                                variant={'contained'}
                                size={'small'}
                                onClick={handlerSaveDocument} startIcon={<SaveAsIcon />}>
                                {stateDocument?.uuid[0] !== '#' ? "SAVE" : "ADD"}
                            </Button>
                            <Button
                                startIcon={<CloseIcon />}
                                color={"warning"}
                                variant={'contained'}
                                size={'small'}
                                onClick={handleClose}>Close</Button>
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default DocumentsUserComponent;

interface IPropDocumentComponent {
    doc: IDocumentResponseDto;
    expanded: boolean;
    handleChange: (uuid: string) => void
    handlerEdit: (item: IDocumentResponseDto) => void
    refresh: () => void;
    snackMsg: (text: string) => void;
}

const DocumentComponent = (props: IPropDocumentComponent) => {
    const { doc, expanded, handleChange, handlerEdit } = props;
    const filePick = useRef<HTMLInputElement | null>(null);
    const [vewFiles, setViewFile] = useState<DocumentAttachmentResponseDto>();
    const [nrViewFiles, setNrViewFiles] = useState(0);
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handlePick = () => {
        filePick.current?.click()
    }

    const hendlerNextFile = () => {
        if (vewFiles) {
            let n = doc.attachments.indexOf(vewFiles);
            if (n > -1) {
                setViewFile(doc.attachments[n + 1]);
                setNrViewFiles(n + 1);
            }
        }
    }

    const hendlerPrevFile = () => {
        if (vewFiles) {
            let n = doc.attachments.indexOf(vewFiles);
            if (n > -1) {
                setViewFile(doc.attachments[n - 1]);
                setNrViewFiles(n - 1);
            }
        }
    }

    const handleView = (e: DocumentAttachmentResponseDto) => {
        setOpen(true);
        setViewFile(e);
        let n = doc.attachments.indexOf(e);
        setNrViewFiles(n);
    }

    const hendlerDeleteFile = (e: DocumentAttachmentResponseDto) => {
        (async () => {
            const [error, response] = await StafService.deleteFileInDocuments(e.uuid);
            if (response) {
                props.snackMsg(`For document type ${doc.type.split('_').join(' ')} an attachment was successfully removed.`);
                props.refresh();
            }
            if (error) { }
        })()
    }

    const uploadHandler = (event: any) => {
        let file = event.target.files[0];
        if (!file) return;
        (async () => {
            let requestData = {
                'documentId': doc.uuid,
                'type': 'MAIN'
            };

            const formData = new FormData();
            formData.append('request', new Blob([JSON.stringify(requestData)], { type: "application/json" }));
            formData.append('file', file);

            const [error, response] = await StafService.updateFileInDocument(formData);
            if (response) {
                event.target.value = null;
                props.refresh();
                props.snackMsg(`For document type ${doc.type.split('_').join(' ')} an attachment was successfully added.`);
            }
            if (error) { }
        })()
    }

    return (
        <>
            <Accordion
                expanded={expanded}
                onChange={() => handleChange(doc.uuid)}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography variant={'body2'} sx={{ flexShrink: 0, marginRight: "10px" }}>
                        {doc.type.split('_').join(' ').toUpperCase()}
                    </Typography>
                    <Typography variant={'body2'} sx={{ color: 'red' }}>
                        {
                            doc.expirationDate && doc.expirationDate > 0 && Date.now() > Number(doc.expirationDate)
                                ? ' [' + timeDiference(Number(doc.expirationDate), Date.now()) + ' expired] '
                                : ''
                        }
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                        <div style={{ display: "flex" }}>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "20px",
                                width: "50%",
                                paddingBottom: "10px",
                                paddingRight: "20px",
                                borderRight: "1px dashed silver"
                            }}>

                                <TextField
                                    disabled={true}
                                    inputProps={{ sx: { fontSize: "12px", } }}
                                    label="Number"
                                    value={doc.number}
                                >
                                </TextField>

                                <TextField
                                    disabled={true}
                                    inputProps={{ sx: { fontSize: "12px", } }}
                                    label="Country"
                                    value={(doc && doc.issueCountry && doc.issueCountry.country) ? doc.issueCountry.country : ""}
                                >
                                </TextField>

                                <div style={{ display: "flex", gap: "10px" }}>
                                    <TextField
                                        disabled={true}
                                        size="small"
                                        label={"Date of issue"}
                                        type="date"
                                        value={dateToYMD(Number(doc.dateOfIssue))}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{ sx: { fontSize: "12px", widht: "185px" } }}
                                    />
                                    <TextField
                                        disabled={true}
                                        size="small"
                                        label={"Expiration date"}
                                        type="date"
                                        value={dateToYMD(Number(doc.expirationDate))}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{ sx: { fontSize: "12px", widht: "185px" } }}
                                    />
                                </div>
                            </div>
                            <div style={{
                                paddingLeft: "20px",
                                display: "flex",
                                justifyContent: "center",

                            }}>
                                <div>
                                    <div style={{ display: "flex" }}>
                                        <Tooltip
                                            title="Add attachment to the document"
                                            arrow={true}
                                            placement="top"
                                        >
                                            <Button
                                                color={'warning'}
                                                variant='contained'
                                                sx={{ marginBottom: "10px", width: "95px" }}
                                                onClick={handlePick}
                                            >
                                                <AttachFileIcon fontSize='small' />
                                            </Button>
                                        </Tooltip>
                                        {
                                            (doc.attachments.length > 0) &&
                                            <div style={{ display: "flex", paddingLeft: "20px", paddingTop: "5px" }}>
                                                <Typography variant={'body2'} color={'GrayText'}>
                                                    files: {doc.attachments.length}
                                                </Typography>
                                            </div>
                                        }

                                        <input type="file" onChange={uploadHandler}
                                            accept=".png, .jpg, .jpeg, .txt, .pdf"
                                            ref={filePick}
                                            style={{
                                                width: "0px",
                                                height: "0px",
                                                margin: "0px",
                                                padding: "0px",
                                                overflow: "hidden"
                                            }} />
                                    </div>

                                    <div style={{
                                        maxHeight: "200px",
                                        overflowY: "auto",
                                    }}>
                                        {

                                            doc.attachments.map((e, i) =>
                                                <div style={{}} key={i}>
                                                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                        <Tooltip
                                                            title="Open the file to view"
                                                            arrow={true}
                                                            placement={"top"}
                                                        >
                                                            <Button
                                                                color={'info'}
                                                                variant='outlined'
                                                                size={'small'}
                                                                onClick={() => handleView(e)}
                                                            >
                                                                {e.fileDetails.extension.toLocaleLowerCase() === 'pdf' && <PictureAsPdfIcon fontSize='small' />}
                                                                {['png', 'jpg', 'jpeg'].includes(e.fileDetails.extension.toLocaleLowerCase()) && <FilterIcon fontSize='small' />}
                                                                {e.fileDetails.extension.toLocaleLowerCase() === 'txt' && <FilterNoneIcon fontSize='small' />}
                                                                <span style={{ marginLeft: "10px" }}> file - {i + 1} </span>
                                                            </Button>
                                                        </Tooltip>

                                                        <div >
                                                            <Tooltip
                                                                title="Download file"
                                                                arrow={true}
                                                                placement="top"
                                                            >
                                                                <Box>
                                                                    <FileDownloadComponent fileName={doc.type + '.' + e.fileDetails.extension} ent={e.fileDetails} />
                                                                </Box>
                                                            </Tooltip>

                                                        </div>
                                                        <Tooltip
                                                            title="Remove file"
                                                            arrow={true}
                                                            placement="top"
                                                        >
                                                            <Box>
                                                                <SecureDialogComponent
                                                                    answerYES={() => hendlerDeleteFile(e)}
                                                                    text={''}
                                                                    icon={<DeleteForeverIcon fontSize='medium' sx={{ marginRight: "5px", marginLeft: "5px" }} />}
                                                                    color={EButtonColor.error}
                                                                    iconButton={true}
                                                                />
                                                            </Box>
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{
                            marginTop: "20px",
                            width: "250px",
                            display: "flex",
                            gap: "20px"
                        }} >
                            <Tooltip
                                title="Open the document for editing"
                                placement="top"
                                arrow={true}
                            >
                                <Button
                                    color={'info'}
                                    variant={'contained'}
                                    size={'small'}
                                    onClick={() => handlerEdit(doc)}
                                    style={{ width: "95px", height: "32px" }}
                                >
                                    <AutoAwesomeIcon fontSize='small' />
                                </Button>
                            </Tooltip>
                        </div>

                        <Dialog
                            sx={{
                                "& .MuiDialog-container": {
                                    "& .MuiPaper-root": {
                                        width: "100%",
                                        maxWidth: "75%",
                                        minHeight: "83%",
                                    },
                                },
                            }}
                            open={open}
                            onClose={handleClose}
                        >
                            <DialogTitle>
                                {doc.type.split('_').join(' ')}
                            </DialogTitle>
                            <DialogContent>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100%" }}>
                                    {vewFiles &&
                                        <FileViewComponent ent={vewFiles.fileDetails} width={300} heigth={300} />}
                                </div>
                            </DialogContent>
                            <DialogActions
                                style={{
                                    marginBottom: "20px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    paddingLeft: "20px",
                                    paddingRight: "20px"
                                }}>
                                {vewFiles &&
                                    <FileDownloadComponent fileName={doc.type + '.' + vewFiles.fileDetails.extension} ent={vewFiles.fileDetails} />
                                }
                                <div style={{ display: "flex", gap: "10px" }}>
                                    <Button
                                        onClick={hendlerPrevFile}
                                        disabled={nrViewFiles <= 0}
                                        variant='contained'
                                        size='small'>
                                        <KeyboardArrowLeftIcon fontSize='small' />
                                    </Button>

                                    <div> {nrViewFiles + 1} of {doc.attachments.length} </div>

                                    <Button
                                        onClick={hendlerNextFile}
                                        disabled={nrViewFiles === doc.attachments.length - 1}
                                        variant='contained'
                                        size='small'>
                                        <KeyboardArrowRightIcon fontSize='small' />
                                    </Button>
                                </div>
                                <Button onClick={handleClose}>Close</Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </AccordionDetails>
            </Accordion>
        </>
    )
}