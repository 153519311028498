import { IHistory } from "../models/NotificationModels";

const historyActions = "historyActions";
const max = 50;

class HistoryListService {

    public static getAllHistory = (user: string): Array<IHistory> => {
        let s = localStorage.getItem(historyActions)
        if (s) {
            let n: IHistory[] = []
            try {
                n = [...JSON.parse(s)].filter((e: IHistory) => e.userUUID === user);
            } catch (error) {
                console.error(error);
            }
            return n;
        } else {
            return []
        }
    }

    public static setHistory = async (m: IHistory, user: string) => {
        let n:IHistory[] = [];        
        try {
            n = this.getAllHistory(user);
        } catch (error) {
            console.error(error);
        }
        ;
        if (n) {            
                n = [...[m], ...n];
                if (n.length > max) { n = n.slice(0, max); }
                localStorage.setItem(historyActions, JSON.stringify(n));            
        }
        return true
    }
}


export default HistoryListService;