import { Suspense, useEffect, useState } from "react";
import { Box, Button, CircularProgress, Paper, Snackbar, Step, StepButton, StepContent, Stepper, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AppDispatch } from "../../store/store";
import { useDispatch } from "react-redux";
import { addNotification, setLastError } from "../../store/reducers/cnfSlice";
import GridViewIcon from '@mui/icons-material/GridView';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { INotification } from "../../models/NotificationModels";
import { ENotificationType } from "../../models/EnumGeneral";
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import CancelIcon from '@mui/icons-material/Cancel';

import CoverFaceRouteComponent from "./CoverFaceRouteComponent";
import { IRouteDetails, IRouteResponseDetails } from "../../models/RoutesModels";
import RouteServices from "../../services/RouteServices";
import { useSearchParams } from 'react-router-dom';




export default function EditRouteComponent() {
  const [activeStep, setActiveStep] = useState(1);
  const [editMode, setEditMode] = useState('edit');
  const { id } = useParams();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [textSnackbar, setTextSnackbar] = useState('');
  const [reset, setReset] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation().pathname;
  const [searchParams, setSearchParams] = useSearchParams();

  const postQuery = searchParams.get('step') || '';


  const [route, setRoute] = useState<IRouteResponseDetails | null>(null);
  const refreshAfretSave = () => { setReset(st => !st); }
  const snackMsg = (text: string) => {

    let n: INotification = {
      userUUID: " ",
      dt: 0,
      text: [text],
      state: false,
      type: ENotificationType.ADDITIONAL,
      link: location
    }
    dispatch(addNotification(n))
    setTextSnackbar(text);
    setOpenSnackbar(true);
  }

  const handleEditMode = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string | null,
  ) => {
    if (newValue) {
      setEditMode(newValue);
    }
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
    setSearchParams({ step: step.toString() });
  };



  const handleCloseSnackbar = () => { setOpenSnackbar(false); }


  useEffect(() => {

    if (id) {
      (async () => {
        const [error, response] = await RouteServices.getRouteInfo(id);
        if (response) { setRoute(response.data.body); }
        if (error) { dispatch(setLastError(error)) }
      })()
    }
  }, [id, reset, dispatch])

  return (
    <>

      <div>
        <Paper>
          {<CoverFaceRouteComponent routes={route} />}
        </Paper>
        <div style={{ display: "flex" }}>
        </div>
      </div>

      {editMode === 'edit' &&
        <div>
          <div style={{ marginTop: "20px", marginLeft: "35px" }}>
            <Stepper nonLinear activeStep={activeStep} orientation="vertical">


              <Step completed={false}>
                <StepButton color="inherit" onClick={handleStep(0)}>
                  {'registration'.toUpperCase()}
                </StepButton>
                <StepContent>
                  <div>
                    <Suspense fallback={<CircularProgress />}>
                      <Paper sx={{ marginTop: "10px" }}>
                        <Box sx={{ padding: 3, paddingRight: 0, marginBottom: "10px", marginTop: "1", display: "flex" }}>

                        </Box>
                      </Paper>
                    </Suspense>
                  </div>
                </StepContent>
              </Step>


              <Step completed={false}>
                <StepButton color="inherit" onClick={handleStep(1)}>
                  {'items'.toUpperCase()}
                </StepButton>
                <StepContent>
                  <div>
                    <Suspense fallback={<CircularProgress />}>
                      <Paper sx={{ marginTop: "10px" }}>
                        <Box sx={{ padding: 3, paddingRight: 0, marginBottom: "10px", marginTop: "1", display: "flex" }}>
                          <div>

                          </div>
                        </Box>
                      </Paper>
                    </Suspense>
                  </div>
                </StepContent>
              </Step>


              <Step completed={false}>
                <StepButton color="inherit" onClick={handleStep(2)}>
                  {'path'.toUpperCase()}
                </StepButton>
                <StepContent>

                  <Paper sx={{ marginTop: "10px" }}>
                    <Box sx={{ padding: 3, paddingRight: 0, marginTop: "1", display: "flex" }}>

                    </Box>
                  </Paper>
                </StepContent>
              </Step>


              <Step completed={false}>
                <StepButton color="inherit" onClick={handleStep(3)}>
                  {'transport'.toUpperCase()}
                </StepButton>
                <StepContent>

                  <Paper sx={{ marginTop: "10px" }}>
                    <Box sx={{ padding: 3, paddingRight: 0, marginTop: "1", display: "flex" }}>

                    </Box>
                  </Paper>
                </StepContent>
              </Step>


              <Step completed={false}>
                <StepButton color="inherit" onClick={handleStep(4)}>
                  {'user'.toUpperCase()}
                </StepButton>
                <StepContent>

                  <Paper sx={{ marginTop: "10px" }}>
                    <Box sx={{ padding: 3, paddingRight: 0, marginTop: "1", display: "flex" }}>

                    </Box>
                  </Paper>
                </StepContent>
              </Step>
              <Step completed={false}>
                <StepButton color="inherit" onClick={handleStep(5)}>
                  {'status'.toUpperCase()}
                </StepButton>
                <StepContent>

                  <Paper sx={{ marginTop: "10px" }}>
                    <Box sx={{ padding: 3, paddingRight: 0, marginTop: "1", display: "flex" }}>

                    </Box>
                  </Paper>
                </StepContent>
              </Step>
            </Stepper>
          </div>

        </div>}

      {/* {editMode === 'view' && warehouse && <VeiwTableWarehouseReportComponent toStep={hendlerToEdit} w={warehouse} />} */}

      <Paper sx={{ padding: "15px", marginTop: "20px", display: "flex", gap: "10px", justifyContent: "space-between" }} >
        <Box sx={{ display: "flex", gap: "10px" }}>
          <ToggleButtonGroup
            size={'small'}
            value={editMode}
            exclusive
            onChange={handleEditMode}
            aria-label=""
          >
            <ToggleButton value="edit" aria-label="">
              <AutoAwesomeIcon fontSize={'small'} color={editMode === "edit" ? 'warning' : 'inherit'} />
            </ToggleButton>
            <ToggleButton value="view" aria-label="">
              <GridViewIcon fontSize={'small'} color={editMode === "view" ? 'warning' : 'inherit'} />
            </ToggleButton >
          </ToggleButtonGroup>
        </Box>





        <Box sx={{ display: "flex", gap: "10px" }}>

        </Box>

      </Paper>


      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message=""
        anchorOrigin={{ vertical: "top", horizontal: 'center' }}
        action={<Button color="secondary" size="small"> lorem ipsum dolorem </Button>}
      >

        <Paper style={{ display: "flex", padding: "10px", minWidth: "350px", gap: "20px", border: "1px solid silver" }}>
          <div style={{ display: "flex", alignItems: "center", paddingLeft: "15px" }}>
            <GppMaybeIcon color={'success'} fontSize={'large'} />
          </div>
          <div style={{
            display: "flex",
            alignItems: "center",
            minWidth: "250px",
            justifyContent: "center"
          }}>
            <Typography variant='subtitle2' >
              {textSnackbar}
            </Typography>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button onClick={handleCloseSnackbar}><CancelIcon /></Button>
          </div>
        </Paper>
      </Snackbar>

      {/* <pre style={{ maxWidth: "600px", overflow: "hidden" }}>
        {JSON.stringify(warehouse, undefined, 2)}
      </pre> */}

    </>
  );
}