export interface IRouteCreateDto {
  name: string,
  startDate: number,
  endDate: number
}

export interface IRouteDetails {
  id?: string,
  uuid: string,
  name: string,
  startDate: number,
  endDate: number,
  status: string,
  state: string,
  readonly: boolean,
  amount: number,
  progress: IRouteProgress,
}
export interface IRouteResponseDetails {
  uuid: string,
  name: string,
  startDate: number,
  endDate: number,
  status: string,
  state: string,
  readonly: boolean,
  amount: number,
  progress: IRouteProgress,
  crudState: {
    step: number,
    completed: boolean,
  }
  createdDate: number,
  createdBy:string,
}

export interface IRouteProgress {
  total: number,
  completed: number,
}

export interface IRouteList {
  id?: string,
  uuid: string,
  name: string,
  status: string
}
export interface IRouteStatus {
  active: number;
  inactive: number;
  incomplete: number;
};

export enum ERouteStates {
  INITIALIZATION = 'INITIALIZATION',
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE'
}
export interface IResponseRoutesAutocomplete {
  uuid: string,
  name: string,
  startDate: number,
  endDate: number
}
export interface IResponseHotLoad {
  uuid: string,
  idno: string,
  hotDetails: {
    hot: boolean,
    hotItems: number,
    hottestItemDate: number,
  }
  progress: IRouteProgress,
}

