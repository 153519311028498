import { API } from "./APIService";

class GeneralService {

    public static getUserProfile = async (): Promise<any> => {
        const [error, response] = await API('GET', 'userProfile');
        return [error, response];
    }

    public static getServerTime = async (): Promise<any> => {
        const [error, response] = await API('GET', 'public/serverTime');
        return [error, response];
    }

    public static putUserProfile = async (body: Object): Promise<any> => {
        const [error, response] = await API('PUT', 'userProfile/preference/user', JSON.stringify(body));
        return [error, response];
    }

    public static putGlobalPreferenceProfile = async (body: Object): Promise<any> => {
        const [error, response] = await API('PUT', 'userProfile/preference/global', JSON.stringify(body));
        return [error, response];
    }

    public static getLinkOfPublicAttachment = async (file: string): Promise<any> => {
        const [error, response] = await API('GET', 'public/publicAttachment/' + file);
        return [error, response];
    }

    public static getBase64OfPublicAttachment = async (file: string): Promise<any> => {
        const [error, response] = await API('GET', 'public/publicAttachment/file/' + file);
        return [error, response];
    }

    public static postLinkOfPublicAttachment = async (body: Object): Promise<any> => {
        const [error, response] = await API('POST', 'publicAttachment', body);
        return [error, response];
    }

    public static deletePublicAttachment = async (body: Object): Promise<any> => {
        const [error, response] = await API('DELETE', 'publicAttachment', body);
        return [error, response];
    }

}

export default GeneralService;