import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Divider, Box, ListItem, ListItemIcon, ListItemButton, Checkbox, ListItemText, List } from '@mui/material';

import { setLastError } from "../../store/reducers/cnfSlice";
import { useDispatch } from "react-redux";

import { ITypeWarehouse } from "../../models/WarehouseModels";
import WarehouseService from "../../services/WarhouseService";
import { TagComponent } from "./TagComponent";

interface IProps {
  getList: (list: ITypeWarehouse[]) => void
  list?: ITypeWarehouse[]
}

export default function ListWarehouseTempComponent(props: IProps) {

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [checked, setChecked] = useState<number[]>([]);
  const [item, setItem] = useState<ITypeWarehouse[]>([]);
  const [types, setTypes] = useState<ITypeWarehouse[]>([]);
  const [completelist, setCompletelist] = useState<boolean>(false);


  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    const newItem = [...item];

    if (currentIndex === -1) {
      newChecked.push(value);
      newItem.push(types[value]);
    } else {
      newChecked.splice(currentIndex, 1);
      newItem.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    setItem(newItem);
    props.getList(newItem);
  };



  useEffect(() => {
    (async () => {
      const [error, response] = await WarehouseService.warehouseType();
      if (response) {
        let rs = response.data.body as ITypeWarehouse[];
        setTypes(rs);
        setCompletelist(true);
      }
      if (error) { dispatch(setLastError(error)); }
    })()
  }, [])

  useEffect(() => {
    let ch: number[] = [];
    let it: ITypeWarehouse[] = [];
    if (props.list) {
      props.list.map(e => {
        let i = types.findIndex(el => el.uuid === e.uuid);
        it.push(e);
        ch.push(i);
      })
    }
    setChecked(ch);
    setItem(it);
  }, [completelist, props.list])

  return (
    <>

      <Divider sx={{ paddingTop: "5px", fontSize: "16px" }} textAlign="left">TYPE</Divider>
      <Box sx={{ display: "flex", justifyContent: "center", minWidth: "100%" }}>
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
          {
            types.map((value, i) => {
              const labelId = `checkbox-list-label-${value.name}`;

              return (
                <ListItem dense
                  key={value.name}
                  secondaryAction={
                    <div>
                      <TagComponent size={25} value={value.name} />
                    </div>
                  }
                  disablePadding
                >
                  <ListItemButton role={undefined} onClick={handleToggle(i)} dense>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={checked.indexOf(i) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={`${value.name.split('_').join(' ')}`} />
                  </ListItemButton>
                </ListItem>
              );
            })}
        </List>
      </Box>
    </>
  );
}