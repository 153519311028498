import { AddressResponseDto } from "./AddressModels";
import { IFileDetailsResponse } from "./FileModel";
import { IRole } from "./IStaf";
import { EDateFormat, ETimeFormat } from "./ProfileModels";

export interface ISecure {
	accessToken: string;
	tokenType: string;
	refreshToken: string;
	expiryDuration: string;
}

export interface UserPersonalDataRequestDto {
	uuid: string;
	color: string;
	birthday: number;
	description: string;
	addressUuid: string;
}

export interface UserStatusRequestDto {
	uuid: string;
	status: string;
	activeFromDate: number;
	activeToDate: number;
}

export interface UserContactsResponseDto {
	uuid: string;
	type: string;
	value: string;
}

export interface UserRegistrationResponseDto {
	birthday: number;
	color: string;
	address: AddressResponseDto;
	description: string;
}

export interface UserStatusResponseDto {
	uuid: string;
	status: string;
	activeFromDate?: number;
	activeToDate?: number;
}

export interface UserAuthorityUpdateRequestDto {
	uuid: string;
	roleUuid: string;
}

export interface UserStepRequestDto {
	uuid: string;
	step: number;
}

export interface UserContactRequestDto {
	userId: string;
	contacts: IContactResponseDto[];
}

export interface IFavoritesUser {
	uuid: string;
	dt: string;
	user: IResponseAutocomplete;
}

export interface IResponseAutocomplete {
	uuid: string;
	firstName: string;
	lastName: string;
	email: string;
	fullName: string;
	username: string;
	avatar: string;
	color: string;
}

export interface IUser {
	avatar: string;
	email: string;
	firstName: string;
	lastName: string;
	phone: string;
	role: any
	status: string;
	username: string;
	online?: boolean;
}

export interface IInterfaceState {
	language: string;
	hints: boolean;
	menuState: number;
	presentMenu: boolean;
	presentFlip: boolean;
	rightFlip: boolean;
	leftFlip: boolean;
	darkMode: boolean;
	pathURL: string;
	dateFormat: EDateFormat;
	timeFormat: ETimeFormat;
	notificationPushEnabled: boolean;
	notificationEmailEnabled: boolean;
}

export interface IUserResponseDto {
	id?: string;
	uuid: string;
	firstName: string;
	lastName: string;
	username: string;
	email: string;
	status: string;
	color: string;
	avatar: string;
	role: string;
	readonly: boolean;
	activeFromDate: number;
	activeToDate: number;
	lastActivityDate: number;
	online: boolean;
}

export interface IUserSummary {
	uuid: string;
	username: string;
	color: string;
	birthday: number;
	primaryEmail: string;
	primaryPhone: string;
	additionalEmails: string[];
	additionalPhones: string[];
	address: string;
	role: string;
	status: string;
	available: boolean;
	statusActiveFromDate: number;
	statusActiveToDate: number;
	employee: boolean;
	employeeStartDate: number | null;
	employeeEndDate: number | null;
	employeeDescription: string;
	employeeNumberOfHoursPerDay: number;
	socialSecurityNumber: boolean | null;
	socialSecurityNumberIssueDate: number | null;
	socialSecurityNumberExpirationDate: number | null;
	driverLicense: boolean | null;
	driverLicenseIssueDate: number | null;
	driverLicenseExpirationDate: number | null;
	drugTest: boolean | null;
	drugTestIssueDate: number | null;
	drugTestExpirationDate: number | null;
}

export type TUserResponseDto = Array<IUserInfoResponseDto>;

export interface IUserMetadata {
	step: number;
	completed: boolean;
};

export interface IUserInfoResponseDto {
	id?: string;
	uuid: string;
	firstName: string;
	lastName: string;
	username: string;
	email: string;
	metadata: {
		step: number;
		completed: boolean;
	};
	avatar: string;
	role: IRole;
	online: boolean;
	readonly: boolean;
	status: string;
	activeFromDate: number;
	activeToDate: number;
	lastActivityDate: number;
	fullNameOrEmail: string;
}

export interface UserUpdateRequestDto {
	uuid: string;
	step: number;
	birthday: number;
	status: string;
	color: string;
	activeFromDate?: number;
	activeToDate?: number;
	description?: string;
	roleUuid?: string;
	addressUuid?: string | null;
	contacts?: IContactResponseDto[] | null;
}

export interface LocationResponseDto {
	uuid: string;
	country: string;
	state?: string;
	county?: string;
	city?: string;
	village?: string;
}

export interface IUserCreateRequestDto {
	email: string;
	step: number;
	color: string;
}

export interface IContactResponseDto {
	uuid: string;
	type: string;
	value: string;
}

export interface ICountryLocationDto {
	uuid: string;
	country: string;
	fullLocation?: string;
}

export interface IDocumentResponseDto {
	uuid: string;
	type: string;
	number: string;
	dateOfIssue: string;
	expirationDate: number;
	issueCountry: {
		uuid: string;
		country: string;
	};
	attachments: DocumentAttachmentResponseDto[];
}

export interface IDocumentRequestDto {
	uuid: string;
	type: string;
	number: string;
	dateOfIssue: string;
	expirationDate: string;
	issueCountryUuid: string;
}

export interface IDocumentCreateDto {
	userId: string;
	type: string;
	number: string;
	dateOfIssue: string;
	expirationDate: string;
	issueCountryUuid: string;
}

export interface DocumentAttachmentResponseDto {
	uuid: string;
	type: string;
	fileDetails: IFileDetailsResponse;
	readonly?: string;
}

export interface IFavoritesUserResponseDto {
	uuid: string;
	firstName: string;
	lastName: string;
	email: string;
	username: string;
	avatar: string;
	color: string;
}

export interface IUserShortResponseDto {
	uuid: string;
	firstName?: string;
	lastName?: string;
	email: string;
	username: string;
	avatar?: string;
	color?: string;
	fullName: string;
}

export enum EDocumentType {
	PASSPORT = 'PASSPORT',
	SOCIAL_SECURITY_CARD = 'SOCIAL_SECURITY_NUMBER',
	DRIVER_LICENSE = 'DRIVER_LICENSE',
	DRUG_TEST = 'DRUG_TEST',
	MEDICAL_CARD = 'MEDICAL_CARD'
}

export enum AttachmentType {
	MAIN = 'MAIN',
	ADDITIONAL = 'ADDITIONAL'
}

export enum EContactType {
	PRIMARY_PHONE = 'PRIMARY_PHONE',
	ADDITIONAL_PHONE = 'ADDITIONAL_PHONE',
	ADDITIONAL_EMAIL = 'ADDITIONAL_EMAIL',
	PRIMARY_EMAIL = 'PRIMARY_EMAIL'
}

export enum EIncomeType {
	HOURLY = 'HOURLY',
	CENTS_PER_MILE = 'CENTS_PER_MILE',
	PERCENTAGE = 'PERCENTAGE',
	DROP_PICKUPS = 'DROP_PICKUPS'
}

export enum EFavoritesType {
	USER = 'USER'
}

export enum EStatusStafServer {
	ACTIVE = 'ACTIVE',
	INACTIVE = 'INACTIVE',
	ARCHIVED = 'ARCHIVED',
	TEMPORARY = 'TEMPORARY',
	DEFAULT = 'DEFAULT',
	INCOMPLETE = 'INCOMPLETE'
}