import { useEffect, useState } from 'react'
import { Alert } from "@mui/material";

import MottoService from '../../services/MottoService';
import { setLastError } from '../../store/reducers/cnfSlice';
import { IMotto, TMottos } from '../../models/IMotto';
import { useDispatch } from 'react-redux';

const MottoTextComponent = () => {

    const [motto, setMotto] = useState<IMotto>();
    const dispatch = useDispatch();

    useEffect(() => {
        let isSubscribed = true;
        (async () => {
            const [error, response] = await MottoService.getActiveMottos();
            if (response) {
                let mottos: TMottos = response;
                if (mottos && mottos.length > 0) {
                    let n = Math.floor(Math.random() * mottos.length);
                    if (isSubscribed) {
                        setMotto(mottos[n])
                    };
                }
            }
            if (error) { }
        })();
        return () => { isSubscribed = false };
    }, [])

    return (
        <>
            {motto &&
                <Alert icon={false} severity="info">
                    <div style={{ textAlign: "center", width: "600px" }}>
                        {motto.text}
                        <p style={{ textAlign: "center", margin: "0px" }}>
                            <b>{motto.author}</b>
                        </p>
                    </div>
                </Alert>
            }
        </>
    )
}
export default MottoTextComponent;