import { Box, MenuItem, TextField, Tooltip, Typography } from "@mui/material";
import SCViewList from "../../../styledComponents/sharedComponent/SCViewList";
import { useEffect, useState } from "react";
import { EConsumptionType, EDistanceType, EEnginePowerType, EEngineVolumeType, EMassType, ESizeType, IGlobalProfile } from "../../../models/ProfileModels";
import { compareValue } from "../../../helpers/generalHelper";
import { RootState } from "../../../store/store";
import { useSelector } from "react-redux";
import SecureDialogComponent from "../../CustomComponentModule/SecureDialogComponent";
import { EButtonColor } from "../../../models/EnumGeneral";

interface IProps {
    globalProfileState: IGlobalProfile,
    getNewInterfaceState: (state: IGlobalProfile) => void;
}
const ASettingsSizeType = [
    {
        key: 'in',
        value: 'INCH (in)'
    },
    {
        key: 'ft',
        value: 'FOOT (ft)'
    },
    {
        key: 'cm',
        value: 'CENTIMETER (cm)'
    },
    {
        key: 'mm',
        value: 'MILLIMETER (mm)'
    }
]

const ASettingsDistanceType = [
    {
        key: 'nm',
        value: 'NAUTICAL MILE (nm)'
    },
    {
        key: 'mi',
        value: 'MILE (mi)'
    },
    {
        key: 'km',
        value: 'KILOMETER (km)'
    },
    {
        key: 'm',
        value: 'METER (m)'
    }
]
const ASettingsConsumptionType = [
    {
        key: 'MPG',
        value: 'MILES PER GALLON (MPG)'
    },
    {
        key: 'L/100km',
        value: 'LITRES 100 KM (L/100km)'
    }
]
const ASettingsMassType = [
    {
        key: 'ib',
        value: 'POUND (ib)'
    },
    {
        key: 'kg',
        value: 'KILOGRAM (kg)'
    },
    {
        key: 't',
        value: 'TON (t)'
    },

]
const ASettingsEngineVolumeType = [
    {
        key: 'l',
        value: 'LITER (l)'
    },

]
const ASettingsEnginePowerType = [
    {
        key: 'hp',
        value: 'HORSE POWER (hp)'
    },

]
const MessureSettingsComponent = (props: IProps) => {
    const { globalProfileState, getNewInterfaceState } = props
    const { hints } = useSelector((state: RootState) => state.cnfSlice.interfaceState);

    const [state, setState] = useState<IGlobalProfile>(globalProfileState);

    const [disabled, setDisabled] = useState<boolean>(true);

    useEffect(() => {
        setState(globalProfileState);
    }, [globalProfileState]);

    useEffect(() => {
        setDisabled(compareValue(state, globalProfileState));
    }, [globalProfileState, state]);

    const handlerSize = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value as ESizeType;
        setState((st) => { return { ...st, measureSize: value } })
    }
    const handlerDistance = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value as EDistanceType;
        setState((st) => { return { ...st, measureDistance: value } })
    }

    const handlerConsumption = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value as EConsumptionType;
        setState((st) => { return { ...st, measureConsumption: value } })
    }

    const handlerMass = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value as EMassType;
        setState((st) => { return { ...st, measureMass: value } })
    }

    const handlerEngineVolume = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value as EEngineVolumeType;
        setState((st) => { return { ...st, measureEngineVolume: value } })
    }

    const handlerEnginePower = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value as EEnginePowerType;
        setState((st) => { return { ...st, measureEnginePower: value } })
    }

    const handleSaveButton = () => {
        getNewInterfaceState(state);
    }
    
    return (
        <SCViewList>
            <SCViewList width={5}>

            </SCViewList>
            <SCViewList column gap={30} center>

                <SCViewList gap={40} center>
                    <SCViewList column gap={55} width={30} center>

                        <Typography color={"black"} variant="subtitle2"> SIZE</Typography>
                        <Typography color={"black"} variant="subtitle2"> DISTANCE</Typography>
                        <Typography color={"black"} variant="subtitle2"> CONSUMPTION</Typography>
                        <Typography color={"black"} variant="subtitle2"> MASS</Typography>
                        <Typography color={"black"} variant="subtitle2"> ENGINE VOLUME</Typography>
                        <Typography color={"black"} variant="subtitle2"> ENGINE POWER</Typography>

                    </SCViewList>
                    <SCViewList column gap={20} width={60}>
                        <TextField
                            select
                            value={state.measureSize}
                            inputProps={{ sx: { fontSize: "12px", } }}
                            sx={{ width: "200px" }}
                            onChange={handlerSize}
                        >
                            {ASettingsSizeType.map((option: any, i) =>
                                <MenuItem key={i} value={option.key}>
                                    {option.value.toUpperCase()}
                                </MenuItem>)}
                        </TextField>
                        <TextField
                            select
                            value={state.measureDistance}
                            inputProps={{ sx: { fontSize: "12px", } }}
                            sx={{ width: "200px" }}
                            onChange={handlerDistance}
                        >
                            {ASettingsDistanceType.map((option: any, i) =>
                                <MenuItem key={i} value={option.key}>
                                    {option.value.toUpperCase()}
                                </MenuItem>)}
                        </TextField>
                        <TextField
                            select
                            value={state.measureConsumption}
                            inputProps={{ sx: { fontSize: "12px", } }}
                            sx={{ width: "200px" }}
                            onChange={handlerConsumption}
                        >
                            {ASettingsConsumptionType.map((option: any, i) =>
                                <MenuItem key={i} value={option.key}>
                                    {option.value.toUpperCase()}
                                </MenuItem>)}
                        </TextField>
                        <TextField
                            select
                            value={state.measureMass}
                            inputProps={{ sx: { fontSize: "12px", } }}
                            sx={{ width: "200px" }}
                            onChange={handlerMass}
                        >
                            {ASettingsMassType.map((option: any, i) =>
                                <MenuItem key={i} value={option.key}>
                                    {option.value.toUpperCase()}
                                </MenuItem>)}
                        </TextField>
                        <TextField
                            select
                            value={state.measureEngineVolume}
                            inputProps={{ sx: { fontSize: "12px", } }}
                            sx={{ width: "200px" }}
                            onChange={handlerEngineVolume}
                        >
                            {ASettingsEngineVolumeType.map((option: any, i) =>
                                <MenuItem key={i} value={option.key}>
                                    {option.value.toUpperCase()}
                                </MenuItem>)}
                        </TextField>
                        <TextField
                            select
                            value={state.measureEnginePower}
                            inputProps={{ sx: { fontSize: "12px", } }}
                            sx={{ width: "200px" }}
                            onChange={handlerEnginePower}
                        >
                            {ASettingsEnginePowerType.map((option: any, i) =>
                                <MenuItem key={i} value={option.key}>
                                    {option.value.toUpperCase()}
                                </MenuItem>)}
                        </TextField>
                    </SCViewList>
                </SCViewList>
                <SCViewList flexEnd width={90}>
                    <Tooltip
                        title={(hints && !disabled) ? "Save global profile configurations" : ""}
                        placement="top"
                        arrow={true}
                    >
                        <Box>
                            <SecureDialogComponent
                                answerYES={() => handleSaveButton()}
                                text={'SAVE'}
                                iconButton={false}
                                color={EButtonColor.primary}
                                disabled={disabled}
                            />
                        </Box>
                    </Tooltip>
                </SCViewList>
            </SCViewList>
        </SCViewList>
    )
}
export default MessureSettingsComponent;