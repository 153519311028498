import { API } from "./APIService";

class PermissionService {

    public static getAllRoleLinks = async (): Promise<any> => {
        const [error, response] = await API('GET', 'role/privilege/link');
        return [error, response];
    }
    public static getAllRole = async (): Promise<any> => {
        const [error, response] = await API('GET', 'role');
        return [error, response];
    }
    public static getAllPrivilege = async (): Promise<any> => {
        const [error, response] = await API('GET', 'role/privilege');
        return [error, response];
    }

    public static isAvailablePrivilege = async (privilege: object): Promise<any> => {
        const [error, response] = await API('POST', 'role/privilege/available', JSON.stringify(privilege));
        return [error, response];
    }

    public static createPrivilege = async (body: Object): Promise<any> => {
        const [error, response] = await API('POST', 'role/privilege', JSON.stringify(body));
        return [error, response];
    }

    public static linkPrivilegeToRole = async (body: Object): Promise<any> => {
        const [error, response] = await API('POST', 'role/privilege/link', JSON.stringify(body));
        return [error, response];
    }

    public static updatePrivilege = async (body: Object): Promise<any> => {
        const [error, response] = await API('PUT', 'role/privilege', JSON.stringify(body));
        return [error, response];
    }

    public static deletePrivilege = async (uuid: string): Promise<any> => {
        const [error, response] = await API('DELETE', 'role/privilege/' + uuid);
        return [error, response];
    }
    public static unlinkPrivilege = async (body: Object): Promise<any> => {
        const [error, response] = await API('DELETE', 'role/privilege/unlink', JSON.stringify(body));
        return [error, response];
    }




}


export default PermissionService;