import React, {useEffect, useState} from 'react'
import NativeSelect from "@mui/material/NativeSelect";


import i18next from "i18next";
import { RootState } from '../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { modifyGlobalPreference, modifyLanguage } from '../../store/reducers/cnfSlice';
import { IGlobalProfile } from '../../models/ProfileModels';


const SelectLangComponent = () => {

    const dispatch = useDispatch();    
    const {language} = useSelector((state: RootState) => state.cnfSlice.interfaceState);
    
    const languages = ['en','ru'];
    const [defLang, setDefLang] = useState(language||'en');

    

   const handlerLanguage = (e:any)=>{
      const lang = e.target.value
      dispatch(modifyLanguage(lang)); 
      setDefLang(e.target.value);
   }

   useEffect(()=>{
       i18next.changeLanguage(defLang);
   },[defLang])

    return (
        <div>
            <NativeSelect sx={{marginTop:1}}
                onChange={handlerLanguage}
                defaultValue={defLang}
            >
                {languages.map(value =>
                    <option
                        key={value}
                        value={value}
                    >
                        {' '+value.toUpperCase()}
                    </option>
                )}
            </NativeSelect>
        </div>
    )
}

export default SelectLangComponent;