import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getDateFormat } from "../../../helpers/dateHelper";
import { deliveryStateList, ILoadDeliveryResponseDto, ILoadItemResponseDto } from "../../../models/LoadModels";
import LoadService from "../../../services/LoadService";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography, } from "@mui/material";
import EditDeliveryComponent from "../EditDeliveryComponent";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import SCViewList from "../../../styledComponents/sharedComponent/SCViewList";
import SaveAsIcon from '@mui/icons-material/SaveAs';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';

interface IProps {
    refreshAfretSave: () => void;
    snackMsg: (text: string) => void;
    handleStep: (n: number) => void;
}

const DeliveryComponent = (props: IProps) => {
    const { dateFormat, hints } = useSelector((state: RootState) => state.cnfSlice.interfaceState);
    const { refresh } = useSelector((state: RootState) => state.cnfSlice.load);

    const { id } = useParams();

    const [items, setItems] = useState<ILoadItemResponseDto[]>([]);
    const [item, setItem] = useState<ILoadDeliveryResponseDto | null>(null)
    const [delivery, setDelivery] = useState<ILoadDeliveryResponseDto[]>([]);
    const [stateList, setStateList] = useState<deliveryStateList[]>([])

    const [snapshot, setSnapshot] = useState("");

    const [error, setError] = useState(0)
    const [open, setOpen] = useState(false);
    const [editableMode, setEditableMode] = useState<boolean>(false);
    const [save, setSave] = useState<boolean>(true);

    useEffect(() => {
        (async () => {
            const [, res] = await LoadService.getDeliveryById(id ? id.toString() : "")
            if (res) {
                let b = res.data.body as ILoadDeliveryResponseDto[];
                let st: ILoadDeliveryResponseDto[] = b.map((e: ILoadDeliveryResponseDto) => ({ ...e, id: e.uuid }))

                setDelivery(st)
            }
        })()
    }, [id, refresh])

    useEffect(() => {
        let p: deliveryStateList[] = []
        for (let it of delivery) {
            p.push({ itemUuid: it.loadItem.uuid, deliveryUuid: it.uuid ? it.uuid : '' })
        }
        setStateList(p)
    }, [delivery])

    const activate = (
        e: ILoadDeliveryResponseDto | null
    ) => {
        setItem(e);
        setSnapshot(JSON.stringify(e));
        setOpen(true);
    }

    const handleSave = () => {
        setSave(true);
        setOpen(false);
        props.refreshAfretSave();
    };

    const handleClose = () => {
        setEditableMode(false);
        setOpen(false)
    };

    const columns: GridColDef[] = [
        {
            field: "1", headerName: '', sortable: false, filterable: false, disableColumnMenu: true, width: 40,
            renderCell: (cellValues: any) => {
                return (
                    <>
                        <IconButton color={'primary'} size="small"
                            onClick={() => {
                                activate(cellValues.row)
                            }}><AutoAwesomeIcon fontSize="small" /></IconButton>
                    </>
                );
            }
        },
        {
            field: "2", headerName: 'ITEM', sortable: false, filterable: false, disableColumnMenu: true, width: 190, headerAlign: "center",
            renderCell: (cellValues: any) => {
                return (
                    <>
                        <Typography variant={'body2'} fontSize={'11px'}>{cellValues.row.loadItem.name} ({cellValues.row.loadItem.idno}) </Typography>
                    </>
                );
            }
        },
        {
            field: "3", headerName: 'TYPE', sortable: false, filterable: false, disableColumnMenu: true, width: 100, headerAlign: "center",
            renderCell: (cellValues: any) => {
                return (
                    <>
                        <Typography variant={'body2'} fontSize={'11px'}>{cellValues.row.deliveryType} </Typography>
                    </>
                );
            }
        },
        {
            field: "4", headerName: 'PICKUP & DELIVERY', sortable: false, filterable: false, disableColumnMenu: true, width: 165, headerAlign: "center",
            renderCell: (cellValues: any) => {
                let row = cellValues.row;
                return (
                    <>
                        <SCViewList spaceBetween>
                            <div style={{ minWidth: "70px", display: "flex", justifyContent: "center" }}>
                                {row.pickupWarehouse &&
                                    <Tooltip
                                        title={hints ? row.pickupWarehouse.address.fullAddress : ''}
                                        arrow
                                    >
                                        <IconButton color={'primary'} size={'small'}>
                                            <WarehouseIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>

                                }
                                {row.pickupAddress &&
                                    <Tooltip
                                        title={hints ? row.pickupAddress.fullAddress : ''}
                                        arrow
                                    >
                                        <IconButton color={'primary'} size={'small'}>
                                            <AddLocationAltIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                }

                            </div>
                            {((row.pickupWarehouse || row.pickupAddress) && (row.deliveryWarehouse || row.deliveryAddress)) &&
                                <div style={{ minWidth: "4px", minHeight: "20px", borderLeft: "2px solid silver" }}></div>
                            }
                            <div style={{ minWidth: "70px", display: "flex", justifyContent: "center" }}>
                                {row.deliveryWarehouse &&
                                    <Tooltip
                                        title={hints ? row.deliveryWarehouse.address.fullAddress : ''}
                                        arrow
                                    >
                                        <IconButton color={'success'} size={'small'}>
                                            <WarehouseIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>

                                }
                                {row.deliveryAddress &&
                                    <Tooltip
                                        title={hints ? row.deliveryAddress.fullAddress : ''}
                                        arrow
                                    >
                                        <IconButton color={'success'} size={'small'}>
                                            <AddLocationAltIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                }
                            </div>

                        </SCViewList >
                    </>
                );
            }
        },
        {
            field: "5", headerName: 'DELIVERED BY', sortable: false, filterable: false, disableColumnMenu: true, width: 115, headerAlign: "center",
            renderCell: (cellValues: any) => {
                return (
                    <>
                        {cellValues.row.deliveredBy &&
                            <Typography variant={'body2'} fontSize={'11px'}>{getDateFormat(cellValues.row.deliveredBy, dateFormat)} </Typography>
                        }
                    </>
                );
            }
        },
    ];

    const handleError = (n: number) => {
        setError(n);
    }

    return (
        <>
            <div style={{ display: "flex", gap: 10, width: "100%" }}>
                <div style={{ display: "flex", flexDirection: "column", width: "100%", marginRight: "18px" }}>
                    <div style={{ height: "350px", width: "630px" }}>
                        <DataGrid
                            rows={delivery}
                            columns={columns}
                            disableSelectionOnClick
                            hideFooter
                        />
                    </div>
                </div>
            </div>

            <Dialog
                sx={{ '& .MuiDialog-paper': { width: '100%' } }}
                maxWidth="lg"
                keepMounted
                open={open}

            >
                <DialogTitle>{(editableMode) ? 'EDIT ' : 'VIEW '}LOAD ITEM PICKUP & DELIVERY </DialogTitle>
                <DialogContent>
                    <EditDeliveryComponent
                        onSave={() => setSave(false)}
                        setError={handleError}
                        save={save}
                        item={item ? item : null}
                        items={delivery}
                        snap={snapshot}
                        stateList={stateList}
                        editableMode={editableMode}
                        snackMsg={props.snackMsg}
                        refreshAfterSave={props.refreshAfretSave}
                    />
                </DialogContent>
                <DialogActions >
                    <Box sx={{
                        paddingBottom: "20px",
                        paddingRight: "20px",
                        display: "flex",
                        width: "100%",
                        paddingLeft: "45px",
                        justifyContent: "space-between"
                    }}>
                        <div style={{ display: "flex", width: "100%", paddingLeft: "20px", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", width: "250px", gap: "15px", paddingTop: "14px", height: "35px" }}>


                                {!editableMode &&
                                    <Tooltip
                                        title={(hints) ? 'Open load item delivery in editing mode' : ''}
                                        arrow
                                        placement="top"
                                    >
                                        <Button
                                            color="info"
                                            variant="contained"
                                            onClick={() => setEditableMode(true)}
                                        >
                                            <DriveFileRenameOutlineIcon />
                                        </Button>

                                    </Tooltip>
                                }
                            </div>

                            <div style={{ display: "flex", gap: "15px", width: "250px", justifyContent: "flex-end", marginTop: "14px" }}>
                                {editableMode &&
                                    <Button
                                        variant={'contained'}
                                        disabled={error !== 1}
                                        size={'small'}
                                        startIcon={<SaveAsIcon />}
                                        onClick={handleSave}
                                    >
                                        SAVE
                                    </Button>
                                }
                                <Button autoFocus onClick={handleClose} variant={'contained'} color={'warning'}>
                                    Cancel
                                </Button>
                            </div>
                        </div>

                    </Box>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default DeliveryComponent