import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Typography, Tooltip, Box, Divider } from '@mui/material';
import { modifyWarehousePart, setLastError } from "../../store/reducers/cnfSlice";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import WarehouseService from "../../services/WarhouseService";
import { EWarehouseStatus, IWarehouseStatistic } from "../../models/WarehouseModels";
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';


export default function WarehouseFiltersComponent() {

  const { hints } = useSelector((state: RootState) => state.cnfSlice.interfaceState);
  const { part, refresh } = useSelector((state: RootState) => state.cnfSlice.warehouse);

  const { t } = useTranslation();
  const dispatch = useDispatch();


  const handlerModifyTab = (s: string): void => { dispatch(modifyWarehousePart(s)); }

  const [countPar, setCountPar] = useState<IWarehouseStatistic>(
    { active: 0, inactive: 0, incomplete: 0 });
  useEffect(() => {
    (async () => {
      const [error, response] = await WarehouseService.warehouseStatistics();
      if (response) {
        setCountPar(response.data.body)
      }
      if (error) { dispatch(setLastError(error)) }
    })()
  }, [refresh])



  const styles = {
    item: { width: "40px" },
    box: { border: "1px solid silver", textAlign: "center", borderRadius: "3px", marginLeft: "2px", marginRight: "2px", marginBottom: "4px" },
    icon: { fontSize: "25px", cursor: "pointer", marginLeft: "8px" },
    button: { border: "1px solid silver" }
  };

  return (
    <>
      <div style={{
        display: "flex",
        gap: "2px",
        marginTop: "5px",
        justifyContent: "center",
        marginBottom: "10px"
      }}>

        <div style={styles.item}>
          <Box sx={styles.box}>
            <Typography sx={{ fontSize: "10px" }}>{countPar?.active}</Typography>
          </Box>
          <Tooltip
            title={(hints) ? t(EWarehouseStatus.ACTIVE) : ''}
            placement={"bottom"}
            arrow={true}
          >
            <AssignmentIndIcon
              onClick={() => { handlerModifyTab(EWarehouseStatus.ACTIVE) }}
              color={(part === EWarehouseStatus.ACTIVE) ? "primary" : "disabled"}
              sx={styles.icon}
            />
          </Tooltip>
        </div>

        <div style={styles.item}>
          <Box sx={styles.box}>
            <Typography sx={{ fontSize: "10px" }}>{countPar?.inactive}</Typography>
          </Box>
          <Tooltip
            title={(hints) ? t(EWarehouseStatus.INACTIVE) : ''}
            placement={"bottom"}
            arrow={true}
          > 
            <AssignmentLateIcon
              onClick={() => { handlerModifyTab(EWarehouseStatus.INACTIVE) }}
              color={(part === EWarehouseStatus.INACTIVE) ? "primary" : "disabled"}
              sx={styles.icon}
            />
          </Tooltip>
        </div>


        <div style={styles.item}>
          <Box sx={styles.box}>
            <Typography sx={{ fontSize: "10px" }}>{countPar?.incomplete}</Typography>
          </Box>
          <Tooltip
            title={(hints) ? t(EWarehouseStatus.INCOMPLETE) : ''}
            placement={"bottom"}
            arrow={true}
          >
            <IntegrationInstructionsIcon
              onClick={() => { handlerModifyTab(EWarehouseStatus.INCOMPLETE) }}
              color={(part === EWarehouseStatus.INCOMPLETE) ? "primary" : "disabled"}
              sx={styles.icon} />
          </Tooltip>
        </div>



      </div>
      <Divider />

    </>
  );
}


