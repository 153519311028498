interface IProps {
	width: number,
	heigth: number,
	color?: string
}

const ErrorPhotoComponent = (props: IProps) => {
	return (
		<div style={{ width: props.width, height: props.heigth }}>
			<svg width={props.width} height={props.heigth} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" version="1.1">
				<g transform="scale(0.521)">
					<circle style={{ fill: "#BF3603", stroke: "none" }} cx="96" cy="96" r="74" />
					<path style={{ fill: "#FFF" }} d="m 86,48 20,0 -4,72 -12,0 z" />
					<circle style={{ fill: "#FFF" }} cx="96" cy="140" r="10" />
				</g>
			</svg>
		</div>
	)
}

export default ErrorPhotoComponent;