import { IconButton, Tooltip } from "@mui/material";
import ErrorPhotoComponent from "./ErrorPhotoComponent";
import HelpProperMap from "./HelpProperComponent";
import YesPhotoComponent from "./YesPhotoComponent";

import SaveAsIcon from '@mui/icons-material/SaveAs';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";

interface IProps {
    error: number,
    xObject?: any,
    textErrors: Array<string>,
    disabledReset?: boolean,
    handlerReset: (x: Object) => void,
    handlerSave: () => void
}

const ControlStateInCRUDComponent = (props: IProps) => {

    const { error, xObject, textErrors, disabledReset, handlerReset, handlerSave } = props;
    const { hints } = useSelector((state: RootState) => state.cnfSlice.interfaceState);

    return (
        <>
            <div style={{ flex: "1 1 auto", display: "flex", flexDirection: "column", alignItems: "flex-end", justifyContent: "center" }} >
                {(error === -1) && <div style={{
                    display: "flex",
                    gap: 1,
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    maxWidth: "50px",
                    border: "1px dashed silver",
                    borderRadius: "5px"
                }}>
                    <Tooltip
                        title={(hints && !disabledReset) ? 'Reset' : ''}
                        arrow={true}
                        placement="top"
                    >
                        <IconButton onClick={() => (xObject) ? handlerReset(xObject) : handlerReset({})} disabled={disabledReset} >
                            <RotateLeftIcon color={'disabled'} />
                        </IconButton>
                    </Tooltip>

                    <ErrorPhotoComponent width={23} heigth={23} />
                    <HelpProperMap texts={textErrors}></HelpProperMap>
                </div>
                }

                {(error === 1) && <div style={{
                    display: "flex",
                    gap: 1,
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    maxWidth: "50px",
                    border: "1px dashed silver",
                    borderRadius: "5px"
                }}>
                    <Tooltip
                        title={(hints) ? 'Reset' : ''}
                        arrow={true}
                        placement="top"
                    >
                        <IconButton onClick={() => (xObject) ? handlerReset(xObject) : handlerReset({})} >
                            <RotateLeftIcon color={'disabled'} />
                        </IconButton>
                    </Tooltip>
                    <YesPhotoComponent width={20} heigth={20} />
                    <Tooltip
                        title={(hints) ? 'Save' : ''}
                        arrow={true}
                        placement="bottom"
                    >
                        <IconButton onClick={handlerSave} ><SaveAsIcon color={'info'} /></IconButton>
                    </Tooltip>
                </div>}
            </div >
        </>
    )
}

export default ControlStateInCRUDComponent;