import { IconButton, Rating, Typography } from '@mui/material';
import { Box } from '@mui/system';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useEffect, useState } from 'react';
import { IReviewModel, IWarehouseDetails } from '../../models/WarehouseModels';
import WarehouseService from '../../services/WarhouseService';
import { useDispatch, useSelector } from 'react-redux';
import { setLastError } from '../../store/reducers/cnfSlice';
import { RootState } from '../../store/store';
import { getDateFormat } from '../../helpers/dateHelper';
import SCViewList from '../../styledComponents/sharedComponent/SCViewList';

interface IProps {
    warehouse: IWarehouseDetails | null
}
const ReviewWarehouseComponent = (props: IProps) => {
    const { warehouse } = props
    const { dateFormat } = useSelector((state: RootState) => state.cnfSlice.interfaceState);

    const [state, setState] = useState<IReviewModel[]>([])
    const [actualView, setActualView] = useState<number>(0)

    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            if (warehouse) {
                const [error, response] = await WarehouseService.getWarehouseReview(warehouse.uuid);
                if (response) { setState(response.data.body); }
                if (error) { dispatch(setLastError(error)) }
            }
        })()
    }, [warehouse]);

    const handlePreviusView = () => {
        setActualView(actualView - 1);
    }
    const handleForwardView = () => {
        setActualView(actualView + 1);
    }

    return (
        <>
            <Box sx={{ display: "flex" }}>
                {(actualView !== 0 && state.length >= 1) ?
                    <Box sx={{ display: "flex", justifyContent: "left", alignItems: "center", width: "50px" }}>
                        <IconButton onClick={handlePreviusView}>
                            <ArrowBackIosNewIcon />
                        </IconButton>
                    </Box>
                    : <Box sx={{ width: "50px" }} />
                }
                {(state.length >= 1)
                    ? <Box sx={{ display: "flex", flexDirection: "column", gap: "20px", width: "450px", padding: "30px" }}>
                        <SCViewList gap={10} spaceBetween>
                            <SCViewList column>
                                <Typography>{(state[actualView].email) ? state[actualView].email : ""}</Typography>
                                {
                                    (state[actualView].mark)
                                        ? <Rating value={state[actualView].mark} readOnly></Rating>
                                        : <div></div>
                                }
                            </SCViewList>
                            <div style={{ minWidth: "100px", paddingLeft: "10px", borderLeft: "1px solid silver", maxHeight: "20px" }}>
                                {
                                    (state[actualView].createdDate)
                                        ? getDateFormat(state[actualView].createdDate, dateFormat)
                                        : ''
                                }
                            </div>
                        </SCViewList>
                        <div style={{ border: "1px solid silver", padding: "10px", borderRadius: "3px", minHeight: "50px", fontSize: "10px" }}>
                            {
                                (state[actualView].message)
                                    ? state[actualView].message
                                    : ''
                            }
                        </div>
                    </Box>
                    : <div style={{ border: "1px dashed silver", display: "flex", padding: "10px", borderRadius: "3px", minHeight: "50px", width: "500px", fontSize: "15px", textAlign: "center", alignItems: "center", justifyContent: "center" }}>There are no reviews.</div>
                }

                {((state.length - 1) > actualView) &&
                    <Box sx={{ display: "flex", justifyContent: "right", alignItems: "center", width: "50px" }}>
                        <IconButton onClick={handleForwardView}>
                            <ArrowForwardIosIcon />
                        </IconButton>
                    </Box>

                }
            </Box >
        </>
    )
}
export default ReviewWarehouseComponent;