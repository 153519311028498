import { useTranslation } from "react-i18next";
import { Typography, Tooltip, Box } from '@mui/material';
import { modifyStafPart } from "../../store/reducers/cnfSlice";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';

import { IStatus } from "../../models/IStaf";
import { RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { EStatusStafServer } from "../../models/IUser";

interface IProps {
  countPar:IStatus
}

export default function TabsFiltersComponent(props:IProps) {

  const { hints } = useSelector((state: RootState) => state.cnfSlice.interfaceState);
  const { part } = useSelector((state: RootState) => state.cnfSlice.staf);
  const {countPar} = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();  

  const handlerModifyTab = (s: string): void => {
    dispatch(modifyStafPart(s));
  }
  

  const styles = {
    item: { width: "40px" },
    box: { border: "1px solid silver", textAlign: "center", borderRadius: "3px", marginLeft:"2px", marginRight:"2px",  marginBottom:"4px" },
    icon: { fontSize: "25px", cursor: "pointer", marginLeft: "8px" },
    button: { border: "1px solid silver" }
  };

  return (
    <>
      <div style={{
        display: "flex",
        gap: "2px",
        marginTop: "5px",
        justifyContent: "center",
        marginBottom: "10px"
      }}>
       
        <div style={styles.item}>
          <Box sx={styles.box}>
            <Typography sx={{ fontSize: "10px" }}>{countPar?.active}</Typography>
          </Box>
          <Tooltip
            title={(hints) ? t("active").toLocaleUpperCase() : ''}
            placement={"bottom"}
            arrow={true}
          >
            <AssignmentIndIcon
              onClick={() => { handlerModifyTab(EStatusStafServer.ACTIVE) }}
              color={(part === EStatusStafServer.ACTIVE) ? "primary" : "disabled"}
              sx={styles.icon}
            />
          </Tooltip>
        </div>
       
        <div style={styles.item}>
          <Box sx={styles.box}>
            <Typography sx={{ fontSize: "10px" }}>{countPar?.temporary}</Typography>
          </Box>
          <Tooltip
            title={(hints) ? t("temporary").toLocaleUpperCase() : ''}
            placement={"bottom"}
            arrow={true}
          >
            <AssignmentTurnedInIcon
              onClick={() => { handlerModifyTab(EStatusStafServer.TEMPORARY) }}
              color={(part === EStatusStafServer.TEMPORARY) ? "primary" : "disabled"}
              sx={styles.icon}
            />
          </Tooltip>
        </div>
        
        {/* <div style={styles.item}>
          <Box sx={styles.box}>            
          </Box>
          <Tooltip
            title={(hints) ? t("arhive") : ''}
            placement={"bottom"}
            arrow={true}
          >
            <AssignmentReturnedIcon
              onClick={() => { handlerModifyTab(EStatusStafServer.ARCHIVED) }}
              color={(part === EStatusStafServer.ARCHIVED) ? "primary" : "disabled"}
              sx={styles.icon} />
          </Tooltip>
        </div> */}
       
        <div style={styles.item}>
          <Box sx={styles.box}>
            <Typography sx={{ fontSize: "10px" }}>{countPar?.inactive}</Typography>
          </Box>
          <Tooltip
            title={(hints) ? t("inactive").toLocaleUpperCase() : ''}
            placement={"bottom"}
            arrow={true}
          >
            <AssignmentLateIcon
              onClick={() => { handlerModifyTab(EStatusStafServer.INACTIVE) }}
              color={(part === EStatusStafServer.INACTIVE) ? "primary" : "disabled"}
              sx={styles.icon} />
          </Tooltip>
        </div>       
        
        <div style={styles.item}>
          <Box sx={styles.box}>
            <Typography sx={{ fontSize: "10px" }}>{countPar?.incomplete}</Typography>
          </Box>
          <Tooltip
            title={(hints) ? t("incomplete").toLocaleUpperCase() : ''}
            placement={"bottom"}
            arrow={true}
          >
            <IntegrationInstructionsIcon
              onClick={() => { handlerModifyTab(EStatusStafServer.INCOMPLETE) }}
              color={(part === EStatusStafServer.INCOMPLETE) ? "primary" : "disabled"}
              sx={styles.icon} />
          </Tooltip>
        </div> 

      </div>     
    </>
  );
}

