
import to from 'await-to-js';
import axios, { AxiosResponse } from 'axios';
import { getTokens } from './TokensService';
import { settings } from "../configs/settings";

type MethodType = "GET" | "POST" | "PUT" | "DELETE" | "HEAD";

export const API = async (
  method: MethodType,
  url: string,
  body?: any,
  params?: any
): Promise<[Error | null, AxiosResponse<any> | undefined]> => {

  const { accessToken } = getTokens(); 

  let [error, response] = await
    to(
      axios(
        {
          method: method,
          baseURL: settings.baseURL,
          url: url,
          headers: {
            "Authorization": accessToken?.trim() !== '' ? "Bearer " + accessToken : '',
            "Content-Type": "application/json"
          },
          data: body ? body : '',
          params: params ? params : '',          
        }
      )
    )

  return [error, response]
}