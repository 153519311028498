
import { Button, Divider, IconButton, Paper, Snackbar, Tooltip, Typography } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useEffect, useState } from 'react';
import { TRoles } from '../models/IStaf';
import StafService from '../services/StafService';
import { addNotification, modifyLeftFlip, setLastError } from '../store/reducers/cnfSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store/store';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import { useTranslation } from 'react-i18next';
import { INotification } from '../models/NotificationModels';
import { ENotificationType } from '../models/INotifications';

import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import CancelIcon from '@mui/icons-material/Cancel';

import GridWarehouseComponent from '../components/WarehouseModule/GridWarehouseComponent';
import WarehouseButtonBarComponent from '../components/WarehouseModule/WarehouseButtonBarComponent';
import ListWarehouseComponent from '../components/WarehouseModule/ListWarehouseComponent';
import WarehouseFiltersComponent from '../components/WarehouseModule/WarehouseTabsFiltersComponent';
import EditWarehouseComponent from '../components/WarehouseModule/EditWarehouseComponent';
import RoleWarehouseComponent from '../components/WarehouseModule/RoleWarehouseComponent';
import { WarehouseMapBoxComponent } from '../components/WarehouseModule/WarehouseMapBoxComponent';

const styled = {
    icon: { fontSize: "28px", cursor: "pointer" }
}

export default function WarehousePage() {


    const { hints, leftFlip } = useSelector((state: RootState) => state.cnfSlice.interfaceState);
    const { grid } = useSelector((state: RootState) => state.cnfSlice.warehouse);

    const { t } = useTranslation();
    const { id } = useParams();
    const dispatch = useDispatch<AppDispatch>();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [textSnackbar, setTextSnackbar] = useState('');
    const location = useLocation().pathname;
    const navigate = useNavigate();

    const isValidId = (id: any) => {
        if (!id) { return false }
        return true;
    }
    const snackMsg = (text: string) => {

        let n: INotification = {
            userUUID: " ",
            dt: 0,
            text: [text],
            state: false,
            type: ENotificationType.ADDITIONAL,
            link: location
        }
        dispatch(addNotification(n))
        setTextSnackbar(text);
        setOpenSnackbar(true);
    }
    const handleCloseSnackbar = () => { setOpenSnackbar(false); }
    const hendleShowLeft = () => {
        dispatch(modifyLeftFlip());
    }

    const [allRoles, setAllRoles] = useState<TRoles>(null);
    useEffect(() => {
        (async () => {
            const [error, data] = await StafService.getAllRole();
            if (data) { setAllRoles(data); }
            if (error) { dispatch(setLastError(error)); }
        })()
    }, [dispatch])

    const linkTo = (uuid: string) => {
        navigate('/warehouse/' + uuid)
    }

    return (

        <div style={{ width: "100%", height: "calc(100vh - 140px)", display: "flex", alignContent: "stretch", gap: "20px" }} >
            {leftFlip &&
                <Paper sx={{ flex: 1, maxWidth: "60px", textAlign: "center", padding: 1, paddingTop: "5px", paddingBottom: "2px" }}>
                    <div style={{ marginBottom: "2px" }} >
                        <Tooltip
                            title={(hints) ? t("staf") : ''}
                            placement={"top"}
                            arrow={true}
                        >
                            <IconButton onClick={hendleShowLeft} >
                                <MultipleStopIcon sx={styled.icon} color={"primary"} />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <Divider></Divider>
                </Paper>
            }

            {(!leftFlip) &&
                <Paper style={{ flex: 1, maxWidth: "240px", padding: "5px", paddingTop: "0px", }}>
                    <WarehouseButtonBarComponent snackMsg={snackMsg} />
                    <WarehouseFiltersComponent />
                    <ListWarehouseComponent />
                    <RoleWarehouseComponent />
                </Paper>}

            {(!leftFlip) &&
                <Paper sx={{ flex: 1, padding: 2, width: "auto", paddingTop: "2px", paddingBottom: "2px", paddingRight: "10px", }}>
                    {grid ? <GridWarehouseComponent /> : <WarehouseMapBoxComponent linkTo={linkTo} />}
                </Paper>
            }

            {isValidId(id) &&
                <div style={{ flex: 1, width: "auto", minWidth: "750px", maxWidth: "750px" }}>
                    <EditWarehouseComponent snackMsg={snackMsg} />
                </div>
            }

            <Snackbar
                open={openSnackbar}
                autoHideDuration={5000}
                onClose={handleCloseSnackbar}
                message=""
                anchorOrigin={{ vertical: "top", horizontal: 'center' }}
                action={<Button color="secondary" size="small"> lorem ipsum dolorem </Button>}
            >


                <Paper style={{ display: "flex", padding: "10px", minWidth: "350px", gap: "20px", border: "1px solid silver" }}>
                    <div style={{ display: "flex", alignItems: "center", paddingLeft: "15px" }}>
                        <GppMaybeIcon color={'success'} fontSize={'large'} />

                    </div>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        minWidth: "250px",
                        justifyContent: "center"
                    }}>
                        <div>
                            <Typography variant='subtitle2' >
                                {textSnackbar}
                            </Typography>

                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Button onClick={handleCloseSnackbar}><CancelIcon /></Button>
                    </div>
                </Paper>
            </Snackbar>

        </div>
    );
}