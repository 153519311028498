import { useEffect, useState } from "react";
import { compareValue } from "../../helpers/generalHelper";
import { EContactType, IUserInfoResponseDto, IUserMetadata, UserContactRequestDto, UserContactsResponseDto, UserStepRequestDto, UserUpdateRequestDto } from "../../models/IUser";
import StafService from "../../services/StafService";
import ControlStateInCRUDComponent from "../CustomComponentModule/ControlStateInCRUDComponent";
import MailListComponent from "./MailListComponent";
import PhoneListComponent from "./PhoneListComponent";
import { realStringInfoCoverFace } from "../../helpers/textHelper";

interface IProps {
    user: IUserInfoResponseDto | null;
    userContact: UserContactsResponseDto[],
    userMetadata: IUserMetadata | null,
    refreshAfretSave: (us: IUserInfoResponseDto) => void;
    snackMsg: (text: string) => void;
    handleStep: (step: number) => void;
}
const ContactsUserComponent = (props: IProps) => {

    const { user, userContact, userMetadata } = props;

    const [erMail, setErMail] = useState(false);
    const [erPhone, setErPhone] = useState(false);
    const [error, setError] = useState(0);
    const [listM, setListM] = useState([]);
    const [listP, setListP] = useState([]);

    const [copyUser, setCopyUser] = useState<UserContactsResponseDto[]>([]);
    const [save, setSave] = useState<boolean>(false);

    const [q, setQ] = useState<any>();

    const request = (erMail: boolean, erPhone: boolean): number => {
        if (erPhone || erMail) return -1;
        let l = [...listP, ...listM];
        if (!compareValue(l, copyUser)) return 1;
        return 0;
    }

    useEffect(() => {
        if (save) {
            (async () => {
                let body: UserContactRequestDto;
                if (user) {

                    body = {
                        userId: user.uuid,
                        contacts: [...listP, ...listM]
                    }
                    setQ(body);
                    const [error, data] = await StafService.saveUserContacts(body);
                    if (data) {
                        if (!userMetadata?.completed) {
                            const [error, response] = await StafService.getUserMetadata(user.uuid);
                            if (response) {
                                if (response.data.body.step !== 6) {
                                    let bd: UserStepRequestDto = {
                                        uuid: user.uuid,
                                        step: 4
                                    }
                                    const [err, dt] = await StafService.saveUserStep(bd);
                                } else { props.handleStep(5) }
                            }
                        }


                        setError(0);
                        props.refreshAfretSave(user);
                        let snackMessText = ((realStringInfoCoverFace(user?.firstName) === '') || (realStringInfoCoverFace(user?.firstName) === ''))
                            ? `${user.email}`
                            : `${user.firstName} ${user.lastName}`
                        props.snackMsg('Contacts was successfully saved for ' + snackMessText + '.')
                    }
                    if (error) { }
                }
            })()
        }
        setSave(false);
    }, [save])


    useEffect(() => {
        if (userContact) {
            let addMail = userContact.filter((e: UserContactsResponseDto) => e.type === EContactType.ADDITIONAL_EMAIL);
            let primaryPhone = userContact.filter((e: UserContactsResponseDto) => e.type === EContactType.PRIMARY_PHONE);
            let addPhone = userContact.filter((e: UserContactsResponseDto) => e.type === EContactType.ADDITIONAL_PHONE);

            setCopyUser([...primaryPhone, ...addPhone, ...addMail])
        }
        setErMail(false);
        setErPhone(false);
        setError(0);
        setListM([]);
        setListP([]);
        setSave(false);
        setQ(null);
    }, [userContact])

    useEffect(() => {
        setError(request(erMail, erPhone))
    }, [erMail, erPhone, listP, listM]);

    const hendlerStateMail = (s: any) => { setListM(s); }
    const hendlerErrorMail = (b: boolean) => { setErMail(b); }
    const hendlerStatePhone = (s: any) => { setListP(s); }
    const hendlerErrorPhone = (b: boolean) => { setErPhone(b); }
    const handlerReset = () => {
        if (userContact) {
            let addMail = userContact.filter((e: UserContactsResponseDto) => e.type === EContactType.ADDITIONAL_EMAIL);
            let primaryPhone = userContact.filter((e: UserContactsResponseDto) => e.type === EContactType.PRIMARY_PHONE);
            let addPhone = userContact.filter((e: UserContactsResponseDto) => e.type === EContactType.ADDITIONAL_PHONE);


            setCopyUser([...primaryPhone, ...addPhone, ...addMail])
        }
        setError(0);

    }
    const handlerSaveUser = () => { setSave(true); }

    return (
        <>
            <div style={{ display: "flex", width: "620px", justifyContent: "space-between" }}>
                <div>
                    <MailListComponent
                        setJSONState={hendlerStateMail}
                        setMailsError={hendlerErrorMail}
                        user={user}
                        userContact={copyUser}
                        disable={error}
                    />
                    <div style={{ marginBottom: "10px", marginTop: "10px" }}>
                    </div>
                    <PhoneListComponent
                        setJSONState={hendlerStatePhone}
                        setPhonesError={hendlerErrorPhone}
                        user={user}
                        userContact={copyUser}
                        disable={error}
                    />
                </div>

                <div style={{ display: "flex", minWidth: "53px" }} >
                    <ControlStateInCRUDComponent
                        error={error}
                        textErrors={['* contact list contains invalid data']}
                        handlerReset={handlerReset}
                        handlerSave={handlerSaveUser}
                    />
                </div>
            </div>
        </>
    )
}
export default ContactsUserComponent;