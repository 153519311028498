import { Button, Popover, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { IErrorInList } from "../../models/CustomModels";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

import { EContactType, IContactResponseDto, IResponseAutocomplete, IUserInfoResponseDto, UserContactsResponseDto, } from "../../models/IUser";

import ClearIcon from "@mui/icons-material/Clear";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { isValidPhone } from "../../helpers/generalHelper";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import StafService from "../../services/StafService";
import { useNavigate } from "react-router";
interface IPropsPhoneListComponent {
  user: IUserInfoResponseDto | null;
  userContact: UserContactsResponseDto[];
  setJSONState: (c: any) => void;
  setPhonesError: (b: boolean) => void;
  disable: number
}
const PhoneListComponent = (props: IPropsPhoneListComponent) => {
  const { user, setJSONState, setPhonesError, userContact } = props;

  const [phoneList, setPhoneList] = useState<IContactResponseDto[]>([]);
  const [phonePrimary, setPhoneParimary] = useState<IContactResponseDto>();
  const [allPhoneError, setAllPhoneError] = useState<Array<IErrorInList>>([]);
  const [deleteState, setDeleteState] = useState(false);

  useEffect(() => {

    let c = phoneList.map((e: IContactResponseDto) => {
      if (e.uuid[0] === "#") {
        const { uuid, ...rest } = e;
        return rest;
      } else return e;
    });
    setJSONState(c);

    let b: boolean = allPhoneError.filter((e) => e.isError === true).length > 0;
    setPhonesError(b);
  }, [allPhoneError, phoneList, deleteState]);

 
  useEffect(() => {

    if (userContact) {
      let list: IContactResponseDto[] = [];

      let errors: Array<IErrorInList> = [];
      if (userContact) {
        list = userContact.filter(
          (c) => (c.type === EContactType.PRIMARY_PHONE)
        );
        if (list.length > 0) {
          errors.push({
            uuid: list[0].uuid,
            isError: false,
          });
        }
      }

      if (!list[0]) {
        let mp: IContactResponseDto = {
          uuid: "#" + Math.random() * 100 + '-' + Math.random(),
          type: EContactType.PRIMARY_PHONE,
          value: "",
        };
        list.push(mp);
        errors.push({
          uuid: mp.uuid,
          isError: true,
        });
      }

      if (userContact) {
        let additional = userContact.filter((c) => (c.type === EContactType.ADDITIONAL_PHONE));
        list = [...list, ...additional];

        for (let i in additional) {
          errors.push({
            uuid: additional[i].uuid,
            isError: false,
          });
        }
      }
      setPhoneList(list);
      setAllPhoneError(errors);
    }
  }, [userContact]);

  const hendlerDelete = (uuid: string) => {
    setAllPhoneError((list) => list.filter((e) => e.uuid !== uuid));
    setPhoneList((list) => list.filter((e) => e.uuid !== uuid));
    setDeleteState(!deleteState)
  };

  const hendlerError = (uuid: string, v: boolean) => {
    setAllPhoneError((list) =>
      list.map((e) => (e.uuid === uuid ? { ...e, isError: v } : e))
    );
  };

  const hendlerValue = (uuid: string, v: string) => {
    setPhoneList((list) =>
      list.map((e) => (e.uuid === uuid ? { ...e, value: v } : e))
    );
  };

  const hendlerNewPhone = () => {
    let newPhone: IContactResponseDto = {
      uuid: "#" + Math.random() * 1000,
      type: EContactType.ADDITIONAL_PHONE,
      value: "",
    };

    let e: any = allPhoneError.push({ uuid: newPhone.uuid, isError: true });
    setPhoneList(e);

    let x = [...phoneList, newPhone];
    setPhoneList(x);
  };

  return (
    <>

      {phoneList[0] && (
        <div style={{ display: "flex", gap: "10px", marginBottom: "10px", justifyContent: "space-between", }}>
          <PhoneElementComponent
            uuid={user?.uuid ? user?.uuid : ""}
            getValue={hendlerValue}
            deletePhone={hendlerDelete}
            state={allPhoneError.filter((er) => er.uuid === phoneList[0].uuid)[0].isError}
            getError={hendlerError}
            phone={phoneList[0]}
            disable={props.disable}
          />
          <Button
            size="small"
            onClick={hendlerNewPhone} color={"info"}>
            <AddBoxIcon />
          </Button>
        </div>
      )}

      <div
        style={{
          display: "flex",
          gap: "10px",
          flexDirection: "column",
          marginTop: "7px",
        }}
      >
        {phoneList &&
          phoneList.filter((er) => er.type !== EContactType.PRIMARY_PHONE).map((e, i) => (
            <PhoneElementComponent
              uuid={user?.uuid ? user?.uuid : ''}
              getValue={hendlerValue}
              deletePhone={hendlerDelete}
              getError={hendlerError}
              phone={e}
              key={i}
              state={
                allPhoneError.filter((er) => er.uuid === e.uuid)[0].isError
              }
              disable={props.disable}
            />
          ))}
      </div>


    </>
  );
};
export default PhoneListComponent;

interface IPropsPhoneElementComponent {
  phone: IContactResponseDto;
  state: boolean;
  getError: (uuid: string, v: boolean) => void;
  deletePhone: (uuid: string) => void;
  getValue: (uuid: string, v: string) => void;
  disable: number,
  uuid: string
}
const PhoneElementComponent = (props: IPropsPhoneElementComponent) => {
  const { phone, getError, state, deletePhone, getValue } = props;

  const [eValue, setEvalue] = useState<string>("");
  const [error, setError] = useState<boolean>();
  const [used, setUsed] = useState(false);
  const [usedUser, setUsedUser] = useState<IResponseAutocomplete | null>();
  const navigate = useNavigate();

  useEffect(() => {
    setEvalue(phone.value);
    setError(state);
    setUsed(false);
    setUsedUser(null);
    return setAnchorEl(null)
  }, [phone]);

  const handlerValue = (event: any) => {
    let value: string = (event.target.value as string).trim(),
      isUsed = false;

    if (value) {
      let isError = !isValidPhone(value);
      if (!isError) {
        (async () => {
          const [error, response] = await StafService.isTrueContact(value)
          if (response) {
            let ras = response.data.body.available;
            isUsed = ras;
            if (response.data.body.user) {
              if (props.uuid === response.data.body.user.uuid && phone.type === EContactType.PRIMARY_PHONE) {
                isUsed = true;
              }
              setUsedUser(response.data.body.user)
            } else { 
              setUsedUser(null) 
            }

            setUsed(!isUsed);
            if (!isUsed) { isError = true }
            setError(isError);
            getError(phone.uuid, isError);
          }
        })()
      } else {
        setUsed(false)
        setUsed(isUsed);
        setError(isError);
        getError(phone.uuid, isError);
      };
      getValue(phone.uuid, value);
    }

    setEvalue(value);
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const hendlerLink = () => {
    if (usedUser) {
      navigate('/user/' + usedUser.uuid);
    }
  }
  const linkTel = useRef<HTMLAnchorElement | null>(null);
  const handlerLinkTel = () => {
    linkTel.current?.click()
  }

  return (
    <>
      <div style={{ display: "flex", gap: "10px" }}>
        <div
          style={{
            width: "65px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {!error ?
            !usedUser ?
              <div>
                <Button
                  size="small"
                  color={"info"}
                  disabled={props.disable !== 0}
                  onClick={handlerLinkTel}
                >
                  <PhoneInTalkIcon />
                </Button>

                <div style={{ width: "0px", height: "0px", margin: "0px", overflow: "hidden", padding: "0px" }}>
                  <a ref={linkTel} href={"tel:" + phone} data-rel="external">aa</a>
                </div>

              </div> : ''
            : used ?
              <div>
                {usedUser &&
                  <Button aria-describedby={id} onClick={handleClick}>
                    <ManageAccountsIcon color={'error'} />
                  </Button>}
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <div style={{ padding: "15px" }}>
                    {props.uuid === usedUser?.uuid
                      ? <Typography variant={'subtitle2'}>* trying to add number twice</Typography>
                      : <div>
                        <Typography variant={'subtitle2'}>This is a primary company number assigned on : </Typography>
                        <Typography variant={'subtitle2'}>{usedUser?.firstName + ' '} {usedUser?.lastName}({usedUser?.username})</Typography>
                        <Button size={'small'} onClick={hendlerLink}>go to user</Button>
                      </div>
                    }
                  </div>
                </Popover>
              </div>

              : ''
          }
        </div>

        <TextField
          autoComplete={'off'}
          error={error || used}
          value={eValue}
          onChange={handlerValue}
          label={
            phone.type === EContactType.PRIMARY_PHONE
              ? "Primary phone"
              : "Additional phone"
          }
          sx={{ width: "350px" }}
          inputProps={{ sx: { fontSize: "13px" } }}
          helperText={used ? '* contact already in use' : ''}
        />

        {phone.type !== EContactType.PRIMARY_PHONE && (
          <Button
            size="small"
            color={"error"}
            onClick={() => deletePhone(phone?.uuid)}
          >
            <ClearIcon />
          </Button>
        )}
      </div>
    </>
  );
};