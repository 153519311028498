import { useEffect } from "react"
import { useDispatch} from 'react-redux';
import { logoutTokens } from "../services/TokensService";
import { modifyAuth } from "../store/reducers/cnfSlice";

export default function LogOutPage() {
    const dispatch = useDispatch();
    useEffect(() => {
        logoutTokens();
        dispatch(modifyAuth(null));
    },[dispatch])
    return (
        <>
           <p>este</p>
        </>
    )
}

