import { configureStore } from '@reduxjs/toolkit'
import counterSlice from './reducers/counterSlice'
import cnfSlice from './reducers/cnfSlice'
import mapSlice from './reducers/mapSlice'
import favoritesSlice from './reducers/favoritesSlice'
import auditSlice from './reducers/auditSlice'
import routesSlice from './reducers/routesSlice'

export const store = configureStore({
  reducer: {
      counterSlice,
      mapSlice,
      cnfSlice,
      favoritesSlice,
      auditSlice,
      routesSlice        
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch