import { IconButton, Divider } from "@mui/material";
import { Box } from "@mui/system";
import { GoogleMap, Polyline, Marker, InfoWindow } from "@react-google-maps/api"
import React, { useState, useEffect } from "react";
import { defaultTheme } from './Themes'
import { ItemsService } from '../../services/ItemsService';
import { IMapPoint, IWayInfoInMap, IWayPath } from "../../models/MapModels";

import { modifyStateWays } from "../../store/reducers/mapSlice";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Typography } from "@mui/material";
import { setIdSelected, setCenter } from "../../store/reducers/mapSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
// import useWindowDimensions from "../../hooks/useWindowDimensions";
// import { Rectangle } from '@react-google-maps/api'

const containerStyle = {
    width: '100%',
    height: '100%'
};

const center_company = {
    lat: 45.07628287127715,
    lng: -93.24481908541335
};

const defaultOptions = {
    styles: defaultTheme
}

export const MapComponent = () => {

    // const { height, width } = useWindowDimensions();
    const dispatch = useDispatch();
    const mapRef = React.useRef(null);

    const { ways, wayInfo, startsMap, filtersMap, idSelected, center } = useSelector((state: RootState) => state.mapSlice);
    const { setMarks, drawPaths, viewSetMarks, viewDrawPaths } = useSelector((state: RootState) => state.mapSlice.mapState);


    const [infoBoxCompanie, setInfoBoxCompanie] = useState(true);

    const [visibleEventPoint, setVisibleEventoint] = useState(false);
    const [eventPoint] = useState<IMapPoint[]>([]);

    function viewInfoWimdow(p: IWayInfoInMap) {
        if (p.id.toString() === idSelected.toString()) { return true }
        if (!setMarks) { return false }
        if (viewSetMarks === "all") { return true }
        if ((viewSetMarks === "star") && (startsMap.includes(p.id.toString()))) { return true };
        if ((viewSetMarks === "filter") && (filtersMap.includes(p.id.toString()))) { return true };
        return false;
    }

    function viewWays(p: IWayPath) {
        if (p.id.toString() === idSelected.toString()) { return true }
        if (!drawPaths) { return false }
        if (viewDrawPaths === "all") { return true }
        if ((viewDrawPaths === "star") && (startsMap.includes(p.id.toString()))) { return true };
        if ((viewDrawPaths === "filter") && (filtersMap.includes(p.id.toString()))) { return true };
        return false;
    }

    function findMaxMin(p: IMapPoint[]): any {
        let xMax = p[0].lat;
        let xMin = p[0].lat;
        let yMax = p[0].lng;
        let yMin = p[0].lng;
        p.forEach((e) => {
            if (e.lat > xMax) { xMax = e.lat }
            if (e.lat < xMin) { xMin = e.lat }
            if (e.lng > yMax) { yMax = e.lng }
            if (e.lng < yMin) { yMin = e.lng }
        })
        return {
            xMax: xMax,
            xMin: xMin,
            yMax: yMax,
            yMin: yMin
        }
    }

    // function distanceTwoPoint(mk1: IMapPoint, mk2: IMapPoint) {
    //     var R = 3958.8; // Radius of the Earth in miles
    //     var rlat1 = mk1.lat * (Math.PI / 180); // Convert degrees to radians
    //     var rlat2 = mk2.lat * (Math.PI / 180); // Convert degrees to radians
    //     var difflat = rlat2 - rlat1; // Radian difference (latitudes)
    //     var difflon = (mk2.lng - mk1.lng) * (Math.PI / 180); // Radian difference (longitudes)  
    //     var d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat / 2) * Math.sin(difflat / 2) + Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)));
    //     return d;
    // }

    useEffect(() => {
        for (let i = 0; i <= 9; i++) {
            (async () => {
                await ItemsService.getWay(i + 1).then((data: IWayPath | undefined) => {
                    if (data) {
                        data.value = findMaxMin(data.data);
                        dispatch(modifyStateWays(data))
                    }
                }).catch(() => { })
            })();
        }
        // let path = google.maps.geometry.encoding.decodePath('nxzuE_fnlYRMLIHAjCQ~AKb@E~@GbBIPAPAJARIJEJGFGFKJYH}@C{C?O?[@yABo@@k@@W?E@EBEFI');       
    }, [dispatch])


    useEffect(() => {
        setVisibleEventoint(true);

        let xc: any = 45.07628287127715;
        let yc: any = -93.24481908541335

        if (idSelected !== '0') {

            let id = Number(idSelected);
            // let b: Array<IMapPoint> = [];
            let a: any = ways.filter(e => e.id.toString() === idSelected.toString());

            if (a[0]) {

                let c: any = ways[id - 1].value;
                xc = (c.xMax + c.xMin) / 2;
                yc = (c.yMax + c.yMin) / 2;

                // let p1: any = { lat: c.xMax, lng: c.yMax }
                // let p2: any = { lat: c.xMin, lng: c.yMin }
                // let p3: any = { lat: xc, lng: yc }
                // b.push(p1); b.push(p2); b.push(p3);                
                // setEventPoint(b);

            }
        }
        else {

            let x1 = center_company.lat;
            let x2 = center_company.lat;
            let y1 = center_company.lng;
            let y2 = center_company.lng;

            ways.forEach((e: any) => {

                let { xMax, xMin, yMax, yMin } = e.value;
                if (xMax > x1) { x1 = xMax };
                if (xMin < x2) { x2 = xMin };
                if (yMax > y1) { y1 = yMax };
                if (yMin < y2) { y2 = yMin };

            })

            xc = (x1 + x2) / 2;
            yc = (y1 + y2) / 2;
        }

        dispatch(setCenter({
            lat: xc,
            lng: yc
        }))
    }, [idSelected, dispatch, ways])

    const getColor = (index: number) => {
        return "black"
    }

    const onLoad = React.useCallback(function callback(map: any) { mapRef.current = map }, []);
    const onUnmount = React.useCallback(function callback(map: any) { mapRef.current = null; }, []);

    const hendlerinfoBoxCompanie = () => { setInfoBoxCompanie(!infoBoxCompanie); };
    const hendlerInfoBox = () => { };
    const hendlerHideInfoBox = (p: IWayInfoInMap) => {
        //dispatch(setMapStars(p.id)); 
        dispatch(setIdSelected(p.id))
    }

    return (
        <>  
            <Box sx={{ display: "flex", flexDirection: "row", alignContent: "stretch", alignItems: "stretch", height: "100%" }} >
                {/* <div style={{ width: "20px" }}>
                    <Button>Центр</Button>
                </div> */}
                <div style={{ flex: 1 }}>
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={6}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                        options={defaultOptions}
                    >

                        {/* paths for every ways*/}
                        { 
                            ((ways.length !== 0)) && ways.map((p: IWayPath, index) => (
                                ((viewWays(p)) && (index < 10)) &&
                                < Polyline key={`s` + index}
                                    path={ways[index].data}
                                    options={{
                                        strokeColor: wayInfo[index].wayColor,
                                        // strokeColor: getColor(index),
                                        strokeOpacity: 0.2,
                                        strokeWeight: 6
                                    }}
                                />
                            ))
                        }

                        {/* // markers for begining of ways */}
                        {
                            (ways.length !== 0) && ways.map((p: IWayPath, index) => (
                                (index < 10) &&<Marker key={`begin` + index}
                                    position={{ lat: p.data[0].lat, lng: p.data[0].lng }}
                                    onClick={hendlerinfoBoxCompanie}
                                    // icon={{
                                    //     url: '/starthere.svg',
                                    //     origin: new window.google.maps.Point(0, 0),
                                    //     anchor: new window.google.maps.Point(20, 25),
                                    //     scaledSize: new window.google.maps.Size(40, 40)
                                    // }}
                                />
                            ))
                        }


                        {/* infobox for every whays - in current position*/}
                        {
                            (wayInfo.length !== 0) && wayInfo.map((p: IWayInfoInMap, index) => (
                                (viewInfoWimdow(p)) &&
                                <InfoWindow key={`q` + index}
                                    position={{ lat: p.curentPoint.lat, lng: p.curentPoint.lng }}
                                    onCloseClick={hendlerInfoBox}                                >
                                    <div style={{ textAlign: "center" }}>
                                        <img src={p.currentAuto.photo} width={70} alt={p.wayName} />
                                        <Typography variant={"body2"}>
                                            {p.currentAuto.name}
                                        </Typography>
                                        <Divider />
                                        <Box sx={{ display: "flex" }}>
                                            <IconButton aria-label="clear" size="small">
                                                <AssignmentIcon fontSize="small" />
                                            </IconButton>
                                            <Box >
                                                <Box sx={{ display: "flex" }}>
                                                    {
                                                        (p.loadsTotal.length > 0) &&
                                                        <Typography variant={"body2"}>
                                                            {p.loadsRealized.length}/{p.loadsTotal.length}
                                                        </Typography>
                                                    }
                                                </Box>
                                                <Divider />
                                                <Typography variant={"body2"}>
                                                    {
                                                        (p.loadsTotal.length > 0) &&
                                                        // Math.round(p.loadsRealized.length / p.loadsTotal.length * 100) + '%'
                                                        p.id
                                                    }
                                                </Typography>
                                            </Box>
                                            <IconButton aria-label="clear" size="small" onClick={() => {
                                                setVisibleEventoint(!visibleEventPoint);
                                            }}>
                                                <PlayCircleOutlineIcon fontSize="small" />
                                            </IconButton>
                                        </Box>
                                    </div>
                                </InfoWindow>
                            ))
                        }

                        {/* markers current position in ways */}
                        {
                            (wayInfo.length !== 0) && wayInfo.map((p: IWayInfoInMap, index) => (
                                <Marker key={`p` + index}
                                    position={{ lat: p.curentPoint.lat, lng: p.curentPoint.lng }}
                                    onClick={() => { hendlerHideInfoBox(p) }}
                                    // icon={{
                                    //     url: '/pointMaps.svg',
                                    //     origin: new window.google.maps.Point(0, 0),
                                    //     anchor: new window.google.maps.Point(20, 15),
                                    //     scaledSize: new window.google.maps.Size(40, 40)
                                    // }}
                                />
                            ))
                        }

                        {/* markers current position in ways */}
                        {
                            (eventPoint.length !== 0) && eventPoint.map((p: IMapPoint, index) => (
                                (visibleEventPoint) &&
                                <Marker key={`pa` + index}
                                    position={{ lat: p.lat, lng: p.lng }}
                                    // icon={{
                                    //     url: '/pointEvent.svg',
                                    //     origin: new window.google.maps.Point(0, 0),
                                    //     anchor: new window.google.maps.Point(20, 15),
                                    //     scaledSize: new window.google.maps.Size(40, 40)
                                    // }}
                                />
                            ))
                        }


                        {/* Info of company in Maps Marks and InfoBox */}
                        {(!infoBoxCompanie) &&
                            <Marker
                                position={{
                                    lat: 45.07628287127715,
                                    lng: -93.24481908541335
                                }}
                                // label={"KLMA"}
                                onClick={hendlerinfoBoxCompanie}
                            />}
                        {(infoBoxCompanie) &&
                            <InfoWindow
                                position={{ lat: 45.07628287127715, lng: -93.24481908541335 }}
                                onCloseClick={hendlerinfoBoxCompanie}
                            >
                                <div style={{ textAlign: "center" }}>
                                    <img src='/logo.svg' width={40} height={40} alt={"Company Name"} />
                                </div>
                            </InfoWindow>
                        }

                    </GoogleMap>
                </div>
            </Box>
        </>
    )
}