import { Avatar, Divider, Typography, useTheme } from "@mui/material";
import { height } from "@mui/system";
import { randomPhoto } from "../../helpers/generalHelper";
import { IBook } from "../../models/CustomModels";
import { IResponseAutocomplete } from "../../models/IUser";
import './../../SearchBar.scss';
import AvatarUserComponent from "./AvatarUserComponent";
import { AnyPhoto } from "./GridStafComponent";


interface IProps {
  item: IResponseAutocomplete;
  hendlerActiveElement: (item: any) => void;
}


export default function ItemInSearchListComponent(props: IProps) {

  const { hendlerActiveElement, item } = props;
  const user: IResponseAutocomplete = props.item;
  const theme = useTheme();


  return (
    <>
      <div
        className="dataItem"
        style={{ marginTop: "4px", marginBottom: "4px", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "start", gap: "15px", paddingLeft: "10px" }}
        onClick={() => hendlerActiveElement(item)}
      >
        <div style={{ width: "5px", height: "30px", background: user.color }}></div>
        {((user.avatar) && (user.avatar !== 'N/A'))
          ? <AvatarUserComponent data={user.avatar} />
          : <AnyPhoto />}
        <Typography
          color={theme.palette.primary.main}
          variant={"caption"}
        >
          {item.fullName}
        </Typography>
      </div>
      <Divider></Divider>
    </>
  );
}

