import { IBrandCreateDto, IDocumentCreateDto, IDocumentRequestDto, IResponseAutocompleteAuto } from "../models/TransportModels";
import { API } from "./APIService";

class TransportService {

  public static getAutocomplete = async (s: string): Promise<any> => {
    const [error, response] = await API('GET', 'transport/autocomplete/' + s,);
    let st: IResponseAutocompleteAuto[] = [];
    if (response) {
      st = response.data.body;
    }
    return [error, st]
  }

  public static getStatisticsAuto = async (): Promise<any> => {
    const [error, response] = await API('GET', 'transport/count');
    return [error, response]
  }

  public static updateStatusAuto = async (body: any): Promise<any> => {
    const [error, response] = await API('PUT', 'transport/status', body);
    return [error, response]
  }
  public static getStatusAuto = async (uuid: string): Promise<any> => {
    const [error, response] = await API('GET', 'transport/status/' + uuid);
    return [error, response]
}
  public static deleteTransportImages = async (UUID: string): Promise<any> => {
    const [error, response] = await API('DELETE', 'transport/image/' + UUID);
    return [error, response]
  }
  public static deleteTransport = async (UUID: string): Promise<any> => {
    const [error, response] = await API('DELETE', 'transport/' + UUID);
    return [error, response]
  }
  public static getTransportImages = async (uuid: string): Promise<any> => {
    const [error, response] = await API('GET', 'transport/image/' + uuid);
    return [error, response]
  }
  public static addImageInTransport = async (body: any): Promise<any> => {
    const [error, response] = await API('PUT', 'transport/image', body);
    return [error, response]
  }
  public static getDetailsAuto = async (uuid: string): Promise<any> => {
    const [error, response] = await API('GET', 'transport/' + uuid);
    return [error, response]
  }
  public static getAutoByFilter = async (body: Object): Promise<any> => {
    const [error, response] = await API('POST', 'transport/all', JSON.stringify(body));
    return [error, response]
  }
  public static createBrand = async (body: IBrandCreateDto): Promise<any> => {
    const [error, response] = await API('POST', 'brand', JSON.stringify(body));
    return [error, response]
  }
  public static getAllBrand = async (type: string): Promise<any> => {
    const [error, response] = await API('GET', '/transport/vehicle/brand/' + type);
    return [error, response]
  }
  public static deleteBrand = async (UUID: string): Promise<any> => {
    const [error, response] = await API('DELETE', 'brand/' + UUID);
    return [error, response]
  }
  public static createVehicle = async (body: any): Promise<any> => {
    const [error, response] = await API('POST', 'transport/vehicle', JSON.stringify(body));
    return [error, response]
  }
  public static updateVehicle = async (body: any): Promise<any> => {
    const [error, response] = await API('PUT', 'transport/vehicle', JSON.stringify(body))
    return [error, response]
  }
  public static getAllVehicleByType = async (type: string): Promise<any> => {
    const [error, response] = await API('GET', 'transport/vehicle/type/' + type);
    return [error, response]
  }
  public static getVehicleById = async (type: string): Promise<any> => {
    const [error, response] = await API('GET', 'transport/vehicle/' + type);
    return [error, response]
  }
  public static deleteVehicle = async (UUID: string): Promise<any> => {
    const [error, response] = await API('DELETE', 'transport/vehicle/' + UUID);
    return [error, response]
  }
  public static checkVinNumber = async (vin: string): Promise<any> => {
    const [error, response] = await API('GET', 'transport/available/vin/' + vin)
    return [error, response]
  }
  public static getMetadata = async (uuid: string): Promise<any> => {
    const [error, response] = await API('GET', 'transport/metadata/' + uuid);
    return [error, response];
  }
  public static createTransport = async (body: any): Promise<any> => {
    const [error, response] = await API('POST', 'transport', JSON.stringify(body));
    return [error, response]
  }
  public static SaveRegistrationData = async (body: any): Promise<any> => {
    const [error, response] = await API('PUT', 'transport/registrationData', JSON.stringify(body));
    return [error, response]
  }
  public static GetRegistrationData = async (uuid: string): Promise<any> => {
    const [error, response] = await API('GET', 'transport/registrationData/' + uuid);
    return [error, response]
  }
  public static getCurrentStep = async (uuid: any): Promise<any> => {
    const [error, response] = await API('GET', 'transport/step/' + uuid,);
    return [error, response]
  }
  public static saveTransportStep = async (body: any): Promise<any> => {
    const [error, response] = await API('PUT', 'transport/step', JSON.stringify(body));
    return [error, response]
  }
  public static deleteFileInDocuments = async (uuid: string): Promise<any> => {
    const [error, response] = await API('DELETE', 'transport/document/attachment/' + uuid);
    return [error, response];
  }
  public static updateFileInDocument = async (body: any): Promise<any> => {
    const [error, response] = await API('PUT', 'transport/document/attachment', body);
    return [error, response]
  }
  public static deleteDocuments = async (uuid: string): Promise<any> => {
    const [error, response] = await API('DELETE', 'transport/document/' + uuid);
    return [error, response];
  }
  public static getDocuments = async (uuid: string): Promise<any> => {
    const [error, response] = await API('GET', 'transport/document/' + uuid);
    return [error, response];
  }
  public static createDocument = async (body: IDocumentCreateDto): Promise<any> => {
    const [error, response] = await API('POST', 'transport/document', JSON.stringify(body));
    return [error, response]
  }
  public static updateDocument = async (body: IDocumentRequestDto): Promise<any> => {
    const [error, response] = await API('PUT', 'transport/document', JSON.stringify(body));
    return [error, response]
  }
  public static isTrueLicensePlate = async (num: string): Promise<any> => {
    const [error, response] = await API('GET', 'transport/available/licensePlate/' + num);
    return [error, response];
  }
  public static isTrueTransportNumber = async (num: string): Promise<any> => {
    const [error, response] = await API('GET', 'transport/available/number/' + num);
    return [error, response];
  }
  public static getTransportSummary = async (uuid: string): Promise<any> => {
    const [error, response] = await API('GET', 'transport/summary/' + uuid);
    return [error, response]
  }
}
export default TransportService;