import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, TextField, Tooltip, useMediaQuery, useTheme } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';

import SaveAsIcon from '@mui/icons-material/SaveAs';
import CloseIcon from '@mui/icons-material/Close';
import { useDebounce } from '../../hooks/useDebounce';

import { useNavigate } from 'react-router-dom';
import { modifyCustomerRefresh, setLastError } from '../../store/reducers/cnfSlice';
import CustomerService from '../../services/CustomerService';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { ICustomerStepNew, IFavoritesCustomer } from '../../models/CustomerModels';
import SearchCustomerComponent from './SearchCustomerComponent';

interface CustomerOptionType {
    uuid: string,
    idno?: string,
    name?: string,
    addressUuid?: string,
    email?: string,
    phone?: string,
    description?: string
    inputValue?: string;
    customerUuid?: string;
    lastLoadDate?: number,
    reviews?: any[] | null
}

const styled = { icon: { fontSize: "28px", cursor: "pointer" } }

interface IProps {
    snackMsg: (s: string) => void
}

export default function CustomerButtonBarComponent(props: IProps) {

    const { hints } = useSelector((state: RootState) => state.cnfSlice.interfaceState);
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [openN, setOpenN] = useState(false);
    const [save, setSave] = useState(false);
    const [value, setValue] = useState<CustomerOptionType | null>(null);
    const [isValidIDNO, setIsValidIDNO] = useState<boolean>(true);
    const [isValidName, setIsValidName] = useState<boolean>(true);

    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const [state, setState] = useState<ICustomerStepNew>({
        name: '',
        idno: ''
    })

    const debouncedIDNP = useDebounce(state.idno, 300);
    const debouncedName = useDebounce(state.name, 300);


    const handlerChangeName = (e: any) => {
        let value: string = (e.target.value as string);
        setState(st => ({ ...st, name: value }))
    }

    const handlerChangeIdno = (e: any) => {
        let value: string = (e.target.value as string).trim();
        setState(st => ({ ...st, idno: value }))
    }


    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickOpen = () => { setOpen(true); };
    const handleClose = () => {
        setOpen(false);

    };
    
    const hendlerActiveElement = (item: IFavoritesCustomer) => {
        handleClose();
        navigate('/customer/' + item.uuid)
    }

    const handlerAddNewLoad = () => {
        setOpenN(true);
    }
    const handleCloseN = () => {
        setOpenN(false);
        setState({
            name: '',
            idno: ''
        })
        setIsValidIDNO(true)
        setIsValidName(true)
    }
    const handlerSaveCustomer = () => { setSave(true); }

    useEffect(() => {
        if (save) {
            (async () => {
                const body: ICustomerStepNew = { ...state };
                const [error, response] = await CustomerService.addCustomer(body);
                if (response) {
                    let uuid = response.data.response.entityId;
                    handleCloseN();
                    navigate('/customer/' + uuid);
                    props.snackMsg('Customer ' + body.name + ' was successfully created.')
                    dispatch(modifyCustomerRefresh());
                }
                if (error) { }
            })()
        }
        setSave(false);
    }, [save])

    useEffect(() => {
        if (debouncedIDNP) {
            if (state.idno.length >= 6) {
                (async () => {
                    const [error, response] = await CustomerService.isValidIDNO(state.idno);
                    if (response) {
                        const value: boolean = response.data.body as boolean;
                        setIsValidIDNO(value);
                    }
                    if (error) {
                        dispatch(setLastError(error))
                    }
                })()
            } else {
                setIsValidIDNO(true);
            }
        }
    }, [debouncedIDNP])

    useEffect(() => {
        if (debouncedName) {
            if (state.name.length >= 6) {
                (async () => {
                    const [error, response] = await CustomerService.isValidName(state.name);
                    if (response) {
                        const value: boolean = response.data.body as boolean;
                        setIsValidName(value);
                    }
                    if (error) {
                        dispatch(setLastError(error))
                    }
                })()
            } else {
                setIsValidName(true);
            }
        }
    }, [debouncedName])

    const isValidCreate = useCallback((): boolean => {
        return state.name.trim().length < 3 || state.idno.trim().length < 1 || !isValidIDNO || !isValidName
    }, [isValidIDNO, isValidName, state])

    return (
        <div>

            <Box sx={{ marginBottom: "5px" }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", gap: "5px" }}>

                        <Tooltip
                            title={(hints) ? t("Search customer") : ''}
                            placement={"top"}
                            arrow={true}
                        >
                            <IconButton onClick={handleClickOpen}>
                                <ManageSearchIcon style={styled.icon} color={"primary"} />
                            </IconButton>
                        </Tooltip>

                        <Tooltip
                            title={(hints) ? t("Create customer") : ''}
                            placement={"top"}
                            arrow={true}
                        >
                            <IconButton onClick={handlerAddNewLoad} >
                                <DashboardCustomizeIcon style={styled.icon} color={"primary"} />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>

                <Dialog
                    fullScreen={fullScreen}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                        { }
                    </DialogTitle>
                    <DialogContent>
                        <div style={{ minHeight: "350px", minWidth: "330px" }}>
                            <SearchCustomerComponent
                                hendlerActiveElement={hendlerActiveElement}
                                placeholder={'Search by name or IDNO'}
                            ></SearchCustomerComponent>
                        </div>

                        <DialogContentText>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    fullScreen={fullScreen}
                    maxWidth={'lg'}
                    open={openN}
                    onClose={handleCloseN}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                        {"CREATE CUSTOMER"}
                        {((value !== null && (value.idno === undefined))) && <span style={{ color: "silver" }}>* {'(add new customer)'.toUpperCase()} </span>}
                    </DialogTitle>
                    <DialogContent>
                        <div style={{ display: "flex" }}>
                            <div style={{ paddingTop: "20px" }}>
                                <div style={{ display: "flex", gap: "18px", alignItems: "start", justifyContent: "" }}>
                                    <Box sx={{ width: '350px', display: "flex", flexDirection: "row" }}>
                                        {((value === null) || (value !== null && (value.idno !== undefined))) &&
                                            <Box style={{ width: "350px", display: "flex", flexDirection: "column", gap: "45px", paddingTop: "10px" }}>

                                                <div style={{ display: "flex", flexDirection: "row" }}>
                                                    <TextField
                                                        autoComplete={'off'}
                                                        error={state.name.length < 3 || !isValidName}
                                                        disabled={value !== null && (!value?.idno)}
                                                        value={state.name}
                                                        label="Customer Name"
                                                        onChange={handlerChangeName}
                                                        style={{ width: "340px" }}
                                                        inputProps={{ sx: { fontSize: "16px" } }}
                                                        InputLabelProps={{ sx: { fontSize: "17px" } }}
                                                        helperText={!isValidName ? '* this ID exists' : ''}
                                                    />

                                                </div>

                                                <TextField
                                                    autoComplete={'off'}
                                                    error={state.idno.length < 1 || !isValidIDNO}
                                                    disabled={value !== null && (!value?.idno)}
                                                    value={state.idno}
                                                    label="Customer ID"
                                                    onChange={handlerChangeIdno}
                                                    style={{ width: "340px" }}
                                                    inputProps={{ sx: { fontSize: "16px" } }}
                                                    InputLabelProps={{ sx: { fontSize: "17px" } }}
                                                    helperText={!isValidIDNO ? '* this ID exists' : ''}
                                                />

                                            </Box>
                                        }

                                    </Box>
                                </div>
                            </div>
                        </div>
                        <DialogContentText>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <div style={{ display: "flex", justifyContent: "flex-end", width: "100%", padding: "20px", gap:"10px" }}>
                            <Button
                                variant={'contained'}
                                disabled={isValidCreate()}
                                size={'small'}
                                onClick={handlerSaveCustomer} startIcon={<SaveAsIcon />}>Create</Button>
                            <Button
                                startIcon={<CloseIcon />}
                                variant={'contained'}
                                color={'warning'}
                                size={'small'}
                                onClick={handleCloseN}>Close</Button>
                        </div>
                    </DialogActions>
                </Dialog>

            </Box>
            <Divider></Divider>
            <Box sx={{ height: "10px" }}></Box>
        </div>
    );
}