import { ILoadDocumentUpdateDTO, ILoadRegistrationRequestDto } from "../models/LoadModels";
import { API } from "./APIService";

class LoadService {

    public static getAutocomplete = async (text: string): Promise<any> => {
        const [error, response] = await API('GET', 'load/autocomplete/' + text);
        return [error, response];
    }

    public static loadStatistics = async (): Promise<any> => {
        const [error, response] = await API('GET', 'load/count');
        return [error, response];
    }

    public static getLoadDetails = async (uuid: string): Promise<any> => {
        const [error, response] = await API('GET', 'load/' + uuid);
        return [error, response];
    }

    public static getLoadReview = async (uuid: string): Promise<any> => {
        const [error, response] = await API('GET', 'load/reviews/' + uuid);
        return [error, response];
    }

    public static getLoadSummary = async (uuid: string): Promise<any> => {
        const [error, response] = await API('GET', 'load/summary/' + uuid);
        return [error, response];
    }

    public static getAllLoad = async (body: Object): Promise<any> => {
        const [error, response] = await API('POST', 'load/all', JSON.stringify(body));
        return [error, response];
    }

    public static markAsDone = async (body: Object): Promise<any> => {
        const [error, response] = await API('POST', 'load/item/action/done', JSON.stringify(body));
        return [error, response];
    }

    public static markLoadAsDone = async (body: Object): Promise<any> => {
        const [error, response] = await API('POST', 'load/action/done', JSON.stringify(body));
        return [error, response];
    }

    public static createLoad = async (body: Object): Promise<any> => {
        const [error, response] = await API('POST', 'load', JSON.stringify(body));
        return [error, response];
    }

    public static isAvailableLoadIDNO = async (text: string): Promise<any> => {
        const [error, response] = await API('GET', 'load/available/idno/' + text);
        return [error, response];
    }

    public static isAvailableLoadItemIDNO = async (text: string): Promise<any> => {
        const [error, response] = await API('GET', 'load/item/available/idno/' + text);
        return [error, response];
    }

    public static updateFragilityType = async (body: Object): Promise<any> => {
        const [error, response] = await API('PUT', 'load/fragilityType', JSON.stringify(body));
        return [error, response];
    }

    public static updateRegistration = async (body: ILoadRegistrationRequestDto): Promise<any> => {
        const [error, response] = await API('PUT', 'load/registration', JSON.stringify(body));
        return [error, response];
    }

    public static addFragilityType = async (body: Object): Promise<any> => {
        const [error, response] = await API('POST', 'load/fragilityType', JSON.stringify(body));
        return [error, response];
    }

    public static deleteFragilityType = async (uuid: string): Promise<any> => {
        const [error, response] = await API('DELETE', 'load/fragilityType/' + uuid);
        return [error, response];
    }

    public static getAllFragilityType = async (): Promise<any> => {
        const [error, response] = await API('GET', 'load/fragilityType');
        return [error, response];
    }

    public static getAllPalletType = async (): Promise<any> => {
        const [error, response] = await API('GET', 'load/palletType');
        return [error, response];
    }

    public static getAllPallete = async (): Promise<any> => {
        const [error, response] = await API('GET', 'load/palletType');
        return [error, response];
    }

    public static createItemForLoad = async (body: any): Promise<any> => {
        const [error, response] = await API('POST', 'load/item', JSON.stringify(body));
        return [error, response]
    }

    public static updateItemForLoad = async (body: any): Promise<any> => {
        const [error, response] = await API('PUT', 'load/item', JSON.stringify(body));
        return [error, response]
    }

    public static updateDeliveryForItem = async (body: any): Promise<any> => {
        const [error, response] = await API('PUT', 'load/item/delivery', JSON.stringify(body));
        return [error, response]
    }

    public static getDeliveryById = async (uuid: string): Promise<any> => {
        const [error, response] = await API('GET', 'load/item/delivery/' + uuid);
        return [error, response]
    }

    public static getLoadById = async (uuid: string): Promise<any> => {
        const [error, response] = await API('GET', 'load/' + uuid)
        return [error, response]
    }
    public static getLoadItemById = async (uuid: string): Promise<any> => {
        const [error, response] = await API('GET', 'load/item/' + uuid)
        return [error, response]
    }

    public static getLoadRegistrationStep = async (uuid: string): Promise<any> => {
        const [error, response] = await API('GET', 'load/registration/' + uuid)
        return [error, response]
    }

    public static DeleteLoadItemById = async (uuid: string): Promise<any> => {
        const [error, response] = await API('DELETE', 'load/item/' + uuid)
        return [error, response]
    }

    public static DeleteLoad = async (uuid: string): Promise<any> => {
        const [error, response] = await API('DELETE', 'load/' + uuid)
        return [error, response]
    }

    public static updateFileInDocument = async (body: any): Promise<any> => {
        const [error, response] = await API('PUT', 'load/document/attachment', body);
        return [error, response]
    }

    public static createDocument = async (body: any): Promise<any> => {
        const [error, response] = await API('POST', 'load/document', JSON.stringify(body));
        return [error, response]
    }

    public static updateDocument = async (body: ILoadDocumentUpdateDTO): Promise<any> => {
        const [error, response] = await API('PUT', 'load/document', JSON.stringify(body));
        return [error, response]
    }

    public static getDocuments = async (uuid: string): Promise<any> => {
        const [error, response] = await API('GET', 'load/document/' + uuid);
        return [error, response];
    }
    public static deleteFileInDocuments = async (uuid: string): Promise<any> => {
        const [error, response] = await API('DELETE', 'load/document/attachment/' + uuid);
        return [error, response];
    }

    public static deleteDocuments = async (uuid: string): Promise<any> => {
        const [error, response] = await API('DELETE', 'load/document/' + uuid);
        return [error, response];
    }

    public static getLoadDetailsStep = async (id: string): Promise<any> => {
        const [error, response] = await API('GET', 'load/details/' + id);
        return [error, response]
    }

    public static saveLoadDetails = async (body: any): Promise<any> => {
        const [error, response] = await API('PUT', 'load/details', body);
        return [error, response]
    }

    public static updatePalletType = async (body: Object): Promise<any> => {
        const [error, response] = await API('PUT', 'load/palletType', JSON.stringify(body));
        return [error, response];
    }

    public static addPalletType = async (body: Object): Promise<any> => {
        const [error, response] = await API('POST', 'load/palletType', JSON.stringify(body));
        return [error, response];
    }

    public static deletePalletType = async (uuid: string): Promise<any> => {
        const [error, response] = await API('DELETE', 'load/palletType/' + uuid);
        return [error, response];
    }

    public static isAvailablePalletName = async (name: string): Promise<any> => {
        const [error, response] = await API('GET', 'load/palletType/available/name/' + name);
        return [error, response];
    }

    public static isAviableFragilityType = async (name: string): Promise<any> => {
        const [error, response] = await API('GET', 'load/fragilityType/available/name/' + name);
        return [error, response];
    }

    public static getMetadata = async (uuid: string): Promise<any> => {
        const [error, response] = await API('GET', 'load/metadata/' + uuid);
        return [error, response];
    }

    public static updateLoadStep = async (body: any): Promise<any> => {
        const [error, response] = await API('PUT', 'load/step', body);
        return [error, response]
    }
}

export default LoadService;