import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Divider, Button } from '@mui/material';
import { modifyWarhouseReputation } from "../../store/reducers/cnfSlice";
import { RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { EWarehouseReputationLevel } from "../../models/WarehouseModels";

export default function RoleWarehouseComponent() {

  const { t } = useTranslation();
  const { part, reputation } = useSelector((state: RootState) => state.cnfSlice.warehouse);
  const [reputationTable, setReputationTable] = useState<string[]>([]);

  const dispatch = useDispatch();
  const hendlerSelectReputation = (p: string) => {
    dispatch(modifyWarhouseReputation(p));
  }

  useEffect(() => {
    let tab: string[] = []
    for (let el of Object.keys(EWarehouseReputationLevel)) { tab.push(el) }
    setReputationTable(tab);
  }, [])

  return (
    <>
      <Divider sx={{ paddingTop: "5px", fontSize: "16px" }} textAlign="left">REPUTATION</Divider>
      {reputationTable.map((el, i) =>
        <div key={i} style={{ marginTop: "10px" }}>
          <Button
            onClick={() => hendlerSelectReputation(el)}
            disabled={reputation === el}
            variant={((reputation === el) || (el === 'ALL')) ? 'contained' : 'text'}
            color={(el === 'ALL') ? 'primary' : 'inherit'}
            size="small"
            sx={{ width: "100%", marginBottom: "10px", fontSize: "13px" }}
          >
            {el === 'ALL' ? 'ALL REPUTATIONS IN ' + part : el.split('_').join(' ')}
          </Button>
          <Divider />
        </div>)}
    </>
  );
}