import { useEffect, useRef, useState } from "react";
import ReportFooter from "../ReportFooter";
import ReportHeader from "../ReportHeader";
import ReportUserInfo from "../ReportUserInfo";
import { useParams } from "react-router-dom";
import StafService from "../../../../services/StafService";
import { IUserInfoResponseDto } from "../../../../models/IUser";
import ReportIncomes from "../ReportIncomes";
import ReportDocuments from "../ReportDocuments";
import styles from "./ReportUser.module.scss"
import ReactToPrint from "react-to-print";
interface IProps { 
   user:IUserInfoResponseDto | null;
}

const ReportUser = (props:IProps) => {
  const { user }  = props



  return (
    <>

      <div className={styles.reportUser}>
       

        <div><ReportHeader /></div>
        <div><ReportUserInfo user={user ? user : null} /></div>
        <div><ReportIncomes user={user ? user : null} /></div>
        <div><ReportDocuments user={user ? user : null} /></div>
        <div><ReportFooter /></div>

      </div>

     
    </>
  )
}
export default ReportUser;