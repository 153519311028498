import { useEffect, useState } from "react";
import { ELoadDeliveryType, ILoadDeliveryResponseDto } from "../../../models/LoadModels";
import { TextField, MenuItem, Button, Dialog, DialogTitle, DialogContent, DialogActions, Divider, Tooltip, Box, } from '@mui/material';
import { ObjectToValueArray } from "../../../helpers/generalHelper";
import HTMLRedactor from "../../CustomComponentModule/HTMLRedactor";
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import StafService from "../../../services/StafService";
import { dateToYMD, toTimestamp } from "../../../helpers/dateHelper";
import WarehouseIcon from '@mui/icons-material/Warehouse';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import { WMapComponent } from "../WMapComponent";
import { IMarkerInfoWarehouse } from "../../../models/WarehouseModels";
import { StyledDivider } from "../../../styledComponents/sharedComponent/StyledDivider";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { AddressRequestDto, AddressResponseDto } from "../../../models/AddressModels";
import AddressComponent from "../../MapModule/AddressComponent";
import AddressService from "../../../services/AddressService";


interface IProps {
    delivery: ILoadDeliveryResponseDto | null;
    editableMode: boolean;
    newSelModel: boolean;
    getDeliveryContent: (d: ILoadDeliveryResponseDto) => void;
    setError: (n: number) => void
}

const newLoadDelivery = (): ILoadDeliveryResponseDto => {
    return {
        id: "",
        uuid: "",
        loadItem: {
            uuid: "",
            name: "",
            idno: "",
            units: 0,
            width: 0,
            height: 0,
            length: 0,
            weight: 0,
            fragilityLevel: "",
            fragilityType: {
                uuid: "",
                name: "",
                caption: ""
            },
            amount: 0,

        },
        pickupWarehouse: null,
        deliveryWarehouse: null,
        deliveryType: "",
        deliveredBy: 0,
        description: "",
        readonly: false
    };

}
const EditableTableComponent = (props: IProps) => {
    const { delivery, editableMode, setError } = props

    const { hints } = useSelector((state: RootState) => state.cnfSlice.interfaceState);

    const [newAdress, setNewAdress] = useState<AddressResponseDto | null>()
    const [adressPickUp, setAdressPickUp] = useState<AddressResponseDto | null>();
    const [adressDelivery, setAdressDelivery] = useState<AddressResponseDto | null>();
    const [wPickUp, setWPickUp] = useState<IMarkerInfoWarehouse | null>();
    const [wDelivery, setWDelivery] = useState<IMarkerInfoWarehouse | null>();

    const [, setTextError] = useState<Array<string>>([""])
    const [description, setDescription] = useState<string>("");
    const [snapshot, setSnapshot] = useState<string>("");
    const [state, setState] = useState<ILoadDeliveryResponseDto>(delivery ? delivery : newLoadDelivery)

    const [openB, setOpenB] = useState(false);
    const [openW, setOpenW] = useState(false);
    const [open, setOpen] = useState(false);

    const [getUUidAddress, setGetUUidAddress] = useState(false);
    const [deliveryAdress, setDeliveryAdress] = useState(false)
    const [pd, setPD] = useState<string>('p');


    const deleteWDelivery = () => {
        setState((st) => {
            return {
                ...st,
                deliveryAddress: undefined,
                deliveryWarehouse: null
            }
        })
    }

    const deleteWPicIp = () => {
        setState((st) => {
            return {
                ...st,
                pickupAddress: undefined,
                pickupWarehouse: null,
            }
        })
    }

    const handleNewAdres = (a: AddressResponseDto) => { setNewAdress(a); }
    const handleClickOpen = () => { setOpen(true); };
    const handleClickOpenB = () => { setOpenB(true); };
    const handleCloseB = () => { setOpenB(false); setNewAdress(null) };

    const handleClose = () => { setOpen(false); setNewAdress(null) };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length <= 255) { setDescription(event.target.value); }
    };

    const fixWarehouseId = () => {
        if (pd === 'd' && wDelivery) {
            setState((st) => {
                return {
                    ...st,
                    deliveryWarehouse: {
                        uuid: wDelivery.uuid,
                        address: {
                            latitude: wDelivery.lat,
                            longitude: wDelivery.lng,
                            uuid: wDelivery.addressUUID,
                            fullAddress: wDelivery.address
                        }
                    }
                }
            })
        }

        if (pd === 'p' && wPickUp) {
            setState((st) => {
                return {
                    ...st,
                    pickupWarehouse: {
                        uuid: wPickUp.uuid,
                        address: {
                            latitude: wPickUp.lat,
                            longitude: wPickUp.lng,
                            uuid: wPickUp.addressUUID,
                            fullAddress: wPickUp.address
                        }
                    }
                }
            })
        }
        setOpenW(false);
    }

    const addWarehouseId = (w: IMarkerInfoWarehouse) => {
        if (pd === 'd') { setWDelivery(w) }
        if (pd === 'p') { setWPickUp(w) }
    }

    const handleClickOpenWD = () => {
        setPD('d');
        setOpenW(true);
    }
    const handleClickOpenWP = () => {
        setPD('p');
        setOpenW(true);
    }


    const handleCloseW = () => {
        setOpenW(false)
    }

    const handleEdit = (v: boolean) => { }

    const handleActivateAddress = () => {
        if (newAdress) {
            setAdressPickUp(newAdress);
            setGetUUidAddress(true);
            setOpen(false);
        }
    }

    const handleActivateAddressDelivery = () => {
        if (newAdress) {
            setAdressDelivery(newAdress);
            setDeliveryAdress(true)
            setGetUUidAddress(true);
            setOpenB(false);
        }
    }

    useEffect(() => {
        if (delivery) {
            setState(delivery)
            setSnapshot(JSON.stringify(delivery))
            setError(0);
            setDescription(delivery.description)
            setAdressPickUp(delivery.pickupAddress)
            setAdressDelivery(delivery.deliveryAddress)

            if (delivery.deliveryWarehouse) {
                setWDelivery({
                    lat: delivery.deliveryWarehouse.address.latitude,
                    lng: delivery.deliveryWarehouse.address.longitude,
                    label: "",
                    types: [],
                    status: "",
                    uuid: delivery?.uuid ? delivery?.uuid : "",
                    reputationLevel: "",
                    address: delivery.deliveryWarehouse.address.fullAddress,
                    addressUUID: delivery.deliveryWarehouse.address.uuid
                })
            }

            if (delivery.pickupWarehouse) {
                setWPickUp({
                    lat: delivery.pickupWarehouse.address.latitude,
                    lng: delivery.pickupWarehouse.address.longitude,
                    label: "",
                    types: [],
                    status: "",
                    uuid: delivery?.uuid ? delivery?.uuid : "",
                    reputationLevel: "",
                    address: delivery.pickupWarehouse.address.fullAddress,
                    addressUUID: delivery.pickupWarehouse.address.uuid
                })
            }
        } else {
            setState(newLoadDelivery());
            setSnapshot('*')
        }

    }, [delivery])

    useEffect(() => {
        if (newAdress && getUUidAddress) {
            (async () => {
                const { uuid, fullAddress, ...rest } = newAdress;
                let body: AddressRequestDto = rest;
                const [error, data] = await AddressService.getOrCreateAddress(body);
                if (data) {
                    newAdress.uuid = data.uuid;
                    deliveryAdress
                        ? setAdressDelivery(newAdress)
                        : setAdressPickUp(newAdress);
                    deliveryAdress
                        ? setState((st) => ({
                            ...st,
                            deliveryAddress: newAdress,
                        }))
                        : setState((st) => ({
                            ...st,
                            pickupAddress: newAdress,

                        }))
                }
                if (error) {
                    console.log(error);
                }
            })()
        }
        setDeliveryAdress(false);
        setGetUUidAddress(false);
    }, [getUUidAddress])


    const handlerFragil = (event: any) => {
        let b: string = event.target.value as string;
        if (state) {
            setState((st) => { return { ...st, deliveryType: b } })
        }
    }
    const handlerDescription = (s: string) => {
        setState((st) => { return { ...st, description: s } })
    }
    const handlerDeliveredBy = (event: any) => {
        let value = event.target.value;
        setState((st) => { return { ...st, deliveredBy: toTimestamp(value) } })
    }

    useEffect(() => {
        props.getDeliveryContent(state);
    }, [state])

    const correctInterval = () => {
        return (state.deliveredBy < Date.now()) && !(JSON.stringify(dateToYMD(state.deliveredBy)) === JSON.stringify(dateToYMD(Date.now())))
    }


    useEffect(() => {
        let er: boolean = false;
        let ter: Array<string> = [];

        if ((!state.deliveryType)) {
            er = true;
        }

        if ((!state.deliveredBy)) {
            er = true;
        }

        if ((!state.pickupAddress && !state.pickupWarehouse)) {
            er = true;
        }
        if ((!state.deliveryAddress && !state.deliveryWarehouse)) {
            er = true;
        }
        if (correctInterval()) {
            er = true;
        }

        if (state && !er) {
            if (props.newSelModel) { setError(1) } else {
                if (snapshot === JSON.stringify(state)) {
                    setError(0)

                } else {
                    setError(1)
                }
            }

        } else {
            setError(-1)
        }

        setTextError(ter);
    }, [delivery, state, snapshot, props.newSelModel])


    return (
        <>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: 10 }} >
                <div style={{ paddingLeft: "50px", display: "flex", flexDirection: "row" }}>

                    <div style={{ display: "flex", flexDirection: "column", width: "456px", gap: 10 }}>
                        <div style={{ width: "456px", display: "flex", gap: 10, marginBottom: "20px" }}>
                            <TextField
                                error={!state.deliveryType}
                                select
                                id="demo-select-small"
                                value={state.deliveryType || ''}
                                onChange={handlerFragil}
                                disabled={!editableMode}
                                sx={{ width: "240px" }}
                                label={"Delivery Type"}
                                inputProps={{ sx: { fontSize: "14px" } }}
                            >
                                {ObjectToValueArray(ELoadDeliveryType).map((option: string, i) =>
                                    <MenuItem key={i} value={option}>
                                        {option.split('_').join(' ')}
                                    </MenuItem>)}
                            </TextField>

                            <TextField
                                label={"Delivered By"}
                                error={!state.deliveredBy || correctInterval()}
                                type="date"
                                value={dateToYMD(state.deliveredBy) || ''}
                                disabled={!editableMode}
                                onChange={handlerDeliveredBy}
                                sx={{ width: "240px" }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{ sx: { fontSize: "14px", widht: "145px" } }}
                            />

                        </div>
                        <div style={{ width: "456px", display: "flex", gap: 10 }}>
                            <div style={{ width: "240px", display: "flex", flexDirection: "column", gap: "20px", marginTop: "5px" }}>
                                <div>
                                    <StyledDivider sx={{ fontSize: "12px" }} textAlign="left" >PICKUP</StyledDivider>
                                </div>

                                {state.pickupAddress ?
                                    <TextField
                                        label="Pickup Adress"
                                        disabled
                                        multiline
                                        rows={3}
                                        value={state.pickupAddress?.fullAddress.replaceAll('**', ' ')}
                                        onChange={handleChange}
                                        inputProps={{ sx: { fontSize: "11px", padding: "1px", lineHeight: "1.5" } }}
                                        InputLabelProps={{ sx: { fontSize: "17px" } }}
                                    /> : <div style={{ marginTop: "-6px" }}> </div>}
                                {state.pickupAddress && <Divider />}
                                <div style={{ display: "flex", gap: "10px" }}>
                                    {(state.pickupAddress || state.pickupWarehouse) &&
                                        <Tooltip
                                            title={(hints) ? 'Remove pickup warehouse and address' : ''}
                                        >
                                            <Box>
                                                <Button variant={'contained'} onClick={deleteWPicIp} color={'warning'} size={'small'} disabled={!editableMode}>
                                                    <CancelPresentationIcon fontSize="small" />
                                                </Button>
                                            </Box>

                                        </Tooltip>
                                    }
                                    <Tooltip
                                        title={(hints) ? state.pickupWarehouse ? 'Update pickup warehouse' : 'Add pickup warehouse' : ''}
                                    >
                                        <Box>
                                            <Button variant={'contained'} onClick={handleClickOpenWP} color={(state.pickupWarehouse || state.pickupAddress) ? 'secondary' : 'error'} size={'small'} disabled={!editableMode}>
                                                <WarehouseIcon fontSize="small" />
                                            </Button>
                                        </Box>
                                    </Tooltip>
                                    <Tooltip
                                        title={(hints) ? state.pickupAddress ? 'Update pickup address' : 'Add pickup address' : ''}
                                    >
                                        <Box>
                                            <Button variant={'contained'} onClick={handleClickOpen} color={(state.pickupWarehouse || state.pickupAddress) ? 'secondary' : 'error'} size={'small'} disabled={!editableMode}>
                                                <AddLocationAltIcon fontSize="small" />
                                            </Button>
                                        </Box>
                                    </Tooltip>
                                </div>
                                <Divider />
                                {state.pickupWarehouse ?
                                    <TextField
                                        label="Pickup Warehouse"
                                        disabled
                                        multiline
                                        rows={3}
                                        value={wPickUp?.address?.replaceAll('**', ' ')}
                                        onChange={handleChange}
                                        inputProps={{ sx: { fontSize: "11px", padding: "1px", lineHeight: "1.5" } }}
                                        InputLabelProps={{ sx: { fontSize: "17px" } }}
                                    /> : <div style={{ marginTop: "-6px" }}> </div>}
                            </div>
                            <div style={{ width: "240px", display: "flex", flexDirection: "column", gap: "20px", marginTop: "5px" }}>
                                <div>
                                    <StyledDivider sx={{ fontSize: "12px" }} textAlign="left" >DELIVERY</StyledDivider>
                                </div>
                                {state.deliveryAddress ?
                                    <TextField
                                        label="Delivery Adress"
                                        disabled
                                        multiline
                                        rows={3}
                                        value={adressDelivery?.fullAddress.replaceAll('**', ' ')}
                                        onChange={handleChange}
                                        inputProps={{ sx: { fontSize: "11px", padding: "1px", lineHeight: "1.5" } }}
                                        InputLabelProps={{ sx: { fontSize: "17px" } }}
                                    /> : <div style={{ marginTop: "-6px" }}> </div>}
                                {state.deliveryAddress && <Divider />}
                                <div style={{ display: "flex", gap: "10px", justifyContent: "flex-end" }}>
                                    <Tooltip
                                        title={(hints) ? state.deliveryAddress ? 'Update delivery address' : 'Add delivery address' : ''}
                                    >
                                        <Box>
                                            <Button variant={'contained'} onClick={handleClickOpenB} color={state.deliveryAddress || state.deliveryWarehouse ? 'info' : 'error'} size={'small'} disabled={!editableMode}>
                                                <AddLocationAltIcon fontSize="small" />
                                            </Button>
                                        </Box>

                                    </Tooltip>
                                    <Tooltip
                                        title={(hints) ? state.deliveryWarehouse ? 'Update delivery warehouse' : 'Add delivery warehouse' : ''}
                                    >
                                        <Box>
                                            <Button
                                                variant={'contained'}
                                                onClick={handleClickOpenWD}
                                                color={state.deliveryAddress || state.deliveryWarehouse ? 'info' : 'error'} size={'small'}
                                                disabled={!editableMode}
                                            >

                                                <WarehouseIcon fontSize="small" />
                                            </Button>
                                        </Box>

                                    </Tooltip>
                                    {
                                        (state.deliveryAddress || state.deliveryWarehouse) &&
                                        <Tooltip
                                            title={(hints) ? 'Remove delivery warehouse and address' : ''}
                                        >
                                            <Box>

                                                <Button variant={'contained'} onClick={deleteWDelivery} color={'warning'} size={'small'} disabled={!editableMode}>
                                                    <CancelPresentationIcon fontSize="small" />
                                                </Button>
                                            </Box>

                                        </Tooltip>}

                                </div>
                                <Divider />
                                {(state.deliveryWarehouse) ?
                                    <TextField
                                        label="Delivery Warehouse"
                                        disabled
                                        multiline
                                        rows={3}
                                        value={wDelivery?.address?.replaceAll('**', ' ')}
                                        onChange={handleChange}
                                        inputProps={{ sx: { fontSize: "11px", padding: "1px", lineHeight: "1.5" } }}
                                        InputLabelProps={{ sx: { fontSize: "17px" } }}
                                    /> : <div style={{ marginTop: "-6px" }}> </div>}
                            </div>
                        </div>

                        <div style={{ marginTop: "25px", marginBottom: "20px" }}>
                            <HTMLRedactor

                                getValue={handlerDescription}
                                initValue={description}
                            />
                        </div>
                        {!editableMode &&
                            <div style={{ marginTop: "-160px", width: "460px", opacity: "0.2", height: "120px" }}>
                            </div>
                        }
                    </div>
                </div>

            </div>

            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle></DialogTitle>
                <DialogContent>
                    <AddressComponent current={adressPickUp ? adressPickUp : null} getPlace={handleNewAdres} getEdit={handleEdit} />
                </DialogContent>
                <DialogActions sx={{ marginBottom: "10px", marginRight: "20px" }}>
                    <Button variant={'contained'} color={'info'}
                        onClick={handleActivateAddress} disabled={!newAdress || newAdress.zipcode.length < 4}> Apply/Select
                    </Button>
                    <Button variant={'contained'} color={'warning'} onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={openB}
                onClose={handleCloseB}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle></DialogTitle>
                <DialogContent>
                    <AddressComponent current={adressDelivery ? adressDelivery : null} getPlace={handleNewAdres} getEdit={handleEdit} />
                </DialogContent>
                <DialogActions sx={{ marginBottom: "10px", marginRight: "20px" }}>
                    <Button variant={'contained'} color={'info'}
                        onClick={handleActivateAddressDelivery} disabled={!newAdress || newAdress.zipcode.length < 4}> Apply/Select
                    </Button>
                    <Button variant={'contained'} color={'warning'} onClick={handleCloseB}>Cancel</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={openW}
                onClose={handleCloseW}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{'SELECT WAREHOUSE FOR PICKUP & DELIVERY'}</DialogTitle>
                <DialogContent>
                    <div style={{ width: "100%", height: "700px" }}>
                        <WMapComponent linkTo={addWarehouseId} />
                    </div>
                </DialogContent>
                <DialogActions sx={{ marginBottom: "10px", marginRight: "20px" }}>
                    <Button variant={'contained'} color={'info'} onClick={fixWarehouseId}>
                        APPLY/SELECT
                    </Button>
                    <Button variant={'contained'} color={'warning'} onClick={handleCloseW}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </>
    )

}
export default EditableTableComponent