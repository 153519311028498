import { useTranslation } from "react-i18next";
import { Avatar, Box, IconButton, Tooltip, Typography } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DownloadIcon from '@mui/icons-material/Download';
import { EContactType, IUserInfoResponseDto, IUserMetadata, UserContactsResponseDto } from "../../models/IUser";
import StarsIcon from '@mui/icons-material/Stars';
import { realStringInfoCoverFace } from "../../helpers/textHelper";
import EmailIcon from '@mui/icons-material/Email';
import { AppDispatch, RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import { modifyRightFlip } from "../../store/reducers/cnfSlice";
import { useNavigate } from "react-router";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { addFavoritesUsers } from "../../store/reducers/favoritesSlice";
import { useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import ReportUser from "../ReportsModule/User/ReportUser";
import axios from "axios";
import { getTokens } from "../../services/TokensService";
import { settings } from "../../configs/settings";
import AvatarComponent from "../CustomComponentModule/AvatarComponent";

const styled = {
  container: { display: "flex", gap: "20px" },
  leftPart: { width: "200px", padding: "0px 10px 50px 10px", height: "1060px" },
  centerPart: { width: "100%", padding: "20px 10px 50px 10px" },
  rigthPart: { width: "450px", padding: "5px 10px 50px 10px" },
  item: { width: "40px" },
  box: { border: "1px solid silver", textAlign: "center", borderRadius: "3px" },
  icon: { fontSize: "28px", cursor: "pointer" },
  button: { border: "1px solid silver" },
  info: {},
  rows: { display: "flex", gap: "2px" },
  boxis: { width: "200px", padding: "4px", fontSize: "12px", overflow: "hidden" },
}

interface IPropsCoverFaceUserComponent {
  user: IUserInfoResponseDto | null,
  userMetadata: IUserMetadata | null,
  userContact: UserContactsResponseDto[],
}
const CoverFaceUserComponent = (props: IPropsCoverFaceUserComponent) => {

  const { user, userMetadata, userContact } = props;
  const { t } = useTranslation();
  const { hints } = useSelector((state: RootState) => state.cnfSlice.interfaceState);
  const { auth } = useSelector((state: RootState) => state.cnfSlice);
  const { accessToken } = getTokens();
  const [phone, setPhone] = useState("");

  const linkTel = useRef<HTMLAnchorElement | null>(null);
  const componentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (userContact) {
      let primaryPhone: UserContactsResponseDto[] = (userContact.filter(e => e.type === EContactType.PRIMARY_PHONE))
      if (primaryPhone.length > 0) {
        setPhone(primaryPhone[0].value)
      }
    }
  }, [userContact]);

  const hendlerExcel = () => {
    let body = {
      criteria: [
        {
          property: "uuid",
          value: user?.uuid,
          expression: "EQUALS"
        }
      ]
    }

    axios({
      url: settings.baseURL + 'user/report',
      method: 'POST',
      headers: {
        "Authorization": accessToken?.trim() !== '' ? "Bearer " + accessToken : '',
        "Content-Type": "application/json"
      },
      data: JSON.stringify(body),
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const fileName = response.headers['content-disposition'].split('filename=')[1].replaceAll('"', '');
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', fileName);

      document.body.appendChild(link);

      link.click();
      link.remove();
    });
  }

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleClose = () => {
    dispatch(modifyRightFlip(false));
    navigate('/user');
  }
  const handlerLinkTel = () => {
    linkTel.current?.click()
  }

  const handlerAddToFavorite = () => {
    if (user) {
      dispatch(addFavoritesUsers([user?.uuid]));
    }
  }

  const handlerCreateEmail = () => {
    let url = 'https://mail.google.com/mail/?view=cm&fs=1';
    let to = '&to=' + user?.email;
    let bcc = '&bcc=';
    if (auth) {
      bcc += auth.email
    }
    window.open(url + to + bcc);
  }

  return (
    <>
      <Box sx={{ padding: 2, marginTop: "1", display: "flex" }}>
        <div style={{ display: "flex", alignItems: "center", marginLeft: "10px" }}>
          {
            ((user?.avatar) && (user.avatar !== 'N/A'))
              ? <AvatarComponent link={user.avatar} />
              : <Avatar src={''} />
          }
        </div>

        <div style={{ display: "flex", flexDirection: "column", marginLeft: "35px" }}>
          <Box style={styled.rows}>
            <Typography variant="subtitle1" align="left" >
              <b>{(realStringInfoCoverFace(user?.firstName) + ' ' + realStringInfoCoverFace(user?.lastName))}</b>
            </Typography>
          </Box>

          <Box style={styled.rows}>

            {(!(realStringInfoCoverFace(user?.firstName) === ''))
              ? <div style={styled.boxis}>{(user?.email)?.toLocaleLowerCase()}</div>
              : <Typography variant="subtitle1" align="left" >
                <b>{(user?.email)?.toLocaleLowerCase()}</b>
              </Typography>
            }
          </Box>
        </div>

        <div style={{ display: "flex", alignItems: "center", marginLeft: "45px" }}>
          <div style={{ paddingLeft: "10px", display: "flex", gap: "10px", height: "40px", borderLeft: "1px dashed silver", justifyContent: "space-between", width: "350px" }}>
            {!userMetadata?.completed ?
              <Typography variant='subtitle2' sx={{ padding: "3px", paddingTop: "9px" }} color='red'>INCOMPLETE USER</Typography>
              : <div>
                <Tooltip
                  title={(hints) ? t("Add user to favorites") : ''}
                  placement={"top"}
                  arrow={true}
                >
                  <IconButton
                    onClick={handlerAddToFavorite}
                  >
                    <StarsIcon style={styled.icon} />
                  </IconButton>
                </Tooltip>

                <Tooltip
                  title={(hints) ? t("Send email to selected user") : ''}
                  placement={"top"}
                  arrow={true}
                >
                  <IconButton
                    onClick={handlerCreateEmail}
                  >
                    <EmailIcon style={styled.icon} />
                  </IconButton>
                </Tooltip>

                <Tooltip
                  title={(hints) ? t("Call the selected user") : ''}
                  placement={"top"}
                  arrow={true}
                >
                  <IconButton
                    onClick={handlerLinkTel}
                  >
                    <PhoneAndroidIcon style={styled.icon} />
                  </IconButton>
                </Tooltip>

                <Tooltip
                  title={(hints) ? t("Generate PDF report for selected user") : ''}
                  placement={"top"}
                  arrow={true}
                >
                  <span>
                    <ReactToPrint
                      trigger={() => <IconButton disabled>
                        <PictureAsPdfIcon style={styled.icon} />
                      </IconButton>}
                      content={() => componentRef.current}
                    />
                  </span>
                </Tooltip>

                <Tooltip
                  title={(hints) ? t("Download XLS report for selected user") : ''}
                  placement={"top"}
                  arrow={true}
                  onClick={hendlerExcel}
                >
                  <IconButton>
                    <DownloadIcon style={styled.icon} />
                  </IconButton>
                </Tooltip>
              </div>}
            <div style={{ width: "0px", height: "0px", padding: 0, margin: 0, overflow: "hidden" }}>

              <div ref={componentRef}>
                <ReportUser user={user ? user : null} />

              </div>
            </div>
            <Tooltip
              title={(hints) ? t("Close and return to the list of users") : ''}
              placement={"top"}
              arrow={true}
            >
              <IconButton onClick={handleClose} >
                <HighlightOffIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </Box>
      <div style={{ width: "0px", height: "0px", overflow: "hidden", border: "1px solid silver" }}>
        <a ref={linkTel} href={"tel:" + phone} data-rel="external">aa</a>
      </div>
    </>
  )
}

export default CoverFaceUserComponent;