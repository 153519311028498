import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Divider, Button } from '@mui/material';

import { modifyAutoRole } from "../../../store/reducers/cnfSlice";
import { RootState } from "../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { EVehicleType } from "../../../models/TransportModels";

export default function ListTransportComponent() {

  const { t } = useTranslation();
  const { part, role } = useSelector((state: RootState) => state.cnfSlice.auto);
  const dispatch = useDispatch();

  const handleFilterRole = (r: any) => {
    dispatch(modifyAutoRole(r[0]));
  }

  useEffect(() => {
    Object.entries(EVehicleType);
  }, [])

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
        <Divider />
        {(role !== 'all') ?
          <Button
            color='primary'
            onClick={() => handleFilterRole(["all", "all"])}
            size="small"
            variant="contained"
            sx={{ width: "100%", fontSize: "12px" }}>{t("All ")} {t(part)}
          </Button>
          :
          <Button
            color='inherit'
            size="small"
            variant="contained"
            disabled={true}
            sx={{ width: "100%", fontSize: "12px" }}
          >
            {t("All ")} {part}
          </Button>}
        <Divider />

        {Object.entries(EVehicleType).map((r: any, i) =>
          <div key={i}>
            <Button
              disabled={role === t(r)}
              variant={!(role === t(r)) ? 'text' : 'contained'}
              color='inherit'
              onClick={() => handleFilterRole(r)}
              size="small"
              sx={{ width: "100%", marginBottom: "5px", fontSize: "12px" }}
            >
              {r[0].split('_').join(' ')}
            </Button>
            <Divider />
          </div>
        )}
      </div>
    </>
  );
}