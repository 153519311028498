import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DownloadIcon from '@mui/icons-material/Download';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import StafService from "../../../services/StafService";
import { EStatusStafServer, IResponseAutocomplete, IUserResponseDto } from "../../../models/IUser";
import StarsIcon from '@mui/icons-material/Stars';
import { realStringInfo } from "../../../helpers/textHelper";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import { modifyLeftFlip, modifyRightFlip, modifyStafSizePage, modifyStafPage, modifyStafRefresh } from "../../../store/reducers/cnfSlice";
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import ActivateDetailsComponent from "../../CustomComponentModule/ActivateDetailsComponent";
import { addFavoritesUsers } from "../../../store/reducers/favoritesSlice";
import React from "react";
import ReportUserComponent from "../../ReportsModule/ReportUserComponent";
import CloseIcon from '@mui/icons-material/Close';
import axios from "axios";
import { getTokens } from "../../../services/TokensService";
import { dateToYMD, getDateAndTimeFormat } from "../../../helpers/dateHelper";
import AvatarUserComponent from "../AvatarUserComponent";
import { settings } from "../../../configs/settings";

export default function GridStafComponent() {

  const { t } = useTranslation();
  const { id } = useParams();
  const { auth } = useSelector((state: RootState) => state.cnfSlice);

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { refresh, page, size, part, role } = useSelector((state: RootState) => state.cnfSlice.staf);
  const { hints, dateFormat, timeFormat } = useSelector((state: RootState) => state.cnfSlice.interfaceState);

  const [checkState, setCheckState] = useState(true);
  const [selectionModel, setSelectionModel] = useState<Array<string>>([]);

  const [statePdfSelect, setStatePdfSelect] = useState<IResponseAutocomplete[]>([])
  const [open, setOpen] = React.useState(false);

  const [rowUsers, setRowUsers] = useState<IUserResponseDto[]>([]);
  const [pageSizeC, setPageSizeC] = useState(size);
  const [pageC, setPageC] = useState(page);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTtalRows] = useState(0);
  const { accessToken } = getTokens();

  const hendlerExcel = () => {
    let criteria: any[] = [];
    if (role !== 'all') {
      criteria.push({
        property: "authorities.authority",
        value: role,
        expression: "IN"
      });
    }

    criteria.push({
      property: "status",
      value: part,
      expression: "EQUALS"
    });

    let body = {
      criteria: criteria,
      paging: {
        page: 0,
        size: totalRows
      }
    };

    axios({
      baseURL: settings.baseURL,
      url: 'user/report',
      method: 'POST',
      headers: {
        "Authorization": accessToken?.trim() !== '' ? "Bearer " + accessToken : '',
        "Content-Type": "application/json"
      },
      data: JSON.stringify(body),
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const fileName = response.headers['content-disposition'].split('filename=')[1].replaceAll('"', '');
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', fileName);

      document.body.appendChild(link);

      link.click();
      link.remove();
    });
  }

  const hendlerEmailList = () => {
    let url = 'https://mail.google.com/mail/?view=cm&fs=1';
    let to = '&to=';
    let bcc = '&bcc=';
    if (auth) {
      bcc += auth.email
    }
    for (let elm of rowUsers) {
      if (selectionModel.includes(elm.uuid)) {
        to += elm.email + ','
      }
    }
    window.open(url + to + bcc);
  }


  const hendlerFavoritesList = () => {
    let list: string[] = [];
    for (let elm of rowUsers) {
      if (selectionModel.includes(elm.uuid)) {
        list.push(elm.uuid);
      }
    }
    dispatch(addFavoritesUsers(list));
  }

  const handleOpen = () => {
    setOpen(true);
  }
  const handleClose = () => {
    setOpen(false);
  }

  const handlerRefresh = () => {
    dispatch(modifyStafRefresh());
  }

  const handlerPage = (newPage: number) => {
    dispatch(modifyStafPage(newPage));
    setPageC(newPage);
  }

  const handlerPageSize = (newPageSize: number) => {
    dispatch(modifyStafSizePage(newPageSize));
    setPageSizeC(newPageSize);
  }

  const handlerCheck = () => {
    setSelectionModel([]);
    setCheckState((v) => { return !v });
  }

  const handleClick = (cellValues: any) => {
    navigate('/user/' + cellValues.row.id);
    dispatch(modifyRightFlip(true));
    if (!selectionModel.includes(cellValues.row.id.toString())) {
    }
  }

  const hendleShowLeft = () => {
    dispatch(modifyLeftFlip());
  }

  useEffect(() => {
    setLoading(true);
    (async () => {
      const [error, response] = await StafService.getStaf(role, part, page, size);
      if (response) {
        let rs = response.data.body;
        setTtalRows(response.data.total);
        if (rs) {
          const st: IUserResponseDto[] = rs.map((e: IUserResponseDto) => (
            {
              ...e,
              id: e.uuid,
              firstName: realStringInfo(e.firstName),
              lastName: realStringInfo(e.lastName),

            })
          )
          setRowUsers(st);
        }
      }
      if (error) { }
      setLoading(false);
    })()
  }, [role, part, page, size, refresh])

  useEffect(() => {
    if (id) {
      if (!selectionModel.includes(id.toString())) {
        setSelectionModel(list => [id, ...list]);
      }
    }
  }, [id])

  const columns: GridColDef[] = [
    {
      field: "2", headerName: '', width: 50, sortable: false, filterable: false, disableColumnMenu: true,
      renderCell: (cellValues: any) => {
        return (
          <>
            <div style={{ display: "flex", textAlign: "center", justifyContent: "center", padding: 0 }}>
              <Tooltip
                title={"User details"}
                arrow={true}
                placement="bottom"
              >
                <Box>
                  <ActivateDetailsComponent
                    activate={() => handleClick(cellValues)}
                  />
                </Box>
              </Tooltip>
            </div>
          </>
        );
      }
    },
    {
      field: "1", headerName: '', sortable: false, filterable: false, disableColumnMenu: true, width: 65,
      renderCell: (cellValues: any) => {
        return (
          <>
            <div style={{ display: "flex", gap: "10px" }}>
              <div style={{ height: "30px", width: "5px", background: cellValues.row.color }}></div>
              {
                ((cellValues.row.avatar) && (cellValues.row.avatar !== 'N/A'))
                  ? <AvatarUserComponent data={cellValues.row.avatar} online={cellValues.row.online} />
                  : <AnyPhoto online={cellValues.row.online} />
              }
            </div>
          </>
        );
      }
    },
    {
      field: 'firstName', headerName: t('full name').toUpperCase(), width: 200, headerAlign: "center",
      renderCell: (cellValues: any) => {
        return (
          <>
            <div>
              {cellValues.row.firstName === '*'

                ? <Typography variant={'body2'} fontSize={'11px'}>{'N/A'} </Typography>

                : <Tooltip
                  title={cellValues.row.firstName.toLocaleUpperCase() + " " + cellValues.row.lastName.toLocaleUpperCase()}
                  placement={"bottom"}
                  arrow={true}
                >
                  <Typography variant={'body2'} fontSize={'11px'}>{cellValues.row.firstName} {cellValues.row.lastName} </Typography>
                </Tooltip>
              }
            </div>
          </>
        );
      }
    },
    {
      field: "4", headerName: t('email').toUpperCase(), sortable: true, width: 240, headerAlign: "center",
      renderCell: (cellValues: any) => {
        return (
          <>
            <div>
              {cellValues.row.role &&
                <Tooltip
                  title={String(cellValues.row.email).toLocaleUpperCase()}
                  placement={"bottom"}
                  arrow={true}
                >
                  <Typography variant={'body2'} fontSize={'11px'}>{String(cellValues.row.email).toLocaleUpperCase()} </Typography>
                </Tooltip>
              }
            </div>
          </>
        );
      }
    },
    {
      field: "5", headerName: t('username').toUpperCase(), sortable: true, width: 140, headerAlign: "center",
      renderCell: (cellValues: any) => {
        return (
          <>
            <div>
              {cellValues.row.role &&
                <Tooltip
                  title={cellValues.row.username.toLocaleUpperCase()}

                  placement={"bottom"}
                  arrow={true}
                >

                  <Typography variant={'body2'} fontSize={'11px'}>{String(cellValues.row.username.toLocaleUpperCase())} </Typography>
                </Tooltip>

              }
            </div>
          </>
        );
      }
    },
    {
      field: "6", headerName: t('role').toUpperCase(), sortable: true, width: 140, headerAlign: "center",

      renderCell: (cellValues: any) => {
        return (
          <>
            <div>
              {cellValues.row.role &&
                <Typography variant={'body2'} fontSize={'11px'}>{cellValues.row.role.split('_').join(' ')} </Typography>
              }
            </div>
          </>
        );
      }
    },
    {
      field: "7", headerName: t('AVAILABLE'), sortable: false, width: 120, headerAlign: "center",
      renderCell: (cellValues: any) => {
        return (
          <>
            <div>
              <Typography variant={'body2'} fontSize={'13px'}>
                {cellValues.row.available ? 'YES' : 'NO'}
              </Typography>
            </div>
          </>
        );
      }
    },
    {
      field: "8", headerName: t('status').toUpperCase(), sortable: true, width: 240, headerAlign: "center",
      renderCell: (cellValues: any) => {
        let status: string = (cellValues.row.status === EStatusStafServer.TEMPORARY) ? `${t(cellValues.row.status)} [${dateToYMD(cellValues.row.activeFromDate)} - ${dateToYMD(cellValues.row.activeToDate)}]` : t(cellValues.row.status)
        return (
          <>
            <div>
              {cellValues.row.role &&

                <Tooltip
                  title={(hints) ? status : ''}
                  placement={"bottom"}
                >
                  <Typography variant={'body2'} fontSize={'11px'}>
                    {status}
                  </Typography>
                </Tooltip>
              }
            </div>
          </>
        );
      }
    },
    {
      field: "9", headerName: 'Last activity'.toUpperCase(), sortable: true, width: 160, headerAlign: "center",
      renderCell: (cellValues: any) => {
        const value = getDateAndTimeFormat(cellValues.row.lastActivityDate, dateFormat, timeFormat);
        return (
          <>
            <div>
              {cellValues.row.lastActivityDate &&
                <Tooltip title={value} placement={"bottom"}>
                  <Typography variant={'body2'} fontSize={'11px'}>
                    {value}
                  </Typography>
                </Tooltip>
              }
            </div>
          </>
        );
      }
    },
    {
      field: "10", headerName: 'Created'.toUpperCase(), sortable: true, width: 180, headerAlign: "center",
      renderCell: (cellValues: any) => {
        const date = getDateAndTimeFormat(cellValues.row.createdDate, dateFormat, timeFormat),
          author = cellValues.row.createdBy,
          tooltipValue = author + ' ' + date;
        return (
          <>
            <Tooltip title={tooltipValue} placement={"bottom"}>
              <Typography variant={'body2'} fontSize={'11px'}>
                {author} <br /> {date}
              </Typography>
            </Tooltip>
          </>
        );
      }
    },
    {
      field: "11", headerName: 'Updated'.toUpperCase(), sortable: true, width: 180, headerAlign: "center",
      renderCell: (cellValues: any) => {
        const date = getDateAndTimeFormat(cellValues.row.updatedDate, dateFormat, timeFormat),
          author = cellValues.row.updatedBy,
          tooltipValue = author + ' ' + date;
        return (
          <>
            {cellValues.row.updatedDate &&
              <Tooltip title={tooltipValue} placement={"bottom"}>
                <Typography variant={'body2'} fontSize={'11px'}>
                  {author} <br /> {date}
                </Typography>
              </Tooltip>
            }
          </>
        );
      }
    }
  ];

  const styled = {
    container: { display: "flex", gap: "20px" },
    item: { width: "40px" },
    box: { border: "1px solid silver", textAlign: "center", borderRadius: "3px" },
    icon: { fontSize: "28px", cursor: "pointer" },
    button: { border: "1px solid silver" }
  }

  return (
    <>
      <div style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingBottom: "2px",
        height: "46px"
      }}>
        <div>

          <div style={{ width: "400px", paddingLeft: "7px", display: "flex", gap: "10px" }}>
            <Tooltip
              title={(hints) ? t("Show/Hide the selection column") : ''}
              placement={"top"}
              arrow={true}
            >
              <IconButton onClick={handlerCheck} size='small'>
                <PlaylistAddCheckCircleIcon style={styled.icon} />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={(hints) ? t("Refresh") : ''}
              placement={"top"}
              arrow={true}
            >
              <IconButton onClick={handlerRefresh} size='small'>
                <ReplayCircleFilledIcon style={styled.icon} />
              </IconButton>
            </Tooltip>
            {!(rowUsers.length < 1) && <Tooltip
              title={(hints) ? t("Download XLS report for users") : ''}
              placement={"top"}
              arrow={true}
              onClick={hendlerExcel}
              key={"key"}
            >
              <IconButton>
                <DownloadIcon style={styled.icon} />
              </IconButton>
            </Tooltip>}
            {(selectionModel.length > 0) &&
              <div>
                <Tooltip
                  title={(hints) ? t("Generate PDF report for selected user(s)") : ''}
                  placement={"top"}
                  arrow={true}
                >
                  <IconButton disabled 
                  // onClick={hendlerPdfReportList}
                  >
                    <PictureAsPdfIcon style={styled.icon} color={"primary"} />
                  </IconButton>
                </Tooltip>

                <Tooltip
                  title={(hints) ? t("Send email to selected user(s)") : ''}
                  placement={"top"}
                  arrow={true}
                >
                  <IconButton
                    onClick={hendlerEmailList}
                  >
                    <MarkEmailReadIcon style={styled.icon} color={"primary"} />
                  </IconButton>
                </Tooltip>

                <Tooltip
                  title={(hints) ? t("Add selected user(s) to favorites") : ''}
                  placement={"top"}
                  arrow={true}
                >
                  <IconButton
                    onClick={hendlerFavoritesList}
                  >
                    <StarsIcon style={styled.icon} color={"primary"} />
                  </IconButton>
                </Tooltip>

              </div>
            }
          </div>
        </div>
        <div>
          {(id) &&
            <Tooltip
              title={(hints) ? t("staf") : ''}
              placement={"top"}
              arrow={true}
            >

              <IconButton onClick={hendleShowLeft} >
                <MultipleStopIcon style={styled.icon} color={"primary"} />
              </IconButton>
            </Tooltip>
          }
        </div>

      </div>

      <div style={{ height: "92%" }}>
        <DataGrid
          density={'compact'}
          paginationMode="server"
          loading={loading}
          rows={rowUsers}
          columns={columns}

          rowCount={totalRows}
          page={pageC}
          pageSize={pageSizeC}

          rowsPerPageOptions={[5, 10, 15, 20, 25]}
          onPageSizeChange={handlerPageSize}
          onPageChange={handlerPage}

          checkboxSelection={checkState}
          onSelectionModelChange={(newSelectionModel: any) => {
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
        />
      </div>

      <Dialog open={open} onClose={handleClose} maxWidth={'lg'}>
        <DialogTitle>{`Pdf Report`}</DialogTitle>
        <DialogContent>
          <ReportUserComponent user={statePdfSelect} />
        </DialogContent>
        <DialogActions>
          <div style={{ display: "flex", justifyContent: "right", width: "100%", padding: "20px" }}>
            <Button
              startIcon={<CloseIcon />}
              color={'inherit'}
              variant={'contained'}
              size={'small'}
              onClick={handleClose}>Close</Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}

interface IPhotoProps {
  online?: boolean;
}

export const AnyPhoto = (props: IPhotoProps) => {
  const { online } = props
  return (
    <Avatar sx={{ border: online ? "2px solid #52BD0B" : "", width: "30px", height: "30px" }} src={''} />
  )
}