import { json } from "stream/consumers";
import {TMottos } from "../models/IMotto";
import { API } from "./APIService";

class NotificationApiService {

    public static MarkNotificationAsRead = async (body: Array<String>): Promise<any> => {
        const [error, response] = await API('PUT','notification/read',JSON.stringify(body));
        return [error, response];
    }
    public static MarkNotificationAsUnread = async (body: Array<String>): Promise<any> => {
        const [error, response] = await API('PUT','notification/unread',JSON.stringify(body));
        return [error, response];
    }
    public static GetNumberOfUnreadNotifications = async (): Promise<any> => {
        const [error, response] = await API('GET','notification/count');
        return [error, response];
    }
    public static GetNotifications = async (): Promise<any> => {
        const [error, response] = await API('GET','notification');
        return [error, response];
    }


}

export default NotificationApiService;