import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { IconButton, Link, Rating, Tooltip, Typography, useTheme } from '@mui/material';
import { getDateFormat } from '../../../helpers/dateHelper';
import { useEffect, useState } from 'react';
import styles from './load.module.scss';
import { ILoadDetailsDTO, ILoadSummary } from '../../../models/LoadModels';
import LoadService from '../../../services/LoadService';
import { useNavigate } from 'react-router-dom';
import { curency } from '../../../helpers/textHelper';
import SCViewList from '../../../styledComponents/sharedComponent/SCViewList';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { generateUniqueId } from '../../../helpers/generalHelper';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import { IWarehouseIdNameAddressResponseDto } from '../../../models/WarehouseModels';

interface IProps {
    load: ILoadDetailsDTO | null;
    toStep: (step: number) => void;
}

export default function ViewTableLoadReport(props: IProps) {
    const { load } = props;
    const theme = useTheme();
    const [summary, setSummary] = useState<ILoadSummary>();

    const { dateFormat } = useSelector((state: RootState) => state.cnfSlice.interfaceState);
    const navigate = useNavigate();

    useEffect(() => {
        if (load) {
            (async () => {
                const [, res] = await LoadService.getLoadSummary(load.uuid);
                if (res) {
                    setSummary(res.data.body);
                }
            })();
        }
    }, [load]);

    const boxSX = {
        color: theme.palette.action.disabled,
        "&:hover": {
            color: theme.palette.primary.main
        }
    }

    const pickupAddresses: Array<String> = summary?.pickupAddresses || [],
        pickupWarehouses: Array<IWarehouseIdNameAddressResponseDto> = summary?.pickupWarehouses || [],
        pickupAddressesSize: number = pickupAddresses.length,
        pickupWarehousesSize: number = pickupWarehouses.length,
        pickupOnlyOneAddress: String | null = (pickupAddressesSize === 1) ? pickupAddresses[0] : null,
        pickupOnlyOneWarehouse: IWarehouseIdNameAddressResponseDto | null = (pickupWarehousesSize === 1) ? pickupWarehouses[0] : null;

    const deliveryAddresses: Array<String> = summary?.deliveryAddresses || [],
        deliveryWarehouses: Array<IWarehouseIdNameAddressResponseDto> = summary?.deliveryWarehouses || [],
        deliveryAddressesSize: number = deliveryAddresses.length,
        deliveryWarehousesSize: number = deliveryWarehouses.length,
        deliveryOnlyOneAddress: String | null = (deliveryAddressesSize === 1) ? deliveryAddresses[0] : null,
        deliveryOnlyOneWarehouse: IWarehouseIdNameAddressResponseDto | null = (deliveryWarehousesSize === 1) ? deliveryWarehouses[0] : null;

    return (
        <div>
            <table style={{ width: "100%", marginTop: "10px" }}>
                <tbody>
                    <tr>
                        <td className={styles.but_customer}>
                            <IconButton size={'small'} onClick={() => { props.toStep(0) }} >
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>

                        <td className={styles.label_customer}>
                            <Typography color={'silver'} variant='subtitle2'>CUSTOMER</Typography>
                        </td>
                        <td className={styles.vals_customer}>
                            <Tooltip title={''} placement={"bottom"} sx={{ cursor: 'pointer', }}>
                                <Link style={{ marginTop: '5px', fontSize: "16px" }} onClick={() => { navigate('/customer/' + summary?.customer.uuid) }} underline="hover" >
                                    {summary?.customer.name}
                                </Link>
                            </Tooltip>
                        </td>
                    </tr>

                    <tr>
                        <td className={styles.but_customer} >
                            <IconButton size={'small'} onClick={() => { props.toStep(0) }} >
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={styles.label_customer}>
                            <Typography color={'silver'} variant='subtitle2'>IDNO</Typography>
                        </td>
                        <td className={styles.vals_customer}>
                            <Typography variant='subtitle2'>{summary?.idno}</Typography>
                        </td>
                    </tr>

                    <tr>
                        <td className={styles.but_customer} >
                            <IconButton size={'small'} onClick={() => { props.toStep(1) }} >
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={styles.label_customer}>
                            <Typography color={summary?.totalNumberOfItems !== 0 ? 'silver' : 'error'} variant='subtitle2'>TOTAL ITEMS</Typography>
                        </td>
                        <td className={styles.vals_customer}>
                            <Typography variant='subtitle2'>{summary?.totalNumberOfItems}</Typography>
                        </td>
                    </tr>

                    <tr>
                        <td className={styles.but_customer} >
                            <IconButton size={'small'} onClick={() => { props.toStep(1) }} >
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={styles.label_customer}>
                            <Typography color={'silver'} variant='subtitle2'>LOADED ITEMS</Typography>
                        </td>
                        <td className={styles.vals_customer}>
                            <Typography variant='subtitle2'>{summary?.numberOfLoadedItems}</Typography>
                        </td>
                    </tr>
                    <tr>
                        <td className={styles.but_customer} >
                            <IconButton size={'small'} onClick={() => { props.toStep(1) }} >
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={styles.label_customer}>
                            <Typography color={'silver'} variant='subtitle2'>DELIVERED ITEMS</Typography>
                        </td>
                        <td className={styles.vals_customer}>
                            <Typography variant='subtitle2'>{summary?.numberOfDeliveredItems}</Typography>
                        </td>
                    </tr>

                    <tr>
                        <td className={styles.but_customer} >
                            <IconButton size={'small'} onClick={() => { props.toStep(1) }} >
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={styles.label_customer}>
                            <Typography color={'silver'} variant='subtitle2'>PAYED ITEMS</Typography>
                        </td>
                        <td className={styles.vals_customer}>
                            <Typography variant='subtitle2'>{summary?.numberOfPayedItems}</Typography>
                        </td>
                    </tr>

                    <tr>
                        <td className={styles.but_customer} >

                        </td>
                        <td className={styles.label_customer}>
                            <Typography color={'silver'} variant='subtitle2'>AMOUNT</Typography>
                        </td>
                        <td className={styles.vals_customer}>
                            <Typography variant='subtitle2'>{summary?.totalAmount} {curency}</Typography>
                        </td>
                    </tr>

                    <tr>
                        <td className={styles.but_customer} >

                        </td>
                        <td className={styles.label_customer}>
                            <Typography color={'silver'} variant='subtitle2'>PAYED AMOUNT</Typography>
                        </td>
                        <td className={styles.vals_customer}>
                            <Typography variant='subtitle2'>{summary?.payedAmount} {curency}</Typography>
                        </td>
                    </tr>

                    <tr>
                        <td className={styles.but_customer} >
                            <IconButton size={'small'} onClick={() => { props.toStep(2) }} disabled={!load?.metadata.completed}>
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={styles.label_customer}>
                            <Typography color={'silver'} variant='subtitle2'>PICKUP</Typography>
                        </td>
                        <td className={styles.vals_customer}>
                            <SCViewList>
                                {pickupOnlyOneAddress && !pickupOnlyOneWarehouse &&
                                    <Tooltip key={generateUniqueId()} title={pickupOnlyOneAddress} arrow >
                                        <Typography variant={'body2'} fontSize={'10px'}>
                                            <IconButton color={'primary'} size={'small'}>
                                                <AddLocationAltIcon fontSize="small" />
                                            </IconButton>
                                            {pickupOnlyOneAddress}
                                        </Typography>
                                    </Tooltip>
                                }

                                {pickupOnlyOneWarehouse && !pickupOnlyOneAddress &&
                                    <Tooltip key={generateUniqueId()} title={`${pickupOnlyOneWarehouse.name} [ ${pickupOnlyOneWarehouse.address} ]`} arrow >
                                        <Typography variant={'body2'} fontSize={'10px'}>
                                            <Link onClick={() => { navigate('/warehouse/' + pickupOnlyOneWarehouse.uuid) }} underline="hover" >
                                                <IconButton color={'primary'} size={'small'}>
                                                    <WarehouseIcon fontSize="small" />
                                                </IconButton>
                                            </Link>
                                            {pickupOnlyOneWarehouse.address}
                                        </Typography>
                                    </Tooltip>
                                }

                                {(pickupWarehousesSize > 1 || (pickupOnlyOneWarehouse && pickupOnlyOneAddress)) && pickupWarehouses.map(item => (
                                    <Tooltip key={generateUniqueId()} title={`${item.name} [ ${item.address} ]`} arrow >
                                        <Link style={{ marginRight: 10 }} onClick={() => { navigate('/warehouse/' + item.uuid) }} underline="hover" >
                                            <IconButton color={'primary'} size={'small'}>
                                                <WarehouseIcon fontSize="small" />
                                            </IconButton>
                                        </Link>
                                    </Tooltip>
                                ))}

                                {(pickupAddressesSize > 1 || (pickupOnlyOneWarehouse && pickupOnlyOneAddress)) && pickupAddresses.map(item => (
                                    <Tooltip key={generateUniqueId()} title={item} arrow >
                                        <IconButton style={{ marginRight: 10 }} color={'primary'} size={'small'}>
                                            <AddLocationAltIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                ))}
                            </SCViewList >
                        </td>
                    </tr>
                    <tr>
                        <td className={styles.but_customer} >
                            <IconButton size={'small'} onClick={() => { props.toStep(2) }} disabled={!load?.metadata.completed}>
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={styles.label_customer}>
                            <Typography color={'silver'} variant='subtitle2'>DELIVERY</Typography>
                        </td>
                        <td className={styles.vals_customer}>
                            <SCViewList>
                                {deliveryOnlyOneAddress && !deliveryOnlyOneWarehouse &&
                                    <Tooltip key={generateUniqueId()} title={deliveryOnlyOneAddress} arrow >
                                        <Typography variant={'body2'} fontSize={'10px'}>
                                            <IconButton color={'success'} size={'small'}>
                                                <AddLocationAltIcon fontSize="small" />
                                            </IconButton>
                                            {deliveryOnlyOneAddress}
                                        </Typography>
                                    </Tooltip>
                                }

                                {deliveryOnlyOneWarehouse && !deliveryOnlyOneAddress &&
                                    <Tooltip key={generateUniqueId()} title={`${deliveryOnlyOneWarehouse.name} [ ${deliveryOnlyOneWarehouse.address} ]`} arrow >
                                        <Typography variant={'body2'} fontSize={'10px'}>
                                            <Link onClick={() => { navigate('/warehouse/' + deliveryOnlyOneWarehouse.uuid) }} underline="hover" >
                                                <IconButton color={'success'} size={'small'}>
                                                    <WarehouseIcon fontSize="small" />
                                                </IconButton>
                                            </Link>
                                            {deliveryOnlyOneWarehouse.address}
                                        </Typography>
                                    </Tooltip>
                                }

                                {(deliveryWarehousesSize > 1 || (deliveryOnlyOneWarehouse && deliveryOnlyOneAddress)) && deliveryWarehouses.map(item => (
                                    <Tooltip key={generateUniqueId()} title={`${item.name} [ ${item.address} ]`} arrow >
                                        <Link style={{ marginRight: 10 }} onClick={() => { navigate('/warehouse/' + item.uuid) }} underline="hover" >
                                            <IconButton color={'success'} size={'small'}>
                                                <WarehouseIcon fontSize="small" />
                                            </IconButton>
                                        </Link>
                                    </Tooltip>
                                ))}

                                {(deliveryAddressesSize > 1 || (deliveryOnlyOneWarehouse && deliveryOnlyOneAddress)) && deliveryAddresses.map(item => (
                                    <Tooltip key={generateUniqueId()} title={item} arrow >
                                        <IconButton style={{ marginRight: 10 }} color={'success'} size={'small'}>
                                            <AddLocationAltIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                ))}
                            </SCViewList>
                        </td>
                    </tr>
                    <tr>
                        <td className={styles.but_customer} >
                            <IconButton size={'small'} onClick={() => { props.toStep(6) }} disabled={!load?.metadata.completed}>
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={styles.label_customer}>
                            <Typography color={'silver'} variant='subtitle2'>STATUS</Typography>
                        </td>
                        <td className={styles.vals_customer}>
                            <Typography variant='subtitle2'>{summary?.status} </Typography>
                        </td>
                    </tr>
                    <tr>
                        <td className={styles.but_customer} >

                        </td>
                        <td className={styles.label_customer}>
                            <Typography color={'silver'} variant='subtitle2'>HOT</Typography>
                        </td>
                        <td className={styles.vals_customer}>
                            {summary?.hotDetails.hot
                                ? <SCViewList column>
                                    <Typography variant='subtitle2'>ITEM(S): {summary.hotDetails.hotItems}</Typography>
                                    <Typography variant='subtitle2'>HOTTEST ITEM:  {`${getDateFormat(summary?.hotDetails.hottestItemDate, dateFormat)}`}</Typography>
                                </SCViewList>

                                : <Typography variant='subtitle2'>NO</Typography>
                            }
                        </td>
                    </tr>

                    <tr>
                        <td className={styles.but_customer} >

                        </td>
                        <td className={styles.label_customer}>
                            <Typography color={'silver'} variant='subtitle2'>ACTIVE LOAD</Typography>
                        </td>
                        <td className={styles.vals_customer}>

                        </td>
                    </tr>

                    <tr>
                        <td className={styles.but_customer} >
                        </td>
                        <td className={styles.label_customer}>
                            <Typography color={'silver'} variant='subtitle2' >
                                REVIEW MARK
                            </Typography>
                        </td>
                        <td className={styles.vals_customer}>
                            {
                                <Rating value={summary?.reviewMark ? summary?.reviewMark : 0} readOnly ></Rating>
                            }
                        </td>
                    </tr>
                </tbody>
            </table>
        </div >
    );
}