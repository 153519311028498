export const compareValue = (a1: any, b1: any): boolean => {
    return JSON.stringify(a1) === JSON.stringify(b1);
}

export const isValidPhone = (phoneNumber: string): boolean => {
    if (phoneNumber.length < 8 || phoneNumber.length > 16) {
        return false;
    }

    if (phoneNumber.startsWith('00')) {
        return false;
    }

    const prefixPhoneRegex = /^\+\d+$/,
        isPrefixZeroFirstCharacters = phoneNumber.startsWith('+0');
    if (prefixPhoneRegex.test(phoneNumber) && !isPrefixZeroFirstCharacters) {
        return true;
    }

    const onlyDigitsRegex = /^\d+$/,
        isZeroFirstCharacter = phoneNumber.startsWith('0');
    if (isZeroFirstCharacter && onlyDigitsRegex.test(phoneNumber)) {
        return true;
    }

    return false;
}

export const isRealNumber = (n: string) => {
    let pattern = /^\d*[.]?\d*$/;
    return pattern.test(n);
}

export const isSSN = (str: string) => {
    let pattern = /^(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/;
    return pattern.test(str);
}

export const isNumber = (str: string) => {
    let pattern = /^\d+\.?\d*$/;
    return pattern.test(str);
}

export const isValidEmail = (email: string) => {
    return /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/.test(email);
}

export const isValidUrl = (url: string) => {
    return /^((https?|ftp)\:\/\/)?([a-z0-9]{1})((\.[a-z0-9-])|([a-z0-9-]))*\.([a-z]{2,6})(\/?)$/.test(url);
}

export const isNotEmpty = (str: string) => {
    let pattern = /\S+/;
    return pattern.test(str);
}

export const ObjectToValueArray = (obj: any): any[] => {
    var arr = [];
    for (var i in obj) {
        var v = obj[i];
        arr.push(v);
    }
    return arr;

}

export const randomColor = () => {

    let n1 = Math.floor(Math.random() * 255);
    let h1 = n1.toString(16);
    if (h1.length < 2) { h1 = '0' + h1 }

    let n2 = Math.floor(Math.random() * 255);
    let h2 = n2.toString(16);
    if (h2.length < 2) { h2 = '0' + h2 }

    let n3 = Math.floor(Math.random() * 255);
    let h3 = n3.toString(16);
    if (h3.length < 2) { h3 = '0' + h3 }

    return '#' + h1 + h2 + h3;
}

export const randomPhoto = () => {
    const s = [
        'https://media.kohlsimg.com/is/image/kohls/Mens-dp-20220106-visnav-02?scl=1&fmt=pjpeg',
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9kd_4vnaHacSYA4Y4yiFAgM8MQRPsf3ZPWg&usqp=CAU",
        "https://www.vn.nl/wp-content/uploads/fly-images/447349/Sander_Panic-1-2-gesleept-520x520-cc.jpg",
        "https://www.vn.nl/wp-content/uploads/fly-images/452913/Mens_FrankWammes_square-520x520-cc.jpg"
    ]
    return s[Math.floor(Math.random() * s.length)];
}

/**
 * This method generates each time an unique ID
 */
export const generateUniqueId = (): string => {
    return crypto.randomUUID();
}