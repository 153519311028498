import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Rating, Tooltip, Typography } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DownloadIcon from '@mui/icons-material/Download';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { IResponseAutocomplete } from "../../../models/IUser";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import StarsIcon from '@mui/icons-material/Stars';

import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import { modifyLeftFlip, modifyRightFlip, modifyWarehousePage, modifyWarehouseRefresh, modifyWarehouseSizePage } from "../../../store/reducers/cnfSlice";
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import ActivateDetailsComponent from "../../CustomComponentModule/ActivateDetailsComponent";
import React from "react";
import ReportUserComponent from "../../ReportsModule/ReportUserComponent";
import CloseIcon from '@mui/icons-material/Close';
import { addFavoritesWarehouses } from "../../../store/reducers/favoritesSlice";
import WarehouseService from "../../../services/WarhouseService";
import { ITypeWarehouse, IWarehouseList } from "../../../models/WarehouseModels";
import GenericCRUDService from "../../../services/GenericCRUDService";
import { getDateAndTimeFormat } from "../../../helpers/dateHelper";

export default function GridCustomerComponent() {

  const { t } = useTranslation();
  const { id } = useParams();
  const { auth } = useSelector((state: RootState) => state.cnfSlice);

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { reputation, refresh, page, size, part, role } = useSelector((state: RootState) => state.cnfSlice.warehouse);
  const { hints, timeFormat, dateFormat } = useSelector((state: RootState) => state.cnfSlice.interfaceState);

  const [checkState, setCheckState] = useState(true);
  const [selectionModel, setSelectionModel] = useState<Array<string>>([]);

  const [statePdfSelect] = useState<IResponseAutocomplete[]>([])
  const [open, setOpen] = React.useState(false);

  const [rowWarehouse, setRowWarehouse] = useState<IWarehouseList[]>([]);
  const [pageSizeC, setPageSizeC] = useState(size);
  const [pageC, setPageC] = useState(page);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTtalRows] = useState(0);

  const hendlerExcel = () => {
    let criteria: any[] = [];

    criteria.push({
      property: "status",
      value: part.toUpperCase(),
      expression: "equals"
    })

    let typesValue: string = ''
    for (let r of role) {
      (typesValue)
        ? typesValue += ', ' + r.uuid
        : typesValue += r.uuid
    };

    if (typesValue) {
      criteria.push({
        property: "types.uuid",
        value: typesValue,
        expression: "INOR"
      })
    }

    if (reputation !== 'ALL') {
      criteria.push({
        property: "reputationLevel",
        value: reputation,
        expression: "EQUALS"
      })
    }

    let body = {
      criteria: criteria,
      paging: {
        page: page,
        size: size
      }
    }

    GenericCRUDService.downloadReport('warehouse/report', body)

  }

  const hendlerEmailList = () => {
    let url = 'https://mail.google.com/mail/?view=cm&fs=1';
    let to = '&to=';
    let bcc = '&bcc=';
    if (auth) {
      bcc += auth.email
    }
    for (let elm of rowWarehouse) {
      if (selectionModel.includes(elm.uuid)) {
        to += elm.email + ','
      }
    }
    window.open(url + to + bcc);
  }

  const hendlerFavoritesList = () => {
    let list: string[] = [];
    for (let elm of rowWarehouse) {
      if (selectionModel.includes(elm.uuid)) {
        list.push(elm.uuid);
      }
    }
    dispatch(addFavoritesWarehouses(list));
  }

  const handleClose = () => {
    setOpen(false);
  }

  const hendlerPdfReportList = () => {
    // let list: IResponseAutocomplete[] = [];
    // for (let elm of rowUsers) {
    //   if (selectionModel.includes(elm.uuid)) {

    //     let u: IResponseAutocomplete = {
    //       uuid: elm.uuid,
    //       firstName: elm.firstName,
    //       lastName: elm.lastName,
    //       email: elm.email,
    //       username: elm.username,
    //       avatar: elm.avatar,
    //       color: elm.color
    //     }
    //     list.push(u);
    //   }
    // }
    // setStatePdfSelect(list);
    // handleOpen();
  }

  const handlerRefresh = () => {
    dispatch(modifyWarehouseRefresh());
  }

  const handlerPage = (newPage: number) => {
    dispatch(modifyWarehousePage(newPage));
    setPageC(newPage);
  }

  const handlerPageSize = (newPageSize: number) => {
    dispatch(modifyWarehouseSizePage(newPageSize));
    setPageSizeC(newPageSize);
  }

  const handlerCheck = () => {
    setSelectionModel([]);
    setCheckState((v) => { return !v });
  }

  const handleClick = (cellValues: any) => {
    navigate('/warehouse/' + cellValues.row.id);
    dispatch(modifyRightFlip(true));
    if (!selectionModel.includes(cellValues.row.id.toString())) {
    }
  }

  const hendleShowLeft = () => {
    dispatch(modifyLeftFlip());
  }

  useEffect(() => {

    setLoading(true);

    (async () => {
      let criteria: any[] = [];

      criteria.push({
        property: "status",
        value: part.toUpperCase(),
        expression: "equals"
      })

      let typesValue: string = ''
      for (let r of role) {
        (typesValue)
          ? typesValue += ', ' + r.uuid
          : typesValue += r.uuid
      };

      if (typesValue) {
        criteria.push({
          property: "types.uuid",
          value: typesValue,
          expression: "INOR"
        })
      }

      if (reputation !== 'ALL') {
        criteria.push({
          property: "reputationLevel",
          value: reputation,
          expression: "EQUALS"
        })
      }

      let body = {
        criteria: criteria,
        paging: {
          page: page,
          size: size
        }
      }

      const [error, response] = await WarehouseService.getWarehouses(body);
      if (response) {

        let rs = response.data.body;
        setTtalRows(response.data.total);
        if (rs) {
          const st: IWarehouseList[] = rs.map((e: IWarehouseList) => (
            {
              ...e,
              id: e.uuid
            })
          )
          setRowWarehouse(st);
        }
      }
      if (error) { }
      setLoading(false);
    })()
  }, [reputation, role, part, page, size, refresh])

  useEffect(() => {
    if (id) {
      if (!selectionModel.includes(id.toString())) {
        setSelectionModel(list => [id, ...list]);
      }
    }
  }, [id])

  const columns: GridColDef[] = [
    {
      field: "2", headerName: '', width: 50, sortable: false, filterable: false, disableColumnMenu: true,
      renderCell: (cellValues: any) => {
        return (
          <>
            <div style={{ display: "flex", textAlign: "center", justifyContent: "center", padding: 0 }}>
              <IconButton
                size={'small'}
                onClick={(event: any) => { handleClick(cellValues); }}
              >
                <AutoAwesomeIcon color={"disabled"} />
              </IconButton>
              <ActivateDetailsComponent
                activate={() => handleClick(cellValues)}
              />
            </div>
          </>
        );
      }
    },
    {
      field: 'NAME', headerName: t('NAME'), width: 150, headerAlign: "center",
      renderCell: (cellValues: any) => {
        return (
          <>
            <Tooltip
              title={cellValues.row.name}

              placement={"bottom"}
              arrow={true}
            >
              <Typography variant={'body2'} fontSize={'11px'}>{cellValues.row.name} </Typography>
            </Tooltip>
          </>
        );
      }
    },
    {
      field: "CONTACT NAME", headerName: 'CONTACT NAME', sortable: false, filterable: false, disableColumnMenu: true, width: 180, headerAlign: "center",
      renderCell: (cellValues: any) => {
        return (
          <>
            <Tooltip
              title={cellValues.row.contactName}

              placement={"bottom"}
              arrow={true}
            >
              <Typography variant={'body2'} fontSize={'11px'}>{cellValues.row.contactName} </Typography>
            </Tooltip>
          </>
        );
      }
    },
    {
      field: 'EMAIL', headerName: 'EMAIL', width: 200, headerAlign: "center",
      renderCell: (cellValues: any) => {
        return (
          <>
            <Tooltip
              title={cellValues.row.email}

              placement={"bottom"}
              arrow={true}
            >
              <Typography variant={'body2'} fontSize={'11px'}>
                {cellValues.row.email}
              </Typography>
            </Tooltip>
          </>
        );
      }
    },
    {
      field: 'PHONE', headerName: 'PHONE', width: 130, headerAlign: "center",
      renderCell: (cellValues: any) => {
        return (
          <>
            <Tooltip
              title={cellValues.row.phone}

              placement={"bottom"}
              arrow={true}
            >
              <Typography variant={'body2'} fontSize={'11px'}>
                {cellValues.row.phone}
              </Typography>
            </Tooltip>
          </>
        );
      }
    },
    {
      field: 'CELL', headerName: 'CELL', width: 130, headerAlign: "center",
      renderCell: (cellValues: any) => {
        return (
          <>
            <Tooltip
              title={cellValues.row.cell}

              placement={"bottom"}
              arrow={true}
            >
              <Typography variant={'body2'} fontSize={'11px'}>
                {cellValues.row.cell}
              </Typography>
            </Tooltip>
          </>
        );
      }
    },
    {
      field: 'FAX', headerName: 'FAX', width: 130, headerAlign: "center",
      renderCell: (cellValues: any) => {
        return (
          <>
            <Tooltip
              title={cellValues.row.fax}

              placement={"bottom"}
              arrow={true}
            >
              <Typography variant={'body2'} fontSize={'11px'}>
                {cellValues.row.fax}
              </Typography>
            </Tooltip>
          </>
        );
      }
    },
    {
      field: 'ADDRESS', headerName: 'ADDRESS', width: 380, headerAlign: "center",
      renderCell: (cellValues: any) => {
        const fullAddress = cellValues.row.address ? cellValues.row.address.fullAddress : '';
        return (
          <>
            <Tooltip title={fullAddress} placement={"left"} arrow={true}>
              <Typography variant={'body2'} fontSize={'11px'}>
                {fullAddress}
              </Typography>
            </Tooltip>
          </>
        );
      }
    },
    {
      field: 'TYPES', headerName: 'TYPES', width: 220, headerAlign: "center",
      renderCell: (cellValues: any) => {
        const typesGrid = (cellValues.row.types as ITypeWarehouse[]).reduce((a, e, i: number) =>
          a.concat(' ', e.name.split('_').join(' '), (cellValues.row.types.length - 1 === i) ? '' : ' | '), '');
        return (
          <>
            <Tooltip
              title={typesGrid}
              placement={"bottom"}
              arrow={true}
            >
              <Typography variant={'body2'} fontSize={'11px'}>
                {typesGrid}
              </Typography>
            </Tooltip>
          </>
        );
      }
    },
    {
      field: 'STATUS', headerName: 'STATUS', width: 130, headerAlign: "center",
      renderCell: (cellValues: any) => {
        const statusGrid: string = (cellValues.row.status as string).split('_').join(' ');
        return (
          <>
            <Tooltip
              title={statusGrid}
              placement={"bottom"}
              arrow={true}
            >
              <Typography variant={'body2'} fontSize={'11px'}>
                {statusGrid}
              </Typography>
            </Tooltip>
          </>
        );
      }
    },
    {
      field: 'reputationLevel', headerName: 'REPUTATION', width: 120, headerAlign: "center",
      renderCell: (cellValues: any) => {
        const reputationGrid: string = (cellValues.row.reputationLevel as string).split('_').join(' ');
        return (
          <>
            <Tooltip
              title={reputationGrid}
              placement={"bottom"}
              arrow={true}
            >
              <Typography variant={'body2'} fontSize={'11px'}>
                {reputationGrid}
              </Typography>
            </Tooltip>
          </>
        );
      }
    },
    {
      field: "REVIEW", headerName: t('REVIEW'), sortable: true, width: 150, align: "center", headerAlign: "center",
      renderCell: (cellValues: any) => {
        return (
          <>
            {
              < Rating
                precision={0.5}
                readOnly
                value={cellValues.row.reviewMark}
              />
            }
          </>
        );
      }
    },
    {
      field: "created", headerName: 'Created'.toUpperCase(), sortable: true, width: 180, headerAlign: "center",
      renderCell: (cellValues: any) => {
        const date = getDateAndTimeFormat(cellValues.row.createdDate, dateFormat, timeFormat),
          author = cellValues.row.createdBy,
          tooltipValue = author + ' ' + date;
        return (
          <>
            <Tooltip title={tooltipValue} placement={"bottom"}>
              <Typography variant={'body2'} fontSize={'11px'}>
                {author} <br /> {date}
              </Typography>
            </Tooltip>
          </>
        );
      }
    },
    {
      field: "updated", headerName: 'Updated'.toUpperCase(), sortable: true, width: 180, headerAlign: "center",
      renderCell: (cellValues: any) => {
        const date = getDateAndTimeFormat(cellValues.row.updatedDate, dateFormat, timeFormat),
          author = cellValues.row.updatedBy,
          tooltipValue = author + ' ' + date;
        return (
          <>
            {cellValues.row.updatedDate &&
              <Tooltip title={tooltipValue} placement={"bottom"}>
                <Typography variant={'body2'} fontSize={'11px'}>
                  {author} <br /> {date}
                </Typography>
              </Tooltip>
            }
          </>
        );
      }
    }
  ];

  const styled = {
    container: { display: "flex", gap: "20px" },
    item: { width: "40px" },
    box: { border: "1px solid silver", textAlign: "center", borderRadius: "3px" },
    icon: { fontSize: "28px", cursor: "pointer" },
    button: { border: "1px solid silver" }
  }

  return (
    <>
      <div style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingBottom: "2px",
        height: "46px"
      }}>
        <div>

          <div style={{ width: "400px", paddingLeft: "7px", display: "flex", gap: "10px" }}>
            <Tooltip
              title={(hints) ? t("Show/Hide the selection column") : ''}
              placement={"top"}
              arrow={true}
            >
              <IconButton onClick={handlerCheck} size='small'>
                <PlaylistAddCheckCircleIcon style={styled.icon} />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={(hints) ? t("Refresh") : ''}
              placement={"top"}
              arrow={true}
            >
              <IconButton onClick={handlerRefresh} size='small'>
                <ReplayCircleFilledIcon style={styled.icon} />
              </IconButton>
            </Tooltip>
            {!(rowWarehouse.length < 1) && <Tooltip
              title={(hints) ? t("Download XLS report for warehouse") : ''}
              placement={"top"}
              arrow={true}
              onClick={hendlerExcel}
              key={"key"}
            >
              <IconButton>
                <DownloadIcon style={styled.icon} />
              </IconButton>
            </Tooltip>}
            {(selectionModel.length > 0) &&
              <div>
                <Tooltip
                  title={(hints) ? t("Generate PDF report for selected warehouse") : ''}
                  placement={"top"}
                  arrow={true}
                >
                  <IconButton disabled onClick={hendlerPdfReportList}>
                    <PictureAsPdfIcon style={styled.icon} color={"primary"} />
                  </IconButton>
                </Tooltip>

                <Tooltip
                  title={(hints) ? t("Send email to selected warehouse") : ''}
                  placement={"top"}
                  arrow={true}
                >
                  <IconButton
                    onClick={hendlerEmailList}
                  >
                    <MarkEmailReadIcon style={styled.icon} color={"primary"} />
                  </IconButton>
                </Tooltip>

                <Tooltip
                  title={(hints) ? t("Add selected warehouse(s) to favorites") : ''}
                  placement={"top"}
                  arrow={true}
                >
                  <IconButton
                    onClick={hendlerFavoritesList}
                  >
                    <StarsIcon style={styled.icon} color={"primary"} />
                  </IconButton>
                </Tooltip>

              </div>
            }
          </div>
        </div>
        <div>
          {(id) &&
            <Tooltip
              title={(hints) ? t("warehouse") : ''}
              placement={"top"}
              arrow={true}
            >
              <IconButton onClick={hendleShowLeft} >
                <MultipleStopIcon style={styled.icon} color={"primary"} />
              </IconButton>
            </Tooltip>
          }
        </div>
      </div>

      <div style={{ height: "92%" }}>
        <DataGrid
          density={'compact'}
          paginationMode="server"
          loading={loading}
          rows={rowWarehouse}
          columns={columns}

          rowCount={totalRows}
          page={pageC}
          pageSize={pageSizeC}

          rowsPerPageOptions={[5, 10, 15, 20, 25]}
          onPageSizeChange={handlerPageSize}
          onPageChange={handlerPage}

          checkboxSelection={checkState}
          onSelectionModelChange={(newSelectionModel: any) => {
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
        />
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={'lg'}
      >
        <DialogTitle>{`Pdf Report`}</DialogTitle>
        <DialogContent>
          <ReportUserComponent user={statePdfSelect} />
        </DialogContent>
        <DialogActions>
          <div style={{ display: "flex", justifyContent: "right", width: "100%", padding: "20px" }}>
            <Button
              startIcon={<CloseIcon />}
              color={'inherit'}
              variant={'contained'}
              size={'small'}
              onClick={handleClose}>Close</Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}