import { Suspense, useEffect, useState } from "react";
import { Box, CircularProgress, Paper, Step, StepButton, StepContent, Stepper, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import StafService from "../../services/StafService";
import { IDocumentResponseDto, IUserInfoResponseDto, IUserMetadata, UserContactsResponseDto, UserRegistrationResponseDto, UserStatusResponseDto } from "../../models/IUser";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IStatus, TRoles } from "../../models/IStaf";
import { AppDispatch, RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import CoverFaceUserComponent from "./CoverFaceUserComponent";
import { modifyRightFlip, modifyStafRefresh, modifyUserStep, setLastError } from "../../store/reducers/cnfSlice";
import EditStatusUserComponent from "./EditStatusUserComponent";
import EditPersonalInfoUserComponent from "./EditPersonalInfoUserComponent";
import ContactsUserComponent from "./ContactsUserComponent";
import GridViewIcon from '@mui/icons-material/GridView';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import EmployeeUserComponent from "./EmployeeUserComponent";
import { EButtonColor } from "../../models/EnumGeneral";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DocumentsUserComponent from "./DocumentsUserComponent";
import SecureDialogComponent from "../CustomComponentModule/SecureDialogComponent";
import EditRoleUserComponent from "./EditRoleUserComponent";
import VeiwTableReportComponent from "./VeiwTableReportComponent";
import { dateToYMD } from "../../helpers/dateHelper";
import { EmployeeResponseDto, IncomeResponseDto } from "../../models/EmployeeModels";
import { realStringInfoCoverFace } from "../../helpers/textHelper";

interface IProps {
  allRole: TRoles,
  countPar: IStatus
  snackMsg: (text: string, loc: string) => void;
}

interface IStateEmployee {
  uuid: string;
  startDate: string;
  endDate: string,
  description: string,
  numberOfHoursPerDay: number,
  incomes: IncomeResponseDto[] | null
}
export default function UserEditComponent(props: IProps) {

  const { step, refresh } = useSelector((state: RootState) => state.cnfSlice.staf);
  const [activeStep, setActiveStep] = useState(step);
  const [editMode, setEditMode] = useState('edit');
  const { id } = useParams();

  const { rightFlip } = useSelector((state: RootState) => state.cnfSlice.interfaceState);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation().pathname;

  const [notificationRedirect, setNotificationRedirect] = useState<boolean>(false)

  const [userStatus, setUserStatus] = useState<UserStatusResponseDto | null>();
  const [userMetadata, setUserMetadata] = useState<IUserMetadata | null>();
  const [user, setUser] = useState<IUserInfoResponseDto | null>(null);
  const [userContact, setUserContact] = useState<UserContactsResponseDto[]>()
  const [userRegisterData, setUserRegisterData] = useState<UserRegistrationResponseDto | null>();
  const [documents, setDocuments] = useState<IDocumentResponseDto[] | null>()
  const [employee, setEmployee] = useState<IStateEmployee | null>(null)

  const [maxStep, setMaxStep] = useState(0);

  const refreshAuthorityStep = () => {
    (async () => {
      if (id) {
        const [, response] = await StafService.getUserDetails(id?.toString());
        if (response) {
          setUser(response);
          setUserMetadata(response.metadata)
          dispatch(modifyUserStep(response.metadata.step))
          dispatch(modifyRightFlip(true));
        }
      }
    })()
  }
  const refreshRegisterDataStep = () => {
    (async () => {
      if (id) {
        const [err, res] = await StafService.getRegistrationData(id?.toString());
        let dt = res.data.body;
        if (dt) {
          setUserRegisterData(dt);
        }
        if (err) { }
        const [, response] = await StafService.getUserMetadata(id?.toString());
        let data = response.data.body;
        if (data) {
          if (userMetadata && userMetadata?.completed !== data.completed) {
            dispatch(modifyStafRefresh());
          }
          setUserMetadata(data);
        }
      }
    })()
  }

  const SnackMess = (text: string) => {
    props.snackMsg(text, location + "?" + "step=" + activeStep.toString());
  }

  const refreshContactsStep = () => {
    (async () => {
      if (id) {
        const [, rs] = await StafService.getUserContact(id?.toString());
        let dataS = rs.data.body;
        if (dataS) {
          setUserContact(dataS);
        }
        const [, response] = await StafService.getUserMetadata(id?.toString());
        let data = response.data.body;
        if (data) {
          if (userMetadata && userMetadata?.completed !== data.completed) {
            dispatch(modifyStafRefresh());
          }
          setUserMetadata(data);
        }
      }
    })()
  }

  const refreshEmployeeStep = () => {
    (async () => {
      if (id && user) {

        const [error, res] = await StafService.getEmployeeDetails(user.uuid);
        if (res) {
          let ras: EmployeeResponseDto = res;
          let st: IStateEmployee = {
            uuid: ras.uuid,
            startDate: dateToYMD(ras.startDate),
            endDate: (ras.endDate !== 0) ? dateToYMD(ras.endDate) : '',
            description: ras.description === "N/A" ? "<p>N/A</p>" : ras.description,
            numberOfHoursPerDay: ras.numberOfHoursPerDay,
            incomes: ras.incomes
          };
          setEmployee(st);
        }
        if (error) {
          dispatch(setLastError(error));
        }

        const [err, response] = await StafService.getUserMetadata(id?.toString());
        let data = response.data.body;
        if (data) {
          if (userMetadata && userMetadata?.completed !== data.completed) {
            dispatch(modifyStafRefresh());
          }
          setUserMetadata(data);
        }
      }
    })()
  }

  const refreshDocumentStep = () => {
    (async () => {
      if (id) {
        const [, res] = await StafService.getDocuments(id?.toString());
        if (res && res.data.body) {
          setDocuments(res.data.body);
        }

        const [, response] = await StafService.getUserMetadata(id?.toString());
        let data = response.data.body;
        if (data) {
          if (userMetadata && userMetadata?.completed !== data.completed) {
            dispatch(modifyStafRefresh());
          }
          setUserMetadata(data);
        }
      }
    })()
  }

  const refreshStatusStep = () => {
    (async () => {
      if (id) {
        const [errorS, responseS] = await StafService.getUserStatus(id?.toString());
        let dataS = responseS.data.body;
        if (dataS) {
          setUserStatus(dataS);
        }
        if (errorS) { }
        const [, response] = await StafService.getUserMetadata(id?.toString());
        let data = response.data.body;
        if (data) {
          if (userMetadata && userMetadata?.completed !== data.completed) {
            dispatch(modifyStafRefresh());
          }
          setUserMetadata(data);
        }
      }
    })()
  }

  const handleEditMode = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string | null,
  ) => {
    if (newValue) {
      setEditMode(newValue);
    }
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const hendlerDeleteUser = () => {
    if (user) {
      (async () => {
        const [error, response] = await StafService.deleteUser(user?.uuid);
        if (response) {
          dispatch(modifyRightFlip(false));
          navigate('/user');
          let snackMessText = ((realStringInfoCoverFace(user?.firstName) === '') || (realStringInfoCoverFace(user?.firstName) === ''))
            ? `${user.email}`
            : `${user.firstName} ${user.lastName}`
          SnackMess('User ' + snackMessText + ' was successfully removed.');
          dispatch(modifyStafRefresh());
        }
        if (error) { }
      })()
    }
  }

  const hendlerToEdit = (step: number) => {
    setActiveStep(step);
    setEditMode('edit');
  }

  useEffect(() => {
    (async () => {
      if (id) {
        setUserContact([]);
        setUserRegisterData(null);
        setDocuments([]);
        const [, response] = await StafService.getUserDetails(id?.toString());
        if (response) {
          setUser(response);
          setUserMetadata(response.metadata)
          setUserStatus({ uuid: response.uuid, status: response.status, activeFromDate: response.activeFromDate, activeToDate: response.activeToDate })
          dispatch(modifyUserStep(response.metadata.step))
          dispatch(modifyRightFlip(true));
          (response.metadata.completed)
            ? setActiveStep(0)
            : setMaxStep(response.metadata.step - 1)
        }
      }
    })()
  }, [id]);

  useEffect(() => {
    if (id) {
      if (maxStep < activeStep) {
        setMaxStep(activeStep)
      }
      (async () => {
        if (activeStep === 1 && !userRegisterData) {
          const [, res] = await StafService.getRegistrationData(id?.toString());
          let dt = res.data.body;
          if (dt) {
            setUserRegisterData(dt);
          }
        }

        if (activeStep === 2 && userContact && userContact?.length < 1) {
          const [error, response] = await StafService.getUserContact(id?.toString());
          let dataS = response.data.body;
          if (dataS) {
            setUserContact(dataS);
          }
        }
        if (user && activeStep === 3 && !employee) {

          const [error, data] = await StafService.getEmployeeDetails(user.uuid);
          if (data) {
            let ras: EmployeeResponseDto = data;
            let st: IStateEmployee = {
              uuid: ras.uuid,
              startDate: dateToYMD(ras.startDate),
              endDate: (ras.endDate !== 0) ? dateToYMD(ras.endDate) : '',
              description: ras.description === "N/A" ? "<p>N/A</p>" : ras.description,
              numberOfHoursPerDay: ras.numberOfHoursPerDay,
              incomes: ras.incomes
            };
            setEmployee(st);
          }
          if (error) {
            dispatch(setLastError(error));
          }
        }

        if (activeStep === 4 && documents && documents?.length < 1) {
          const [, res] = await StafService.getDocuments(id?.toString());
          if (res) {
            if (res.data.body) {
              setDocuments(res.data.body);
            }
          }
        }
        if (activeStep === 5) {
          const [errorS, responseS] = await StafService.getUserStatus(id?.toString());
          let dataS = responseS.data.body;
          if (dataS) {
            setUserStatus(dataS);
          }
          if (errorS) { }
        }

      })()
    }
  }, [id, activeStep, refresh])

  useEffect(() => {
    if (!rightFlip) {
    }
  }, [rightFlip])

  useEffect(() => {
    if (!notificationRedirect) {
      if (!userMetadata?.completed) {
        if (!user?.role) { setActiveStep(Number(0)) }
        else { setActiveStep(Number(userMetadata?.step) - 1) }
      }
    } else {
      setActiveStep(step);
    }
  }, [notificationRedirect, userMetadata])
  return (
    <>
      <div>
        <Paper>
          <CoverFaceUserComponent userContact={userContact ? userContact : []} user={user} userMetadata={userMetadata ? userMetadata : null} />
        </Paper>
        <div style={{ display: "flex" }}>
        </div>
      </div>

      {editMode === 'edit' &&
        <div>
          <div style={{ marginTop: "20px", marginLeft: "35px" }}>
            <Stepper nonLinear activeStep={activeStep} orientation="vertical">
              {/* -------------------- */}
              {/* {user?.role && */}
              <Step completed={false}>
                <StepButton color="inherit" onClick={handleStep(0)}>
                  {'AUTHORITY'.toUpperCase()}
                </StepButton>
                <StepContent>
                  <div>
                    <Suspense fallback={<CircularProgress />}>
                      <Paper sx={{ marginTop: "10px" }}>
                        <Box sx={{ padding: 3, paddingRight: 0, marginBottom: "10px", marginTop: "1", display: "flex" }}>
                          <EditRoleUserComponent
                            userMetadata={userMetadata ? userMetadata : null}
                            userStatus={userStatus ? userStatus : null}
                            handleStep={(e) => {
                              setActiveStep(e)
                              dispatch(modifyUserStep(e))
                            }}
                            refreshAfretSave={refreshAuthorityStep}
                            snackMsg={SnackMess}
                            allRole={props.allRole}
                            user={user ? user : null} />
                        </Box>
                      </Paper>
                    </Suspense>
                  </div>
                </StepContent>
              </Step>

              {
                <Step completed={false} disabled={(userMetadata?.completed) ? false : (maxStep >= 1) ? false : true}>
                  <StepButton color="inherit" onClick={handleStep(1)}>
                    {'PERSONAL DATA'.toUpperCase()}
                  </StepButton>
                  <StepContent>
                    <div>
                      <Suspense fallback={<CircularProgress />}>
                        <Paper sx={{ marginTop: "10px" }}>
                          <Box sx={{ padding: 3, paddingRight: 0, marginBottom: "10px", marginTop: "1", display: "flex" }}>
                            <div>
                              <EditPersonalInfoUserComponent
                                userMetadata={userMetadata ? userMetadata : null}
                                userRegisterData={userRegisterData ? userRegisterData : null}
                                handleStep={(e) => {
                                  setActiveStep(e)
                                  dispatch(modifyUserStep(e))
                                }}
                                snackMsg={SnackMess}
                                refreshAfretSave={refreshRegisterDataStep}
                                user={user ? user : null}
                              ></EditPersonalInfoUserComponent>
                            </div>
                          </Box>
                        </Paper>
                      </Suspense>
                    </div>
                  </StepContent>
                </Step>}
              {/* -------------------- */}
              {
                <Step completed={false} disabled={(userMetadata?.completed) ? false : (maxStep >= 2) ? false : true}>
                  <StepButton color="inherit" onClick={handleStep(2)}>
                    {'CONTACTS'.toUpperCase()}
                  </StepButton>
                  <StepContent>
                    <div>
                      <Suspense fallback={<CircularProgress />}>
                        <Paper sx={{ marginTop: "10px" }}>
                          <Box sx={{ padding: 3, paddingRight: 0, marginBottom: "10px", marginTop: "1", display: "flex" }}>
                            <div>
                              <ContactsUserComponent
                                userMetadata={userMetadata ? userMetadata : null}
                                userContact={userContact ? userContact : []}
                                handleStep={(e) => {
                                  setActiveStep(e)
                                  dispatch(modifyUserStep(e))
                                }}
                                user={user ? user : null}
                                snackMsg={SnackMess}
                                refreshAfretSave={refreshContactsStep}
                              />
                            </div>
                          </Box>
                        </Paper>
                      </Suspense>
                    </div>
                  </StepContent>
                </Step>}
              {/* -------------------- */}
              {
                <Step completed={false} disabled={(userMetadata?.completed) ? false : (maxStep >= 3) ? false : true}>
                  <StepButton color="inherit" onClick={handleStep(3)}>
                    {'EMPLOYEE'.toUpperCase()}
                  </StepButton>
                  <StepContent>
                    <Paper sx={{ marginTop: "10px", paddingBottom: "30px" }}>
                      <Box sx={{ padding: 3, paddingRight: 0, marginBottom: "10px", marginTop: "1", display: "flex" }}>
                        <div>
                          <EmployeeUserComponent
                            userMetadata={userMetadata ? userMetadata : null}
                            employee={employee ? employee : null}
                            handleStep={(e) => {
                              setActiveStep(e)
                              dispatch(modifyUserStep(e))
                            }}
                            user={user ? user : null}
                            snackMsg={SnackMess}
                            refreshAfretSave={refreshEmployeeStep}
                          />
                        </div>
                      </Box>
                    </Paper>
                  </StepContent>
                </Step>}
              {/* -------------------- */}
              {
                <Step completed={false} disabled={(userMetadata?.completed) ? false : (maxStep >= 4) ? false : true}>
                  <StepButton color="inherit" onClick={handleStep(4)}>
                    {'DOCUMENTS'.toUpperCase()}
                  </StepButton>
                  <StepContent>

                    <Paper sx={{ marginTop: "10px", paddingBottom: "30px" }}>
                      <Box sx={{ padding: 3, paddingRight: 0, marginBottom: "10px", marginTop: "1", display: "flex" }}>
                        <div>
                          <DocumentsUserComponent
                            userMetadata={userMetadata ? userMetadata : null}
                            documents={documents ? documents : null}
                            handleStep={(e) => {
                              setActiveStep(e)
                              dispatch(modifyUserStep(e))
                            }}
                            user={user ? user : null}
                            snackMsg={SnackMess}
                            refreshAfretSave={refreshDocumentStep}
                          />
                        </div>
                      </Box>
                    </Paper>

                  </StepContent>
                </Step>}


              <Step completed={false} disabled={!userMetadata?.completed}>
                <StepButton color="inherit" onClick={handleStep(5)}>
                  {'STATUS'.toUpperCase()}
                </StepButton>
                <StepContent>

                  <Paper sx={{ marginTop: "10px" }}>
                    <Box sx={{ padding: 3, display: "flex" }}>
                      <div>
                        <EditStatusUserComponent
                          userMetadata={userMetadata ? userMetadata : null}
                          userStatus={userStatus ? userStatus : null}
                          refreshAfretSave={refreshStatusStep}
                          snackMsg={SnackMess}
                          allRole={props.allRole}
                          user={user ? user : null} />
                      </div>
                    </Box>
                  </Paper>

                </StepContent>
              </Step>



            </Stepper>
          </div>
        </div>}

      {editMode === 'view' && user && <VeiwTableReportComponent userMetadata={userMetadata ? userMetadata : null} toStep={hendlerToEdit} uuid={user.uuid} />}

      <Paper sx={{ padding: "15px", marginTop: "20px", display: "flex", gap: "10px", justifyContent: "space-between" }} >
        <Box sx={{ display: "flex", gap: "10px" }}>
          <ToggleButtonGroup
            size={'small'}
            value={editMode}
            exclusive
            onChange={handleEditMode}
            aria-label=""
          >
            <ToggleButton value="edit" aria-label="">
              <AutoAwesomeIcon fontSize={'small'} color={editMode === "edit" ? 'warning' : 'inherit'} />
            </ToggleButton>
            <ToggleButton value="view" aria-label="">
              <GridViewIcon fontSize={'small'} color={editMode === "view" ? 'warning' : 'inherit'} />
            </ToggleButton >
          </ToggleButtonGroup>


        </Box>

        <Box sx={{ display: "flex", gap: "10px" }}>


          <Tooltip
            title="Remove user"
            arrow={true}
            placement="top"
          ><Box>
              <SecureDialogComponent
                answerYES={hendlerDeleteUser}
                text={''}
                icon={<DeleteForeverIcon fontSize='medium' sx={{ marginRight: "5px", marginLeft: "5px" }} />}
                color={EButtonColor.error}
                iconButton={false}
                disabled={user?.readonly}
              />
            </Box>

          </Tooltip>
        </Box>
      </Paper>
    </>
  );
}