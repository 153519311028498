import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMapState, IWayInfoInMap, IWayPath, TWayInfo } from "../../models/MapModels";

interface MapState {
    jsMapIsLoaded: boolean,
    ways: Array<IWayPath>
    startsMap: Array<string>,
    filtersMap: Array<string>,
    mapState: IMapState,
    wayInfo: TWayInfo,
    idSelected: string,
    center: {
        lat: number
        lng: number
    },
    zoom: number

}

const initialState: MapState = {
    jsMapIsLoaded: false,
    mapState: {
        drawPaths: false,
        setMarks: false,
        viewDrawPaths: 'all',
        viewSetMarks: 'all'
    },
    ways: [],
    wayInfo: [],
    startsMap: [],
    filtersMap: [],
    idSelected: '0',
    center: {
        lat: 45.07628287127715,
        lng: -93.24481908541335
    },
    zoom: 10

}

export const mapSlice = createSlice({
    name: 'map',
    initialState,
    reducers: {
        modifyStateJSLoaded(state, action: PayloadAction<boolean>) {
            state.jsMapIsLoaded = action.payload;
        },
        modifyStateWays(state, action: PayloadAction<IWayPath>) {
            state.ways.push(action.payload);
        },
        setMapStars(state, action: PayloadAction<string>) {
            let a = [...state.startsMap];
            let idx = a.indexOf(action.payload.toString());
            if (idx >= 0) { a.splice(idx, 1); } else { a.push(action.payload.toString()) }
            state.startsMap = a;
        },
        modifyMapState(state, action: PayloadAction<IMapState>) {
            state.mapState = action.payload;
        },
        setWayInfo(state, action: PayloadAction<Array<IWayInfoInMap>>) {
            state.wayInfo = action.payload;
        },
        setIdSelected(state, action: PayloadAction<string>) {
            if (state.idSelected === action.payload) {
                state.idSelected = '0';
            } else {
                state.idSelected = action.payload;
            }
        },
        setFiltersWays(state, action: PayloadAction<Array<string>>) {
            state.filtersMap = action.payload
        },

        clearStars(state) { state.startsMap = [] },
        clearFilters(state) { state.filtersMap = [] },
        setCenter(state, actions: PayloadAction<any>) {
            state.center = actions.payload;
        }
    }
})

export const {
    modifyStateJSLoaded,
    modifyStateWays,
    setMapStars,
    modifyMapState,
    setWayInfo,
    setIdSelected,
    clearStars,
    clearFilters,
    setFiltersWays,
    setCenter
} = mapSlice.actions;

export default mapSlice.reducer;