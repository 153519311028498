import { useEffect, useState } from "react";
import ControlStateInCRUDComponent from "../CustomComponentModule/ControlStateInCRUDComponent";
import { useDebounce } from "../../hooks/useDebounce";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import { TextField } from "@mui/material";
import { modifyCustomerRefresh, setLastError } from "../../store/reducers/cnfSlice";
import { ICustomerDetails } from "../../models/CustomerModels";
import CustomerService from "../../services/CustomerService";
import { isValidEmail, isValidPhone } from "../../helpers/generalHelper";

interface IContactsCustomerStepState {
    uuid: string,
    email: string,
    phone: string
}

interface IProps {
    customer: ICustomerDetails | null;
    refreshAfretSave: () => void;
    snackMsg: (text: string) => void;
    handleStep: (step: number) => void;
}

const ContactsCustomerComponent = (props: IProps) => {

    const { customer } = props
    const [state, setState] = useState<IContactsCustomerStepState>({
        uuid: '',
        email: '',
        phone: ''
    })

    const [save, setSave] = useState<boolean>(false);
    const [isValidName, setIsValidName] = useState<boolean>(true);
    // const debouncedName = useDebounce(state.name, 300);
    const dispatch = useDispatch<AppDispatch>();
    const [snapshot, setSnapshot] = useState<string>("");
    const [error, setError] = useState<number>(-1);
    const [textErrors, setTextErrors] = useState<Array<string>>([])
    const [contolStateCustomer, setContolStateCustomer] = useState(false);

    const handlerSaveUser = () => { setSave(true); }
    const handlerReset = () => {
        let st = JSON.parse(snapshot);
        setState(st);
    }  

    useEffect(() => {
        if (save && customer) {
            (async () => {
                const {...body } = state;
                const [error, response] = await CustomerService.saveContacts(body)
                if (response) {
                    setSnapshot(JSON.stringify({ ...state }));
                    setContolStateCustomer(true); 
                }
                if (error) { dispatch(setLastError(error)) }
            })()
        }
        setSave(false);
    }, [save])

    useEffect(() => {
        if (contolStateCustomer) {
            if (customer) {
                (async () => {
                    const [error, response] = await CustomerService.getMetadata(customer.uuid)
                    if (response) {
 
                        props.refreshAfretSave();
                        props.snackMsg('Contact data was successfully saved for ' + customer.name + '.');
                        dispatch(modifyCustomerRefresh());
                        const { step, completed } = response.data.body;

                        if (completed && !customer.metadata.completed && step !== 5) {
                           props.handleStep(5);
                        }
                        if (!completed && !customer.metadata.completed && step !== 1) {
                            let bd = { uuid: customer.uuid, step: 2 }
                            const [er, rs] = await CustomerService.saveCustomerStep(bd);
                            if (er) { dispatch(setLastError(error)) }
                        }
                    }
                    if (error) { dispatch(setLastError(error)) }
                })()
            }
        }
        setContolStateCustomer(false);
    }, [contolStateCustomer])

    useEffect(() => {
        if (customer) {
            (async () => {
                const [error, response] = await CustomerService.getContactsContactStep(customer.uuid);
                if (response) {
                    const value = response.data.body as IContactsCustomerStepState;
                    if (value.email === 'N/A') {
                        value.email = ''
                    }
                    if (value.phone === 'N/A') {
                        value.phone = ''
                    }
                    setState({ ...value })
                    setSnapshot(JSON.stringify({ ...value }));
                }
                if (error) {
                    dispatch(setLastError(error))
                }
            })()
        }
    }, [customer])

    useEffect(() => {
        let er: boolean = false;
        let ter: string[] = [];


        if ((state.email.length <= 3)) {
            er = true;
            ter.push("* eMail is requared field")
        } else {
            if (!isValidEmail(state.email)) {
                er = true;
                ter.push("* eMail is not valid")
            }
        }

        if ((state.phone.length <= 3)) {
            er = true;
            ter.push("* eMail is requared field")
        } else {
            if (!isValidPhone(state.phone)) {
                er = true;
                ter.push("* eMail is not valid")
            }
        }

        if (customer && !er) {
            if (snapshot === JSON.stringify(state)) {
                setError(0)
            } else {
                setError(1)
            }
        } else {
            setError(-1)
        }

        setTextErrors(ter);
    }, [state, snapshot])

    const handlerChangeEmail = (e: any) => {
        let value: string = (e.target.value as string);
        setState(st => ({ ...st, email: value }))
    }

    const handlerChangePhone = (e: any) => {
        let value: string = (e.target.value as string);
        setState(st => ({ ...st, phone: value }))
    }

    return (
        <>

            <div style={{ flex: "1 1 auto", display: "flex", gap: 10, width: "620px", }}>
                <div style={{ display: "flex", flexDirection: "column", gap: "10px", width: "535px", marginRight: "18px" }}>

                    <div style={{ display: "flex", flexDirection: "row", marginBottom: "20px" }}>
                        <TextField
                            autoComplete={'off'}
                            error={state.email.length <= 3 || !isValidEmail(state.email)}
                            value={state.email}
                            label="Email"
                            onChange={handlerChangeEmail}
                            style={{ width: "540px" }}
                            inputProps={{ sx: { fontSize: "16px" } }}
                            InputLabelProps={{ sx: { fontSize: "17px" } }}
                            helperText={!isValidName ? '* this ID exists' : ''}
                        />




                    </div>
                    <div style={{ width: "720px", display: "flex", flexDirection: "row", gap: "5px", marginBottom: "5px" }}>
                        <TextField
                            autoComplete={'off'}
                            error={state.phone.length <= 3 || !isValidPhone(state.phone)}
                            value={state.phone}
                            label="Phone"
                            onChange={handlerChangePhone}
                            style={{ width: "540px" }}
                            inputProps={{ sx: { fontSize: "16px" } }}
                            InputLabelProps={{ sx: { fontSize: "17px" } }}
                            helperText={!isValidName ? '* this ID exists' : ''}
                        />

                    </div>

                    <div style={{ display: "flex" }}>
                        {/* <pre style={{ maxWidth: "300px", overflow: "hidden" }}>
                            {JSON.stringify(state, undefined, 2)}
                        </pre> */}
                    </div>
                </div>



                <div style={{ display: "flex", minWidth: "53px" }} >
                    <ControlStateInCRUDComponent
                        error={error}
                        textErrors={textErrors}
                        handlerReset={handlerReset}
                        handlerSave={handlerSaveUser}
                    />
                </div>
            </div>


        </>
    )
}
export default ContactsCustomerComponent;