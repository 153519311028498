export interface LabelType {
    name: string;
    key: string;
    description?: string;
}

export interface ITimes {
    t1: number, v1: string,
    t2: number, v2: string
}

export interface IAuditFilter {
    times: ITimes;
    v1: string[];
    v2: string[];
    v3: string[];
}

export interface valueState {
    filter: IAuditFilter,
    valueFilter: LabelType[][]
}

export interface IAuditUserResponseDTO {
    firstName?: string,
    lastName?: string,
    email: string
}

export interface IFavoritesAudit {
    uuid?: string,
    name: string,
    value: string
}


export interface IAuditResponseDTO {
    id?: string;
    action: EAuditAction,
    module: EAuditModule,
    deviceType: string,
    date: number,
    value: string,
    oldValue: string,
    newValue: string,
    user: IAuditUserResponseDTO,
    businessItemId?: string,
    businessItemValue: string
}

export enum EAuditAction {
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE',
    NAVIGATE = 'NAVIGATE',
    LOGIN = 'LOGIN',
    LOGOUT = 'LOGOUT',
    LINK = 'LINK',
    UNLINK = 'UNLINK'
}

export enum EAuditModule {
    USER = 'USER',
    GLOBAL_PREFERENCE = 'GLOBAL PREFERENCE',
    AUTH = 'AUTHENTICATION',
    MOTTO = 'MOTTO',
    PRIVILEGE = 'PRIVILEGE',
    ROLE_PRIVILEGE = 'ROLE PRIVILEGE',
    ROUTE = 'ROUTE',
    PUBLIC_ATTACHMENT = 'PUBLIC ATTACHMENT',
    BRAND = 'BRAND',
    PALLET_TYPE = 'PALLET TYPE',
    FRAGILITY_TYPE = 'FRAGILITY TYPE',
    VEHICLE = 'VEHICLE',
    TRANSPORT = 'TRANSPORT',
    CUSTOMER = 'CUSTOMER',
    LOAD = 'LOAD',
    LOAD_ITEM = 'LOAD ITEM',
    LOAD_ITEM_DELIVERY = 'LOAD ITEM DELIVERY',
    WAREHOUSE = 'WAREHOUSE'
}