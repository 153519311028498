import { Autocomplete, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ICustomerShortResponseDto } from "../../models/CustomerModels";
import CustomerService from "../../services/CustomerService";
import SCViewList from "../../styledComponents/sharedComponent/SCViewList";
import { useTranslation } from "react-i18next";

interface IProps {
    customerId: String | null | undefined,
    onChange: any
}

const CustomerAutocompleteComponent = (props: IProps) => {

    const { t } = useTranslation();
    const [customers, setCustomers] = useState<ICustomerShortResponseDto[]>([]);

    useEffect(() => {
        (async () => {
            const [, response] = await CustomerService.getPendingCustomers();
            if (response) {
                const data: Array<ICustomerShortResponseDto> = response.data.body;
                setCustomers(data);
            }
        })();
    }, []);

    const getOptionLabel = (record: ICustomerShortResponseDto): string => {
        return record.name;
    }

    return (
        <>
            <Autocomplete
                value={props.customerId ? customers.find(item => item.uuid === props.customerId) || null : null}
                onChange={props.onChange}
                options={customers}
                getOptionLabel={getOptionLabel}
                renderOption={(props, record) =>
                    <li {...props}>
                        <SCViewList column gap={3}>
                            <Typography>{record.name}</Typography>
                            <Typography sx={{ minWidth: "120px", maxWidth: "120px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} color={'secondary'}>
                                {record.idno}
                            </Typography>
                        </SCViewList>
                    </li>}
                fullWidth
                renderInput={(params) => (
                    <TextField {...params} label={t('Customer')} />
                )}
            />
        </>
    )
}
export default CustomerAutocompleteComponent;