import { useEffect, useState } from 'react'
import { Box, useTheme } from "@mui/material";

interface IProps {
      width: number,
      heigth: number,
      color?: string
}

const BLViewComponent = (props: IProps) => {
      const [, setStyleColor] = useState({})

      const theme = useTheme();

      useEffect(() => {
            const style_dark = {
                  fill: "white"
            }

            const style_ligth = {
                  fill: "black"
            }

            let color: any = ((theme.palette.mode === "light")) ? style_ligth : style_dark;
            if (props.color === 'white') color = style_dark;
            if (props.color === 'black') color = style_ligth;
            setStyleColor(color);
      }, [theme, props])


      return (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                        viewBox="0 0 400 400"
                        style={{ width: props.width, height: props.heigth, fill: "red", overflow: "hidden" }}
                  >

                        <path d="M112.782,205.804c10.644,7.166,23.449,11.355,37.218,11.355c36.837,0,66.808-29.971,66.808-66.808
				c0-13.769-4.189-26.574-11.355-37.218L112.782,205.804z"/>
                        <path d="M150,83.542c-36.839,0-66.808,29.969-66.808,66.808c0,15.595,5.384,29.946,14.374,41.326l93.758-93.758
				C179.946,88.926,165.595,83.542,150,83.542z"/>
                        <path d="M149.997,0C67.158,0,0.003,67.161,0.003,149.997S67.158,300,149.997,300s150-67.163,150-150.003S232.837,0,149.997,0z
				 M150,237.907c-48.28,0-87.557-39.28-87.557-87.557c0-48.28,39.277-87.557,87.557-87.557c48.277,0,87.557,39.277,87.557,87.557
				C237.557,198.627,198.277,237.907,150,237.907z"/>

                  </svg>
            </Box>
      )
}

export default BLViewComponent;