import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { modifyChatMessageReadRefresh, modifyChatRefresh, modifyGlobalPreference, modifyNotificationState, modifyNotificationStateCount, modifyChatLastMessage } from "../../store/reducers/cnfSlice";
import { AppDispatch } from "../../store/store";
import GeneralService from "../../services/GeneralService";
import { EChannelsType, ECommandType, ESocketType } from "../../models/SocketModels";
import { IConversationMessages, ISocketConversationMessage } from "../../models/PersonalChatModels";

interface IProps {
    socketCommand: ECommandType | null;
    socketChannels: EChannelsType | null
}

const ProcessingSocketCommandsComponent = (props: IProps) => {
    const { socketCommand, socketChannels } = props
    const dispatch = useDispatch<AppDispatch>()


    const handleNotification = () => {
        dispatch(modifyNotificationState(true))
        dispatch(modifyNotificationStateCount(true));
    }

    const handleGlobalPreference = (async () => {
        const [error, response] = await GeneralService.getUserProfile();
        if (response) {
            let res = response.data.body.userPreference;
            const { webAppPreferences, ...rest } = res;
            dispatch(modifyGlobalPreference(rest))

        }
    })

    const handleReadChat = () => {
        if (socketCommand?.entityId) {
            dispatch(modifyChatMessageReadRefresh(socketCommand?.entityId))
        }
    }

    const handlePersonalChatRefresh = () => {
        if (socketCommand?.entityId) {
            dispatch(modifyChatRefresh(socketCommand?.entityId));
        }
    }

    const handlePersonalChatSetLastMessage = (s: object) => {
        let lastMessage = s as ISocketConversationMessage
        dispatch(modifyChatLastMessage(lastMessage));
    }


    useEffect(() => {
        (async () => {
            if (socketCommand) {
                switch (socketCommand.command) {
                    case 'NOTIFICATION_REFRESH': return handleNotification();
                    case 'GLOBAL_PREFERENCE_UPDATE': return await handleGlobalPreference();
                    case 'PERSONAL_CHAT_MESSAGE_READ': return handleReadChat();
                    case 'PERSONAL_CHAT_MESSAGE_RECEIVED': return handlePersonalChatRefresh();
                    case 'USER_ONLINE_REFRESH': return console.log('user online refresh');
                    default: console.error("Unknown command")
                }
            }
        })()

    }, [socketCommand]);

    useEffect(() => {
        (async () => {

            if (socketChannels) {
                switch (socketChannels.type) {
                    case ESocketType.CHAT: return handlePersonalChatSetLastMessage(socketChannels.jsonBody);
                    case ESocketType.NOTIFICATION: return console.log('some notification arrived via notification channel')
                    default: console.error("Unknown command")
                }
            }

        })()

    }, [socketChannels]);


    return (
        <>
        </>
    )

}; export default ProcessingSocketCommandsComponent;