import { useTranslation } from "react-i18next";

import { Divider, Button } from '@mui/material';

import { modifyStafRole } from "../../store/reducers/cnfSlice";
import { IRole, TRoles } from "../../models/IStaf";
import { RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { EStatusStafServer } from "../../models/IUser";

interface IProps {
  allRoles: TRoles
}

export default function ListRolesComponent(props: IProps) {

  const { allRoles } = props
  const { t } = useTranslation();
  const { part, role } = useSelector((state: RootState) => state.cnfSlice.staf);
  const dispatch = useDispatch();
  const handleFilterRole = (r: IRole) => {
    dispatch(modifyStafRole(r.name));
  }
  return (
    <>

      <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
        <Divider />
        {(role !== 'all') ?
          <Button
            color='primary'
            onClick={() => handleFilterRole({ uuid: '', name: "all" })}
            size="small"
            variant="contained"
            sx={{ width: "100%", fontSize: "12px" }}>{part === EStatusStafServer.INCOMPLETE ? t("All ") : "All "} {t(part).split('_').join(' ')}
          </Button>
          :
          <Button
            color='inherit'
            size="small"
            variant="contained"
            disabled={true}
            sx={{ width: "100%", fontSize: "12px" }}
          >
            {part === EStatusStafServer.INCOMPLETE ? t("All ") : "All "} {t(part).split('_').join(' ')}
          </Button>}
        <Divider />
        {(allRoles) && allRoles.map((r, i) =>
          <div key={i}>
            {(role !== r.name) ?
              <Button
                color='inherit'
                onClick={() => handleFilterRole(r)}
                size="small"
                sx={{ width: "100%", marginBottom: "5px", fontSize: "12px" }}
              >
                {t(r.name)}
              </Button>
              :
              <Button
                color="secondary"
                variant="contained"
                disabled={true}
                size="small"
                sx={{ width: "100%", marginBottom: "5px", fontSize: "12px" }}
              >
                {t(r.name)}
              </Button>}
            <Divider />
          </div>

        )}
      </div>
    </>
  );
}