import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, TextField, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import SearchBar from '../CustomComponentModule/SearchBarComponent';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import EditColorComponent from '../CustomComponentModule/EditColorComponent/EditColorComponent';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import CloseIcon from '@mui/icons-material/Close';
import { isValidEmail, randomColor } from '../../helpers/generalHelper';
import { useDebounce } from '../../hooks/useDebounce';
import StafService from '../../services/StafService';
import { IResponseAutocomplete, IUserCreateRequestDto } from '../../models/IUser';
import { useNavigate } from 'react-router-dom';
import { modifyLeftFlip, modifyRightFlip, modifyStafRefresh, setLastError } from '../../store/reducers/cnfSlice';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { addFavoritesUsers } from '../../store/reducers/favoritesSlice';

const styled = {
    icon: { fontSize: "28px", cursor: "pointer" }
}
interface IProps {
    snackMsg: (text: string, loc: string) => void;
}
export default function StafButtonBarComponent(props: IProps) {
    const { hints } = useSelector((state: RootState) => state.cnfSlice.interfaceState);
    const { corporateEmailHostDomain } = useSelector((state: RootState) => state.cnfSlice);
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [openN, setOpenN] = useState(false);

    const [color, setColor] = useState('#000000');
    const [errorColor, setErrorColor] = useState(false);
    const debouncedColor = useDebounce(color, 300);

    const [errorMail, setErrorMail] = useState(false);
    const [textMail, setTextMail] = useState('');
    const debouncedMail = useDebounce(textMail, 300);
    const [textErrorMail, setTextErrorMail] = useState('');
    const [save, setSave] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const SnackMess = (text: string) => {
        props.snackMsg(text, '/user');
    }

    useEffect(() => {
        if (save) {
            (async () => {
                const body: IUserCreateRequestDto = {
                    email: textMail,
                    step: 1,
                    color: color
                }
                const [error, response] = await StafService.createUser(body);
                if (response) {
                    let uuid = response.data.response.entityId;
                    navigate('/user/' + uuid);
                    dispatch(modifyRightFlip(true));
                    dispatch(modifyStafRefresh());
                    SnackMess('User with email ' + textMail + ' was successfully created.');
                }
                if (error) { }
            })()
        }
        setSave(false);
    }, [save])

    useEffect(() => {
        if (debouncedMail) {

            if (!isValidEmail(textMail)) {
                setErrorMail(true);
                setTextErrorMail('* this text is not email')
            } else {
                let b = textMail.split('@');
                if ((b[b.length - 1] !== corporateEmailHostDomain)) {
                    setErrorMail(true);
                    setTextErrorMail('* email is not in @' + corporateEmailHostDomain + ' space');
                } else {
                    (async () => {
                        const [error, response] = await StafService.isTrueSistemMail(textMail);
                        if (response) {
                            let ras = response.data.body
                            if (ras) {
                                setErrorMail(false); setTextErrorMail('')
                            } else {
                                setErrorMail(true); setTextErrorMail('* email is already in use');
                            }
                        }
                        if (error) { }
                    })()
                }
            }
        }
    }, [debouncedMail])

    useEffect(() => {
        if (debouncedColor) {
            (async () => {
                const [error, data] = await StafService.isValidUserColor(color);
                if (data !== null) {
                    if (data === false) {
                        setColor(randomColor());
                    }
                    setErrorColor(!data);
                }
            })()
        }
    }, [debouncedColor])

    const handlerChangeMail = (e: any) => {
        let value: string = e.target.value as string;
        value = value.trim().toLocaleLowerCase();
        let b = value.split('@');
        let n = value.length;
        if (value[n - 1] === '@' && b.length <= 2 && value.indexOf('@' + corporateEmailHostDomain) === -1) {
            value += corporateEmailHostDomain;
        }
        setTextMail(value);
    }

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickOpen = () => { setOpen(true); };
    const handleClose = () => { setOpen(false); };

    const hendlerActiveElement = (item: IResponseAutocomplete) => {
        handleClose();
        dispatch(addFavoritesUsers([item.uuid]));
        navigate('/user/' + item.uuid);
    }

    const handleClickAddNewUser = () => {
        navigate('/user');
        setTextMail('');
        setTextErrorMail('');
        setErrorMail(false);
        setColor(randomColor());
        setOpenN(true);
    }
    const handleCloseN = () => {
        setOpenN(false);
    }
    const handlerSaveUser = () => {
        setSave(true);
        handleCloseN();
        dispatch(modifyLeftFlip());
    }
    const handleChengeColor = (s: string) => {
        setColor(s);
    }

    return (
        <div>
            <Box sx={{ marginBottom: "5px" }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", gap: "5px" }}>
                        <Tooltip
                            title={(hints) ? "Search user" : ''}
                            placement={"top"}
                            arrow={true}
                        >
                            <IconButton onClick={handleClickOpen}>
                                <ManageSearchIcon style={styled.icon} color={"primary"} />
                            </IconButton>
                        </Tooltip>

                        <Tooltip
                            title={(hints) ? "Create user" : ''}
                            placement={"top"}
                            arrow={true}
                        >
                            <IconButton onClick={handleClickAddNewUser}>
                                <DashboardCustomizeIcon style={styled.icon} color={"primary"} />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>


                <Dialog
                    fullScreen={fullScreen}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                        { }
                    </DialogTitle>
                    <DialogContent>
                        <div style={{ minHeight: "350px", minWidth: "330px" }}>
                            <SearchBar
                                hendlerActiveElement={hendlerActiveElement}
                                placeholder={'Search by first name or last name or email'}
                            ></SearchBar>
                        </div>

                        <DialogContentText>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    fullScreen={fullScreen}
                    maxWidth={'lg'}
                    open={openN}
                    onClose={handleCloseN}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                        {"CREATE USER"}
                    </DialogTitle>
                    <DialogContent>
                        <div style={{ display: "flex", gap: "30px", }}>
                            <div style={{ minHeight: "90px", paddingTop: "30px" }}>

                                <div style={{ display: "flex", gap: "18px", alignItems: "start", justifyContent: "", marginBottom: "30px", height: "70px" }}>
                                    <EditColorComponent
                                        type={1}
                                        currentColor={color}
                                        getColor={handleChengeColor}

                                    ></EditColorComponent>

                                    <TextField
                                        label={'Corporative color '}
                                        disabled
                                        error={errorColor}
                                        id="standard-basic"
                                        value={color}
                                        style={{ width: "340px" }}
                                        inputProps={{ sx: { fontSize: "13px" } }}
                                        InputLabelProps={{ sx: { fontSize: "17px" } }}
                                        helperText={errorColor ? "* please enter another color this is already used ..." : ''}
                                    />
                                </div>

                                <div style={{ display: "flex", gap: "20px", height: "70px" }}>
                                    <TextField
                                        error={errorMail}
                                        autoFocus
                                        autoComplete='off'
                                        onChange={handlerChangeMail}
                                        label={'Corporative email '}
                                        style={{ width: "400px" }}
                                        value={textMail}
                                        inputProps={{ sx: { fontSize: "13px" } }}
                                        InputLabelProps={{ sx: { fontSize: "17px" } }}
                                        helperText={textErrorMail}
                                    />
                                </div>
                            </div>
                        </div>
                        <DialogContentText>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <div style={{ display: "flex", justifyContent: "flex-end", width: "100%", padding: "20px", gap: "10px" }}>
                            <Button
                                variant={'contained'}
                                disabled={errorColor || errorMail || textMail.length < 4}
                                size={'small'}
                                onClick={handlerSaveUser} startIcon={<SaveAsIcon />}>Create</Button>
                            <Button
                                startIcon={<CloseIcon />}
                                color={'warning'}
                                variant={'contained'}
                                size={'small'}
                                onClick={handleCloseN}>Close</Button>

                        </div>
                    </DialogActions>
                </Dialog>

            </Box>
            <Divider></Divider>
            <Box sx={{ height: "10px" }}></Box>
        </div>
    );
}