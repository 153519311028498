import styled from '@emotion/styled'

interface IProps {
    red?: number;
    green?: number;
    blue?: number;
    hot?: number;
    priority?: boolean;
    minWidth?: number;
}
const SCViewGradient = styled.div<IProps>`
    background: rgba(${(props) => props.red ? props.red : 255},${(props) => props.hot ? props.hot : 100} ,${(props) => props.hot ? props.hot : 100} ,1 );
    border-radius: 14px;
    padding:5px;
    min-width: ${(props) => props.minWidth ? props.minWidth : 0}px;
    justify-content: center;
    text-align: center;
    display:flex;
    gap:3px;
    font-weight:bold;
`
export default SCViewGradient;