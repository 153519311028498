import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Divider, Button } from '@mui/material';
import { modifyCustomerRole } from "../../store/reducers/cnfSlice";
import { RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { ECustomerReputationLevel } from "../../models/CustomerModels";

export default function ListCustomerComponent() {

  const { t } = useTranslation();
  const { role, part } = useSelector((state: RootState) => state.cnfSlice.customer);
  const [roleTable, setRoleTable] = useState<string[]>([]);

  const dispatch = useDispatch();
  const hendlerSelectRole = (p: string) => {
    dispatch(modifyCustomerRole(p));
  }

  useEffect(() => {
    let tab: string[] = []
    for (let el of Object.keys(ECustomerReputationLevel)) { tab.push(el) }
    tab = ['ALL', ...tab];
    setRoleTable(tab);
  }, [])

  return (
    <>
      {roleTable.map((el, i) =>
        <div key={i} style={{ marginTop: "5px" }}>
          <Button
            onClick={() => hendlerSelectRole(el)}
            disabled={role === el}
            variant={((role === el) || (el === 'ALL')) ? 'contained' : 'text'}
            color={(el === 'ALL') ? 'primary' : 'inherit'}
            size="small"
            sx={{ width: "100%", marginBottom: "5px", fontSize: "12px" }}
          >
            {el === 'ALL' ? 'ALL ' + part : el.split('_').join(' ')}
          </Button>
          <Divider />
        </div>)}
    </>
  );
}