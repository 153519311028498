
import { useEffect, useState } from "react";
import ControlStateInCRUDComponent from "../CustomComponentModule/ControlStateInCRUDComponent";
import HTMLRedactor from "../CustomComponentModule/HTMLRedactor";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import { IWarehouseDetails, IWarehouseDetailsStep } from "../../models/WarehouseModels";
import WarehouseService from "../../services/WarhouseService";
import { setLastError } from "../../store/reducers/cnfSlice";
import { IUserMetadata } from "../../models/IUser";

interface IProps {
    w: IWarehouseDetails | null;
    details: IWarehouseDetailsStep | null;
    metadata: IUserMetadata | null;
    refreshAfretSave: () => void;
    snackMsg: (text: string) => void;
    handleStep: (step: number) => void
}

const DetailsWarehouseComponent = (props: IProps) => {

    const { w, details, metadata } = props
    const [state, setState] = useState<IWarehouseDetailsStep>({
        uuid: '',
        description: ''
    })
    const [save, setSave] = useState<boolean>(false);

    const dispatch = useDispatch<AppDispatch>();
    const [snapshot, setSnapshot] = useState<string>("");
    const [error, setError] = useState<number>(0);
    const [description, setDescription] = useState('');

    const handlerSaveUser = () => { setSave(true); }
    const handlerReset = () => {
        setDescription('')
        let st = JSON.parse(snapshot);
        setState(st);
    }
    const handlerDescription = (s: string) => {
        if (state) {
            setState((st) => { return { ...st, description: s } })
        }
    }

    useEffect(() => {
        if (save) {
            (async () => {
                const [error, response] = await WarehouseService.saveWarehouseDetails(state)
                if (response) {
                    setSnapshot(JSON.stringify({ ...state }));
                    props.snackMsg('Details data was successfully saved for ' + props.w?.name);
                }
                if (error) { dispatch(setLastError(error)) }
                props.refreshAfretSave();
            })()

            setSave(false);
        }
    }, [save])

    useEffect(() => {
        if (details) {
            setSnapshot(JSON.stringify(details));
            setState(details)
            setDescription(details.description ? details.description : "");
        }

    }, [details])

    useEffect(() => {
        if (JSON.stringify(state) === snapshot) {
            setError(0)
        } else setError(1)

    }, [state])

    return (
        <>
            <div style={{ display: "flex", gap: 10, width: "620px" }}>
                <div style={{ width: "80%" }}>
                    <HTMLRedactor
                        getValue={handlerDescription}
                        initValue={description}
                    />
                </div>
                <div style={{ minWidth: "20%", display: "flex", alignItems: "center" }} >
                    <ControlStateInCRUDComponent
                        error={error}
                        textErrors={[]}
                        handlerReset={handlerReset}
                        handlerSave={handlerSaveUser}
                    />
                </div>
            </div>
        </>
    )
}
export default DetailsWarehouseComponent;