import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { dateToYMD } from "../../helpers/dateHelper";
import { IAuditFilter, LabelType } from "../../models/AuditModels";

interface AuditState {
    filter: IAuditFilter;
    query: number;
    valueFilter: Array<LabelType[]>;
    grid: {
        page: number;
        size: number;
        refresh: boolean;
    }
}

const initialState: AuditState = {
    query: 0,
    valueFilter: [[], [], []],
    filter: {
        times: {
            t1: Date.now(), v1: dateToYMD(Date.now()),
            t2: Date.now(), v2: dateToYMD(Date.now())
        },
        v1: [],
        v2: [],
        v3: []
    },
    grid: {
        page: 0,
        size: 25,
        refresh: false
    }
}

export const auditSlice = createSlice({
    name: 'audit',
    initialState,
    reducers: {
        modifyAuditFilter(state, action: PayloadAction<IAuditFilter>) {
            state.grid.page = 0;
            state.filter = action.payload
        },
        modifyAuditPage(state, action: PayloadAction<number>) {
            state.grid.page = action.payload
        },
        modifyAuditSizePage(state, action: PayloadAction<number>) {
            state.grid.page = 0;
            state.grid.size = action.payload;
        },
        modifyAuditRefresh(state) {
            state.grid.refresh = !state.grid.refresh;
        },
        modifyAuditValues(state, action: PayloadAction<Array<LabelType[]>>) {
            state.valueFilter = action.payload;
        },
        modifyQuery(state) {
            state.query = state.query + 1;
        },
    },
    extraReducers: () => {}
})

export const {
    modifyQuery,
    modifyAuditValues,
    modifyAuditPage,
    modifyAuditSizePage,
    modifyAuditRefresh,
    modifyAuditFilter
} = auditSlice.actions;

export default auditSlice.reducer;