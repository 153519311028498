import { useTranslation } from "react-i18next";
import { Box, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import StarsIcon from '@mui/icons-material/Stars';
import EmailIcon from '@mui/icons-material/Email';
import { AppDispatch, RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import { modifyRightFlip } from "../../store/reducers/cnfSlice";
import { useNavigate } from "react-router";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useRef } from "react";
import { addFavoritesWarehouses } from "../../store/reducers/favoritesSlice";
import { EWarehouseReputationLevel, IWarehouseDetails, WarehouseStatusRequestDto } from "../../models/WarehouseModels";
import VIPViewComponent from "../CustomComponentModule/VIPViewComponent";
import GenericCRUDService from "../../services/GenericCRUDService";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import { IUserMetadata } from "../../models/IUser";
import DownloadIcon from '@mui/icons-material/Download';

const styled = {
  container: { display: "flex", gap: "20px" },
  leftPart: { width: "200px", padding: "0px 10px 50px 10px", height: "1060px" },
  centerPart: { width: "100%", padding: "20px 10px 50px 10px" },
  rigthPart: { width: "450px", padding: "5px 10px 50px 10px" },
  item: { width: "40px" },
  box: { border: "1px solid silver", textAlign: "center", borderRadius: "3px" },
  icon: { fontSize: "28px", cursor: "pointer" },
  button: { border: "1px solid silver" },
  info: {},
  rows: { display: "flex", gap: "2px" },
  boxis: { width: "200px", padding: "4px", fontSize: "12px", overflow: "hidden" },
}

interface IPropsCoverFaceWarehouseComponent {
  warehouse: IWarehouseDetails | null,
  status: WarehouseStatusRequestDto | null,
  metadata: IUserMetadata | null
}
const CoverFaceWarehouseComponent = (
  props: IPropsCoverFaceWarehouseComponent
) => {

  const { t } = useTranslation();
  const { hints } = useSelector((state: RootState) => state.cnfSlice.interfaceState);
  const componentRef = useRef<HTMLDivElement | null>(null);
  const linkTel = useRef<HTMLAnchorElement | null>(null);
  const { auth } = useSelector((state: RootState) => state.cnfSlice);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleClose = () => {
    dispatch(modifyRightFlip(false));
    navigate('/warehouse');
  }

  const handlerAddToFavorite = () => {
    if (props.warehouse) {
      let id = props.warehouse.uuid
      dispatch(addFavoritesWarehouses([id]));
    }
  }

  const handlerLinkTel = () => {
    linkTel.current?.click()
  }

  const handlerCreateEmail = () => {
    let url = 'https://mail.google.com/mail/?view=cm&fs=1';
    let to = '&to=' + props.warehouse?.email;
    let bcc = '&bcc=';
    if (auth) {
      bcc += auth.email
    }
    window.open(url + to + bcc);
  }

  const hendlerExcel = () => {
    let body = {
      criteria: [
        {
          property: "uuid",
          value: props.warehouse?.uuid,
          expression: "EQUALS"
        }
      ]
    }

    GenericCRUDService.downloadReport('warehouse/report', body)
  }


  return (
    <>
      <Box sx={{ padding: 2, marginTop: "1", display: "flex", alignItems: "center" }}>
        {props.status?.reputationLevel === EWarehouseReputationLevel.VIP &&
          <Paper elevation={0} sx={{ minWidth: "50px", height: "50px", display: "flex", justifyContent: "center", alignItems: "center", marginLeft: "-10px", marginRight: "10px" }}>
            <Box sx={{ paddingLeft: "10px", paddingTop: "10px" }}>
              <VIPViewComponent width={40} heigth={40} />
            </Box>
          </Paper>}

        {props.status?.reputationLevel === EWarehouseReputationLevel.POOR &&
          <Paper elevation={0} sx={{ minWidth: "50px", height: "50px", display: "flex", justifyContent: "center", alignItems: "center", marginLeft: "-10px", marginRight: "10px" }}>
            <Box sx={{ paddingLeft: "10px", paddingTop: "10px" }}>
              <ErrorOutlineIcon color="error" fontSize="large" />
            </Box>
          </Paper>}
        {props.status?.reputationLevel === EWarehouseReputationLevel.STANDARD &&
          <Paper elevation={0} sx={{ minWidth: "50px", height: "50px", display: "flex", justifyContent: "center", alignItems: "center", marginLeft: "-10px", marginRight: "10px" }}>
            <Box sx={{ paddingLeft: "10px", paddingTop: "10px" }}>
              <VerifiedUserOutlinedIcon color="success" fontSize="large" />
            </Box>
          </Paper>}

        <div style={{ display: "flex", width: "240px", flexDirection: "column", marginLeft: "15px", justifyContent: "center" }}>
          <Box style={styled.rows}>
            <Typography variant="subtitle1" align="left" >
              <b>{(props.warehouse?.name)?.toLocaleUpperCase()}</b>
            </Typography>
          </Box>

          <Box style={styled.rows}>
            <Typography align="left" style={styled.boxis}>
              {(props.warehouse?.contactName)?.toLocaleUpperCase()}
            </Typography>
          </Box>
        </div>

        <div style={{ display: "flex", alignItems: "center", marginLeft: "20px" }}>
          <div style={{ marginLeft: "20px", paddingLeft: "10px", display: "flex", gap: "10px", height: "40px", borderLeft: "1px dashed silver", justifyContent: "space-between", width: "350px" }}>
            {!props.metadata?.completed ?
              <Typography variant='subtitle2' sx={{ padding: "3px", paddingTop: "9px" }} color='red'>INCOMPLETE WAREHOUSE</Typography>
              : <div style={{ display: "flex", alignItems: "center" }}>

                <Tooltip
                  title={(hints) ? t("Add warehouse to favorites") : ''}
                  placement={"top"}
                  arrow={true}
                >
                  <IconButton
                    onClick={handlerAddToFavorite}
                  >
                    <StarsIcon style={styled.icon} />
                  </IconButton>
                </Tooltip>


                <Tooltip
                  title={(hints) ? t("Send email to selected warehouse") : ''}
                  placement={"top"}
                  arrow={true}
                >
                  <IconButton
                    onClick={handlerCreateEmail}
                  >
                    <EmailIcon style={styled.icon} />
                  </IconButton>
                </Tooltip>

                <Tooltip
                  title={(hints) ? t("Call the selected warehouse") : ''}
                  placement={"top"}
                  arrow={true}
                >
                  <IconButton
                    onClick={handlerLinkTel}
                  >
                    <PhoneAndroidIcon style={styled.icon} />
                  </IconButton>
                </Tooltip>

                <Tooltip
                  title={(hints) ? t("Download XLS report for selected warehouse") : ''}
                  placement={"top"}
                  arrow={true}
                >
                  <IconButton
                    onClick={hendlerExcel}
                  >
                    <DownloadIcon style={styled.icon} />
                  </IconButton>
                </Tooltip>

              </div>}
            <div style={{ width: "0px", height: "0px", padding: 0, margin: 0, overflow: "hidden" }}>

              <div ref={componentRef}>
                {/* <ReportUser /> */}
              </div>
            </div>

            <Tooltip
              title={(hints) ? t("Close and return to the list of warehouses") : ''}
              placement={"top"}
              arrow={true}
            >
              <IconButton onClick={handleClose} >
                <HighlightOffIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </Box>

      <div style={{ width: "0px", height: "0px", overflow: "hidden", border: "1px solid silver" }}>
        <a ref={linkTel} href={"tel:" + props.warehouse?.phone} data-rel="external">_</a>
      </div>
    </>
  )
}

export default CoverFaceWarehouseComponent;