import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { IconButton, Rating, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { ITypeWarehouse, IWarehouseViewDetails } from '../../models/WarehouseModels';
import WarehouseService from '../../services/WarhouseService';
import { IUserMetadata } from '../../models/IUser';

interface IProps {
    uuid: string;
    metadata: IUserMetadata | null;
    toStep: (step: number) => void;
}

export default function VeiwTableWarehouseReportComponent(props: IProps) {
    const { uuid, metadata } = props;
    const theme = useTheme();
    const [state, setState] = useState<IWarehouseViewDetails | null>(null);

    useEffect(() => {
        if (uuid) {
            (async () => {
                const [error, response] = await WarehouseService.getWarehouseSummary(uuid);
                if (response) {
                    setState(response.data.body)
                }
                if (error) { }
            })()
        }
    }, [uuid]);

    const hendlerToEdit = (step: number) => {
        props.toStep(step - 1)
    }

    const boxSX = {
        color: theme.palette.action.disabled,
        "&:hover": {
            color: theme.palette.primary.main
        },
    }

    return (
        <div>
            <table style={{ width: "100%", marginTop: "10px" }}>
                <tbody>
                    <tr>
                        <td className={'cell but'}>
                            <IconButton size={'small'} onClick={() => hendlerToEdit(1)}>
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={'cell label'}>
                            <Typography color={'silver'} variant='subtitle2'>NAME</Typography>
                        </td>
                        <td className={'cell vals'}>
                            <Typography variant='subtitle2'>{state?.name ? state?.name : '*'}</Typography>
                        </td>
                    </tr>

                    <tr>
                        <td className={'cell but'}>
                            <IconButton size={'small'} onClick={() => hendlerToEdit(1)}>
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={'cell label'}>
                            <Typography color={'silver'} variant='subtitle2' >
                                TYPE
                            </Typography>
                        </td>
                        <td className={'cell vals'}>
                            <Typography variant='subtitle2'>
                                {(state?.types)
                                    ? (state?.types as ITypeWarehouse[]).reduce((a, e, i: number) =>
                                        a.concat(' ', e.name.split('_').join(' '), (state?.types && state?.types.length - 1 === i) ? '' : ','), '')
                                    : "N/A"}
                            </Typography>
                        </td>
                    </tr>

                    <tr>
                        <td className={'cell but'}>
                            <IconButton size={'small'} onClick={() => hendlerToEdit(1)}>
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={'cell label'}>
                            <Typography color={'silver'} variant='subtitle2'>CONTACT NAME</Typography>
                        </td>
                        <td className={'cell vals'}>
                            <Typography variant='subtitle2'>{state?.contactName ? state?.contactName : '*'}</Typography>
                        </td>
                    </tr>

                    <tr>
                        <td className={'cell but'}>
                            <IconButton size={'small'} onClick={() => hendlerToEdit(1)}>
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={'cell label'}>
                            <Typography color={state?.address ? 'silver' : 'error'} variant='subtitle2' >
                                ADDRESS
                            </Typography>
                        </td>
                        <td className={'cell vals'}>
                            <Typography variant='subtitle2'>
                                {state?.address ? state.address : 'N/A'}
                            </Typography>
                        </td>
                    </tr>


                    <tr>
                        <td className={'cell but'}>
                            <IconButton size={'small'} onClick={() => hendlerToEdit(2)} >
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={'cell label'}>
                            <Typography color={state?.phone ? 'silver' : 'error'} variant='subtitle2' >
                                PHONE
                            </Typography>
                        </td>
                        <td className={'cell vals'}>
                            <Typography variant='subtitle2'>
                                {state?.phone ? state?.phone : 'N/A'}
                            </Typography>
                        </td>
                    </tr>


                    <tr>
                        <td className={'cell but'}>
                            <IconButton size={'small'} onClick={() => hendlerToEdit(2)}>
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={'cell label'}>
                            <Typography color='silver' variant='subtitle2' >
                                EMAIL
                            </Typography>
                        </td>
                        <td className={'cell vals'}>
                            <Typography variant='subtitle2'>
                                {state?.email ? state?.email : 'N/A'}
                            </Typography>
                        </td>
                    </tr>


                    <tr>
                        <td className={'cell but'}>
                            <IconButton size={'small'} onClick={() => hendlerToEdit(2)} disabled={!metadata?.completed}>
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={'cell label'}>
                            <Typography color={'silver'} variant='subtitle2' >
                                CELL
                            </Typography>
                        </td>
                        <td className={'cell vals'}>
                            <Typography variant='subtitle2'>
                                {state?.cell ? state.cell : 'N/A'}
                            </Typography>
                        </td>
                    </tr>


                    <tr>
                        <td className={'cell but'}>
                            <IconButton size={'small'} onClick={() => hendlerToEdit(2)} disabled={!metadata?.completed}>
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={'cell label'}>
                            <Typography color={'silver'} variant='subtitle2' >
                                FAX
                            </Typography>
                        </td>
                        <td className={'cell vals'}>
                            <Typography variant='subtitle2'>
                                {state?.fax ? state.fax : 'N/A'}
                            </Typography>
                        </td>
                    </tr>


                    <tr>
                        <td className={'cell but'}>
                            <IconButton size={'small'} onClick={() => hendlerToEdit(5)} disabled={!metadata?.completed}>
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={'cell label'}>
                            <Typography color={'silver'} variant='subtitle2' >
                                STATUS
                            </Typography>
                        </td>
                        <td className={'cell vals'}>
                            <Typography variant='subtitle2'>
                                {state?.status ? state?.status.split('_').join(' ') : '*'}

                            </Typography>
                        </td>
                    </tr>


                    <tr>
                        <td className={'cell but'}>
                            <IconButton size={'small'} onClick={() => hendlerToEdit(5)} disabled={!metadata?.completed}>
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={'cell label'}>
                            <Typography color={'silver'} variant='subtitle2' >
                                REPUTATION
                            </Typography>
                        </td>
                        <td className={'cell vals'}>
                            <Typography variant='subtitle2'>
                                {state?.reputationLevel ? state?.reputationLevel : '*'}

                            </Typography>
                        </td>
                    </tr>


                    <tr>
                        <td className={'cell but'}>
                            <IconButton size={'small'} onClick={() => hendlerToEdit(4)} disabled={!metadata?.completed}>
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={'cell label'}>
                            <Typography color={'silver'} variant='subtitle2' >
                                REVIEW MARK
                            </Typography>
                        </td>
                        <td className={'cell vals'}>
                            {
                                <Rating value={state?.reviewMark ? state?.reviewMark : 0} readOnly ></Rating>
                            }
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}



