import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import ControlStateInCRUDComponent from "../CustomComponentModule/ControlStateInCRUDComponent";
import HTMLRedactor from "../CustomComponentModule/HTMLRedactor";
import { useDebounce } from "../../hooks/useDebounce";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import { modifyCustomerRefresh, setLastError } from "../../store/reducers/cnfSlice";
import { ICustomerDetails } from "../../models/CustomerModels";
import CustomerService from "../../services/CustomerService";
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import { AddressRequestDto, AddressResponseDto } from "../../models/AddressModels";
import AddressComponent from "../MapModule/AddressComponent";
import AddressService from "../../services/AddressService";

interface IRegisterCustomerStepState {
    uuid: string,
    name: string,
    addressId: string,
    description: string,
    address?: any | null
}

interface IProps {
    customer: ICustomerDetails | null;
    refreshAfretSave: () => void;
    snackMsg: (text: string) => void;
    handleStep: (step: number) => void;
}

const RegistrationCustomerComponent = (props: IProps) => {

    const { customer } = props
    const [state, setState] = useState<IRegisterCustomerStepState>({
        uuid: '',
        name: '',
        addressId: '',
        address: null,
        description: ''
    })

    const [save, setSave] = useState<boolean>(false);
    const [isValidName, setIsValidName] = useState<boolean>(true);
    const debouncedName = useDebounce(state.name, 300);
    const dispatch = useDispatch<AppDispatch>();
    const [snapshot, setSnapshot] = useState<string>("");
    const [error, setError] = useState<number>(-1);
    const [textErrors, setTextErrors] = useState<Array<string>>([])
    const [errors, setErrors] = useState([false, false]);
    const [adress, setAdress] = useState<AddressResponseDto | null>();
    const [open, setOpen] = useState(false);
    const [newAdress, setNewAdress] = useState<AddressResponseDto | null>()
    const [getUUidAddress, setGetUUidAddress] = useState(false);
    const [description, setDescription] = useState('');
    const [contolStateCustomer, setContolStateCustomer] = useState(false);


    const handlerSaveUser = () => { setSave(true); }
    const handlerReset = () => {
        setDescription('')
        let st = JSON.parse(snapshot);
        setState(st);

    }
    const handlerDescription = (s: string) => {
        setState((st) => { return { ...st, description: s } })
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length <= 255) { }
    };

    useEffect(() => {
        if (debouncedName) {
            if (state.name.length >= 6 && state.name !== customer?.name) {
                (async () => {
                    const [error, response] = await CustomerService.isValidName(state.name);
                    if (response) {
                        const value: boolean = response.data.body as boolean;
                        setIsValidName(value);
                    }
                    if (error) {
                        dispatch(setLastError(error))
                    }
                })()
            } else {
                setIsValidName(true);
            }
        }
    }, [debouncedName])

    useEffect(() => {
        if (newAdress && getUUidAddress) {
            (async () => {
                const { uuid, fullAddress, ...rest } = newAdress;
                let body: AddressRequestDto = rest;
                const [, data] = await AddressService.getOrCreateAddress(body);
                if (data) {
                    const addressResponse: AddressResponseDto = data as AddressResponseDto;
                    setNewAdress(addressResponse);
                    setState((st) => ({ ...st, addressId: addressResponse.uuid, address: addressResponse }))
                    setAdress(newAdress);
                }
            })()
        }
        setGetUUidAddress(false);
    }, [getUUidAddress])


    useEffect(() => {
        if (save && customer) {
            (async () => {
                const { address, ...body } = state;
                const [error, response] = await CustomerService.saveRegistration(body)
                if (response) {
                    setSnapshot(JSON.stringify({ ...state }));
                    setContolStateCustomer(true);
                }
                if (error) { dispatch(setLastError(error)) }
            })()
        }
        setSave(false);
    }, [save])

    useEffect(() => {
        if (contolStateCustomer) {
            if (customer) {
                (async () => {
                    const [error, response] = await CustomerService.getMetadata(customer.uuid)
                    if (response) {


                        const { step, completed } = response.data.body;

                        if (completed && !customer.metadata.completed && step !== 5) {
                            props.handleStep(5);
                        }
                        if (!completed && !customer.metadata.completed && step !== 1) {
                            let bd = { uuid: customer.uuid, step: 1 }
                            const [er, rs] = await CustomerService.saveCustomerStep(bd);
                            if (er) { dispatch(setLastError(error)) }
                        }

                        props.refreshAfretSave();
                        props.snackMsg('Registration data was successfully saved for ' + customer.name + '.');
                        dispatch(modifyCustomerRefresh());
                    }
                    if (error) { dispatch(setLastError(error)) }
                })()
            }
        }
        setContolStateCustomer(false);
    }, [contolStateCustomer])

    useEffect(() => {

        if (customer) {
            (async () => {
                const [error, response] = await CustomerService.getRegistrationCustomerStep(customer.uuid);
                if (response) {
                    const value = response.data.body as IRegisterCustomerStepState;
                    if (!value.description) {
                        value.description = ''
                    }
                    if (!value.address) {
                        value.addressId = ''
                    } else {
                        value.addressId = value.address.uuid
                    }
                    setDescription(value.description);
                    setState({ ...value })
                    setSnapshot(JSON.stringify({ ...value }));
                }
                if (error) {
                    dispatch(setLastError(error))
                }
            })()
        }
    }, [customer?.uuid])

    useEffect(() => {
        let er: boolean = false;
        let ter: string[] = [];

        if ((state.name.length < 3)) {
            er = true;
            ter.push("* NAME is requared field")
        }
        if (!isValidName) {
            er = true;
            ter.push("* NAME exist in sistem")
        }
        if (!state.address?.uuid) {
            er = true;
            ter.push("* ADRESS is requared field")
        }

        if (customer && !er) {
            if (snapshot === JSON.stringify(state)) {
                setError(0)
            } else {
                setError(1)
            }
        } else {
            setError(-1)
        }

        setTextErrors(ter);
    }, [state, isValidName, snapshot])

    const handleClickOpen = () => { setOpen(true); };
    const handleActivateAddress = () => {
        if (newAdress) {
            setAdress(newAdress);
            setGetUUidAddress(true);
            setOpen(false);
        }
    }
    const handleNewAdres = (a: AddressResponseDto) => { setNewAdress(a); }
    const handleEdit = (v: boolean) => { }
    const handleClose = () => {
        setOpen(false);
    };
    const handlerChangeName = (e: any) => {
        let value: string = (e.target.value as string);
        setState(st => ({ ...st, name: value }))
    }

    return (
        <>

            <div style={{ flex: "1 1 auto", display: "flex", gap: 10, width: "620px", }}>
                <div style={{ display: "flex", flexDirection: "column", gap: "10px", width: "535px", marginRight: "18px" }}>

                    <div style={{ display: "flex", flexDirection: "row", marginBottom: "20px" }}>
                        <TextField
                            autoComplete={'off'}
                            error={state.name.length < 3 || !isValidName}
                            value={state.name}
                            label="Customer Name"
                            onChange={handlerChangeName}
                            style={{ width: "540px" }}
                            inputProps={{ sx: { fontSize: "16px" } }}
                            InputLabelProps={{ sx: { fontSize: "17px" } }}
                            helperText={!isValidName ? '* this ID exists' : ''}
                        />
                    </div>
                    <div style={{ width: "720px", display: "flex", flexDirection: "row", gap: "5px", marginBottom: "5px" }}>
                        <Button
                            variant={'contained'}
                            onClick={handleClickOpen}
                            color={!state.address ? 'error' : 'info'}
                            sx={{ maxWidth: "40px" }}
                        >
                            <AddLocationAltIcon />
                        </Button>

                        <TextField
                            label=""
                            disabled
                            sx={{ width: "465px" }}
                            multiline
                            rows={2}
                            value={state.address ? state.address?.fullAddress?.replaceAll('**', ' ') : ''}
                            inputProps={{ sx: { fontSize: "12px", padding: "1px", lineHeight: "1.5" } }}
                            InputLabelProps={{ sx: { fontSize: "17px" } }}
                        />



                    </div>
                    <div style={{ marginTop: "16px", marginBottom: "20px" }}>
                        <HTMLRedactor
                            getValue={handlerDescription}
                            initValue={description}
                        />
                    </div>

                    <div style={{ display: "flex" }}>
                        {/* <pre style={{ maxWidth: "300px", overflow: "hidden" }}>
                            {JSON.stringify(state, undefined, 2)}
                        </pre> */}
                    </div>
                </div>



                <div style={{ display: "flex", minWidth: "53px" }} >
                    <ControlStateInCRUDComponent
                        error={error}
                        textErrors={textErrors}
                        handlerReset={handlerReset}
                        handlerSave={handlerSaveUser}
                    />
                </div>



                <Dialog
                    fullWidth={true}
                    maxWidth={'md'}
                    open={open}
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle></DialogTitle>
                    <DialogContent>
                        {/* {adress && */}
                        <AddressComponent
                            current={adress ? adress : null}
                            getPlace={handleNewAdres}
                            getEdit={handleEdit}
                        />
                        {/* } */}

                    </DialogContent>
                    <DialogActions sx={{ marginBottom: "10px", marginRight: "20px" }}>
                        <Button variant={'contained'} color={'info'}
                            onClick={handleActivateAddress} disabled={!newAdress || newAdress.zipcode.length < 4}> Apply/Select
                        </Button>
                        <Button variant={'contained'} color={'warning'} onClick={handleClose}>Cancel</Button>
                    </DialogActions>
                </Dialog>

            </div>
        </>
    )
}
export default RegistrationCustomerComponent;