import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMotto } from "../../models/IMotto";
import { IInterfaceState, ISecure, IUser } from "../../models/IUser";
import { INotification } from "../../models/NotificationModels";
import { ITypeWarehouse } from "../../models/WarehouseModels";
import GeneralService from "../../services/GeneralService";
import NotificationService from "../../services/NotificationService";
import { RootState } from "../store";
import { EConsumptionType, EDateFormat, EDistanceType, EEnginePowerType, EEngineVolumeType, EMassType, ESizeType, ETimeFormat, IGlobalProfile } from "../../models/ProfileModels";
import { IConversationMessages, ICoversationCreateMessageRequest, ISocketConversationMessage } from "../../models/PersonalChatModels";

interface CnfState {
    tockens: any | null;
    modifyNotification: boolean,
    modifyNotificationCount: boolean,
    lastError: any | null,
    motto: IMotto,
    host: string,
    corporateEmailHostDomain: string,
    auth?: IUser | null,
    secure: ISecure,
    role: Array<number>,
    globalPreferenceState: IGlobalProfile;
    interfaceState: IInterfaceState;
    staf: {
        part: string;
        role: string;
        page: number;
        size: number;
        refresh: boolean;
        step: number;
    };
    auto: {
        part: string;
        role: string;
        page: number;
        size: number;
        refresh: boolean;
    },
    customer: {
        part: string;
        role: string;
        page: number;
        size: number;
        refresh: boolean;
    },
    load: {
        part: string;
        statuses: string[];
        page: number;
        size: number;
        refresh: boolean;
        refreshTypes: boolean;
    },
    activeLoad: {
        part: string;
        page: number;
        size: number;
        refresh: boolean;
    }
    warehouse: {
        part: string;
        role: ITypeWarehouse[];
        reputation: string;
        page: number;
        size: number;
        refresh: boolean;
        refreshTypes: boolean;
        grid: boolean;
    },
    chat: {
        refresh: boolean,
        refreshRead: boolean,
        readMessageSocket: string,
        lastMessage: ISocketConversationMessage | null,
        partnerId: string,
        conversationId: string,
        autoRead: boolean
    }
}

const initialState: CnfState = {
    tockens: null,
    modifyNotification: false,
    modifyNotificationCount: false,
    motto: {
        uuid: '',
        author: 'Winston Churchill',
        text: 'Now this is not the end. It is not even the beginning of the end. But it is, perhaps, the end of the beginning.',
        active: true
    },
    auth: null,
    host: '',
    corporateEmailHostDomain: '',
    role: [1, 0, 1, 0],
    lastError: null,
    globalPreferenceState: {
        measureSize: ESizeType.INCH,
        measureDistance: EDistanceType.MILE,
        measureConsumption: EConsumptionType.MILES_PER_GALLON,
        measureMass: EMassType.POUND,
        measureEngineVolume: EEngineVolumeType.LITER,
        measureEnginePower: EEnginePowerType.HORSE_POWER
    },
    interfaceState: {
        language: 'en',
        notificationEmailEnabled: false,
        notificationPushEnabled: true,
        pathURL: '/settings',
        hints: true,
        darkMode: false,
        menuState: 1,
        presentMenu: true,
        presentFlip: false,
        rightFlip: false,
        leftFlip: true,
        dateFormat: EDateFormat.d_mm_dd_yyyy,
        timeFormat: ETimeFormat.h_12
    },
    staf: {
        part: "ACTIVE",
        role: "all",
        page: 0,
        size: 25,
        refresh: true,
        step: 0
    },
    auto: {
        part: "ACTIVE",
        role: "all",
        page: 0,
        size: 25,
        refresh: true
    },
    customer: {
        part: "ACTIVE",
        role: 'ALL',
        page: 0,
        size: 25,
        refresh: true
    },
    load: {
        part: "ACTIVE",
        statuses: [],
        page: 0,
        size: 25,
        refresh: true,
        refreshTypes: false
    },
    activeLoad: {
        part: "ACTIVE",
        page: 0,
        size: 25,
        refresh: true
    },
    warehouse: {
        part: "ACTIVE",
        role: [],
        reputation: "ALL",
        page: 0,
        size: 25,
        refresh: true,
        refreshTypes: false,
        grid: true
    },
    secure: {
        accessToken: '',
        tokenType: '',
        refreshToken: '',
        expiryDuration: ''
    },
    chat: {
        refresh: false,
        refreshRead: false,
        lastMessage: null,
        partnerId: '',
        conversationId: '',
        readMessageSocket: '',
        autoRead: false
    }
}


export const addNotification = createAsyncThunk<boolean, INotification, { rejectValue: string, state: RootState }>(
    'cnf/addNotification',
    async function (n: INotification, { rejectWithValue, getState }) {


        let userName: string = getState().cnfSlice.auth?.username as string;

        const [error, response] = await GeneralService.getServerTime();
        if (response) {
            const m: INotification = { ...n, userUUID: userName, dt: response.data.body.serverTime }
            NotificationService.setNotification(m);
        }
        if (error) { }

        return true;
    }
);


export const cnfSlice = createSlice({
    name: 'cnf',
    initialState,
    reducers: {
        modifyChatLastMessage(state, action: PayloadAction<ISocketConversationMessage | null>) {
            if (state.chat.conversationId === '*') {
                state.chat.lastMessage = action.payload;
                return
            }

            if (action.payload?.conversationId !== state.chat.conversationId) {
                state.chat.lastMessage = null;
            } else {
                state.chat.lastMessage = action.payload;
            }

        },
        modifyChatRefresh(state, action: PayloadAction<string>) {
            if (action.payload === '*') {
                state.chat.refresh = !state.chat.refresh;
                state.chat.partnerId = state.chat.conversationId;
            } else if (state.chat.conversationId !== action.payload || !state.chat.autoRead) {
                state.chat.refresh = !state.chat.refresh;
                state.chat.partnerId = action.payload
            }
        },
        modifyChatConversationId(state, action: PayloadAction<string>) {
            state.chat.conversationId = action.payload;
        },
        modifyChatAutoRead(state, action: PayloadAction<boolean>) {
            state.chat.autoRead = action.payload;
        },
        modifyChatPartnerId(state, action: PayloadAction<string>) {
            state.chat.partnerId = action.payload;
        },
        modifyChatMessageReadRefresh(state, action: PayloadAction<string>) {
            state.chat.refreshRead = !state.chat.refreshRead;
            state.chat.readMessageSocket = action.payload
        },
        modifyUserStep(state, action: PayloadAction<number>) {
            state.staf.step = action.payload;
        },
        modifyModeTheme(state, action: PayloadAction<boolean>) {
            state.interfaceState.darkMode = action.payload;
        },
        modifyDateFormat(state, action: PayloadAction<string>) {
            state.interfaceState.dateFormat = action.payload as EDateFormat;
        },
        modifyTimeFormat(state, action: PayloadAction<string>) {
            state.interfaceState.timeFormat = action.payload as ETimeFormat;
        },
        modifyLanguage(state, action: PayloadAction<string>) {
            state.interfaceState.language = action.payload;
        },
        modifyEmailNotifications(state, action: PayloadAction<boolean>) {
            state.interfaceState.notificationEmailEnabled = action.payload;
        },
        modifyPushNotifications(state, action: PayloadAction<boolean>) {
            state.interfaceState.notificationPushEnabled = action.payload;
        },
        modifyMotto(state, action: PayloadAction<IMotto>) {
            state.motto = action.payload;
        },
        modifyAuth(state, action: PayloadAction<IUser | null>) {
            state.auth = action.payload;
        },
        modifyShowHits(state, action: PayloadAction<boolean>) {
            state.interfaceState.hints = action.payload;
        },
        modifyMenuState(state, action: PayloadAction<number>) {
            state.interfaceState.menuState = action.payload;
        },
        modifyPresentMenu(state) {
            state.interfaceState.presentMenu = !state.interfaceState.presentMenu;
        },
        modifyPresentFlip(state) {
            state.interfaceState.presentFlip = !state.interfaceState.presentFlip;
        },
        setLastError(state, action: PayloadAction<string | null>) {
            state.lastError = action.payload;
        },
        modifyStafPart(state, action: PayloadAction<string>) {
            state.staf.page = 0;
            state.staf.part = action.payload;
        },
        modifyStafRole(state, action: PayloadAction<string>) {
            state.staf.page = 0;
            state.staf.role = action.payload;
        },
        modifyUserInterfaceState(state, action: PayloadAction<IInterfaceState>) {
            state.interfaceState = action.payload;
        },
        modifyGlobalPreference(state, action: PayloadAction<IGlobalProfile>) {
            state.globalPreferenceState = action.payload;
        },
        modifyURLState(state, action: PayloadAction<string>) {
            state.interfaceState.pathURL = action.payload;
        },
        modifyLeftFlip(state) {
            if (!state.interfaceState.rightFlip) {
                state.interfaceState.leftFlip = false;
            } else {
                state.interfaceState.leftFlip = !state.interfaceState.leftFlip;
            }
        },
        modifyRightFlip(state, action: PayloadAction<boolean>) {
            state.interfaceState.rightFlip = action.payload;
            if (!action.payload) {
                state.interfaceState.leftFlip = false;
            }
        },
        sethostWithDomain(state, action: PayloadAction<string>) {
            state.host = action.payload;
        },
        setCorporateEmailHostDomain(state, action: PayloadAction<string>) {
            state.corporateEmailHostDomain = action.payload;
        },
        modifyNotificationState(state, action: PayloadAction<boolean>) {
            state.modifyNotification = action.payload;
        },
        modifyNotificationStateCount(state, action: PayloadAction<boolean>) {
            state.modifyNotificationCount = action.payload;
        },
        modifyTockens(state, action: PayloadAction<any>) {
            if (action.payload) {
                state.tockens = action.payload
            };
        },
        modifyStafPage(state, action: PayloadAction<number>) {
            state.staf.page = action.payload
        },
        modifyCustomerPage(state, action: PayloadAction<number>) {
            state.customer.page = action.payload
        },
        modifyAutoPage(state, action: PayloadAction<number>) {
            state.auto.page = action.payload
        },
        modifyLoadPage(state, action: PayloadAction<number>) {
            state.load.page = action.payload
        },
        modifyWarehousePage(state, action: PayloadAction<number>) {
            state.warehouse.page = action.payload
        },
        modifyCustomerSizePage(state, action: PayloadAction<number>) {
            state.customer.page = 0;
            state.customer.size = action.payload;
        },
        modifyStafSizePage(state, action: PayloadAction<number>) {
            state.staf.page = 0;
            state.staf.size = action.payload;
        },
        modifyAutoSizePage(state, action: PayloadAction<number>) {
            state.auto.page = 0;
            state.auto.size = action.payload;
        },
        modifyLoadSizePage(state, action: PayloadAction<number>) {
            state.load.page = 0;
            state.load.size = action.payload;
        },
        modifyWarehouseSizePage(state, action: PayloadAction<number>) {
            state.warehouse.page = 0;
            state.warehouse.size = action.payload;
        },
        modifyWarehouseRefresh(state) {
            state.warehouse.refresh = !state.warehouse.refresh;
        },
        modifyStafRefresh(state) {
            state.staf.refresh = !state.staf.refresh;
        },
        modifyAutoRefresh(state) {
            state.auto.refresh = !state.auto.refresh;
        },
        modifyLoadRefresh(state) {
            state.load.refresh = !state.load.refresh;
        },
        modifyLoadTypesRefresh(state) {
            state.load.refreshTypes = !state.load.refreshTypes;
        },
        modifyActiveLoadRefresh(state) {
            state.activeLoad.refresh = !state.activeLoad.refresh;
        },
        modifyActiveLoadSizePage(state, action: PayloadAction<number>) {
            state.activeLoad.page = 0;
            state.activeLoad.size = action.payload;
        },
        modifyActiveLoadPage(state, action: PayloadAction<number>) {
            state.activeLoad.page = action.payload
        },
        modifyAutoPart(state, action: PayloadAction<string>) {
            state.auto.page = 0;
            state.auto.part = action.payload;
        },
        modifyWarehousePart(state, action: PayloadAction<string>) {
            state.warehouse.page = 0;
            state.warehouse.part = action.payload;
        },
        modifyLoadPart(state, action: PayloadAction<string>) {
            state.load.page = 0;
            state.load.part = action.payload;
        },
        modifyContactPart(state, action: PayloadAction<string>) {
            state.load.page = 0;
            state.load.part = action.payload;
        },
        modifyCustomerPart(state, action: PayloadAction<string>) {
            state.customer.page = 0;
            state.customer.part = action.payload;
        },

        modifyCustomerRole(state, action: PayloadAction<string>) {
            state.customer.page = 0;
            state.customer.role = action.payload;
        },
        modifyWarhouseReputation(state, action: PayloadAction<string>) {
            state.warehouse.page = 0;
            state.warehouse.reputation = action.payload;
        },
        modifyAutoRole(state, action: PayloadAction<string>) {
            state.auto.page = 0;
            state.auto.role = action.payload;
        },
        modifyWarehouseRole(state, action: PayloadAction<ITypeWarehouse[]>) {
            state.warehouse.page = 0;
            state.warehouse.role = action.payload;
        },
        modifyLoadRole(state, action: PayloadAction<string[]>) {
            state.load.page = 0;
            state.load.statuses = action.payload;
        },
        modifyWarehouseGrid(state, action: PayloadAction<boolean>) {
            state.warehouse.grid = action.payload;
        },
        modifyTransportRefresh(state) {
            state.auto.refresh = !state.auto.refresh;
        },
        modifyCustomerRefresh(state) {
            state.customer.refresh = !state.customer.refresh;
        },

        setSecure(state, action: PayloadAction<ISecure>) {
            state.secure = action.payload;
        },


    },
    extraReducers: (builder) => {
        builder
            .addCase(addNotification.fulfilled, (state) => {
                state.modifyNotification = true;
            })

    }
})

export const {
    modifyChatMessageReadRefresh,
    modifyChatAutoRead,
    modifyChatLastMessage,
    modifyChatConversationId,
    modifyChatPartnerId,
    modifyUserStep,
    modifyPushNotifications,
    modifyEmailNotifications,
    modifyLanguage,
    modifyLoadRole,
    modifyLoadPart,
    modifyWarehouseGrid,
    modifyWarhouseReputation,
    modifyCustomerRole,
    modifyWarehouseRefresh,
    modifyWarehousePart,
    modifyWarehousePage,
    modifyWarehouseSizePage,
    modifyWarehouseRole,
    modifyContactPart,
    modifyLoadPage,
    modifyLoadSizePage,
    modifyLoadRefresh,
    modifyLoadTypesRefresh,
    modifyActiveLoadRefresh,
    modifyActiveLoadSizePage,
    modifyActiveLoadPage,
    modifyCustomerPart,
    modifyCustomerPage,
    modifyCustomerSizePage,
    modifyCustomerRefresh,
    setSecure,
    modifyAutoPage,
    modifyAutoSizePage,
    modifyTransportRefresh,
    modifyAutoRole,
    modifyAutoPart,
    modifyAutoRefresh,
    modifyStafRefresh,
    modifyChatRefresh,
    modifyStafSizePage,
    modifyStafPage,
    modifyTockens,
    modifyNotificationState,
    modifyNotificationStateCount,
    sethostWithDomain,
    setCorporateEmailHostDomain,
    modifyLeftFlip,
    modifyRightFlip,
    modifyURLState,
    modifyUserInterfaceState,
    modifyStafRole,
    modifyStafPart,
    modifyPresentFlip,
    modifyPresentMenu,
    modifyShowHits,
    modifyMenuState,
    modifyModeTheme,
    modifyDateFormat,
    modifyTimeFormat,
    modifyMotto,
    modifyAuth,
    modifyGlobalPreference,
    setLastError
} = cnfSlice.actions;
export default cnfSlice.reducer;