import { IResponseAutocomplete } from "../../../models/IUser";
import styles from "./ReportListUserInfo.module.scss"
interface IProps {

    fontSize: number,
    user: IResponseAutocomplete[]
}


const ReportListUserInfo = (props: IProps) => {
    const fontSize = props.fontSize;
    const  user  = props.user
    
    return (
        <>
            <div style={{
                fontSize: fontSize.toString() + "px",
                display: "flex",
                flexDirection: "column" as 'column',
                marginLeft: "35px",
                width: "90%",
                gap: "7px"
            }}>
                {user.map((e, i: number) =>
                    <div key={i}
                        className={styles.firsAndSecondName}
                    >
                        {e.firstName}  {e.lastName}
                    </div>
                )}
            </div>
        </>
    )
}
export default ReportListUserInfo;