export enum EAction {
    CREATE = "CREATE",
    READ = "READ",
    UPDATE = "UPDATE",
    DELETE = "DELETE",
    NAVIGATE = "NAVIGATE",
    LOGIN = "LOGIN",
    LOGOUT = "LOGOUT",
    ARCHIVED = "ARCHIVED",
    DEFAULT = "DEFAULT"
};

export enum EExpretions {
    EQUALS = "equals",
    LIKE = "like",
    LESS_EQUAL_THAN = "lessEqualThan",
    GREATER_EQUAL_THAN = "greaterEqualThan",
    GREATER_THAN = "greaterThan",
    LESS_THAN = "lessThan",
    BETWEEN_DATE = "betweenDate"
};

export enum EStatus {
    enabled = "staf",
    temporary = "guest",
    archived = "arhive",
    disabled = "inactive",
    inProgress="inProgress"
}

export enum EAutoStatus {
    enabled = "ENABLED",
    temporary = "TEMPORARY",
    archived = "ARHIVED",
    disabled = "DISABLED",
    inProgress="IN_PROGRESS"
}

export enum EIncomeType {
    HOURLY = "HOURLY",
    CENTS_PER_MILE = "CENTS_PER_MILE",
    PERCENTAGE = "PERCENTAGE",
    DROP_PICKUPS = "DROP_PICKUPS"
    
}

export enum ENotificationType {
    REQUARED='REQUARED',
    ADDITIONAL='ADDITIONAL',
    ERROR='ERROR'	
}

export enum EButtonColor {
    inherit='inherit',
    primary='primary', 
    secondary='secondary',
    success='success',
    error='error', 
    info='info',
    warning='warning' 
}
