import { Link, Rating, Tooltip, Typography } from '@mui/material';
import './customer.module.scss';
import { ICustomerLoadsModel, ICustomerDetails } from '../../models/CustomerModels';
import { useEffect, useState } from 'react';
import CustomerService from '../../services/CustomerService';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { curency } from '../../helpers/textHelper';

interface IProps {
    customer: ICustomerDetails | null;
}

const CustomerLoadsComponent = (props: IProps) => {
    const { customer } = props
    const [state, setState] = useState<ICustomerLoadsModel[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (customer) {
            (async () => {
                const [error, response] = await CustomerService.getAllCustomerLoads(customer.uuid)
                if (response) {
                    let rs = response.data.body;
                    const st: ICustomerLoadsModel[] = rs.map((e: ICustomerLoadsModel) => (
                        {
                            ...e,
                            id: e.uuid
                        })
                    )
                    setState(st)
                }

            })()
        }
    }, [customer]);
    const handleRedirectLoad = (s: string) => {
        navigate('/load/' + s)
    }

    const columns: GridColDef[] = [
        {
            field: "1", headerName: 'IDNO', sortable: false, filterable: false, disableColumnMenu: true, width: 150, headerAlign: "center",
            renderCell: (cellValues: any) => {
                return (
                    <>
                        <Tooltip
                            title={cellValues.row.idno}
                            arrow={true}
                        >
                            <Link onClick={() => { handleRedirectLoad(cellValues.row.uuid) }} href="#" underline="hover" fontSize={'11px'} >
                                {cellValues.row.idno}
                            </Link>
                        </Tooltip>
                    </>
                );
            }
        },
        {
            field: "2", headerName: 'AMOUNT', sortable: false, filterable: false, disableColumnMenu: true, width: 105, headerAlign: "center",
            renderCell: (cellValues: any) => {
                return (
                    <>
                        <Tooltip
                            title={cellValues.row.amount + ' ' + curency}
                            arrow={true}
                        >
                            <Typography variant={'body2'} fontSize={'11px'}>{cellValues.row.amount + ' ' + curency} </Typography>
                        </Tooltip>
                    </>
                );
            }
        },
        {
            field: "3", headerName: 'STATUS', sortable: false, filterable: false, disableColumnMenu: true, width: 115, headerAlign: "center",
            renderCell: (cellValues: any) => {
                return (
                    <>

                        <Typography variant={'body2'} fontSize={'11px'}>{cellValues.row.status} </Typography>
                    </>
                );
            }
        },
        {
            field: "5", headerName: 'REVIEW', sortable: false, filterable: false, disableColumnMenu: true, width: 130, headerAlign: "center",
            renderCell: (cellValues: any) => {
                return (
                    <> {
                        <Rating
                            precision={0.5}
                            readOnly
                            value={cellValues.row.reviewMark}
                        />
                    }

                    </>
                );
            }
        }
    ];

    return (
        <>
            <div style={{ height: "500px", width: "570px" }}>
                <DataGrid
                    rows={state}
                    columns={columns}
                    disableSelectionOnClick
                    hideFooter
                />
            </div>
        </>
    )
}

export default CustomerLoadsComponent;