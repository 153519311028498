import { ICustomerDocumentUpdateDTO, IFavoritesCustomer } from "../models/CustomerModels";
import { API } from "./APIService";

class CustomerService {

    public static getAllCustomerLoads = async (uuid: string): Promise<any> => {
        const [error, response] = await API('GET', 'customer/loads/' + uuid);
        return [error, response];
    }
    public static saveCustomerStep = async (body: any): Promise<any> => {
        const [error, response] = await API('PUT', 'customer/step', body);
        return [error, response]
    }

    public static getMetadata = async (uuid: string): Promise<any> => {
        const [error, response] = await API('GET', 'customer/metadata/' + uuid);
        return [error, response];
    }

    public static updateStatusCustomer = async (body: any): Promise<any> => {
        const [error, response] = await API('PUT', 'customer/status', body);
        return [error, response]
    }
    public static getStatusCustomer = async (uuid: string): Promise<any> => {
        const [error, response] = await API('GET', 'customer/status/' + uuid);
        return [error, response]
    }

    public static getAutocomplete = async (s: string): Promise<any> => {
        const [error, response] = await API('GET', 'customer/autocomplete/' + s,);
        let st: IFavoritesCustomer[] = [];
        if (response) {
            st = response.data.body;
        }
        return [error, st]
    }

    public static deleteDocument = async (uuid: string): Promise<any> => {
        const [error, response] = await API('DELETE', 'customer/document/' + uuid);
        return [error, response];
    }

    public static deleteFileInDocuments = async (uuid: string): Promise<any> => {
        const [error, response] = await API('DELETE', 'customer/document/attachment/' + uuid);
        return [error, response];
    }

    public static deleteCustomer = async (uuid: string): Promise<any> => {
        const [error, response] = await API('DELETE', 'customer/' + uuid);
        return [error, response]
    }

    public static updateFileInDocument = async (body: any): Promise<any> => {
        const [error, response] = await API('PUT', 'customer/document/attachment', body);
        return [error, response]
    }

    public static createDocument = async (body: any): Promise<any> => {
        const [error, response] = await API('POST', 'customer/document', JSON.stringify(body));
        return [error, response]
    }

    public static updateDocument = async (body: ICustomerDocumentUpdateDTO): Promise<any> => {
        const [error, response] = await API('PUT', 'customer/document', JSON.stringify(body));
        return [error, response]
    }

    public static getDocuments = async (uuid: string): Promise<any> => {
        const [error, response] = await API('GET', 'customer/document/' + uuid);
        return [error, response];
    }

    public static getContactsContactStep = async (uuid: string): Promise<any> => {
        const [error, response] = await API('GET', 'customer/contact/' + uuid);
        return [error, response];
    }

    public static getRegistrationCustomerStep = async (uuid: string): Promise<any> => {
        const [error, response] = await API('GET', 'customer/registration/' + uuid);
        return [error, response];
    }

    public static getDetailsCustomer = async (uuid: string): Promise<any> => {
        const [error, response] = await API('GET', 'customer/' + uuid);
        return [error, response];
    }

    public static getStatistics = async (): Promise<any> => {
        const [error, response] = await API('GET', 'customer/count');
        return [error, response];
    }

    public static addCustomer = async (body: Object): Promise<any> => {
        const [error, response] = await API('POST', 'customer', JSON.stringify(body));
        return [error, response];
    }

    public static saveRegistration = async (body: Object): Promise<any> => {
        const [error, response] = await API('PUT', 'customer/registration', JSON.stringify(body));
        return [error, response];
    }

    public static saveContacts = async (body: Object): Promise<any> => {
        const [error, response] = await API('PUT', 'customer/contact', JSON.stringify(body));
        return [error, response];
    }

    public static saveStep = async (body: Object): Promise<any> => {
        const [error, response] = await API('PUT', 'customer/step', JSON.stringify(body));
        return [error, response];
    }

    public static getAllCustomers = async (): Promise<any> => {
        const body = {}
        const [error, response] = await API('POST', 'customer/all', body);
        return [error, response];
    }

    public static filterBy = async (body: Object): Promise<any> => {
        const [error, response] = await API('POST', 'customer/all', body);
        return [error, response];
    }

    public static isValidIDNO = async (idno: string): Promise<any> => {
        const [error, response] = await API('GET', 'customer/available/idno/' + idno);
        return [error, response];
    }

    public static isValidName = async (name: string): Promise<any> => {
        const [error, response] = await API('GET', 'customer/available/name/' + name);
        return [error, response];
    }

    public static getFavorites = async (): Promise<any> => {
        const [error, response] = await API('GET', 'customer/favorite');
        return [error, response];
    }

    public static getCustomerReview = async (uuid: string): Promise<any> => {
        const [error, response] = await API('GET', 'customer/review/' + uuid);
        return [error, response]
    }

    public static getCustomerSummary = async (uuid: string): Promise<any> => {
        const [error, response] = await API('GET', 'customer/summary/' + uuid);
        return [error, response]
    }

    public static getPendingCustomers = async (): Promise<any> => {
        const [error, response] = await API('GET', 'customer/pending');
        return [error, response]
    }
}

export default CustomerService;