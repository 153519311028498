import { useTranslation } from "react-i18next";
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DownloadIcon from '@mui/icons-material/Download';
import StarsIcon from '@mui/icons-material/Stars';
import { AppDispatch, RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { modifyRightFlip } from "../../store/reducers/cnfSlice";
import { useNavigate } from "react-router";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useRef } from "react";
import ReactToPrint from "react-to-print";
import { IAutoDetails } from "../../models/TransportModels";
import NoPhotoComponent from "../CustomComponentModule/NoPhotoComponent";
import ImageBase64ServerComponent from "../CustomComponentModule/ImageBase64ServerComponent";
import { addFavoritesAutos } from "../../store/reducers/favoritesSlice";
import GenericCRUDService from "../../services/GenericCRUDService";

const styled = {
  container: { display: "flex", gap: "20px" },
  leftPart: { width: "200px", padding: "0px 10px 50px 10px", height: "1060px" },
  centerPart: { width: "100%", padding: "20px 10px 50px 10px" },
  rigthPart: { width: "450px", padding: "5px 10px 50px 10px" },
  item: { width: "40px" },
  box: { border: "1px solid silver", textAlign: "center", borderRadius: "3px" },
  icon: { fontSize: "28px", cursor: "pointer" },
  button: { border: "1px solid silver" },
  info: {},
  rows: { display: "flex", gap: "2px" },
  boxis: { width: "200px", padding: "4px", fontSize: "12px", overflow: "hidden" },
}

interface IPropsCoverFaceTransportComponent {
  auto: IAutoDetails | null
}
const CoverFaceTransportComponent = (props: IPropsCoverFaceTransportComponent) => {

  const { auto } = props;
  const { t } = useTranslation();
  const { hints } = useSelector((state: RootState) => state.cnfSlice.interfaceState);
  const componentRef = useRef<HTMLDivElement | null>(null);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleClose = () => {
    dispatch(modifyRightFlip(false));
    navigate('/transport');
  }

  const handlerAddToFavorite = () => {
    if (auto) {
      dispatch(addFavoritesAutos([auto.uuid]));
    }
  }

  const hendlerExcel = () => {
    let body = {
      criteria: [
        {
          property: "uuid",
          value: auto?.uuid,
          expression: "EQUALS"
        }
      ]
    }

    GenericCRUDService.downloadReport('transport/report', body)
  }
  return (
    <>
      <Box sx={{ padding: 2, marginTop: "1", display: "flex" }}>

        <div style={{ display: "flex", alignItems: "center" }}>
          {auto?.mainImage && auto?.mainImage !== ''
            ? <ImageBase64ServerComponent base64={auto?.mainImage} url={auto?.mainImage ? auto?.mainImage : ""} w={60} h={60} />
            : <NoPhotoComponent width={45} heigth={45} />}

        </div>

        <div style={{ display: "flex", flexDirection: "column", marginLeft: "35px" }}>

          <Box style={styled.rows}>
            <Typography variant="subtitle1" align="left" >
              <b>{auto?.licensePlate}</b>
            </Typography>
          </Box>

          <Box style={styled.rows}>
            <Typography align="left" style={styled.boxis}>({auto?.brand + ' ' + auto?.model})</Typography>
          </Box>

        </div>

        <div style={{ display: "flex", alignItems: "center", marginLeft: "40px" }}>
          <div style={{ paddingLeft: "10px", display: "flex", gap: "10px", height: "40px", borderLeft: "1px dashed silver", justifyContent: "space-between", width: "350px" }}>
            {!auto?.metadata.completed ?
              <Typography variant='subtitle2' sx={{ padding: "3px", paddingTop: "9px" }} color='red'>INCOMPLETE TRANSPORT</Typography>
              : <div>
                <Tooltip
                  title={(hints) ? t("Add transport to favorites") : ''}
                  placement={"top"}
                  arrow={true}
                >
                  <IconButton
                    onClick={handlerAddToFavorite}
                  >
                    <StarsIcon style={styled.icon} />
                  </IconButton>
                </Tooltip>

                <Tooltip title={(hints) ? t("pdf file whith list") : ''} placement={"top"} arrow={true}>
                  <span>
                    <ReactToPrint
                      trigger={() => <IconButton disabled >
                        <PictureAsPdfIcon style={styled.icon} />
                      </IconButton>}
                      content={() => componentRef.current}
                    />
                  </span>
                </Tooltip>

                <Tooltip
                  title={(hints) ? t("Download XLS report for selected transport") : ''}
                  placement={"top"}
                  arrow={true}
                  onClick={hendlerExcel}
                >
                  <IconButton>
                    <DownloadIcon style={styled.icon} />
                  </IconButton>
                </Tooltip>
              </div>}
            <div style={{ width: "0px", height: "0px", padding: 0, margin: 0, overflow: "hidden" }}>
              <div ref={componentRef}>
              </div>
            </div>
            <Tooltip
              title={(hints) ? t("Close and return to the list of transports") : ''}
              placement={"top"}
              arrow={true}
            >
              <IconButton onClick={handleClose} >
                <HighlightOffIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </Box>
    </>
  )
}

export default CoverFaceTransportComponent;