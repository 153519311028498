import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { compareValue } from "../../helpers/generalHelper";
import ControlStateInCRUDComponent from "../CustomComponentModule/ControlStateInCRUDComponent";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import { modifyWarehouseRefresh } from "../../store/reducers/cnfSlice";
import { EWarehouseReputationLevel, EWarehouseStatus, IWarehouseDetails, WarehouseStatusRequestDto } from "../../models/WarehouseModels";
import WarehouseService from "../../services/WarhouseService";
import { IUserMetadata } from "../../models/IUser";



interface IPropsRoleAndSrtaus {
  w: IWarehouseDetails | null,
  stat: WarehouseStatusRequestDto | null,
  metadata: IUserMetadata | null,
  refreshAfretSave: () => void;
  snackMsg: (text: string) => void
}

const StatusWarehouseComponent = (props: IPropsRoleAndSrtaus) => {

  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const { w, stat, metadata } = props;

  const [status, setStatus] = useState<string>('');
  const [reputationLevel, setReputationLevel] = useState<string>('');

  const [error, setError] = useState<number>(0);
  const [textErrors, setTextErrors] = useState<Array<string>>([])
  const [save, setSave] = useState<boolean>(false);



  const handleChangeStatus = (event: any) => {
    setStatus(event.target.value as string);
  };
  const handleChangeReputationLevel = (event: any) => {
    setReputationLevel(event.target.value as string);
  };

  const handlerSaveStatus = () => {
    setSave(true);
  }

  useEffect(() => {

    if (save) {
      (async () => {

        if (w) {
          let body: WarehouseStatusRequestDto = {
            uuid: w?.uuid,
            status: status,
            reputationLevel: reputationLevel
          }

          const [error, data] = await WarehouseService.saveWarehouseStatus(body);
          if (data) {
            handlerReset();
            setError(0);
            props.snackMsg('Status and reputation data was successfully saved for ' + w?.name);
            dispatch(modifyWarehouseRefresh());
          }

          if (error) { }

        }
      })()
    }
    setSave(false);
  }, [save])

  useEffect(() => {
    if (w) {
      setReputationLevel((stat?.reputationLevel) ? stat.reputationLevel.toUpperCase() : "");
      setError(0);
    }
  }, [w]);
  useEffect(() => {
    if (stat) {
      setStatus((stat?.status) ? stat?.status.toUpperCase() : "");
    }
  }, [stat]);
  const handlerReset = () => {
    if (w) {
      props.refreshAfretSave();
    }
  }

  useEffect(() => {

    let er: boolean = false;
    let md: boolean = false;
    let ter: string[] = [];

    if (!compareValue(status, stat?.status)) {
      md = true;
    }

    if (!compareValue(reputationLevel, stat?.reputationLevel)) {
      md = true;
    }


    setTextErrors(ter);
    if (er) {
      setError(-1);
    }
    if ((!er) && (md)) setError(1)
    if ((!er) && (!md)) setError(0)

  }, [status, reputationLevel])


  return (
    <>
      <div style={{ flex: "1 1 auto", display: "flex", gap: 10, width: "420px", padding: 0, marginBottom: "-20px" }}>
        {<div
          style={{
            display: "flex",
            marginLeft: "14px",
            justifyContent: "center",
            paddingTop: "10px"
          }
          }
        >

          {metadata?.completed &&
            <div>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "60px" }}>
                <Typography variant="body2" sx={{ width: "60px" }}>STATUS</Typography>
                <ToggleButtonGroup
                  color="primary"
                  value={status}
                  exclusive
                  onChange={handleChangeStatus}
                  sx={{ width: "420px", height: "50px" }}
                >
                  <ToggleButton style={{ width: "120px", fontSize: "12px" }} value={EWarehouseStatus.ACTIVE} >{EWarehouseStatus.ACTIVE}</ToggleButton>
                  <ToggleButton style={{ width: "120px", fontSize: "12px" }} value={EWarehouseStatus.INACTIVE} >{EWarehouseStatus.INACTIVE}</ToggleButton>
                </ToggleButtonGroup>
              </div>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "60px", marginTop: "30px" }}>
                <Typography variant="body2" sx={{ width: "60px" }}>REPUTATION</Typography>
                <ToggleButtonGroup
                  color="primary"
                  value={reputationLevel}
                  exclusive
                  onChange={handleChangeReputationLevel}
                  sx={{ width: "420px", height: "50px" }}
                >
                  <ToggleButton style={{ width: "120px", fontSize: "12px" }} value={EWarehouseReputationLevel.VIP} >{EWarehouseReputationLevel.VIP}</ToggleButton>
                  <ToggleButton style={{ width: "120px", fontSize: "12px" }} value={EWarehouseReputationLevel.STANDARD} >{EWarehouseReputationLevel.STANDARD}</ToggleButton>
                  <ToggleButton style={{ width: "120px", fontSize: "12px" }} value={EWarehouseReputationLevel.POOR} >{EWarehouseReputationLevel.POOR}</ToggleButton>
                </ToggleButtonGroup>
              </div>

            </div>

          }
        </div>
        }
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "20px", marginTop: "20px" }}  >
          <ControlStateInCRUDComponent
            error={error}
            textErrors={textErrors}
            handlerReset={handlerReset}
            handlerSave={handlerSaveStatus}
          />
        </div>

      </div>




    </>
  )
}
export default StatusWarehouseComponent;
