import { Typography } from "@mui/material";
import SCViewList from "../../styledComponents/sharedComponent/SCViewList";
import SCViewTab from "../../styledComponents/sharedComponent/SCViewTab";
import styled from '@emotion/styled'
import { useEffect, useState } from "react";
import { IResponseHotLoad } from "../../models/RoutesModels";
import RouteServices from "../../services/RouteServices";
import { useNavigate } from "react-router-dom";

const HotLoadComponent = () => {
  const [state, setState] = useState<IResponseHotLoad[]>([]); 
  const navigate = useNavigate();

  
  useEffect(() => {
    (async () => {
        const [,response] = await RouteServices.getHotLoad();
        if (response) { 
            setState(response.data.body)
        }

    })()
  }, []);

  const handleRedirect = (uuid:string) => { 
    navigate('/load/'+uuid)

  }
    return (
        <>

            <SCViewList column>
                <SCDIv>
                    <SCViewList center>
                        <Typography variant="body2">HOT LOADS</Typography>
                    </SCViewList>
                </SCDIv>
                <SCDIv>
                    <SCViewList gap={20} center>
                        <Typography variant="subtitle2">IDNO</Typography>
                        <Typography variant="subtitle2">PROGRESS </Typography>
                        <Typography variant="subtitle2">ITEMS</Typography>
                    </SCViewList>
                </SCDIv>



                {state.map((r, i) =>
                    <div key={i}>
                        <SCViewTab gap={5}>
                            <div onClick={()=>{handleRedirect(r.uuid)}} className='item'>{r.idno}</div>
                            <div className='item'>{r.progress.completed + ' of ' + r.progress.total}</div>
                            <div className='item'>{r.hotDetails.hotItems}</div>
                        </SCViewTab>

                    </div>

                )}



            </SCViewList >
        </>
    )
}
export default HotLoadComponent;

const SCDIv = styled.div`
                border: 1px solid silver;
                padding:10px;
                `