import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { IconButton, Typography, useTheme } from '@mui/material';
import { EStatusStafServer } from '../../models/IUser';
import { useEffect, useState } from 'react';
import { EVehicleType, IAutoDetails, ITransportSummary } from '../../models/TransportModels';
import TransportService from '../../services/TransportService';
import './transport.scss';
import { getDateFormat } from '../../helpers/dateHelper';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import SCViewList from '../../styledComponents/sharedComponent/SCViewList';
import styled from '@emotion/styled'

interface IProps {
    auto: IAutoDetails;
    toStep: (step: number) => void;
}

export default function ViewTransportTableReportComponent(props: IProps) {
    const { auto } = props;
    const theme = useTheme();
    const [state, setState] = useState<ITransportSummary | null>(null)
    const { t } = useTranslation();
    const { dateFormat } = useSelector((state: RootState) => state.cnfSlice.interfaceState);
    const { measureConsumption, measureMass, measureEnginePower, measureEngineVolume, measureSize } = useSelector((state: RootState) => state.cnfSlice.globalPreferenceState);

    const SCDIv = styled.div`
  
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 50px;
    border-left: 1px solid silver;
    justify-content: space-between;
    width: 18%;
    min-width: 180px;
                 `
    useEffect(() => {
        if (auto) {
            (async () => {
                const [error, res] = await TransportService.getTransportSummary(auto.uuid);
                if (res) {
                    setState(res.data.body);
                }
                if (error) { }
            })()
        }
    }, [auto.uuid]);

    const hendlerToEdit = (step: number) => {
        if (!auto.licensePlate) { props.toStep(0) }
        else { props.toStep(step - 1) }
    }

    const boxSX = {
        color: theme.palette.action.disabled,
        "&:hover": {
            color: theme.palette.primary.main
        },
    }

    const returnStatus = (): string => {
        if (state?.status) {
            let status: string = (state?.status === EStatusStafServer.TEMPORARY)
                ? `${t(state?.status)} [${getDateFormat(state?.activeFromDate, dateFormat)} - ${getDateFormat(state?.activeToDate, dateFormat)}]`
                : t(state?.status)
            return status
        } else { return '' }
    }

    return (
        <div>
            <table style={{ width: "100%", marginTop: "10px" }}>
                <tbody>
                    <tr>
                        <td className={'cell but'}>

                        </td>
                        <td className={'cell label'}>
                            <Typography color={'silver'} variant='subtitle2'>NAME</Typography>
                        </td>
                        <td className={'cell vals'}>
                            <Typography variant='subtitle2'>{`${state?.brand} ${state?.model} ${state?.year}`}</Typography>
                        </td>
                    </tr>

                    <tr>
                        <td className={'cell but'}>

                        </td>
                        <td className={'cell label'}>
                            <Typography color={'silver'} variant='subtitle2'>TYPE</Typography>
                        </td>
                        <td className={'cell vals'}>
                            <Typography variant='subtitle2'>{state?.type}</Typography>
                        </td>
                    </tr>

                    <tr>
                        <td className={'cell but'}>
                            <IconButton size={'small'} onClick={() => hendlerToEdit(1)} >
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={'cell label'}>
                            <Typography color={state?.transportNumber ? 'silver' : 'error'} variant='subtitle2'>NUMBER</Typography>
                        </td>
                        <td className={'cell vals'}>
                            <Typography variant='subtitle2'>{state?.transportNumber ? state?.transportNumber : 'N/A'}</Typography>
                        </td>
                    </tr>

                    <tr>
                        <td className={'cell but'}>
                            <IconButton size={'small'} onClick={() => hendlerToEdit(1)} >
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={'cell label'}>
                            <Typography color={state?.licensePlate ? 'silver' : 'error'} variant='subtitle2'>LICENSE PLATE</Typography>
                        </td>
                        <td className={'cell vals'}>
                            <Typography variant='subtitle2'>{state?.licensePlate ? state?.licensePlate : 'N/A'}</Typography>
                        </td>
                    </tr>

                    <tr>
                        <td className={'cell but'}>

                        </td>
                        <td className={'cell label'}>
                            <Typography color={'silver'} variant='subtitle2'>VIN</Typography>
                        </td>
                        <td className={'cell vals'}>
                            <Typography variant='subtitle2'>{state?.vin}</Typography>
                        </td>
                    </tr>

                    <tr>
                        <td className={'cell but'}>
                            <IconButton size={'small'} onClick={() => hendlerToEdit(1)} >
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={'cell label'}>
                            <Typography color={state?.color ? 'silver' : 'error'} variant='subtitle2' >
                                COLOR
                            </Typography>
                        </td>
                        <td className={'cell vals'}>
                            <Typography variant='subtitle2' key='color2Typo'>
                                {state?.color
                                    ? <div style={{ width: "25px", height: "25px", background: state?.color }}></div>
                                    : 'N/A'}
                            </Typography>
                        </td>
                    </tr>

                    <tr>
                        <td className={'cell but'}>

                        </td>
                        <td className={'cell label'}>
                            <Typography color={'silver'} variant='subtitle2'>AVAILABLE</Typography>
                        </td>
                        <td className={'cell vals'}>
                            <Typography variant='subtitle2'>{state?.available ? 'YES' : 'NO'}</Typography>
                        </td>
                    </tr>

                    <tr>
                        <td className={'cell but'}>
                            <IconButton size={'small'} onClick={() => hendlerToEdit(4)}
                            >
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={'cell label'}>
                            <Typography color={state?.status ? 'silver' : 'error'} variant='subtitle2' >
                                STATUS
                            </Typography>
                        </td>
                        <td className={'cell vals'}>
                            <Typography variant='subtitle2'>
                                {returnStatus()}
                            </Typography>
                        </td>
                    </tr>

                    <tr>
                        <td className={'cell but'}>

                        </td>
                        <td className={'cell label'}>
                            <Typography color={'silver'} variant='subtitle2'>SIZE</Typography>
                        </td>
                        <td className={'cell vals'}>
                            <Typography variant='subtitle2'>{`W${state?.width} ${measureSize} x H${state?.height} ${measureSize} x L${state?.length} ${measureSize}`}</Typography>
                        </td>
                    </tr>

                    <tr>
                        <td className={'cell but'}>

                        </td>
                        <td className={'cell label'}>
                            <Typography color={'silver'} variant='subtitle2'>WEIGHT</Typography>
                        </td>
                        <td className={'cell vals'}>
                            <SCViewList >
                                <div style={{
                                    paddingLeft: "10px", display: "flex", flexDirection: "column", gap: "10px", height: "50px", justifyContent: "space-between", minWidth: "180px"
                                }}>
                                    <SCViewList>
                                        <div style={{ width: "70px" }}>
                                            <Typography variant="body2" >EMPTY:</Typography>
                                        </div>
                                        <Typography variant="body2" >{`${state?.weight} ${measureMass}`}</Typography>
                                    </SCViewList>
                                    <SCViewList>
                                        <div style={{ width: "70px" }}>
                                            <Typography variant="body2" >LOADING: </Typography>
                                        </div>
                                        <Typography variant="body2" >{`${state?.loadingWeight} ${measureMass}`}</Typography>
                                    </SCViewList>
                                </div>
                                <SCDIv>
                                    <SCViewList>
                                        <div style={{ minWidth: "160px" }}>
                                            <Typography variant="body2" >GROSS:</Typography>
                                        </div>
                                        <Typography variant="body2" style={{ minWidth: "90px" }}>{`${state?.grossWeight} ${measureMass}`}</Typography>
                                    </SCViewList>
                                    <SCViewList>
                                        <div style={{ minWidth: "160px" }}>
                                            <Typography variant="body2" >GROSS COMBINATION: </Typography>
                                        </div>
                                        <Typography variant="body2" style={{ minWidth: "90px" }}>{`${state?.grossCombinationWeight} ${measureMass}`} </Typography>
                                    </SCViewList>
                                </SCDIv>
                            </SCViewList>
                        </td>
                    </tr>

                    {(state?.type !== EVehicleType.TRAILER) &&
                        <tr>
                            <td className={'cell but'}>

                            </td>
                            <td className={'cell label'}>
                                <Typography color={'silver'} variant='subtitle2'>FUEL</Typography>
                            </td>
                            <td className={'cell vals'}>
                                <Typography variant='subtitle2'>{`${state?.fuelType} ${state?.fuelConsumption} ${measureConsumption}`}</Typography>
                            </td>
                        </tr >
                    }

                    {(state?.type !== EVehicleType.TRAILER) &&
                        <tr>
                            <td className={'cell but'}>

                            </td>
                            <td className={'cell label'}>
                                <Typography color={'silver'} variant='subtitle2'>TRANSMISSION</Typography>
                            </td>
                            <td className={'cell vals'}>
                                <Typography variant='subtitle2'>{state?.transmissionType}</Typography>
                            </td>
                        </tr >
                    }

                    {(state?.type !== EVehicleType.TRAILER) &&
                        <tr>
                            <td className={'cell but'}>

                            </td>
                            <td className={'cell label'}>
                                <Typography color={'silver'} variant='subtitle2'>ENGINE</Typography>
                            </td>
                            <td className={'cell vals'}>
                                <Typography variant='subtitle2'>{`${state?.engineCapacity} ${measureEngineVolume} ${state?.enginePower} ${measureEnginePower}`}</Typography>
                            </td>
                        </tr >
                    }

                    <tr key='documents'>
                        <td className={'cell but'} key='documents1'>
                            <IconButton size={'small'} onClick={() => hendlerToEdit(3)} disabled={!auto?.metadata.completed} >
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={'cell label'} key='documents2'>
                            <Typography
                                color={state?.annualDot && (state?.annualDotExpirationDate && (state?.annualDotExpirationDate > Date.now())) ? 'silver' : 'error'} variant='subtitle2' >
                                ANNUAL DOT
                            </Typography>
                        </td>
                        <td className={'cell vals'} key='documents3'>
                            <Typography variant='subtitle2'>
                                {(state?.annualDotExpirationDate)
                                    ?
                                    Date.now() > Number(state?.annualDotExpirationDate)
                                        ? `YES [expired on ${getDateFormat(state?.annualDotExpirationDate, dateFormat)}]`
                                        : `YES [expires on ${getDateFormat(state?.annualDotExpirationDate, dateFormat)}]`

                                    : 'N/A'
                                }
                            </Typography>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div >
    );
}