import React from 'react';
import { Client } from '@stomp/stompjs';
import { settings } from "../../configs/settings";
import { EChannelsType, ECommandType, ESocketType } from '../../models/SocketModels';

//TODO send the token in header instead of param URL
//TODO https://github.com/stomp-js/rx-stomp/issues/204
//TODO https://umes4ever.medium.com/real-time-application-using-websocket-spring-boot-java-react-js-flutter-eb87fe95f94f

interface SocketExport {
  message?: ECommandType,
  payload?: string,
}
interface IProps {
  email: string,
  callbackCommand: (command: ECommandType) => void;
  callbackChannels: (object: EChannelsType) => void;
}
class SocketsComponents extends React.Component {


  constructor(props: IProps) {

    const { email, callbackChannels, callbackCommand } = props
    super(props);
    this.state = {
      messages: 'You server message here.',
    };
    let onConnected = () => {
      console.log("Connected!!")
      client.subscribe('/api/ws/channel/' + email + '/command', function (msg) {
        if (msg.body) {
          var jsonBody = JSON.parse(msg.body);
          if (jsonBody) {
            callbackCommand(jsonBody);
          }

        }
      });

      // client.subscribe('/api/ws/channel/' + email + '/notification', function (msg) {
      //   if (msg.body) {
      //     var jsonBody = JSON.parse(msg.body);
      //     if (jsonBody) {
      //       callback({ payload: msg.body });
      //       console.log(jsonBody)
      //     }
      //   }
      // });

      client.subscribe('/api/ws/channel/' + email + '/chat/personal', function (msg) {
        if (msg.body) {
          var jsonBody = JSON.parse(msg.body);
          if (jsonBody) {
            let obj: EChannelsType = {
              type: ESocketType.CHAT,
              jsonBody: jsonBody
            }
            callbackChannels(obj)
          }
        }
      });
    }

    let onDisconnected = () => {
      console.log("Disconnected!!")
    }

    const getUrl = (): string => {
      const token = sessionStorage.getItem('access_token')
      const SOCKET_URL = settings.socketURL + '?Authorization=Bearer ' + token;
      return SOCKET_URL;
    }

    const client = new Client({
      brokerURL: getUrl(),
      reconnectDelay: 5000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000,
      onConnect: onConnected,
      onDisconnect: onDisconnected
    });

    client.activate();
  };

  render() {
    return (
      <div></div>
    );
  }
}
export default SocketsComponents;