import { useEffect, useState } from 'react'
import { APIFile } from '../../services/APIFile';
import DownloadIcon from '@mui/icons-material/Download';
import { CircularProgress, IconButton } from '@mui/material';
import { IFileDetailsResponse } from '../../models/FileModel';
import { FileDownload } from '@mui/icons-material';
import FileService from '../../services/FileService';

interface IProps {
	ent: IFileDetailsResponse,
	fileName: string;
}

const FileDownloadComponent = (props: IProps) => {

	const { url } = props.ent;
	const [down, setDown] = useState(false);

	const handlerDown = (b: boolean) => {
		(async () => {
			let listOfElem = url.split("/")
			let uuid = listOfElem[listOfElem.length - 1];
			FileService.dowloadFile("file/download/" + uuid)

		})()
	}




	return (
		<div>
			{!down ?
				<IconButton
					color={'info'}
					size={'small'}
					onClick={() => handlerDown(true)}
				>
					< DownloadIcon fontSize='medium' />
				</IconButton>
				: <CircularProgress size={20} />
			}
		</div>
	)
}

export default FileDownloadComponent;