export enum ESocketType {
    CHAT = 'chat',
    NOTIFICATION = 'notification'
}
export interface ECommandType {
    command: string,
    dateTime: number,
    entityId?: string
}

export interface EChannelsType { 
    type: string, 
    jsonBody: object
}