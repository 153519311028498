import { isBlankOrNA } from "../helpers/textHelper";
import { EmployeeCreateRequestDto, EmployeeResponseDto, EmployeeUpdateRequestDto } from "../models/EmployeeModels";
import { IStatus, TRoles } from "../models/IStaf";
import {
  IDocumentCreateDto,
  IDocumentRequestDto,
  IUserInfoResponseDto,
  IUserCreateRequestDto,
  UserAuthorityUpdateRequestDto,
  UserContactRequestDto,
  UserPersonalDataRequestDto,
  UserStatusRequestDto,
  UserStepRequestDto,
  UserUpdateRequestDto,
} from "../models/IUser";
import { API } from "./APIService";

class StafService {

  public static saveUserSatus = async (body: UserStatusRequestDto): Promise<any> => {
    const [error, response] = await API('PUT', 'user/status', JSON.stringify(body));
    return [error, response]
  }
  public static getUserStatus = async (uuid: string): Promise<any> => {
    const [error, response] = await API('GET', 'user/status/' + uuid);
    return [error, response];
  }
  public static getRegistrationData = async (uuid: string): Promise<any> => {
    const [error, response] = await API('GET', 'user/registrationData/' + uuid);
    return [error, response];
  }
  public static getUserContact = async (uuid: string): Promise<any> => {
    const [error, response] = await API('GET', 'user/contact/' + uuid);
    return [error, response];
  }
  public static saveUserContacts = async (body: UserContactRequestDto): Promise<any> => {
    const [error, response] = await API('PUT', 'user/contact', JSON.stringify(body));
    return [error, response]
  }
  public static saveUserpersonalData = async (body: UserPersonalDataRequestDto): Promise<any> => {
    const [error, response] = await API('PUT', 'user/registrationData', JSON.stringify(body));
    return [error, response]
  }
  public static saveUserRole = async (body: UserAuthorityUpdateRequestDto): Promise<any> => {
    const [error, response] = await API('PUT', 'user/authority', JSON.stringify(body));
    return [error, response]
  }
  public static saveUserStep = async (body: UserStepRequestDto): Promise<any> => {
    const [error, response] = await API('PUT', 'user/step', JSON.stringify(body));
    return [error, response]
  }
  public static deleteFileInDocuments = async (uuid: string): Promise<any> => {
    const [error, response] = await API('DELETE', 'user/document/attachment/' + uuid);
    return [error, response];
  }
  public static updateFileInDocument = async (body: any): Promise<any> => {
    const [error, response] = await API('PUT', 'user/document/attachment', body);
    return [error, response]
  }
  public static deleteDocuments = async (uuid: string): Promise<any> => {
    const [error, response] = await API('DELETE', 'user/document/' + uuid);
    return [error, response];
  }
  public static deleteUser = async (uuid: string): Promise<any> => {
    const [error, response] = await API('DELETE', 'user/' + uuid);
    return [error, response];
  }
  public static getCurrentStep = async (uuid: string): Promise<any> => {
    const [error, response] = await API('GET', 'user/step/' + uuid);
    return [error, response];
  }
  public static getDocuments = async (uuid: string): Promise<any> => {
    const [error, response] = await API('GET', 'user/document/' + uuid);
    return [error, response];
  }
  public static createDocument = async (body: IDocumentCreateDto): Promise<any> => {
    const [error, response] = await API('POST', 'user/document', JSON.stringify(body));
    return [error, response]
  }
  public static updateDocument = async (body: IDocumentRequestDto): Promise<any> => {
    const [error, response] = await API('PUT', 'user/document', JSON.stringify(body));
    return [error, response]
  }

  public static createUser = async (body: IUserCreateRequestDto): Promise<any> => {
    const [error, response] = await API('POST', 'user', JSON.stringify(body));
    return [error, response]
  }
  public static isTrueSistemMail = async (mail: string): Promise<any> => {
    const [error, response] = await API('GET', 'user/available/email/' + mail);
    return [error, response];
  }

  public static isTrueContact = async (contact: string): Promise<any> => {
    const [error, response] = await API('GET', 'user/available/contact/' + contact);
    return [error, response];
  }

  public static isTrueSistemPhoneNumber = async (phoneNumber: string, userId: string): Promise<any> => {
    const [error, response] = await API('GET', 'user/available/contact/' + phoneNumber + '/');
    return [error, response];
  }

  public static isValidUserColor = async (s: string): Promise<any> => {
    let color = s.replace('#', '');
    const [error, response] = await API('GET', 'user/available/color/' + color,);
    let st: EmployeeResponseDto | undefined;
    if (response) {
      st = response.data.body;
    }
    return [error, st]
  }
  public static getAllRole = async (): Promise<any> => {
    const [error, response] = await API('GET', 'role');
    let roles: TRoles | undefined;
    if (response) {
      roles = response.data.body;
    }
    return [error, roles]
  }
  public static getStatisticsStatus = async (): Promise<any> => {
    const [error, response] = await API('GET', 'user/count');
    let st: IStatus | undefined;
    if (response) {
      st = response.data.body;
    }
    return [error, st]
  }

  /**
   * This method returns the users in format ID, NAME order by NAME ASC
   * This method must be used in places/components such as combobox, autocomplete, etc.
   */
  public static getStafInIdValueFormat = async (): Promise<any> => {
    const [error, response] = await API('GET', 'user/all/idValue');
    return [error, response]
  }

  public static getStaf = async (role: string, status: string, page: number, size: number): Promise<any> => {

    let criteria: any[] = [];
    if (role !== 'all') {
      criteria.push({
        property: "authorities.authority",
        value: role,
        expression: "IN",
      })
    }

    criteria.push({
      property: "status",
      value: status,
      expression: "EQUALS"
    })

    let body = {
      criteria: criteria,
      paging: {
        page: page,
        size: size
      }
    }

    const [error, response] = await API('POST', 'user/all', JSON.stringify(body));
    return [error, response]
  }

  public static getUserDetails = async (id: string): Promise<any> => {
    const [error, response] = await API('GET', 'user/' + id,);
    let st: IUserInfoResponseDto | undefined;
    if (response) {
      st = response.data.body;
      if (st) {
        st.fullNameOrEmail = (isBlankOrNA(st.firstName) || isBlankOrNA(st.lastName)) ? st.email : st.firstName + ' ' + st.lastName;
      }
    }
    return [error, st]
  }

  public static saveUserDetails = async (body: UserUpdateRequestDto): Promise<any> => {
    const [error, response] = await API('PUT', 'user', JSON.stringify(body));
    return [error, response]
  }
  public static getEmployeeDetails = async (id: string): Promise<any> => {
    const [error, response] = await API('GET', 'user/employee/' + id,);
    let st: EmployeeResponseDto | undefined;
    if (response) {
      st = response.data.body;
    }
    return [error, st]
  }
  public static getAutocomplete = async (s: string): Promise<any> => {
    const [error, response] = await API('GET', 'user/autocomplete/' + s,);
    return [error, response]
  }
  public static createUserEmployee = async (body: EmployeeCreateRequestDto): Promise<any> => {
    const [error, response] = await API('POST', 'user/employee', JSON.stringify(body));
    return [error, response]
  }
  public static updateUserEmployee = async (body: EmployeeUpdateRequestDto): Promise<any> => {
    const [error, response] = await API('PUT', 'user/employee', JSON.stringify(body));
    return [error, response]
  }

  public static getUserMetadata = async (id: string): Promise<any> => {
    const [error, response] = await API('GET', 'user/metadata/' + id,);
    return [error, response]
  }

  public static getFavorites = async (): Promise<any> => {
    const [error, response] = await API('GET', 'user/favorite');
    return [error, response];
  }

  public static getSummary = async (id: string): Promise<any> => {
    const [error, response] = await API('GET', 'user/summary/' + id);
    return [error, response];
  }

  public static createFavorites = async (body: string[]): Promise<any> => {
    const [error, response] = await API('POST', 'user/favorite', JSON.stringify(body));
    return [error, response];
  }

  public static deleteFavorites = async (body: string[]): Promise<any> => {
    const [error, response] = await API('DELETE', 'user/favorite', body);
    return [error, response];
  }

}

export default StafService;