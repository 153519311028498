
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { IconButton, Tooltip, useTheme } from '@mui/material';

interface IProps {
  activate: () => void;
}

const ActivateDetailsComponent = (props: IProps) => {

  const theme = useTheme();

  const boxSX = {
    color: theme.palette.action.disabled,
    "&:hover": {
      color: theme.palette.primary.main
    },
  }

  const handleClick = () => {
    props.activate()
  };

  return (
  
      <IconButton
        sx={boxSX}
        size={'small'}
        onClick={handleClick}
      >
        <AutoAwesomeIcon />
      </IconButton>

  )
}

export default ActivateDetailsComponent;