import { Paper } from '@mui/material';
import ActiveLoadGridComponent from '../components/ActiveLoadModule/Grid/ActiveLoadGridComponent';

const ActiveLoadPage = () => {
    return (
        <div style={{ width: "100%", height: "calc(100vh - 140px)", display: "flex", alignContent: "stretch", gap: "20px" }} >
            <Paper sx={{ flex: 1, padding: 2, width: "auto", paddingTop: "2px", paddingBottom: "2px", paddingRight: "10px", }}>
                <ActiveLoadGridComponent />
            </Paper>
        </div>
    );
}
export default ActiveLoadPage;