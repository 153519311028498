import { useEffect, useState } from 'react'
import { Box, TextField } from "@mui/material";
import { APIFile } from '../../services/APIFile';
import { IFileDetailsResponse } from '../../models/FileModel';
import { Image } from '@mui/icons-material';
import SCImage from '../../styledComponents/sharedComponent/SCImage';
import useWindowDimensions from '../../hooks/useWindowDimensions';


interface IProps {
	avatar?: boolean,
	width?: number,
	heigth?: number,
	ent: IFileDetailsResponse,
}

const FileViewComponent = (props: IProps) => {

	const { url, extension } = props.ent;
	const [data, setData] = useState('')
	const [size, setSize] = useState(useWindowDimensions());
	useEffect(() => {
		(async () => {
			const [error, response] = await APIFile(url);
			if (response) {
				let d: string = response.data;
				setData(d);
			}
			if (error) { }
		})()
	}, [url])

	return (
		<div style={{ maxWidth: "100%", maxHeight: "100%", display:"flex", justifyContent:"center", alignItems:"center" }} >

			{
				data !== '' && extension.toLocaleLowerCase() === 'png' &&
				<Box
					component="img"
					src={"data:image/png;base64," + data}
					alt={''}
					sx={{
						height: `${size.height * 0.7}px`,
						width: `${size?.width * 0.7}px`,
						objectFit: "scale-down"
					}}
				/>
				// <img width={"100%"} height={"100%"} src={"data:image/png;base64," + data} alt="" />
			}
			{
				data !== '' && extension.toLocaleLowerCase() === 'jpg' &&
				<Box
					component="img"
					src={"data:image/jpg;base64," + data}
					alt={''}
					sx={{
						height: `${size.height * 0.7}px`,
						width: `${size?.width * 0.7}px`,
						objectFit: "scale-down"
					}}
				/>
			}
			{
				data !== '' && extension.toLocaleLowerCase() === 'jpeg' &&
				<Box
					component="img"
					src={"data:image/jpeg;base64," + data}
					alt={''}
					sx={{
						height: `${size.height * 0.7}px`,
						width: `${size?.width * 0.7}px`,
					}}
				/>
				// <img width={"100%"} height={"100%"} src={"data:image/jpeg;base64," + data} alt="" />
			}
			{
				data !== '' && extension.toLocaleLowerCase() === 'txt' &&
				<TextField
					multiline
					value={atob(data)}
					sx={{ width: "1150px", height: "700px" }}
					minRows={29}
				/>
			}

			{data !== '' && extension.toLocaleLowerCase() === 'pdf' &&
				<object type="application/pdf" width={"1150px"} height={"700px"}
					data={"data:application/pdf;base64," + data}></object>
			}
		</div>
	)
}

export default FileViewComponent;