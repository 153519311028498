import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from "@mui/material";
import { IAutoDetails, IExportImageInfo } from "../../models/TransportModels";
import CropImageComponent from "../CustomComponentModule/CropImageComponent";
import CloseIcon from '@mui/icons-material/Close';
import PostAddIcon from '@mui/icons-material/PostAdd';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import { useEffect, useRef, useState } from "react";
import TransportService from "../../services/TransportService";
import SecureDialogComponent from "../CustomComponentModule/SecureDialogComponent";
import { EButtonColor, ENotificationType } from "../../models/EnumGeneral";
import ImageBase64ServerComponent from "../CustomComponentModule/ImageBase64ServerComponent";
import SCViewList from "../../styledComponents/sharedComponent/SCViewList";
import FileViewComponent from "../CustomComponentModule/FileViewComponent";
import FileDownloadComponent from "../CustomComponentModule/FileDownloadComponent";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useDispatch } from "react-redux";
import { modifyAutoRefresh } from "../../store/reducers/cnfSlice";
import { EFileType, IFileDetailsRequest, IImageResponse } from "../../models/FileModel";
import { green } from "@mui/material/colors";

interface IProps {
    auto: IAutoDetails | null;
    refreshAfretSave: (us: IAutoDetails) => void;
    snackMsg: (text: string, type?: ENotificationType) => void;
    handleStep: (step: number) => void;
}

const MediaFileTransportComponent = (props: IProps) => {
    const [openDialogAddImage, setOpenDialogAddImage] = useState(false);
    const [exp, setExp] = useState<IExportImageInfo | null>(null);
    const [imageType, setImageType] = useState<EFileType>(EFileType.ADDITIONAL);
    const [mainImage, setMainImage] = useState<IImageResponse[]>([]);
    const [additionalImage, setAdditionalImage] = useState<IImageResponse[]>([]);
    const [loading, setLoading] = useState(false);

    const [open, setOpen] = useState(false);
    const [nrViewFiles, setNrViewFiles] = useState(0);
    const [viewFiles, setViewFile] = useState<IImageResponse>();
    const [fullWidth] = useState(true);

    const dispatch = useDispatch();

    const deleteTransportImages = (uuid: string) => {
        (async () => {
            let isMain = false
            if (mainImage.findIndex((mainImg: IImageResponse) => mainImg.uuid === uuid) !== -1) {
                isMain = true;
            }
            const [error, response] = await TransportService.deleteTransportImages(uuid);
            if (response) {
                if (props.auto) {
                    if (props.auto.metadata.step !== 2) {
                        let bd = {
                            uuid: props.auto.uuid,
                            step: 2
                        }
                        const [er, rs] = await TransportService.saveTransportStep(bd);
                        if (er) { }
                    }
                    setMainImage([])
                    setAdditionalImage([])
                    props.refreshAfretSave(props.auto);
                }
                props.snackMsg('Image was successfully removed for ' + props.auto?.brand + props.auto?.model + '.')
                if (isMain) {
                    dispatch(modifyAutoRefresh());
                }
            }
        })()
    }

    const hendlerDeleteMainImage = () => {
        if (mainImage.length !== 0) {
            let uuid: string = mainImage[0].uuid;
            deleteTransportImages(uuid);
        }
    }

    const handleView = (e: IImageResponse) => {
        setOpen(true);
        setViewFile(e);
        let n = additionalImage.indexOf(e);
        setNrViewFiles(n);
    }

    const handleClose = () => {
        setOpen(false);
        setNrViewFiles(1);
    };

    const handleCloseOpenDialogAddImage = () => {
        setExp(null)
        setOpenDialogAddImage(false)
    }

    const handleOpenDialogAddImage = (type: EFileType) => {
        setImageType(type);
        setOpenDialogAddImage(true)
    }

    const hendlerPrevFile = () => {
        if (viewFiles) {
            let n = additionalImage.indexOf(viewFiles);
            if (n > -1) {
                setViewFile(additionalImage[n - 1]);
                setNrViewFiles(n - 1);
            }
        }
    }

    const hendlerNextFile = () => {
        if (viewFiles) {
            let n = additionalImage.indexOf(viewFiles);
            if (n > -1) {
                setViewFile(additionalImage[n + 1]);
                setNrViewFiles(n + 1);
            }
        }
    }

    const hendlerSaveImage = () => {
        if (exp) {

            (async () => {
                setLoading(true);
                const formData = new FormData();
                let cropSize: IFileDetailsRequest = {}
                formData.append('request', new Blob([JSON.stringify(exp?.data)], { type: "application/json" }));
                formData.append('image', exp?.image);

                const [error, response] = await TransportService.addImageInTransport(formData);
                if (response) {
                    if (props.auto) {
                        if (props.auto.metadata.step !== 2) {
                            let bd = {
                                uuid: props.auto.uuid,
                                step: 2
                            }
                            const [er, rs] = await TransportService.saveTransportStep(bd);
                            if (er) { }
                        }
                        props.refreshAfretSave(props.auto);
                        setMainImage([])
                        setAdditionalImage([])
                        if (exp.data.type === EFileType.MAIN) {
                            dispatch(modifyAutoRefresh());
                        }
                    }
                    props.snackMsg('Image was successfully saved for ' + props.auto?.brand + props.auto?.model + '.');
                    setLoading(false);
                    handleCloseOpenDialogAddImage();
                }
                if (error) {
                    setLoading(false);
                    props.snackMsg('A problem occurred in the process of uploading the image. Please try again later.', ENotificationType.ERROR);
                }
            })()
        }

    }

    const exportInfo = (info: IExportImageInfo) => {
        if (props.auto) {
            info.data.type = imageType;
            info.data.uuid = props.auto.uuid;
            setExp(info);
        }
    }

    useEffect(() => {
        if (props.auto && props.auto.uuid) {
            (async () => {
                const [error, response] = await TransportService.getTransportImages(props.auto?.uuid ? props.auto?.uuid : '');
                if (response) {
                    let res = response.data.body,
                        mainImg: IImageResponse[] = res?.filter((image: IImageResponse) => (image.type === EFileType.MAIN)),
                        additionalImg: IImageResponse[] = res?.filter((image: IImageResponse) => (image.type === EFileType.ADDITIONAL));

                    setMainImage(mainImg);
                    setAdditionalImage(additionalImg);
                }
                if (error) { }
            })()
        }
    }, [props.auto])



    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: "space-between" }}>
                <Box sx={{ display: 'flex', marginBottom: "15px", flexDirection: "column" }}   >
                    <SCViewList gap={25} >
                        <Button
                            disabled={(mainImage.length !== 0)}
                            onClick={() => handleOpenDialogAddImage(EFileType.MAIN)}
                            color={'warning'} variant={'contained'} size={'small'} sx={{ height: "35px", width: "210px", display: "flex", justifyContent: "flex-start" }}>
                            <PostAddIcon fontSize={'small'} sx={{ marginRight: "15px" }} />
                            add main image
                        </Button>

                        <Box sx={{ width: "270px", display: "flex", alignItems: "center", marginBottom: "15px" }} >
                            <Button
                                onClick={() => handleOpenDialogAddImage(EFileType.ADDITIONAL)}
                                color={'info'} variant={'contained'} size={'small'} sx={{ height: "35px", width: "210px", display: "flex", justifyContent: "flex-start" }}>
                                <PostAddIcon fontSize={'small'} sx={{ marginRight: "10px" }} />
                                add additional image
                            </Button>
                        </Box>
                    </SCViewList>
                    <div style={{ marginBottom: "15px", width: "560px" }}>
                        <Divider sx={{ fontSize: "16px" }} textAlign="left">MAIN IMAGE</Divider>
                    </div>

                    {(mainImage.length !== 0) ? mainImage.map((mainImg, key) =>
                        <div style={{ padding: "1px", display: "flex", alignItems: "center", maxWidth: mainImg.thumbnail?.width, maxHeight: mainImg.thumbnail?.height }} onClick={() => { handleView(mainImg) }} key={key}>
                            <ImageBase64ServerComponent base64={mainImg.thumbnail.value} url={mainImg.thumbnail ? mainImg.thumbnail.url : mainImg.fileDetails.url} w={mainImg.thumbnail?.width} h={mainImg.thumbnail?.height} pointer />
                        </div>)
                        : <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "560px", height: "130px" }}> There is no main image</div>
                    }

                    {
                        mainImage.length !== 0 &&
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Box>
                                <div style={{ marginTop: "-4px", marginLeft: "-6px", width: "30px" }}>
                                    <SecureDialogComponent
                                        answerYES={hendlerDeleteMainImage}
                                        text={''}
                                        icon={<CloseIcon fontSize='small' />}
                                        color={EButtonColor.error}
                                        iconButton={true}
                                        tooltip
                                        tooltipText={'Remove image'}
                                    />
                                </div>
                            </Box>
                        </Box>
                    }
                </Box>
            </Box>
            <div style={{ marginBottom: "15px", width: "560px" }}>
                <Divider sx={{ paddingTop: "5px", fontSize: "16px" }} textAlign="left">ADDITIONAL IMAGES</Divider>
            </div>
            <Box>
                {additionalImage.length !== 0
                    ? <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
                        {additionalImage.map((additionalImg, key) =>
                            <div style={{ padding: "1px", display: "flex", flexDirection: "column", alignItems: "flex-start", marginBottom: "-10px" }} key={key}>
                                <div style={{ width: additionalImg.thumbnail?.width, height: additionalImg.thumbnail?.height }} onClick={() => { handleView(additionalImg) }}>
                                    <ImageBase64ServerComponent base64={additionalImg.thumbnail.value} url={additionalImg.thumbnail ? additionalImg.thumbnail.url : additionalImg.fileDetails.url} h={additionalImg.thumbnail?.height} w={additionalImg.thumbnail?.width} pointer />
                                </div>

                                <div style={{ marginTop: "-4px", marginLeft: "-6px" }}>
                                    <SecureDialogComponent
                                        answerYES={() => { deleteTransportImages(additionalImg.uuid) }}
                                        text={''}
                                        icon={<CloseIcon fontSize='small' />}
                                        color={EButtonColor.error}
                                        tooltip
                                        tooltipText="Remove image"
                                        iconButton={true}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    : <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "560px", height: "70px" }}> There are no additional images</div>
                }

            </Box >

            <Dialog
                open={openDialogAddImage}
                onClose={handleCloseOpenDialogAddImage}
            >
                <DialogTitle>
                    {'Image management'.toLocaleUpperCase()}
                </DialogTitle>
                <DialogContent>
                    <div style={{ display: "flex" }}>
                        <div style={{ width: "1000px", display: "flex", flexDirection: "column", gap: "15px", marginTop: "15px", marginBottom: "5px", paddingBottom: "10px", opacity: (loading ? 0.5 : 1) }}>
                            <CropImageComponent disabled={loading} aspectRatio={(imageType === EFileType.MAIN) ? 1 : 0} exportInfo={exportInfo} />
                        </div>
                    </div>

                </DialogContent>
                <DialogActions>
                    <div style={{ display: "flex", justifyContent: "flex-end", width: "100%", padding: "20px" }}>
                        <div style={{ display: "flex", gap: "10px" }}>
                            <Button
                                variant="contained"
                                disabled={loading}
                                onClick={hendlerSaveImage}
                                size={'small'}
                                startIcon={<SaveAsIcon />}>Save</Button>
                            {loading && (
                                <CircularProgress
                                    size={35}
                                    sx={{
                                        color: "#1976d2",
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />
                            )}
                            <Button
                                startIcon={<CloseIcon />}
                                color={'warning'}
                                variant={'contained'}
                                size={'small'}
                                onClick={handleCloseOpenDialogAddImage}>Close</Button>
                        </div>
                    </div>
                </DialogActions>
            </Dialog>

            <Dialog
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "75%",
                            minHeight: "83%",
                        },
                    },
                }}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                    {'IMAGE PREVIEW'}
                </DialogTitle>
                <DialogContent>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100%" }}>
                        {viewFiles &&
                            <FileViewComponent ent={viewFiles.fileDetails} width={viewFiles.fileDetails.width} heigth={viewFiles.fileDetails.height} />}
                    </div>
                </DialogContent>
                <DialogActions
                    style={{
                        marginBottom: "20px",
                        display: "flex",
                        justifyContent: "space-between",
                        paddingLeft: "20px",
                        paddingRight: "20px"
                    }}>
                    {viewFiles &&
                        <FileDownloadComponent fileName={'doc' + '.' + viewFiles.fileDetails.extension} ent={viewFiles.fileDetails} />
                    }
                    <div style={{ display: "flex", gap: "10px" }}>
                        <Button
                            onClick={hendlerPrevFile}
                            disabled={(viewFiles?.type === 'MAIN') ? true : nrViewFiles <= 0}
                            variant='contained'
                            size='small'>
                            <KeyboardArrowLeftIcon fontSize='small' />
                        </Button>

                        <div> {nrViewFiles + 1} of {(viewFiles?.type === 'MAIN') ? 1 : additionalImage.length} </div>

                        <Button
                            onClick={hendlerNextFile}
                            disabled={(viewFiles?.type === 'MAIN') ? true : nrViewFiles === additionalImage.length - 1}
                            variant='contained'
                            size='small'>
                            <KeyboardArrowRightIcon fontSize='small' />
                        </Button>
                    </div>
                    <Button onClick={handleClose} >Close</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default MediaFileTransportComponent;