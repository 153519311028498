import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, TextField, ToggleButton, ToggleButtonGroup, Tooltip, useMediaQuery, useTheme } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';

import SaveAsIcon from '@mui/icons-material/SaveAs';
import CloseIcon from '@mui/icons-material/Close';
import { useDebounce } from '../../hooks/useDebounce';

import { useNavigate } from 'react-router-dom';
import { modifyWarehouseGrid, modifyWarehouseRefresh, setLastError } from '../../store/reducers/cnfSlice';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import ViewListIcon from '@mui/icons-material/ViewList';

import { IResponseWarehouseAutocomplete, ITypeWarehouse, IWarehouseCreateDTO } from '../../models/WarehouseModels';
import ListWarehouseTempComponent from './ListWarehouseTempComponent';
import WarehouseService from '../../services/WarhouseService';
import SearchWarehouseComponent from './SearchWarehouseComponent';
import SCViewList from '../../styledComponents/sharedComponent/SCViewList';
import { isBlank } from '../../helpers/textHelper';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import AddressComponent from '../MapModule/AddressComponent';
import { AddressRequestDto, AddressResponseDto } from '../../models/AddressModels';
import AddressService from '../../services/AddressService';

const styled = { icon: { fontSize: "28px", cursor: "pointer" } }

interface IProps {
    snackMsg: (s: string) => void
}

export default function WarehouseTabsFiltersComponent(props: IProps) {

    const { hints } = useSelector((state: RootState) => state.cnfSlice.interfaceState);
    const { grid } = useSelector((state: RootState) => state.cnfSlice.warehouse);
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [openN, setOpenN] = useState(false);
    const [save, setSave] = useState(false);
    const [isValidName, setIsValidName] = useState<boolean>(true);
    const [addressDialogShow, setAddressDialogShow] = useState(false);
    const [address, setAddress] = useState<AddressResponseDto | null>(null);
    const [retrieveAddress, setRetrieveAddress] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const [alignment, setAlignment] = useState('web');

    const handleChange = (event: React.MouseEvent<HTMLElement>, newValue: string) => {
        if (newValue) {
            setAlignment(newValue);
            dispatch(modifyWarehouseGrid(!grid));
        }
    };

    const [state, setState] = useState<IWarehouseCreateDTO>({
        name: '',
        contactName: '',
        typeIds: [],
        addressId: ''
    });

    const debouncedName = useDebounce(state.name, 300);

    const handlerChangeName = (e: any) => {
        let value: string = (e.target.value as string);
        setState(st => ({ ...st, name: value }))
    }

    const handlerChangeIdno = (e: any) => {
        let value: string = (e.target.value as string);
        setState(st => ({ ...st, contactName: value }))
    }

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickOpen = () => { setOpen(true); };
    const handleClose = () => {
        setOpen(false);
    };

    const hendlerActiveElement = (item: IResponseWarehouseAutocomplete) => {
        handleClose();
        navigate('/warehouse/' + item.uuid)
    }

    const hendlerList = (list: ITypeWarehouse[]) => {
        let t: string[] = [];
        for (let e of list) { t.push(e.uuid); }
        setState(st => ({ ...st, typeIds: t }))
    }

    const handlerAddNewWarehouse = () => {
        setOpenN(true);
    }

    const handleCloseN = () => {
        setOpenN(false);
        setState({
            name: '',
            contactName: '',
            typeIds: [],
            addressId: ''
        })
        setIsValidName(true)
        setAddress(null);
    }

    const handlerSaveWarehouse = () => { setSave(true); }

    const onAddressDialogShow = () => {
        setAddressDialogShow(true);
    };

    const onAddressDialogClose = () => {
        setAddress(null);
        setAddressDialogShow(false);
    }

    const onAddressChange = (value: AddressResponseDto) => {
        setAddress(value);
    }

    const onApplyAddress = () => {
        setRetrieveAddress(true);
        checkIfWarehouseNameAvailable();
    }

    useEffect(() => {
        if (retrieveAddress && address) {
            (async () => {
                const { uuid, fullAddress, ...rest } = address;
                const body: AddressRequestDto = rest;
                const [, data] = await AddressService.getOrCreateAddress(body);
                if (data) {
                    const addressResponse: AddressResponseDto = data as AddressResponseDto;
                    state.addressId = addressResponse.uuid;
                    setAddress(addressResponse);
                    setAddressDialogShow(false);
                }
            })();
            setRetrieveAddress(false);
        }
    }, [retrieveAddress, address, state]);

    useEffect(() => {
        if (save) {
            (async () => {
                const body: IWarehouseCreateDTO = { ...state };
                const [error, response] = await WarehouseService.createWarehouse(body);
                if (response) {
                    let uuid = response.data.response.entityId;
                    handleCloseN();
                    navigate('/warehouse/' + uuid);
                    props.snackMsg('Warehouse ' + body.name + ' was successfully created.')
                    dispatch(modifyWarehouseRefresh());
                }
                if (error) { dispatch(setLastError(error)) }
            })()
        }
        setSave(false);
    }, [save])

    useEffect(() => {
        if (debouncedName) {
            checkIfWarehouseNameAvailable();
        }
    });

    const checkIfWarehouseNameAvailable = () => {
        const warehouseName = state.name.trim();
        const addressId = state.addressId;

        if (isBlank(addressId) || warehouseName.length < 3) {
            setIsValidName(true);
            return;
        }

        (async () => {
            const [, response] = await WarehouseService.warehouseNameAviable(warehouseName, state.addressId);
            if (response) {
                setIsValidName(response.data.body as boolean);
                return;
            }
            setIsValidName(false);
        })();
    };

    const onDisabledCreateButton = useCallback((): boolean => {
        const warehouseName = state.name.trim();
        const contactName = state.contactName.trim();
        const selectedTypes = state.typeIds.length > 0;
        const selectedAddress = !isBlank(state.addressId);

        return (warehouseName.length < 3 || warehouseName.length > 160)
            || (contactName.length < 2 || contactName.length > 56)
            || !isValidName || !selectedTypes || !selectedAddress;

    }, [isValidName, state])

    return (
        <div>
            <Box sx={{ marginBottom: "5px" }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "5px" }}>
                    <div style={{ display: "flex", gap: "5px" }}>

                        <Tooltip
                            title={(hints) ? t("Search warehouse") : ''}
                            placement={"top"}
                            arrow={true}
                        >
                            <IconButton onClick={handleClickOpen}>
                                <ManageSearchIcon style={styled.icon} color={"primary"} />
                            </IconButton>
                        </Tooltip>

                        <Tooltip
                            title={(hints) ? t("Create warehouse") : ''}
                            placement={"top"}
                            arrow={true}
                        >
                            <IconButton onClick={handlerAddNewWarehouse} >
                                <DashboardCustomizeIcon style={styled.icon} color={"primary"} />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <ToggleButtonGroup
                        color="primary"
                        value={alignment}
                        exclusive
                        onChange={handleChange}
                        aria-label="Platform"
                        sx={{ marginLeft: "20px" }}
                    >
                        <ToggleButton size='small' value="ios"><FmdGoodIcon /></ToggleButton>
                        <ToggleButton size='small' value="web"><ViewListIcon /></ToggleButton>
                    </ToggleButtonGroup>
                </div>

                <Dialog
                    fullScreen={fullScreen}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                        { }
                    </DialogTitle>
                    <DialogContent>
                        <div style={{ minHeight: "350px", minWidth: "330px" }}>
                            <SearchWarehouseComponent
                                hendlerActiveElement={hendlerActiveElement}
                                placeholder={'Search by name'}
                            ></SearchWarehouseComponent>
                        </div>

                        <DialogContentText>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    fullScreen={fullScreen}
                    maxWidth={'xl'}
                    open={openN}
                    onClose={handleCloseN}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                        {"CREATE WAREHOUSE"}
                    </DialogTitle>
                    <DialogContent>
                        <div style={{ display: "flex" }}>
                            <div style={{ paddingTop: "20px" }}>
                                <div style={{ display: "flex", gap: "18px", alignItems: "start", justifyContent: "" }}>
                                    <Box sx={{ width: '600px', display: "flex", justifyContent: "center", flexDirection: "row" }}>
                                        <Box style={{ width: "295px", display: "flex", flexDirection: "column", gap: "45px", paddingTop: "10px" }}>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                height: "360px",
                                                gap: "50px",
                                                paddingLeft: "10px"
                                            }}>
                                                <TextField
                                                    autoComplete={'off'}
                                                    error={state.name.length < 3 || !isValidName || state.name.length > 160}
                                                    value={state.name}
                                                    label="Warehouse Name"
                                                    onChange={handlerChangeName}
                                                    style={{ width: "250px" }}
                                                    inputProps={{ sx: { fontSize: "16px" } }}
                                                    InputLabelProps={{ sx: { fontSize: "17px" } }}
                                                    helperText={!isValidName ? 'Warehouse with specified name and address already exists.' : ''}
                                                />

                                                <TextField
                                                    autoComplete={'off'}
                                                    error={state.contactName.length < 2 || state.contactName.length > 56}
                                                    value={state.contactName}
                                                    label="Contact Name"
                                                    onChange={handlerChangeIdno}
                                                    style={{ width: "250px" }}
                                                    inputProps={{ sx: { fontSize: "16px" } }}
                                                    InputLabelProps={{ sx: { fontSize: "17px" } }}
                                                />

                                                <Box sx={{ width: "100%" }}>
                                                    <Button sx={{ minWidth: "250px", marginBottom: "5px" }} variant={'contained'} onClick={onAddressDialogShow} color={state.addressId ? 'info' : 'error'}>
                                                        <AddLocationAltIcon />  Address
                                                    </Button>
                                                    <TextField label="" disabled multiline rows={2} value={address?.fullAddress}
                                                        inputProps={{ sx: { fontSize: "11px", padding: "1px", lineHeight: "1.5" } }}
                                                        InputLabelProps={{ sx: { fontSize: "17px" } }}
                                                        sx={{ minWidth: "250px" }}
                                                    />
                                                </Box>
                                            </div>
                                        </Box>
                                        <div style={{ borderLeft: "1px dashed silver", padding: "20px" }}>
                                            <ListWarehouseTempComponent getList={hendlerList} />
                                        </div>
                                    </Box>
                                </div>
                            </div>
                        </div>
                        <DialogContentText>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <SCViewList flexEnd padding={20} gap={10}>
                            <Button
                                variant={'contained'}
                                disabled={onDisabledCreateButton()}
                                size={'small'}
                                onClick={handlerSaveWarehouse} startIcon={<SaveAsIcon />}>{('Create').toUpperCase()}</Button>
                            <Button
                                startIcon={<CloseIcon />}
                                variant={'contained'}
                                color={'warning'}
                                size={'small'}
                                onClick={handleCloseN}>Close</Button>
                        </SCViewList>
                    </DialogActions>
                </Dialog>

            </Box>
            <Divider></Divider>
            <Box sx={{ height: "10px" }}></Box>

            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={addressDialogShow}
                onClose={onAddressDialogClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle></DialogTitle>
                <DialogContent>
                    <AddressComponent current={address ? address : null} getPlace={onAddressChange} />
                </DialogContent>
                <DialogActions sx={{ marginBottom: "10px", marginRight: "20px" }}>
                    <Button variant={'contained'} color={'info'} onClick={onApplyAddress} disabled={!address || address.zipcode.length < 4}> Apply/Select</Button>
                    <Button variant={'contained'} color={'warning'} onClick={onAddressDialogClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}