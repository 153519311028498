import { EFileType, IFileDetailsRequest } from "./FileModel"

export enum ETransportStatus {
    INCOMPLETE = 'INCOMPLETE',
    ACTIVE = 'ACTIVE',
    TEMPORARY = 'TEMPORARY',
    INACTIVE = 'INACTIVE'
}

export interface IFavoritesAuto {
    brand: string,
    color: string,
    licensePlate: string,
    mainImage: string,
    model: string,
    transportNumber: string,
    uuid: string,
    vin: string
}

export interface ITransportCreateRequestDto {
    vehicleId: string,
    vin: string
}

export interface AutoStatusRequestDto {
    uuid: string,
    status: string,
    activeFromDate: number,
    activeToDate: number
}

export interface IAutoRequestDto {
    id?: string,
    uuid: string,
    status: string | null,
    activeFromDate: number,
    activeToDate: number,
    vehicle: IVehicleCreateRequestDto,
    color: string | null,
    description: string | null,
    licensePlate: string | null,
    vin: string,
    transportNumber: string,
    complete: boolean,
    allowDelete: boolean,
    step: number,
    year: number
}

export interface IAutoDetails {
    uuid: string,
    brand: string,
    color: string | null,
    licensePlate: string | null,
    metadata: {
        step: number,
        completed: boolean
    }
    model: string,
    transportNumber: string,
    type: string,
    vin: string,
    year: number,
    mainImage: string,
    readonly: boolean
}

export interface IAutoRegistration {
    color: string,
    description: string,
    transportNumber: string,
    licensePlate: string
}
export interface IVehicleDetails {
    uuid?: string,
    brand: {
        uuid?: string;
        name: string,
        type: string
    },
    model: string,
    type: string,
    year?: string,
    height?: string,
    width?: string,
    length?: string,
    weight?: string,
    loadingWeight?: string,
    grossWeight?: string;
    grossCombinationWeight?: string,
    fuelType?: string,
    fuelConsumption?: string,
    transmissionType?: string,
    enginePower?: string,
    engineCapacity?: string,
    readonly?: boolean
}
export interface IVehicleCreateRequestDto {
    brand: {
        uuid?: string;
        name: string,
        type: string
    },
    model: string,
    type: string,
    year: number,
    height: number,
    width: number,
    length: number,
    weight: number,
    loadingWeight: number,
    grossWeight: number;
    grossCombinationWeight: number,
    fuelType?: string,
    fuelConsumption?: number,
    transmissionType?: string,
    enginePower?: number,
    engineCapacity?: number
}
export interface IVehicleUpdateRequestDto {
    uuid: string,
    brand: {
        uuid: string,
        name: string,
        type: string
    },
    type: string,
    model: string,
    year: number;
    height: number;
    width: number;
    length: number;
    weight: number;
    loadingWeight: number;
    grossWeight: number;
    grossCombinationWeight: number;
    fuelType?: string;
    fuelConsumption?: number;
    transmissionType?: string;
    enginePower?: number;
    engineCapacity?: number;
}
export interface IVehicleResponseDto {
    uuid: string,
    brand: {
        uuid: string,
        name: string,
        type: string
    },
    model: string,
    type: string,
    year: number,
    height: number,
    width: number,
    length: number,
    weight: number,
    loadingWeight: number,
    grossWeight: number,
    grossCombinationWeight: number,
    fuelType?: string,
    fuelConsumption?: number,
    transmissionType?: string,
    enginePower?: number,
    engineCapacity?: number,
    image?: string,
    allowDelete?: boolean
}
export interface IAllVehicleResponseDto {
    uuid: string,
    brand: string,
    model: string,
    fullInfo: string,
    year: number,
    type: string
}

export interface IResponseAutocompleteAuto {
    uuid: string,
    brand: string,
    model: string,
    vin: string,
    licensePlate: string,
    color: string,
    transportNumber: string,
    mainImage: string
}

export interface IResponseAuto {
    id?: string,
    activeFromDate: number,
    activeToDate: number,
    available: boolean,
    brand: string,
    color: string,
    licensePlate: string,
    mainImage: string,
    model: string,
    status: string,
    transportNumber: string,
    type: string,
    uuid: string,
    vin: string,
    year: number,
    readonly: boolean
}

export interface ITransportSummary {
    brand: string;
    model: string;
    year: number;
    type: string;
    status: string;
    activeFromDate: number;
    activeToDate: number;
    color: string;
    licensePlate: string;
    vin: string;
    transportNumber: string;
    available: boolean;
    height: number;
    width: number;
    length: number;
    weight: number;
    loadingWeight: number;
    grossWeight: number;
    grossCombinationWeight: number;
    fuelType: string;
    fuelConsumption: number;
    transmissionType: string;
    enginePower: number;
    engineCapacity: number;
    annualDot: boolean;
    annualDotExpirationDate?: number;
}

export interface IExportImageInfo {
    image: string,
    data: {
        uuid: string,
        type: EFileType,
        fileDetails: IFileDetailsRequest
    }
}

export interface IBrandResponseDto {
    uuid: string;
    brand: string;
    model: string;
    type: string;
    allowDelete: boolean;
}
export interface IBrandCreateDto {
    brand: string;
    model: string;
    type: string;
}

export interface IDocumentResponseDto {
    uuid: string,
    type: string,
    number?: string,
    caption?: string,
    expirationDate?: number,
    attachments?: any,
    allowDelete?: true
}

export interface IDocumentRequestDto {
    uuid: string;
    type: string,
    number?: string,
    caption?: string,
    expirationDate?: number
}

export interface IDocumentCreateDto {
    transportId: string;
    type: string;
    number?: string,
    caption?: string,
    expirationDate?: number
}

export enum EFuelType {
    GASOLINE = 'GASOLINE',
    DIESEL = 'DIESEL',
    ELECTRICITY = 'ELECTRICITY',
    HYBRID = 'HYBRID'
}

export enum ETransmissionType {
    MANUAL = 'MANUAL',
    AUTO = 'AUTO'
}

export enum EVehicleType {
    TRUCK = 'TRUCK',
    TRAILER = 'TRAILER',
    BOX_TRUCK = 'BOX_TRUCK'
}

export enum ETransportDocumentType {
    ANNUAL_DOT = 'ANNUAL_DOT'
}