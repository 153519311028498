import { Avatar } from "@mui/material";



interface IProps {
	data: string,
	online?: boolean
}

const AvatarUserComponent = (props: IProps) => {

	const { data, online } = props;

	return (
		<>
			{data !== '' && <Avatar sx={{ border: online ? "2px solid green" : "", width: "30px", height: "30px" }} alt="" src={"data:image/png;base64," + data} />}
		</>
	)
}

export default AvatarUserComponent;