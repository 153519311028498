import { ENotificationType } from "./EnumGeneral"

export interface  INotification  {
    userUUID: string,
    dt: number,
    text: string[],
    state: boolean,
    type?: ENotificationType
    link?:string,
    title?:string,
    priority?:EPriorityNotification    
    uuid?:string,
}

export interface IHistory  {
    userUUID: string,
    dt: number,
    text: string,        
    link:string,
    values?:string    
}

export enum EPriorityNotification {
    LOW='LOW',
    MEDIUM='MEDIUM',
    HIGH='HIGH'	
}
