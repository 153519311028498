import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, MenuItem, TextField, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import CloseIcon from '@mui/icons-material/Close';
import { ObjectToValueArray } from '../../../helpers/generalHelper';
import { modifyTransportRefresh, setLastError } from '../../../store/reducers/cnfSlice';
import React from 'react';
import { EVehicleType, IAllVehicleResponseDto, IResponseAutocompleteAuto, ITransportCreateRequestDto, IVehicleDetails } from '../../../models/TransportModels';
import TransportService from '../../../services/TransportService';
import { useNavigate } from 'react-router';
import SearchTransportBarComponent from '../SearchTransportBarComponent';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import VeivVehicleTableComponent from './VievVehicleTableComponent';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import VehicleCrudComponentB from '../VehicleCrudComponentB';
import { useDebounce } from '../../../hooks/useDebounce';

function defaultTransport() {
    return {
        vehicleId: "",
        vin: ""
    }
}

interface IProps {
    snackMsg: (text: string) => void;
}

const styled = {
    icon: { fontSize: "28px", cursor: "pointer" },
}

export default function TransportButtonBarComponent(props: IProps) {

    const { hints } = useSelector((state: RootState) => state.cnfSlice.interfaceState);
    const { t } = useTranslation();

    const [vehicleIsValid, setVehicleIsValid] = useState<boolean>(false);

    const [open, setOpen] = useState(false);
    const [openN, setOpenN] = useState(false);

    const [openVehicle, setOpenVehicle] = useState<boolean>(false);
    const [redirectToForm, setRedirectToForm] = useState<boolean>(false);

    const [state, setState] = useState<IAllVehicleResponseDto[]>([]);
    const [vehicleState, setVehicleState] = useState<IVehicleDetails | null>(null);
    const [stateTransport, setStateTransport] = useState<ITransportCreateRequestDto>(defaultTransport());

    const [type, setType] = useState<string>("")
    const [disabled, setDisabled] = useState<boolean>(false);

    const [getAllVehicle, setGetAllVehicle] = useState<boolean>(false);
    const [getVehicleById, setGetVehicleById] = useState<boolean>(false);
    const [localError, setLocalError] = useState(true);
    const debounceVin = useDebounce(stateTransport.vin, 300);

    const [visualSaveButton, setVisualSaveButton] = useState(false);

    const [save, setSave] = useState(false);
    const navigate = useNavigate();

    const dispatch = useDispatch<AppDispatch>();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleVehicleRedactor = () => {
        setOpenN(false);
        setRedirectToForm(true);
        setOpenVehicle(true);
    };

    const handleSetVehicleState = (vehicleState: IVehicleDetails) => {
        setOpenN(true);
        setState([...state, {
            uuid: vehicleState.uuid ? vehicleState.uuid : '',
            brand: vehicleState.brand.name ? vehicleState.brand.name : '',
            model: vehicleState.model ? vehicleState.model : '',
            fullInfo: `${vehicleState.brand.name} ${vehicleState.model} ${vehicleState.year}`,
            year: (vehicleState.year) ? parseInt(vehicleState.year, 10) : 0,
            type: vehicleState.type
        }])
        setVehicleState(vehicleState);
        setStateTransport({ vehicleId: vehicleState.uuid ? vehicleState.uuid : '', vin: '' });
    }

    const handleClickOpenVehicle = () => {
        setOpenVehicle(true);
    }
    const handleCloseVehicle = () => {
        setOpenVehicle(false);
        setRedirectToForm(false);
        setVisualSaveButton(false);
    }

    const handleClickAddNewAuto = () => {
        setVehicleState(null)
        setOpenN(true);
        setType("")
        setRedirectToForm(false);
        setStateTransport(defaultTransport());
    }

    const handleCloseN = () => {
        setOpenN(false);
        setDisabled(false);
        setRedirectToForm(false);
    }

    useEffect(() => {
        (async () => {
            if (getVehicleById) {
                const [error, response] = await TransportService.getVehicleById(stateTransport.vehicleId)

                if (response) {
                    setVehicleState(response.data.body)
                }
                if (error) {
                    dispatch(setLastError(error))
                }
            }
        })()
        setGetVehicleById(false)
    }, [getVehicleById])

    useEffect(() => {
        (async () => {
            if (getAllVehicle) {
                if (type) {
                    const [error, response] = await TransportService.getAllVehicleByType(type)

                    if (response) {
                        setState(response.data.body)

                    }
                    if (error) {
                        dispatch(setLastError(error))
                    }
                }
            }
        })()
        setGetAllVehicle(false)
    }, [getAllVehicle])

    const handleClickOpen = () => { setOpen(true); };
    const handleClose = () => { setOpen(false); };

    const hendlerActiveElement = (item: IResponseAutocompleteAuto) => {
        navigate('/transport/' + item.uuid);
        handleClose();
    }

    useEffect(() => {
        (async () => {
            if (debounceVin && stateTransport.vin.trim().length > 14 && (stateTransport.vehicleId.trim().length > 2)) {
                const [error, response] = await TransportService.checkVinNumber(stateTransport.vin)
                if (response) { setLocalError(!response.data.body) }
                if (error) { }
            }
        })()
    }, [debounceVin]);

    useEffect(() => {
        (async () => {
            if (save) {
                const [er, res] = await TransportService.createTransport(stateTransport)
                if (res) {
                    let id = res.data.response.entityId;
                    navigate('/transport/' + id);
                    dispatch(modifyTransportRefresh());
                    props.snackMsg(`Transport with VIN ${stateTransport.vin} was successfully created.`);
                }
            }
            setSave(false)
            setOpenN(false);
        })()
    }, [save])

    const handlerSaveTransport = async () => {
        handleCloseN();
        setSave(true)
    }

    const handleChangeType = (e: any) => {
        let value: EVehicleType = e.target.value as EVehicleType;
        setType(value);
        setDisabled(true);
        setStateTransport(defaultTransport());
        setGetAllVehicle(true);
    }

    const handleChangeVehicle = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value: string = event.target.value as string;
        setGetVehicleById(true);
        setStateTransport((st) => { return { ...st, vehicleId: value } })

    }

    const handleChangeVin = (e: any) => {
        let value: string = e.target.value as string;
        setStateTransport((st) => { return { ...st, vin: value } })

    }

    useEffect(() => {
        if (stateTransport) {
            let er = false;
            if (stateTransport.vehicleId.trim() === '') er = true;

            if (stateTransport.vin.trim().length <= 15) er = true;

            setLocalError(er);
        }
    }, [stateTransport])

    return (
        <div>
            <Box sx={{ marginBottom: "5px" }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", gap: "5px" }}>
                        <Tooltip
                            title={(hints) ? t("Search transport") : ''}
                            placement={"top"}
                            arrow={true}
                        >
                            <IconButton onClick={handleClickOpen}>
                                <ManageSearchIcon style={styled.icon} color={"primary"} />
                            </IconButton>
                        </Tooltip>

                        <Tooltip
                            title={(hints) ? t("Create transport") : ''}
                            placement={"top"}
                            arrow={true}
                        >
                            <IconButton onClick={handleClickAddNewAuto}>
                                <DashboardCustomizeIcon style={styled.icon} color={"primary"} />

                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            title={(hints) ? t("Vehicle management") : ''}
                            placement={"top"}
                            arrow={true}
                        >
                            <IconButton onClick={handleClickOpenVehicle}>
                                <SettingsSuggestIcon style={styled.icon} color={"primary"} />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>

                <Dialog
                    fullScreen={fullScreen}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                        { }
                    </DialogTitle>
                    <DialogContent>
                        <div style={{ minHeight: "350px", minWidth: "330px" }}>
                            <SearchTransportBarComponent
                                hendlerActiveElement={hendlerActiveElement}
                                placeholder={'Search by transport number or license plate or VIN'}
                            ></SearchTransportBarComponent>
                        </div>

                        <DialogContentText>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    fullScreen={fullScreen}
                    maxWidth={'lg'}
                    open={openN}
                    onClose={handleCloseN}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                        CREATE TRANSPORT
                    </DialogTitle>
                    <DialogContent>
                        <div style={{ display: "flex" }}>
                            <div style={{ display: "flex", width: "600px", height: "380px" }}>
                                <div style={{ width: "250px", display: "flex", flexDirection: "column", gap: "35px", marginTop: "35px", marginBottom: "5px", paddingLeft: "5px", paddingBottom: "10px" }}>
                                    <TextField
                                        select
                                        defaultValue={null}
                                        value={type ? type : ''}
                                        sx={{ width: "200px" }}
                                        inputProps={{ sx: { fontSize: "14px", } }}
                                        label='Type'
                                        onChange={handleChangeType}
                                    >
                                        {ObjectToValueArray(EVehicleType).map((option: string, i) =>
                                            <MenuItem key={i} value={option}>
                                                {option.split('_').join(' ')}
                                            </MenuItem>)}
                                    </TextField>

                                    <div style={{ display: "flex" }} >

                                        <TextField
                                            select
                                            sx={{ width: "200px" }}
                                            disabled={!disabled}
                                            defaultValue={""}
                                            value={stateTransport?.vehicleId}
                                            inputProps={{ sx: { fontSize: "14px", } }}
                                            label="Brand & Model"
                                            onChange={handleChangeVehicle}
                                        >
                                            {state.map((option: IAllVehicleResponseDto, i) =>
                                                <MenuItem key={i} value={option.uuid}>
                                                    {option.fullInfo}
                                                </MenuItem>
                                            )}

                                        </TextField>
                                        {!(stateTransport.vehicleId !== "") && <IconButton onClick={handleVehicleRedactor} disabled={!disabled}>
                                            <PlaylistAddIcon sx={{ marginLeft: "5px" }} />
                                        </IconButton>}
                                    </div>

                                    {<TextField
                                        error={localError && stateTransport.vehicleId.trim().length > 2}
                                        disabled={stateTransport ? stateTransport.vehicleId.trim().length < 2 : false}
                                        inputProps={{ sx: { fontSize: "14px", } }}
                                        autoComplete={"off"}
                                        sx={{ width: "200px" }}
                                        value={stateTransport?.vin}
                                        label="VIN"
                                        onChange={handleChangeVin}
                                    />}
                                </div>
                                {vehicleState && <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "305px" }}>
                                    <VeivVehicleTableComponent vehicle={vehicleState ? vehicleState : null} />
                                </div>}
                                {!vehicleState && <div style={{ display: "flex", justifyContent: "center", alignItems: "center", border: "1px dotted silver", width: "305px" }}>
                                    No information
                                </div>}


                            </div>

                        </div>
                        <DialogContentText>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <div style={{ display: "flex", justifyContent: "flex-end", width: "100%", padding: "20px", gap: "10px" }}>
                            <Button
                                disabled={localError}
                                variant={'contained'}
                                size={'small'}
                                onClick={handlerSaveTransport} startIcon={<SaveAsIcon />}>Create</Button>
                            <Button
                                startIcon={<CloseIcon />}
                                color={'warning'}
                                variant={'contained'}
                                size={'small'}
                                onClick={handleCloseN}>Close</Button>

                        </div>
                    </DialogActions>
                </Dialog>
            </Box>
            <Divider></Divider>
            <Box sx={{ height: "10px" }}></Box>

            <Dialog
                fullScreen={fullScreen}
                maxWidth={'lg'}
                open={openVehicle}
                onClose={handleCloseVehicle}
            >
                <DialogTitle>VEHICLE MANAGEMENT</DialogTitle>
                <DialogContent>
                    <div style={{ width: "920px", height: "700px" }}>
                        <VehicleCrudComponentB snackMess={(s: string) => props.snackMsg(s)} getEditableState={(b) => setVisualSaveButton(b)} getVehicleState={handleSetVehicleState} redirectToForm={redirectToForm} type={type} close={handleCloseVehicle} isValid={(state) => { setVehicleIsValid(state) }} />
                    </div>

                </DialogContent>

                <DialogActions>
                    <div style={{ display: "flex", justifyContent: "flex-end", width: "100%", padding: "20px", gap: "10px" }}>

                        {visualSaveButton &&
                            <Button
                                disabled={!vehicleIsValid}
                                form="myForm"
                                type="submit"
                                startIcon={<SaveAsIcon />}
                                variant={'contained'}
                                size={'small'}
                            >
                                Save
                            </Button>
                        }

                        <Button
                            startIcon={<CloseIcon />}
                            color={'warning'}
                            variant={'contained'}
                            size={'small'}
                            onClick={handleCloseVehicle}
                        >
                            Close
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
}