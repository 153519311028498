import { Box } from "@mui/system";
import { GoogleMap, Polyline, Marker, InfoWindow } from "@react-google-maps/api"
import React, { useState, useEffect } from "react";
import { IMarkerInfoWarehouse } from "../../models/WarehouseModels";
import '../../App.scss';
import { Tooltip, Typography } from "@mui/material";

import { ILoadDeliveryResponseDto } from "../../models/LoadModels";

const containerStyle = {
    width: '100%',
    height: '550px'
};

interface IProps {
    delivery: ILoadDeliveryResponseDto | undefined
}

export const DeliveryMapComponent = (props: IProps) => {

    const { delivery } = props;

    const mapRef = React.useRef(null);
    const [path, setPath] = useState<any>([]);
    const onLoad = React.useCallback(function callback(map: any) { mapRef.current = map }, []);
    const onUnmount = React.useCallback(function callback(map: any) { mapRef.current = null; }, []);
    const [items, setItems] = useState<IMarkerInfoWarehouse[]>([]);

    const [center_company, setCenter_company] = useState({
        lat: 45.07628287127715,
        lng: -93.24481908541335
    });

    const defaultOptions = {
        zoom: 5
    }

    useEffect(() => {
        let points = [];

        if (delivery) {

            if (delivery.pickupAddress) {
                points.push(
                    {
                        lat: delivery.pickupAddress.latitude,
                        lng: delivery.pickupAddress.longitude
                    })
            }

            if (delivery.pickupWarehouse) {
                points.push(
                    {
                        lat: delivery.pickupWarehouse.address.latitude,
                        lng: delivery.pickupWarehouse.address.longitude
                    })
            }

            
            if (delivery.deliveryWarehouse) {
                points.push(
                    {
                        lat: delivery.deliveryWarehouse.address.latitude,
                        lng: delivery.deliveryWarehouse.address.longitude
                    })
            }


            if (delivery.deliveryAddress) {
                points.push(
                    {
                        lat: delivery.deliveryAddress.latitude,
                        lng: delivery.deliveryAddress.longitude
                    })
            }

            
        }
        setPath(points);

        let cx = 0;
        let cy = 0;
        let n = points.length;
        if (n) {
            for(let it of points){
                cx+=it.lat
                cy+=it.lng
            }
            cx = cx/n;
            cy = cy/n;
            setCenter_company({lat:cx,lng:cy})
        }


    }, [delivery])



    return (
        <>
            <Box sx={{ display: "flex", flexDirection: "row", alignContent: "stretch", alignItems: "stretch", height: "100%" }} >
                <div style={{ flex: 1 }}>
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center_company}
                        zoom={6}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                        options={defaultOptions} >

                        {
                            (path) && (path.map(
                                (p: any, index: number) => (
                                    <Marker key={`pa` + index}
                                        position={{ lat: p.lat, lng: p.lng }}
                                        icon={{
                                            url: '/pointEvent.svg',
                                            origin: new window.google.maps.Point(0, 0),
                                            anchor: new window.google.maps.Point(20, 15),
                                            scaledSize: new window.google.maps.Size(40, 40)
                                        }}
                                    />
                                )))
                        }

                        < Polyline
                            path={path}
                            options={{
                                strokeColor: 'red',
                                strokeOpacity: 1,
                                strokeWeight: 6
                            }}
                        />

                        {(path) && (path.map(
                            (p:any, index:number) => (
                                <InfoWindow key={`q` + index}
                                    position={{ lat: p.lat, lng: p.lng }}
                                    >
                                    <div style={{ textAlign: "center", cursor: "pointer" }} >
                                        <Tooltip
                                            title={p.label ? p.label : ''}
                                            placement={"top"}
                                            arrow={true}
                                        >
                                            <Box sx={{ display: 'flex', gap: "10px", justifyContent:"center", alignItems: "center"  }}>
                                                <Typography color={'primary'}>{index+1}</Typography>                                                                                                                                    
                                            </Box>
                                        </Tooltip>
                                    </div>
                                </InfoWindow>
                            )))
                        }
                    </GoogleMap>

                    {/* <pre>
                        {JSON.stringify(delivery, undefined, 2)}
                    </pre> */}


                    {/* <pre style={{ maxWidth: "300px", overflow: "hidden" }}>
                        {JSON.stringify(delivery, undefined, 2)}
                    </pre> */}

                </div>
            </Box>
        </>
    )
}