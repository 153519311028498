import { AddressResponseDto } from "./AddressModels"
import { ICustomerIdNameResponseDto } from "./CustomerModels";
import { IWarehouseIdNameAddressResponseDto } from "./WarehouseModels";

export enum ELoadSettingsType {
  Fragility_Type = 'Fragility_Type',
  Pallet_Type = 'Pallet_Type'
}

export interface deliveryStateList {
  itemUuid: string;
  deliveryUuid: string;
}

export interface IFavouritesLoad {
  uuid: string;
  customer: ICustomerIdNameResponseDto;
  idno: string;
  reviewMark: number;
  status: ELoadStatus;
}

export interface ILoadSummary {
  customer: ICustomerIdNameResponseDto;
  idno: string;
  status: ELoadStatus;
  reviewMark: number;
  totalNumberOfItems: number;
  numberOfLoadedItems: number;
  numberOfDeliveredItems: number;
  numberOfPayedItems: number;
  totalAmount: number;
  payedAmount: number;
  hotDetails: ILoadHotDetailsResponseDto;
  pickupAddresses?: Array<String>;
  pickupWarehouses?: Array<IWarehouseIdNameAddressResponseDto>;
  deliveryAddresses?: Array<String>;
  deliveryWarehouses?: Array<IWarehouseIdNameAddressResponseDto>
}

export interface ILoadItemRequestDto {
  loadId: string;
  uuid?: string;
  name: string;
  units: number;
  unitWidth: number;
  unitHeight: number;
  idno: string;
  unitLength: number;
  unitWeight: number;
  fragilityLevel: string;
  fragilityTypeId: string;
  amount: string;
  palletTypeId: string;
  description: string;
}

interface IPalletDTO {
  uuid: string;
  name: string;
  height: number;
  width: number;
  length: number;
  weight: number;
}

export interface ILoadItemResponseDto {
  id?: string;
  uuid?: string;
  name: string;
  idno: string;
  units: number;
  unitWidth: number;
  unitHeight: number;
  unitLength: number;
  unitWeight: number;
  fragilityLevel: string;
  fragilityType: {
    uuid: string,
    name: string,
    caption: string
  };
  amount: number;
  palletType: IPalletDTO;
  description: string;
  state: string;
  readonly: boolean;
}

export interface ICreateLoadDTO {
  customerUuid: string;
  idno: string;
}

export interface IFragilityType {
  uuid: string;
  name: string;
  caption: string;
}

export interface ILoadHotDetailsResponseDto {
  hot: boolean;
  hotItems: number;
  hottestItemDate: number;
}

export interface ILoadResponseDTO {
  uuid: string;
  idno: string;
  status: ELoadStatus;
  reviewMark: number;
  customer: ICustomerIdNameResponseDto;
  totalNumberOfItems: number;
  numberOfLoadedItems: number;
  numberOfDeliveredItems: number;
  numberOfPayedItems: number;
  totalAmount: number;
  payedAmount: number;
  readonly: boolean;
  hotDetails: ILoadHotDetailsResponseDto;
  pickupAddresses?: Array<string>;
  pickupWarehouses?: Array<IWarehouseIdNameAddressResponseDto>;
  deliveryAddresses?: Array<string>;
  deliveryWarehouses?: Array<IWarehouseIdNameAddressResponseDto>;
  favorite: boolean;
  createdBy: string;
  createdDate: number;
  updatedBy?: string;
  updatedDate?: number;
}

export interface ILoadDetailsDTO {
  uuid: string;
  idno: string;
  customer: ICustomerIdNameResponseDto;
  metadata: {
    step: number,
    completed: boolean
  };
  status: string;
  readonly: boolean;
}

export enum ELoadFragilityType {
  LOWEST = 'LOWEST',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  HIGHEST = 'HIGHEST'
}

export enum ELoadStatus {
  INCOMPLETE = 'INCOMPLETE',
  PENDING = 'PENDING',
  LOADED = 'LOADED',
  IN_ROUTE = 'IN_ROUTE',
  DELIVERED = 'DELIVERED',
  INVOICE = 'INVOICE',
  PAYED = 'PAYED'
}

export interface IReviewLoadModel {
  message: string;
  mark: number;
  email: string;
  createdDate: number;
}

export enum ELoadDocumentType {
  DOCUMENT = 'DOCUMENT'
}

export interface IResponseLoadAutocomplete {
  uuid: string;
  idno: string;
  status: ELoadStatus;
  customer: ICustomerIdNameResponseDto;
  reviewMark: number;
}

export interface ILoadDeliveryRequestDto {
  name?: string;
  idno?: string;
  uuid?: string;
  loadItemId: string;
  pickupAddressId: string;
  deliveryAddressId: string;
  pickupWarehouseAddressId?: string;
  deliveryWarehouseAddressId?: string;
  deliveryWarehouseAddress?: any;
  pickupWarehouseAddress?: any;
  deliveryType: string;
  deliveredBy?: string;
  description: string;
  pickupAddress?: any | undefined;
  deliveryAddress?: any | undefined;
}

export interface ILoadDeliveryResponseDto {
  id?: string;
  uuid: string;
  loadItem: {
    uuid: string,
    name: string,
    idno: string,
    units: number,
    width: number,
    height: number,
    length: number,
    weight: number,
    fragilityLevel: string,
    fragilityType: {
      uuid: string,
      name: string,
      caption: string
    },
    amount: number,
    palletType?: IPalletDTO,
  };
  pickupAddress?: AddressResponseDto;
  deliveryAddress?: AddressResponseDto;
  pickupWarehouse: any | null;
  deliveryWarehouse: any | null;
  deliveryType: string;
  deliveredBy: number;
  description: string;
  readonly: boolean;
}

export enum ELoadDeliveryType {
  STANDARD = 'STANDARD',
  PRIORITY = 'PRIORITY'
}

export interface ILoadDocumentUpdateDTO {
  uuid: string;
  type: string;
  expirationDate: number;
  number: string;
  caption: string;
}

export interface ILoadDocumentCreateDTO {
  attachments?: any[];
  type: string;
  expirationDate: number;
  number: string;
  caption: string;
  uuid: string;
}

export interface ILoadDetailsStep {
  uuid: string;
  description: string | null;
}

export interface ILoadRegistrationResponseDto {
  uuid: string,
  customer: ICustomerIdNameResponseDto,
  idno: string
}

export interface ILoadRegistrationRequestDto {
  uuid: string,
  customerId: string,
  idno: string
}

export interface ILoadIdIdnoCustomerResponseDto {
  uuid: string;
  idno: string;
  customer: ICustomerIdNameResponseDto
}