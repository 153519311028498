import { Autocomplete, Box, Button, createFilterOptions, Divider, FormControl, FormControlLabel, FormLabel, ListItem, ListItemButton, ListItemText, Radio, RadioGroup, TextField, Tooltip, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { PrivilegeType } from "../../models/PermissionModels";
import PermissionService from "../../services/PermissionsService";
import { setLastError } from "../../store/reducers/cnfSlice";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import SecureDialogComponent from "../CustomComponentModule/SecureDialogComponent";
import { EButtonColor } from "../../models/EnumGeneral";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import ControlStateInCRUDComponent from "../CustomComponentModule/ControlStateInCRUDComponent";
import ReplyIcon from '@mui/icons-material/Reply';
import { useDebounce } from "../../hooks/useDebounce";

interface ItemOptionType {
    uuid: string,
    name?: string,
    caption?: string,
    inputValue?: string;
    readonly?: string;
    type?: PrivilegeType | null;
    itemUuid: string,
}
interface IProps {
    snackMsg: (text: string) => void,
    isNew: (b: boolean) => void,
    isEditable: (b: boolean) => void,
}
const PrivilegesCrud = (props: IProps) => {
    const { hints } = useSelector((state: RootState) => state.cnfSlice.interfaceState);


    const [listItem, setListItem] = React.useState<ItemOptionType[]>([])
    const [currentValue, setCurrentValue] = React.useState<ItemOptionType | null>(null);
    const [state, setState] = React.useState(0);
    const [value, setValue] = React.useState<ItemOptionType | null>(null);
    const [refresh, setRefresh] = React.useState(true);
    const filter = createFilterOptions<ItemOptionType>();
    const dispatch = useDispatch<AppDispatch>();

    const handleClickList = (item: ItemOptionType) => {
        setValue({ ...item });
    }
    const handleBackToList = () => {
        setState(0);
        setValue(null);
        setCurrentValue(null);
        props.isEditable(false);
        props.isNew(false);
    }
    const handleEditItem = () => {
        setState(1);
        props.isEditable(true);
    }
    const hendlerNewItem = () => {
        setCurrentValue({
            caption: "",
            uuid: '#',
            itemUuid: "#"
        })
        setState(1);
        setValue({ itemUuid: "#", uuid: "" })
        props.isNew(true);
    }
    const handleGetState = (n: number) => {
        setState(n);
        setRefresh(true)
    }

    React.useEffect(() => {

        if (value === null) {
            setState(0);
            setCurrentValue(null)
        }
        if (value !== null && value.itemUuid === "#") {
            setState(1);
            setCurrentValue({
                caption: value.uuid,
                uuid: '#',
                itemUuid: value.itemUuid
            })
            props.isNew(true)
        }

        if (value !== null && value.itemUuid !== "#") {
            setState(2);
            setCurrentValue(value)
        }
    }, [value])

    const hendlerDeleteItem = () => {
        if (currentValue) {
            (async () => {
                const [error, response] = await PermissionService.deletePrivilege(currentValue?.itemUuid ? currentValue?.itemUuid : '')
                if (response) {
                    setListItem((st) => st.filter(e => e.uuid !== currentValue?.uuid));
                    props.snackMsg("Privilege " + currentValue.caption + " was removed successfully")
                    setCurrentValue(null);
                    setValue(null);
                    setState(0);
                    setRefresh(true);
                }
                if (error) {
                    dispatch(setLastError(error))
                }
            })()
        }
    }

    React.useEffect(() => {
        (async () => {
            if (refresh) {
                const [error, response] = await PermissionService.getAllPrivilege()
                if (response) {
                    let arr = response.data.body as ItemOptionType[];
                    let a: any = arr.map((e: ItemOptionType) => { return { ...e, itemUuid: e.uuid, uuid: e.caption } })
                    setListItem(a);
                    setValue(null);
                    setCurrentValue(null);
                }
                if (error) { dispatch(setLastError(error)) }
                setRefresh(false);
            }
        })()
    }, [refresh])



    function renderRow(props: ListChildComponentProps) {
        const { index, style } = props;
        return (
            <div>
                <ListItem divider style={style} key={index} component="div" disablePadding>
                    <ListItemButton
                        selected={(currentValue?.itemUuid === listItem[index].itemUuid)}
                        onClick={() => { handleClickList(listItem[index]) }}
                        style={{ fontSize: "10px" }}
                    >
                        <ListItemText primary={<Typography sx={{ fontSize: "12px" }}>{listItem[index].caption + " (" + listItem[index].type + ")"}</Typography>} />
                    </ListItemButton>
                </ListItem>
            </div>
        );
    }
    const getSnackMess = (s: string) => {
        props.snackMsg(s)
    }

    return (
        <>
            <div style={{ display: "flex", flexDirection: "row", marginTop: "5px", marginBottom: "10px", maxWidth: "550px" }}>
                {(state !== 1) && <Autocomplete
                    value={value}
                    onChange={(event, newValue) => {
                        if (typeof newValue === "string") {
                            setValue({ uuid: newValue, itemUuid: "#" });
                        } else if (newValue && newValue.inputValue) {
                            setValue({ uuid: newValue.inputValue, itemUuid: "#" });
                        } else {
                            setValue(newValue);
                        }
                    }}
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        const { inputValue } = params;
                        const isExisting = options.some((option) => inputValue === option.uuid);
                        if (inputValue !== "" && !isExisting) {
                            setValue(null);
                            filtered.push({ inputValue, uuid: `Add "${inputValue}"`, itemUuid: "#" });
                        }
                        return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    freeSolo
                    handleHomeEndKeys
                    options={listItem}
                    getOptionLabel={(option) => {
                        if (typeof option === "string") { return option; }
                        if (option.inputValue) { return option.inputValue; }
                        if (option.type) {
                            return option.uuid + " " + option.type
                        } else {
                            return option.uuid
                        }
                    }}
                    renderOption={(props, option) =>
                        <li {...props}>
                            <Typography sx={{ minWidth: "220px", fontSize: "12px", fontWeight: "bold" }} >{option.uuid}</Typography>
                            {option.type && <Typography sx={{ minWidth: "220px", fontSize: "12px", color: "silver" }}>[{option.type}]</Typography>}
                        </li>}
                    sx={{ width: 550, marginTop: "10px" }}
                    renderInput={(params) => (
                        <TextField  {...params} label={"PRIVILEGE"} />
                    )}
                />}
                {state === 1 && <Button variant="contained" size={"small"} sx={{ width: "150px", marginTop: "10px" }} onClick={handleBackToList} color={'warning'} >
                    <ReplyIcon fontSize='small' sx={{ marginRight: "15px" }} /> <Typography variant="subtitle2"> Back To List</Typography>
                </Button>}
                {!value &&
                    <Tooltip
                        title={"Add new privilege"}
                        arrow={true}
                        placement={"top"}
                    >
                        <Button
                            variant={'contained'}
                            size={'medium'} sx={{ marginLeft: "10px", marginTop: "10px" }}
                            onClick={hendlerNewItem}
                        >
                            <PlaylistAddIcon fontSize={'medium'} />
                        </Button>
                    </Tooltip>
                }

            </div>

            {(state === 0 || state === 2) && <Divider />}


            <Box
                sx={{ width: '550px', height: 400, paddingTop: "10px", display: "flex", flexDirection: "row", marginBottom: "10px" }}
            >
                <div>
                    {(state !== 1) &&
                        <FixedSizeList
                            height={400}
                            width={280}
                            itemSize={46}
                            itemCount={listItem.length}
                            overscanCount={10}
                        >
                            {renderRow}
                        </FixedSizeList>}
                </div>

                <div style={{
                    width: state === 1 ? "100%" : "270px",
                    height: "400px",
                    border: "1px dashed silver",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "3px"
                }}>

                    {state === 0 && <Typography sx={{ color: "silver" }}> No selected Item </Typography>}
                    {state === 1 && <div> {currentValue?.uuid && <EditItemComponent snackMess={getSnackMess} currentValue={currentValue} getState={handleGetState} />}</div>}
                    {state === 2 && <div style={{ display: "flex", flexDirection: "column", gap: "20px", justifyContent: "flex-start" }}>
                        <Tooltip
                            title={currentValue?.caption}
                            arrow={true}
                            placement="top"
                        >
                            <TextField
                                label="Name"
                                disabled
                                value={currentValue?.caption}
                                inputProps={{ sx: { fontSize: "12px" } }}
                                sx={{ width: "240px" }}
                            />
                        </Tooltip>
                        <Tooltip
                            title={currentValue?.name}
                            arrow={true}
                            placement="top"
                        >
                            <TextField
                                label="URL"
                                disabled
                                value={currentValue?.name}
                                inputProps={{ sx: { fontSize: "12px" } }}
                                sx={{ width: "240px" }}
                            />
                        </Tooltip>
                        <div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                            <Typography sx={{ color: "silver", paddingRight: "5px", paddingLeft: "20px" }}>Type:</Typography>
                            <div style={{ display: "flex" }}>
                                <Radio
                                    checked={currentValue?.type === PrivilegeType.READ}
                                    value={PrivilegeType.READ}
                                    disabled
                                    name="radio-buttons"
                                />
                                <Typography sx={{ color: "silver", paddingTop: "10px" }} variant="subtitle2">Read</Typography>
                            </div>
                            <div style={{ display: "flex" }}>


                                <Radio
                                    checked={currentValue?.type === PrivilegeType.WRITE}
                                    value={PrivilegeType.WRITE}
                                    name="radio-buttons"
                                    disabled
                                />
                                <Typography sx={{ color: "silver", paddingTop: "10px" }} variant="subtitle2">Write</Typography>
                            </div>
                        </div>

                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Tooltip
                                title={(hints) ? "Open the privilege for editing" : ""}
                                arrow={true}
                                placement={"top"}
                            >

                                <Button
                                    size={'small'}
                                    variant={'contained'}
                                    sx={{ marginRight: "10px" }}
                                    onClick={handleEditItem} >
                                    <AutoAwesomeIcon />
                                </Button>
                            </Tooltip>

                            {!value?.readonly &&
                                <Tooltip
                                    title={(hints) ? "Remove privilege" : ""}
                                    arrow={true}
                                    placement={"top"}
                                >
                                    <Box>
                                        <SecureDialogComponent
                                            answerYES={hendlerDeleteItem}
                                            text={''}
                                            icon={<DeleteForeverIcon fontSize='small' sx={{ marginRight: "5px", marginLeft: "5px" }} />}
                                            color={EButtonColor.error}
                                            iconButton={false}
                                        />
                                    </Box>
                                </Tooltip>
                            }
                        </div>

                    </div>
                    }
                </div>
            </Box>
        </>
    )

}; export default PrivilegesCrud;


interface IEditItemComponent {
    currentValue: ItemOptionType;
    getState: (n: number) => void;
    snackMess: (s: string) => void;
}

const EditItemComponent = (props: IEditItemComponent) => {
    const { currentValue, getState } = props;
    const [error, setError] = React.useState(1);
    const [save, setSave] = React.useState(false);
    const [saved, setSaved] = React.useState(false);
    const [value, setValue] = React.useState<ItemOptionType>({ uuid: '', name: '', caption: '', readonly: '', itemUuid: "" });
    const [formError, setFormError] = React.useState<boolean>(false);
    const debouncedCaption = useDebounce(value.caption ? value.caption : "", 300);
    const [textErrors, setTextErrors] = React.useState<string[]>([]);
    const [snepShot, setSnepShot] = React.useState<string>('');
    const [snapshot, setSnapshot] = React.useState<string>("");
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (saved) {
            getState(0);
        }
        setSaved(false);
    }, [saved])


    const handleChangeType = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value as PrivilegeType
        setValue(st => ({ ...st, type: value }))
    };
    const handlerReset = () => {
        setValue(JSON.parse(snepShot));
    }

    const handlerSaveItem = () => { setSave(true); }

    const handlerChangeName = (e: any) => {
        let value: string = (e.target.value as string);
        setValue(st => ({ ...st, name: value }))
    }

    const handlerChangeCaption = (e: any) => {
        let value: string = (e.target.value as string);
        setValue(st => ({ ...st, caption: value }))
    }

    React.useEffect(() => {
        if (currentValue) {
            setValue({ ...currentValue });
            setSnapshot(JSON.stringify({ caption: currentValue.caption, type: currentValue.type }));
            setSnepShot(JSON.stringify(currentValue))
        }
    }, [currentValue])

    React.useEffect(() => {
        if (save) {
            (async () => {

                let body = {}
                let add: boolean = value.uuid === '#';


                if (add) {
                    body = {
                        name: value.name,
                        caption: value.caption,
                        type: value.type
                    }
                } else {
                    body = {
                        uuid: value.itemUuid,
                        name: value.name,
                        caption: value.caption,
                        type: value.type
                    }
                }

                const [error, response] = add
                    ? await PermissionService.createPrivilege(body)
                    : await PermissionService.updatePrivilege(body);

                if (response) {
                    setSaved(true);
                    props.snackMess(add ? "The Privilege " + value.caption + " was created successfully " : "The Privilege " + value.caption + " was updated successfully ")
                }

                if (error) { dispatch(setLastError(error)) }

            })()
        }
        setSave(false);
    }, [save])

    React.useEffect(() => {



    }, [debouncedCaption, value.type]);

    React.useEffect(() => {

        if (value && snepShot !== '') {
            (async () => {
                let err: number = 0;
                let txErr: string[] = []
                let existPrivilege: boolean = false;
                if (debouncedCaption) {


                    if (!(JSON.stringify({ caption: value.caption, type: value.type }) === snapshot)) {
                        if (debouncedCaption && value.type) {

                            let body: object = {
                                caption: debouncedCaption,
                                type: value.type,
                            }

                            const [error, response] = await PermissionService.isAvailablePrivilege(body)

                            if (response) {
                                let ras = response.data.body
                                if (ras) {
                                    existPrivilege = false;
                                } else {
                                    existPrivilege = true;
                                }
                            }


                        }
                    }
                    if (debouncedCaption.length < 2 || existPrivilege) {
                        err = -1
                        if (!existPrivilege)
                            txErr.push("* The name should have the minimum length 2")

                    }
                } else {
                    err = -1
                    txErr.push("* The name should have the minimum length 2")

                }

                if (value.name) {
                    if (value.name?.length < 2) {
                        err = -1
                        txErr.push("* The url should have the minimum length 2")
                    }
                } else {
                    err = -1
                    txErr.push("* The url should have the minimum length 2")
                }
                if (value.type) {
                    if (value.type.length < 2 || existPrivilege) {
                        err = -1
                        if (!existPrivilege)
                            txErr.push("* The type should be selected")
                    }
                } else {
                    err = -1
                    txErr.push("* The type should be selected")
                }
                if (existPrivilege || err === -1) {
                    setError(-1);
                    if (existPrivilege) txErr.push("* This privilege is already used");
                }
                if (JSON.stringify(value) !== snepShot) {
                    if ((err !== -1 && !existPrivilege)) {
                        setError(1)
                    }
                } else {
                    if (err === 0) {
                        txErr.push("* Some changes are required");
                        setError(-1)
                    }
                }


                setTextErrors(txErr);
            })()

        }
    }, [value.name, value.type, debouncedCaption])

    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "20px" }}>
            <div style={{ display: "flex", gap: "30px" }}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "20px" }}>

                    <Tooltip
                        title={currentValue?.caption}
                        arrow={true}
                        placement="top"
                    >

                        <TextField
                            label="Name"
                            value={value.caption || ''}
                            onChange={handlerChangeCaption}
                            inputProps={{ sx: { fontSize: "13px" } }}
                            sx={{ width: "340px" }}
                        />
                    </Tooltip>
                    <Tooltip
                        title={currentValue?.name}
                        arrow={true}
                        placement="top"
                    >

                        <TextField
                            autoFocus
                            autoComplete={'off'}
                            value={value.name || ''}
                            label="URL"
                            onChange={handlerChangeName}
                            style={{ width: "340px" }}
                            inputProps={{ sx: { fontSize: "13px" } }}
                            InputLabelProps={{ sx: { fontSize: "17px" } }}
                        />
                    </Tooltip>
                    <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", borderRadius: "5px", minWidth: "340px", paddingLeft: "30px" }}>
                        <Typography variant={"subtitle1"} sx={{ paddingRight: "40px", }}>Type</Typography>
                        <FormControl>

                            <RadioGroup
                                row

                                value={value.type ? value.type : ""}
                                onChange={handleChangeType}
                            >
                                <FormControlLabel value={PrivilegeType.READ} control={<Radio />} label={PrivilegeType.READ} />
                                <FormControlLabel value={PrivilegeType.WRITE} control={<Radio />} label={PrivilegeType.WRITE} />
                            </RadioGroup>
                        </FormControl>
                    </div>

                </div>
                {error !== 0 &&
                    <div style={{ display: "flex", minWidth: "53px" }} >
                        <ControlStateInCRUDComponent
                            error={error}
                            textErrors={textErrors}
                            handlerReset={handlerReset}
                            handlerSave={handlerSaveItem}
                        />
                    </div>}
            </div>



        </div>

    )
}