import { Divider, Typography } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { dateToHM } from "../../helpers/dateHelper";
import { IHistory } from "../../models/NotificationModels";
import HistoryListService from "../../services/HistoryListService";
import { RootState } from "../../store/store";

const HistoryActionsComponent = () => {
    const { pathURL } = useSelector((state: RootState) => state.cnfSlice.interfaceState);
    const { auth } = useSelector((state: RootState) => state.cnfSlice);
    const [paths, setPaths] = useState<IHistory[]>([]);

    useEffect(() => {
        if (auth) {
            setPaths(HistoryListService.getAllHistory(auth?.username));
        }
    }, [pathURL])

    return (
        <>  <div style={{maxHeight:"calc(100vh - 200px)", overflowY:'scroll', marginTop:"10px"}}>
            {paths.map((p, i) =>
                <ActionLinkComponent action={p} key={i} />
            )}
            </div>
        </>
    )
}

export default HistoryActionsComponent;

interface IProps {
    action: IHistory
}

const ActionLinkComponent = (props: IProps) => {

    const theme = useTheme();

    const boxSX = {
        color: theme.palette.action.disabled,
        "&:hover": {
            color: theme.palette.primary.main,
            cursor:"pointer"                        
        },
    }

    const navigate = useNavigate();
    const hendlerLink = () => {
        navigate(props.action.link);
    }

    return (
        <>
            <Divider></Divider>
            <Box sx={boxSX}>
            <div style={{ display: "flex", padding: "7px", paddingTop:"5px"}} onClick={hendlerLink}>
                <div style={{ width: "60px" }}>
                    <Typography sx={boxSX}  variant={'body2'} fontSize={'small'}>{dateToHM(props.action.dt)}</Typography>
                </div>
                <div>
                    <div></div>
                    <div>
                        <Typography 
                        fontSize={'small'}
                        sx={{
                            minWidth: "210px",
                            maxWidth: "210px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",


                        }} color={'gray'} variant={'body2'}  >{props.action.link.replace('/', '')}</Typography>
                    </div>
                </div>
            </div>
            </Box>
        </>
    )
}