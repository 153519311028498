import { INotification } from "../models/NotificationModels";

const notification = "notifications";
const max = 50;

class NotificationService {

    public static getAllNotification = (): Array<INotification> => {
        let s = localStorage.getItem(notification)
        if (s) { const n = JSON.parse(s); return n; } else { return [] }
    }

    public static setNotification = async (m: INotification) => {
     
        let allNotifications = this.getAllNotification();
        if (allNotifications) {

            allNotifications = [...[m], ...allNotifications];
            if (allNotifications.length > max) { allNotifications = allNotifications.slice(0, max); }
            localStorage.setItem(notification, JSON.stringify(allNotifications));
        }
        return true
    }

    public static getCountOfTrueNotifications = (): number => {
        let n = this.getAllNotification().filter((e) => e.state === true).length;
        return n;
    }

    public static changeNotificationStatus = (m: number) => {
        let n = this.getAllNotification();
        const index = n.findIndex(element => {
            if (element.dt === m) {
                return true;
            }
            return false;
        });
        n[index] = { ...n[index], state: !n[index].state }
        let trueNotification = n.filter((e) => e.state === true)
        n = n.filter((e) => e.state === false).sort((a1, a2) => a2.dt - a1.dt);

        localStorage.setItem(notification, JSON.stringify([...trueNotification, ...n]));
    }
    public static checkAllNotificationData = () => {
        let notifications = this.getAllNotification();
        const timestamp = Date.now();
        const currentDate = new Date(timestamp).getDate();
        let newNotifications: INotification[] = notifications;

        notifications.map((e: INotification) => {

            let notificationDate = new Date(e.dt).getDate();
            if ((notificationDate < currentDate) && (!e.state)) {
                newNotifications = newNotifications.filter((m) => m.dt !== e.dt);
            }
        })
        localStorage.setItem(notification, JSON.stringify(newNotifications));

    }
}


export default NotificationService