import * as React from 'react';
import { useTheme, styled } from '@mui/material/styles';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import Autocomplete, {
  AutocompleteCloseReason,
  autocompleteClasses,
} from '@mui/material/Autocomplete';
import ButtonBase from '@mui/material/ButtonBase';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { LabelType } from '../../models/AuditModels';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';


interface PopperComponentProps {
  anchorEl?: any;
  disablePortal?: boolean;
  open: boolean;
}

const StyledAutocompletePopper = styled('div')(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none',
    margin: 0,
    color: 'inherit',
    fontSize: 13,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 8,
      borderBottom: `1px solid  ${theme.palette.mode === 'light' ? ' #eaecef' : '#30363d'
        }`,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
      {
        backgroundColor: theme.palette.action.hover,
      },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
}));

function PopperComponent(props: PopperComponentProps) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}

const StyledPopper = styled(Popper)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'}`,
  boxShadow: `0 8px 24px ${theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'
    }`,
  borderRadius: 6,
  width: 300,
  zIndex: theme.zIndex.modal,
  fontSize: 11,
  color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
  padding: 10,
  width: '100%',
  borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'
    }`,
  '& input': {
    borderRadius: 4,
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#0d1117',
    padding: 8,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    border: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
    fontSize: 14,
    '&:focus': {
      boxShadow: `0px 0px 0px 3px ${theme.palette.mode === 'light'
        ? 'rgba(3, 102, 214, 0.3)'
        : 'rgb(12, 45, 107)'
        }`,
      borderColor: theme.palette.mode === 'light' ? '#0366d6' : '#388bfd',
    },
  },
}));

const Button = styled(ButtonBase)(({ theme }) => ({

  fontSize: 14,
  width: '100%',
  textAlign: 'left',
  paddingBottom: 2,
  color: theme.palette.mode === 'light' ? '#586069' : '#8b949e',
  fontWeight: 600,
  '&:hover,&:focus': {
    color: theme.palette.mode === 'light' ? '#0366d6' : '#58a6ff',
  },
  '& span': {
    width: '100%',
  },
  '& svg': {
    width: 16,
    height: 16,
  },
}));

interface IProps {
  vs: number;
  title: string;
  info: LabelType[];
  reset: number;
  fixValue: (v: LabelType[]) => void
}
export default function AutocompletComponent(props: IProps) {
  const { valueFilter } = useSelector((state: RootState) => state.auditSlice);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [value, setValue] = React.useState<LabelType[]>([]);
  const [pendingValue, setPendingValue] = React.useState<LabelType[]>([]);
  const [labels, setLabels] = React.useState<LabelType[]>([]);
  const [res, setRes] = React.useState(true);
  const theme = useTheme();

  React.useEffect(() => {
    setValue([]);
    setRes(false);
  }, [props.reset])

  React.useEffect(() => {
    props.fixValue(value)
  }, [value])

  React.useEffect(() => {
    if (JSON.stringify(valueFilter) !== JSON.stringify([[], [], [], []]))
      setValue(valueFilter[props.vs]);
  }, [valueFilter])

  const handlerDelete = (label: LabelType) => {
    setValue(value.filter(e => JSON.stringify(e) !== JSON.stringify(label)));
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setPendingValue(value);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setValue(pendingValue);
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  React.useEffect(() => {
    setLabels(props.info)
  }, [props.info])

  const open = Boolean(anchorEl);
  const id = open ? 'github-label' : undefined;

  return (
    <React.Fragment>
      <Box sx={{ width: 300, fontSize: 11, }}>

        <Button disableRipple onClick={handleClick} color={value.length > 0 ? 'warning' : 'inherit'}>
          <DragIndicatorIcon color={value.length > 0 ? 'warning' : 'inherit'} />
          <span style={{ marginLeft: "15px" }}>{props.title.toUpperCase()}</span>
        </Button>
        <div style={{ maxHeight: "100px", overflow: "auto" }}>
          {value.map((label) => (
            <Box key={label.name}>
              <div style={{ display: "flex", flexDirection: "row", height: "25px", marginLeft: "30px" }}>

                <div style={{ display: "flex", width: "15px", justifyContent: "center", alignItems: "center", borderRadius: "2px" }}>
                  <Button disableRipple
                    onClick={() => handlerDelete(label)}>
                    <CloseIcon fontSize={'small'} color={'error'} />
                  </Button>
                </div>

                <div style={{ display: "flex", width: "220px", height: "25px", paddingLeft: "10px", alignItems: "center" }}>
                  <Typography variant={'subtitle2'} color={'silver'}>
                    {label.name.toUpperCase()}
                  </Typography>
                </div>

              </div>
            </Box>
          ))}
        </div>
      </Box>


      <StyledPopper id={id} open={open} anchorEl={anchorEl} placement="bottom-start">
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", paddingRight: "40px", paddingTop: "10px", marginLeft: "30px" }} >
              <div></div>
              {<Button sx={{ width: "12px", fontSize: "12px" }} onClick={handleClose} >APPLY</Button>}
            </Box>

            <Autocomplete
              open
              multiple
              onClose={(
                event: React.ChangeEvent<{}>,
                reason: AutocompleteCloseReason,
              ) => {
                if (reason === 'escape') {
                  handleClose();
                }
              }}
              value={pendingValue}
              onChange={(event, newValue, reason) => {
                if (
                  event.type === 'keydown' &&
                  (event as React.KeyboardEvent).key === 'Backspace' &&
                  reason === 'removeOption'
                ) {
                  return;
                }
                setPendingValue(newValue);
              }}
              disableCloseOnSelect
              PopperComponent={PopperComponent}
              renderTags={() => null}
              noOptionsText="No values"
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Box
                    component={DoneIcon}
                    sx={{ width: 17, height: 17, mr: '5px', ml: '-2px' }}
                    style={{
                      visibility: selected ? 'visible' : 'hidden',
                    }}
                  />
                  <Box
                    sx={{
                      flexGrow: 1,
                      '& span': {
                        color:
                          theme.palette.mode === 'light' ? '#586069' : '#8b949e',
                      },
                    }}
                  >
                    {option.name.toUpperCase()}
                    <br />
                  </Box>
                  <Box
                    component={CloseIcon}
                    sx={{ opacity: 0.6, width: 18, height: 18 }}
                    style={{
                      visibility: selected ? 'visible' : 'hidden',
                    }}
                  />
                </li>
              )}
              options={[...labels].sort((a, b) => {
                let ai = value.indexOf(a);
                ai = ai === -1 ? value.length + labels.indexOf(a) : ai;
                let bi = value.indexOf(b);
                bi = bi === -1 ? value.length + labels.indexOf(b) : bi;
                return ai - bi;
              })}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <StyledInput
                    ref={params.InputProps.ref}
                    inputProps={params.inputProps}
                    autoFocus
                    placeholder="Search ..."
                  />
                </div>
              )}
            />
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </React.Fragment>
  );
}