import { Divider, Typography, useTheme } from "@mui/material";
import { IFavoritesCustomer } from "../../models/CustomerModels";
import './../../SearchBar.scss';

interface IProps {
  item: IFavoritesCustomer;  
  hendlerActiveElement:(item:any)=>void;
} 

export default function ItemCustomerInSearchListComponent(props:IProps) {
  const {hendlerActiveElement,item} = props;
  const customer: IFavoritesCustomer = props.item;
  const theme = useTheme();
 
  return (
    <>
      <div
        className="dataItem"
        style={{ marginTop: "4px", marginBottom: "4px", cursor:"pointer", display:"flex", alignItems:"center", justifyContent:"start", gap:"15px", paddingLeft:"10px" }}
        onClick={()=>hendlerActiveElement(item)}
      >       
        <Typography
          color={theme.palette.primary.main}
          variant={"caption"}>{customer.name} ({customer.idno}) </Typography>        
      </div>
      <Divider></Divider>      
    </>
  );
}