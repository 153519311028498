import {Box} from "@mui/system";
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { Avatar, Badge, Divider, IconButton, Typography } from "@mui/material";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { IWayInfoInMap } from "../../models/MapModels";
import { setMapStars, setIdSelected } from "../../store/reducers/mapSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";



interface IProps {
    star: boolean,
    info: IWayInfoInMap
}

interface IProps {

}

export const WayListComponent = (p: IProps) => {

    const { jsMapIsLoaded, idSelected } = useSelector((state: RootState) => state.mapSlice);
    const dispatch = useDispatch();   

    const handleChangeStart = (id: string) => { dispatch(setMapStars(id)); };

    return (
        <>
            <Badge
                badgeContent={p.info.issues}
                color="error"
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                variant="dot"
                sx={{ width: "100%", marginTop: "8px" }}
            >
                <Box sx={{
                    border: ` ${(p.info.id === idSelected) ? "2px solid red" : "1px solid silver"}`,
                    borderRadius: "5px",
                    padding: "4px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 1,
                    width: "100%"
                }}>
                    {/* <StarIcon style={{color:"red"}}></StarIcon> */}
                    <Box
                        sx={{
                            paddingLeft: "1px",
                            marginLeft: "1px"
                        }}
                    >
                        {(!p.star)
                            ? <StarBorderIcon
                                onClick={() => { handleChangeStart(p.info.id); }}
                                style={{ cursor: "pointer" }}
                            />
                            : <StarIcon
                                onClick={() => { handleChangeStart(p.info.id); }}
                                style={{ color: "primary", cursor: "pointer" }}
                            />
                        }
                        <Box sx={{
                            textAlign: "center"
                        }}>
                            <Typography variant={"body2"}>{p.info.currentOperator.id}</Typography>
                        </Box>
                    </Box>

                    <Box>
                        <Avatar
                            onClick={() => { dispatch(setIdSelected(p.info.id)) }}
                            alt={p.info.currentDriver.name}
                            src={p.info.currentDriver.photo}
                            sx={{ width: 45, height: 45, border: `2px solid ${p.info.wayColor}`, cursor: "pointer" }}


                        />
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography variant={"body2"}>{p.info.wayName} </Typography>
                        <Typography variant={"body2"}>{p.info.currentDriver.name}</Typography>
                        <Typography variant={"body2"}>{p.info.currentAuto.name}</Typography>

                    </Box>

                    <Box>
                        <Box sx={{ display: "flex" }}>
                            {
                                (p.info.loadsTotal.length > 0) &&
                                <Typography variant={"body2"}>
                                    {p.info.loadsRealized.length}/{p.info.loadsTotal.length}
                                </Typography>
                            }
                        </Box>  <Divider />
                        
                            <Typography variant={"body2"}>
                                {
                                    (p.info.loadsTotal.length > 0) &&
                                    Math.round(p.info.loadsRealized.length / p.info.loadsTotal.length * 100) + '%'
                                }
                            </Typography>
                        
                    </Box>
                    <Box>
                        <IconButton aria-label="clear" size="small">
                            <AssignmentIcon fontSize="small" />
                        </IconButton>
                        {((p.info.id === idSelected)) ? <><Divider /><IconButton aria-label="clear" size="small"><PlayCircleOutlineIcon fontSize="small" /></IconButton></> : ''}
                    </Box>
                    <Box>
                        <Divider />
                    </Box>
                </Box>
            </Badge>
        </>
    )
}