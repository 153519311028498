import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface RoutesState {
    page: number,
    size: number,
    refresh: boolean,
    status: string,
    state: string,
}

const initialState: RoutesState = {
    page: 0,
    size: 25,
    refresh: true,
    state: "ALL",
    status: "ACTIVE"
}

export const routesSlice = createSlice({
    name: 'routes',
    initialState,
    reducers: {
        modifyRouteStatus(state, action: PayloadAction<string>) {
            state.status = action.payload
        },
        modifyRouteState(state, action: PayloadAction<string>) {
            state.state = action.payload;
        },
        modifyRoutePage(state, action: PayloadAction<number>) {
            state.page = action.payload
        },
        modifyRoutesSizePage(state, action: PayloadAction<number>) {
            state.page = 0;
            state.size = action.payload;
        },

        modifyRoutesRefresh(state) {
            state.refresh = !state.refresh;
        },
    },
    extraReducers: (builder) => {
    }
})

export const {
    modifyRouteStatus,
    modifyRouteState,
    modifyRoutePage,
    modifyRoutesRefresh,
    modifyRoutesSizePage,
} = routesSlice.actions;
export default routesSlice.reducer;