import { useEffect, useState } from "react";
import { dateToYMD } from "../../../../helpers/dateHelper";
import { EmployeeResponseDto, IncomeResponseDto } from "../../../../models/EmployeeModels";
import { IUserInfoResponseDto } from "../../../../models/IUser";
import StafService from "../../../../services/StafService";
import styles from "./ReportIncomes.module.scss"

interface IProps {
    user: IUserInfoResponseDto | null;
}
interface IState {
    uuid: string;
    startDate: string;
    endDate: string,
    description: string,
    numberOfHoursPerDay: number,
    incomes: IncomeResponseDto[] | null
}

const newState = () => {
    return {
        uuid: '#' + Math.random() * 1000 + Math.random() * 1000,
        startDate: dateToYMD(Date.now()),
        endDate: '',
        description: '<p><br/><p>',
        numberOfHoursPerDay: 8,
        incomes: null
    }
}
const ReportIncomes = (props: IProps) => {

    const { user } = props;
    const [state, setState] = useState<IState>(newState());
    const [width, setWidth] = useState(0);

    return (
        <>
            <div className={styles.mainReportDocument} >
                <div className={styles.firstRowOfTable} >
                    <div className={styles.firstRowOfTableCell}>Incomes</div>
                    <div className={styles.firstRowOfTableCell}>Amount</div>
                    <div className={styles.firstRowOfTableCell}>Capture</div>
                </div>
                <div style={{
                    display: "flex",
                    flexDirection: "row" as 'row',
                    width: width.toString() + "px",
                    height: "211px",
                    fontSize: "16px"
                }}>
                    {state.incomes?.map((e, i: number) =>
                        <div key={i}

                        >
                            <div
                                className={styles.tabelCell}
                            >
                                {e.type}
                            </div>
                            <div
                                className={styles.tabelCell}
                            >
                                {e.amount}
                            </div>
                            <div
                                className={styles.tabelCell}
                            >
                                {e.caption}
                            </div>

                        </div>)}

                </div>
            </div>


        </>
    )


}
export default ReportIncomes;