import { useEffect, useState } from "react";
import { ILoadDetailsDTO, ILoadRegistrationRequestDto, ILoadRegistrationResponseDto } from "../../models/LoadModels";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import LoadService from "../../services/LoadService";
import { modifyLoadRefresh, setLastError } from "../../store/reducers/cnfSlice";
import { TextField } from "@mui/material";
import ControlStateInCRUDComponent from "../CustomComponentModule/ControlStateInCRUDComponent";
import { ICustomerShortResponseDto } from "../../models/CustomerModels";

import CustomerAutocompleteComponent from "../CustomerModule/CustomerAutocompleteComponent";

interface IProps {
    load: ILoadDetailsDTO | null;
    refreshAfretSave: () => void;
    snackMsg: (text: string) => void;
    handleStep: (step: number) => void;
}

const RegistrationCustomerComponent = (props: IProps) => {
    const { load } = props;
    const [state, setState] = useState<ILoadRegistrationRequestDto>({
        uuid: '',
        idno: '',
        customerId: ''
    })

    const [save, setSave] = useState<boolean>(false);
    const [isValidName, setIsValidName] = useState<boolean>(true);

    const dispatch = useDispatch<AppDispatch>();

    const [snapshot, setSnapshot] = useState<string>("");
    const [error, setError] = useState<number>(-1);
    const [textErrors, setTextErrors] = useState<Array<string>>([])

    const [contolStateCustomer, setContolStateCustomer] = useState(false);

    const handlerSaveUser = () => { setSave(true); }
    const handlerReset = () => {
        let st = JSON.parse(snapshot);
        setState(st);
    }

    useEffect(() => {
        if (save && load) {
            (async () => {
                const [error, response] = await LoadService.updateRegistration(state)
                if (response) {
                    setSnapshot(JSON.stringify({ ...state }));
                    setContolStateCustomer(true);
                }
                if (error) { dispatch(setLastError(error)) }
            })()
        }
        setSave(false);
    }, [save])

    useEffect(() => {
        if (contolStateCustomer) {
            if (load) {
                (async () => {
                    const [error, response] = await LoadService.getMetadata(load.uuid)
                    if (response) {
                        const { step, completed } = response.data.body;

                        if (completed && !load.metadata.completed && step !== 5) {
                            props.handleStep(5);
                        }

                        if (!completed && !load.metadata.completed && step !== 1) {
                            let bd = { uuid: load.uuid, step: 1 }
                            const [er, rs] = await LoadService.updateLoadStep(bd);
                            if (er) { dispatch(setLastError(error)) }
                        }

                        props.refreshAfretSave();
                        props.snackMsg('Registration data was successfully saved for load ' + load.idno + '.');
                        dispatch(modifyLoadRefresh());
                    }
                    if (error) { dispatch(setLastError(error)) }
                })()
            }
        }
        setContolStateCustomer(false);
    }, [contolStateCustomer])

    useEffect(() => {
        if (load) {
            (async () => {
                const [, response] = await LoadService.getLoadRegistrationStep(load.uuid);
                if (response) {
                    const data: ILoadRegistrationResponseDto = response.data.body as ILoadRegistrationResponseDto;
                    setState({
                        uuid: data.uuid,
                        customerId: data.customer.uuid,
                        idno: data.idno
                    })
                    setSnapshot(JSON.stringify({ ...data }));
                }
            })()
        }
    }, [load?.uuid])

    useEffect(() => {
        let er: boolean = false;
        let ter: string[] = [];

        if ((state.idno.length < 1)) {
            er = true;
            ter.push("* IDNO is requared field")
        }

        if ((state.customerId.length === 1)) {
            er = true;
            ter.push("* Customer is requared field")
        }

        if (load && !er) {
            if (snapshot === JSON.stringify(state)) {
                setError(0)
            } else {
                setError(1)
            }
        } else {
            setError(-1)
        }
        setTextErrors(ter);
    }, [state, isValidName, snapshot])

    const handlerChangeName = (e: any) => {
        let value: string = (e.target.value as string);
        setState(st => ({ ...st, idno: value }))
    }

    const onChangeCustomer = (event: any, newRecord: ICustomerShortResponseDto | null) => {
        if (newRecord) {
            state.customerId = newRecord.uuid;
        }
    }

    return (
        <>
            <div style={{ flex: "1 1 auto", display: "flex", gap: 10, width: "620px", }}>
                <div style={{ display: "flex", flexDirection: "column", gap: "10px", width: "535px", marginRight: "18px" }}>
                    <div style={{ display: "flex", flexDirection: "row", marginBottom: "20px" }}>
                        <CustomerAutocompleteComponent customerId={state.customerId} onChange={onChangeCustomer} />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", marginBottom: "20px" }}>
                        <TextField
                            autoComplete={'off'}
                            error={state.idno.length < 1 || !isValidName}
                            value={state.idno}
                            label="Load IDNO"
                            onChange={handlerChangeName}
                            style={{ width: "540px" }}
                            inputProps={{ sx: { fontSize: "16px" } }}
                            InputLabelProps={{ sx: { fontSize: "17px" } }}
                            helperText={!isValidName ? '* this ID exists' : ''}
                        />
                    </div>
                </div>
                <div style={{ display: "flex", minWidth: "53px" }} >
                    <ControlStateInCRUDComponent
                        error={error}
                        textErrors={textErrors}
                        handlerReset={handlerReset}
                        handlerSave={handlerSaveUser}
                    />
                </div>
            </div>
        </>
    )
}
export default RegistrationCustomerComponent;