import { Button, styled } from '@mui/material'
import React, { useState, useRef, useEffect } from 'react'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
} from 'react-image-crop'


import 'react-image-crop/dist/ReactCrop.css'
import { canvasPreview } from '../../hooks/canvasPreview'
import { useDebounceEffect } from '../../hooks/useDebounceEffect'
import { IExportImageInfo } from '../../models/TransportModels'
import { EFileType } from '../../models/FileModel';
import { NoMeals } from '@mui/icons-material';


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});


interface IProps {
    aspectRatio: number,
    exportInfo: (info: IExportImageInfo) => void,
    disabled: boolean
}


export default function CropImageComponent(props: IProps) {
    const [imgSrc, setImgSrc] = useState('')
    const previewCanvasRef = useRef<HTMLCanvasElement>(null)
    const imgRef = useRef<HTMLImageElement>(null)
    const [crop, setCrop] = useState<Crop>()
    const [completedCrop, setCompletedCrop] = useState<PixelCrop | any>()
    const [scale, setScale] = useState(1)
    const [rotate, setRotate] = useState(0)
    const [aspect, setAspect] = useState<number | undefined>(props.aspectRatio)
    const [fileToUpload, setFileToUpload] = useState<any>(null);
    const [actualMinSize, setActualMinSize] = useState<{ height: number, width: number }>({ height: 0, width: 0 })
    
    function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined);
            const reader: any = new FileReader()
            reader.addEventListener('load', () =>
                setImgSrc(reader.result.toString() || ''),
            )
            reader.readAsDataURL(e.target.files[0])
            setFileToUpload(e.target.files[0])
        }
    }

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        const { width, height, naturalHeight, naturalWidth } = e.currentTarget
        setActualMinSize({ width: (width / naturalWidth) * 180, height: (height / naturalHeight) * 180 })
        if (aspect) {
            let minSize = (width > height) ? height : width;
            setCrop({ width: minSize, height: minSize, x: 0, y: 0, unit: 'px' })
        } else {
            setCrop({ width: width, height: height, x: 0, y: 0, unit: 'px' })
        }
    }



    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale,
                    rotate,
                )
            }
        },
        100,
        [completedCrop, scale, rotate],
    )

    useEffect(() => {
        if (imgRef.current && completedCrop) {
            let k1 = imgRef.current?.naturalWidth / imgRef.current?.width;
            let k2 = imgRef.current?.naturalHeight / imgRef.current?.height;
            let info: IExportImageInfo = {
                image: fileToUpload,
                data: {
                    uuid: '',
                    type: EFileType.MAIN,
                    fileDetails: {
                        cropX: Math.floor(completedCrop.x * k1),
                        cropY: Math.floor(completedCrop.y * k2),
                        cropWidth: Math.floor(completedCrop.width * k1),
                        cropHeight: Math.floor(completedCrop.height * k2)
                    }
                }
            }
            if ((completedCrop.width === imgRef.current.width) && (completedCrop.height === imgRef.current.height)) {
                info.data.fileDetails = {};
            }
            props.exportInfo(info);

        }
    }, [completedCrop, imgSrc])

    const checkForEdges = (n: number, h?: boolean) => {
        if (n < 0) return 0
        if (imgRef.current) {
            if (h) {
                if ((n + 180) > imgRef.current?.height) return imgRef.current.height - 180;
            } else {
                if ((n + 180) > imgRef.current?.width) return imgRef.current.width - 180;
            }
        }
        return n;
    }

    const enforceMinSize = (crop: Crop) => {


        return {
            ...crop,
            x: checkForEdges(crop.x),
            y: checkForEdges(crop.y, true)
        };

    };
    const onCropChange = (newCrop: Crop) => {
        setCrop(enforceMinSize(newCrop));
    };

    return (
        <div className="App">
            <div className="Crop-Controls" style={{ display: "flex", justifyContent: "flex-start" }}>
                <Button component="label" variant="contained" sx={{ marginBottom: "25px" }} startIcon={<CloudUploadIcon />}>
                    Upload file
                    <VisuallyHiddenInput type="file" accept="image/*" onChange={onSelectFile} />
                </Button>

            </div>
            {Boolean(imgSrc) && (
                <ReactCrop
                    crop={crop}
                    style={{ width: '100%', height: 'auto' }}
                    onChange={onCropChange}
                    onComplete={(c) => { setCompletedCrop(c) }}
                    aspect={aspect}
                    minHeight={actualMinSize.height}
                    minWidth={actualMinSize.width}
                    disabled={props.disabled}
                >
                    <img
                        ref={imgRef}
                        alt="Crop me"
                        src={imgSrc}
                        style={{
                            transform: `scale(${scale}) rotate(${rotate}deg)`,
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                        }}
                        onLoad={onImageLoad}
                    />
                </ReactCrop>
            )}
        </div>
    )
}