export enum PrivilegeType {
    WRITE = 'WRITE',
    READ = 'READ'
 }

 export interface IPrivilege { 
    id?: string,
    uuid?: string, 
    name: string,
    caption: string,
    type:PrivilegeType,
    allowDelete?:boolean,
    selected?:boolean,
    roleName?: string,
 };

 export interface RoleLinks {
   uuid: string,
   name: string,
   privileges: IPrivilege[]
};