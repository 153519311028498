import { css } from '@emotion/react'
import styled from '@emotion/styled'

const style_vertical = css`   
  flex-direction: column;
`

const style_horzontal = css`
  flex-direction: row;
`

interface IProps {
  column?: boolean;
  gap?: number;
  width?: number;
  center?: boolean;
  flexEnd?: boolean;
  spaceBetween?: boolean;
  alignCenter?: boolean;
  alignStretch?: boolean;
  height?: string;
  wrap?: string;
  padding?: number;
  minWidth?: number
}
const SCViewList = styled.div<IProps>`
    display: flex;
    ${(props) => props.column ? style_vertical : style_horzontal}
    gap:${(props) => props.gap ? props.gap : 0}px;      
    width:${(props) => props.width ? props.width : 100}%;  
    min-width:${(props) => props.minWidth ? props.minWidth : 'auto'}px;    
    justify-content:${(props) => props.center ? "center" : props.flexEnd ? "flex-end" : props.spaceBetween ? "space-between" : "auto"} ;
    align-items: ${(props) => props.alignCenter ? "center" : props.alignStretch ? "stretch" : "normal"};
    height: ${(props) => props.height ? props.height : "auto"};
    padding: ${(props) => props.padding ? props.padding : 0}px;
    flex-wrap: ${(props) => props.wrap ? props.wrap : 'nowrap'}
`
export default SCViewList;