import { useEffect, useState } from 'react'
import { Avatar } from "@mui/material";
import { APIFile } from '../../services/APIFile';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';



interface IProps {
	link: string,
	w?: number,
	h?: number
}

const AvatarComponent = (props: IProps) => {

	const { link } = props;

	

	return (
		<>
			{<Avatar alt="" src={"data:image/png;base64," + link} sx={{width:`${props.w}px`,height:`${props.h}px` }}/>}
		</>
	)
}

export default AvatarComponent;