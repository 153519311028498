import { Button, Paper, Snackbar } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { addNotification, modifyAuth, setLastError } from "../../store/reducers/cnfSlice";
import { AppDispatch, RootState } from "../../store/store";
import CancelIcon from '@mui/icons-material/Cancel';
import GppBadIcon from '@mui/icons-material/GppBad';
import { INotification } from "../../models/NotificationModels";
import { ENotificationType } from "../../models/EnumGeneral";

const ErrorProcessingComponent = () => {
    const { lastError } = useSelector((state: RootState) => state.cnfSlice);
    const { t } = useTranslation();    
    const dispatch = useDispatch<AppDispatch>();

    const [open, setOpen] = React.useState(false);
    const [text, setText] = React.useState<string[]>([]);

    useEffect(() => {
        if (lastError) {
            let err: any = lastError;
            if (
                err.message === 'Network Error'
                || (err.message.toString().includes('401'))
                || (err.message.toString().includes('402'))
                || (err.message.toString().includes('403'))
                || (err.message.toString().includes('500'))
                || (err.message.toString().includes('501'))
                || (err.message.toString().includes('503'))
            ) {
                dispatch(modifyAuth(null));
                handleClick();
                setText(err.message);
                handleClick();
            } else {
                let txt: string[] = [];
                let obj: any = lastError.response.data.response.errors
                for (var i in obj) {
                    var v = obj[i];
                    txt.push(v.split('.').join(' '))
                }setText(txt);
                handleClick();

                let n: INotification = {
                    userUUID: " ",
                    dt: 0,
                    text: txt,
                    state: false,
                    type: ENotificationType.ERROR,
                    link: ''
                  }
                  dispatch(addNotification(n))
            }
            dispatch(setLastError(null));
        }
    }, [lastError])

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Snackbar
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
                message=""
                anchorOrigin={{ vertical: "top", horizontal: 'center' }}
                action={<Button color="secondary" size="small"> lorem ipsum dolorem </Button>}
            >
                <Paper style={{ display: "flex", padding: "10px", minWidth: "350px", gap: "20px",  border: "1px solid silver" }}>
                    <div style={{ display: "flex", alignItems: "center", paddingLeft: "15px" }}>
                        <GppBadIcon color={'error'} fontSize={'large'} />
                    </div>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        minWidth: "250px",
                        justifyContent: "center"
                    }}>
                        <div>
                            <pre style={{ maxWidth: "600px", overflow: "hidden" }}>
                                {JSON.stringify(text, undefined, 2)}
                            </pre> 
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Button onClick={handleClose}><CancelIcon /></Button>
                    </div>
                </Paper>
            </Snackbar>
        </>
    );
}

export default ErrorProcessingComponent;