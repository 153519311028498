import SCViewList from "../../styledComponents/sharedComponent/SCViewList";
import { Avatar, Badge, Divider, IconButton, InputBase, ListItem, ListItemButton, ListItemText, Paper, ToggleButton, ToggleButtonGroup, Typography, } from "@mui/material";
import { IConversations } from "../../models/PersonalChatModels";
import { useEffect, useState } from "react";
import PersonalChatService from "../../services/PersonalChatService";
import { modifyChatConversationId, setLastError } from "../../store/reducers/cnfSlice";
import { useDispatch, useSelector } from "react-redux";
import { AnyPhoto } from "../StafModule/GridStafComponent";
import { useDebounce } from "../../hooks/useDebounce";
import StafService from "../../services/StafService";
import { IResponseAutocomplete } from "../../models/IUser";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import ItemInSearchListComponent from "../StafModule/ItemInSearchListComponent";
import PersonalChatComponent from "./PersonalChatComponent";
import { RootState } from "../../store/store";
import SCViewGradient from "../../styledComponents/sharedComponent/SCViewGradient";
import { isBlankOrNA } from "../../helpers/textHelper";
import { styled } from '@mui/material/styles';
import PersonIcon from '@mui/icons-material/Person';
import CloseIcon from '@mui/icons-material/Close';
import WorkIcon from '@mui/icons-material/Work';
import { useTranslation } from "react-i18next";
import styles from './ChatComponent.module.scss'

interface IProps {
    close: () => void,
    count: number
}

export const ChatComponent = (props: IProps) => {
    const { count } = props

    const [curentSelectedChat, setCurentSelectedChat] = useState<IConversations | null>(null);
    const [listItem, setListItem] = useState<IConversations[]>([]);
    const [filteredData, setFilteredData] = useState<IResponseAutocomplete[]>([]);
    const { refresh, partnerId } = useSelector((state: RootState) => state.cnfSlice.chat);

    const [togglePartLeft, setTogglePartLeft] = useState('personal');
    const [isChatVisible, setIsChatVisible] = useState<boolean>(false);
    const [wordEntered, setWordEntered] = useState("");

    const dispatch = useDispatch();
    const debouncedSerchValue = useDebounce(wordEntered, 300);
    const { t } = useTranslation();

    const StyledBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            backgroundColor: '#44b700',
            color: '#44b700',
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        },

    }));

    useEffect(() => {
        (async () => {

            const [error, response] = await PersonalChatService.getAllConversations();
            if (response) {
                let value = response.data.body as IConversations[];
                setListItem(value);
            }
            if (error) { dispatch(setLastError(error)); }

        })();
    }, []);

    useEffect(() => {
        (async () => {
            if (!isBlankOrNA(partnerId)) {
                const [error, response] = await PersonalChatService.getConversationPartner(partnerId);
                if (response) {
                    let conversationPartners = [...listItem];
                    let isNewConversation = conversationPartners.find((conversation: IConversations) => conversation.uuid === partnerId) ? false : true;
                    if (isNewConversation) {
                        setListItem((prev) => [response.data.body, ...prev])
                    } else {
                        conversationPartners = conversationPartners.map((item: IConversations) => {
                            if (item.uuid === partnerId) {
                                return response.data.body;
                            }
                            return item;
                        });
                        setListItem(conversationPartners);
                    }
                }
                if (error) { }
            }
        })();
    }, [refresh]);

    const handlerBlur = () => {
        setTimeout(() => {
            clearInput();
        }, 500);
    };

    const handleFilter = (event: any) => {
        const searchWord = event.target.value;
        setWordEntered(searchWord);
        if (searchWord === "") {
            setFilteredData([]);
        }
    };
    const clearInput = () => {
        setFilteredData([]);
        setWordEntered("");
    };


    const handleActivateChat = (item: IResponseAutocomplete) => {
        let conversationId = listItem.find((conversation: IConversations) => conversation.partnerId === item.uuid)?.uuid ?? '*'

        let selectedAutocomplete: IConversations = {
            uuid: conversationId,
            partnerId: item.uuid,
            avatar: item.avatar,
            fullName: item.fullName,
            online: false,
            unreadMessagesCount: 0,
            email: item.email,
            deletedUser: false
        }
        dispatch(modifyChatConversationId(conversationId));

        if (conversationId === '*') {
            setListItem((st) => { return [selectedAutocomplete, ...st] })
        }
        setCurentSelectedChat(selectedAutocomplete);

        setIsChatVisible(true);
    };
    const handleClickList = (item: IConversations) => {
        setIsChatVisible(true);
        setCurentSelectedChat(item);
        dispatch(modifyChatConversationId(item.uuid));
    };

    const handleUpdateConversationId = (id: string) => {
        let conversationPartners = [...listItem];
        conversationPartners = conversationPartners.map((item: IConversations) => {
            if (item.partnerId === curentSelectedChat?.partnerId) {
                return { ...item, uuid: id }
            }
            return item;
        });
        setListItem(conversationPartners);
    }


    useEffect(() => {
        if ((wordEntered.trim() !== "") && (debouncedSerchValue)) {
            (async () => {
                const [error, response] = await StafService.getAutocomplete(wordEntered.trim());
                if (response) { setFilteredData(response.data.body); }
                if (error) { }
            })();
        }
    }, [debouncedSerchValue]);

    function renderRow(props: ListChildComponentProps) {
        const { index } = props;
        let currItem: IConversations = { ...listItem[index] };
        return (
            <div>
                <ListItem divider style={{ height: "45px", marginLeft: "5px", maxWidth: "240px" }} key={index} component="div" disablePadding>
                    <ListItemButton
                        selected={(curentSelectedChat?.partnerId === currItem.partnerId)}
                        onClick={() => { handleClickList(listItem[index]); }}
                        style={{ fontSize: "10px" }}
                    >
                        <SCViewList gap={15} spaceBetween alignCenter>
                            <SCViewList gap={10} alignCenter>
                                {currItem.avatar
                                    ? (currItem.avatar) &&
                                    <StyledBadge
                                        overlap="circular"
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                        variant={currItem.online ? "dot" : "standard"}
                                    >
                                        <Avatar sx={{ width: "30px", height: "30px" }} alt="" src={"data:image/png;base64," + currItem.avatar} />

                                    </StyledBadge>

                                    : <AnyPhoto online={listItem[index].online} />
                                }
                                <ListItemText primary={<Typography sx={{ fontSize: "12px" }}>{currItem.fullName}</Typography>} />
                            </SCViewList>
                            {currItem.unreadMessagesCount ?
                                <div style={{ minWidth: "50x", height: "30px" }}>
                                    <div style={{ display: "flex", justifyContent: "flex-end", minWidth: "25px", }}>
                                        <SCViewGradient red={194} hot={194}>
                                            <Typography fontSize={12} color={"white"} style={{ paddingLeft: "5px", paddingRight: "5px" }}> {currItem.unreadMessagesCount}</Typography>
                                        </SCViewGradient>
                                    </div>

                                </div>

                                : <div></div>}
                        </SCViewList>
                    </ListItemButton>
                </ListItem>
            </div>
        );
    }

    return (
        <>
            <div className={styles.chat}>
                <div style={{ display: "flex", width: "1000px", minHeight: "500px", margin: "15px", gap: "10px" }}>
                    <div style={{ width: "75px" }}>
                        <ToggleButtonGroup
                            orientation="vertical"
                            color="primary"
                            value={togglePartLeft}
                            size="large"
                            exclusive
                            style={{ paddingTop: "20px" }}
                            fullWidth
                        >
                            <ToggleButton value="personal" style={{ display: "flex", flexDirection: "column", height: "50px", fontSize: '8px' }} disabled>

                                <Badge

                                    sx={{
                                        "& .MuiBadge-badge": { fontSize: 9 }
                                    }}
                                    badgeContent={count} color="error" max={99} >
                                    <PersonIcon />
                                </Badge>
                                <Typography fontSize={9}>{t('personal').toLocaleUpperCase()}</Typography>
                            </ToggleButton>
                            <ToggleButton value="all" style={{ display: "flex", flexDirection: "column", height: "50px" }} disabled>
                                <WorkIcon fontSize="small" />
                                <Typography fontSize={9}>{t('issue').toLocaleUpperCase()}</Typography>
                            </ToggleButton>
                            <ToggleButton value="all" style={{ display: "flex", flexDirection: "column", height: "50px" }} disabled>
                                <WorkIcon fontSize="small" />
                                <Typography fontSize={9}>{t('ROUTE').toLocaleUpperCase()}</Typography>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>

                    <Divider orientation="vertical" flexItem />

                    <div style={{ width: "260px" }}>
                        <SCViewList column>
                            <div style={{ border: "1px solid silver", width: "260px", borderRadius: "16px", }}>
                                <InputBase
                                    autoFocus
                                    type="text"
                                    placeholder={'Search user'}
                                    value={wordEntered}
                                    onChange={handleFilter}
                                    style={{ width: "240px", paddingLeft: "10px" }}
                                    onBlur={handlerBlur} />
                            </div>
                            {filteredData.length !== 0 && filteredData &&
                                <Paper className="dataResult" style={{ zIndex: "10" }}>
                                    {filteredData.slice(0, 15).map((value: IResponseAutocomplete, key) => {
                                        return (<ItemInSearchListComponent
                                            key={key}
                                            item={value}
                                            hendlerActiveElement={handleActivateChat} />);
                                    })}
                                </Paper>}
                            <Divider sx={{ paddingTop: "15px" }} flexItem />
                            {listItem.length !== 0
                                ? <FixedSizeList
                                    height={450}
                                    width={270}
                                    itemSize={46}
                                    itemCount={listItem.length}
                                    overscanCount={1}
                                    style={{ position: "absolute", top: "65px" }}
                                >
                                    {renderRow}
                                </FixedSizeList>

                                : <SCViewList center alignCenter>
                                    <Typography style={{ paddingTop: "100px" }} fontSize={14} variant="subtitle1">No conversations here yet</Typography>
                                </SCViewList>
                            }

                        </SCViewList>
                    </div>
                    <Divider orientation="vertical" flexItem />
                    <div style={{ display: "flex", width: "100%" }}>
                        {isChatVisible
                            ? <PersonalChatComponent chat={curentSelectedChat} close={props.close} updateConversationIdInList={handleUpdateConversationId} />
                            : <div style={{ width: "100%" }}>
                                <div style={{ display: "flex", width: "100%", height: "35px", justifyContent: "end", alignItems: "start" }}>
                                    <IconButton
                                        color="inherit"
                                        onClick={props.close}
                                    ><CloseIcon sx={{ width: "20px" }} /></IconButton>
                                </div>
                                <SCViewList center alignCenter height="90%">
                                    <Typography fontSize={14} variant="subtitle1">No messages here yet</Typography>
                                </SCViewList>


                            </div>}
                    </div>
                </div >
            </div>
        </>
    );
};
