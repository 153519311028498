import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Divider, Button } from '@mui/material';
import { modifyStafRole, setLastError } from "../../store/reducers/cnfSlice";
import StafService from "../../services/StafService";
import { IRole, TRoles } from "../../models/IStaf";
import { RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { EStatusStafServer } from "../../models/IUser";
import SCViewList from "../../styledComponents/sharedComponent/SCViewList";
import { ObjectToValueArray } from "../../helpers/generalHelper";
import { ERouteStates } from "../../models/RoutesModels";
import { modifyRouteState } from "../../store/reducers/routesSlice";



export default function ListStateComponent() {

  const { state, status } = useSelector((state: RootState) => state.routesSlice);
  const dispatch = useDispatch();
  const handleFilterRole = (r: string) => {
    dispatch(modifyRouteState(r));
  }
  return (
    <>

      <SCViewList column gap={5}>

        <Divider />
        {(state.toLocaleLowerCase() !== 'all') ?
          <Button
            color='inherit'
            onClick={() => handleFilterRole("ALL")}
            size="small"
            sx={{ width: "100%", fontSize: "12px" }}>{status === EStatusStafServer.INCOMPLETE ? "All " : "All "} 
          </Button>
          :
          <Button
            color='inherit'
            size="small"
            variant="contained"
            disabled={true}
            sx={{ width: "100%", fontSize: "12px" }}
          >
            {status === EStatusStafServer.INCOMPLETE ? "All " : "All "}
          </Button>}
        <Divider />
        {ObjectToValueArray(ERouteStates).map((r, i) =>
          <div key={i}>
            {(state !== r) ?
              <Button
                color='inherit'
                onClick={() => handleFilterRole(r)}
                size="small"
                sx={{ width: "100%", marginBottom: "5px", fontSize: "12px" }}
              >
                {r.split('_').join(' ')}
              </Button>
              :
              <Button
                color="secondary"
                variant="contained"
                disabled={true}
                size="small"
                sx={{ width: "100%", marginBottom: "5px", fontSize: "12px" }}
              >
                {r.split('_').join(' ')}
              </Button>}
            <Divider />
          </div>

        )}
      </SCViewList>
    </>
  );
}

