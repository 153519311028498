import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { MenuItem, TextField } from '@mui/material';
import { IUserInfoResponseDto, IUserMetadata, UserAuthorityUpdateRequestDto, UserStatusResponseDto, UserStepRequestDto, UserUpdateRequestDto } from "../../models/IUser";
import { IRole, TRoles } from "../../models/IStaf";

import { dateToYMD, toTimestamp } from "../../helpers/dateHelper";
import { compareValue } from "../../helpers/generalHelper";
import StafService from "../../services/StafService";
import ControlStateInCRUDComponent from "../CustomComponentModule/ControlStateInCRUDComponent";
import { useDispatch } from "react-redux";
import { modifyStafRefresh } from "../../store/reducers/cnfSlice";
import { useParams } from "react-router-dom";
import { realStringInfoCoverFace } from "../../helpers/textHelper";

interface IPropsRoleAndSrtaus {
  allRole: TRoles,
  user: IUserInfoResponseDto | null,
  userMetadata: IUserMetadata | null,
  userStatus: UserStatusResponseDto | null,
  refreshAfretSave: (us: IUserInfoResponseDto) => void;
  snackMsg: (text: string) => void;
  handleStep: (n: number) => void;
}

const EditRoleUserComponent = (props: IPropsRoleAndSrtaus) => {


  const { t } = useTranslation();
  const { allRole, user, userStatus, userMetadata } = props;
  const { id } = useParams();

  const [role, setRole] = useState<string>('');

  const [enabledRole, setEnabledRole] = useState(false);
  const dispatch = useDispatch();


  const [status, setStatus] = useState<string>(() => (userStatus?.status) ? userStatus?.status : "");
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [error, setError] = useState<number>(0);
  const [errors, setErrors] = useState<Array<boolean>>([false, false, false]);
  const [textErrors, setTextErrors] = useState<Array<string>>([])
  const [save, setSave] = useState<boolean>(false);

  useEffect(() => {
    if (user && user.role)
      setRole(user?.role.name ? user?.role.name : "")
  }, [user]);
  useEffect(() => {
    if (userStatus)
      setStatus(userStatus?.status)
  }, [userStatus]);
  const handleChange = (event: any) => {
    setRole(event.target.value as string);
  };


  const handlerSaveUser = () => { setSave(true); }

  const getUUIDRole = (s: string) => {
    let uuid = allRole?.filter(e => e.name === s)[0].uuid;
    return uuid ? uuid : "";
  }


  useEffect(() => {

    if (save) {
      (async () => {
        let body: UserAuthorityUpdateRequestDto;
        if (user && id) {
          body = {
            uuid: user?.uuid,
            roleUuid: getUUIDRole(role),
          }
          const [error, data] = await StafService.saveUserRole(body);
          if (data) {
            if (!userMetadata?.completed) {
              const [error, response] = await StafService.getUserMetadata(user.uuid);
              if (response) {
                if (response.data.body.step !== 6) {
                  let bd: UserStepRequestDto = {
                    uuid: user.uuid,
                    step: 2
                  }
                  const [err, dt] = await StafService.saveUserStep(bd);
                } else { props.handleStep(5) }
              }
            }
            props.refreshAfretSave(user);
            let snackMessText = ((realStringInfoCoverFace(user?.firstName) === '') || (realStringInfoCoverFace(user?.firstName) === ''))
              ? `${user.email}`
              : `${user.firstName} ${user.lastName}`
            props.snackMsg('Authority was successfully saved for ' + snackMessText + '.');
            dispatch(modifyStafRefresh());
          }
          if (error) { }
        }
      })()
    }
    setSave(false);
  }, [save])

  useEffect(() => {
    setSave(false);
    if (user && userStatus) {
      if (!user.role) { setEnabledRole(true) }
      setRole((): string => {
        if (user?.role) {
          return user.role.name
        } else return '';
      });
      setStatus({ ...userStatus }?.status)
      setStartDate(dateToYMD(userStatus.activeFromDate ? userStatus.activeFromDate : 0))
      setEndDate(dateToYMD(userStatus.activeToDate ? userStatus.activeToDate : 0))
      setError(0);
    }
  }, [user]);

  const handlerReset = () => {
    if (user) {
      props.refreshAfretSave(user);
    }
  }


  useEffect(() => {

    let er: boolean = false;
    let md: boolean = false;
    let ter: string[] = [];
    let errs: boolean[] = [];

    if (userStatus?.status) {
      if (!compareValue(status, userStatus?.status)) {
        md = true;
      }
    }

    let va1: number = (startDate) ? toTimestamp(startDate) : 0;
    let vb1: number = (endDate) ? toTimestamp(endDate) : 0;


    let va2: number = 0;
    let vb2: number = 0;

    if (role === '') { er = true; ter.push('* role is requared') }
    if (role !== user?.role?.name) { md = true }


    setTextErrors(ter);
    if (er) {
      setError(-1);
      errs = [(va1 === 0) && status === 'TEMPORARY', (vb1 === 0) && status === 'TEMPORARY', role === ''];
      setErrors(errs);
    }
    if (!er) setErrors([false, false, false]);
    if ((!er) && (md)) setError(1)
    if ((!er) && (!md)) setError(0)

  }, [role, status, startDate, endDate])

  return (
    <>
      <div style={{ flex: "1 1 auto", display: "flex", gap: 10, width: "620px", }}>
        <div style={{ width: "240px", display: "flex", flexDirection: "column", gap: "24px" }}>

          {allRole &&
            <TextField
              error={errors[2]}
              select
              label={t("Role in the company")}
              value={role}
              onChange={handleChange}
              inputProps={{ sx: { fontSize: "11px" } }}
            >
              {allRole.map((e: IRole, i: number) =>
                <MenuItem key={i} sx={{ fontSize: "11px" }}
                  value={e.name}>{t(e.name)}</MenuItem>)}
            </TextField>
          }
          {
            <TextField
              disabled
              label={t("Status") + ' '}
              id="outlined-size-small"
              size="small"
              value={status}
              inputProps={{ sx: { fontSize: "12px" } }}
            />}

        </div>

        {<div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "30px",
            marginLeft: "14px",
            paddingLeft: "23px",
            justifyContent: "center",
          }
          }
        >

        </div>
        }
        <div style={{ display: "flex", minWidth: "320px" }} >
          <ControlStateInCRUDComponent
            error={error}
            xObject={user}
            textErrors={textErrors}
            handlerReset={handlerReset}
            handlerSave={handlerSaveUser}
          />
        </div>

      </div>
      <br />
      <div style={{ display: "flex" }}>
      </div>
    </>
  )
}

export default EditRoleUserComponent;