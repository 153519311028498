import { IconButton, Divider, Badge, Typography, AppBar, Box, Toolbar, Avatar, Paper, Button, Popper } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import FlipIcon from '@mui/icons-material/Flip';
import CustomizedBreadcrumbs from "../components/NavigateModule/BreadcrumbsComponent";
import HeadMenuComponent from "../components/NavigateModule/HeadMenuComponent";
import { Outlet, useNavigate } from 'react-router-dom';
import { modifyChatAutoRead, modifyChatConversationId, modifyChatPartnerId, modifyNotificationState, modifyNotificationStateCount, modifyPresentFlip, modifyPresentMenu, setLastError } from "../store/reducers/cnfSlice";
import NoPhotoComponent from '../components/CustomComponentModule/NoPhotoComponent';
import LogoViewComponent from '../components/CustomComponentModule/LogoViewComponent';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { WrapMap } from '../components/MapModule/WrapMap';
import { useEffect, useState } from 'react';
import HelperComponent from '../components/RightPartModule/HelperComponent';
import NotificationApiService from '../services/NotificationApiService';
import PersonalChatService from '../services/PersonalChatService';
import { ChatComponent } from '../components/ChatComponent';
import { styled, css } from '@mui/system';
import React from 'react';
import { useDebounce } from '../hooks/useDebounce';
export default function LayoutPage() {

    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const idPooper = open ? 'simple-popper' : undefined;

    const { menuState, presentMenu, presentFlip } = useSelector((state: RootState) => state.cnfSlice.interfaceState);
    const { auth, modifyNotificationCount } = useSelector((state: RootState) => state.cnfSlice);
    const { refresh } = useSelector((state: RootState) => state.cnfSlice.chat);

    const [countBadge, setCountBadge] = useState<number>();
    const [countPersonalChat, setCountPersonalChat] = useState<number>(0);

    const navigate = useNavigate();
    const [viewMap, setViewMap] = useState(false);


    useEffect(() => {
        if (modifyNotificationCount) {
            (async () => {
                const [, res] = await NotificationApiService.GetNumberOfUnreadNotifications()
                if (res) {
                    setCountBadge(res.data.body)
                }
                dispatch(modifyNotificationStateCount(false));
            })()
        }
    }, [modifyNotificationCount])

    useEffect(() => {
        dispatch(modifyNotificationState(true));
    }, [])

    useEffect(() => {
        (async () => {
            const [error, response] = await PersonalChatService.getCountOfUnreadMess();
            if (response) {
                setCountPersonalChat(response.data.body);
            }
            if (error) {
                dispatch(setLastError(error))
            }


        })()
    }, [, refresh]);

    const hendlerShowMenu = () => {
        dispatch(modifyPresentMenu())
    }

    const hendlerShowFlip = () => {
        dispatch(modifyPresentFlip())
    }

    const hendlerView = () => {
        setViewMap(true);
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        dispatch(modifyChatPartnerId(''));
        dispatch(modifyChatAutoRead(false));
    };

    const handleClose = () => {
        setAnchorEl(null);
        dispatch(modifyChatConversationId(''));
        dispatch(modifyChatAutoRead(false));
    };


    return (
        <div style={{ height: "100vh", display: "flex", flexDirection: "column", alignContent: "stretch", gap: 20 }}>
            <AppBar position="static">
                <div style={{ marginLeft: "5%", marginRight: "5%", padding: 0 }}>
                    <Toolbar sx={{ marginLeft: "-24px", marginRight: "-48px", minWidth: "1028px" }}>

                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            sx={{ mr: 2 }}
                            onClick={hendlerShowMenu}
                        >
                            {(presentMenu) ? <MenuOpenIcon sx={{ fontSize: "30px" }} /> : <MenuIcon sx={{ fontSize: "30px" }} />}
                        </IconButton>
                        <Box sx={{ marginTop: "10px", marginRight: "20px" }}>
                            <LogoViewComponent heigth={55} width={55} color={'white'} />
                        </Box>
                        <CustomizedBreadcrumbs />
                        <Box sx={{ flexGrow: 1 }} />
                        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>

                            <IconButton size="large" aria-label="show 4 new mails" color="inherit" onClick={handleClick}>
                                <Badge badgeContent={countPersonalChat} color="error">
                                    <MailIcon />
                                </Badge>
                            </IconButton>

                            <Popper
                                open={open}
                                anchorEl={anchorEl}
                                placement='bottom-start'
                            >
                                <ChatComponent count={countPersonalChat} close={handleClose} />
                            </Popper>

                            <IconButton color="inherit" size="large" onClick={() => { navigate('/notification'); }}>
                                <Badge badgeContent={countBadge} color="error">
                                    <NotificationsIcon />
                                </Badge>
                            </IconButton>

                            <IconButton
                                size="large"
                                edge="end"
                                aria-label="account of current user"
                                aria-haspopup="true"
                                color="inherit"
                            >
                                {
                                    (auth?.avatar) ?
                                        <Avatar alt="" src={"data:image/png;base64," + auth?.avatar} />
                                        : <NoPhotoComponent width={40} heigth={40} />}
                            </IconButton>
                            <Box sx={{ marginTop: "12px", marginLeft: 2 }}>
                                <Typography variant={"body2"}>
                                    {
                                        (auth?.avatar)
                                            ? <span>{auth.firstName}</span>
                                            : "Ivan Modets"
                                    }
                                </Typography>
                                <Typography variant={"body2"}>{(auth) && <span>{auth?.lastName}</span>}</Typography>
                            </Box>

                            <Box style={{ width: "15px", padding: "15px 5px 5px 5px" }}>
                            </Box>

                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                sx={{ mr: 2 }}
                                onClick={hendlerShowFlip}
                            >
                                <FlipIcon sx={{ fontSize: "30px" }} />
                            </IconButton>
                        </Box>
                    </Toolbar>
                </div>
            </AppBar>
            <div style={{
                flex: "1 1 auto",
                marginLeft: "5%",
                marginRight: "5%",
                marginBottom: 20,
                display: "flex",
                alignContent: "stretch",
                minWidth: "1028px",
                gap: 20,
            }}>

                {(presentMenu) &&
                    <Paper sx={{ flex: 1, maxWidth: (menuState === 1) ? "90px" : "50px", padding: 2, textAlign: "center" }}>
                        <HeadMenuComponent></HeadMenuComponent>
                    </Paper>
                }

                <Paper sx={{ flex: 1, padding: 2 }}>

                    {viewMap ? <WrapMap /> : <Button onClick={hendlerView}>view</Button>}
                    <div style={{
                        display: "flex",
                        alignContent: "stretch",
                    }} > <Outlet /> </div>
                </Paper>
                {(presentFlip) &&
                    <Paper sx={{ flex: 1, maxWidth: "300px", padding: 2 }}>
                        <HelperComponent />
                        <Divider />
                    </Paper>
                }
            </div>
        </div>

    );
}
