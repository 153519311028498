import { Button, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { IErrorInList } from "../../models/CustomModels";
import { EContactType, IContactResponseDto, IUserInfoResponseDto } from "../../models/IUser";
import ClearIcon from '@mui/icons-material/Clear';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { isValidEmail } from "../../helpers/generalHelper";
import EmailIcon from '@mui/icons-material/Email';
import StafService from "../../services/StafService";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

interface IPropsMailListComponent {
    user: IUserInfoResponseDto | null,
    userContact: IContactResponseDto[],
    setJSONState: (c: any) => void,
    setMailsError: (b: boolean) => void,
    disable: number
}

const MailListComponent = (props: IPropsMailListComponent) => {
    const { user, setJSONState, setMailsError, userContact } = props;
    const [emailList, setEmailList] = useState<IContactResponseDto[]>([]);
    const [emailPrimary, setMailParimary] = useState<IContactResponseDto>();
    const [allEmailError, setAllEmailError] = useState<Array<IErrorInList>>([]);
    const [deleteState, setDeleteState] = useState(false);

    useEffect(() => {
        let c = emailList.map((e: IContactResponseDto) => {
            if (e.uuid[0] === '#') {
                const { uuid, ...rest } = e;
                return rest
            } else return e;
        })
        setJSONState(c);

        let b: boolean = allEmailError.filter(e => e.isError === true).length > 0;
        setMailsError(b);

    }, [allEmailError, emailList, deleteState])

    useEffect(() => {

        if (user) {
            let mp: IContactResponseDto = {
                uuid: "0",
                type: EContactType.PRIMARY_EMAIL,
                value: user.email
            }
            setMailParimary(mp);
        }

        if (userContact.length > 0) {
            let errors: Array<IErrorInList> = []
            let list: IContactResponseDto[] =
                userContact.filter((c) => c.type === EContactType.ADDITIONAL_EMAIL);
            setEmailList(list);

            for (let i in list) {
                errors.push({
                    uuid: list[i].uuid,
                    isError: false
                })
            }
            setAllEmailError(errors);
        } else {
            setEmailList([]);
            setAllEmailError([])
        };
    }, [user, userContact])

    const hendlerDelete = (uuid: string) => {
        setAllEmailError(list => list.filter(e => e.uuid !== uuid));
        setEmailList(list => list.filter(e => e.uuid !== uuid));
        setDeleteState(!deleteState)
    }

    const hendlerError = (uuid: string, v: boolean) => {
        setAllEmailError(list => list.map(e => (e.uuid === uuid ? { ...e, isError: v } : e)))
    }

    const hendlerValue = (uuid: string, v: string) => {
        setEmailList(list => list.map(e => (e.uuid === uuid ? { ...e, value: v } : e)))
    }

    const hendlerNewMail = () => {
        let newEmail: IContactResponseDto = {
            uuid: '#' + Math.random() * 1000,
            type: EContactType.ADDITIONAL_EMAIL,
            value: ""
        }

        let e: any = allEmailError.push({ uuid: newEmail.uuid, isError: true });
        setEmailList(e);

        let x = [...emailList, newEmail];
        setEmailList(x);

    }

    return (
        <>
            {emailPrimary &&
                <div style={{ width: "300px", display: "flex", gap: "10px", marginBottom: "10px" }}>
                    <EmailElementComponent getValue={hendlerValue} deleteMail={hendlerDelete} state={false} getError={hendlerError} email={emailPrimary} disable={props.disable} />
                    <Button onClick={hendlerNewMail} size={'small'} color={'info'}>
                        <AddBoxIcon />
                    </Button>
                </div>
            }

            <div style={{ display: "flex", gap: "10px", flexDirection: "column", marginTop: "7px" }}>
                {emailList && emailList.map((e, i) =>
                    <EmailElementComponent
                        getValue={hendlerValue}
                        deleteMail={hendlerDelete}
                        getError={hendlerError}
                        email={e}
                        key={i}
                        state={allEmailError.filter(er => er.uuid === e.uuid)[0].isError}
                        disable={props.disable}
                    />
                )}
            </div>
        </>
    )
}
export default MailListComponent;

interface IPropsMailElementComponent {
    email: IContactResponseDto;
    state: boolean
    getError: (uuid: string, v: boolean) => void
    deleteMail: (uuid: string) => void
    getValue: (uuid: string, v: string) => void
    disable: number
}
const EmailElementComponent = (props: IPropsMailElementComponent) => {

    const { email, getError, state, deleteMail, getValue } = props;
    const [eValue, setEvalue] = useState<string>('')
    const [error, setError] = useState<boolean>();
    const [used, setUsed] = useState(false);
    const { auth } = useSelector((state: RootState) => state.cnfSlice);

    const hendlerEmailList = () => {
        let url = 'https://mail.google.com/mail/?view=cm&fs=1';
        let to = '&to=';
        let bcc = '&bcc=';
        if (auth) {
            bcc += auth.email
        }
        to += eValue;

        window.open(url + to + bcc);
    }

    useEffect(() => {
        setEvalue(email.value)
        setError(state)
    }, [email])

    const handlerValue = (event: any) => {
        let value: string = (event.target.value as string).trim();
        let isError = !isValidEmail(value);
        if (!isError) {
            (async () => {
                const [error, response] = await StafService.isTrueContact(value)
                if (response) {
                    let ras = response.data.body.available;
                    setUsed(!ras);
                }
                if (error) { }
            })()
        }

        if (used) { isError = true }
        setEvalue(value);
        setError(isError);
        getError(email.uuid, isError);
        getValue(email.uuid, value);
    }

    return (
        <>


            <div style={{ display: "flex", gap: "10px", }}>
                <div style={{ width: "65px", display: "flex", justifyContent: "center", alignItems: "center", }} >
                    {!error &&
                        <Button size='small' color={'info'} disabled={props.disable !== 0}
                            onClick={hendlerEmailList}  >
                            < EmailIcon />
                        </Button>
                    }
                </div>

                <TextField
                    autoComplete={'off'}
                    disabled={email.type === EContactType.PRIMARY_EMAIL}
                    error={error || used}
                    value={eValue}
                    onChange={handlerValue}
                    label={email.type === EContactType.PRIMARY_EMAIL ? 'Primary email' : 'Additional email'}
                    sx={{ width: "350px" }}
                    inputProps={{ sx: { fontSize: "13px", } }}
                    helperText={used ? '* contact already in use' : ''}
                />

                {email.type !== EContactType.PRIMARY_EMAIL &&
                    <Button size='small' color={'error'} onClick={() => deleteMail(email?.uuid)}>
                        < ClearIcon />
                    </Button>
                }
            </div>
        </>
    )
}