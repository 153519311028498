import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { compareValue } from "../../helpers/generalHelper";
import ControlStateInCRUDComponent from "../CustomComponentModule/ControlStateInCRUDComponent";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import { modifyCustomerRefresh } from "../../store/reducers/cnfSlice";
import CustomerService from "../../services/CustomerService";
import { EWarehouseReputationLevel, EWarehouseStatus } from "../../models/WarehouseModels";
import { CustomerStatusRequestDto, ICustomerDetails } from "../../models/CustomerModels";

interface IPropsRoleAndSrtaus {
  customer: ICustomerDetails | null,
  refreshAfretSave: () => void;
  snackMsg: (text: string) => void;
}

const StatusCustomerComponent = (props: IPropsRoleAndSrtaus) => {

  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const { customer } = props;

  const [status, setStatus] = useState<string>('');
  const [reputationLevel, setReputationLevel] = useState<string>('');

  const [error, setError] = useState<number>(0);
  const [errors, setErrors] = useState<Array<boolean>>([false, false, false]);
  const [textErrors, setTextErrors] = useState<Array<string>>([])
  const [save, setSave] = useState<boolean>(false);

  const handleChangeStatus = (event: any) => {
    setStatus(event.target.value as string);
  };
  const handleChangeReputationLevel = (event: any) => {
    setReputationLevel(event.target.value as string);
  };

  const handlerSaveUser = () => { setSave(true); }

  useEffect(() => {

    if (save) {
      (async () => {

        if (customer) {
          let body: CustomerStatusRequestDto = {
            uuid: customer?.uuid,
            status: status === 'LOWREPUTATION' ? 'LOW_REPUTATION' : status,
            reputationLevel: reputationLevel,
            activeFromDate: 0,
            activeToDate: 0
          }

          const [error, data] = await CustomerService.updateStatusCustomer(body);
          if (data) {
            handlerReset();
            setError(0);
            props.snackMsg('Status and reputation were successfully saved for ' + customer.name + '.');
            dispatch(modifyCustomerRefresh());
          }

          if (error) { }

        }
      })()
    }
    setSave(false);
  }, [save])


  useEffect(() => {
    if (customer) {
      (async () => {
        const [error, data] = await CustomerService.getStatusCustomer(customer.uuid);
        if (data) {
          let res = data.data.body
          setStatus((res.status) ? res.status.toUpperCase() : "");
          setReputationLevel((res.reputationLevel) ? res.reputationLevel.toUpperCase() : "");
          setError(0);
        }

        if (error) { }

      })()
    }
  }, [customer])



  const handlerReset = () => {
    if (customer) {
      props.refreshAfretSave();
    }
  }


  useEffect(() => {

    let er: boolean = false;
    let md: boolean = false;
    let ter: string[] = [];
    let errs: boolean[] = [];

    if (!compareValue(status, customer?.status)) {
      md = true;
    }

    if (!compareValue(reputationLevel, customer?.reputationLevel)) {
      md = true;
    }


    setTextErrors(ter);
    if (er) {
      setError(-1);
      setErrors(errs);
    }
    if ((!er) && (md)) setError(1)
    if ((!er) && (!md)) setError(0)

  }, [status, reputationLevel])
  /** --------- */
  return (
    <>
      <div style={{ flex: "1 1 auto", display: "flex", gap: 10, width: "420px", padding: 0, marginBottom: "-20px" }}>
        {<div
          style={{
            display: "flex",
            marginLeft: "14px",
            justifyContent: "center",
            paddingTop: "10px"
          }
          }
        >
          {customer?.metadata.completed &&
            <div>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "60px" }}>
                <Typography variant="body2" sx={{ width: "60px" }}>STATUS</Typography>
                <ToggleButtonGroup
                  color="primary"
                  value={status}
                  exclusive
                  onChange={handleChangeStatus}
                  sx={{ width: "420px", height: "50px" }}
                >
                  <ToggleButton style={{ width: "120px", fontSize: "12px" }} value={EWarehouseStatus.ACTIVE} >{EWarehouseStatus.ACTIVE}</ToggleButton>
                  <ToggleButton style={{ width: "120px", fontSize: "12px" }} value={EWarehouseStatus.INACTIVE} >{EWarehouseStatus.INACTIVE}</ToggleButton>
                </ToggleButtonGroup>
              </div>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "60px", marginTop: "30px" }}>
                <Typography variant="body2" sx={{ width: "60px" }}>REPUTATION</Typography>
                <ToggleButtonGroup
                  color="primary"
                  value={reputationLevel}
                  exclusive
                  onChange={handleChangeReputationLevel}
                  sx={{ width: "420px", height: "50px" }}
                >
                  <ToggleButton style={{ width: "120px", fontSize: "12px" }} value={EWarehouseReputationLevel.VIP} >{EWarehouseReputationLevel.VIP}</ToggleButton>
                  <ToggleButton style={{ width: "120px", fontSize: "12px" }} value={EWarehouseReputationLevel.STANDARD} >{EWarehouseReputationLevel.STANDARD}</ToggleButton>
                  <ToggleButton style={{ width: "120px", fontSize: "12px" }} value={EWarehouseReputationLevel.POOR} >{EWarehouseReputationLevel.POOR.split('_').join(' ')}</ToggleButton>
                </ToggleButtonGroup>
              </div>

            </div>

          }
        </div>
        }
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "20px", marginTop: "20px" }}  >
          <ControlStateInCRUDComponent
            error={error}
            textErrors={textErrors}
            handlerReset={handlerReset}
            handlerSave={handlerSaveUser}
          />
        </div>

      </div>
      <div style={{ display: "flex" }}>
      </div>
    </>
  )
}
/** --------- */
export default StatusCustomerComponent;
//*** -------------- ***/