import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { dateToYMD, toTimestamp } from "../../helpers/dateHelper";
import { compareValue } from "../../helpers/generalHelper";
import ControlStateInCRUDComponent from "../CustomComponentModule/ControlStateInCRUDComponent";
import { AutoStatusRequestDto, ETransportStatus, IAutoDetails } from "../../models/TransportModels";
import TransportService from "../../services/TransportService";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import { modifyAutoRefresh } from "../../store/reducers/cnfSlice";

interface IPropsRoleAndSrtaus {
  auto: IAutoDetails | null,
  refreshAfretSave: (us: IAutoDetails) => void;
  snackMsg: (text: string) => void;
}

export interface AutoStatus {
  uuid: string,
  status: string,
  activeFromDate?: number,
  activeToDate?: number
}
const EditStatusTransportComponent = (props: IPropsRoleAndSrtaus) => {

  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const { auto } = props;

  const [status, setStatus] = useState<string>('');

  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [error, setError] = useState<number>(0);
  const [errors, setErrors] = useState<Array<boolean>>([false, false, false]);
  const [textErrors, setTextErrors] = useState<Array<string>>([])
  const [save, setSave] = useState<boolean>(false);
  const [state, setState] = useState<AutoStatusRequestDto | null>();

  const handleChangeStatus = (event: any) => {
    setStatus(event.target.value as string);
  };

  const handleChangeStartDate = (event: any) => {
    let value: string = event.target.value as string;
    setStartDate(value);

  }

  const handleChangeEndtDate = (event: any) => {
    let value: string = event.target.value as string;
    setEndDate(value);
  }

  const handlerSaveUser = () => { setSave(true); }

  useEffect(() => {
    if (auto) {
      (async () => {
        const [error, data] = await TransportService.getStatusAuto(auto.uuid);
        if (data) {
          let res = data.data.body
          setStatus((res.status) ? res.status.toUpperCase() : "");
          setStartDate(dateToYMD(res.activeFromDate ? res.activeFromDate : ''))
          setEndDate(dateToYMD(res.activeToDate ? res.activeToDate : ''))
          setError(0);
          setState(res)
        }
      })()
    }
  }, [auto])

  useEffect(() => {

    if (save) {
      (async () => {
        let body: AutoStatusRequestDto;
        if (auto) {

          body = {
            uuid: auto?.uuid,
            status: status,
            activeFromDate: toTimestamp(startDate),
            activeToDate: toTimestamp(endDate)
          }

          const [error, data] = await TransportService.updateStatusAuto(body);
          if (data) {

            if (props.auto) {
              if (props.auto.metadata.step !== 4) {
                let bd = {
                  uuid: props.auto.uuid,
                  step: 4
                }
                const [er, rs] = await TransportService.saveTransportStep(bd);
                if (er) { }
              }
              props.refreshAfretSave(props.auto);
            }
            props.snackMsg(`Status was successfully saved for ${auto.brand} ${auto.model} ${auto.year}.`);
            dispatch(modifyAutoRefresh());
          }
        }
      })()
    }
    setSave(false);
  }, [save])

  const handlerReset = () => {
    if (auto) {
      props.refreshAfretSave(auto);
    }
  }

  useEffect(() => {

    let er: boolean = false;
    let md: boolean = false;
    let ter: string[] = [];
    let errs: boolean[] = [];

    if (!compareValue(status, state?.status)) {
      md = true;
    }

    let va1: number = (startDate) ? toTimestamp(startDate) : 0;
    let vb1: number = (endDate) ? toTimestamp(endDate) : 0;


    let va2: number = 0;
    let vb2: number = 0;

    if (status === 'TEMPORARY') {
      if (state) {
        va2 = { ...state }.activeFromDate;
        vb2 = { ...state }.activeToDate;
      }

      if ((va1 === 0) || (vb1 === 0)) { er = true; ter.push('* start and expiration is requared') };
      if (vb1 - va1 < 0) { er = true; ter.push('* the start must be less than the expiration') }

      if ((!er) && ((dateToYMD(va1) !== dateToYMD(va2)) || dateToYMD(vb1) !== dateToYMD(vb2))) { md = true }
      if ((!er) && ((dateToYMD(va1) !== dateToYMD(va2)) || dateToYMD(vb1) !== dateToYMD(vb2))) { md = true }
    }

    setTextErrors(ter);
    if (er) {
      setError(-1);
      errs = [(va1 === 0) && status === 'TEMPORARY', (vb1 === 0) && status === 'TEMPORARY'];
      setErrors(errs);
    }
    if (!er) setErrors([false, false, false]);
    if ((!er) && (md)) setError(1)
    if ((!er) && (!md)) setError(0)

  }, [status, startDate, endDate, state])

  return (
    <>
      <div style={{ flex: "1 1 auto", display: "flex", gap: 10, width: "620px", padding: 0 }}>
        {<div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "30px",
            marginLeft: "14px",
            justifyContent: "center",
            paddingTop: "10px"
          }
          }
        >
          {auto?.metadata.completed &&
            <ToggleButtonGroup
              color="primary"
              value={status}
              exclusive
              onChange={handleChangeStatus}
            >
              <ToggleButton style={{ width: "90px", fontSize: "12px" }} value={ETransportStatus.ACTIVE} >{ETransportStatus.ACTIVE}</ToggleButton>
              <ToggleButton style={{ width: "90px", fontSize: "12px" }} value={ETransportStatus.TEMPORARY}>{ETransportStatus.TEMPORARY}</ToggleButton>
              <ToggleButton style={{ width: "90px", fontSize: "12px" }} value={ETransportStatus.INACTIVE}>{ETransportStatus.INACTIVE}</ToggleButton>
            </ToggleButtonGroup>
          }
          {auto?.metadata.completed &&
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <TextField
                error={errors[0]}
                disabled={!["TEMPORARY"].includes(status)}
                size="small"
                label={t("Start")}
                type="date"
                value={startDate}
                onChange={handleChangeStartDate}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ sx: { fontSize: "11px" } }}
              />
              <TextField
                error={errors[1]}
                disabled={!["TEMPORARY"].includes(status)}
                size="small"
                value={endDate}
                onChange={handleChangeEndtDate}
                label={t("Expiration")}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ sx: { fontSize: "11px" } }}
              />
            </div>
          }
        </div>
        }
        <div style={{ display: "flex", minWidth: "320px" }} >
          <ControlStateInCRUDComponent
            error={error}
            textErrors={textErrors}
            handlerReset={handlerReset}
            handlerSave={handlerSaveUser}
          />
        </div>

      </div>
      <br />
      <div style={{ display: "flex", flexDirection: "column" }}>
      </div>
    </>
  )
}

export default EditStatusTransportComponent;