import { Box, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from "@mui/material";
import { IInterfaceState } from "../../../models/IUser";
import SCViewList from "../../../styledComponents/sharedComponent/SCViewList";
import SecureDialogComponent from "../../CustomComponentModule/SecureDialogComponent";
import { EButtonColor } from "../../../models/EnumGeneral";
import { useEffect } from "react";
import { compareValue } from "../../../helpers/generalHelper";
import { RootState } from "../../../store/store";
import { useSelector } from "react-redux";
import React from "react";

interface IProps {
    stateLocal: IInterfaceState;
    getNewInterfaceState: (state: IInterfaceState) => void;
}

const NotificationSettingsComponent = (props: IProps) => {
    const { getNewInterfaceState, stateLocal } = props

    const { hints } = useSelector((state: RootState) => state.cnfSlice.interfaceState);

    const [disabled, setDisabled] = React.useState<boolean>(true);
    const [state, setstate] = React.useState<IInterfaceState>(stateLocal);

    useEffect(() => {
        setstate(stateLocal);
    }, [stateLocal]);


    const handleNotificationPush = (event: any, newNotificationPush: boolean) => {
        if (newNotificationPush !== undefined)
            setstate((st) => { return { ...st, notificationPushEnabled: newNotificationPush } });
    }

    const handleNotificationEmail = (event: any, newNotificationEmail: boolean) => {
        if (newNotificationEmail !== undefined)
            setstate((st) => { return { ...st, notificationEmailEnabled: newNotificationEmail } });
    }

    const handleSaveButton = () => {
        getNewInterfaceState(state);
    }

    useEffect(() => {
        setDisabled(compareValue(state, stateLocal));
    }, [state, stateLocal]);

    return (
        <>
            <SCViewList column gap={30} center>

                <SCViewList gap={10} center>

                    <SCViewList column gap={35} width={20} center>

                        <Typography color={"black"} variant="subtitle2" sx={{ marginLeft: "-35px" }}> PUSH </Typography>
                        <Typography color={"black"} variant="subtitle2" sx={{ marginLeft: "-35px" }}> EMAIL </Typography>

                    </SCViewList>


                    <SCViewList column gap={10} width={60}>
                        <ToggleButtonGroup
                            color="primary"
                            value={state.notificationPushEnabled}
                            exclusive
                            onChange={handleNotificationPush}
                            fullWidth={true}
                        >
                            <ToggleButton value={true}>{"ENABLED"}</ToggleButton>
                            <ToggleButton value={false}>{"DISABLED"}</ToggleButton>
                        </ToggleButtonGroup>


                        <ToggleButtonGroup
                            color="primary"
                            value={state.notificationEmailEnabled}
                            exclusive
                            onChange={handleNotificationEmail}
                            fullWidth={true}
                        >
                            <ToggleButton value={true}>{"ENABLED"}</ToggleButton>
                            <ToggleButton value={false}>{"DISABLED"}</ToggleButton>
                        </ToggleButtonGroup>

                    </SCViewList>


                </SCViewList>
                <SCViewList flexEnd width={90}>
                    <Tooltip
                        title={(hints && !disabled) ? "Save personal profile configurations" : ""}
                        placement="top"
                        arrow={true}
                    >
                        <Box>
                            <SecureDialogComponent
                                answerYES={() => handleSaveButton()}
                                text={'SAVE'}
                                iconButton={false}
                                color={EButtonColor.primary}
                                disabled={disabled}
                            />
                        </Box>
                    </Tooltip>
                </SCViewList>
            </SCViewList>

        </>)
}
export default NotificationSettingsComponent;