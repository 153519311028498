import { useEffect, useState } from "react";
import { dateToYMD } from "../../../../helpers/dateHelper";
import { IDocumentResponseDto, IUserInfoResponseDto } from "../../../../models/IUser";
import StafService from "../../../../services/StafService";
import styles from "./ReportDocuments.module.scss"

interface IProps {
    user: IUserInfoResponseDto | null;
}

const ReportDocuments = (props: IProps) => {

    const { user } = props;
    const [documents, setDocuments] = useState<IDocumentResponseDto[] | null>()
    const [width, setWidth] = useState<number>(0);
    const [bool, setBool] = useState<boolean>(false);

    useEffect(() => {
        if (user) {
            (async () => {
                const [error, res] = await StafService.getDocuments(user.uuid);
                if (res) {
                    if (res.data.body) {                       
                        setWidth(res.data.body.length)
                        setDocuments(res.data.body);
                        for (let b of res.data.body) {
                            if (b.type === "SOCIAL_SECURITY_CARD") setBool(true);
                        }
                    }
                    else {
                        setDocuments([]);
                    };
                }

            })()
        }
    }, []);


    return (
        <>
            <div className={styles.mainReportDocument} >
                <div className={styles.firstRowOfTable}  >
                    <div className={styles.firstRowOfTableCell} >Documents</div>
                    <div className={styles.firstRowOfTableCell} ><div className={styles.centerFirstRowText} >Expiration Date</div></div>
                    <div className={styles.firstRowOfTableCell}><div className={styles.centerFirstRowText} >Date Of Issue</div></div>
                </div>
                <div style={{
                    display: "flex",
                    flexDirection: "row" as 'row',
                    width: (width > 1 && bool) ? (width * 180 - (width - 1) * 60).toString() + "px" : (width * 180 + 1).toString() + "px",
                    height: "211px",
                    fontSize: "15px"
                }}>

                    {documents?.map((e, i: number) =>
                        <div key={i}
                        >
                            <div
                                style={{ display: "flex", flexDirection: "row", width: e.type === "SOCIAL_SECURITY_CARD" ? "180px" : "120px", height: "70px", border: "1px solid silver", alignItems: "center", justifyContent: "center" }}
                            >
                                {e.type}
                            </div>
                            <div
                                style={{ display: "flex", flexDirection: "row", width: e.type === "SOCIAL_SECURITY_CARD" ? "180px" : "120px", height: "70px", border: "1px solid silver", alignItems: "center", justifyContent: "center" }}
                            >
                                {dateToYMD(e.expirationDate)}
                            </div>
                            <div
                                style={{ display: "flex", flexDirection: "row", width: e.type === "SOCIAL_SECURITY_CARD" ? "180px" : "120px", height: "70px", border: "1px solid silver", alignItems: "center", justifyContent: "center" }}
                            >
                                {dateToYMD(parseInt(e.dateOfIssue))}
                            </div>


                        </div>)}

                </div>


            </div>




        </>
    )
}
export default ReportDocuments;