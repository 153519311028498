import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { APIFile } from "../../services/APIFile";

interface IpropsImages {
    url: string,
    w?: number,
    h?: number,
    pointer?: boolean,
    base64?: string
}
const ImageBase64ServerComponent = (props: IpropsImages) => {
    const { base64 } = props;
    const [fileBase64, setFileBase64] = useState<string>(base64 ? base64 : '');
    const [loading, setLoadig] = useState(true);

    useEffect(() => {
        (async () => {
            if (!base64) {
                const [error, response] = await APIFile(props.url);
                if (response) {
                    let d: string = response.data;
                    setLoadig(false);
                    setFileBase64(d);
                }
                if (error) { }
            } else {
                setLoadig(false);
            }

        })()
    }, [])

    return (
        <>
            {
                loading ? <CircularProgress size={30} /> :
                    <img
                        style={{ cursor: (props.pointer) ? 'pointer' : 'auto' }}
                        src={"data:image/png;base64," + fileBase64}
                        width={props.w ? props.w : 75}
                        height={props.h ? props.h : 75}
                    />
            }
        </>
    )
}

export default ImageBase64ServerComponent;