import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from "@mui/material";
import axios from "axios";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { settings } from "../../configs/settings";
import { ISecure } from "../../models/IUser";

import { API } from "../../services/APIService";
import GeneralService from "../../services/GeneralService";
import { modifyAuth, modifyEmailNotifications, modifyGlobalPreference, modifyLanguage, modifyPushNotifications, modifyUserInterfaceState, setCorporateEmailHostDomain, sethostWithDomain, setLastError, setSecure } from "../../store/reducers/cnfSlice";
import { RootState } from "../../store/store";
import LogoViewComponent from "../CustomComponentModule/LogoViewComponent";

const AuthControlComponent = () => {

    const dispatch = useDispatch();
    const location = useLocation().pathname;
    const navigate = useNavigate();
    const { auth, interfaceState, secure } = useSelector((state: RootState) => state.cnfSlice);
    const [open, setOpen] = useState(false);
    const [pathURL, setpathURL] = useState('');

    const handleClose = () => {
        setOpen(false);
    };

    const handleYes = () => {
        navigate(pathURL);
        setOpen(false);
    }

    /* ---- */
    const handleLogout = async () => {
        await API('POST', 'auth/logout');
    }

    /* ---- */
    const getUserProfile = async (tokenData: ISecure) => {
        axios(
            {
                method: 'GET',
                baseURL: settings.baseURL,
                url: 'userProfile',
                headers: {
                    "Authorization": tokenData.accessToken?.trim() !== '' ? "Bearer " + tokenData.accessToken : '',
                    "Content-Type": "application/json"
                },
                data: '',
                params: '',
            }
        ).then((response) => {
            if (tokenData.accessToken && tokenData.refreshToken && tokenData.expiryDuration && tokenData.tokenType) {
                sessionStorage.setItem('access_token', tokenData.accessToken);
                sessionStorage.setItem('refresh_token', tokenData.refreshToken);
                sessionStorage.setItem('access_token_expiry_duration', tokenData.expiryDuration);
                sessionStorage.setItem('access_token_type', tokenData.tokenType);
            }

            dispatch(modifyAuth(response.data.body.userProfile));
            const systemPreference = response.data.body.systemPreference;

            const host = systemPreference.hostWithDomain;
            if (host && host !== '') {
                dispatch(sethostWithDomain(host))
            }

            dispatch(setCorporateEmailHostDomain(systemPreference.corporateEmailHostDomain));

            let copyLocalState: any = { ...interfaceState };
            let res = JSON.parse(response.data.body.userPreference.webAppPreferences);

            for (var key in res) {
                if (copyLocalState[key] !== undefined) { 
                    copyLocalState[key] = res[key]; 
                }
            }

            dispatch(modifyUserInterfaceState(copyLocalState));
            if (copyLocalState.pathURL && copyLocalState.pathURL !== '/' && location !== copyLocalState.pathURL) {
                setOpen(true);
                setpathURL(copyLocalState.pathURL);
            }

            let resUserPreferences = response.data.body.userPreference;
            const { webAppPreferences, language, notificationPushEnabled, notificationEmailEnabled, ...rest } = resUserPreferences;
            dispatch(modifyGlobalPreference(rest))
            i18next.changeLanguage(language);
            dispatch(modifyLanguage(language));
            dispatch(modifyPushNotifications(notificationPushEnabled));
            dispatch(modifyEmailNotifications(notificationEmailEnabled));
            
        }).catch((e) => {
            dispatch(setLastError(e))
        });
    }

    useEffect(() => {
        if (location === '/oauth2/done') {
            const params = window.location.search,
                urlParams = new URLSearchParams(params),
                accessToken = urlParams.get('accessToken'),
                refreshToken = urlParams.get('refreshToken'),
                tokenType = urlParams.get('tokenType'),
                expiryDuration = urlParams.get('expiryDuration');

            let tokenData: ISecure = {
                accessToken: accessToken ? accessToken : '',
                tokenType: tokenType ? tokenType : '',
                refreshToken: refreshToken ? refreshToken : '',
                expiryDuration: expiryDuration ? expiryDuration : ''
            }
            navigate('/');

            const data = {
                refreshToken: tokenData.refreshToken
            }
            axios(
                {
                    method: 'PUT',
                    baseURL: settings.baseURL,
                    url: 'auth/refreshToken',
                    headers: {
                        "Authorization": tokenData.accessToken?.trim() !== '' ? "Bearer " + tokenData.accessToken : '',
                        "Content-Type": "application/json"
                    },
                    data: JSON.stringify(data),
                    params: '',
                }
            ).then((res) => {
                let tokDt: ISecure = res.data.body as ISecure;
                dispatch(setSecure(tokDt));
                getUserProfile(tokDt);
            })
        }
    }, [location])


    /* ---- */
    useEffect(() => {
        const dt: number = 120000;
        let timerID = setInterval(() => {
            if (auth) {
                (async () => {
                    const [error, response] = await GeneralService.getServerTime();
                    if (error) { dispatch(setLastError(error)) }
                    if (response) {
                        let t1 = Number(response.data.body.serverTime);
                        let t2 = Number(secure.expiryDuration);
                        let d = t2 - t1;
                        if (d < dt) {
                            const data = {
                                refreshToken: secure.refreshToken
                            }
                            axios(
                                {
                                    method: 'PUT',
                                    baseURL: settings.baseURL,
                                    url: 'auth/refreshToken',
                                    headers: {
                                        "Authorization": secure.accessToken?.trim() !== '' ? "Bearer " + secure.accessToken : '',
                                        "Content-Type": "application/json"
                                    },
                                    data: JSON.stringify(data),
                                    params: '',
                                }
                            ).then((res) => {
                                let tokDt: ISecure = res.data.body as ISecure;
                                dispatch(setSecure(tokDt));
                            }).catch((error) => {
                                handleLogout();
                                dispatch(setLastError(error))
                            })

                        }
                    }
                })();
            }
        }, dt);

        return function cleanup() {
            clearInterval(timerID);
        };
    })

    const { t } = useTranslation();

    const styled = {
        info: {},
        rows: { display: "flex", gap: "10px", marginBottom: "5px", marginTop: "5px" },
        boxis: { width: "100px", padding: "4px", fontSize: "12px" },

    }

    return (<>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {t("Restore work session?")}
            </DialogTitle>
            <DialogContent>
                <div style={{
                    display: "flex", gap: "50px",
                    paddingTop: "30px",
                    paddingLeft: "50px",
                    paddingRight: "15px",
                    paddingBottom: "0px"
                }}>
                    <Box sx={{ display: "flex", justifyContent: "center", marginBottom: "10px", marginTop: "10px", alignItems: "center" }}>
                        <LogoViewComponent
                            width={150}
                            heigth={150}
                        />
                    </Box>
                    <Box>
                        <Box style={styled.rows}>
                            <Typography align="center" style={styled.boxis}>{t("chapter")}</Typography>
                            <Typography align="center" style={styled.boxis}>{t(pathURL.split('/')[1])}</Typography>
                        </Box>
                        <Divider></Divider>
                        <Box style={styled.rows}>
                            <Typography align="center" style={styled.boxis}>{t("time")}</Typography>
                            <Typography align="center" style={styled.boxis}>21.02.2022 17:25</Typography>
                        </Box>
                        <Divider></Divider>
                        <Box style={styled.rows}>
                            <Typography align="center" style={styled.boxis}>{t("place")}</Typography>
                            <Typography align="center" style={styled.boxis}>{t("office")}</Typography>
                        </Box>
                    </Box>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleYes} autoFocus>{t("yes")}</Button>
                <Button onClick={handleClose} > {t("no")}</Button>
            </DialogActions>
        </Dialog>
    </>)
}

export default AuthControlComponent;