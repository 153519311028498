import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, Paper, Rating, TextField, Tooltip, Typography, useMediaQuery, useTheme, } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import CloseIcon from '@mui/icons-material/Close';
import { useDebounce } from '../../hooks/useDebounce';
import { IResponseAutocomplete } from '../../models/IUser';
import { useNavigate } from 'react-router-dom';
import { modifyLoadRefresh, modifyLeftFlip, setLastError } from '../../store/reducers/cnfSlice';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import { ECustomerReputationLevel, ICustomerShortResponseDto } from '../../models/CustomerModels';
import RedactTypesItemsLoadComponent from './RedactTypesItemsLoadComponent';
import LoadService from '../../services/LoadService';
import SearchLoadComponent from './SearchLoadComponent';
import { ELoadSettingsType } from '../../models/LoadModels';
import SCViewList from '../../styledComponents/sharedComponent/SCViewList';
import { getDateFormat } from '../../helpers/dateHelper';
import VIPViewComponent from '../CustomComponentModule/VIPViewComponent';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CustomerAutocompleteComponent from '../CustomerModule/CustomerAutocompleteComponent';

const styled = {
    icon: { fontSize: "28px", cursor: "pointer" }
}

interface IProps {
    snackMess: (s: string) => void;
}

export default function LoadButtonBarComponent(props: IProps) {
    const { snackMess } = props;

    const { hints } = useSelector((state: RootState) => state.cnfSlice.interfaceState);
    const { t } = useTranslation();

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const [open, setOpen] = useState(false);
    const [openN, setOpenN] = useState(false);
    const [openS, setOpenS] = useState(false);

    const [type, setType] = useState<ELoadSettingsType>(ELoadSettingsType.Fragility_Type);

    const [state, setState] = useState({ customerId: '', idno: '' })

    const debouncedIDNO = useDebounce(state.idno ? state.idno : '', 300);

    const [isValidIDNO, setIsValidIDNO] = useState<boolean>(true);
    const [aviableForSave, setAviableForSave] = useState<boolean>(false)
    const [save, setSave] = useState<boolean>(false);
    const [selectedCustomer, setSelectedCustomer] = useState<ICustomerShortResponseDto>();

    const handlerChangeIdno = (e: any) => {
        let value: string = (e.target.value as string).trim();
        setState(st => ({ ...st, idno: value }))
    }

    const handleClickOpen = () => { setOpen(true); };
    const handleClose = () => { setOpen(false); };

    const hendlerActiveElement = (item: IResponseAutocomplete) => {
        navigate('/load/' + item.uuid);
        handleClose();
    }

    const handlerAddNewLoad = () => {
        setState({ customerId: '', idno: '' })
        setOpenN(true);
        navigate('/load');
    }

    const handleCloseN = () => { setOpenN(false); }

    const handlerSaveLoad = () => {
        setSave(true); handleCloseN(); dispatch(modifyLeftFlip());
    }

    const handlerSettingsLoad = () => { setOpenS(true) }
    const handlerCloseSettings = () => { setOpenS(false) }

    useEffect(() => {
        if (debouncedIDNO && state.idno.length > 6) {
            if (state.idno.length >= 6) {
                (async () => {
                    const [error, response] = await LoadService.isAvailableLoadIDNO(state.idno);
                    if (response) {
                        const value: boolean = response.data.body as boolean;
                        setIsValidIDNO(value);
                    }
                    if (error) { dispatch(setLastError(error)) }
                })()
            } else {
                setIsValidIDNO(true);
            }
        }
    }, [debouncedIDNO])

    useEffect(() => {
        let err = true;
        if (state.customerId.trim().length === 0) { err = false }
        if (state.idno.trim().length < 1) { err = false }
        if (!isValidIDNO) { err = false }
        setAviableForSave(err);

    }, [state, isValidIDNO])

    useEffect(() => {
        if (save) {
            (async () => {
                const body = state;
                const [, response] = await LoadService.createLoad(body);
                if (response) {
                    let uuid = response.data.response.entityId;
                    navigate('/load/' + uuid);
                    dispatch(modifyLoadRefresh());
                    snackMess('Load with idno ' + body.idno + ' was successfully created.')
                }
            })()
        }
        setSave(false);
    }, [save])

    const onChangeCustomer = (event: any, newValue: ICustomerShortResponseDto) => {
        if (newValue) {
            setSelectedCustomer(newValue);
            setState(st => ({ ...state, customerId: newValue.uuid }))
        }
    }

    return (
        <div>
            <Box sx={{ marginBottom: "5px" }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", gap: "5px" }}>
                        <Tooltip
                            title={(hints) ? t("Search load") : ''}
                            placement={"top"}
                            arrow={true}
                        >
                            <IconButton onClick={handleClickOpen}>
                                <ManageSearchIcon style={styled.icon} color={"primary"} />
                            </IconButton>
                        </Tooltip>

                        <Tooltip
                            title={(hints) ? t("Create load") : ''}
                            placement={"top"}
                            arrow={true}
                        >
                            <IconButton onClick={handlerAddNewLoad} >
                                <DashboardCustomizeIcon style={styled.icon} color={"primary"} />
                            </IconButton>
                        </Tooltip>

                        <Tooltip
                            title={(hints) ? t("Pallet type and Fragility type management") : ''}
                            placement={"top"}
                            arrow={true}
                        >
                            <IconButton onClick={handlerSettingsLoad} >
                                <SettingsSuggestIcon style={styled.icon} color={"primary"} />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>

                <Dialog
                    fullScreen={fullScreen}
                    open={openS}
                    onClose={handlerCloseSettings}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                        {type.split('_').join(' ').toLocaleUpperCase() + ' MANAGEMENT'}
                    </DialogTitle>
                    <DialogContent>
                        <RedactTypesItemsLoadComponent snackMess={snackMess} getType={(s) => setType(s)} />
                    </DialogContent>
                    <DialogActions sx={{ padding: "25px", paddingTop: "0px" }} >
                        <Button onClick={handlerCloseSettings} variant={'contained'} color={'warning'}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    fullScreen={fullScreen}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                        { }
                    </DialogTitle>
                    <DialogContent>
                        <div style={{ minHeight: "350px", minWidth: "330px" }}>
                            <SearchLoadComponent
                                hendlerActiveElement={hendlerActiveElement}
                                placeholder={'Search by IDNO'}
                            ></SearchLoadComponent>
                        </div>

                        <DialogContentText>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    fullScreen={fullScreen}
                    maxWidth={'xl'}
                    open={openN}
                    onClose={handleCloseN}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                        {"CREATE LOAD"}
                    </DialogTitle>
                    <DialogContent>
                        <div style={{ display: "flex" }}>
                            <div style={{ paddingTop: "20px" }}>
                                <div style={{ display: "flex", gap: "18px", alignItems: "start", justifyContent: "" }}>
                                    <Box sx={{ width: '500px', display: "flex", flexDirection: "column", gap: "25px" }}>
                                        <Box style={{ width: "500px", display: "flex", flexDirection: "column", gap: "25px", paddingTop: "10px" }}>
                                            <CustomerAutocompleteComponent customerId={state.customerId} onChange={onChangeCustomer} />

                                            <TextField
                                                error={state.idno.length < 1 || !isValidIDNO}
                                                autoComplete={'off'}
                                                value={state.idno}
                                                id="standard-basic"
                                                label="Load IDNO"
                                                onChange={handlerChangeIdno}
                                                fullWidth
                                                inputProps={{ sx: { fontSize: "16px" } }}
                                                InputLabelProps={{ sx: { fontSize: "17px" } }}
                                                helperText={!isValidIDNO && state.idno.length > 1 ? 'This Load IDNO is used' : ''}
                                            />
                                        </Box>

                                        <Box style={{ minWidth: "500px" }}>
                                            <div>
                                                <CustomerComponent
                                                    customer={selectedCustomer}
                                                />
                                            </div>
                                        </Box>
                                    </Box>
                                </div>
                            </div>
                        </div>
                        <DialogContentText>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <div style={{ display: "flex", justifyContent: "flex-end", width: "100%", padding: "20px", gap: "15px" }}>
                            <Button
                                variant={'contained'}
                                disabled={!aviableForSave}
                                size={'small'}
                                onClick={handlerSaveLoad} startIcon={<SaveAsIcon />}>Create</Button>
                            <Button
                                startIcon={<CloseIcon />}
                                variant={'contained'}
                                color={'warning'}
                                size={'small'}
                                onClick={handleCloseN}>Close</Button>

                        </div>
                    </DialogActions>
                </Dialog>
            </Box>
            <Divider></Divider>
            <Box sx={{ height: "10px" }}></Box>
        </div>
    );
}

interface IPropsCustomerComponent {
    customer: ICustomerShortResponseDto | null | undefined,
}
const CustomerComponent = (props: IPropsCustomerComponent) => {
    const { customer } = props;
    const { dateFormat } = useSelector((state: RootState) => state.cnfSlice.interfaceState);

    return <>
        <Suspense>
            {(customer)
                ? <Box sx={{ border: "1px dashed silver", borderRadius: "5px" }}>

                    <SCViewList column gap={5} padding={10}>
                        <SCViewList>
                            <Typography sx={{ minWidth: "360px", maxWidth: "360px", fontSize: "12px" }}>{(customer.name) ? customer.name : ""}</Typography>
                            <SCViewList>
                                <Typography sx={{ borderLeft: "1px solid silver", paddingLeft: "10px", fontSize: "12px", minWidth: "80px" }}>{(customer.reputationLevel) ? customer.reputationLevel : ""}</Typography>
                                {customer?.reputationLevel === ECustomerReputationLevel.VIP &&
                                    <Paper elevation={0} sx={{ minWidth: "20px", height: "20px", display: "flex", justifyContent: "center", alignItems: "center", marginLeft: "-10px", marginRight: "10px" }}>
                                        <Box sx={{ paddingLeft: "10px" }}>
                                            <VIPViewComponent width={25} heigth={25} />
                                        </Box>
                                    </Paper>}

                                {customer?.reputationLevel === ECustomerReputationLevel.POOR &&
                                    <Paper elevation={0} sx={{ minWidth: "20px", height: "20px", display: "flex", justifyContent: "center", alignItems: "center", marginLeft: "-10px", marginRight: "10px" }}>
                                        <Box sx={{ paddingLeft: "10px" }}>
                                            <ErrorOutlineIcon color="error" fontSize="small" />
                                        </Box>
                                    </Paper>}
                                {customer?.reputationLevel === ECustomerReputationLevel.STANDARD &&
                                    <Paper elevation={0} sx={{ minWidth: "20px", height: "20px", display: "flex", justifyContent: "center", alignItems: "center", marginLeft: "-10px", marginRight: "10px" }}>
                                        <Box sx={{ paddingLeft: "10px" }}>
                                            <VerifiedUserOutlinedIcon color="success" fontSize="small" />
                                        </Box>
                                    </Paper>}
                            </SCViewList>
                        </SCViewList>
                        <Divider sx={{ marginRight: "20px", marginBottom: "28px" }} />

                        <SCViewList center alignCenter>
                            {
                                <Rating value={customer.reviewMark} readOnly></Rating>
                            }
                        </SCViewList>

                        <Typography sx={{ marginTop: "25px" }}>
                            {
                                `Last load: ${(customer.lastLoadDate)
                                    ? getDateFormat(customer.lastLoadDate, dateFormat)
                                    : 'N/A'}`
                            }
                        </Typography>
                    </SCViewList>

                </Box>

                : <div style={{ border: "1px dashed silver", display: "flex", padding: "10px", borderRadius: "3px", minHeight: "135px", width: "480px", fontSize: "15px", textAlign: "center", alignItems: "center", justifyContent: "center" }}>There is no selected customer.</div>
            }
        </Suspense >
    </>
}