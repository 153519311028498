import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface CounterState {
    value: number
  }
  
  const initialState: CounterState = {
    value: 0,
  }

  export const counterSlice = createSlice({
    name: 'counter',
    initialState,
    reducers: {
      increment: (state) => {
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the Immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes
        state.value += 1
      },
      decrement: (state) => {
        state.value -= 1
      },
      incrementByAmount: (state, action: PayloadAction<number>) => {
        state.value += action.payload
      },
    },
  })
  
  export const { increment, decrement, incrementByAmount } = counterSlice.actions
  export default counterSlice.reducer