import { IPrivilege, RoleLinks } from "../../models/PermissionModels";
import React, { useEffect, useState } from "react";
import { Button, Divider, InputAdornment, TextField, Tooltip, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRowParams } from "@mui/x-data-grid";
import SearchIcon from '@mui/icons-material/Search';
import PermissionService from "../../services/PermissionsService";
import { setLastError } from "../../store/reducers/cnfSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import RotateLeftIcon from '@mui/icons-material/RotateLeft';

interface IProps {
    name: string,
    uuid: string,
    save: boolean,
    reset: boolean,
    getState: (n: number) => void,
    snackMess: (s: string) => void;
    sentReset: (b: boolean) => void;
    getSave: (b: boolean) => void;
}


const PrivilegeTable = (props: IProps) => {
    const { uuid, save, getState, getSave } = props
    const [pageC, setPageC] = React.useState(0);
    const [pageSizeC, setPageSizeC] = React.useState(10);

    const [search, setSeacrh] = React.useState<string>("");
    const [checkState, setCheckState] = useState(true);
    const [selectionModel, setSelectionModel] = useState<Array<string>>([]);
    const [privilegeState, setPrivilegeState] = React.useState<IPrivilege[]>([]);
    const [roleLinksState, setRoleLinksState] = React.useState<RoleLinks[]>([]);
    const [snapshot, setSnapshot] = React.useState<string>("")
    const [reset, setReset] = useState<boolean>(true);
    const dispatch = useDispatch<AppDispatch>();
    const [pastSelectedPrivilege, setPastSelectedPrivilege] = useState<IPrivilege[]>([])

    React.useEffect(() => {
        let a: IPrivilege[] = [];

        for (let item of pastSelectedPrivilege) {
            if (item.caption.toUpperCase().indexOf(search.toUpperCase()) > -1) {

                a.push(item);

            }
        }

        setPrivilegeState(a);

    }, [search])


    useEffect(() => {
        (async () => {

            if (save && uuid) {
                let snapSht: string[] = JSON.parse(snapshot);
                let link: boolean = false;
                let permission: boolean = false;

                for (let item of privilegeState) {
                    if (selectionModel.includes(item.id ? item.id : "")) {
                        if (!snapSht.includes(item.id ? item.id : "")) {
                            link = true
                            permission = true
                        } else permission = false
                    } else {
                        if (snapSht.includes(item.id ? item.id : "")) {
                            link = false
                            permission = true
                        } else permission = false
                    }
                    if (permission) {
                        const [error, data] = (link)
                            ? await PermissionService.linkPrivilegeToRole({ roleId: uuid, privilegeId: item.id })
                            : await PermissionService.unlinkPrivilege({ roleId: uuid, privilegeId: item.id })
                        if (error) {
                            dispatch(setLastError(error))
                        }
                    }
                };
                props.snackMess("The privileges of the " + props.name + " role have been successfully updated");
                getState(-1);
                getSave(false);
                setReset(true);
            }
        })()

    }, [save]);

    React.useEffect(() => {
        (async () => {
            if (reset) {
                const [error, data] = await PermissionService.getAllRoleLinks();
                if (data) {
                    let arr = data.data.body as RoleLinks[];
                    setRoleLinksState(arr);
                }
                if (error) { dispatch(setLastError(error)) }
                setReset(false);
            }
        })()
    }, [reset])

    React.useEffect(() => {
        (async () => {

            const [error, response] = await PermissionService.getAllPrivilege()
            if (response) {
                let arr = response.data.body as IPrivilege[];
                let a: any = arr.map((e: IPrivilege) => { return { ...e, selected: false, id: e.uuid } })
                setPastSelectedPrivilege(a);
                setPrivilegeState(a);
            }
            if (error) { dispatch(setLastError(error)) }
        })()

    }, [])

    React.useEffect(() => {
        if (uuid !== "") {
            let a: string[] = []
            for (let item of roleLinksState) {
                if (item.uuid === uuid) {
                    item.privileges.map((e: IPrivilege) => {
                        if (e.uuid) {
                            a.push(e.uuid)
                        }
                    })
                }
            }
            setSnapshot(JSON.stringify(a));
            setSelectionModel(a);
        }
    }, [uuid, roleLinksState])

    useEffect(() => {
        if (selectionModel) {
            if (uuid !== "") {
                if (JSON.stringify(selectionModel) === snapshot) {
                    getState(-1);
                } else {
                    getState(1);
                }
            } else {
            }
        }
    }, [selectionModel]);
    useEffect(() => {
        if (props.reset) {
            if (uuid !== "") {
                if (JSON.stringify(selectionModel) !== snapshot) {
                    setSelectionModel(JSON.parse(snapshot));
                }

            } else {
                setSelectionModel([]);
                setSnapshot("");
            }
            props.sentReset(false)
        }


    }, [props.reset]);

    const columns: GridColDef[] = [
        {
            field: "privilegeName", headerName: 'privilege'.toUpperCase(), sortable: true, width: 250,
            renderCell: (cellValues: any) => {
                return (
                    <>
                        <div>
                            {cellValues.row.caption &&
                                <Tooltip
                                    title={String(cellValues.row.caption).toLocaleUpperCase()}
                                    placement={"bottom"}
                                    arrow={true}
                                >
                                    <Typography variant={'body2'} fontSize={'11px'}>{String(cellValues.row.caption).toLocaleUpperCase()} </Typography>
                                </Tooltip>
                            }
                        </div>
                    </>
                );
            }
        },

        {
            field: "url", headerName: 'url'.toUpperCase(), sortable: true, width: 150,
            renderCell: (cellValues: any) => {
                return (
                    <>
                        <div>
                            {cellValues.row.name &&
                                <Tooltip
                                    title={String(cellValues.row.name).toLocaleUpperCase()}
                                    placement={"bottom"}
                                    arrow={true}
                                >
                                    <Typography variant={'body2'} fontSize={'11px'}>{String(cellValues.row.name).toLocaleUpperCase()} </Typography>
                                </Tooltip>
                            }
                        </div>
                    </>
                );
            }
        },
        {
            field: "type", headerName: 'type'.toUpperCase(), sortable: true, width: 100,
            renderCell: (cellValues: any) => {
                return (
                    <>
                        <div>
                            {cellValues.row.type &&
                                <Tooltip
                                    title={String(cellValues.row.type).toLocaleUpperCase()}
                                    placement={"bottom"}
                                    arrow={true}
                                >
                                    <Typography variant={'body2'} fontSize={'11px'}>{String(cellValues.row.type).toLocaleUpperCase()} </Typography>
                                </Tooltip>
                            }
                        </div>
                    </>
                );
            }
        }

    ]


    const handleResetSeacrhBar = () => {
        setSeacrh("");
    }

    const handleSearch = (e: any) => {
        let s: string = e.target.value as string;
        setSeacrh(s);
    }

    const handlerPageSize = (newPageSize: number) => {
        setPageSizeC(newPageSize);
    }

    const handlerPage = (newPage: number) => {
        setPageC(newPage);
    }

    return (
        <>


            <div style={{ display: "flex", gap: "10px", width: "100%", paddingTop: "30px", paddingBottom: "10px" }}>
                <div style={{ display: "flex", width: "90%", backgroundColor: search ? ((privilegeState.length === 0) ? "#f9e4e7" : "white") : "white", fontWeight: "normal" }}>

                    <TextField
                        value={search}
                        inputProps={{ sx: { fontSize: "12px" } }}
                        style={{ width: "100%" }}
                        label="Search by privilege"
                        autoComplete="off"
                        onChange={handleSearch}
                        size={"small"}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon fontSize={'small'} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
                <Tooltip
                    title={search?"Reset search field":""}
                    arrow={true}
                    placement={"top"}
                >

                    <Button
                        disabled={!search}
                        variant="contained"
                        onClick={handleResetSeacrhBar}
                    >
                        <RotateLeftIcon
                            fontSize="small"
                        />
                    </Button>
                </Tooltip>

            </div>

            <Divider />

            <div style={{ height: "92%", paddingTop: "10px" }}>

                <DataGrid
                    density={'compact'}
                    rows={privilegeState}
                    columns={columns}
                    rowCount={privilegeState.length}

                    rowsPerPageOptions={[5, 10, 15]}

                    onPageSizeChange={handlerPageSize}
                    onPageChange={handlerPage}


                    page={pageC}
                    pageSize={pageSizeC}

                    isRowSelectable={(params: GridRowParams) => (uuid !== "") ? true : false}
                    checkboxSelection={checkState}
                    onSelectionModelChange={(newSelectionModel: any) => {
                        setSelectionModel(newSelectionModel);
                    }}
                    selectionModel={selectionModel}
                />
            </div>
        </>
    )
};
export default PrivilegeTable;