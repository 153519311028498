import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Typography, Tooltip, Box } from '@mui/material';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import DeblurIcon from '@mui/icons-material/Deblur';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';

import { RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { EStatusStafServer } from "../../models/IUser";
import { IRouteStatus } from "../../models/RoutesModels";
import { modifyRouteStatus } from "../../store/reducers/routesSlice";

interface IProps {
      countPar:IRouteStatus
}

export default function RoutesTabsFiltersComponent(props: IProps) {

    const { hints } = useSelector((state: RootState) => state.cnfSlice.interfaceState);
    const { status } = useSelector((state: RootState) => state.routesSlice);
    const { countPar } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handlerModifyTab = (s: string): void => {
        dispatch(modifyRouteStatus(s));
    }


    const styles = {
        item: { width: "40px" },
        box: { border: "1px solid silver", textAlign: "center", borderRadius: "3px", marginLeft: "2px", marginRight: "2px", marginBottom: "4px" },
        icon: { fontSize: "25px", cursor: "pointer", marginLeft: "8px" },
        button: { border: "1px solid silver" }
    };

    return (
        <>
            <div style={{
                display: "flex",
                gap: "2px",
                marginTop: "5px",
                justifyContent: "center",
                marginBottom: "10px"
            }}>

                <div style={styles.item}>
                    <Box sx={styles.box}>
                        <Typography sx={{ fontSize: "10px" }}>{countPar?.active}</Typography>
                    </Box>
                    <Tooltip
                        title={(hints) ? t("active") : ''}
                        placement={"bottom"}
                        arrow={true}
                    >
                        <AssignmentIndIcon
                            onClick={() => { handlerModifyTab(EStatusStafServer.ACTIVE) }}
                            color={(status === EStatusStafServer.ACTIVE) ? "primary" : "disabled"}
                            sx={styles.icon}
                        />
                    </Tooltip>
                </div>

                <div style={styles.item}>
                    <Box sx={styles.box}>
                        <Typography sx={{ fontSize: "10px" }}>{countPar?.inactive}</Typography>
                    </Box>
                    <Tooltip
                        title={(hints) ? t("inactive") : ''}
                        placement={"bottom"}
                        arrow={true}
                    >
                        <AssignmentTurnedInIcon
                            onClick={() => { handlerModifyTab(EStatusStafServer.INACTIVE) }}
                            color={(status === EStatusStafServer.INACTIVE) ? "primary" : "disabled"}
                            sx={styles.icon}
                        />
                    </Tooltip>
                </div>

                <div style={styles.item}>
                    <Box sx={styles.box}>
                        <Typography sx={{ fontSize: "10px" }}>{countPar?.incomplete}</Typography>
                    </Box>
                    <Tooltip
                        title={(hints) ? t("incomplete") : ''}
                        placement={"bottom"}
                        arrow={true}
                    >
                        <IntegrationInstructionsIcon
                            onClick={() => { handlerModifyTab(EStatusStafServer.INCOMPLETE) }}
                            color={(status === EStatusStafServer.INCOMPLETE) ? "primary" : "disabled"}
                            sx={styles.icon} />
                    </Tooltip>
                </div>

            </div>
        </>
    );
}

