import * as React from 'react';
import Popover from '@mui/material/Popover';
import InfoIcon from '@mui/icons-material/Info';
import { IconButton } from '@mui/material';

interface IProps {
  texts: string;
  icon?: any;
}

export default function HelpProperHtmlComponent(props: IProps) {
  const { texts, icon } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <IconButton onClick={handleClick}>
        { icon ? icon : <InfoIcon /> }
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div style={{ maxWidth: "400px", maxHeight: "400px", minWidth: "200px", minHeight: "200px", overflowY: "scroll", padding: "10px", fontSize: "10px" }}>
          <div dangerouslySetInnerHTML={{ __html: texts }}>
          </div>
        </div>
      </Popover>
    </div>
  );
}