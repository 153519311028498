
import { Box, Divider, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import styled from '@emotion/styled'
import { AppDispatch, RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { modifyRightFlip } from "../../store/reducers/cnfSlice";
import { useNavigate } from "react-router";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { IRouteDetails, IRouteResponseDetails } from "../../models/RoutesModels";
import SCViewList from "../../styledComponents/sharedComponent/SCViewList";
import { getDateFormat } from "../../helpers/dateHelper";
import { curency } from "../../helpers/textHelper";


interface IPropsCoverFaceRouteComponent {
  routes: IRouteResponseDetails | null
}

const CoverFaceRouteComponent = (props: IPropsCoverFaceRouteComponent) => {
  const { routes } = props;
  const { hints, dateFormat } = useSelector((state: RootState) => state.cnfSlice.interfaceState);
  const createdBy = routes?.createdBy.split('@')

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleClose = () => {
    dispatch(modifyRightFlip(false));
    navigate('/routes');
  }





  return (
    <>
      <Box sx={{ marginTop: "1", display: "flex", flexDirection: "column", alignItems: "center", gap: "10px" }}>
        <div style={{ borderBottom: "1px dashed silver", width: "100%" }}>
          <SCViewList spaceBetween>
            <Typography variant="h5" sx={{ paddingLeft: "10px" }}>{routes?.name}</Typography>
            <Tooltip
              title={(hints) ? "close" : ''}
              placement={"top"}
              arrow={true}
            >
              <IconButton
                onClick={handleClose}
                sx={{ paddingRight: "10px" }}
              >
                <HighlightOffIcon />
              </IconButton>
            </Tooltip>
          </SCViewList>
        </div>
        <div style={{ paddingBottom: "10px", width: "100%" }}>
          <SCViewList>
            <SCDIv>
              <SCViewList>
                <div style={{ width: "100px" }}>
                  <Typography variant="body2" >START DATE:</Typography>
                </div>
                <Typography variant="body2" >  {getDateFormat(routes?.startDate ? routes?.startDate : 0, dateFormat)}</Typography>
              </SCViewList>
              <SCViewList>
                <div style={{ width: "100px" }}>
                  <Typography variant="body2" >END DATE: </Typography>
                </div>
                <Typography variant="body2" >  {getDateFormat(routes?.endDate ? routes?.endDate : 0, dateFormat)}</Typography>
              </SCViewList>
            </SCDIv>
            <SCDIv>
              <SCViewList>
                <div style={{ width: "70px" }}>
                  <Typography variant="body2" >STATUS:</Typography>
                </div>
                <Typography variant="body2" >  {routes?.status}</Typography>
              </SCViewList>
              <SCViewList>
                <div style={{ width: "70px" }}>
                  <Typography variant="body2" >STATE: </Typography>
                </div>
                <Typography variant="body2" >  {routes?.state}</Typography>
              </SCViewList>
            </SCDIv>
            <SCDIv>
              <SCViewList>
                <div style={{ width: "90px" }}>
                  <Typography variant="body2" >AMOUNT:</Typography>
                </div>
                <Typography variant="body2" >  {routes?.amount + ' ' + curency}</Typography>
              </SCViewList>
              <SCViewList>
                <div style={{ width: "90px" }}>
                  <Typography variant="body2" >PROGRESS: </Typography>
                </div>
                <Typography variant="body2" >  {routes?.progress.completed + ' of ' + routes?.progress.total}</Typography>
              </SCViewList>
            </SCDIv>
            <SCDIv>
              <SCViewList>
                <div style={{ width: "120px" }}>
                  <Typography variant="body2" >CREATED DATE:</Typography>
                </div>
                <Typography variant="body2" > {getDateFormat(routes?.createdDate ? routes?.createdDate : 0, dateFormat)}</Typography>
              </SCViewList>
              <SCViewList>
                <div style={{ width: "150px" }}>
                  <Typography variant="body2" >CREATED  BY : </Typography>
                </div>
                <Typography variant="body2"  > {createdBy ? createdBy[0] : ""} </Typography>
              </SCViewList>
            </SCDIv>


          </SCViewList>
        </div>
      </Box>




    </>
  )
}

export default CoverFaceRouteComponent;
const SCDIv = styled.div`
   margin-left: 20px ;
   padding-left: 10px;
   display: flex;
   flex-direction: column;
   gap: 10px;
   height: 50px;
   border-left: 1px solid silver;
   justify-content: space-between;
   width: 18%;
   min-width: 220px;
                `