export interface IFileDetailsRequest {
    cropX?: number,
    cropY?: number,
    cropWidth?: number,
    cropHeight?: number
}

export interface IFileDetailsResponse {
    url: string,
    value?: string,
    extension: string,
    width?: number,
    height?: number
}

export interface IImageResponse {
    uuid: string,
    type: EFileType,
    fileDetails: IFileDetailsResponse,
    thumbnail: IFileDetailsResponse;
}

export enum EFileType {
    MAIN = 'MAIN',
    ADDITIONAL = 'ADDITIONAL'
}