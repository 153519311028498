import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Rating, Tooltip, Typography } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DownloadIcon from '@mui/icons-material/Download';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { IResponseAutocomplete } from "../../../models/IUser";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import StarsIcon from '@mui/icons-material/Stars';

import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import { modifyLeftFlip, modifyRightFlip, modifyCustomerPage, modifyCustomerRefresh, modifyCustomerSizePage, } from "../../../store/reducers/cnfSlice";
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import ActivateDetailsComponent from "../../CustomComponentModule/ActivateDetailsComponent";
import React from "react";
import ReportUserComponent from "../../ReportsModule/ReportUserComponent";
import CloseIcon from '@mui/icons-material/Close';
import CustomerService from "../../../services/CustomerService";
import { ICustomerListDTO } from "../../../models/CustomerModels";
import { getDateAndTimeFormat, getDateFormat } from "../../../helpers/dateHelper";
import { addFavoritesCustomers } from "../../../store/reducers/favoritesSlice";
import GenericCRUDService from "../../../services/GenericCRUDService";
import { curency } from "../../../helpers/textHelper";

export default function GridCustomerComponent() {

  const { auth } = useSelector((state: RootState) => state.cnfSlice);
  const { t } = useTranslation();
  const { id } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { refresh, page, size, part, role } = useSelector((state: RootState) => state.cnfSlice.customer);
  const { hints, timeFormat, dateFormat } = useSelector((state: RootState) => state.cnfSlice.interfaceState);

  const [checkState, setCheckState] = useState(true);
  const [selectionModel, setSelectionModel] = useState<Array<string>>([]);

  const [statePdfSelect] = useState<IResponseAutocomplete[]>([])
  const [open, setOpen] = React.useState(false);

  const [rowCustomers, setRowCustomers] = useState<ICustomerListDTO[]>([]);
  const [pageSizeC, setPageSizeC] = useState(size);
  const [pageC, setPageC] = useState(page);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTtalRows] = useState(0);

  const hendlerExcel = () => {
    let criteria: any[] = [];

    if (part !== 'ALL') {
      criteria.push({
        property: "status",
        value: part,
        expression: "EQUALS"
      })
    }

    if (role !== 'ALL') {
      criteria.push({
        property: "reputationLevel",
        value: role,
        expression: "EQUALS"
      })
    }

    let body = {
      criteria: criteria,
      paging: {
        page: page,
        size: size
      }
    }

    GenericCRUDService.downloadReport('customer/report', body)
  }

  const hendlerEmailList = () => {
    const url = 'https://mail.google.com/mail/?view=cm&fs=1';
    let to = '&to=',
        bcc = '&bcc=';

    if (auth) {
      bcc += auth.email;
    }

    for (let customer of rowCustomers) {
      if (selectionModel.includes(customer.uuid)) {
        to += customer.email + ','
      }
    }
    
    window.open(url + to + bcc);
  }

  const hendlerFavoritesList = () => {
    let list: string[] = [];
    for (let elm of rowCustomers) {
      if (selectionModel.includes(elm.uuid)) {
        list.push(elm.uuid);
      }
    }
    dispatch(addFavoritesCustomers(list));
  }

  const handleClose = () => {
    setOpen(false);
  }

  const hendlerPdfReportList = () => {
    // let list: IResponseAutocomplete[] = [];
    // for (let elm of rowUsers) {
    //   if (selectionModel.includes(elm.uuid)) {

    //     let u: IResponseAutocomplete = {
    //       uuid: elm.uuid,
    //       firstName: elm.firstName,
    //       lastName: elm.lastName,
    //       email: elm.email,
    //       username: elm.username,
    //       avatar: elm.avatar,
    //       color: elm.color
    //     }
    //     list.push(u);
    //   }
    // }
    // setStatePdfSelect(list);
    // handleOpen();
  }

  const handlerRefresh = () => {
    dispatch(modifyCustomerRefresh());
  }

  const handlerPage = (newPage: number) => {
    dispatch(modifyCustomerPage(newPage));
    setPageC(newPage);
  }

  const handlerPageSize = (newPageSize: number) => {
    dispatch(modifyCustomerSizePage(newPageSize));
    setPageSizeC(newPageSize);
  }

  const handlerCheck = () => {
    setSelectionModel([]);
    setCheckState((v) => { return !v });
  }

  const handleClick = (cellValues: any) => {
    navigate('/customer/' + cellValues.row.id);
    dispatch(modifyRightFlip(true));
    if (!selectionModel.includes(cellValues.row.id.toString())) {
      // setSelectionModel(list => [cellValues.row.id, ...list])
    }
  }

  const hendleShowLeft = () => {
    dispatch(modifyLeftFlip());
  }

  useEffect(() => {
    setLoading(true);

    (async () => {

      let criteria: any[] = [];

      if (part !== 'ALL') {
        criteria.push({
          property: "status",
          value: part,
          expression: "EQUALS"
        })
      }

      if (role !== 'ALL') {
        criteria.push({
          property: "reputationLevel",
          value: role,
          expression: "EQUALS"
        })
      }

      let body = {
        criteria: criteria,
        paging: {
          page: page,
          size: size
        }
      }

      const [error, response] = await CustomerService.filterBy(body);
      if (response) {

        let rs = response.data.body;
        setTtalRows(response.data.total);
        if (rs) {
          const st: ICustomerListDTO[] = rs.map((e: ICustomerListDTO) => (
            {
              ...e,
              id: e.uuid
            })
          )
          setRowCustomers(st);
        }
      }
      if (error) { }
      setLoading(false);
    })()
  }, [role, part, page, size, refresh])

  useEffect(() => {
    if (id) {
      if (!selectionModel.includes(id.toString())) {
        setSelectionModel(list => [id, ...list]);
      }
    }
  }, [id])

  const columns: GridColDef[] = [
    {
      field: "2", headerName: '', width: 50, sortable: false, filterable: false, disableColumnMenu: true,
      renderCell: (cellValues: any) => {
        return (
          <>
            <div style={{ display: "flex", textAlign: "center", justifyContent: "center", padding: 0 }}>
              <IconButton
                size={'small'}
                onClick={(event: any) => {
                  handleClick(cellValues);
                }}
              >
                <AutoAwesomeIcon color={"disabled"} />
              </IconButton>
              <ActivateDetailsComponent
                activate={() => handleClick(cellValues)}
              />

            </div>
          </>
        );
      }
    },
    {
      field: "1", headerName: 'NAME', sortable: false, filterable: false, disableColumnMenu: true, width: 240, headerAlign: "center",
      renderCell: (cellValues: any) => {
        return (
          <>
            <Tooltip
              title={cellValues.row.name}
              arrow={true}
            >
              <Typography variant={'body2'} fontSize={'11px'}>{cellValues.row.name} </Typography>
            </Tooltip>
          </>
        );
      }
    },
    {
      field: 'idno', headerName: t('IDNO'), width: 120, headerAlign: "center",
      renderCell: (cellValues: any) => {
        return (
          <>
            <Tooltip
              title={cellValues.row.idno}
              arrow={true}
            >
              <Typography variant={'body2'} fontSize={'11px'}>{cellValues.row.idno} </Typography>
            </Tooltip>
          </>
        );
      }
    },
    {
      field: 'email', headerName: 'EMAIL', width: 270, headerAlign: "center",
      renderCell: (cellValues: any) => {
        return (
          <>
            <Tooltip
              title={cellValues.row.email}
              arrow={true}
            >
              <Typography variant={'body2'} fontSize={'11px'}>{cellValues.row.email} </Typography>
            </Tooltip>
          </>
        );
      }
    },
    {
      field: 'phone', headerName: 'PHONE', width: 100, headerAlign: "center",
      renderCell: (cellValues: any) => {
        return (
          <>
            <Tooltip
              title={cellValues.row.phone}
              arrow={true}
            >
              <Typography variant={'body2'} fontSize={'11px'}>
                {cellValues.row.phone}
              </Typography>
            </Tooltip>
          </>
        );
      }
    },
    {
      field: 'load', headerName: 'LOADS', width: 100, headerAlign: "center",
      renderCell: (cellValues: any) => {
        return (
          <>
            <div style={{ display: "flex", gap: "5px", justifyContent: "center", alignItems: "center" }}>

              <Tooltip
                title={'TOTAL ' + cellValues.row.totalNumberOfLoads}
                arrow={true}
              >
                <Typography variant={'body2'} fontSize={'11px'} color={"black"} sx={{ minWidth: "25px", textAlign: "center" }}>
                  {cellValues.row.totalNumberOfLoads}
                </Typography>
              </Tooltip>
              |
              <Tooltip
                title={'PAYED ' + cellValues.row.numberOfPayedLoads}
                arrow={true}
              >
                <Typography variant={'body2'} fontSize={'11px'} color={"#003663"} sx={{ minWidth: "25px", textAlign: "center" }}>
                  {cellValues.row.numberOfPayedLoads}
                </Typography>
              </Tooltip>
            </div>
          </>
        );
      }
    },
    {
      field: 'AMOUNT', headerName: 'AMOUNT', width: 180, headerAlign: "center",
      renderCell: (cellValues: any) => {
        return (
          <>
            <div style={{ display: "flex", gap: "5px", justifyContent: "center", alignItems: "center" }}>
              <Tooltip
                title={'TOTAL ' + cellValues.row.totalAmount}
                arrow={true}
              >
                <Typography variant={'body2'} fontSize={'11px'} color={"black"} sx={{ minWidth: "70px", textAlign: "center" }}>
                  {cellValues.row.totalAmount + ' ' + curency}
                </Typography>
              </Tooltip>
              |
              <Tooltip
                title={'PAYED ' + cellValues.row.payedAmount}
                arrow={true}
              >
                <Typography variant={'body2'} fontSize={'11px'} color={"#003663"} sx={{ minWidth: "70px", textAlign: "center" }}>
                  {cellValues.row.payedAmount + ' ' + curency}
                </Typography>
              </Tooltip>
            </div>
          </>
        );
      }
    },
    {
      field: 'lastLoadDate', headerName: 'LAST LOAD', width: 130, headerAlign: "center",
      renderCell: (cellValues: any) => {
        const date = cellValues.row.lastLoadDate ? getDateFormat(cellValues.row.lastLoadDate, dateFormat) : null;
        return (
          <>
              <Typography variant={'body2'} fontSize={'11px'}>
                {date}
              </Typography>
          </>
        );
      }
    },
    {
      field: 'status', headerName: 'STATUS', width: 100, headerAlign: "center",
      renderCell: (cellValues: any) => {
        return (
          <>
            <Typography variant={'body2'} fontSize={'11px'}>
              {cellValues.row.status}
            </Typography>
          </>
        );
      }
    },
    {
      field: 'reputation', headerName: 'REPUTATION', width: 120, headerAlign: "center",
      renderCell: (cellValues: any) => {
        return (
          <>
            <Typography variant={'body2'} fontSize={'11px'}>
              {cellValues.row.reputationLevel}
            </Typography>
          </>
        );
      }
    },
    {
      field: 'review', headerName: 'REVIEW', width: 150, headerAlign: "center",
      renderCell: (cellValues: any) => {
        return (
          <>
            {<Rating precision={0.5} value={cellValues.row.reviewMark} readOnly></Rating>}
          </>
        );
      }
    },
    {
      field: "created", headerName: 'Created'.toUpperCase(), sortable: true, width: 180, headerAlign: "center",
      renderCell: (cellValues: any) => {
        const date = getDateAndTimeFormat(cellValues.row.createdDate, dateFormat, timeFormat),
          author = cellValues.row.createdBy,
          tooltipValue = author + ' ' + date;
        return (
          <>
            <Tooltip title={tooltipValue} placement={"bottom"}>
              <Typography variant={'body2'} fontSize={'11px'}>
                {author} <br /> {date}
              </Typography>
            </Tooltip>
          </>
        );
      }
    },
    {
      field: "updated", headerName: 'Updated'.toUpperCase(), sortable: true, width: 180, headerAlign: "center",
      renderCell: (cellValues: any) => {
        const date = getDateAndTimeFormat(cellValues.row.updatedDate, dateFormat, timeFormat),
          author = cellValues.row.updatedBy,
          tooltipValue = author + ' ' + date;
        return (
          <>
            {cellValues.row.updatedDate &&
              <Tooltip title={tooltipValue} placement={"bottom"}>
                <Typography variant={'body2'} fontSize={'11px'}>
                  {author} <br /> {date}
                </Typography>
              </Tooltip>
            }
          </>
        );
      }
    }
  ];

  const styled = {
    container: { display: "flex", gap: "20px" },
    item: { width: "40px" },
    box: { border: "1px solid silver", textAlign: "center", borderRadius: "3px" },
    icon: { fontSize: "28px", cursor: "pointer" },
    button: { border: "1px solid silver" }
  }

  return (
    <>
      <div style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingBottom: "2px",
        height: "46px"
      }}>
        <div>

          <div style={{ width: "400px", paddingLeft: "7px", display: "flex", gap: "10px" }}>
            <Tooltip
              title={(hints) ? t("Show/Hide the selection column") : ''}
              placement={"top"}
              arrow={true}
            >
              <IconButton onClick={handlerCheck} size='small'>
                <PlaylistAddCheckCircleIcon style={styled.icon} />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={(hints) ? t("Refresh") : ''}
              placement={"top"}
              arrow={true}
            >
              <IconButton onClick={handlerRefresh} size='small'>
                <ReplayCircleFilledIcon style={styled.icon} />
              </IconButton>
            </Tooltip>
            {!(rowCustomers.length < 1) && <Tooltip
              title={(hints) ? t("Download XLS report for customer") : ''}
              placement={"top"}
              arrow={true}
              onClick={hendlerExcel}
              key={"key"}
            >
              <IconButton>
                <DownloadIcon style={styled.icon} />
              </IconButton>
            </Tooltip>}
            {(selectionModel.length > 0) &&
              <div>
                <Tooltip
                  title={(hints) ? t("Generate PDF report for selected customer") : ''}
                  placement={"top"}
                  arrow={true}
                >
                  <IconButton disabled onClick={hendlerPdfReportList}>
                    <PictureAsPdfIcon style={styled.icon} color={"primary"} />
                  </IconButton>
                </Tooltip>

                <Tooltip
                  title={(hints) ? t("Send email to selected customer") : ''}
                  placement={"top"}
                  arrow={true}
                >
                  <IconButton
                    onClick={hendlerEmailList}
                  >
                    <MarkEmailReadIcon style={styled.icon} color={"primary"} />
                  </IconButton>
                </Tooltip>

                <Tooltip
                  title={(hints) ? t("Add selected customer(s) to favorites") : ''}
                  placement={"top"}
                  arrow={true}
                >
                  <IconButton
                    onClick={hendlerFavoritesList}
                  >
                    <StarsIcon style={styled.icon} color={"primary"} />
                  </IconButton>
                </Tooltip>

              </div>
            }
          </div>
        </div>
        <div>
          {(id) &&
            <Tooltip
              title={(hints) ? t("Customer") : ''}
              placement={"top"}
              arrow={true}
            >
              <IconButton onClick={hendleShowLeft} >
                <MultipleStopIcon style={styled.icon} color={"primary"} />
              </IconButton>
            </Tooltip>
          }
        </div>
      </div>

      <div style={{ height: "92%" }}>
        <DataGrid
          density={'compact'}
          paginationMode="server"
          loading={loading}
          rows={rowCustomers}
          columns={columns}

          rowCount={totalRows}
          page={pageC}
          pageSize={pageSizeC}

          rowsPerPageOptions={[5, 10, 15, 20, 25]}
          onPageSizeChange={handlerPageSize}
          onPageChange={handlerPage}

          checkboxSelection={checkState}
          onSelectionModelChange={(newSelectionModel: any) => {
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
        />
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={'lg'}

      >
        <DialogTitle>{`Pdf Report`}</DialogTitle>
        <DialogContent>
          <ReportUserComponent user={statePdfSelect} />
        </DialogContent>
        <DialogActions>
          <div style={{ display: "flex", justifyContent: "right", width: "100%", padding: "20px" }}>
            <Button
              startIcon={<CloseIcon />}
              color={'inherit'}
              variant={'contained'}
              size={'small'}
              onClick={handleClose}>Close</Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}