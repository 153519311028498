
import React, { FC, useEffect, useState } from "react";
import { Alert, Box, Button, Checkbox, Divider, IconButton, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";

import FilterListIcon from '@mui/icons-material/FilterList';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import StarBorderPurple500Icon from '@mui/icons-material/StarBorderPurple500';

import { useTranslation } from "react-i18next";
import { WayListComponent } from "./WayListComponent";
import { ItemsService } from "../../services/ItemsService";

import { IWayInfoInMap } from "../../models/MapModels";
import { setWayInfo, modifyMapState, clearFilters, clearStars, setFiltersWays } from "../../store/reducers/mapSlice";
import { useTheme } from "@emotion/react";
import HelpProperMap from "../CustomComponentModule/HelpProperComponent";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";


export const AuxMapComponent = () => {

    const dispatch = useDispatch();
    const theme = useTheme();
    const { mapState, startsMap, wayInfo, filtersMap } = useSelector((state: RootState) => state.mapSlice);
    const { t } = useTranslation();
    const [w, setW] = useState<Array<IWayInfoInMap>>([])
    const [f, setF] = useState('');
    // const [stats, setStarts] = useState<Array<string>>(['1','2','3','4','5'])

    const handleViewPaths = (event: any, newValue: any) => {
        if (newValue !== null) {
            dispatch(modifyMapState({ ...mapState, viewDrawPaths: newValue }))
        }
    };
    const handleViewMarks = (event: any, newValue: any) => {
        if (newValue?.length) {
            dispatch(modifyMapState({ ...mapState, viewSetMarks: newValue }))
        }
    };
    const handleChangeViewPaths = (event: any) => {
        dispatch(modifyMapState({ ...mapState, drawPaths: event.target.checked }))
    };
    const handleChangeViewMarks = (event: any) => {
        dispatch(modifyMapState({ ...mapState, setMarks: event.target.checked }))
    };
    const heandlerClearAll = () => { dispatch(clearFilters()); dispatch(clearStars()); }
    const heandlerClearFilters = () => { dispatch(clearFilters()); setF('') }
    const heandlerClearSrars = () => { dispatch(clearStars()); }

    useEffect(() => {

        let OP: Array<string> = [];
        let b = f.split(' ');
        b.forEach(e => {
            let c = e.split('#');
            if ((c[0] === 'O') && (c[1] !== '') && (c[1] !== undefined)) { OP.push(c[1]) };
        });

        let ras: Array<IWayInfoInMap> | undefined = wayInfo.filter(e => OP.includes(e.currentOperator.id.toString()))

        if (ras.length > 0) {
            setW(sortWays(ras));
            let filterID: any = [];

            ras.forEach(e => {
                filterID.push(e.id.toString())
            });

            dispatch(setFiltersWays(filterID))

        } else {
            setW(sortWays(wayInfo))
            dispatch(clearFilters());
        };

    }, [f])

    useEffect(() => {
        (async () => {
            await ItemsService.getWayList().then((data: any) => {
                if (data) {
                    dispatch(setWayInfo(data.data));
                }
            }).catch(() => { })
        })();
    }, [])

    useEffect(() => {
        setW(sortWays(wayInfo));
    }, [wayInfo])

    useEffect(() => {
        setW(sortWays(w));
    }, [startsMap])

    function sortWays(a: Array<IWayInfoInMap>): Array<IWayInfoInMap> {

        let b: Array<string> = startsMap;
        let a_1 = a.filter((x: IWayInfoInMap) => (b.indexOf(x.id.toString()) >= 0) && (x.issues > 0))
        let a_2 = a.filter((x: IWayInfoInMap) => (b.indexOf(x.id.toString()) >= 0) && (x.issues <= 0))
        let a_3 = a.filter((x: IWayInfoInMap) => (b.indexOf(x.id.toString()) < 0) && (x.issues > 0))
        let a_4 = a.filter((x: IWayInfoInMap) => (b.indexOf(x.id.toString()) < 0) && (x.issues <= 0))

        return [...a_1, ...a_2, ...a_3, ...a_4];
    }


    return (
        <>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1, marginTop: "10px" }}>
                <Checkbox
                    checked={mapState.drawPaths}
                    onChange={handleChangeViewPaths}
                />
                {(mapState.drawPaths) &&
                    <ToggleButtonGroup
                        value={mapState.viewDrawPaths}
                        exclusive
                        onChange={handleViewPaths}
                        aria-label="text"
                    >
                        <ToggleButton size={"small"} value="all" aria-label="centered">
                            <ClearAllIcon />
                        </ToggleButton>
                        <ToggleButton size={"small"} value="filter" aria-label="left aligned">
                            <FilterListIcon />
                        </ToggleButton>
                        <ToggleButton size={"small"} value="star" aria-label="centered">
                            <StarBorderPurple500Icon />
                        </ToggleButton>
                    </ToggleButtonGroup>
                }
                <Typography variant={"body2"}>{t("draw paths")}</Typography>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", gap: 1, marginTop: "5px", marginBottom: "10px" }}>
                <Checkbox
                    checked={mapState.setMarks}
                    onChange={handleChangeViewMarks}
                />
                {(mapState.setMarks) &&
                    <ToggleButtonGroup
                        value={mapState.viewSetMarks}
                        exclusive
                        onChange={handleViewMarks}
                        aria-label="text"
                    >
                        <ToggleButton size={"small"} value="all" aria-label="centered">
                            <ClearAllIcon />
                        </ToggleButton>
                        <ToggleButton size={"small"} value="filter" aria-label="left aligned">
                            <FilterListIcon />
                        </ToggleButton>
                        <ToggleButton size={"small"} value="star" aria-label="centered">
                            <StarBorderPurple500Icon />
                        </ToggleButton>
                    </ToggleButtonGroup>
                }
                <Typography variant={"body2"} > {t("set markers")} </Typography>
            </Box>
            <Divider />

            <Box sx={{ display: "flex", alignItems: "center", gap: 1, marginTop: "10px", marginBottom: "10px" }}>

                <HelpProperMap
                    texts={["O# - code operator","C# - part of load id","A# - part of name driver"]}                    
                ></HelpProperMap>
                <TextField
                    size={"small"}
                    id="outlined-basic"
                    label={t("filter keywords")}
                    variant="outlined"
                    style={{ width: "100%" }}
                    value={f}
                    onChange={event => setF(event.target.value)}
                />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2, justifyContent: "right", marginRight: "5px", marginBottom: "5px" }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1, justifyContent: "right" }}>
                    <IconButton size={"small"} onClick={heandlerClearAll}>
                        <ClearAllIcon fontSize={"small"} />
                    </IconButton>
                    [{wayInfo?.length}]
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1, justifyContent: "right" }} >
                    <IconButton size={"small"} onClick={heandlerClearFilters}>
                        <FilterListIcon fontSize={"small"} />
                    </IconButton>
                    [{filtersMap?.length}]
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1, justifyContent: "right" }}>
                    <IconButton size={"small"} onClick={heandlerClearSrars}>
                        <StarBorderPurple500Icon fontSize={"small"} />
                    </IconButton >
                    [{startsMap?.length}]
                </Box>
            </Box>
            <Divider />
            {
                w?.map((elm: IWayInfoInMap) => (
                    <WayListComponent
                        key={elm.id}
                        star={startsMap.some(a => a === elm.id.toString())}
                        info={elm}
                    ></WayListComponent>
                ))
            }
        </>
    )
}