import SettingsIcon from "@mui/icons-material/Settings";
import { IMenuElement } from "../../models/IMenuElement";
import MenuComponent from "./MenuComponent";

import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import RouteIcon from '@mui/icons-material/Route';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CategoryIcon from '@mui/icons-material/Category';
import SecurityIcon from '@mui/icons-material/Security';

import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { useEffect, useState } from "react";

export const menuContent: Array<IMenuElement> = [{
    id: 1,
    name: 'Dashboard',
    description: 'Dashboard',
    to: '/',
    icon: <DashboardIcon />,
    short: '1',
    disabled: false
}, {
    id: 2,
    name: 'Route',
    description: 'Route management',
    to: 'route',
    icon: <RouteIcon />,
    short: '2',
    disabled: true
}, {
    id: 3,
    name: 'Active Load',
    description: 'Active load management',
    to: 'activeLoad',
    icon: <AccountTreeIcon />,
    short: '3',
    disabled: false
}, {
    id: 4,
    name: 'Load',
    description: 'Load management',
    to: 'load',
    icon: <CategoryIcon />,
    short: '4',
    disabled: false
}, {
    id: 5,
    name: 'Customer',
    description: 'Customer management',
    to: 'customer',
    icon: <BusinessCenterIcon />,
    short: '5',
    disabled: false
}, {
    id: 6,
    name: 'Warehouse',
    description: 'Warehouse management',
    to: 'warehouse',
    icon: <WarehouseIcon />,
    short: '6',
    disabled: false
}, {
    id: 7,
    name: 'Transport',
    description: 'Transport management',
    to: 'transport',
    icon: <LocalShippingIcon />,
    short: '7',
    disabled: false
}, {
    id: 8,
    name: 'User',
    description: 'User management',
    to: 'user',
    icon: <PeopleAltIcon />,
    short: '8',
    disabled: false
}, {
    id: 9,
    name: 'Audit',
    description: 'Audit management',
    to: 'audit',
    icon: <SettingsBackupRestoreIcon />,
    short: '9',
    disabled: false
}, {
    id: 10,
    name: 'Permissions',
    description: 'Permissions management',
    to: 'permissions',
    icon: <SecurityIcon />,
    short: '10',
    disabled: false
}, {
    id: 11,
    name: 'Settings',
    description: 'Settings',
    to: 'settings/personalProfile',
    icon: <SettingsIcon />,
    short: '11',
    disabled: false,
    submenu: [{
        id: 0,
        name: 'PERSONAL PROFILE',
        description: 'Personal profile management',
        to: '/settings/personalProfile',
        icon: <SettingsIcon />,
        short: '13',
        disabled: false
    }, {
        id: 1,
        name: 'GLOBAL PROFILE',
        description: 'Global profile management',
        to: '/settings/globalProfile',
        icon: <SettingsIcon />,
        short: '14',
        disabled: false
    }, {
        id: 2,
        name: 'HELP & PRIVACY',
        description: 'Help and privacy management',
        to: '/settings/helpAndPrivacy',
        icon: <SettingsIcon />,
        short: '15',
        disabled: false
    },{
        id: 3,
        name: 'QUOTES',
        description: 'Quotes management',
        to: '/settings/quotes',
        icon: <SettingsIcon />,
        short: '16',
        disabled: false
    }]
}, {
    id: 12,
    name: 'Logout',
    description: 'Logout',
    to: 'logout',
    icon: <LogoutIcon />,
    short: '12',
    disabled: false
}];

export default function HeadMenuComponent() {
    const { menuState, hints } = useSelector((state: RootState) => state.cnfSlice.interfaceState);
    const navigate = useNavigate();
    const location = useLocation().pathname
    const [path, setPath] = useState<string>('');

    useEffect(() => {
        let path: Array<string> = (location.split('/').filter((e) => e !== ''));
        (path[0] === '') ? setPath('/') : setPath(path[0]);
    }, [location]);

    const heandlerLink = (path: string) => { navigate(path); }

    return (
        <>
            {
                menuContent.map((menu: IMenuElement, i: number) => (
                    <MenuComponent
                        disable={menu.disabled as boolean}
                        key={i}
                        hints={hints}
                        heandlerLink={heandlerLink}
                        active={menu.to.startsWith(path)}
                        bigSize={menuState}
                        menu={menu}
                    />
                ))
            }
        </>
    );
}