import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Checkbox, Divider, Radio, Box, Button, DialogTitle, Dialog, TextField } from '@mui/material';
import { IMotto, TMottos } from "../../models/IMotto";

import MottoService from "../../services/MottoService";

import { setLastError } from "../../store/reducers/cnfSlice";
import { ejectTextWhitError, isText } from "../../helpers/textHelper";
import { useDispatch } from "react-redux";

interface IPropsSettings {
  snackMess: (s: string) => void;
}
export default function SettingsMottosComponent(props: IPropsSettings) {

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [allMotto, setAllMotto] = useState<TMottos>(null);
  useEffect(() => {
    (async () => {
      const [error, data] = await MottoService.getAllMotto()
      if (data) {
        let mottos: TMottos = data;
        if (mottos && (mottos.filter((e: IMotto) => isText(e.text) && e.active)).length > 1) { setRandValue(true) }
        setAllMotto(mottos);
      }
      if (error) {
        dispatch(setLastError(error));
      }
    })()
  }, [dispatch])

  /* +++ Dialog */
  const [open, setOpen] = React.useState(false);
  const handleClose = () => { setOpen(false); };
  const saveAll = () => {
    (async () => {
      const [error, data] = await MottoService.saveAllMotto(allMotto);
      if (data) {
        props.snackMess("The quotes was successfully updated.");

      }
      if (error) { dispatch(setLastError(ejectTextWhitError(error))); }
    })()
  }

  const handleSave = (value: IMotto | null) => {
    setOpen(false);
    if (allMotto) {
      for (let i in allMotto) {
        if (allMotto[i].uuid === value?.uuid) {
          if (allMotto.filter(m => isText(m.text)).length === 0) {
            allMotto[i].active = true;
          }
          allMotto[i].author = value.author;
          allMotto[i].text = value.text;
          if (!isText(value.text) && (!randValue)) { setRandValue(true) }
        }
      }
    }
    saveAll();
  };
  /* --- Dialog */

  /* +++ random state control */
  const [randValue, setRandValue] = React.useState(false);
  const [, setUuidSelected] = React.useState('');
  const handleRandValue = () => {

    if ((!randValue) && (allMotto)) {
      for (let m of allMotto) {
        m.active = true;
      }
    }

    if ((randValue) && (allMotto)) {
      for (let m of allMotto) {
        m.active = false;
      }
      let valid = allMotto.filter(m => isText(m.text))
      let n = valid.length;
      if (n > 0) {
        let i = Math.floor(Math.random() * n);
        valid[i].active = true;
      }
    }
    setRandValue(!randValue);
    saveAll();
  }

  const [selectedMotto, setSelecredMotto] = useState<IMotto | null>(null)
  const handleSelect = (motto: IMotto) => {
    setSelecredMotto({ ...motto })
    setOpen(true);
  }
  const handleCheck = (motto: IMotto) => {
    if (allMotto) {
      for (let m of allMotto) {
        if (m.uuid === motto?.uuid) { m.active = true; }
        else { m.active = false; }
      }
    }
    setUuidSelected(motto.uuid);
    saveAll();
  }

  return (
    <>
      <Divider></Divider>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ flex: "1", marginTop: "5px" }}>
          {allMotto && allMotto.map((m: IMotto, i: number) =>
            <MottoComponent
              key={i}
              onSelectForEdit={handleSelect}
              onCheckEdit={handleCheck}
              motto={m}
              randomState={randValue}
              nr={i + 1}
            />
          )}
        </Box >
      </Box>
      <Divider></Divider>

      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "left", marginTop: "10px" }}>
        <Checkbox checked={randValue} onChange={handleRandValue} />
        <Box onClick={handleRandValue} style={{ cursor: "pointer" }}>
          {t("Random selection")}
        </Box>
      </Box>

      <MottoEditDialog
        motto={selectedMotto}
        open={open}
        onClose={handleClose}
        onSave={handleSave}
      />

    </>
  );
}

/* component in other component */
interface IProps {
  motto: IMotto,
  randomState: boolean,
  nr: number,
  onSelectForEdit: (motto: IMotto) => void;
  onCheckEdit: (motto: IMotto) => void;
}

const MottoComponent = (props: IProps): JSX.Element => {

  const { t } = useTranslation();
  return (
    <>
      {isText(props.motto.text)
        ?
        <div style={{ display: "flex", paddingTop: "8px", paddingBottom: "8px", alignItems: "center", maxWidth: "500px" }}>
          {!props.randomState
            ? <Radio
              checked={props.motto.active}
              onChange={() => props.onCheckEdit(props.motto)}
            />
            : <div
              style={{
                minWidth: "30px",
                minHeight: "36px",
                marginLeft: "1px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "10px",
                border: "1px dashed silver",
                borderRadius: "4px"

              }}>
              {props.nr}
            </div>
          }

          <div
            style={{ cursor: "pointer" }}
            onClick={() => props.onSelectForEdit(props.motto)}
          >
            {props.motto.text}[<b>{props.motto.author}</b>]
          </div>

        </div>
        :
        <div
          style={{
            cursor: "pointer",
            minWidth: "100%",
            height: "45px",
            marginBottom: "5px",
            border: "1px dashed silver",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "silver"
          }}
          onClick={() => props.onSelectForEdit(props.motto)}
        >
          {t("quote is empty")}
        </div>
      }
    </>
  )
}

/* dialog window */
interface MottoEditDialogProps {
  motto: IMotto | null;
  open: boolean;
  onClose: () => void;
  onSave: (value: IMotto | null) => void;
}

function MottoEditDialog(props: MottoEditDialogProps) {
  const { t } = useTranslation();
  const { onClose, onSave, open, motto } = props;
  const [author, setAuthor] = useState<string>('');
  const [text, setText] = useState<string>('');

  const handleClose = () => { onClose(); };
  const handleSave = (motto: IMotto | null) => {
    if (motto) {
      let request: IMotto = { ...motto };
      request.author = author;
      request.text = text;
      onSave(request);
    }
  };

  useEffect(() => {
    (motto?.author) ? setAuthor(motto.author) : setAuthor('');
    (motto?.text) ? setText(motto.text) : setText('');
  }, [motto])

  function handleChangeText(event: any) { setText(event.target.value); }
  function handleChangeAuthor(event: any) { setAuthor(event.target.value); }

  const styles = {
    Button: { width: "150px", margin: "0 auto" },
    textarea: { fontFamily: "inherit", maxWidth: "78%", minWidth: "78%", width: "78%", fontSize: "16px", padding: "10px", border: "1px solid silver", borderRadius: "4px", height: "200px" }
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle style={{ textAlign: "center" }}>{t("Edit quote").toLocaleUpperCase()}</DialogTitle>

      <div style={{ width: "600px", display: "flex", flexDirection: "column", gap: "15px", alignItems: "center" }}>

        <TextField
          id="outlined-basic"
          label={t("author").toLocaleUpperCase()}
          variant="outlined"
          style={{ width: "82%" }}
          value={author}
          onChange={handleChangeAuthor}
        />
        <textarea
          aria-label="minimum height"
          value={text}
          onChange={handleChangeText}
          placeholder={t("Text of quote")}
          style={styles.textarea}
        />
      </div>

      <div style={{ display: "flex", marginBottom: "20px", marginTop: "20px" }}>
        <Button style={styles.Button} variant="contained" onClick={() => handleSave(motto)}>{t("save")}</Button>
        <Button style={styles.Button} variant="contained" color="error" onClick={handleClose}>{t("close")}</Button>
      </div>

    </Dialog>
  );
}