import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import { Button, Paper, Snackbar, Tab, Typography } from '@mui/material';
import Box from '@mui/material/Box';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useTranslation } from "react-i18next";
import SettingsMottosComponent from '../components/SettingsModule/SettingsMottosComponent';
import SettingsFilesPrivacyAndHelpComponent from '../components/SettingsModule/SettingsFilesPrivacyAndHelpComponent';
import InterfaceComponent from '../components/SettingsModule/personal profile/InterfaceComponent';
import DateAndTimeFormat from '../components/SettingsModule/personal profile/DateAndTimeFormat';
import { IInterfaceState } from '../models/IUser';
import GeneralService from '../services/GeneralService';
import { useDispatch, useSelector } from 'react-redux';
import { addNotification, modifyDateFormat, modifyEmailNotifications, modifyGlobalPreference, modifyLanguage, modifyMenuState, modifyModeTheme, modifyPushNotifications, modifyShowHits, modifyTimeFormat, setLastError } from '../store/reducers/cnfSlice';
import { AppDispatch, RootState } from '../store/store';
import LanguageSettingsComponent from '../components/SettingsModule/personal profile/LanguageSettingsComponent';
import MessureSettingsComponent from '../components/SettingsModule/global profile/MeasureSettingsComponent';
import { IGlobalProfile } from '../models/ProfileModels';
import { useLocation, useNavigate } from 'react-router-dom';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import CancelIcon from '@mui/icons-material/Cancel';
import { INotification } from '../models/NotificationModels';
import { ENotificationType } from '../models/EnumGeneral';
import NotificationSettingsComponent from '../components/SettingsModule/personal profile/NotificationSettingsComponent';
import { menuContent } from '../components/NavigateModule/HeadMenuComponent';
import { IMenuElement } from '../models/IMenuElement';

function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export default function SettingsPage() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [value, setValue] = React.useState(0);

    const { interfaceState, globalPreferenceState } = useSelector((state: RootState) => state.cnfSlice);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [textSnackbar, setTextSnackbar] = useState('');
    const location = useLocation();
    const dispatch = useDispatch<AppDispatch>();

    const snackMsg = (text: string) => {
        let n: INotification = {
            userUUID: " ",
            dt: 0,
            text: [text],
            state: false,
            type: ENotificationType.ADDITIONAL,
            link: location.toString()
        };
        dispatch(addNotification(n));
        setTextSnackbar(text);
        setOpenSnackbar(true);
    };

    const settingsMenu: IMenuElement = menuContent
        .filter(item => item.id === 11)[0];
    const settingsSubmenu: Array<IMenuElement> = settingsMenu.submenu ? settingsMenu.submenu : [];

    const handleChange = (event: any, newValue: any) => {
        const menuItem = settingsSubmenu
            .filter(item => item.id === newValue)[0];

        setValue(newValue);
        navigate(menuItem.to);
    };

    const handleUpdateStateInterface = (async (state: IInterfaceState) => {
        const { language, notificationPushEnabled, notificationEmailEnabled, ...rest } = state;
        const body = {
            language: state.language,
            webAppPreferences: JSON.stringify(rest),
            notificationPushEnabled: state.notificationPushEnabled,
            notificationEmailEnabled: state.notificationEmailEnabled
        };
        const [, response] = await GeneralService.putUserProfile(body);
        if (response) {
            if (state.darkMode !== interfaceState.darkMode) { dispatch(modifyModeTheme(state.darkMode)) }
            if (state.menuState !== interfaceState.menuState) { dispatch(modifyMenuState(state.menuState)) }
            if (state.hints !== interfaceState.hints) { dispatch(modifyShowHits(state.hints)) }
            snackMsg("The personal profile settings was successfully updated.")
        }
    });

    const handleCloseSnackbar = () => { setOpenSnackbar(false); }

    const handleUpdateLanguage = (async (state: IInterfaceState) => {
        const { language, notificationPushEnabled, notificationEmailEnabled, ...rest } = state
        const body = {
            language: state.language,
            webAppPreferences: JSON.stringify(rest),
            notificationPushEnabled: state.notificationPushEnabled,
            notificationEmailEnabled: state.notificationEmailEnabled
        };
        const [, response] = await GeneralService.putUserProfile(body);
        if (response) {
            if (state.language !== interfaceState.language) { dispatch(modifyLanguage(state.language)) }
            snackMsg("The personal profile settings was successfully updated.")
        }
    });

    const handleUpdateNotifications = (async (state: IInterfaceState) => {
        const { language, notificationPushEnabled, notificationEmailEnabled, ...rest } = state
        const body = {
            language: state.language,
            webAppPreferences: JSON.stringify(rest),
            notificationPushEnabled: state.notificationPushEnabled,
            notificationEmailEnabled: state.notificationEmailEnabled
        };
        const [, response] = await GeneralService.putUserProfile(body);
        if (response) {
            if (state.notificationPushEnabled !== interfaceState.notificationPushEnabled) { dispatch(modifyPushNotifications(state.notificationPushEnabled)) }
            if (state.notificationEmailEnabled !== interfaceState.notificationEmailEnabled) { dispatch(modifyEmailNotifications(state.notificationEmailEnabled)) }

            snackMsg("The personal profile settings was successfully updated.")
        }
    });

    const handleUpdateStateDateAndTime = (async (state: IInterfaceState) => {
        const body = {
            language: state.language,
            webAppPreferences: JSON.stringify(state),
            notificationPushEnabled: state.notificationPushEnabled,
            notificationEmailEnabled: state.notificationEmailEnabled
        };
        const [, response] = await GeneralService.putUserProfile(body);
        if (response) {
            if (state.dateFormat !== interfaceState.dateFormat) { dispatch(modifyDateFormat(state.dateFormat)) }
            if (state.timeFormat !== interfaceState.timeFormat) { dispatch(modifyTimeFormat(state.timeFormat)) }
            snackMsg("The personal profile settings was successfully updated.")
        }
    });

    const handleUpdateGlobalPreference = (async (state: IGlobalProfile) => {
        const [, response] = await GeneralService.putGlobalPreferenceProfile(state);
        if (response) {
            dispatch(modifyGlobalPreference(state))
            snackMsg("The global profile settings was successfully updated.")
        }
    });

    return (
        <Box>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={5000}
                onClose={handleCloseSnackbar}
                message=""
                anchorOrigin={{ vertical: "top", horizontal: 'center' }}
                action={<Button color="secondary" size="small"> lorem ipsum dolorem </Button>}
            >
                <Paper style={{ display: "flex", padding: "10px", minWidth: "350px", gap: "20px", border: "1px solid silver" }}>
                    <div style={{ display: "flex", alignItems: "center", paddingLeft: "15px" }}>
                        <GppMaybeIcon color={'success'} fontSize={'large'} />
                    </div>

                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        minWidth: "250px",
                        justifyContent: "center"
                    }}>
                        <div>
                            <Typography variant='subtitle2' >
                                {textSnackbar}
                            </Typography>
                        </div>
                    </div>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Button onClick={handleCloseSnackbar}><CancelIcon /></Button>
                    </div>

                </Paper>
            </Snackbar>

            <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }} >
                <Tabs orientation="vertical" variant="scrollable" value={value} onChange={handleChange} aria-label="Vertical tabs example" sx={{ borderRight: 1, borderColor: 'divider' }}>
                    {
                        settingsSubmenu.map((item: IMenuElement) => (
                            <Tab label={t(item.name)} key={item.id} />
                        ))
                    }
                </Tabs>

                <TabPanel value={value} index={0}>
                    <Box style={{ minWidth: "550px", display: "flex", flexDirection: "column", gap: 10 }}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography color={"secondary"}>{t("LANGUAGE")}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <LanguageSettingsComponent stateLocal={interfaceState} getNewInterfaceState={handleUpdateLanguage}></LanguageSettingsComponent>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography color={"secondary"}>{t("INTERFACE")}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <InterfaceComponent getNewInterfaceState={handleUpdateStateInterface} state={interfaceState}></InterfaceComponent>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography color={"secondary"}>{"DATE AND TIME FORMAT"}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <DateAndTimeFormat getNewInterfaceState={handleUpdateStateDateAndTime} state={interfaceState}></DateAndTimeFormat>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography color={"secondary"}>{t("NOTIFICATIONS ")}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <NotificationSettingsComponent stateLocal={interfaceState} getNewInterfaceState={handleUpdateNotifications}></NotificationSettingsComponent>
                            </AccordionDetails>
                        </Accordion>
                    </Box>

                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Box style={{ minWidth: "550px", display: "flex", flexDirection: "column", gap: 10 }}>


                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography color={"secondary"}>{t("MEASURE")}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <MessureSettingsComponent globalProfileState={globalPreferenceState} getNewInterfaceState={handleUpdateGlobalPreference}></MessureSettingsComponent>
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Box style={{ minWidth: "550px", display: "flex", flexDirection: "column", gap: 10 }}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography color={"secondary"}>{t("HELP & PRIVACY")}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <SettingsFilesPrivacyAndHelpComponent snckMessage={snackMsg} />
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <Box style={{ minWidth: "550px", display: "flex", flexDirection: "column", gap: 10 }}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography color={"secondary"}>{"QUOTES"}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <SettingsMottosComponent snackMess={snackMsg}></SettingsMottosComponent>
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                </TabPanel>
            </Box>
        </Box>
    );
}