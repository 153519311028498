export enum EDocumentCustomerType {
    DOCUMENT = 'DOCUMENT'
}

export enum ECustomerReputationLevel {
    VIP = 'VIP',
    STANDARD = 'STANDARD',
    POOR = 'POOR'
}

export enum ECustomerStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    INCOMPLETE = 'INCOMPLETE'
}

export interface IFavoritesCustomer {
    uuid: string,
    idno: string,
    name: string,
    phone: string,
    lastLoadDate: number,
    status: string,
    reputationLevel: string,
    reviewMark: number,
    email: string
}

export interface CustomerStatusRequestDto {
    uuid: string,
    status: string,
    reputationLevel: string,
    activeFromDate: number,
    activeToDate: number
}

export interface ICustomerDetails {
    uuid: string,
    idno: string,
    name: string,
    email: string,
    phone: string,
    lastLoadDate: number,
    status: string,
    reputationLevel: string,
    reviewMark: number,
    metadata: {
        step: number,
        completed: boolean
    },
    readonly: boolean

}

export interface ICustomerStepNew {
    idno: string,
    name: string
}

export interface ICustomerCreateDTO {
    idno: string,
    name: string,
    addressUuid: string,
    email: string,
    phone: string,
    description: string
}

export interface ICustomerUpdateDTO {
    uuid: string,
    idno: string,
    name: string,
    addressUuid: string,
    email: string,
    phone: string,
    description: string
}

export interface ICustomerListDTO {
    id?: string,
    uuid: string,
    idno: string,
    name: string,
    email: string,
    phone: string,
    lastLoadDate: number,
    status: string,
    reputationLevel: string,
    reviewMark: number,
    totalNumberOfLoads: number,
    numberOfPayedLoads: number,
    totalAmount: number,
    payedAmount: number,
    readonly: boolean
}

export interface ISummaryCustomer {
    uuid: string,
    name: string,
    idno: string,
    email: string,
    phone: string,
    address: string,
    lastLoadDate: number,
    status: string,
    reputationLevel: string,
    reviewMark: number,
    totalNumberOfLoads: number,
    numberOfPayedLoads: number,
    totalAmount: number,
    payedAmount: number
}

export interface IReviewModel {
    uuid: string,
    loadId: string,
    loadIdno: string,
    message: string,
    mark: number,
    email: string,
    createdDate: number
}

export interface ICustomerLoadsModel {
    id?: string,
    uuid: string,
    idno: string,
    amount: number,
    status: string,
    reviewMark: number
}

export interface ICustomerDocumentCreateDTO {
    allowDelete?: boolean,
    attachments?: any[],
    type: string,
    expirationDate: number,
    number: string,
    caption: string,
    uuid: string
}

export interface IDocumentCustomer {
    allowDelete: boolean,
    attachments: any[],
    caption: string,
    expirationDate: number,
    number: string,
    type: string,
    uuid: string
}

export interface ICustomerDocumentUpdateDTO {
    uuid: string,
    type: string,
    expirationDate: number,
    number: string,
    caption: string
}

export interface ICustomerIdNameResponseDto {
    uuid: string,
    name: string
}

export interface ICustomerShortResponseDto {
    uuid: string,
    idno: string,
    name: string,
    email: string,
    phone: string,
    status: string,
    reputationLevel: ECustomerReputationLevel,
    lastLoadDate: number,
    reviewMark: number
}