import { useEffect, useState } from "react"
import { ILoadItemRequestDto, ILoadItemResponseDto } from "../../../models/LoadModels";
import LoadService from "../../../services/LoadService";
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography, } from "@mui/material";
import styles from './ItemsComponent.module.scss'
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import EditItemComponent from "../EditItemComponent";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import SecureDialogComponent from "../../CustomComponentModule/SecureDialogComponent";
import { EButtonColor } from "../../../models/EnumGeneral";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { modifyLoadRefresh } from "../../../store/reducers/cnfSlice";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { curency } from "../../../helpers/textHelper";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import FileDownloadDoneOutlinedIcon from '@mui/icons-material/FileDownloadDoneOutlined';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import FileCopyIcon from '@mui/icons-material/FileCopy';

interface IProps {
    loadId: string,
    refreshAfterSave: () => void
    snackMsg: (text: string) => void;
    handleStep: (n: number) => void;
    existItem: (b: boolean) => void
}

const ItemsComponent = (props: IProps) => {
    const { loadId } = props;
    const [state, setState] = useState<ILoadItemResponseDto[]>([])
    const { refresh } = useSelector((state: RootState) => state.cnfSlice.load);
    const { hints } = useSelector((state: RootState) => state.cnfSlice.interfaceState);
    const { measureEngineVolume, measureMass } = useSelector((state: RootState) => state.cnfSlice.globalPreferenceState);

    const [error, setError] = useState(0)
    const [editableMode, setEditableMode] = useState<boolean>(true);
    const [save, setSave] = useState<boolean>(false);

    const [open, setOpen] = useState(false);
    const [item, setItem] = useState<ILoadItemRequestDto | null>();
    const [delet, setDelet] = useState(false)
    const dispatch = useDispatch<AppDispatch>();
    const [n, setN] = useState<number>(-1);
    const [statistics, setStatistics] = useState({
        count: 0,
        volume: 0,
        amount: 0,
        weight: 0
    })

    useEffect(() => {
        (async () => {
            if (delet) {
                const [, response] = await LoadService.DeleteLoadItemById(item?.uuid ? item?.uuid : '');
                if (response) {
                    props.snackMsg(`Load item ${item?.name} (${item?.idno}) was successfully deleted.`);
                    dispatch(modifyLoadRefresh());
                    setOpen(false);
                    props.refreshAfterSave();
                }
            }
        })()
        setDelet(false)
    }, [delet])

    const hendlerDeleteItem = () => {
        setDelet(true);
    }

    useEffect(() => {
        (async () => {
            const response = await LoadService.getLoadItemById(loadId);
            if (response) {
                let b = response[1].data.body as ILoadItemResponseDto[];
                const st: ILoadItemResponseDto[] = b.map((e: ILoadItemResponseDto) => ({ ...e, id: e.uuid }))
                setState(st);
                props.existItem((b.length !== 0))
                let obj = {
                    count: 0,
                    volume: 0,
                    amount: 0,
                    weight: 0
                }
                for (let e of b) {
                    obj.amount += e.amount;
                    obj.weight += e.unitWeight;
                    obj.count++;
                    obj.volume += (e.unitWidth * e.unitHeight * e.unitLength) * e.units
                }
                setStatistics(obj);
            }
        })()

    }, [loadId, refresh])

    const handleClose = () => {
        setOpen(false)
    };

    const handleOk = (name: string, idno: string) => {
        setOpen(false);
        setSave(false);
        dispatch(modifyLoadRefresh());
        if (item?.uuid === '*') {
            props.snackMsg(`Load item ${name} (${idno}) was successfully created.`);
        } else {
            props.snackMsg(`Load item ${name} (${idno}) was successfully updated.`);
        }
    };

    const createNewItem = () => {
        setItem(newItem(loadId));
        setOpen(true)
        setEditableMode(true);
    }

    const handleMarkDone = () => {
        (async () => {
            let obj: any = {
                loadItemId: item?.uuid
            }
            const [, response] = await LoadService.markAsDone(obj);
            if (response) {
                props.snackMsg(`Load item ${item?.name} (${item?.idno}) was successfully marked as done.`);
            }
        })()
    }

    const activate = (e: ILoadItemResponseDto) => {
        setItem({
            uuid: e.uuid,
            loadId: props.loadId,
            name: e.name,
            units: e.units,
            unitWidth: e.unitWidth,
            unitLength: e.unitLength,
            amount: e.amount.toString(),
            unitHeight: e.unitHeight,
            unitWeight: e.unitWeight,
            fragilityLevel: e.fragilityLevel,
            fragilityTypeId: e.fragilityType.uuid,
            palletTypeId: e.palletType.uuid,
            idno: e.idno,
            description: e.description
        });
        setOpen(true)
    }

    useEffect(() => {
        if (state && item) {
            setN(state.findIndex(b => b.uuid === item.uuid));
        }
    }, [state, item])

    const prevItem = () => {
        if (n) {
            activate(state[n - 1])
        }
    }

    const nextItem = () => {
        if (n < state.length - 1) {
            activate(state[n + 1])
        }
    }

    const columns: GridColDef[] = [
        {
            field: "1", headerName: '', sortable: false, filterable: false, disableColumnMenu: true, width: 40,
            renderCell: (cellValues: any) => {
                return (
                    <>
                        <IconButton color={'primary'} size="small"
                            onClick={() => {
                                activate(cellValues.row)
                                setEditableMode(false);
                            }}><AutoAwesomeIcon fontSize="small" /></IconButton>
                    </>
                );
            }
        },
        {
            field: "2", headerName: 'NAME', sortable: false, filterable: false, disableColumnMenu: true, width: 180, headerAlign: "center",
            renderCell: (cellValues: any) => {
                return (
                    <>
                        <Typography variant={'body2'} fontSize={'11px'}>{cellValues.row.name} [{cellValues.row.idno}]</Typography>
                    </>
                );
            }
        },
        {
            field: "3", headerName: 'AMOUNT', sortable: false, filterable: false, disableColumnMenu: true, width: 100, headerAlign: "center",
            renderCell: (cellValues: any) => {
                return (
                    <>
                        <Tooltip
                            title={cellValues.row.amount + ' ' + curency}
                            arrow={true}
                        >
                            <Typography variant={'body2'} fontSize={'11px'}>{cellValues.row.amount + ' ' + curency} </Typography>
                        </Tooltip>
                    </>
                );
            }
        },
        {
            field: "4", headerName: 'STATUS', sortable: false, filterable: false, disableColumnMenu: true, width: 90, headerAlign: "center",
            renderCell: (cellValues: any) => {
                return (
                    <>
                        <Typography variant={'body2'} fontSize={'11px'}>{cellValues.row.status} </Typography>
                    </>
                );
            }
        },
        {
            field: "5", headerName: 'ACTIVE LOAD', sortable: false, filterable: false, disableColumnMenu: true, width: 120, headerAlign: "center",
            renderCell: (cellValues: any) => {
                return (
                    <>
                        <Typography variant={'body2'} fontSize={'11px'}></Typography>
                    </>
                );
            }
        },
        {
            field: "6", headerName: '', sortable: false, filterable: false, disableColumnMenu: true, width: 65, headerAlign: "center",
            renderCell: (cellValues: any) => {
                let obj: any = { ...cellValues.row, uuid: "*", loadId: loadId }
                return (
                    <>
                        <Tooltip
                            title={hints ? 'Duplicate current load item' : ''}
                        >
                            <IconButton color={'primary'} size="small" onClick={() => {
                                activate(obj)
                                setEditableMode(true);
                            }}>
                                <FileCopyIcon fontSize="small" >{cellValues.row.state} </FileCopyIcon>
                            </IconButton>
                        </Tooltip>
                    </>
                );
            }
        },
    ];
    return (
        <>
            <div style={{ display: "flex", gap: 10, width: "100%" }}>
                <div style={{ display: "flex", flexDirection: "column", width: "100%", marginRight: "18px" }}>

                    <div style={{
                        width: "100%",
                        marginTop: "10px",
                        marginBottom: "20px",
                        display: "flex", gap: "5px",
                        justifyContent: "space-between"
                    }}>
                        <div style={{ display: "flex", alignItems: "flex-end" }}>
                            <Button
                                size="small"
                                variant={'contained'}
                                onClick={createNewItem}
                            >
                                <AddIcon sx={{ marginRight: "5px" }} fontSize="small" />ITEM
                            </Button>
                        </div>
                        <div className={styles.statistics}>
                            <Box className={styles['box-stat']}>
                                <span>ITEMS</span>
                                <span>{statistics.count}</span>
                            </Box>
                            <Box className={styles['box-stat']}>
                                <span>VOLUME</span>
                                <span>{Math.round(statistics.volume / 1000000) + ' ' + measureEngineVolume}</span>
                            </Box>
                            <Box className={styles['box-stat']}>
                                <span>WEIGHT</span>
                                <span>{statistics.weight + ' ' + measureMass}</span>
                            </Box >
                            <Box className={styles['box-stat']}>
                                <span>AMOUNT</span>
                                <span>{statistics.amount + ' ' + curency}</span>
                            </Box>
                        </div>
                    </div>

                    <div style={{ height: "350px", width: "630px" }}>
                        <DataGrid
                            rows={state}
                            columns={columns}
                            disableSelectionOnClick
                            hideFooter
                        />
                    </div>
                </div>
            </div>

            <Dialog
                sx={{ '& .MuiDialog-paper': { width: '100%' } }}
                maxWidth="lg"
                keepMounted
                open={open}
            >
                <DialogTitle>{item?.uuid === '*' ? 'ADD ' : (editableMode) ? 'EDIT ' : 'VIEW '}LOAD ITEM </DialogTitle>
                <DialogContent>
                    <EditItemComponent save={save} setError={(n: number) => { setError(n) }} editableMode={editableMode} onSave={handleOk} item={item ? item : null} />
                </DialogContent>
                <DialogActions >
                    <Box sx={{
                        paddingBottom: "20px",
                        paddingRight: "20px",
                        display: "flex",
                        width: "100%",
                        paddingLeft: "20px",
                        paddingRigth: "20px",
                        justifyContent: "space-between"
                    }}>

                        <div style={{ display: "flex", gap: "15px", width: "250px" }}>
                            {!editableMode && !(item?.uuid === "*") && <SecureDialogComponent
                                answerYES={() => hendlerDeleteItem()}
                                text={''}
                                icon={<DeleteForeverIcon fontSize='medium' sx={{ marginRight: "5px", marginLeft: "5px" }} />}
                                color={EButtonColor.error}
                                iconButton={false}
                                tooltip
                                tooltipText="Remove load item"
                                placement
                            />}
                            {!editableMode &&
                                <Tooltip
                                    title={hints ? 'Open load item in editing mode' : ''}
                                    placement="top"
                                >
                                    <Button
                                        color="info"
                                        variant="contained"
                                        onClick={() => setEditableMode(true)}
                                    >
                                        <DriveFileRenameOutlineIcon />
                                    </Button>
                                </Tooltip>
                            }
                        </div>
                        <div style={{ display: "flex", gap: "50px" }}>
                            {item?.uuid !== '*' && !editableMode &&
                                <div style={{ display: "flex", gap: "10px" }} >
                                    <Tooltip
                                        title={hints ? 'Navigate to the previous load item' : ''}
                                        placement="top"
                                    >
                                        <Button
                                            disabled={n === 0}
                                            size="small"
                                            variant={'contained'}
                                            color={'inherit'}
                                            onClick={prevItem}>
                                            <ChevronLeftIcon fontSize="small" />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip
                                        title={hints ? 'Navigate to the next load item' : ''}
                                        placement="top"
                                    >
                                        <Button
                                            disabled={n >= state.length - 1}
                                            size="small"
                                            variant={'contained'}
                                            color={'inherit'}
                                            onClick={nextItem}>
                                            <ChevronRightIcon fontSize="small" />
                                        </Button>
                                    </Tooltip>
                                </div>}
                        </div>
                        <div style={{ width: "250px", display: "flex", justifyContent: "flex-end", gap: "15px" }}>
                            {editableMode &&
                                <Button
                                    variant={'contained'}
                                    disabled={error !== 1}
                                    size={'small'}
                                    startIcon={<SaveAsIcon />}
                                    onClick={() => setSave(true)}
                                >
                                    {item?.uuid === '*' ? 'ADD ' : 'SAVE '}
                                </Button>}
                            <Button autoFocus onClick={handleClose} variant={'contained'} color={'warning'}>
                                Cancel
                            </Button>
                        </div>
                    </Box>
                </DialogActions>
            </Dialog >
        </>
    )
}
export default ItemsComponent

const newItem = (uuid: string): ILoadItemRequestDto => {
    return {
        uuid: '*',
        loadId: uuid,
        name: "",
        units: 0,
        unitWidth: 0,
        unitLength: 0,
        amount: '',
        unitHeight: 0,
        unitWeight: 0,
        fragilityLevel: "",
        fragilityTypeId: "",
        palletTypeId: "",
        idno: "",
        description: ""
    }
}