import { AddressRequestDto, AddressResponseDto } from "../models/AddressModels";
import { API } from "./APIService";

class AddressService {

    public static getAllCountry = async (): Promise<any> => {
        const [error, response] = await API('GET', 'address/location/country');
        return [error, response]
    }

    public static getOrCreateAddress = async (body: AddressRequestDto): Promise<any> => {
        const [error, response] = await API('POST', 'address', JSON.stringify(body));
        let result: AddressResponseDto | undefined;
        if (response) {
            result = response.data.body;
        }
        return [error, result];
    }

    public static getAddressById = async (uuid: string): Promise<any> => {
        const [error, response] = await API('GET', 'address/' + uuid);
        let result: AddressResponseDto | undefined;
        if (response) {
            result = response.data.body;
        }
        return [error, result]
    }
}
export default AddressService;