import { useEffect, useState } from "react";
import { Control, Controller, useForm } from "react-hook-form";
import { EFuelType, ETransmissionType, EVehicleType, IAllVehicleResponseDto, IVehicleDetails } from "../../models/TransportModels";
import { Autocomplete, Button, Divider, InputAdornment, ListItem, ListItemButton, ListItemText, MenuItem, Select, Tab, Tabs, TextField, Typography, createFilterOptions } from "@mui/material";
import { ObjectToValueArray } from "../../helpers/generalHelper";
import TransportService from "../../services/TransportService";
import SCViewList from "../../styledComponents/sharedComponent/SCViewList";
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import ReplyIcon from '@mui/icons-material/Reply';
import EditIcon from '@mui/icons-material/Edit';
import { FixedSizeList, ListChildComponentProps } from "react-window";
import SecureDialogComponent from "../CustomComponentModule/SecureDialogComponent";
import { setLastError } from "../../store/reducers/cnfSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { EButtonColor } from "../../models/EnumGeneral";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useDispatch } from "react-redux";

const defaultValue = (): IVehicleDetails => {
    return {
        brand: {
            name: "",
            type: ""
        },
        model: "",
        type: "",
        year: "",
        height: "",
        width: "",
        length: "",
        weight: "",
        loadingWeight: "",
        grossWeight: "",
        grossCombinationWeight: "",
        fuelType: EFuelType.GASOLINE,
        fuelConsumption: "",
        transmissionType: ETransmissionType.AUTO,
        enginePower: "",
        engineCapacity: "",
        readonly: false
    };
}

interface CarOptionType {
    inputValue?: string;
    name: string;
    uuid?: string;
    type?: string
}

interface IProps {
    isValid: (state: boolean) => void;
    close: () => void;
    snackMess: (s: string) => void;
    getVehicleState: (state: IVehicleDetails) => void;
    getEditableState: (b: boolean) => void;
    type: string;
    redirectToForm: boolean
}

const VehicleCrudComponentB = (props: IProps) => {

    const { measureSize, measureConsumption, measureEnginePower, measureEngineVolume, measureMass } = useSelector((state: RootState) => state.cnfSlice.globalPreferenceState);

    const [cars, setCars] = useState<Array<CarOptionType | null>>([])
    const [type, setType] = useState<EVehicleType>((props.type) ? props.type as EVehicleType : EVehicleType.TRUCK);

    const [stateVehicle, setStateVehicle] = useState<IVehicleDetails | null>(null)
    const [state, setState] = useState<IAllVehicleResponseDto[]>([])

    const [getVehicleList, setGetVehicleList] = useState<boolean>(true);
    const [editableMode, setEditableMode] = useState<boolean>(false);
    const [noClickArea, setNoClickArea] = useState<boolean>(true)
    const dispatch = useDispatch()

    const {
        formState: { errors, isValid, isDirty },
        handleSubmit,
        reset,
        control
    } = useForm<IVehicleDetails>({
        mode: 'onChange',
        defaultValues: defaultValue()
    });

    const onSubmit = async (data: IVehicleDetails) => {
        let isNewVehicle: boolean = (data.uuid !== '' && data.uuid) ? false : true;
        const { readonly, ...rest } = data
        const [error, response] = (isNewVehicle)
            ? await TransportService.createVehicle({ ...rest, type: type })
            : await TransportService.updateVehicle({ ...rest, type: type });
        if (response) {
            if (props.redirectToForm) {
                props.getVehicleState({ ...rest, type: type, uuid: response.data.response.entityId })
            }
            if (isNewVehicle) {
                props.snackMess(`Vehicle ${rest.brand.name} ${rest.model} ${rest.year} was successfully created.`);
            } else {
                props.snackMess(`Vehicle ${rest.brand.name} ${rest.model} ${rest.year} was successfully updated.`);
            }
            props.close();
        }
    }

    useEffect(() => {
        if (props.redirectToForm) {
            setEditableMode(true);
            setNoClickArea(false);
            props.getEditableState(true);
        }
    }, [props.redirectToForm]);

    const handleChange = (event: React.SyntheticEvent, newValue: EVehicleType) => {
        let b: EVehicleType = newValue
        setType(b);
        setGetVehicleList(true);
    };

    const handleClickList = (async (event: IAllVehicleResponseDto) => {
        if (stateVehicle?.uuid !== event.uuid) {
            getVehicle(event.uuid);
            setNoClickArea(true);
            setEditableMode(true);
        }
    })

    const handleAddForm = () => {
        setStateVehicle(null);
        reset(defaultValue, { keepDefaultValues: false })
        setEditableMode(true);
        props.getEditableState(true)
        setNoClickArea(false);
    }

    const handleBackToList = () => {
        setEditableMode(false);
        props.getEditableState(false);
        reset(defaultValue, { keepDefaultValues: false })
        setStateVehicle(null);
        setNoClickArea(true);
    }

    const handleAllowClick = () => {
        setEditableMode(true);
        props.getEditableState(true);
        setNoClickArea(false);
    }

    const getVehicle = (async (uuid: string) => {
        const [error, response] = await TransportService.getVehicleById(uuid);
        let res = response.data.body as IVehicleDetails;
        if (res) {
            setStateVehicle(res);
            reset(res, { keepDefaultValues: false });
        }

        if (error) {
            dispatch(setLastError(error))
        }
    })

    const handleDeleteVehicle = (async () => {
        if (stateVehicle?.uuid) {
            const [error, response] = await TransportService.deleteVehicle(stateVehicle?.uuid)
            if (response) {
                setGetVehicleList(true);
                setEditableMode(false);
                setNoClickArea(true);
                props.snackMess(`Vehicle ${stateVehicle.brand.name} ${stateVehicle.model} ${stateVehicle.year} was successfully deleted.`)
                setStateVehicle(defaultValue);
            }
        }
    })

    useEffect(() => {
        props.isValid((isDirty && isValid) ? true : false)
    }, [isValid, isDirty]);


    useEffect(() => {
        (async () => {
            if (getVehicleList) {
                const [error, response] = await TransportService.getAllBrand(type)
                if (response) {
                    setCars(response.data.body)
                }
            }
        })()
    }, [getVehicleList])

    useEffect(() => {
        (async () => {
            if (type && getVehicleList) {
                if (!props.redirectToForm) {
                    const [error, response] = await TransportService.getAllVehicleByType(type)
                    if (response) {
                        setState(response.data.body)
                    }
                    if (error) {
                        dispatch(setLastError(error))
                    }
                    setEditableMode(false);
                    setNoClickArea(true);
                }
                setStateVehicle(null);
                setGetVehicleList(false);
            }
        })()
    }, [getVehicleList])

    function renderRow(props: ListChildComponentProps) {
        const { index, style } = props;

        return (
            <ListItem key={index} component="div" disablePadding>
                <ListItemButton selected={(stateVehicle?.uuid === state[index].uuid)} onClick={() => { handleClickList(state[index]) }}>
                    <ListItemText primary={state[index].fullInfo} />
                </ListItemButton>
            </ListItem>
        );
    }

    return (
        <>
            <SCViewList column>
                <Tabs
                    value={type}
                    onChange={handleChange}
                    aria-label="wrapped label tabs example"
                >
                    <Tab value={EVehicleType.TRUCK} label={EVehicleType.TRUCK} />
                    <Tab value={EVehicleType.TRAILER} label={EVehicleType.TRAILER} />
                    <Tab value={EVehicleType.BOX_TRUCK} label={EVehicleType.BOX_TRUCK.split("_").join(" ")} />
                </Tabs>
                <div style={{ marginTop: "20px", minHeight: "40px" }}>

                    <SCViewList spaceBetween >
                        <SCViewList gap={10}>
                            <div>
                                {(!editableMode || (editableMode && noClickArea)) &&
                                    <Button variant="contained" size={"small"} sx={{ width: "110px" }} color={'warning'} onClick={handleAddForm}>
                                        <DashboardCustomizeIcon fontSize='small' sx={{ marginRight: "15px" }} /> <Typography variant="subtitle2"> Add </Typography>
                                    </Button>
                                }
                            </div>
                            <div>
                                {(!noClickArea && !props.redirectToForm) &&
                                    <Button variant="contained" size={"small"} sx={{ width: "110px" }} color={'warning'} onClick={handleBackToList}>
                                        <ReplyIcon fontSize='small' sx={{ marginRight: "15px" }} /> <Typography variant="subtitle2"> Back</Typography>
                                    </Button>
                                }
                            </div>
                            <div>

                                {(noClickArea && editableMode) &&
                                    <Button variant="contained" sx={{ width: "110px" }} size={"small"} color={'primary'} onClick={handleAllowClick} >
                                        <EditIcon fontSize='small' sx={{ marginRight: "15px" }} /> <Typography variant="subtitle2"> Edit </Typography>
                                    </Button>
                                }
                            </div>
                        </SCViewList>

                        {stateVehicle && !stateVehicle.readonly && <SecureDialogComponent
                            answerYES={handleDeleteVehicle}
                            text={''}
                            icon={<DeleteForeverIcon fontSize='small' />}
                            color={EButtonColor.error}
                            iconButton={false}
                        />}
                    </SCViewList>
                </div>

                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                    <SCViewList gap={20} center>
                        {noClickArea && <div style={{ display: "flex", flexDirection: "column" }}>
                            <Divider sx={{ marginBottom: "15px", paddingTop: "10px" }}></Divider>
                            <FixedSizeList
                                height={400}
                                width={320}
                                itemSize={46}
                                itemCount={state.length}
                                overscanCount={5}
                            >
                                {renderRow}
                            </FixedSizeList>
                        </div>}
                        {!editableMode && <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "590px", height: "400px", border: "1px dotted silver", marginTop: "10px" }}>
                            <Typography variant='subtitle2'> There is no {type.replace('_', ' ').toLowerCase()} selected.</Typography>
                        </div>}
                        {editableMode && <form id="myForm" key={1} onSubmit={handleSubmit(onSubmit)} style={{ paddingTop: "10px" }}>

                            <SCViewList column width={100}>
                                {!editableMode && <Divider sx={{ marginBottom: "15px", paddingTop: "10px" }}></Divider>}

                                <div style={{ display: "flex", justifyContent: "center", width: "100%", height: "80px" }}>
                                    <MuiAutoComplete type={type} control={control} cars={cars} disabled={noClickArea} />
                                </div>

                                {(type === EVehicleType.TRAILER) &&
                                    <div style={{ display: "flex", justifyContent: "center", height: "70px" }}>
                                        <Controller
                                            render={({ field }) =>
                                                <TextField
                                                    {...field}
                                                    autoComplete='off'
                                                    label="Model"
                                                    size="small"
                                                    sx={{ width: "590px" }}
                                                    inputProps={{ sx: { fontSize: "11px", height: "20px" } }}
                                                    InputLabelProps={{ shrink: true }}
                                                    disabled={noClickArea}
                                                    error={(errors?.model) ? true : false}
                                                />}
                                            name="model"
                                            control={control}
                                            rules={{ required: true, minLength: 1 }}
                                            defaultValue=""
                                        />
                                    </div>
                                }

                                <SCViewList gap={30} wrap={'wrap'} column height={((type !== EVehicleType.TRAILER)) ? "400px" : "200px"}>
                                    {(type !== EVehicleType.TRAILER) && <Controller
                                        render={({ field }) =>
                                            <TextField
                                                {...field}
                                                label="Model"
                                                autoComplete='off'
                                                size="small"
                                                sx={{ width: "280px" }}
                                                inputProps={{ sx: { fontSize: "11px", height: "20px" } }}
                                                InputLabelProps={{ shrink: true }}
                                                disabled={noClickArea}
                                                error={(errors?.model) ? true : false}
                                            />}
                                        name="model"
                                        control={control}
                                        rules={{ required: true, minLength: 1 }}
                                        defaultValue=""
                                    />}

                                    <Controller
                                        render={({ field }) =>
                                            <TextField
                                                {...field}
                                                label="Year"
                                                size="small"
                                                sx={{ width: "280px" }}
                                                autoComplete='off'
                                                disabled={noClickArea}
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ sx: { fontSize: "11px", height: "20px" } }}
                                                error={(errors?.year) ? true : false}
                                            />}

                                        name="year"
                                        control={control}
                                        rules={{
                                            required: true,
                                            pattern: /^[\d /]*$/,
                                            minLength: 2
                                        }}
                                        defaultValue={undefined}
                                    />
                                    <div style={{ display: "flex", gap: "5px", width: "280px" }}>

                                        <Controller
                                            render={({ field }) =>
                                                <TextField {...field}
                                                    label="Height"
                                                    size="small"
                                                    sx={{ width: "90px" }}
                                                    autoComplete='off'
                                                    disabled={noClickArea}
                                                    inputProps={{
                                                        sx: { fontSize: "11px", height: "20px" }
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <div style={{ fontSize: "12px" }}>{measureSize.toLocaleUpperCase()}</div>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    error={(errors?.height) ? true : false}

                                                />}
                                            name="height"
                                            control={control}
                                            rules={{
                                                required: true,
                                                pattern: /^[\d ./]*$/,
                                                minLength: 2
                                            }}
                                            defaultValue={undefined}
                                        />
                                        <Controller
                                            render={({ field }) =>
                                                <TextField
                                                    {...field}
                                                    label="Width"
                                                    size="small"
                                                    sx={{ width: "90px" }}
                                                    autoComplete='off'
                                                    disabled={noClickArea}
                                                    inputProps={{ sx: { fontSize: "11px", height: "20px" } }}
                                                    InputLabelProps={{ shrink: true }}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <div style={{ fontSize: "12px" }}>{measureSize.toLocaleUpperCase()}</div>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    error={(errors?.width) ? true : false}

                                                />}
                                            name="width"
                                            control={control}
                                            rules={{
                                                required: true,
                                                pattern: /^[\d ./]*$/,
                                                minLength: 2
                                            }}
                                            defaultValue={undefined}
                                        />

                                        <Controller
                                            render={({ field }) =>
                                                <TextField {...field}
                                                    label="Length"
                                                    size="small"
                                                    sx={{ width: "90px" }}
                                                    autoComplete='off'
                                                    disabled={noClickArea}
                                                    inputProps={{ sx: { fontSize: "11px", height: "20px" } }}
                                                    InputLabelProps={{ shrink: true }}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <div style={{ fontSize: "12px" }}>{measureSize.toLocaleUpperCase()}</div>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    error={(errors?.length) ? true : false}

                                                />}
                                            name="length"
                                            control={control}
                                            rules={{
                                                required: true,
                                                pattern: /^[\d ./]*$/,
                                                minLength: 2
                                            }}
                                            defaultValue={undefined}
                                        />
                                    </div>

                                    <Controller
                                        render={({ field }) =>
                                            <TextField {...field}
                                                label="Weight" size="small"
                                                sx={{ width: "280px" }}
                                                autoComplete='off'
                                                disabled={noClickArea}
                                                inputProps={{ sx: { fontSize: "11px", height: "20px" } }}
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <div style={{ fontSize: "12px" }}>{measureMass.toLocaleUpperCase()}</div>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                error={(errors?.weight) ? true : false}

                                            />}
                                        name="weight"
                                        control={control}
                                        rules={{
                                            required: true,
                                            pattern: /^[\d ./]*$/,
                                            minLength: 2
                                        }}
                                        defaultValue={undefined}
                                    />

                                    <Controller
                                        render={({ field }) =>
                                            <TextField {...field}
                                                label="Loading Weight"
                                                sx={{ width: "280px" }}
                                                autoComplete='off'
                                                size="small"
                                                disabled={noClickArea}
                                                inputProps={{ sx: { fontSize: "11px", height: "20px" } }}
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <div style={{ fontSize: "12px" }}>{measureMass.toLocaleUpperCase()}</div>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                error={(errors?.loadingWeight) ? true : false}

                                            />}
                                        name="loadingWeight"
                                        control={control}
                                        rules={{
                                            required: true,
                                            pattern: /^[\d ./]*$/,
                                            minLength: 2
                                        }}
                                        defaultValue={undefined}
                                    />

                                    <Controller
                                        render={({ field }) =>
                                            <TextField
                                                {...field}
                                                label="Gross Weight"
                                                autoComplete='off'
                                                sx={{ width: "280px" }}
                                                size="small"
                                                disabled={noClickArea}
                                                inputProps={{ sx: { fontSize: "11px", height: "20px" } }}
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <div style={{ fontSize: "12px" }}>{measureMass.toLocaleUpperCase()}</div>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                error={(errors?.grossWeight) ? true : false}

                                            />}
                                        name="grossWeight"
                                        control={control}
                                        rules={{
                                            required: true,
                                            pattern: /^[\d ./]*$/,
                                            minLength: 2
                                        }}
                                        defaultValue={undefined}
                                    />

                                    <Controller
                                        render={({ field }) =>
                                            <TextField
                                                {...field}
                                                label="Gross Combination Weight"
                                                sx={{ width: "280px" }}
                                                size="small"
                                                autoComplete='off'
                                                disabled={noClickArea}
                                                inputProps={{ sx: { fontSize: "11px", height: "20px" } }}
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <div style={{ fontSize: "12px" }}>{measureMass.toLocaleUpperCase()}</div>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                error={(errors?.grossCombinationWeight) ? true : false}

                                            />}
                                        name="grossCombinationWeight"
                                        control={control}
                                        rules={{
                                            required: true,
                                            pattern: /^[\d ./]*$/,
                                            minLength: 2
                                        }}
                                        defaultValue={undefined}
                                    />

                                    {(type !== EVehicleType.TRAILER) &&
                                        <Controller
                                            render={
                                                ({ field }) =>
                                                    <Select
                                                        {...field}
                                                        style={{ height: "37px", width: "280px" }}
                                                        disabled={noClickArea}
                                                    >

                                                        {ObjectToValueArray(EFuelType).map((option: string, i) =>
                                                            <MenuItem key={i} value={option}>
                                                                {option.split('_').join(' ')}
                                                            </MenuItem>)}
                                                    </Select>
                                            }
                                            control={control}
                                            name="fuelType"
                                            defaultValue={EFuelType.GASOLINE}
                                        />
                                    }

                                    {(type !== EVehicleType.TRAILER) &&
                                        <Controller
                                            render={({ field }) =>
                                                <TextField
                                                    {...field}
                                                    autoComplete='off'
                                                    label="Fuel Consumption"
                                                    size="small"
                                                    sx={{ width: "280px" }}
                                                    disabled={noClickArea}
                                                    inputProps={{ sx: { fontSize: "11px", height: "20px" } }}
                                                    InputLabelProps={{ shrink: true }}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <div style={{ fontSize: "12px" }}>{measureConsumption.toLocaleUpperCase()}</div>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    error={(errors?.fuelConsumption) ? true : false}

                                                />}
                                            name="fuelConsumption"
                                            control={control}
                                            rules={{
                                                required: true,
                                                pattern: /^[\d ./]*$/,
                                                minLength: 2
                                            }}
                                            defaultValue={undefined}
                                        />
                                    }

                                    {(type !== EVehicleType.TRAILER) &&
                                        <Controller
                                            render={
                                                ({ field }) => <Select
                                                    {...field}
                                                    style={{ height: "37px", width: "280px" }}
                                                    disabled={noClickArea}

                                                >
                                                    {ObjectToValueArray(ETransmissionType).map((option: string, i) =>
                                                        <MenuItem key={i} value={option}>
                                                            {option.split('_').join(' ')}
                                                        </MenuItem>)}
                                                </Select>
                                            }
                                            control={control}
                                            name="transmissionType"
                                            defaultValue={ETransmissionType.AUTO}
                                        />
                                    }

                                    {(type !== EVehicleType.TRAILER) &&
                                        <Controller
                                            render={({ field }) =>
                                                <TextField
                                                    {...field}
                                                    label="Engine Power"
                                                    size="small"
                                                    sx={{ width: "280px" }}
                                                    autoComplete='off'
                                                    disabled={noClickArea}
                                                    inputProps={{ sx: { fontSize: "11px", height: "20px" } }}
                                                    InputLabelProps={{ shrink: true }}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <div style={{ fontSize: "12px" }}>{measureEnginePower.toLocaleUpperCase()}</div>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    error={(errors?.enginePower) ? true : false}
                                                />}
                                            name="enginePower"
                                            control={control}
                                            rules={{
                                                required: true,
                                                pattern: /^[\d ./]*$/,
                                                minLength: 2
                                            }}
                                            defaultValue={undefined}
                                        />
                                    }

                                    {(type !== EVehicleType.TRAILER) &&
                                        <Controller
                                            render={({ field }) =>
                                                <TextField
                                                    {...field}
                                                    label="Engine Capacity"
                                                    size="small"
                                                    sx={{ width: "280px" }}
                                                    disabled={noClickArea}
                                                    inputProps={{ sx: { fontSize: "11px", height: "20px" } }}
                                                    InputLabelProps={{ shrink: true }}
                                                    autoComplete='off'
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <div style={{ fontSize: "12px" }}>{measureEngineVolume.toLocaleUpperCase()}</div>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    error={(errors?.engineCapacity) ? true : false}
                                                />}
                                            name="engineCapacity"
                                            control={control}
                                            rules={{
                                                required: true,
                                                pattern: /^[\d ./]*$/,
                                                minLength: 2
                                            }}
                                            defaultValue={undefined}
                                        />
                                    }

                                </SCViewList>
                            </SCViewList>
                        </form>}
                    </SCViewList>
                </div >
            </SCViewList >
        </>
    )
}

export default VehicleCrudComponentB;

interface IPropsAutocomplete {
    control: Control<IVehicleDetails>,
    cars: Array<CarOptionType | null>,
    disabled: boolean,
    type: EVehicleType
}

const MuiAutoComplete = (props: IPropsAutocomplete) => {
    const { control, cars, disabled, type } = props
    const filter = createFilterOptions<CarOptionType | null>();

    return (
        <>
            <Controller
                render={({ field }) => (
                    <Autocomplete
                        {...field}
                        onChange={(event, data) => {
                            if (typeof data === 'string') {
                                field.onChange(data)
                            } else if (data && data.inputValue) {
                                let obj = {
                                    name: data.inputValue,
                                    uuid: '',
                                    type: type
                                }
                                field.onChange(obj)
                            } else {
                                field.onChange(data)
                            }
                        }}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;
                            const isExisting = options.some((option) => inputValue === option?.name);
                            if (inputValue !== '' && !isExisting) {
                                filtered.push({
                                    inputValue,
                                    name: `Add "${inputValue}"`
                                });
                            }
                            return filtered;
                        }}
                        value={field.value ? field.value.name : ''}
                        disabled={disabled}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        options={cars}
                        getOptionLabel={(option) => {
                            if (typeof option === 'string') {
                                return option;
                            }
                            if (option) {
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                return option.name;
                            } else return ""

                        }}
                        renderOption={(props, option) => <li {...props}>{option?.name}</li>}
                        freeSolo
                        renderInput={(params) =>
                        (
                            <TextField
                                {...params}
                                sx={{ width: "590px" }}
                                label="Brand"
                            />
                        )}
                    />
                )}
                name="brand"
                control={control}
            />
        </>
    )
}