import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    TextField,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store/store';
import { useTranslation } from 'react-i18next';
import { IHistory } from '../models/NotificationModels';
import AutocompletComponent from '../components/CustomComponentModule/AutocompletComponent';
import { dateToYMDHM, toTimestamp } from '../helpers/dateHelper';
import { EAuditAction, IAuditFilter, LabelType, valueState } from '../models/AuditModels';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import { modifyAuditFilter, modifyAuditRefresh } from '../store/reducers/auditSlice';
import AuditGridComponent from '../components/AuditModule/AuditGridComponent';
import StafService from '../services/StafService';
import AuditService from '../services/AuditService';
import { compareValue } from '../helpers/generalHelper';
import { addFavoritesAudits, getFavoritesAudits } from '../store/reducers/favoritesSlice';
import CloseIcon from '@mui/icons-material/Close';

function extractValueWithLabelType(vec: LabelType[]): string[] {
    let a: string[] = [];
    for (let e of vec) {
        a.push(e.key);
    }
    return a;
}

export default function AuditPage() {

    const { auth } = useSelector((state: RootState) => state.cnfSlice);

    const [types, setTypes] = useState<LabelType[]>([]);
    const [users, setUsers] = useState<LabelType[]>([]);
    const [modules, setModules] = useState<LabelType[]>([]);
    const [vs, setVs] = useState<LabelType[][]>([[], [], []]);

    const [reset1, setReset1] = useState<number>(0);
    const [reset2, setReset2] = useState<number>(0);
    const [reset3, setReset3] = useState<number>(0);
    const [filterC, setFilterC] = useState<IAuditFilter>({
        times: {
            t1: Date.now() - 24 * 60 * 60 * 1000, v1: dateToYMDHM(Date.now() - 24 * 60 * 60 * 1000),
            t2: Date.now(), v2: dateToYMDHM(Date.now())
        },
        v1: [],
        v2: [],
        v3: []
    });

    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const [error, setError] = useState(0);
    const { refresh } = useSelector((state: RootState) => state.auditSlice.grid);
    const { filter, valueFilter, query } = useSelector((state: RootState) => state.auditSlice);
    const [audits, setAudits] = useState<IHistory[]>([]);
    const [openDialog, setopenDialog] = useState<boolean>(false);
    const [queryName, setQueryName] = useState<string>('');
    const [init, setInit] = useState(false);

    useEffect(() => {
        if (query !== 0) {
            setFilterC(filter);
            setVs(valueFilter);
        }
    }, [query])

    useEffect(() => {
        let aud: IHistory[] = []
        if (auth) {
            aud = AuditService.getAllHistoryAudit(auth.username);
            setAudits(aud);
        }
    }, [refresh])

    useEffect(() => {
        initUserAutocomplete();
        initModuleAutocomplete();
        initActionsAutocomplete();
        dispatch(getFavoritesAudits());
    }, [])

    useEffect(() => {
        if (init) {
            onResetButtonClickHandler();
        }
    }, [init])

    useEffect(() => {
        setFilterC(filter);
    }, [filter]);

    useEffect(() => {
        if ((filterC.times.v1 !== '' && filterC.times.v2 !== '') && (filterC.times.t1 > filterC.times.t2)) { setError(1) } else { setError(0) }
        if (init) {
            if (!compareValue(filter, filterC)) {
                dispatch(modifyAuditFilter(filterC))
                dispatch(modifyAuditRefresh());
            }
        }
    }, [filterC])

    const initUserAutocomplete = () => {
        (async () => {
            const [, response] = await StafService.getStafInIdValueFormat();
            if (response) {
                let result = [];
                let users = response.data.body;
                for (let user of users) {
                    result.push({ key: user.uuid, name: user.name });
                }
                setUsers(result);
            }
        })();
    };

    const initModuleAutocomplete = () => {
        (async () => {
            const [, response] = await AuditService.auditModule();
            if (response) {
                let result = [];
                let modules = response.data.body;
                for (let module of modules) {
                    result.push({ key: module, name: module, description: module });
                }
                setModules(result);
                setInit(true);
            }
        })();
    };

    const initActionsAutocomplete = () => {
        let result = [];
        for (const [propertyKey, propertyValue] of Object.entries(EAuditAction)) {
            result.push({ key: propertyValue, name: propertyKey, description: propertyValue });
        }
        setTypes(result);
    };

    const onChangeHandlerT1 = (evnt: any) => {
        setFilterC((v) => ({ ...v, times: { ...v.times, v1: evnt.target.value, t1: toTimestamp(evnt.target.value) } }));
    }
    const onChangeHandlerT2 = (evnt: any) => {
        setFilterC((v) => ({ ...v, times: { ...v.times, v2: evnt.target.value, t2: toTimestamp(evnt.target.value) } }));
    }

    const onResetButtonClickHandler = () => {
        setReset1(n => n + 1);
        setReset2(n => n + 1);
        setReset3(n => n + 1);
        setFilterC({
            times: {
                t1: Date.now() - 24 * 60 * 60 * 1000, v1: dateToYMDHM(Date.now() - 24 * 60 * 60 * 1000),
                t2: Date.now(), v2: dateToYMDHM(Date.now()),
            },
            v1: [],
            v2: [],
            v3: []
        })
        setVs([[], [], []])
        setError(0);
    }

    const onCloseDialogHandler = () => {
        setopenDialog(false)
    }

    const onSaveFilterButtonClickHendler = () => {
        let a: IHistory = audits.filter(e => e.text === JSON.stringify(filterC))[0];
        if (a) { setQueryName(a.link) } else { setQueryName('') }
        setopenDialog(true);
    }

    const hendlerSaveQuery = () => {
        if (auth) {
            let aux_Filter: valueState = {
                filter: filterC,
                valueFilter: vs
            }

            dispatch(addFavoritesAudits({ name: queryName, value: JSON.stringify(aux_Filter) }))
            onCloseDialogHandler()
        }
    }


    const fixValueV1 = (v: LabelType[]) => {
        let a = [...vs];
        a[0] = v;
        setVs(a);
        setFilterC((a) => ({ ...a, v1: extractValueWithLabelType(v) }));

    }

    const fixValueV2 = (v: LabelType[]) => {
        let a = [...vs];
        a[1] = v;
        setVs(a);
        setFilterC((a) => ({ ...a, v2: extractValueWithLabelType(v) }))
    }

    const fixValueV3 = (v: LabelType[]) => {
        setFilterC((a) => ({ ...a, v3: extractValueWithLabelType(v) }))
        if (v.length > 0) {
            let a = [...vs];
            a[2] = v;
            setVs(a);
        };
    }

    return (
        <>
            <div style={{ width: "350px", height: "calc(100vh - 140px)", display: "flex", alignContent: "stretch", gap: "20px" }} >
                <div style={{ maxWidth: "320px", display: "flex", flexDirection: "column", gap: "15px", padding: "10px", }}>

                    <div style={{ display: "flex", flexDirection: "row", gap: "15px", marginTop: "5px", justifyContent: 'space-between', marginBottom: "5px" }}>
                        <TextField
                            error={filterC.times.v1 === '' || error === 1}
                            size="small"
                            label={t("START")}
                            type="datetime-local"
                            value={filterC.times.v1 || ''}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={onChangeHandlerT1}
                            inputProps={{ sx: { fontSize: "11px" } }}
                            sx={{ width: "145px" }}
                        />
                        <TextField
                            error={filterC.times.v2 === '' || error === 1}
                            size="small"
                            label={t("END")}
                            type="datetime-local"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={filterC.times.v2 || ''}
                            onChange={onChangeHandlerT2}
                            inputProps={{ sx: { fontSize: "11px" } }}
                            sx={{ width: "145px" }}

                        />
                    </div>

                    {error === 1 && <Typography sx={{ fontSize: "10px", marginTop: "-8px" }} color={'error'}>
                        {'* Start date cannot be greater than or equal to end date.'}
                    </Typography>}
                    <div>
                        <Divider></Divider>
                    </div>
                    <AutocompletComponent
                        vs={0}
                        title="USER "
                        info={users}
                        reset={reset1}
                        fixValue={fixValueV1}
                    />
                    <div>
                        <Divider></Divider>
                    </div>
                    <AutocompletComponent
                        vs={1}
                        title="ACTION"
                        info={types}
                        reset={reset2}
                        fixValue={fixValueV2}
                    />
                    <div>
                        <Divider></Divider>
                    </div>
                    <AutocompletComponent
                        vs={2}
                        title="MODULE"
                        info={modules}
                        reset={reset3}
                        fixValue={fixValueV3}
                    />
                    <div>
                        <Divider></Divider>
                    </div>
                    {<div style={{ display: "flex", gap: "10px", marginTop: "5px", justifyContent: 'space-between' }}>
                        <Button

                            sx={{ width: "142px" }}
                            size='small'
                            variant={'contained'}
                            color={'warning'}
                            onClick={onResetButtonClickHandler}>
                            <RotateLeftIcon sx={{ marginRight: "10px" }} fontSize={'small'} />
                            RESET
                        </Button>
                        {error !== 1 && (filterC.times.v1 !== '') && (filterC.times.v2 !== '') &&
                            <Button
                                disabled={JSON.stringify(vs) === '[[],[],[]]'}
                                sx={{ width: "142px", }}
                                size={'small'}
                                variant={'contained'}
                                onClick={onSaveFilterButtonClickHendler}
                            >
                                <SaveAsIcon sx={{ marginRight: "10px" }} fontSize={'small'} />
                                SAVE
                            </Button>}

                    </div>}
                </div>

                <div>
                    <Dialog
                        open={openDialog}
                        onClose={onCloseDialogHandler}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"SEARCH CRITERIA"}
                        </DialogTitle>
                        <DialogContent>
                            <div style={{ width: "350px", height: "100px" }}>

                                <TextField
                                    autoFocus
                                    label="Name"
                                    value={queryName || ''}
                                    onChange={(evt) => { setQueryName(n => evt.target.value) }}
                                    sx={{ width: "350px", marginTop: "20px" }}

                                />
                            </div>

                        </DialogContent>
                        <DialogActions sx={{ paddingRight: "18px" }}>


                            <div style={{ display: "flex", gap: "10px", paddingBottom: "15px" }}>

                                <Button
                                    disabled={queryName.length < 2}
                                    variant={'contained'}
                                    size={'small'}
                                    onClick={hendlerSaveQuery} startIcon={<SaveAsIcon />}>
                                    SAVE
                                </Button>
                                <Button
                                    startIcon={<CloseIcon />}
                                    color={"warning"}
                                    variant={'contained'}
                                    size={'small'}
                                    onClick={onCloseDialogHandler}>Close</Button>
                            </div>

                        </DialogActions>
                    </Dialog>
                </div>
            </div>
            <div style={{ width: "100%", marginLeft: "20px", marginTop: "10px" }}>
                <AuditGridComponent />
            </div>
        </>
    );
}