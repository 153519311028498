import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconButton, Tooltip, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import StarsIcon from '@mui/icons-material/Stars';
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import { modifyAutoPage, modifyAutoRefresh, modifyAutoSizePage, modifyLeftFlip, modifyRightFlip } from "../../../store/reducers/cnfSlice";
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';
import { IResponseAuto } from "../../../models/TransportModels";
import TransportService from "../../../services/TransportService";
import { addFavoritesAutos } from "../../../store/reducers/favoritesSlice";
import { dateToYMD, getDateAndTimeFormat } from "../../../helpers/dateHelper";
import { AnyPhoto } from "../../StafModule/GridStafComponent";
import AvatarComponent from "../../CustomComponentModule/AvatarComponent";
import { EStatusStafServer } from "../../../models/IUser";
import GenericCRUDService from "../../../services/GenericCRUDService";
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';

export default function GridTransportComponent() {

  const { t } = useTranslation();
  const { id } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { refresh, page, size, part, role } = useSelector((state: RootState) => state.cnfSlice.auto);
  const { hints, dateFormat, timeFormat } = useSelector((state: RootState) => state.cnfSlice.interfaceState);
  const [checkState, setCheckState] = useState(true);
  const [selectionModel, setSelectionModel] = useState<Array<string>>([]);
  const [rowAutos, setRowAutos] = useState<IResponseAuto[]>([]);

  const [pageSizeC, setPageSizeC] = useState(size);
  const [pageC, setPageC] = useState(page);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTtalRows] = useState(0);

  const handlerCheck = () => {
    setSelectionModel([]);
    setCheckState((v) => { return !v });
  }

  const handleClick = (event: any, cellValues: any) => {
    navigate('/transport/' + cellValues.row.id);
    dispatch(modifyRightFlip(true));
  }

  const hendleShowLeft = () => {
    dispatch(modifyLeftFlip());
  }

  const handlerPageSize = (newPageSize: number) => {
    dispatch(modifyAutoSizePage(newPageSize));
    setPageSizeC(newPageSize);
  }

  const handlerPage = (newPage: number) => {
    dispatch(modifyAutoPage(newPage));
    setPageC(newPage);
  }

  useEffect(() => {
    setLoading(true);
    (async () => {
      let criteria: any[] = [];

      if (role !== 'all') {
        criteria.push({
          property: "vehicle.type",
          value: role,
          expression: "EQUALS",
          collection: "true"
        })
      }

      criteria.push({
        property: "status",
        value: part,
        expression: "EQUALS"
      })

      let body = {
        criteria: criteria,
        paging: {
          page: page,
          size: size
        }
      }

      const [error, response] = await TransportService.getAutoByFilter(body);
      if (response) {
        let rs = response.data.body;
        setTtalRows(response.data.total);
        const st: IResponseAuto[] = rs.map((e: IResponseAuto) => ({ ...e, id: e.uuid }))
        setRowAutos(st);
      }
      if (error) { }
      setLoading(false);
    })()
  }, [role, part, page, size, refresh])

  useEffect(() => {
    if (id) {
      if (!selectionModel.includes(id.toString())) {
        setSelectionModel(list => [id, ...list]);
      }
    }
  }, [id])

  const columns: GridColDef[] = [
    {
      field: "2", headerName: '', width: 50, sortable: false, filterable: false, disableColumnMenu: true,
      renderCell: (cellValues: any) => {
        return (
          <>
            <div style={{ display: "flex", textAlign: "center", justifyContent: "center", padding: 0 }}>
              <IconButton
                size={'small'}
                onClick={(event: any) => {
                  handleClick(event, cellValues);
                }}
              >
                <AutoAwesomeIcon color={"disabled"} />
              </IconButton>
            </div>
          </>
        );
      }
    },
    {
      field: "1", headerName: '', sortable: false, filterable: false, disableColumnMenu: true, width: 45,
      renderCell: (cellValues: any) => {
        return (
          <>
            {
              ((cellValues.row.mainImage) && (cellValues.row.mainImage !== 'N/A'))
                ? <AvatarComponent link={cellValues.row.mainImage} w={30} h={30} />
                : <AnyPhoto />
            }
          </>
        );
      }
    },
    {
      field: "3", headerName: t('name').toLocaleUpperCase(), sortable: true, width: 240, headerAlign: "center",
      renderCell: (cellValues: any) => {
        let name = `${cellValues.row.brand} ${cellValues.row.model} ${cellValues.row.year}`
        return (
          <>
            <div>
              <Tooltip
                title={(hints) ? name : ''}
                placement={"bottom"}
              >
                <Typography variant={'body2'} fontSize={'13px'}>
                  {name} </Typography>
              </Tooltip>
            </div>
          </>
        );
      }
    },
    {
      field: "4", headerName: t('type').toLocaleUpperCase(), sortable: true, width: 100, headerAlign: "center",
      renderCell: (cellValues: any) => {
        return (
          <>
            <div>
              <Typography variant={'body2'} fontSize={'13px'}> {cellValues.row.type.split('_').join(' ')} </Typography>
            </div>
          </>
        );
      }
    },
    {
      field: "5", headerName: t('number').toLocaleUpperCase(), sortable: true, width: 160, headerAlign: "center",
      renderCell: (cellValues: any) => {
        return (
          <>
            <div>
              <Typography variant={'body2'} fontSize={'13px'}>
                {cellValues.row.transportNumber}
              </Typography>
            </div>
          </>
        );
      }
    },

    {
      field: "6", headerName: t('license plate').toLocaleUpperCase(), sortable: true, width: 160, headerAlign: "center",
      renderCell: (cellValues: any) => {
        return (
          <>
            <div>
              <Typography variant={'body2'} fontSize={'13px'}>
                {cellValues.row.licensePlate}
              </Typography>
            </div>
          </>
        );
      }
    },
    {
      field: "7", headerName: t('VIN').toLocaleUpperCase(), sortable: true, width: 180, headerAlign: "center",
      renderCell: (cellValues: any) => {
        return (
          <>
            <div>
              <Tooltip
                title={(hints) ? cellValues.row.vin : ''}
                placement={"bottom"}
              >
                <Typography variant={'body2'} fontSize={'13px'}>
                  {cellValues.row.vin}
                </Typography>
              </Tooltip>
            </div>
          </>
        );
      }
    },
    {
      field: "10", headerName: '', sortable: true, width: 40, headerAlign: "center",
      renderCell: (cellValues: any) => {
        return (
          <>
            <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
              <div style={{ width: "20px", height: "20px", background: cellValues.row.color }}>
              </div>
            </div>
          </>
        );
      }
    },
    {
      field: "9", headerName: t('available').toLocaleUpperCase(), sortable: true, width: 120, headerAlign: "center",
      renderCell: (cellValues: any) => {
        return (
          <>
            <div>
              <Typography variant={'body2'} fontSize={'13px'}>
                {cellValues.row.available ? 'YES' : 'NO'}
              </Typography>
            </div>
          </>
        );
      }
    },
    {
      field: "8", headerName: t('status').toUpperCase(), sortable: true, width: 240, headerAlign: "center",
      renderCell: (cellValues: any) => {
        let status: string = (cellValues.row.status === EStatusStafServer.TEMPORARY) ? `${t(cellValues.row.status)} [${dateToYMD(cellValues.row.activeFromDate)} - ${dateToYMD(cellValues.row.activeToDate)}]` : t(cellValues.row.status)
        return (
          <>
            <div>
              <Tooltip
                title={(hints) ? status : ''}
                placement={"bottom"}
              >
                <Typography variant={'body2'} fontSize={'11px'}>
                  {status}
                </Typography>
              </Tooltip>
            </div>
          </>
        );
      }
    },
    {
      field: "created", headerName: 'Created'.toUpperCase(), sortable: true, width: 180, headerAlign: "center",
      renderCell: (cellValues: any) => {
        const date = getDateAndTimeFormat(cellValues.row.createdDate, dateFormat, timeFormat),
          author = cellValues.row.createdBy,
          tooltipValue = author + ' ' + date;
        return (
          <>
            <Tooltip title={tooltipValue} placement={"bottom"}>
              <Typography variant={'body2'} fontSize={'11px'}>
                {author} <br /> {date}
              </Typography>
            </Tooltip>
          </>
        );
      }
    },
    {
      field: "updated", headerName: 'Updated'.toUpperCase(), sortable: true, width: 180, headerAlign: "center",
      renderCell: (cellValues: any) => {
        const date = getDateAndTimeFormat(cellValues.row.updatedDate, dateFormat, timeFormat),
          author = cellValues.row.updatedBy,
          tooltipValue = author + ' ' + date;
        return (
          <>
            {cellValues.row.updatedDate &&
              <Tooltip title={tooltipValue} placement={"bottom"}>
                <Typography variant={'body2'} fontSize={'11px'}>
                  {author} <br /> {date}
                </Typography>
              </Tooltip>
            }
          </>
        );
      }
    }
  ];

  const handlerRefresh = () => {
    dispatch(modifyAutoRefresh());
  }

  const hendlerFavoritesList = () => {
    let list: string[] = [];
    for (let elm of rowAutos) {
      if (selectionModel.includes(elm.uuid)) {
        list.push(elm.uuid);
      }
    }
    dispatch(addFavoritesAutos(list));
  }

  const hendlerExcel = () => {

    let criteria: any[] = [];

    if (role !== 'all') {
      criteria.push({
        property: "vehicle.type",
        value: role,
        expression: "EQUALS",
        collection: "true"
      })
    }

    criteria.push({
      property: "status",
      value: part,
      expression: "EQUALS"
    })

    let body = {
      criteria: criteria,
      paging: {
        page: page,
        size: size
      }
    }

    GenericCRUDService.downloadReport('transport/report', body)
  }

  const styled = {
    container: { display: "flex", gap: "20px" },
    item: { width: "40px" },
    box: { border: "1px solid silver", textAlign: "center", borderRadius: "3px" },
    icon: { fontSize: "28px", cursor: "pointer" },
    button: { border: "1px solid silver" }
  }

  return (
    <>
      <div style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingBottom: "2px",
        height: "46px"
      }}>
        <div>

          <div style={{ width: "400px", paddingLeft: "7px", display: "flex", gap: "10px" }}>
            <Tooltip
              title={(hints) ? t("Show/Hide the selection column") : ''}
              placement={"top"}
              arrow={true}
            >
              <IconButton onClick={handlerCheck} size='small'>
                <PlaylistAddCheckCircleIcon style={styled.icon} />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={(hints) ? t("Refresh") : ''}
              placement={"top"}
              arrow={true}
            >
              <IconButton onClick={handlerRefresh} size='small'>
                <ReplayCircleFilledIcon style={styled.icon} />
              </IconButton>
            </Tooltip>
            {!(rowAutos.length < 1) && <Tooltip
              title={(hints) ? t("Download XLS report for transports") : ''}
              placement={"top"}
              arrow={true}
              onClick={hendlerExcel}
              key={"key"}
            >
              <IconButton>
                <DownloadIcon style={styled.icon} />
              </IconButton>
            </Tooltip>}
            {(selectionModel.length > 0) &&
              <div>
                <Tooltip
                  title={(hints) ? t("Add selected transport(s) to favorites") : ''}
                  placement={"top"}
                  arrow={true}
                >
                  <IconButton
                    onClick={hendlerFavoritesList}
                  >
                    <StarsIcon style={styled.icon} color={"primary"} />
                  </IconButton>
                </Tooltip>
              </div>
            }
          </div>
        </div>
        <div>
          {(id) &&
            <Tooltip
              title={(hints) ? t("staf") : ''}
              placement={"top"}
              arrow={true}
            >
              <IconButton onClick={hendleShowLeft} >
                <MultipleStopIcon style={styled.icon} color={"primary"} />
              </IconButton>
            </Tooltip>
          }
        </div>
      </div>

      <div style={{ height: "92%" }}>
        <DataGrid
          density={'compact'}
          paginationMode="server"
          loading={loading}
          rows={rowAutos}
          columns={columns}

          rowCount={totalRows}
          page={pageC}
          pageSize={pageSizeC}

          rowsPerPageOptions={[5, 10, 15, 20, 25]}
          onPageSizeChange={handlerPageSize}
          onPageChange={handlerPage}

          checkboxSelection={checkState}
          onSelectionModelChange={(newSelectionModel: any) => {
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
        />
      </div>
    </>
  );
}