import { Divider, Typography, useTheme } from "@mui/material";
import { IResponseLoadAutocomplete } from "../../models/LoadModels";
import './../../SearchBar.scss';

interface IProps {
  item: IResponseLoadAutocomplete;  
  hendlerActiveElement:(item:any)=>void;
} 

export default function ItemLoadInSearchListComponent(props:IProps) {
  const {hendlerActiveElement,item} = props;
  const load: IResponseLoadAutocomplete = props.item;
  const theme = useTheme();
 
  return (
    <>
      <div
        className="dataItem"
        style={{ marginTop: "4px", marginBottom: "4px", cursor:"pointer", display:"flex", alignItems:"center", justifyContent:"start", gap:"15px", paddingLeft:"10px" }}
        onClick={()=>hendlerActiveElement(item)}
      >       
       
        <Typography
          color={theme.palette.primary.main}
          variant={"caption"}>{load.idno} ({load.customer.name}) </Typography>        
      </div>
      <Divider></Divider>      
    </>
  );
}