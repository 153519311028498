import { Box } from "@mui/material";
import LogoViewComponent from "../../../CustomComponentModule/LogoViewComponent";
import styles from "./ReportHeader.module.scss"
const ReportHeader = () => {
  return (
    <>
      <Box className={styles.logoStyle} >
        <LogoViewComponent
          width={100}
          heigth={100}
        />
      </Box>
    </>
  )
}
export default ReportHeader;