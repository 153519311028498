import React, { useState, useEffect } from 'react'

import {
  Dialog, DialogActions, DialogContent, DialogTitle,
  Box, Container, Stack,
  Button, Paper, CircularProgress,
} from "@mui/material";

import { styled } from '@mui/material/styles';

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import QuizIcon from '@mui/icons-material/Quiz';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import SwithDarktoLigthComponent from "./SwithDarktoLigthComponent";

import MottoTextComponent from "./MottoTextComponent";
import SelectLangComponent from "./SelectLangComponent";
import GoogleAuthButtonComponent from "./GoogleAuthButtonComponent";

import { useTranslation } from "react-i18next";
import LogoViewComponent from '../CustomComponentModule/LogoViewComponent';
import GeneralService from '../../services/GeneralService';
import { useNavigate } from 'react-router';

const styles_container = {
  display: "flex",
  margin: "auto",
  justifyContent: "center",
  alignItems: "center",
  marginTop: 10
};

const styles_box = {
  display: "flex",
  justifyContent: "space-between"
}

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  color: theme.palette.text.secondary,
  userSelect: "none",
  width: 700
}));

const BootstrapDialogTitle = (props: any) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function AuthComponent() {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const { t } = useTranslation();

  const [base64File, setBase64File] = useState<string>('')
  const [download, setDownload] = useState<boolean>(false);
  const [view, setView] = useState<boolean>(false);
  const [type, setType] = useState<string>('');
  const [existFileHelp, setExistFileHelp] = useState<boolean>(false)
  const [existFilePrivacy, setExistFilePrivacy] = useState<boolean>(false)
  const navigate = useNavigate();

  useEffect(() => {
    if (view) {
      (async () => {
        const [error, response] = await GeneralService.getBase64OfPublicAttachment(type.toUpperCase());
        if (response) {
          setBase64File(response.data);
          setDownload(false);
          setOpen(true);
        }
        if (error) { }
      })()
      setView(false);
    }
  }, [view])

  useEffect(() => {   
    (async () => {
      const [error, response] = await GeneralService.getLinkOfPublicAttachment('HELP');      
      if (response.data.body) { setExistFileHelp(response.data.body.value !== ''); }
      if (error) { }
    })()
    navigate('login');
  }, [])

  useEffect(() => {
    (async () => {
      const [error, response] = await GeneralService.getLinkOfPublicAttachment('PRIVACY');
      if (response.data.body) { setExistFilePrivacy(response.data.body.value !== ''); }
      if (error) { }
    })()
  }, [])

  const hendlerView = (s: string) => {
    if (base64File.length > 0 && type === s) {
      setOpen(true);
    }
    else {
      setType(s);
      setView(true);
      setDownload(true);
    }
  }

  return (
    <>
      <div>
        <Container sx={styles_container}>
          <Stack spacing={2}>
            <Item>
              <MottoTextComponent />
            </Item>
            <Item>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "50px" }}>
                <LogoViewComponent
                  width={300}
                  heigth={300}
                />
              </Box>
              <Box sx={{ textAlign: "center" }}>
                <GoogleAuthButtonComponent />
              </Box>
              <br /><br /><br />
            </Item>
            <Item>
              <Box sx={styles_box}>
                <Box sx={{ width: 200, display: "flex", justifyContent: "space-between" }}>
                  <SelectLangComponent />
                  <SwithDarktoLigthComponent />
                </Box>
                {download ? <CircularProgress size={40} /> :
                  <Box sx={{ width: 300, display: "flex", justifyContent: "flex-end" }}  >
                    {existFileHelp && <Button color="info" startIcon={<QuizIcon />}
                      onClick={() => hendlerView('HELP')} size="large">
                      {t("help")}
                    </Button>}

                    {existFilePrivacy && <Button color="info" startIcon={<PictureAsPdfIcon />}
                      onClick={() => hendlerView('PRIVACY')}
                      size="large">
                      {t("privacy")}
                    </Button>}
                  </Box>}
              </Box>
            </Item>
          </Stack>
        </Container>

        <Dialog
          fullWidth={true}
          maxWidth={'lg'}
          open={open}
          onClose={handleClose}
        >
          <DialogTitle>

          </DialogTitle>
          <DialogContent>
            {base64File !== '' &&
              <object type="application/pdf" width={"1150px"} height={"700px"}
                data={"data:application/pdf;base64," + base64File}></object>
            }
          </DialogContent>
          <DialogActions
            style={{
              marginBottom: "20px",
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: "20px",
              paddingRight: "20px"
            }}>

            <div>

            </div>
            <Button onClick={handleClose}>Close</Button>

          </DialogActions>
        </Dialog>

      </div>
    </>
  )
}

export default AuthComponent;