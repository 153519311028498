import AuditFavoritesComponent from "./AuditFavoritesComponent";
import StafFavoritesComponent from "./StafFavoritesComponent";
import CustomerFavoritesComponent from "./CustomerFavoritesComponent";
import LoadFavoritesComponent from "./LoadFavoritesComponent";
import WarehouseFavoritesComponent from "./WarehouseFavoritesComponent";
import TransportFavoritesComponent from "./TransportFavoritesComponent";

interface IProps {
    type: string;
}

const FavoritesComponent = (props: IProps) => {
    const { type } = props;

    return (
        <>
            {type === 'user' && <StafFavoritesComponent />}
            {type === 'audit' && <AuditFavoritesComponent />}
            {type === 'transport' && <TransportFavoritesComponent />}
            {type === 'customer' && <CustomerFavoritesComponent />}
            {type === 'load' && <LoadFavoritesComponent />}
            {type === 'warehouse' && <WarehouseFavoritesComponent />}
        </>
    )
}

export default FavoritesComponent;