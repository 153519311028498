
import { Box } from "@mui/system";
import { GoogleMap, Polyline, Marker, InfoWindow } from "@react-google-maps/api"
import React, { useState, useEffect } from "react";
import { defaultTheme } from './Themes'
import { useDispatch } from "react-redux";
import { IMapPoint, IMarkerInfo } from "../../models/MapModels";

const containerStyle = {
    width: '100%',
    height: '100%'
};



interface IProps {
    points?: Array<IMarkerInfo>
}

export const MapBoxComponent = (props: IProps) => {

    const  [center_company, setCenter_company] = useState({
        lat: 45.07628287127715,
        lng: -93.24481908541335
    });
    
    const defaultOptions = {
        zoom: 14
    }

    useEffect(()=>{
       if (props.points){
           if (props.points[0]){
               setCenter_company(props.points[0]);
           }
       } 
    },[props.points])

    // const { height, width } = useWindowDimensions();
    const dispatch = useDispatch();
    const mapRef = React.useRef(null);
    const { points } = props;

    const onLoad = React.useCallback(function callback(map: any) { mapRef.current = map }, []);
    const onUnmount = React.useCallback(function callback(map: any) { mapRef.current = null; }, []);


    return (
        <>
            <Box sx={{ display: "flex", flexDirection: "row", alignContent: "stretch", alignItems: "stretch", height: "100%" }} >
                {/* <div style={{ width: "20px" }}>
                    <Button>Центр</Button>
                </div> */}
                <div style={{ flex: 1 }}>
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center_company}
                        zoom={6}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                        options={defaultOptions} >

                        {
                            (points) && (points.map(
                                (p: IMarkerInfo, index) => (
                                    <Marker key={`pa` + index}
                                        position={{ lat: p.lat, lng: p.lng }}
                                        // icon={{
                                        //     url: '/pointEvent.svg',
                                        //     origin: new window.google.maps.Point(0, 0),
                                        //     anchor: new window.google.maps.Point(20, 15),
                                        //     scaledSize: new window.google.maps.Size(40, 40)
                                        // }}
                                    />
                                )))
                        }

                    </GoogleMap>
                </div>
            </Box>
        </>
    )
}