import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import ActivateDetailsComponent from "../../CustomComponentModule/ActivateDetailsComponent";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { modifyActiveLoadPage, modifyActiveLoadRefresh, modifyActiveLoadSizePage, modifyRightFlip } from "../../../store/reducers/cnfSlice";
import { Link, IconButton, Tooltip, Typography } from "@mui/material";
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import DownloadIcon from '@mui/icons-material/Download';
import StarsIcon from '@mui/icons-material/Stars';
import CategoryIcon from '@mui/icons-material/Category';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import PersonIcon from '@mui/icons-material/Person';
import RvHookupIcon from '@mui/icons-material/RvHookup';
import { useEffect, useState } from "react";
import { IActiveLoadResponseDto, IActiveLoadTransportResponseDto, IActiveLoadTransportVehicleResponseDto, IActiveLoadUserResponseDto } from "../../../models/ActiveLoadModels";
import ActiveLoadService from "../../../services/ActiveLoadService";
import { getDateAndTimeFormat, getDateFormat } from "../../../helpers/dateHelper";
import { curency } from "../../../helpers/textHelper";
import { ILoadIdIdnoCustomerResponseDto } from "../../../models/LoadModels";
import { generateUniqueId } from "../../../helpers/generalHelper";
import { IProgress } from "../../../models/CommonModels";
import { useNavigate } from "react-router-dom";
import { IWarehouseIdNameAddressResponseDto } from "../../../models/WarehouseModels";
import SCViewList from "../../../styledComponents/sharedComponent/SCViewList";
import { IUserShortResponseDto } from "../../../models/IUser";

const ActiveLoadGridComponent = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { hints, timeFormat, dateFormat } = useSelector((state: RootState) => state.cnfSlice.interfaceState);

    const { refresh, page, size } = useSelector((state: RootState) => state.cnfSlice.activeLoad);

    const [checkState, setCheckState] = useState(true);
    const [selectionModel, setSelectionModel] = useState<Array<string>>([]);
    const [data, setData] = useState<IActiveLoadResponseDto[]>([]);
    const [loading, setLoading] = useState(false);
    const [pageC, setPageC] = useState(page);
    const [pageSizeC, setPageSizeC] = useState(size);
    const [totalRows, setTotalRows] = useState(0);

    useEffect(() => {
        setLoading(true);
        (async () => {
            const criteria: any[] = [];
            const filter = {
                criteria: criteria,
                paging: {
                    page: page,
                    size: size
                }
            };

            const [, response] = await ActiveLoadService.getAllActiveLoad(filter);
            if (response) {
                setTotalRows(response.data.total);
                const data: IActiveLoadResponseDto[] = response.data.body.map((item: IActiveLoadResponseDto) => ({ ...item, id: item.uuid }))
                setData(data);
            }
            setLoading(false);
        })()
    }, [page, size, refresh])

    const onDetailsCellClick = (cellValue: any) => {
        navigate('/activeLoad/' + cellValue.row.id);
        dispatch(modifyRightFlip(true));
    }

    const columns: GridColDef[] = [{
        field: 'details',
        headerName: '',
        width: 50,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (cellValue: any) => {
            return (
                <>
                    <div style={{ display: "flex", textAlign: "center", justifyContent: "center", padding: 0 }}>
                        <ActivateDetailsComponent activate={() => onDetailsCellClick(cellValue)} />
                    </div>
                </>
            );
        }
    }, {
        field: 'name',
        headerName: t('NAME'),
        width: 200,
        headerAlign: 'center',
        renderCell: (cellValue: any) => {
            return (
                <>
                    <Tooltip title={cellValue.row.name.toUpperCase()} placement={"bottom"} arrow={true}>
                        <Typography variant={'body2'} fontSize={'11px'}>
                            {cellValue.row.name.toUpperCase()}
                        </Typography>
                    </Tooltip>
                </>
            );
        }
    }, {
        field: 'startDate',
        headerName: t('START'),
        width: 115,
        headerAlign: 'center',
        renderCell: (cellValue: any) => {
            const date: number = cellValue.row.startDate,
                dateAsStr = date ? getDateFormat(date, dateFormat) : '';
            return (
                <>
                    <Tooltip title={dateAsStr} placement={"bottom"} arrow={true}>
                        <Typography variant={'body2'} fontSize={'11px'}>
                            {dateAsStr}
                        </Typography>
                    </Tooltip>
                </>
            );
        }
    }, {
        field: 'endDate',
        headerName: t('END'),
        width: 115,
        headerAlign: 'center',
        renderCell: (cellValue: any) => {
            const date: number = cellValue.row.endDate,
                dateAsStr = date ? getDateFormat(date, dateFormat) : '';
            return (
                <>
                    <Tooltip title={dateAsStr} placement={"bottom"} arrow={true}>
                        <Typography variant={'body2'} fontSize={'11px'}>
                            {dateAsStr}
                        </Typography>
                    </Tooltip>
                </>
            );
        }
    }, {
        field: 'pickup',
        headerName: t('PICKUP'),
        width: 380,
        headerAlign: 'center',
        renderCell: (cellValue: any) => {
            const row: IActiveLoadResponseDto = cellValue.row,
                addresses: Array<String> = row.pickupAddresses || [],
                warehouses: Array<IWarehouseIdNameAddressResponseDto> = row.pickupWarehouses || [];

            const addressesSize: number = addresses.length,
                warehousesSize: number = warehouses.length;

            const onlyOneAddress: String | null = (addressesSize === 1) ? addresses[0] : null,
                onlyOneWarehouse: IWarehouseIdNameAddressResponseDto | null = (warehousesSize === 1) ? warehouses[0] : null;

            return (
                <>
                    <SCViewList>
                        {onlyOneAddress && !onlyOneWarehouse &&
                            <Tooltip key={generateUniqueId()} title={hints ? onlyOneAddress : ''} arrow >
                                <Typography variant={'body2'} fontSize={'10px'}>
                                    <IconButton style={{ marginLeft: 10 }} color={'primary'} size={'small'}>
                                        <AddLocationAltIcon fontSize="small" />
                                    </IconButton>
                                    {onlyOneAddress}
                                </Typography>
                            </Tooltip>
                        }

                        {onlyOneWarehouse && !onlyOneAddress &&
                            <Tooltip key={generateUniqueId()} title={hints ? `${onlyOneWarehouse.name} [ ${onlyOneWarehouse.address} ]` : ''} arrow >
                                <Typography variant={'body2'} fontSize={'10px'}>
                                    <Link style={{ marginLeft: 10 }} onClick={() => { navigate('/warehouse/' + onlyOneWarehouse.uuid) }} underline="hover" >
                                        <IconButton color={'primary'} size={'small'}>
                                            <WarehouseIcon fontSize="small" />
                                        </IconButton>
                                    </Link>
                                    {onlyOneWarehouse.address}
                                </Typography>
                            </Tooltip>
                        }

                        {(warehousesSize > 1 || (onlyOneWarehouse && onlyOneAddress)) && warehouses.map(item => (
                            <Tooltip key={generateUniqueId()} title={hints ? `${item.name} [ ${item.address} ]` : ''} arrow >
                                <Link style={{ marginLeft: 10 }} onClick={() => { navigate('/warehouse/' + item.uuid) }} underline="hover" >
                                    <IconButton color={'primary'} size={'small'}>
                                        <WarehouseIcon fontSize="small" />
                                    </IconButton>
                                </Link>
                            </Tooltip>
                        ))}

                        {(addressesSize > 1 || (onlyOneWarehouse && onlyOneAddress)) && addresses.map(item => (
                            <Tooltip key={generateUniqueId()} title={hints ? item : ''} arrow >
                                <IconButton style={{ marginLeft: 10 }} color={'primary'} size={'small'}>
                                    <AddLocationAltIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        ))}
                    </SCViewList >
                </>
            )
        }
    }, {
        field: 'delivery',
        headerName: t('DELIVERY'),
        width: 380,
        headerAlign: 'center',
        renderCell: (cellValue: any) => {
            const row: IActiveLoadResponseDto = cellValue.row,
                addresses: Array<String> = row.deliveryAddresses || [],
                warehouses: Array<IWarehouseIdNameAddressResponseDto> = row.deliveryWarehouses || [];

            const addressesSize: number = addresses.length,
                warehousesSize: number = warehouses.length;

            const onlyOneAddress: String | null = (addressesSize === 1) ? addresses[0] : null,
                onlyOneWarehouse: IWarehouseIdNameAddressResponseDto | null = (warehousesSize === 1) ? warehouses[0] : null;

            return (
                <>
                    <SCViewList>
                        {onlyOneAddress && !onlyOneWarehouse &&
                            <Tooltip key={generateUniqueId()} title={hints ? onlyOneAddress : ''} arrow >
                                <Typography variant={'body2'} fontSize={'10px'}>
                                    <IconButton style={{ marginLeft: 10 }} color={'success'} size={'small'}>
                                        <AddLocationAltIcon fontSize="small" />
                                    </IconButton>
                                    {onlyOneAddress}
                                </Typography>
                            </Tooltip>
                        }

                        {onlyOneWarehouse && !onlyOneAddress &&
                            <Tooltip key={generateUniqueId()} title={hints ? `${onlyOneWarehouse.name} [ ${onlyOneWarehouse.address} ]` : ''} arrow >
                                <Typography variant={'body2'} fontSize={'10px'}>
                                    <Link style={{ marginLeft: 10 }} onClick={() => { navigate('/warehouse/' + onlyOneWarehouse.uuid) }} underline="hover" >
                                        <IconButton color={'success'} size={'small'}>
                                            <WarehouseIcon fontSize="small" />
                                        </IconButton>
                                    </Link>
                                    {onlyOneWarehouse.address}
                                </Typography>
                            </Tooltip>
                        }

                        {(warehousesSize > 1 || (onlyOneWarehouse && onlyOneAddress)) && warehouses.map(item => (
                            <Tooltip key={generateUniqueId()} title={hints ? `${item.name} [ ${item.address} ]` : ''} arrow >
                                <Link style={{ marginLeft: 10 }} onClick={() => { navigate('/warehouse/' + item.uuid) }} underline="hover" >
                                    <IconButton color={'success'} size={'small'}>
                                        <WarehouseIcon fontSize="small" />
                                    </IconButton>
                                </Link>
                            </Tooltip>
                        ))}

                        {(addressesSize > 1 || (onlyOneWarehouse && onlyOneAddress)) && addresses.map(item => (
                            <Tooltip key={generateUniqueId()} title={hints ? item : ''} arrow >
                                <IconButton style={{ marginLeft: 10 }} color={'success'} size={'small'}>
                                    <AddLocationAltIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        ))}
                    </SCViewList >
                </>
            )
        }
    }, {
        field: 'customer',
        headerName: t('CUSTOMER & LOAD'),
        width: 320,
        headerAlign: 'center',
        renderCell: (cellValue: any) => {
            const row: IActiveLoadResponseDto = cellValue.row,
                loads: Array<ILoadIdIdnoCustomerResponseDto> = row.loads || [],
                loadsSize: number = loads.length,
                load: ILoadIdIdnoCustomerResponseDto | null = (loadsSize === 1) ? loads[0] : null;

            return (
                <>
                    {load &&
                        < Tooltip key={generateUniqueId()} title={hints ? `${load.customer.name} [ ${load.idno} ]` : ''} arrow sx={{ cursor: 'pointer' }}>
                            <Typography variant={'body2'} fontSize={'12px'}>
                                <Link onClick={() => { navigate('/customer/' + load.customer.uuid) }} underline="hover" >
                                    {`${load.customer.name} [ ${load.idno} ]`}
                                </Link>
                            </Typography>
                        </Tooltip >
                    }

                    {loadsSize > 1 && loads.map(item => (
                        <Tooltip key={generateUniqueId()} title={hints ? `${item.customer.name} [ ${item.idno} ]` : ''} arrow >
                            <Link style={{ marginLeft: 10 }} onClick={() => { navigate('/customer/' + item.customer.uuid) }} underline="hover" >
                                <IconButton style={{ marginRight: 10 }} color={'primary'} size={'small'}>
                                    <CategoryIcon fontSize="small" />
                                </IconButton>
                            </Link>
                        </Tooltip >
                    ))}
                </>
            );
        }
    }, {
        field: 'users',
        headerName: t('USERS'),
        width: 140,
        headerAlign: 'center',
        renderCell: (cellValue: any) => {
            const row: IActiveLoadResponseDto = cellValue.row,
                users: IActiveLoadUserResponseDto | null = row.users || null;

            let driver: IUserShortResponseDto | null = null,
                dispatcher: IUserShortResponseDto | null = null,
                administrator: IUserShortResponseDto | null = null;

            if (users) {
                driver = users.driver;
                dispatcher = users.dispatcher;
                administrator = users.administrator;
            }

            return (
                <>
                    {driver &&
                        < Tooltip title={hints ? `${driver.fullName}` : ''} arrow >
                            <IconButton style={{ marginRight: 10 }} color={'primary'} size={'small'}>
                                <PersonIcon fontSize="small" />
                            </IconButton>
                        </Tooltip >
                    }

                    {dispatcher &&
                        < Tooltip title={hints ? `${dispatcher.fullName}` : ''} arrow >
                            <IconButton style={{ marginRight: 10 }} color={'primary'} size={'small'}>
                                <RecordVoiceOverIcon fontSize="small" />
                            </IconButton>
                        </Tooltip >
                    }

                    {administrator &&
                        < Tooltip title={hints ? `${administrator.fullName}` : ''} arrow >
                            <IconButton style={{ marginRight: 10 }} color={'primary'} size={'small'}>
                                <PeopleAltIcon fontSize="small" />
                            </IconButton>
                        </Tooltip >
                    }
                </>
            );
        }
    }, {
        field: 'transport',
        headerName: t('TRANSPORT'),
        width: 140,
        headerAlign: 'center',
        renderCell: (cellValue: any) => {
            const row: IActiveLoadResponseDto = cellValue.row,
                transports: IActiveLoadTransportResponseDto | null = row.transports || null;

            let transport: IActiveLoadTransportVehicleResponseDto | null = null,
                mainTrailer: IActiveLoadTransportVehicleResponseDto | null = null,
                additionalTrailer: IActiveLoadTransportVehicleResponseDto | null = null;

            if (transports) {
                transport = transports.transport;
                mainTrailer = transports?.mainTrailer || null;
                additionalTrailer = transports?.additionalTrailer || null;
            }

            return (
                <>
                    {transport &&
                        < Tooltip title={hints ? `${transport.brand} ${transport.model} [ ${transport.transportNumber} ]` : ''} arrow >
                            <IconButton style={{ marginRight: 10 }} color={'primary'} size={'small'}>
                                <LocalShippingIcon fontSize="small" />
                            </IconButton>
                        </Tooltip >
                    }

                    {mainTrailer &&
                        < Tooltip title={hints ? `${mainTrailer.brand} ${mainTrailer.model} [ ${mainTrailer.transportNumber} ]` : ''} arrow >
                            <IconButton style={{ marginRight: 10 }} color={'primary'} size={'small'}>
                                <RvHookupIcon fontSize="small" />
                            </IconButton>
                        </Tooltip >
                    }

                    {additionalTrailer &&
                        < Tooltip title={hints ? `${additionalTrailer.brand} ${additionalTrailer.model} [ ${additionalTrailer.transportNumber} ]` : ''} arrow >
                            <IconButton color={'success'} size={'small'}>
                                <RvHookupIcon fontSize="small" />
                            </IconButton>
                        </Tooltip >
                    }
                </>
            );
        }
    }, {
        field: 'progress',
        headerName: t('PROGRESS'),
        width: 115,
        headerAlign: 'center',
        renderCell: (cellValue: any) => {
            const row: IActiveLoadResponseDto = cellValue.row,
                progress: IProgress = row.progress;
            const value: string = `${progress.completed} / ${progress.total}`;

            return (
                <>
                    <Tooltip title={`Delivered ${progress.completed} from ${progress.total}`} placement={"bottom"} arrow={true}>
                        <Typography variant={'body2'} fontSize={'11px'}>
                            {value}
                        </Typography>
                    </Tooltip>
                </>
            );
        }
    }, {
        field: 'amount',
        headerName: t('AMOUNT'),
        width: 135,
        headerAlign: 'center',
        renderCell: (cellValue: any) => {
            const amount: string = cellValue.row.amount + ' ' + curency;
            return (
                <>
                    <Tooltip title={amount} placement={"bottom"} arrow={true}>
                        <Typography variant={'body2'} fontSize={'11px'}>
                            {amount}
                        </Typography>
                    </Tooltip>
                </>
            );
        }
    }, {
        field: 'status',
        headerName: t('STATUS'),
        width: 115,
        headerAlign: 'center',
        renderCell: (cellValue: any) => {
            return (
                <>
                    <Typography variant={'body2'} fontSize={'11px'}>
                        {cellValue.row.status.split('_').join(' ')}
                    </Typography>
                </>
            );
        }
    }, {
        field: 'created',
        headerName: t('CREATED'),
        sortable: true,
        width: 180,
        headerAlign: 'center',
        renderCell: (cellValue: any) => {
            const date = getDateAndTimeFormat(cellValue.row.createdDate, dateFormat, timeFormat),
                author = cellValue.row.createdBy,
                tooltipValue = author + ' ' + date;
            return (
                <>
                    <Tooltip title={tooltipValue} placement={"bottom"}>
                        <Typography variant={'body2'} fontSize={'11px'}>
                            {author} <br /> {date}
                        </Typography>
                    </Tooltip>
                </>
            );
        }
    }, {
        field: 'updated',
        headerName: t('UPDATED'),
        sortable: true,
        width: 180,
        headerAlign: 'center',
        renderCell: (cellValue: any) => {
            const date = getDateAndTimeFormat(cellValue.row.updatedDate, dateFormat, timeFormat),
                author = cellValue.row.updatedBy,
                tooltipValue = author + ' ' + date;
            return (
                <>
                    {cellValue.row.updatedDate &&
                        <Tooltip title={tooltipValue} placement={"bottom"}>
                            <Typography variant={'body2'} fontSize={'11px'}>
                                {author} <br /> {date}
                            </Typography>
                        </Tooltip>
                    }
                </>
            );
        }
    }];

    const styled = {
        container: { display: "flex", gap: "20px" },
        item: { width: "40px" },
        box: { border: "1px solid silver", textAlign: "center", borderRadius: "3px" },
        icon: { fontSize: "28px", cursor: "pointer" },
        button: { border: "1px solid silver" }
    };

    const onShowHideSelectionColumnClick = () => {
        setSelectionModel([]);
        setCheckState((v) => { return !v });
    };

    const onRefreshBtnClick = () => {
        dispatch(modifyActiveLoadRefresh());
    };

    const onAddToFavoritesBtnClick = () => {
    };

    const onPageChange = (newPage: number) => {
        dispatch(modifyActiveLoadPage(newPage));
        setPageC(newPage);
    }

    const onPageSizeChange = (newPageSize: number) => {
        dispatch(modifyActiveLoadSizePage(newPageSize));
        setPageSizeC(newPageSize);
    }

    return (
        <>
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingBottom: "2px",
                height: "46px"
            }}>
                <div>
                    <div style={{ width: "400px", paddingLeft: "7px", display: "flex", gap: "10px" }}>
                        <Tooltip title={(hints) ? t("Show/Hide the selection column") : ''} placement={"top"} arrow={true}>
                            <IconButton onClick={onShowHideSelectionColumnClick} size='small'>
                                <PlaylistAddCheckCircleIcon style={styled.icon} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={(hints) ? t("Refresh") : ''} placement={"top"} arrow={true} >
                            <IconButton onClick={onRefreshBtnClick} size='small'>
                                <ReplayCircleFilledIcon style={styled.icon} />
                            </IconButton>
                        </Tooltip>
                        {!(data.length < 1) &&
                            <Tooltip title={(hints) ? t("Download XLS report for active load") : ''} placement={"top"} arrow={true} key={"key"}>
                                <IconButton>
                                    <DownloadIcon style={styled.icon} />
                                </IconButton>
                            </Tooltip>}
                        {(selectionModel.length > 0) &&
                            <div>
                                <Tooltip title={(hints) ? t("Add selected active load(s) to favorites") : ''} placement={"top"} arrow={true}>
                                    <IconButton onClick={onAddToFavoritesBtnClick}>
                                        <StarsIcon style={styled.icon} color={"primary"} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        }
                    </div>
                </div>
            </div>

            <div style={{ height: "92%" }}>
                <DataGrid
                    density={'compact'}
                    paginationMode="server"
                    loading={loading}
                    rows={data}
                    columns={columns}

                    rowCount={totalRows}
                    page={pageC}
                    pageSize={pageSizeC}

                    rowsPerPageOptions={[5, 10, 15, 20, 25]}
                    onPageSizeChange={onPageSizeChange}
                    onPageChange={onPageChange}

                    checkboxSelection={checkState}
                    onSelectionModelChange={(newSelectionModel: any) => {
                        setSelectionModel(newSelectionModel);
                    }}
                    selectionModel={selectionModel}
                />
            </div>
        </>
    );
}
export default ActiveLoadGridComponent;