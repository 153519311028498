import { useTranslation } from "react-i18next";
import { Divider, Box, Button } from '@mui/material';
import { modifyWarehouseRole } from "../../store/reducers/cnfSlice";
import { AppDispatch, RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { ITypeWarehouse } from "../../models/WarehouseModels";
import ListWarehouseTempComponent from "./ListWarehouseTempComponent";

export default function ListWarehouseComponent() {

  const { t } = useTranslation();
  const { role, part } = useSelector((state: RootState) => state.cnfSlice.warehouse);
  const dispatch = useDispatch<AppDispatch>();

  const hendlerList = (list: ITypeWarehouse[]) => {
    dispatch(modifyWarehouseRole(list));
  }

  return (
    <>

      <Box sx={{ display: "flex", marginTop: "10px", justifyContent: "center" }} >
        <Button
          size="small"
          variant={'contained'}
          disabled={role.length === 0}
          onClick={() => { dispatch(modifyWarehouseRole([])); }}
          sx={{ marginLeft: "5px", width: "100%" }}>
          {'ALL ' + part}
        </Button>
      </Box>
      <ListWarehouseTempComponent getList={hendlerList} list={role} />
      <Divider />

    </>
  );
}