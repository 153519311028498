
import axios from "axios";

export interface IItem {
  id: number,
  info: string
}

export class ItemsService {


  public static async getCurrentStateInWay(id:number) {
    return await axios.get(`/way${id}.json`)
      .then((res: any) => {
        return res.data.data[30];
      }).catch((erorr) => {
        console.error(erorr)
      });
  }

  public static async getWay(id:number) {
    return await axios.get(`/way${id}.json`)
      .then((res: any) => {
        return res.data;
      }).catch((erorr) => {
        console.error(erorr)
      });
  }

  public static async getWayList() {
    return await axios.get(`/waylist.json`)
      .then((res: any) => {       
        return res.data;
      }).catch((erorr) => {
        console.error(erorr)
      });
  }


  public static async getWayDirections() {

    const API_KEY = 'AIzaSyAiZgZRnIOjWDsnIx0o-WO-SJQLWI4wmOA';

    var config:any = {
      method: 'get',
      url: `https://maps.googleapis.com/maps/api/directions/json?origin=Adelaide%2C%20SA&destination=Adelaide%2C%20SA&waypoints=optimize%3Atrue%7CBarossa%20Valley%2C%20SA%7CClare%2C%20SA%7CConnawarra%2C%20SA%7CMcLaren%20Vale%2C%20SA&key=${API_KEY}`,
      headers: { 'Content-Type': 'application/json' }     
    };   
    return await axios.get('https://maps.googleapis.com/maps/api/directions/json?origin=Adelaide%2C%20SA&destination=Adelaide%2C%20SA&waypoints=optimize%3Atrue%7CBarossa%20Valley%2C%20SA%7CClare%2C%20SA%7CConnawarra%2C%20SA%7CMcLaren%20Vale%2C%20SA&key=AIzaSyAiZgZRnIOjWDsnIx0o-WO-SJQLWI4wmOA')
      .then((res: any) => {             
        return res.data;
      }).catch((error) => {
        return error;
      });
  }

}
