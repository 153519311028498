
import { Box } from "@mui/system";
import { GoogleMap, InfoWindow } from "@react-google-maps/api"
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IMarkerInfo } from "../../models/MapModels";
import { EWarehouseReputationLevel, IMarkerInfoWarehouse, IWarehouseList } from "../../models/WarehouseModels";
import WarehouseService from "../../services/WarhouseService";
import { RootState } from "../../store/store";
import '../../App.scss';
import { Tooltip } from "@mui/material";
import { TagComponent } from "./TagComponent";
import VIPViewComponent from "../CustomComponentModule/VIPViewComponent";
import BLViewComponent from "../CustomComponentModule/BLViewComponent";
import WarehouseIcon from '@mui/icons-material/Warehouse';
import { setLastError } from "../../store/reducers/cnfSlice";

const containerStyle = {
    width: '100%',
    height: '100%'
};


interface IProps {
    points?: Array<IMarkerInfo>
    linkTo: (uuid: string) => void
}

export const WarehouseMapBoxComponent = (props: IProps) => {

    const dispatch = useDispatch();
    const mapRef = React.useRef(null);


    const onLoad = React.useCallback(function callback(map: any) { mapRef.current = map }, []);
    const onUnmount = React.useCallback(function callback(map: any) { mapRef.current = null; }, []);
    const { reputation, refresh, page, size, part, role } = useSelector((state: RootState) => state.cnfSlice.warehouse);
    const [items, setItems] = useState<IMarkerInfoWarehouse[]>([]);

    const hendlerInfoBox = () => { }

    const linkToDetails = (uuid: string) => {
        props.linkTo(uuid);
    }

    const [center_company, setCenter_company] = useState({
        lat: 45.07628287127715,
        lng: -93.24481908541335
    });

    const defaultOptions = {
        zoom: 6
    }


    useEffect(() => {

        (async () => {
            let criteria: any[] = [];

            criteria.push({
                property: "status",
                value: part,
                expression: "equals"
            })

            for (let r of role) {
                criteria.push({
                    property: "types.uuid",
                    value: r.uuid,
                    expression: "IN"
                })
            }

            if (reputation !== 'ALL') {
                criteria.push({
                    property: "reputationLevel",
                    value: reputation,
                    expression: "EQUALS"
                })
            }

            let body = {
                criteria: criteria,
                paging: {
                    page: page,
                    size: size
                }
            }

            const [error, response] = await WarehouseService.getWarehouses(body);
            if (response) {
                let rs: IWarehouseList[] = response.data.body as IWarehouseList[];
                let local_items = [];
                for (let e of rs) {
                    let item: IMarkerInfoWarehouse = {
                        lat: e.address?.latitude ? e.address?.latitude : 0,
                        lng: e.address?.longitude ? e.address.longitude : 0,
                        label: e.contactName.trim(),
                        uuid: e.uuid,
                        types: e.types,
                        status: e.status,
                        reputationLevel: e.reputationLevel

                    }
                    local_items.push(item);
                }
                setItems(local_items);
            }
            if (error) { dispatch(setLastError(error)) }

        })()
    }, [reputation, role, part, page, size, refresh])


    return (
        <>
            <Box sx={{ display: "flex", flexDirection: "row", alignContent: "stretch", alignItems: "stretch", height: "100%" }} >
                <div style={{ flex: 1 }}>
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center_company}
                        zoom={6}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                        options={defaultOptions} >

                        {/* {
                            (items) && (items.map(
                                (p: IMarkerInfo, index) => (
                                    <Marker key={`pa` + index}
                                        position={{ lat: p.lat, lng: p.lng }}
                                        icon={{
                                            url: '/pointEvent.svg',
                                            origin: new window.google.maps.Point(0, 0),
                                            anchor: new window.google.maps.Point(20, 15),
                                            scaledSize: new window.google.maps.Size(40, 40)
                                        }}
                                    />
                                )))


                        } */}

                        {(items) && (items.map(
                            (p: IMarkerInfoWarehouse, index) => (
                                <InfoWindow key={`q` + index}
                                    position={{ lat: p.lat, lng: p.lng }}
                                    onCloseClick={hendlerInfoBox}>
                                    <div style={{ textAlign: "center", cursor: "pointer" }} onClick={() => linkToDetails(p.uuid)}>

                                        <Tooltip
                                            title={p.label ? p.label : ''}
                                            placement={"top"}
                                            arrow={true}
                                        >

                                            <Box sx={{ display: 'flex', gap: "10px", alignItems: "center" }}>

                                                {p.reputationLevel === EWarehouseReputationLevel.VIP &&
                                                    <Box sx={{ display: "flex", alignItems: "center" }} >
                                                        <VIPViewComponent width={30} heigth={30} />
                                                    </Box>}

                                                {p.reputationLevel === EWarehouseReputationLevel.POOR &&
                                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "10px" }}>
                                                        <BLViewComponent width={30} heigth={30} />
                                                    </Box>}

                                                {p.reputationLevel === EWarehouseReputationLevel.STANDARD &&
                                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                        <WarehouseIcon sx={{ color: "silver" }} />
                                                    </Box>}

                                                <Box sx={{ display: "flex", gap: "2px", maxWidth: "60px", flexWrap: "wrap" }}>
                                                    {p.types.map((e, k) =>
                                                        <TagComponent key={k} size={15} value={e.name} />
                                                    )}
                                                </Box>
                                            </Box>
                                        </Tooltip>
                                    </div>
                                </InfoWindow>
                            )))
                        }

                    </GoogleMap>
                </div>
            </Box>
        </>
    )
}