import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { IAutoDetails, IAutoRegistration } from "../../models/TransportModels";
import ControlStateInCRUDComponent from "../CustomComponentModule/ControlStateInCRUDComponent";
import EditColorComponent from "../CustomComponentModule/EditColorComponent/EditColorComponent";
import HTMLRedactor from "../CustomComponentModule/HTMLRedactor";
import TransportService from "../../services/TransportService";
import { useDebounce } from "../../hooks/useDebounce";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import { modifyTransportRefresh, setLastError } from "../../store/reducers/cnfSlice";
import CustomerService from "../../services/CustomerService";

interface IProps {
    auto: IAutoDetails | null;
    refreshAfretSave: () => void;
    snackMsg: (text: string) => void;
    handleStep: (step: number) => void;
}

const RegistrationTransportComponent = (props: IProps) => {
    const auto = props.auto
    const [save, setSave] = useState<boolean>(false);

    const [licencePlate, setLicencePlate] = useState<string>("");
    const [transportNumber, setTransportNumber] = useState<string>("");

    const debounceLicence = useDebounce(licencePlate, 300)
    const debounceTransportNumber = useDebounce(transportNumber, 300)

    const [errorLicensePlate, setErrorLicensePlate] = useState(false)
    const [textErrorLicensePlate, setTextErrorLicensePlate] = useState("")
    const [errorTransportNumber, setErrorTransportNumber] = useState(false)
    const [textTransportNumber, setTextTransportNumber] = useState("")
    const dispatch = useDispatch<AppDispatch>();
    const [errLicence, setErrLicence] = useState(false);
    const [errNumber, setErrNumber] = useState(false);
    const [contolStateCustomer, setContolStateCustomer] = useState(false);
    const [state, setState] = useState<IAutoRegistration>(
        {
            color: "#000000",
            transportNumber: "",
            description: "",
            licensePlate: ""
        });
    const [snapshot, setSnapshot] = useState<string>("");
    const [description, setDescription] = useState<string>("");

    const [error, setError] = useState<number>(0);
    const [textErrors, setTextErrors] = useState<Array<string>>([])
    const [reset, setReset] = useState<boolean>(false)


    const handlerSaveUser = () => { setSave(true); }
    const handlerReset = () => {
        if (auto) { setReset(!reset) }
    }
    const handlerDescription = (s: string) => {
        setState((st) => { return { ...st, description: s } })
    }

    const handleChengeColor = (s: string) => {
        if (s !== '' && s) {
            setState((st) => { return { ...st, color: s } })
        }
    }

    const handleChangeLicensePlate = (event: any) => {
        let value: string = event.target.value.trim()
        setState((st) => { return { ...st, licensePlate: value } })
        setLicencePlate(value)
    }

    const handleChangeTransportNumber = (event: any) => {
        let value: string = event.target.value.trim()
        setState((st) => { return { ...st, transportNumber: value } })
        setTransportNumber(value)
    }

    useEffect(() => {
        if (save && auto) {
            (async () => {
                let body = { ...state, uuid: auto?.uuid }

                const [error, response] = await TransportService.SaveRegistrationData(body)
                if (response) {
                    setSnapshot(JSON.stringify({ ...state }));
                    setContolStateCustomer(true);
                }
                if (error) { dispatch(setLastError(error)) }
            })()
            setSave(false);
        }
    }, [save])
    useEffect(() => {
        if (contolStateCustomer) {
            if (auto) {
                (async () => {
                    const [error, response] = await TransportService.getMetadata(auto.uuid)
                    if (response) {
                        const { step, completed } = response.data.body;

                        if (completed && !auto.metadata.completed && step !== 3) {
                            props.handleStep(3);
                        }
                        if (!completed && !auto.metadata.completed && step !== 1) {
                            let bd = { uuid: auto.uuid, step: 1 }
                            const [er, rs] = await CustomerService.saveCustomerStep(bd);
                            if (er) { dispatch(setLastError(error)) }
                        }

                        props.refreshAfretSave();
                        props.snackMsg('Registration data was successfully saved for ' + auto.brand + auto.model + '.');
                        dispatch(modifyTransportRefresh());
                    }
                    if (error) { dispatch(setLastError(error)) }
                })()
            }
        }
        setContolStateCustomer(false);
    }, [contolStateCustomer])

    useEffect(() => {
        if (debounceLicence) {
            (async () => {
                const [error, response] = await TransportService.isTrueLicensePlate(debounceLicence);
                if (response) {
                    let ras = response.data.body
                    if (ras) {
                        setErrorLicensePlate(false); setTextErrorLicensePlate('');
                    } else {
                        setErrorLicensePlate(true); setTextErrorLicensePlate('* License Plate  is already in use');
                    }
                }
                if (error) { }
            })()
        } else setErrorLicensePlate(false);
    }, [debounceLicence])

    useEffect(() => {
        (async () => {
            if (debounceTransportNumber) {
                const [error, response] = await TransportService.isTrueTransportNumber(debounceTransportNumber);
                if (response) {
                    let ras = response.data.body.available
                    if (ras) {
                        setErrorTransportNumber(false); setTextTransportNumber('');
                    } else {
                        setErrorTransportNumber(true); setTextTransportNumber('* Transport Number is already in use');
                    }
                }
                if (error) { }
            } else setErrorLicensePlate(false);
        })()
    }, [debounceTransportNumber])

    useEffect(() => {
        if (auto) {
            (async () => {
                const [error, response] = await TransportService.GetRegistrationData(auto.uuid);
                if (response) {
                    const value = response.data.body as IAutoRegistration;
                    if (!value.description || (value.description === "N/A")) {
                        value.description = ''
                    }
                    setDescription(value.description);
                    setState({ ...value, color: value.color ? value.color : '#000000' })
                    setSnapshot(JSON.stringify({ ...value }));
                    setLicencePlate(state.licensePlate ? state.licensePlate : "")
                    setTransportNumber(state.transportNumber ? state.transportNumber : "")
                }
                if (error) {
                    dispatch(setLastError(error))
                }
            })()
        }
    }, [auto?.uuid, reset])

    useEffect(() => {
        let er: boolean = false;
        let ter: Array<string> = [];
        let value: string = (state.licensePlate) ? state.licensePlate : "";
        let value2: string = (state.transportNumber) ? state.transportNumber : "";
        let value3: string = (state.color) ? state.color : "";

        if ((value.length < 4)) {
            er = true;
            ter.push("* You should write any license number")
            setErrLicence(true)
        } else if (errorLicensePlate) {
            if (JSON.stringify(auto?.licensePlate) === JSON.stringify(state.licensePlate)) { setErrLicence(false) }
            else {
                er = true;
                ter.push(textErrorLicensePlate)
                setErrLicence(true)
            }
        } else { setErrLicence(false) }

        if (value3 === '') {
            er = true;
            ter.push("* You should choose a color");
        }

        if ((value2.length < 1)) {
            er = true;
            ter.push("* You should write a transport number")
            setErrNumber(true)
        } else if (errorTransportNumber) {
            if (JSON.stringify(auto?.transportNumber) === JSON.stringify(state.transportNumber)) { setErrNumber(false) }
            else {
                er = true;
                ter.push(textTransportNumber)
                setErrNumber(true)
            }
        } else { setErrNumber(false) }

        if (auto && !er) {
            if (snapshot === JSON.stringify(state)) {
                setError(0)
            } else {
                setError(1)
            }
        } else {
            setError(-1)
        }
        setTextErrors(ter);
    }, [auto, state, errorLicensePlate, errorTransportNumber])

    return (
        <>
            <div style={{ flex: "1 1 auto", display: "flex", gap: 10, width: "620px", }}>
                <div style={{ display: "flex", flexDirection: "column", gap: "10px", width: "535px", marginRight: "18px" }}>

                    <div style={{ display: "flex", gap: "30px", alignItems: "start", marginLeft: "10px" }}>
                        <div style={{ width: "50px", height: '50px', display: "flex", justifyContent: "center", alignItems: "center", marginTop: "13px" }}>
                        </div>

                        <TextField
                            error={errLicence}
                            label="License plate"
                            onChange={handleChangeLicensePlate}
                            value={state.licensePlate || ''}
                            style={{ width: "535px", marginTop: "15px" }}
                            inputProps={{ sx: { fontSize: "11px" } }}
                            InputLabelProps={{ sx: { fontSize: "17px" } }}
                        />

                    </div>

                    {<div style={{ display: "flex", gap: "30px", alignItems: "start", marginLeft: "10px" }}>
                        <div style={{ width: "50px", height: '50px', display: "flex", justifyContent: "center", alignItems: "center", marginTop: "13px" }}>

                        </div>
                        <TextField
                            error={errNumber}
                            label="Transport number"
                            onChange={handleChangeTransportNumber}
                            value={state.transportNumber || ''}
                            style={{ width: "535px", marginTop: "15px" }}
                            inputProps={{ sx: { fontSize: "11px" } }}
                            InputLabelProps={{ sx: { fontSize: "17px" } }}
                        />

                    </div>}

                    <div style={{ display: "flex", gap: "30px", alignItems: "start", marginTop: "15px", marginLeft: "80px" }}>
                        <EditColorComponent
                            currentColor={(state.color) ? state.color : '#000000'}
                            getColor={handleChengeColor}
                        ></EditColorComponent>

                        <TextField

                            disabled
                            id="standard-basic"
                            label="Color"
                            error={state.color === ''}
                            value={state.color}
                            style={{ width: "535px" }}
                            inputProps={{ sx: { fontSize: "11px" } }}
                            InputLabelProps={{ sx: { fontSize: "17px" }, shrink: true }}
                        />
                    </div>

                    <div style={{ marginTop: "16px", paddingLeft: "80px", marginBottom: "20px" }}>
                        <HTMLRedactor
                            getValue={handlerDescription}
                            initValue={description}
                        />
                    </div>
                </div>

                <div style={{ display: "flex", minWidth: "53px" }} >
                    <ControlStateInCRUDComponent
                        error={error}
                        textErrors={textErrors}
                        handlerReset={handlerReset}
                        handlerSave={handlerSaveUser}
                    />
                </div>
            </div>
        </>
    )
}

export default RegistrationTransportComponent;