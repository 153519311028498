import { AddressResponseDto } from "./AddressModels"

export interface IResponseWarehouseAutocomplete {
   uuid: string,
   name: string,
   contactName: string,
   email: string,
   phone: string,
   status: string,
   types: ITypeWarehouse[]
}

export interface IFavoritesWarehouse {
   uuid: string,
   address: AddressResponseDto,
   contactName: string,
   email: string,
   name: string,
   phone: string,
   reputationLevel: string,
   status: string,
   types: ITypeWarehouse[]
}

export interface IWarehouseDetailsStep {
   uuid: string,
   description: string | null
}

export interface IWarehouseContact {
   uuid: string,
   email: string | null,
   phone: string | null,
   cell: string | null,
   fax: string | null
}

export interface IWarehouseCreateDTO {
   name: string,
   contactName: string,
   typeIds: string[],
   addressId: string
}

export interface IWarehouseRegistrationUpdate {
   uuid: string,
   name: string,
   contactName: string,
   typeIds: string[],
   addressId: string
}

export interface IWarehouseRegistrationDTO {
   uuid: string,
   name: string,
   contactName: string,
   types: ITypeWarehouse[],
   address: AddressResponseDto | null
}

export interface ITypeWarehouse {
   uuid: string,
   name: string
}

export interface WarehouseStatusRequestDto {
   uuid: string,
   status?: string,
   reputationLevel: string
}

export interface IWarehouseStatistic {
   active: number,
   inactive: number,
   incomplete: number
}

export enum EWarehouseStatus {
   ACTIVE = 'ACTIVE',
   INACTIVE = 'INACTIVE',
   INCOMPLETE = 'INCOMPLETE'
}

export enum EWarehouseReputationLevel {
   STANDARD = 'STANDARD',
   POOR = 'POOR',
   VIP = 'VIP'
}

export interface IReviewModel {
   message: string,
   uuid: string,
   mark: number,
   email: string,
   createdDate: number

}

export interface IWarehouseList {
   id?: string,
   uuid: string,
   name: string,
   contactName: string,
   address: AddressResponseDto | null,
   reputationLevel: EWarehouseReputationLevel,
   email: string | null,
   phone: string | null,
   cell: string | null,
   fax: string | null,
   status: EWarehouseStatus,
   types: ITypeWarehouse[]
}

export interface IWarehouseDetails {
   uuid: string,
   name: string,
   contactName: string,
   reputationLevel: string,
   email: string | null,
   phone: string | null,
   metadata: {
      step: number,
      completed: boolean
   },
   readonly: boolean
}

export interface IWarehouseViewDetails {
   name: string,
   contactName: string,
   address: string,
   email: string | null,
   phone: string | null,
   cell: string | null,
   fax: string | null,
   status: EWarehouseStatus,
   reputationLevel: string,
   types: ITypeWarehouse[],
   reviewMark: number,
}

export interface IMarkerInfoWarehouse {
   lat: number,
   lng: number,
   label: string;
   types: ITypeWarehouse[]
   status: string,
   uuid: string,
   reputationLevel: string,
   address?: string;
   addressUUID?: string;
}

export interface IWarehouseIdNameAddressResponseDto {
   uuid: string,
   name: string,
   address: string
}