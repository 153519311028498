import {TMottos } from "../models/IMotto";
import { API } from "./APIService";

class MottoService {
    
    public static getAllMotto = async (): Promise<any> => {
        const [error, response] = await API('GET','motto');
        let mottos : TMottos | undefined;  
        if (response) {
            mottos= response.data.body;            
        }
        return [error, mottos]
    }

    public static saveAllMotto = async (body: TMottos): Promise<any> => {
        const [error, response] = await API('PUT','motto',body);
        return [error, response];
    }

    public static getActiveMottos = async (): Promise<any> => {
        const [error, response] = await API('GET','public/motto');
        let mottos : TMottos | undefined;  
        if (response) {
            mottos= response.data.body;            
        }
        return [error, mottos];        
    }

}

export default MottoService;