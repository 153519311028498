export const isText = (value: string | null) => {
    if (!value) {
        return false;
    }

    if (trim(value).length === 0) {
        return false;
    }

    return true;
}

export const ejectTextWhitError = (e: any) => {
    return JSON.stringify(e.response.data)
}

export const realStringInfo = (value: string | undefined | null) => {
    if (value === 'N/A') return '*';
    if (value) { return value.toLocaleUpperCase().trim(); }
    else {
        return '';
    }
}

export const realStringInfoCoverFace = (value: string | undefined) => {
    if (value === 'N/A') return '';
    if (value) { return value.toLocaleUpperCase().trim(); }
    else {
        return '';
    }
}

export const realNA = (value: string) => {
    if (!value) return '';
    if (value === 'N/A') return '';
    return value.trim();

}

export const curency = 'USD'

export const isStringJSON = (value: string): boolean => {
    try {
        if (!value) {
            return false;
        }

        const json = JSON.parse(value);
        return (typeof json === 'object');
    } catch (ex) {
        return false;
    }
};

export const convertStringToJSON = (value: string): object => {
    return JSON.parse(value);
};

export const trim = (value: string): string => {
    if (!value) {
        return '';
    }

    if (typeof value === 'string') {
        return value.trim();
    }

    if (typeof value === 'number') {
        return value;
    }

    return ''
}

export const isBlank = (value: string | null | undefined): boolean => {
    if (!value) {
        return true;
    }

    if (!!value) {
        return trim(value).length === 0;
    }

    return true;
}

export const isBlankOrNA = (value: string | null | undefined): boolean => {
    return isBlank(value) || value === 'N/A';
}

export const isNumber = (value: number | null | undefined): boolean => {
    if (value === undefined || value === null) {
        return false;
    }

    return !isNaN(value);
}