import { Box } from '@mui/material';
import { useEffect, useState } from 'react'

interface IProps {
    size: number,
    value: string
}

export const TagComponent = (props: IProps) => {

    const [color, setColor] = useState<string>('#000000');
    const [size, setSize] = useState<number>(0);
    const [value, setValue] = useState<string>('');

    useEffect(() => {
        if (props.value.length >= 2) {
            setValue((props.value[0] + props.value[1]).toUpperCase());
            let c1 = (props.value.charCodeAt(0) * 2).toString(16);
            let c2 = (props.value.charCodeAt(1) * 2).toString(16);
            let c3 = (props.value.charCodeAt(1) + props.value.charCodeAt(2)).toString(16);
            setColor('#' + c1 + c2 + c3);
            setSize(props.size);
        }

    }, [props.value])

    return (
        <>
            <Box sx={{
                width: size,
                height: size,
                border: "1px solid #d44d5c",
                borderRadius: "3px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "black",
                fontWeight: "bold",
                fontSize: "9px"
            }}>
                {value}
            </Box>
        </>
    )
}
