import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { IconButton, Rating, Typography, useTheme } from '@mui/material';
import { getDateFormat } from '../../helpers/dateHelper';
import { useEffect, useState } from 'react';
import styles from './customer.module.scss';
import { ICustomerDetails, ISummaryCustomer } from '../../models/CustomerModels';
import CustomerService from '../../services/CustomerService';
import { curency } from '../../helpers/textHelper';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

interface IProps {
    customer: ICustomerDetails | null;
    toStep: (step: number) => void;
}

export default function ViewTableCustomerReport(props: IProps) {
    const { dateFormat } = useSelector((state: RootState) => state.cnfSlice.interfaceState);

    const { customer } = props;
    const theme = useTheme();
    const [summary, setSummary] = useState<ISummaryCustomer | null>()

    useEffect(() => {
        if (customer) {
            (async () => {
                const [error, res] = await CustomerService.getCustomerSummary(customer.uuid);
                if (res) {
                    setSummary(res.data.body);
                }
                if (error) { }
            })()
        }
    }, [customer]);

    const boxSX = {
        color: theme.palette.action.disabled,
        "&:hover": {
            color: theme.palette.primary.main
        }
    }

    return (
        <div>
            <table style={{ width: "100%", marginTop: "10px" }}>
                <tbody>
                    <tr>
                        <td className={styles['but_customer']}>
                            <IconButton size={'small'} onClick={() => { props.toStep(0) }} >
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={styles['label_customer']}>
                            <Typography color={'silver'} variant='subtitle2'>NAME</Typography>
                        </td>
                        <td className={styles['vals_customer']}>
                            <Typography variant='subtitle2'>{summary?.name}</Typography>
                        </td>
                    </tr>

                    <tr>
                        <td className={styles.but_customer} >
                            <IconButton sx={boxSX} size={'small'} onClick={() => { props.toStep(0) }} >
                                <AutoAwesomeIcon fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={styles.label_customer}>
                            <Typography color={'silver'} variant='subtitle2'>IDNO</Typography>
                        </td>
                        <td className={styles.vals_customer}>
                            <Typography variant='subtitle2'>{summary?.idno}</Typography>
                        </td>
                    </tr>

                    <tr>
                        <td className={styles['but_customer']}>
                            <IconButton size={'small'} onClick={() => { props.toStep(1) }} >
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={styles['label_customer']}>
                            <Typography color={summary?.email !== "N/A" && summary?.email ? 'silver' : 'error'} variant='subtitle2'>EMAIL</Typography>
                        </td>
                        <td className={styles['vals_customer']}>
                            <Typography variant='subtitle2'>{summary?.email ? summary?.email : 'N/A'}</Typography>
                        </td>
                    </tr>

                    <tr>
                        <td className={styles['but_customer']}>
                            <IconButton size={'small'} onClick={() => { props.toStep(1) }} >
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={styles['label_customer']}>
                            <Typography color={summary?.phone !== "N/A" && summary?.phone ? 'silver' : 'error'} variant='subtitle2'>PHONE</Typography>
                        </td>
                        <td className={styles['vals_customer']}>
                            <Typography variant='subtitle2'>{summary?.phone ? summary?.phone : 'N/A'}</Typography>
                        </td>
                    </tr>

                    <tr>
                        <td className={styles['but_customer']}>
                            <IconButton size={'small'} onClick={() => { props.toStep(0) }}>
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={styles['label_customer']}>
                            <Typography color={summary?.address ? 'silver' : 'error'} variant='subtitle2' >
                                ADDRESS
                            </Typography>
                        </td>
                        <td className={styles['vals_customer']}>
                            <Typography variant='subtitle2'>
                                {summary?.address ? summary.address : 'N/A'}
                            </Typography>
                        </td>
                    </tr>

                    <tr>
                        <td className={styles['but_customer']}>
                            <IconButton size={'small'} onClick={() => { props.toStep(4) }} >
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={styles['label_customer']}>
                            <Typography color={'silver'} variant='subtitle2'>LAST LOAD</Typography>
                        </td>
                        <td className={styles['vals_customer']}>
                            <Typography variant='subtitle2'>{
                                (summary?.lastLoadDate !== 0 && summary?.lastLoadDate)
                                    ? getDateFormat(summary?.lastLoadDate, dateFormat)
                                    : 'N/A'}
                            </Typography>
                        </td>
                    </tr>


                    <tr>
                        <td className={styles['but_customer']}>
                            <IconButton size={'small'} onClick={() => { props.toStep(5) }} >
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={styles['label_customer']}>
                            <Typography color={'silver'} variant='subtitle2' >
                                STATUS
                            </Typography>
                        </td>
                        <td className={styles['vals_customer']}>
                            <Typography variant='subtitle2'>
                                {summary?.status ? summary?.status.split('_').join(' ') : '*'}
                            </Typography>
                        </td>
                    </tr>

                    <tr>
                        <td className={styles['but_customer']}>
                            <IconButton size={'small'} onClick={() => { props.toStep(5) }} >
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={styles['label_customer']}>
                            <Typography color={'silver'} variant='subtitle2' >
                                REPUTATION
                            </Typography>
                        </td>
                        <td className={styles['vals_customer']}>
                            <Typography variant='subtitle2'>
                                {summary?.reputationLevel ? summary?.reputationLevel.split('_').join(' ') : '*'}
                            </Typography>
                        </td>
                    </tr>

                    <tr>
                        <td className={styles['but_customer']}>
                            <IconButton size={'small'} onClick={() => { props.toStep(3) }} >
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={styles['label_customer']}>
                            <Typography color={'silver'} variant='subtitle2' >
                                REVIEW
                            </Typography>
                        </td>
                        <td className={styles['vals_customer']}>
                            {<Rating precision={0.5} value={summary?.reviewMark} readOnly></Rating>}
                        </td>
                    </tr>

                    <tr>
                        <td className={styles['but_customer']}>
                            <IconButton size={'small'} onClick={() => { props.toStep(4) }} >
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={styles['label_customer']}>
                            <Typography color={'silver'} variant='subtitle2' >
                                TOTAL NUMBER OF LOADS
                            </Typography>
                        </td>
                        <td className={styles['vals_customer']}>
                            <Typography variant='subtitle2'>
                                {summary?.totalNumberOfLoads}
                            </Typography>
                        </td>
                    </tr>

                    <tr>
                        <td className={styles['but_customer']}>
                            <IconButton size={'small'} onClick={() => { props.toStep(4) }} >
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={styles['label_customer']}>
                            <Typography color={'silver'} variant='subtitle2' >
                                NUMBER OF PAYED LOADS
                            </Typography>
                        </td>
                        <td className={styles['vals_customer']}>
                            <Typography variant='subtitle2'>
                                {summary?.numberOfPayedLoads}
                            </Typography>
                        </td>
                    </tr>

                    <tr>
                        <td className={styles['but_customer']}>
                            <IconButton size={'small'} onClick={() => { props.toStep(4) }} >
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={styles['label_customer']}>
                            <Typography color={'silver'} variant='subtitle2' >
                                TOTAL AMOUNT
                            </Typography>
                        </td>
                        <td className={styles['vals_customer']}>
                            <Typography variant='subtitle2'>
                                {`${summary?.totalAmount}  ${curency}`}
                            </Typography>
                        </td>
                    </tr>

                    <tr>
                        <td className={styles['but_customer']}>
                            <IconButton size={'small'} onClick={() => { props.toStep(4) }} >
                                <AutoAwesomeIcon sx={boxSX} fontSize={'small'} />
                            </IconButton>
                        </td>
                        <td className={styles['label_customer']}>
                            <Typography color={'silver'} variant='subtitle2' >
                                PAYED AMOUNT
                            </Typography>
                        </td>
                        <td className={styles['vals_customer']}>
                            <Typography variant='subtitle2'>
                                {`${summary?.payedAmount}  ${curency}`}
                            </Typography>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}