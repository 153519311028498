import { Suspense, useEffect, useState } from "react";
import { Box, Button, CircularProgress, Paper, Snackbar, Step, StepButton, StepContent, Stepper, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material';
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AppDispatch } from "../../store/store";
import { useDispatch } from "react-redux";
import { addNotification, modifyRightFlip, modifyWarehouseRefresh, setLastError } from "../../store/reducers/cnfSlice";
import GridViewIcon from '@mui/icons-material/GridView';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { INotification } from "../../models/NotificationModels";
import { EButtonColor, ENotificationType } from "../../models/EnumGeneral";
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import CancelIcon from '@mui/icons-material/Cancel';

import SecureDialogComponent from "../CustomComponentModule/SecureDialogComponent";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CoverFaceWarehouseComponent from "./CoverFaceWarehouseComponent";
import WarehouseService from "../../services/WarhouseService";
import { IWarehouseContact, IWarehouseDetails, IWarehouseDetailsStep, IWarehouseRegistrationDTO, WarehouseStatusRequestDto } from "../../models/WarehouseModels";
import RegisterWarehouseComponent from "./RegisterWarehouseComponent";
import ContactWarehouseComponent from "./ContactWarehouseComponent";
import DetailsWarehouseComponent from "./DetailsWarehouseComponent";
import StatusWarehouseComponent from "./StatusWarehouseComponent";
import VeiwTableWarehouseReportComponent from "./VeiwTableWarehouseReportComponent";
import ReviewWarehouseComponent from "./ReviewWarehouseComponent";
import { IUserMetadata } from "../../models/IUser";

interface IProps {
  snackMsg: (s: string) => void;
}

export default function EditWarehouseComponent(props: IProps) {

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation().pathname;

  const [activeStep, setActiveStep] = useState(0);
  const [maxStep, setMaxStep] = useState<number>(0);
  const [editMode, setEditMode] = useState('edit');

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [textSnackbar, setTextSnackbar] = useState('');
  const [reset, setReset] = useState(false);

  const [notificationRedirect, setNotificationRedirect] = useState<boolean>(true);
  const [searchParams, setSearchParams] = useSearchParams();

  const [metadata, setMetadata] = useState<IUserMetadata | null>(null);

  const [warehouse, setWarehouse] = useState<IWarehouseDetails | null>(null);
  const [status, setStatus] = useState<WarehouseStatusRequestDto | null>(null);
  const [registrationState, setRegistrationState] = useState<IWarehouseRegistrationDTO | null>(null)
  const [contactState, setContactState] = useState<IWarehouseContact | null>(null)
  const [detailsState, setDetailsState] = useState<IWarehouseDetailsStep | null>(null)


  const snackMsg = (text: string) => {

    let n: INotification = {
      userUUID: " ",
      dt: 0,
      text: [text],
      state: false,
      type: ENotificationType.ADDITIONAL,
      link: location + "?" + "step=" + activeStep.toString(),
    }
    dispatch(addNotification(n))
    setTextSnackbar(text);
    setOpenSnackbar(true);
  }

  const handleEditMode = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string | null,
  ) => {
    if (newValue) {
      setEditMode(newValue);
    }
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const hendlerDeleteWarehouse = () => {
    if (warehouse) {
      (async () => {
        const [error, response] = await WarehouseService.deleteWarehouse(warehouse?.uuid);
        if (response) {
          dispatch(modifyRightFlip(false));
          navigate('/warehouse');
          props.snackMsg('Warehouse ' + warehouse.name + ' was successfully removed.');
          dispatch(modifyWarehouseRefresh());
        }
        if (error) { dispatch(setLastError(error)) }
      })()
    }
  }

  const handleCloseSnackbar = () => { setOpenSnackbar(false); }

  const hendlerToEdit = (step: number) => {
    setActiveStep(step);
    setEditMode('edit');
  }

  useEffect(() => {

    if (id) {
      setContactState(null);
      setRegistrationState(null);
      setStatus(null);
      setDetailsState(null);

      const base = 10
      let step: string | null = searchParams.get("step");;
      if (step) {
        setNotificationRedirect(true);
        setActiveStep(parseInt(step, base));
      } else {
        setNotificationRedirect(false);
      }
      searchParams.delete("step");
      setSearchParams(searchParams);
      (async () => {
        const [error, response] = await WarehouseService.getWarehouse(id);
        if (response) {
          setWarehouse(response.data.body);
          setMetadata(response.data.body.metadata)
          setStatus({ reputationLevel: response.data.body.reputationLevel, uuid: response.data.body.uuid })
          setReset(true);
        }
        if (error) { dispatch(setLastError(error)) }
      })()
    }

  }, [id])

  useEffect(() => {
    (async () => {
      if (id) {
        if (activeStep === 4 && editMode === "edit") {
          const [err, res] = await WarehouseService.getWarehouseStatus(id);
          if (res) { setStatus(res.data.body); }
          if (err) { dispatch(setLastError(err)) }
        }

        if (activeStep === 0 && !registrationState && editMode === "edit") {
          const [error, response] = await WarehouseService.getRegisterDetailsWarehouse(id);
          if (response) {
            setRegistrationState(response.data.body);


          }
        }
        if (activeStep === 1 && !contactState && editMode === "edit") {
          const [error, response] = await WarehouseService.getWarehouseContact(id);
          if (response) {
            let st: IWarehouseContact = response.data.body as IWarehouseContact;
            if (!st.email) { st.email = '' }
            if (!st.phone) { st.phone = '' }
            if (!st.cell) { st.cell = '' }
            if (!st.fax) { st.fax = '' }
            setContactState(st);
          }
        }
        if (activeStep === 2 && !detailsState && editMode === "edit") {
          const [error, response] = await WarehouseService.getWarehouseDetails(id)
          if (response) {
            let body = response.data.body as IWarehouseDetailsStep
            if (!(body.description)) {
              body.description = '<p>...</p>';
            }
            setDetailsState(body)
          }
        }
        if (reset) {
          setReset(false);
        }

      }
    })()
  }, [activeStep, reset, editMode]);


  useEffect(() => {
    if (!notificationRedirect) {
      if (!metadata?.completed) {
        if (metadata?.step) {
          setActiveStep(Number(metadata?.step))
          setMaxStep(Number(metadata?.step))
        } else {
          setActiveStep(0);
        }
      }
    }
  }, [notificationRedirect, metadata])



  const refreshStatusWarehouse = () => {
    (async () => {
      if (id) {
        const [err, res] = await WarehouseService.getWarehouseStatus(id);
        if (res) { setStatus(res.data.body); }
        if (err) { dispatch(setLastError(err)) }

        const [error, response] = await WarehouseService.getWarehouseMetadata(id);
        let data = response.data.body;
        if (data) {
          setMetadata(data);
        }

      }
    })()
  }
  const refreshRegistrationWarehouse = () => {
    (async () => {
      if (id) {
        const [err, res] = await WarehouseService.getRegisterDetailsWarehouse(id);
        if (res) {
          setRegistrationState(res.data.body);
          setWarehouse((st) => {
            if (st) return { ...st, name: res.data.body.name, contactName: res.data.body.contactName }
            else return null;
          })

        }
        if (err) { dispatch(setLastError(err)) }

        const [error, response] = await WarehouseService.getWarehouseMetadata(id);
        let data = response.data.body;
        if (data) {
          setMetadata(data);
        }

      }
    })()
  }
  const refreshContactsWarehouse = () => {
    (async () => {
      if (id) {
        const [err, res] = await WarehouseService.getWarehouseContact(id);
        if (res) {
          let st: IWarehouseContact = res.data.body as IWarehouseContact;
          if (!st.email) { st.email = '' }
          if (!st.phone) { st.phone = '' }
          if (!st.cell) { st.cell = '' }
          if (!st.fax) { st.fax = '' }
          setContactState(st);
        }
        const [error, response] = await WarehouseService.getWarehouseMetadata(id);
        let data = response.data.body;
        if (data) {
          setMetadata(data);
        }

      }
    })()
  }
  const refreshDetailsWarehouse = () => {
    (async () => {
      if (id) {
        const [err, res] = await WarehouseService.getWarehouseDetails(id)
        if (res) {
          let body = res.data.body as IWarehouseDetailsStep
          if (!(body.description)) {
            body.description = '<p>...</p>';
          }
          setDetailsState(body)
        }

        const [error, response] = await WarehouseService.getWarehouseMetadata(id);
        let data = response.data.body;
        if (data) {
          setMetadata(data);
        }

      }
    })()
  }

  return (
    <>

      <div>
        <Paper>
          {<CoverFaceWarehouseComponent
            metadata={metadata ? metadata : null}
            status={status}
            warehouse={warehouse}
          />}
        </Paper>
        <div style={{ display: "flex" }}>
        </div>
      </div>

      {editMode === 'edit' &&
        <div>
          <div style={{ marginTop: "20px", marginLeft: "35px" }}>
            <Stepper nonLinear activeStep={activeStep} orientation="vertical">
              <Step completed={false}>
                <StepButton color="inherit" onClick={handleStep(0)} >
                  {'Registration'.toUpperCase()}
                </StepButton>
                <StepContent>
                  <div>
                    <Suspense fallback={<CircularProgress />}>
                      <Paper sx={{ marginTop: "10px" }}>
                        <Box sx={{ padding: 3, paddingRight: 0, marginBottom: "10px", marginTop: "1", display: "flex" }}>
                          <RegisterWarehouseComponent
                            metadata={metadata ? metadata : null}
                            registration={registrationState ? registrationState : {
                              uuid: '',
                              name: '',
                              contactName: '',
                              types: [],
                              address: null
                            }}
                            handleStep={(e) => { setActiveStep(e) }}
                            snackMsg={snackMsg}
                            refreshAfretSave={refreshRegistrationWarehouse}
                          />
                        </Box>
                      </Paper>
                    </Suspense>
                  </div>
                </StepContent>
              </Step>
              {
                <Step completed={false}>
                  <StepButton color="inherit" onClick={handleStep(1)} disabled={(metadata?.completed) ? false : (maxStep >= 1) ? false : true}>
                    {'Contacts'.toUpperCase()}
                  </StepButton>
                  <StepContent>
                    <div>
                      <Suspense fallback={<CircularProgress />}>
                        <Paper sx={{ marginTop: "10px" }}>
                          <Box sx={{ padding: 3, paddingRight: 0, marginBottom: "10px", marginTop: "1", display: "flex" }}>
                            <div>
                              <ContactWarehouseComponent
                                handleStep={(e) => { setActiveStep(e) }}
                                snackMsg={snackMsg}
                                metadata={metadata ? metadata : null}
                                refreshAfretSave={refreshContactsWarehouse}
                                contact={contactState ? contactState : null}
                                w={warehouse ? warehouse : null}
                              />
                            </div>
                          </Box>
                        </Paper>
                      </Suspense>
                    </div>
                  </StepContent>
                </Step>}
              {
                <Step completed={false}>
                  <StepButton color="inherit" onClick={handleStep(2)}>
                    {'DETAILS'.toUpperCase()}
                  </StepButton>
                  <StepContent>

                    <Paper sx={{ marginTop: "10px" }}>
                      <Box sx={{ padding: 3, paddingRight: 0, marginTop: "1", display: "flex" }}>
                        <DetailsWarehouseComponent
                          handleStep={(e) => { setActiveStep(e) }}
                          metadata={metadata ? metadata : null}
                          w={warehouse ? warehouse : null}
                          details={detailsState ? detailsState : null}
                          snackMsg={snackMsg}
                          refreshAfretSave={refreshDetailsWarehouse}
                        />
                      </Box>
                    </Paper>
                  </StepContent>
                </Step>}
              {/* --------------------- */}
              <Step>
                <StepButton color="inherit" onClick={handleStep(3)}>
                  {'REVIEW'.toUpperCase()}
                </StepButton>
                <StepContent>
                  <Paper sx={{ marginTop: "10px", paddingBottom: "30px" }}>
                    <Box sx={{ padding: 3, paddingRight: 0, marginBottom: "10px", marginTop: "1", display: "flex" }}>
                      <ReviewWarehouseComponent warehouse={warehouse ? warehouse : null} />
                    </Box>
                  </Paper>
                </StepContent>
              </Step>
              {/* --------------------- */}
              {<Step>
                <StepButton color="inherit" onClick={handleStep(4)} disabled={!warehouse?.metadata.completed}>
                  {'STATUS AND REPUTATION'.toUpperCase()}
                </StepButton>
                <StepContent>
                  <Paper sx={{ marginTop: "10px", paddingBottom: "30px" }}>
                    <Box sx={{ padding: 3, paddingRight: 0, marginBottom: "10px", marginTop: "1", display: "flex" }}>
                      <StatusWarehouseComponent
                        refreshAfretSave={refreshStatusWarehouse}
                        metadata={metadata ? metadata : null}
                        stat={status ? status : null}
                        snackMsg={snackMsg}
                        w={warehouse ? warehouse : null}

                      />

                    </Box>
                  </Paper>
                </StepContent>
              </Step>}
              {/* --------------------- */}
            </Stepper>
          </div>

        </div>}

      {editMode === 'view' && warehouse && <VeiwTableWarehouseReportComponent metadata={metadata ? metadata : null} toStep={hendlerToEdit} uuid={id ? id.toString() : ''} />}

      <Paper sx={{ padding: "15px", marginTop: "20px", display: "flex", gap: "10px", justifyContent: "space-between" }} >
        <Box sx={{ display: "flex", gap: "10px" }}>
          <ToggleButtonGroup
            size={'small'}
            value={editMode}
            exclusive
            onChange={handleEditMode}
            aria-label=""
          >
            <ToggleButton value="edit" aria-label="">
              <AutoAwesomeIcon fontSize={'small'} color={editMode === "edit" ? 'warning' : 'inherit'} />
            </ToggleButton>
            <ToggleButton value="view" aria-label="">
              <GridViewIcon fontSize={'small'} color={editMode === "view" ? 'warning' : 'inherit'} />
            </ToggleButton >
          </ToggleButtonGroup>
        </Box>
        <Box sx={{ display: "flex", gap: "10px" }}>
          {!warehouse?.readonly &&
            <Tooltip
              title={'Delete warehouse'}
              arrow={true}
              placement="top"
            >
              <Box>
                <SecureDialogComponent
                  answerYES={hendlerDeleteWarehouse}
                  text={''}
                  icon={<DeleteForeverIcon fontSize='medium' sx={{ marginRight: "5px", marginLeft: "5px" }} />}
                  color={EButtonColor.error}
                  iconButton={false}
                />
              </Box>
            </Tooltip>
          }
        </Box>

      </Paper>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message=""
        anchorOrigin={{ vertical: "top", horizontal: 'center' }}
        action={<Button color="secondary" size="small"> lorem ipsum dolorem </Button>}
      >
        <Paper style={{ display: "flex", padding: "10px", minWidth: "350px", gap: "20px", border: "1px solid silver" }}>
          <div style={{ display: "flex", alignItems: "center", paddingLeft: "15px" }}>
            <GppMaybeIcon color={'success'} fontSize={'large'} />
          </div>
          <div style={{
            display: "flex",
            alignItems: "center",
            minWidth: "250px",
            justifyContent: "center"
          }}>
            <Typography variant='subtitle2' >
              {textSnackbar}
            </Typography>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button onClick={handleCloseSnackbar}><CancelIcon /></Button>
          </div>
        </Paper>
      </Snackbar>
    </>
  );
}