import { Divider, Typography, useTheme } from "@mui/material";
import { IResponseWarehouseAutocomplete } from "../../models/WarehouseModels";
import './../../SearchBar.scss';

interface IProps {
  item: IResponseWarehouseAutocomplete;
  hendlerActiveElement: (item: any) => void;
}

export default function ItemWarehouseSearchListComponent(props: IProps) {
  const { hendlerActiveElement, item } = props;
  const warehouse: IResponseWarehouseAutocomplete = props.item;
  const theme = useTheme();

  return (
    <>
      <div
        className="dataItem"
        style={{ marginTop: "4px", marginBottom: "4px", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "start", gap: "15px", paddingLeft: "10px" }}
        onClick={() => hendlerActiveElement(item)}
      >

        <Typography
          color={theme.palette.primary.main}
          variant={"caption"}>{warehouse.name} ({warehouse.contactName}) </Typography>
      </div>
      <Divider></Divider>
    </>
  );
}

