import { Suspense, useEffect, useState } from "react";
import { Box, Button, CircularProgress, Paper, Snackbar, Step, StepButton, StepContent, Stepper, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { modifyLoadRefresh, modifyRightFlip } from "../../store/reducers/cnfSlice";
import GridViewIcon from '@mui/icons-material/GridView';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { EButtonColor } from "../../models/EnumGeneral";
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import CancelIcon from '@mui/icons-material/Cancel';
import ItemsComponent from "./ItemsComponent";
import { ILoadDetailsDTO } from "../../models/LoadModels";
import CoverFaceLoadComponent from "./CoverFaceLoadComponent";
import LoadService from "../../services/LoadService";
import DeliveryComponent from "./DeliveryComponent";
import DocumentLoadComponent from "./DocumentLoadComponent";
import DetailsLoadComponent from "./DetailsLoadComponent";
import ViewTableLoadReport from "./ViewTableLoad/ViewTableLoadReport";
import RegistrationCustomerComponent from "./RegistrationLoadComponent";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SecureDialogComponent from "../CustomComponentModule/SecureDialogComponent";
import ReviewLoadComponent from "./ReviewLoadComponent";

interface IProps {
  snackMsg: (text: string, loc: string) => void;
}

export default function EditLoadComponent(props: IProps) {

  const [rating, setRating] = useState<number>(0);
  const [message, setMessage] = useState<string>('')
  const [availableForSave, setAvailableForSave] = useState<boolean>(false);

  const [activeStep, setActiveStep] = useState(1);
  const [editMode, setEditMode] = useState('edit');
  const { id } = useParams();
  const { hints } = useSelector((state: RootState) => state.cnfSlice.interfaceState);
  const { refresh } = useSelector((state: RootState) => state.cnfSlice.load);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [textSnackbar, setTextSnackbar] = useState('');
  const [reset, setReset] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation().pathname;

  const [, setNotificationRedirect] = useState<boolean>(true)
  const [searchParams, setSearchParams] = useSearchParams();

  const [existItem, setExistItem] = useState(false);

  const [load, setLoad] = useState<ILoadDetailsDTO | null>(null);
  const refreshAfretSave = () => { setReset(!reset); }

  const snackMsg = (text: string) => {
    props.snackMsg(text, location + "?" + "step=" + activeStep.toString());
  }

  const handleEditMode = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string | null,
  ) => {
    if (newValue) {
      setEditMode(newValue);
    }
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleCloseSnackbar = () => { setOpenSnackbar(false); }

  const hendlerToEdit = (step: number) => {
    setActiveStep(step);
    setEditMode('edit');
  }

  const hendlerDeleteLoad = () => {
    if (id) {
      (async () => {
        const [error, response] = await LoadService.DeleteLoad(id?.toString());
        if (response) {
          dispatch(modifyRightFlip(false));
          navigate('/load');
          snackMsg(`Load ${load?.idno} was successfully removed.`);
          dispatch(modifyLoadRefresh());
        }
        if (error) { }
      })()
    }
  }

  useEffect(() => {
    if (id) {
      const base = 10
      let step: string | null = searchParams.get("step");;
      if (step) {
        setNotificationRedirect(true);
        setActiveStep(parseInt(step, base));
      } else {
        setNotificationRedirect(false);
      }
      searchParams.delete("step");
      setSearchParams(searchParams);

      (async () => {
        const [error, response] = await LoadService.getLoadDetails(id?.toString());
        if (response) {
          setLoad(response.data.body);
          dispatch(modifyRightFlip(true));
        }
        if (error) { }
      })()
    }
  }, [id, reset, dispatch])

  useEffect(() => {
    let err = false;

    if (rating === 0) err = true;

    if (message.length < 3) err = true;
    if (message.length >= 255) err = true;

    setAvailableForSave(!err)
  }, [rating, message]);

  return (
    <>
      <div>
        <Paper>
          {<CoverFaceLoadComponent load={load} />}
        </Paper>
        <div style={{ display: "flex" }}>
        </div>
      </div>

      {editMode === 'edit' &&
        <div>
          <div style={{ marginTop: "20px", marginLeft: "35px" }}>
            <Stepper nonLinear activeStep={activeStep} orientation="vertical">
              <Step completed={false}>
                <StepButton color="inherit" onClick={handleStep(0)}>
                  {'REGISTRATION'.toUpperCase()}
                </StepButton>
                <StepContent>
                  <div>
                    <Suspense fallback={<CircularProgress />}>
                      <Paper sx={{ marginTop: "10px" }}>
                        <Box sx={{ padding: 3, paddingRight: 0, marginBottom: "10px", marginTop: "1", display: "flex" }}>
                          <RegistrationCustomerComponent
                            handleStep={(e) => { setActiveStep(e) }}
                            snackMsg={snackMsg}
                            refreshAfretSave={refreshAfretSave}
                            load={load ? load : null}
                          />
                        </Box>
                      </Paper>
                    </Suspense>
                  </div>
                </StepContent>
              </Step>

              <Step completed={false}>
                <StepButton color="inherit" onClick={handleStep(1)}>
                  {'ITEMS'.toUpperCase()}
                </StepButton>
                <StepContent>
                  <div>
                    <Suspense fallback={<CircularProgress />}>
                      <Paper sx={{ marginTop: "10px" }}>
                        <Box sx={{ padding: 3, paddingRight: 0, marginBottom: "10px", marginTop: "1", display: "flex" }}>
                          <ItemsComponent
                            handleStep={(e) => { setActiveStep(e) }}
                            snackMsg={snackMsg}
                            refreshAfterSave={refreshAfretSave}
                            loadId={id?.toString() ? id?.toString() : ""}
                            existItem={(b: boolean) => setExistItem(b)}
                          />
                        </Box>
                      </Paper>
                    </Suspense>
                  </div>
                </StepContent>
              </Step>

              {
                <Step completed={false}>
                  <StepButton color="inherit" onClick={handleStep(2)} disabled={(!load?.metadata.completed && !existItem)}>
                    {'PICKUP / DELIVERY '.toUpperCase()}
                  </StepButton>
                  <StepContent>
                    <div>
                      <Suspense fallback={<CircularProgress />}>
                        <Paper sx={{ marginTop: "10px" }}>
                          <Box sx={{ padding: 3, paddingRight: 0, marginBottom: "10px", marginTop: "1", display: "flex" }}>
                            <div>
                              <DeliveryComponent
                                handleStep={(e) => { setActiveStep(e) }}
                                snackMsg={snackMsg}
                                refreshAfretSave={refreshAfretSave}
                              />
                            </div>
                          </Box>
                        </Paper>
                      </Suspense>
                    </div>
                  </StepContent>
                </Step>}

              {
                <Step completed={false}>
                  <StepButton color="inherit" onClick={handleStep(3)}>
                    {'DOCUMENTS '.toUpperCase()}
                  </StepButton>
                  <StepContent>

                    <Paper sx={{ marginTop: "10px", paddingBottom: "30px" }}>
                      <Box sx={{ padding: 3, paddingRight: 0, marginBottom: "10px", marginTop: "1", display: "flex" }}>
                        <div>
                          <DocumentLoadComponent
                            handleStep={(e) => { setActiveStep(e) }}
                            load={load}
                            snackMsg={snackMsg}
                            refreshAfretSave={refreshAfretSave}
                          />
                        </div>
                      </Box>
                    </Paper>
                  </StepContent>
                </Step>}

              <Step completed={false}>
                <StepButton color="inherit" onClick={handleStep(4)}>
                  {'DETAILS'.toUpperCase()}
                </StepButton>
                <StepContent>

                  <Paper sx={{ marginTop: "10px", paddingBottom: "30px" }}>
                    <Box sx={{ padding: 3, paddingRight: 0, marginBottom: "10px", marginTop: "1", display: "flex" }}>
                      <div>
                        <DetailsLoadComponent
                          handleStep={(e) => { setActiveStep(e) }}
                          load={load}
                          snackMsg={snackMsg}
                          refreshAfretSave={refreshAfretSave}
                        />
                      </div>
                    </Box>
                  </Paper>
                </StepContent>
              </Step>
              <Step completed={false}>
                <StepButton color="inherit" onClick={handleStep(5)}>
                  {'REVIEW'.toUpperCase()}
                </StepButton>
                <StepContent>

                  <Paper sx={{ marginTop: "10px", paddingBottom: "30px" }}>
                    <Box sx={{ padding: 3, paddingRight: 0, marginBottom: "10px", marginTop: "1", display: "flex" }}>
                      <div>
                        <ReviewLoadComponent
                          load={load}
                        />
                      </div>
                    </Box>
                  </Paper>
                </StepContent>
              </Step>
            </Stepper>
          </div>
        </div >}

      {editMode === 'view' && load && <ViewTableLoadReport toStep={hendlerToEdit} load={load} />}

      <Paper sx={{ padding: "15px", marginTop: "20px", display: "flex", gap: "10px", justifyContent: "space-between" }} >
        <Box sx={{ display: "flex", gap: "10px" }}>

          <ToggleButtonGroup
            size={'small'}
            value={editMode}
            exclusive
            onChange={handleEditMode}
            aria-label=""
          >
            <ToggleButton value="edit" aria-label="">
              <AutoAwesomeIcon fontSize={'small'} color={editMode === "edit" ? 'warning' : 'inherit'} />
            </ToggleButton>
            <ToggleButton value="view" aria-label="">
              <GridViewIcon fontSize={'small'} color={editMode === "view" ? 'warning' : 'inherit'} />
            </ToggleButton >
          </ToggleButtonGroup>

        </Box>
        <Box sx={{ display: "flex", gap: "10px" }}>
          {!load?.readonly &&
            <SecureDialogComponent
              answerYES={hendlerDeleteLoad}
              text={''}
              icon={<DeleteForeverIcon fontSize='medium' sx={{ marginRight: "5px", marginLeft: "5px" }} />}
              color={EButtonColor.error}
              iconButton={false}
            />
          }
        </Box>
      </Paper>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message=""
        anchorOrigin={{ vertical: "top", horizontal: 'center' }}
        action={<Button color="secondary" size="small"> lorem ipsum dolorem </Button>}
      >

        <Paper style={{ display: "flex", padding: "10px", minWidth: "350px", gap: "20px", border: "1px solid silver" }}>
          <div style={{ display: "flex", alignItems: "center", paddingLeft: "15px" }}>
            <GppMaybeIcon color={'success'} fontSize={'large'} />

          </div>
          <div style={{
            display: "flex",
            alignItems: "center",
            minWidth: "250px",
            justifyContent: "center"
          }}>
            <div>
              <Typography variant='subtitle2' >
                {textSnackbar}
              </Typography>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button onClick={handleCloseSnackbar}><CancelIcon /></Button>
          </div>
        </Paper>
      </Snackbar>
    </>
  );
}