import { Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import Hue from "react-color/lib/components/hue/Hue";
import st from './EditColorComponent.module.scss';

interface IProps {
  type?: number;
  currentColor: string;
  getColor: (s: string) => void;
}

const EditColorComponent = (props: IProps) => {

  const { currentColor, getColor } = props;
  const [state, setState] = useState(
    {
      displayColorPicker: false,
      color: '#ffa'
    }
  )

  const handleClick = () => {
    setState({ ...state, displayColorPicker: !state.displayColorPicker })
  };

  const handleClose = () => {     
    setState({ ...state, displayColorPicker: false })
  };

  const handleChange = (ob: any) => {
    let color = ob.hex;
    setState({ ...state, color: color })
    getColor(color);
  };

  useEffect(() => {
    setState({
      ...state,
      color: currentColor
    })
  }, [currentColor]);

  const styles = {
    color: {
      width: '30px',
      height: '30px',
      borderRadius: '5px',
      background: state.color,
      border: "1px solid silver"
    }
  }

  return (
    <>
      <Paper style={{ width: "43px", height: '44px', paddingTop: '2px' }}>
        <div style={{ fontSize: "12px", textAlign: "center" }}>
        </div>
        <div className={st.swatch} onClick={handleClick}>
          <div style={styles.color} />
        </div>
        {state.displayColorPicker ? <div className={st.popover} >
          <div className={st.cover} onClick={handleClose} />
          {props.type === 1
            ? <div style={{marginTop:"-30px", marginLeft:"72px" }}>
              <Hue color={state.color} onChangeComplete={handleChange} /></div>
            : <SketchPicker color={state.color} onChangeComplete={handleChange} />}
        </div> : null}
      </Paper>
    </>
  )
}

export default EditColorComponent;