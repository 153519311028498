import i18next from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { Box, Button, MenuItem, TextField, Tooltip } from "@mui/material";
import { ObjectToValueArray, compareValue } from "../../../helpers/generalHelper";
import SCViewList from "../../../styledComponents/sharedComponent/SCViewList";
import { IGlobalProfile } from "../../../models/ProfileModels";
import SecureDialogComponent from "../../CustomComponentModule/SecureDialogComponent";
import { EButtonColor } from "../../../models/EnumGeneral";
import { IInterfaceState } from "../../../models/IUser";

interface IProps {
    stateLocal: IInterfaceState;
    getNewInterfaceState: (state: IInterfaceState) => void;
}

const LanguageSettingsComponent = (props: IProps) => {
    const { stateLocal, getNewInterfaceState } = props

    const { hints } = useSelector((state: RootState) => state.cnfSlice.interfaceState);

    const languages = ['en', 'ru'];
    const [state, setState] = useState<IInterfaceState>(stateLocal);
    const [disabled, setDisabled] = useState<boolean>(true);

    useEffect(() => {
        setState(stateLocal);
    }, [stateLocal]);

    useEffect(() => {
        setDisabled(compareValue(state, stateLocal));

    }, [stateLocal, state]);

    const handlerLanguage = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value as string;
        setState((st) => { return { ...st, language: value } })
    }

    const handleSaveButton = () => {
        getNewInterfaceState(state);
        i18next.changeLanguage(state.language);
    }
    return (
        <SCViewList>
            <SCViewList width={5}>

            </SCViewList>
            <SCViewList column gap={40}>

                <TextField
                    select
                    value={state.language}
                    inputProps={{ sx: { fontSize: "12px", } }}
                    label="Language"
                    sx={{ width: "200px" }}
                    onChange={handlerLanguage}
                >
                    {(languages).map((option: string, i) =>
                        <MenuItem key={i} value={option}>
                            {option.toUpperCase()}
                        </MenuItem>)}
                </TextField>
                <SCViewList flexEnd width={90}>
                    <Tooltip
                        title={(hints && !disabled) ? "Save global profile configurations" : ""}
                        placement="top"
                        arrow={true}
                    >
                        <Box>
                            <SecureDialogComponent
                                answerYES={() => handleSaveButton()}
                                text={'SAVE'}
                                iconButton={false}
                                color={EButtonColor.primary}
                                disabled={disabled}
                            />
                        </Box>
                    </Tooltip>
                </SCViewList>
            </SCViewList>
        </SCViewList>

    )
}
export default LanguageSettingsComponent;