
export interface INotification  {
	userUUID: string,
	dt: number,
	text: string[],
	state: boolean,
	type: ENotificationType,
	links:string,
	check:boolean
}



export enum ENotificationType {
    REQUARED='REQUARED',
    ADDITIONAL='ADDITIONAL',
    ERROR='ERROR'	
}
