import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, TextField, Tooltip, useMediaQuery, useTheme } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';

import SaveAsIcon from '@mui/icons-material/SaveAs';
import CloseIcon from '@mui/icons-material/Close';
import { useDebounce } from '../../hooks/useDebounce';

import { useNavigate } from 'react-router-dom';
import { setLastError } from '../../store/reducers/cnfSlice';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import SearchCustomerComponent from '../CustomerModule/SearchCustomerComponent';
import { ITypeWarehouse, IWarehouseCreateDTO } from '../../models/WarehouseModels';

import { IResponseRoutesAutocomplete, IRouteCreateDto } from '../../models/RoutesModels';
import RouteServices from '../../services/RouteServices';
import { dateToYMD, toTimestamp } from '../../helpers/dateHelper';
import SearchRouteComponent from './SearchRouteComponent';



const styled = { icon: { fontSize: "28px", cursor: "pointer" } }

export default function RoutesButtonBarComponent() {

    const { hints } = useSelector((state: RootState) => state.cnfSlice.interfaceState);
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [openN, setOpenN] = useState(false);
    const [save, setSave] = useState(false);
    const [value, setValue] = useState<IWarehouseCreateDTO | null>(null);
    const [isValidName, setIsValidName] = useState<boolean>(true);

    const [state, setState] = useState<IRouteCreateDto>({
        name: '',
        startDate: Date.now(),
        endDate: Date.now()
    })

    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const debouncedName = useDebounce(state.name, 300);


    const handlerChangeName = (e: any) => {
        let value: string = (e.target.value as string);
        setState(st => ({ ...st, name: value }))
    }

    const handlerStartDate = (event: any) => {
        let value = event.target.value;
        setState((st) => { return { ...st, startDate: value ? toTimestamp(value) : Date.now() } })
    }

    const handlerEndDate = (event: any) => {
        let value = event.target.value;
        setState((st) => { return { ...st, endDate: value ? toTimestamp(value) : Date.now() } })
    }

    const handleClickOpen = () => { setOpen(true); };
    const handleClose = () => {
        setOpen(false);

    };
    const hendlerActiveElement = (item: IResponseRoutesAutocomplete) => {
        handleClose();
        navigate('/routes/' + item.uuid)
        // dispatch(addFavoritesCustomers([item.uuid]));
    }

    const hendlerList = (list: ITypeWarehouse[]) => {
        let t: string[] = [];
        for (let e of list) { t.push(e.uuid); }
        setState(st => ({ ...st, typeIds: t }))
    }

    const handlerAddNewRoute = () => {
        setOpenN(true);
    }
    const handleCloseN = () => {
        setOpenN(false);
        setState({
            name: '',
            startDate: Date.now(),
            endDate: Date.now()
        })
        setIsValidName(true)
    }
    const handlerSaveCustomer = () => { setSave(true); }

    useEffect(() => {
        if (save) {
            (async () => {
                const body: IRouteCreateDto = { ...state };
                const [error, response] = await RouteServices.createRoute(body);
                if (response) {
                    let uuid = response.data.response.entityId;
                    handleCloseN();
                    navigate('/routes/' + uuid);
                    // dispatch(modifyRightFlip(true));
                    // dispatch(modifyCustomerRefresh());
                }
                if (error) { }
            })()
        }
        setSave(false);
    }, [save])



    useEffect(() => {
        if (debouncedName) {
            if (state.name.length >= 6) {
                (async () => {
                    const [error, response] = await RouteServices.checkRouteName(state.name.trim());
                    if (response) {
                        const value: boolean = response.data.body as boolean;
                        setIsValidName(value);
                    }
                    if (error) {
                        dispatch(setLastError(error))
                    }
                })()
            } else {
                setIsValidName(true);
            }
        }
    }, [debouncedName])

    const isValidCreate = useCallback((): boolean => {
        return state.name.trim().length < 6 || !isValidName || isValidDate();
    }, [isValidName, state])

    const isValidDate = useCallback((): boolean => {
        return ((dateToYMD(state.endDate) !== dateToYMD(state.startDate)) && (state.endDate < state.startDate) && state.endDate !== null && state.startDate !== null)
    }, [isValidName, state])




    return (
        <div>

            <Box sx={{ marginBottom: "5px" }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "5px" }}>
                    <div style={{ display: "flex", gap: "5px" }}>

                        <Tooltip
                            title={(hints) ? t("") : ''}
                            placement={"top"}
                            arrow={true}
                        >
                            <IconButton onClick={handleClickOpen}>
                                <ManageSearchIcon style={styled.icon} color={"primary"} />
                            </IconButton>
                        </Tooltip>

                        <Tooltip
                            title={(hints) ? t("") : ''}
                            placement={"top"}
                            arrow={true}
                        >
                            <IconButton onClick={handlerAddNewRoute} >
                                <DashboardCustomizeIcon style={styled.icon} color={"primary"} />
                            </IconButton>
                        </Tooltip>



                    </div>

                </div>


                <Dialog
                    fullScreen={fullScreen}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                        { }
                    </DialogTitle>
                    <DialogContent>
                        <div style={{ minHeight: "350px", minWidth: "330px" }}>
                            <SearchRouteComponent
                                hendlerActiveElement={hendlerActiveElement}
                                placeholder={'Search routes..'}
                            ></SearchRouteComponent>
                        </div>

                        <DialogContentText>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    fullScreen={fullScreen}
                    maxWidth={'xl'}
                    open={openN}
                    onClose={handleCloseN}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                        {"ADD NEW ROUTE"}
                    </DialogTitle>
                    <DialogContent>
                        <div style={{ display: "flex" }}>
                            <div style={{ paddingTop: "20px" }}>
                                <div style={{ display: "flex", gap: "18px", alignItems: "start", justifyContent: "" }}>
                                    <Box sx={{ width: '300px', display: "flex", flexDirection: "row" }}>

                                        <Box style={{ width: "350px", display: "flex", flexDirection: "column", gap: "45px", paddingTop: "10px" }}>


                                            <TextField
                                                autoComplete={'off'}
                                                error={state.name.length <= 5 || !isValidName}
                                                disabled={value !== null && (!value?.name)}
                                                value={state.name}
                                                label="Route Name"
                                                onChange={handlerChangeName}
                                                style={{ width: "300px" }}
                                                inputProps={{ sx: { fontSize: "16px" } }}
                                                InputLabelProps={{ sx: { fontSize: "17px" } }}
                                                helperText={!isValidName ? '* this route name exists' : ''}
                                            />

                                            <TextField
                                                error={isValidDate()}
                                                label={"Start Date"}
                                                type="date"
                                                value={dateToYMD(state.startDate)}
                                                onChange={handlerStartDate}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{ sx: { fontSize: "14px", widht: "300px" } }}
                                            />
                                            <TextField
                                                error={isValidDate()}
                                                helperText={isValidDate() ? '* start date is later then end date' : ''}
                                                label={"End Date"}
                                                type="date"
                                                onChange={handlerEndDate}
                                                value={dateToYMD(state.endDate)}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{ sx: { fontSize: "14px", widht: "300px" } }}
                                            />

                                        </Box>

                                    </Box>
                                </div>
                            </div>
                        </div>
                        <DialogContentText>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>


                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: "20px" }}>
                            <Button
                                variant={'contained'}
                                disabled={isValidCreate()}
                                size={'small'}
                                onClick={handlerSaveCustomer} startIcon={<SaveAsIcon />}>{('Create a route').toUpperCase()}</Button>
                            <Button
                                startIcon={<CloseIcon />}
                                variant={'contained'}
                                color={'warning'}
                                size={'small'}
                                onClick={handleCloseN}>Close</Button>

                        </div>
                    </DialogActions>
                </Dialog>



            </Box>
            <Divider></Divider>
            <Box sx={{ height: "10px" }}></Box>

        </div>
    );
}




