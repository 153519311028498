import { convertStringToJSON, isBlank, isStringJSON } from "../helpers/textHelper";
import { EAuditModule, IAuditUserResponseDTO, IAuditFilter } from "../models/AuditModels";
import { IHistory } from "../models/NotificationModels";
import { API } from "./APIService";
import GenericCRUDService from "./GenericCRUDService";

const historyActions = "hQ";
const max = 50;

class AuditService {
    public static deleteOneAudit = (uuid: number) => {
        let s = localStorage.getItem(historyActions)
        if (s) {
            let n: IHistory[] = []
            try {
                n = [...JSON.parse(s)].filter((e: IHistory) => e.dt !== uuid);
                localStorage.setItem(historyActions, JSON.stringify(n));
            } catch (error) {
                console.error(error);
            }
        }
    }

    public static deleteHistoryAudit = (user: string) => {
        localStorage.setItem(historyActions, JSON.stringify([]));
    }

    public static getAllHistoryAudit = (user: string): Array<IHistory> => {
        let s = localStorage.getItem(historyActions)
        if (s) {
            let n: IHistory[] = []
            try {
                n = [...JSON.parse(s)].filter((e: IHistory) => e.userUUID === user);
                localStorage.setItem(historyActions, JSON.stringify(n));
            } catch (error) {
                console.error(error);
            }
            return n;
        } else {
            return []
        }
    }

    public static setHistoryAudit = async (m: IHistory, user: string) => {
        let n: IHistory[] = [];
        try {
            n = this.getAllHistoryAudit(user).filter((e: IHistory) => e.text !== m.text);
        } catch (error) {
            console.error(error);
        }
        ;
        if (n) {
            n = [...[m], ...n];
            if (n.length > max) { n = n.slice(0, max); }
            localStorage.setItem(historyActions, JSON.stringify(n));
        }
    }

    public static auditFilter = async (body: Object): Promise<any> => {
        const [error, response] = await API('POST', 'audit/all', JSON.stringify(body));
        return [error, response];
    }

    public static auditModule = async (): Promise<any> => {
        const [error, response] = await API('GET', 'audit/module/all');
        return [error, response];
    }

    public static downloadAuditReport = (criteriaFilter: object) => {
        GenericCRUDService.downloadReport('audit/report', criteriaFilter);
    }

    public static buildCriteriaFilter = (page: number, size: number, filter: IAuditFilter): Object => {
        let criteriaFilter: any = {
            criteria: [],
            paging: {
              page: page,
              size: size
            }
          };
      
          if (filter.times.t1 && filter.times.t2 && filter.times.t1 < filter.times.t2) {
            criteriaFilter.criteria.push({ property: "date", value: filter.times.t1 + ',' + filter.times.t2, expression: "betweenDate" });
          }
      
          if (filter.v1.length > 0) {
            criteriaFilter.criteria.push({ property: "user.uuid", value: filter.v1.join(','), expression: "inor" });
          }
      
          if (filter.v2.length > 0) {
            criteriaFilter.criteria.push({ property: "action", value: filter.v2.join(','), expression: "inor" });
          }
          if (filter.v3.length > 0) {
            criteriaFilter.criteria.push({ property: "module", value: filter.v3.join(','), expression: "inor" });
          }
          return criteriaFilter;
    };

    public static getUserFullNameOrEmail = (user: IAuditUserResponseDTO): string => {
        const firstName: string | undefined = user.firstName,
            lastName = user.lastName,
            email = user.email;

        return isBlank(firstName) ? email : `${firstName} ${lastName}`;
    };

    public static getAuditValueAsLink = (module: EAuditModule, businessItemId?: string): string => {
        switch (module) {
            case EAuditModule.USER:
                return '/user/' + businessItemId;
            case EAuditModule.GLOBAL_PREFERENCE:
                return '/settings/global_profile'
            case EAuditModule.MOTTO:
                return '/settings/quotes'
            default:
                return '';
        };
    };

    public static buildDiffsValue = (oldValue: string, newValue: string) => {
        if (isBlank(oldValue) && isBlank(newValue)) {
            return null;
        }

        const isOldValueJson: boolean = isStringJSON(oldValue),
            isNewValueJson: boolean = isStringJSON(newValue);

        let response: { fieldName: string, leftValue: string, rightValue: string }[] = [];

        if (!isOldValueJson && !isNewValueJson) {
            response.push(this.buildDiffsValueObject(null, oldValue, newValue));
            return response;
        }

        if (isBlank(oldValue) && !isBlank(newValue)) {
            const rightObject: any = convertStringToJSON(newValue);
            for (const key in rightObject) {
                const valueStr: string = rightObject[key];

                if (!isBlank(valueStr)) {
                    response.push(this.buildDiffsValueObject(key, null, valueStr));
                }
            }

            if (response.length > 0) {
                return response;
            }
        }

        if (!isBlank(oldValue) && !isBlank(newValue)) {
            const leftObject: any = convertStringToJSON(oldValue),
                rightObject: any = convertStringToJSON(newValue);
            for (const key in rightObject) {
                const rightValueStr: string = rightObject[key],
                    leftValueStr: string = leftObject[key];

                if (!(isBlank(leftValueStr) && isBlank(rightValueStr)) && leftValueStr !== rightValueStr) {
                    response.push(this.buildDiffsValueObject(key, leftValueStr, rightValueStr));
                }
            }

            if (response.length > 0) {
                return response;
            }
        }

        return null;
    };

    private static buildDiffsValueObject = (key: string | null, leftValue: string | null, rightValue?: string | null): any => {
        return {
            fieldName: !!key ? key.replaceAll('_', ' ').toUpperCase() : 'VALUE',
            leftValue: leftValue || '',
            rightValue: rightValue || ''
        };
    }
}

export default AuditService;