import styled from '@emotion/styled'

interface IProps {
    column?: boolean;
    gap?: number;
}
const SCViewTab = styled.div<IProps>`
    border: 1px solid silver;
    padding:10px;
    display: flex;
    flex-direction: row;
    gap:${(props) => props.gap ? props.gap : 0}px;      
    .item{ 
        font-size: 18px;
        display: flex;
        justify-content: center;
        &:nth-of-type(1) {
            &:hover {
                cursor: pointer;
            }
            font-size: 16px;
            width: 38%;
            color: blue;
            text-decoration: underline;
        }

        &:nth-of-type(2) {
            width:30%;
        }
        
        &:nth-of-type(3) {
            width:30%;
            border-radius: 25px;
            background: #FFA392;
        }
    }
`
export default SCViewTab;