import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { Tooltip } from "@mui/material";

type typeTooltipPlacement = "bottom-end" | "bottom-start" | "bottom" | "left-end" | "left-start" | "left" | "right-end" | "right-start" | "right" | "top-end" | "top-start" | "top"

interface IProps {
    text: string,
    icon: JSX.Element,
    tooltipPlacement: typeTooltipPlacement
}

const TooltipedIconsHelper = (props:IProps) => {
    const {text, tooltipPlacement, icon}  = props
    const { hints } = useSelector((state: RootState) => state.cnfSlice.interfaceState);
    return (
        <>
            <Tooltip
                title={(hints) ? text : ""}
                arrow={true}
                placement={tooltipPlacement}
            >
                {icon}
            </Tooltip>



        </>
    )
}
export default TooltipedIconsHelper;