import { Divider, Typography, useTheme } from "@mui/material";
import { IResponseAutocompleteAuto } from "../../models/TransportModels";
import './../../SearchBar.scss';
import { AnyPhoto } from "../StafModule/GridStafComponent";
import AvatarComponent from "../CustomComponentModule/AvatarComponent";

interface IProps {
  item: IResponseAutocompleteAuto;
  hendlerActiveElement: (item: any) => void;
}

export default function ItemTransportInSearchListComponent(props: IProps) {

  const { hendlerActiveElement, item } = props;
  const auto: IResponseAutocompleteAuto = props.item;
  const theme = useTheme();

  return (
    <>
      <div
        className="dataItem"
        style={{ marginTop: "4px", marginBottom: "4px", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "start", gap: "15px", paddingLeft: "10px" }}
        onClick={() => hendlerActiveElement(item)}
      >
        <div style={{ width: "5px", height: "30px", background: auto.color }}></div>
        {(auto.mainImage)
          ?  <AvatarComponent link={auto.mainImage} w={30} h={30} />
          : <AnyPhoto />}
        <Typography
          color={theme.palette.primary.main}
          variant={"caption"}>{auto.licensePlate} [ {auto.brand +' '+auto.model}]</Typography>        
      </div>
      <Divider></Divider>
    </>
  );
}