import { useTranslation } from "react-i18next";
import { Divider, Box, Button } from '@mui/material';
import { modifyLoadRole } from "../../store/reducers/cnfSlice";
import { AppDispatch, RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import LoadFilterStatusComponent from "./LoadFilterStatusComponent";

export default function LoadFilterComponent() {

  const { t } = useTranslation();
  const { statuses } = useSelector((statuses: RootState) => statuses.cnfSlice.load);
  const dispatch = useDispatch<AppDispatch>();

  const hendlerList = (list: string[]) => {
    dispatch(modifyLoadRole(list));
  }

  return (
    <>
      <LoadFilterStatusComponent getList={hendlerList} list={statuses} />

      <Divider />

      <Box sx={{ display: "flex", marginTop: "10px", justifyContent: "center" }} >
        <Button
          size="small"
          variant={'contained'}
          disabled={statuses.length === 0}
          onClick={() => { dispatch(modifyLoadRole([])); }}
          sx={{ marginLeft: "5px", width: "100%" }}>
          {t('Reset')}
        </Button>
      </Box>
    </>
  );
}