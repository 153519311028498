import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import PrivilegesCrud from './PrivilegesCrud';
import ChoosePermissionForRoleCrud from './ChoosePermissionForRoleCrud';
import CloseIcon from '@mui/icons-material/Close';


const styled = {
    icon: { fontSize: "28px", cursor: "pointer" }
}
interface IProps {
    reset: () => void;
    snackMsg: (text: string) => void,
}
export default function PermissionButtonBarComponent(props: IProps) {
    const { reset } = props
    const { hints } = useSelector((state: RootState) => state.cnfSlice.interfaceState);
    const { t } = useTranslation();
    const [openL, setOpenL] = useState(false);
    const [openP, setOpenP] = useState(false);
    const [edit, setEdit] = useState<boolean>(false);
    const [newState, setNewState] = useState<boolean>(false);



    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleOpenLinkRole = () => {
        setOpenL(true);
    }
    const handleCloseLinkRole = () => {
        setOpenL(false);
        reset();
    }
    const handleOpenPrivilegesCrud = () => {
        setOpenP(true);
    }
    const handleClosePrivilegesCrud = () => {
        setOpenP(false);
    }
    const getSnackMessage = (s: string) => {
        props.snackMsg(s)
    }
    const handleGetEditStatus = (b:boolean) => {
        setEdit(b);
    }
    const handleGetNewStatus = (b:boolean) => {
        setNewState(b);
    }
    return (
        <div>

            <Box sx={{ marginBottom: "5px" }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", gap: "5px" }}>

                        <Tooltip
                            title={(hints) ? t("Link privileges with role") : ''}
                            placement={"top"}
                            arrow={true}
                        >
                            <IconButton onClick={handleOpenLinkRole}>
                                <DashboardCustomizeIcon style={styled.icon} color={"primary"} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            title={(hints) ? t("Privilege management") : ''}
                            placement={"top"}
                            arrow={true}
                        >
                            <IconButton onClick={handleOpenPrivilegesCrud}>
                                <SettingsSuggestIcon style={styled.icon} color={"primary"} />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>



                <Dialog
                    fullScreen={fullScreen}
                    open={openL}
                    onClose={handleCloseLinkRole}
                    maxWidth="lg"
                >
                    <DialogTitle>{` ROLE PRIVILEGES`}</DialogTitle>

                    <DialogContent>
                        <ChoosePermissionForRoleCrud snackMsg={getSnackMessage} />
                    </DialogContent>
                    <DialogActions sx={{ padding: "25px", paddingTop: "0px" }} >

                        <Button
                            startIcon={<CloseIcon />}
                            color={'warning'}
                            variant={'contained'}
                            size={'small'}
                            onClick={handleCloseLinkRole}>Close
                        </Button>
                    </DialogActions>
                </Dialog>




                <Dialog
                    fullScreen={fullScreen}
                    open={openP}
                    onClose={handleClosePrivilegesCrud}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle>{newState?"CREATE PRIVILEGE":edit?"EDIT PRIVILEGE":`PRIVILEGE MANAGEMENT`}</DialogTitle>

                    <DialogContent>
                        <PrivilegesCrud isEditable={handleGetEditStatus} isNew={handleGetNewStatus} snackMsg={getSnackMessage} />
                    </DialogContent>
                    <DialogActions sx={{ padding: "25px", paddingTop: "0px" }} >


                        <Button
                            startIcon={<CloseIcon />}
                            color={'warning'}
                            variant={'contained'}
                            size={'small'}
                            onClick={handleClosePrivilegesCrud}>Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
            <Divider></Divider>
            <Box sx={{ height: "10px" }}></Box>
        </div>
    );
}


