import { useEffect, useState } from "react";
import { deliveryStateList, ILoadDeliveryResponseDto } from "../../models/LoadModels";
import { ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material';
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import { modifyLoadRefresh } from "../../store/reducers/cnfSlice";
import EditableTableComponent from "./DeliveryComponent/EditableDeliveryComponent";
import LoadService from "../../services/LoadService";
import { DeliveryMapComponent } from "./DeliveryMapComponent";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { curency } from "../../helpers/textHelper";
import PublicIcon from '@mui/icons-material/Public';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';

interface IProps {
    item: ILoadDeliveryResponseDto | null;
    items: ILoadDeliveryResponseDto[];
    snap: string;
    stateList: deliveryStateList[];
    editableMode: boolean;
    save: boolean;
    snackMsg: (text: string) => void;
    setError: (n: number) => void;
    onSave: () => void;
    refreshAfterSave: () => void
}

const EditDeliveryComponent = (props: IProps) => {
    const { item, items, save, stateList, setError } = props
    const dispatch = useDispatch<AppDispatch>();
    const [delivery, setDelivery] = useState<ILoadDeliveryResponseDto>();
    const [selectionModel, setSelectionModel] = useState<Array<string>>([]);
    const [view, setView] = useState('form');
    const [newSelectionMode, setNewSelectionModel] = useState<boolean>(false);

    useEffect(() => {
        setSelectionModel([item?.uuid ? item?.uuid : ""])
    }, [item]);

    const getDeliveryContent = (c: ILoadDeliveryResponseDto) => {
        setDelivery(c);
    }

    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        newView: string,
    ) => {
        if (newView) setView(newView);
    };

    useEffect(() => {
        if (save) {

            let bodyC = [];
            let bodyU = [];
            if (delivery) {

                for (let s of stateList) {
                    if (selectionModel.includes(s.itemUuid) && !s.deliveryUuid) {
                        let c = {
                            uuid: s.itemUuid,
                            deliveryAddressId: delivery.deliveryAddress?.uuid,
                            pickupAddressId: delivery.pickupAddress?.uuid,
                            pickupWarehouseId: delivery.pickupWarehouse?.uuid,
                            deliveryWarehouseId: delivery.deliveryWarehouse?.uuid,
                            deliveryType: delivery.deliveryType,
                            deliveredBy: delivery.deliveredBy,
                            description: delivery.description
                        }
                        bodyC.push(c);
                    }

                    if (selectionModel.includes(s.deliveryUuid) && s.deliveryUuid) {
                        let c = {
                            uuid: s.deliveryUuid,
                            deliveryAddressId: delivery.deliveryAddress?.uuid,
                            pickupAddressId: delivery.pickupAddress?.uuid,
                            pickupWarehouseId: delivery.pickupWarehouse?.uuid,
                            deliveryWarehouseId: delivery.deliveryWarehouse?.uuid,
                            deliveryType: delivery.deliveryType,
                            deliveredBy: delivery.deliveredBy,
                            description: delivery.description
                        }
                        bodyU.push(c);
                    }
                }
            }

            if (bodyU.length > 0) {
                (async () => {
                    const [, data] = await LoadService.updateDeliveryForItem(bodyU)
                    if (data) {
                        dispatch(modifyLoadRefresh());
                        props.snackMsg('Load item pickup & delivery was successfully updated.')
                        props.refreshAfterSave();
                    }
                })()
            }
            props.onSave();
        }
    }, [save])


    const handleError = (n: number) => {
        if (n === 1 && selectionModel.length !== 0) {
            setError(1);
        } else { setError(0) }
    }

    useEffect(() => {
        if (selectionModel.length === 0 || selectionModel.length === 1 && selectionModel[0] === item?.uuid) {
            setNewSelectionModel(false)
        } else {
            setNewSelectionModel(true)
        }
    }, [selectionModel]);

    const columns: GridColDef[] = [

        {
            field: "2", headerName: 'NAME', sortable: false, filterable: false, disableColumnMenu: true, width: 140, headerAlign: "center",
            renderCell: (cellValues: any) => {
                return (
                    <>
                        <Typography variant={'body2'} fontSize={'11px'}>{cellValues.row.loadItem.name} </Typography>
                    </>
                );
            }
        },
        {
            field: "3", headerName: 'IDNO', sortable: false, filterable: false, disableColumnMenu: true, width: 115, headerAlign: "center",
            renderCell: (cellValues: any) => {
                return (
                    <>
                        <Typography variant={'body2'} fontSize={'11px'}>{cellValues.row.loadItem.idno} </Typography>
                    </>
                );
            }
        },
        {
            field: "4", headerName: 'UNITS', sortable: false, filterable: false, disableColumnMenu: true, width: 65, headerAlign: "center",
            renderCell: (cellValues: any) => {
                return (
                    <>
                        <Typography variant={'body2'} fontSize={'11px'}>{cellValues.row.loadItem.units} </Typography>
                    </>
                );
            }
        },
        {
            field: "5", headerName: 'AMOUNT', sortable: false, filterable: false, disableColumnMenu: true, width: 95, headerAlign: "center",
            renderCell: (cellValues: any) => {
                return (
                    <>
                        <Tooltip
                            title={cellValues.row.loadItem.amount + ' ' + curency}
                            arrow={true}
                        >
                            <Typography variant={'body2'} fontSize={'11px'}>{cellValues.row.loadItem.amount + ' ' + curency} </Typography>
                        </Tooltip>
                    </>
                );
            }
        }
    ];

    return (
        <>
            <div style={{ display: "flex", flexDirection: "column", marginTop: "20px" }} >
                <div style={{ display: "flex", flexDirection: "row", gap: "40px" }}>
                    <div>
                        <EditableTableComponent newSelModel={newSelectionMode} editableMode={props.editableMode} getDeliveryContent={getDeliveryContent} setError={handleError} delivery={item} />
                    </div>

                    {view === 'map' &&
                        <div style={{ width: "100%" }}>
                            <DeliveryMapComponent delivery={delivery ? delivery : undefined} />
                        </div>
                    }

                    {view === 'form' &&
                        <div style={{
                            flex: "1 1 auto",
                            display: "flex",
                            flexDirection: "column",
                            maxWidth: "486px",
                            gap: "20px",
                            borderLeft: "1px dashed silver",
                            paddingLeft: "30px",
                            justifyContent: "ce"
                        }}>

                            <div style={{ height: "361px", width: "500px" }}>
                                <DataGrid
                                    rows={items}
                                    columns={columns}
                                    disableSelectionOnClick
                                    hideFooter
                                    checkboxSelection
                                    onSelectionModelChange={(newSelectionModel: any) => {
                                        setSelectionModel(newSelectionModel);
                                    }}
                                    selectionModel={selectionModel}
                                    isRowSelectable={() => props.editableMode}
                                />
                            </div>
                        </div>}
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <ToggleButtonGroup
                            color="primary"
                            value={view}
                            orientation="vertical"
                            exclusive
                            onChange={handleChange}
                            aria-label="Platform"
                        >
                            <ToggleButton value="map"><PublicIcon /></ToggleButton>
                            <ToggleButton value="form"><DisplaySettingsIcon /></ToggleButton>
                        </ToggleButtonGroup>
                    </div>

                </div>
            </div>
        </>
    )
}
export default EditDeliveryComponent