import { useTranslation } from "react-i18next";
import { Box, IconButton, Link, Tooltip } from '@mui/material';
import StarsIcon from '@mui/icons-material/Stars';
import { AppDispatch, RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { modifyRightFlip } from "../../store/reducers/cnfSlice";
import { useNavigate } from "react-router";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { addFavoritesLoads } from "../../store/reducers/favoritesSlice";
import { ELoadStatus, ILoadDetailsDTO } from "../../models/LoadModels";
import DownloadIcon from '@mui/icons-material/Download';
import GenericCRUDService from "../../services/GenericCRUDService";

interface IPropsCoverFaceUserComponent {
  load: ILoadDetailsDTO | null
}

const CoverFaceLoadComponent = (
  props: IPropsCoverFaceUserComponent
) => {
  const { load } = props;
  const { t } = useTranslation();
  const { hints } = useSelector((state: RootState) => state.cnfSlice.interfaceState);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleClose = () => {
    dispatch(modifyRightFlip(false));
    navigate('/load');
  }

  const handlerAddToFavorite = () => {
    if (load) {
      dispatch(addFavoritesLoads([load.uuid]));
    }
  }

  const hendlerExcel = () => {
    let body = {
      criteria: [
        {
          property: "uuid",
          value: load?.uuid,
          expression: "EQUALS"
        }
      ]
    };

    GenericCRUDService.downloadReport('load/report', body)
  }

  return (
    <>
      <Box sx={{ padding: 2, marginTop: "1" }}>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", fontSize: '13px' }}>
          <div style={{ display: "flex", gap: "10px", justifyContent: "space-between" }}>
            <div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>
                  <b>{t('LOAD IDNO')}:</b>&nbsp;
                  {load?.idno.toUpperCase()}</div>
                <div>
                  <b>{t('CUSTOMER')}:</b>&nbsp;
                  <Link style={{cursor: 'pointer'}} onClick={() => { navigate('/customer/' + load?.customer.uuid) }} underline="hover" >
                    {load?.customer.name}
                  </Link>
                </div>
              </div>
            </div>
            <div style={{ marginLeft: '10px', borderLeft: '1px dashed silver', paddingLeft: '10px' }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>
                  <b>{t('STATUS')}:</b>&nbsp;
                  <span style={{ color: load?.status === ELoadStatus.INCOMPLETE ? 'red' : 'black' }}>{load?.status.toUpperCase()}</span>
                </div>
              </div>
            </div>
          </div>

          <div style={{ gap: "10px", height: "40px", borderLeft: "1px dashed silver", paddingLeft: '10px' }}>
            <Tooltip
              title={(hints) ? t("Add load to favorites") : ''}
              placement={"top"}
              arrow={true}
            >
              <IconButton onClick={handlerAddToFavorite}>
                <StarsIcon />
              </IconButton>
            </Tooltip>

            <Tooltip
              title={(hints) ? t("Download XLS report for selected load") : ''}
              placement={"top"}
              arrow={true}
            >
              <IconButton onClick={hendlerExcel}>
                <DownloadIcon />
              </IconButton>
            </Tooltip>

            <Tooltip
              title={(hints) ? t("Close and return to the list of loads") : ''}
              placement={"top"}
              arrow={true}
              style={{ marginLeft: '50px' }}
            >
              <IconButton onClick={handleClose} >
                <HighlightOffIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </Box>
    </>
  )
}

export default CoverFaceLoadComponent;