import { API } from "./APIService";

class WarehouseService {

    public static getWarehouseReview = async (uuid: string): Promise<any> => {
        const [error, response] = await API('GET', 'warehouse/review/' + uuid);
        return [error, response]
    }
    public static getAutocomplete = async (uuid: string): Promise<any> => {
        const [error, response] = await API('GET', 'warehouse/autocomplete/' + uuid);
        return [error, response]
    }

    public static deleteWarehouse = async (uuid: string): Promise<any> => {
        const [error, response] = await API('DELETE', 'warehouse/' + uuid);
        return [error, response]
    }

    public static saveWarehouseStatus = async (body: any): Promise<any> => {
        const [error, response] = await API('PUT', 'warehouse/status', body);
        return [error, response]
    }

    public static getWarehouseStatus = async (uuid: string): Promise<any> => {
        const [error, response] = await API('GET', 'warehouse/status/' + uuid);
        return [error, response]
    }

    public static getWarehouseSummary = async (uuid: string): Promise<any> => {
        const [error, response] = await API('GET', 'warehouse/summary/' + uuid);
        return [error, response]
    }

    public static getWarehouseDetails = async (id: string): Promise<any> => {
        const [error, response] = await API('GET', 'warehouse/details/' + id);
        return [error, response]
    }

    public static saveWarehouseDetails = async (body: any): Promise<any> => {
        const [error, response] = await API('PUT', 'warehouse/details', body);
        return [error, response]
    }

    public static saveWarehouseContact = async (body: any): Promise<any> => {
        const [error, response] = await API('PUT', 'warehouse/contact', body);
        return [error, response]
    }

    public static getWarehouseContact = async (id: string): Promise<any> => {
        const [error, response] = await API('GET', 'warehouse/contact/' + id);
        return [error, response]
    }

    public static getWarehouseMetadata = async (id: string): Promise<any> => {
        const [error, response] = await API('GET', 'warehouse/metadata/' + id);
        return [error, response]
    }


    public static saveWarehouseStep = async (body: any): Promise<any> => {
        const [error, response] = await API('PUT', 'warehouse/step', body);
        return [error, response]
    }

    public static saveWarehouseRegister = async (body: any): Promise<any> => {
        const [error, response] = await API('PUT', 'warehouse/registration', body);
        return [error, response]
    }

    public static getRegisterDetailsWarehouse = async (id: string): Promise<any> => {
        const [error, response] = await API('GET', '/warehouse/registration/' + id);
        return [error, response]
    }

    public static warehouseType = async (): Promise<any> => {
        const [error, response] = await API('GET', 'warehouse/type');
        return [error, response]
    }

    public static warehouseStatistics = async (): Promise<any> => {
        const [error, response] = await API('GET', 'warehouse/count');
        return [error, response]
    }

    public static warehouseNameAviable = async (name: string, addressId: string): Promise<any> => {
        const [error, response] = await API('GET', 'warehouse/name/available/' + name + '/' + addressId);
        return [error, response]
    }

    public static createWarehouse = async (body: any): Promise<any> => {
        const [error, response] = await API('POST', 'warehouse', JSON.stringify(body));
        return [error, response]
    }

    public static getWarehouses = async (body: any): Promise<any> => {
        const [error, response] = await API('POST', 'warehouse/all', JSON.stringify(body));
        return [error, response]
    }

    public static getWarehouse = async (id: string): Promise<any> => {
        const [error, response] = await API('GET', 'warehouse/' + id);
        return [error, response]
    }
    public static getPendingWarehouse = async (): Promise<any> => {
        const [error, response] = await API('GET', 'warehouse/pending');
        return [error, response]
    }
    public static getFavorites = async (): Promise<any> => {
        const [error, response] = await API('GET', 'warehouse/favorite');
        return [error, response];
    }

    public static createFavorites = async (body: string[]): Promise<any> => {
        const [error, response] = await API('POST', 'warehouse/favorite', JSON.stringify(body));
        return [error, response];
    }

    public static deleteFavorites = async (body: string[]): Promise<any> => {
        const [error, response] = await API('DELETE', 'warehouse/favorite', body);
        return [error, response];
    }
}

export default WarehouseService;