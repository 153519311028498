import { useEffect, useState } from "react";
import ControlStateInCRUDComponent from "../CustomComponentModule/ControlStateInCRUDComponent";
import HTMLRedactor from "../CustomComponentModule/HTMLRedactor";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import { setLastError } from "../../store/reducers/cnfSlice";
import { ILoadDetailsDTO, ILoadDetailsStep } from "../../models/LoadModels";
import LoadService from "../../services/LoadService";

interface IProps {
    load: ILoadDetailsDTO | null;
    refreshAfretSave: () => void;
    snackMsg: (text: string) => void;
    handleStep: (step: number) => void;
}

const DetailsLoadComponent = (props: IProps) => {
    const { load } = props
    const [state, setState] = useState<ILoadDetailsStep>({
        uuid: '',
        description: ''
    })
    const [save, setSave] = useState<boolean>(false);

    const dispatch = useDispatch<AppDispatch>();
    const [snapshot, setSnapshot] = useState<string>("");
    const [error, setError] = useState<number>(0);
    const [description, setDescription] = useState('');

    const handlerSaveUser = () => { setSave(true); }
    const handlerReset = () => {
        setDescription('')
        let st = JSON.parse(snapshot);
        setState(st);
    }
    const handlerDescription = (s: string) => {
        if (state) {
            setState((st) => { return { ...st, description: s } })
        }
    }

    useEffect(() => {
        if (save) {
            (async () => {
                let bodyC = { description: state.description, uuid: state.uuid }
                const [error, response] = await LoadService.saveLoadDetails(bodyC)
                if (response) {
                    setSnapshot(JSON.stringify({ ...state }));
                    props.snackMsg('Details was successfully saved for load ' + load?.idno);
                    setError(0)
                }
                if (error) { dispatch(setLastError(error)) }
            })()
        }
        setSave(false);
    }, [save])

    useEffect(() => {
        (async () => {
            if (load?.uuid) {
                const [error, response] = await LoadService.getLoadDetailsStep(load?.uuid ? load?.uuid : '')
                if (response) {
                    let body = response.data.body as ILoadDetailsStep
                    if (!body.description) {
                        body.description = '<p>...</p>';
                    }
                    setSnapshot(JSON.stringify(body));
                    setState(body)
                    setDescription(body.description ? body.description : "");
                }
                if (error) { dispatch(setLastError(error)) }
            }
        })()
    }, [load])

    useEffect(() => {
        if (JSON.stringify(state) === snapshot) {
            setError(0)
        } else setError(1)

    }, [state])

    return (
        <>
            <div style={{ display: "flex", gap: 10, width: "620px" }}>
                <div style={{ width: "80%" }}>
                    <HTMLRedactor
                        getValue={handlerDescription}
                        initValue={description}
                    />
                </div>
                <div style={{ display: "flex", minWidth: "20%" }} >
                    <ControlStateInCRUDComponent
                        error={error}
                        textErrors={[]}
                        handlerReset={handlerReset}
                        handlerSave={handlerSaveUser}
                    />
                </div>
            </div>
            <br />
        </>
    )
}
export default DetailsLoadComponent;