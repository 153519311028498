import { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import Breadcrumbs from '@mui/material/Breadcrumbs';

import { modifyURLState } from '../../store/reducers/cnfSlice';
import { useDispatch, useSelector } from 'react-redux';
import { IHistory } from '../../models/NotificationModels';
import HistoryListService from '../../services/HistoryListService';
import { RootState } from '../../store/store';

function handleClick(event: any) {
    event.preventDefault();
}

export default function IconBreadcrumbs() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { auth } = useSelector((state: RootState) => state.cnfSlice);

    const location = useLocation().pathname
    const [path, setPath] = useState<string>('');

    useEffect(() => {
        const resolvedPath = location.replaceAll('/', ' / ');
        setPath(resolvedPath);

        if (auth && location !== '/') {
            let h: IHistory = {
                userUUID: auth.username,
                dt: Date.now(),
                text: location,
                link: location
            }
            HistoryListService.setHistory(h, auth.username);
        }

        dispatch(modifyURLState(location));
    }, [location, dispatch, auth]);

    return (
        <>
            <div role="presentation" onClick={handleClick}>
                <Breadcrumbs aria-label="breadcrumb">
                    {<Typography sx={{ color: 'white', fontSize: "13px" }} variant={"body1"}>{t(path).toUpperCase()}</Typography>}
                </Breadcrumbs>
            </div>
        </>
    );
}