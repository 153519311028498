const sets = {
  production: {
    baseURL: "https://www.ft.klmbtransportation.com/api/",
    redirectURL:"https://www.ft.klmbtransportation.com",
    socketURL: "wss://ft.klmbtransportation.com/api/ws"
  },
  preproduction: {
    baseURL: "https://www.ft.wbmmbw.click/api/",
    redirectURL:"https://www.ft.wbmmbw.click",
    socketURL: "wss://ft.wbmmbw.click/api/ws"
  },
  development: {
    baseURL: "http://localhost:8081/api/",
    redirectURL:"http://localhost:3000",
    socketURL: "ws://localhost:8081/api/ws"
  }
};

const profile = process.env.REACT_APP_PROFILE ? process.env.REACT_APP_PROFILE as keyof typeof sets  : "development";
export const settings = sets[profile];