import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CalculateIcon from '@mui/icons-material/Calculate';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { IResponseAutocomplete } from "../../../models/IUser";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import StarsIcon from '@mui/icons-material/Stars';

import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { modifyRightFlip, modifyWarehousePage, modifyWarehouseRefresh, modifyWarehouseSizePage } from "../../../store/reducers/cnfSlice";
import { randomPhoto } from "../../../helpers/generalHelper";
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import ActivateDetailsComponent from "../../CustomComponentModule/ActivateDetailsComponent";
import React from "react";
import ReportUserComponent from "../../ReportsModule/ReportUserComponent";
import CloseIcon from '@mui/icons-material/Close';
import { getTokens } from "../../../services/TokensService";
import { getDateFormat } from "../../../helpers/dateHelper";
import RouteServices from "../../../services/RouteServices";
import { IRouteDetails } from "../../../models/RoutesModels";
import SCViewList from "../../../styledComponents/sharedComponent/SCViewList";
import { curency } from "../../../helpers/textHelper";

export default function GridRouteComponent() {

  const { t } = useTranslation();
  const { id } = useParams();


  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();


  const { refresh, page, size, status, state } = useSelector((state: RootState) => state.routesSlice);
  const { hints, dateFormat } = useSelector((state: RootState) => state.cnfSlice.interfaceState);

  const [checkState, setCheckState] = useState(true);
  const [selectionModel, setSelectionModel] = useState<Array<string>>([]);

  const [statePdfSelect, setStatePdfSelect] = useState<IResponseAutocomplete[]>([])
  const [open, setOpen] = React.useState(false);

  const [rowRoute, setRowRoute] = useState<IRouteDetails[]>([]);
  const [pageSizeC, setPageSizeC] = useState(size);
  const [pageC, setPageC] = useState(page);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTtalRows] = useState(0);
  const { accessToken } = getTokens();


  const hendlerExcel = () => {
    // axios({
    //   url: 'http://localhost:8081/api/user/report',
    //   method: 'POST',
    //   headers: {
    //     "Authorization": accessToken?.trim() !== '' ? "Bearer " + accessToken : '',
    //     "Content-Type": "application/json"
    //   },
    //   data: {},
    //   responseType: 'blob',
    // }).then((response) => {
    //   const url = window.URL.createObjectURL(new Blob([response.data]));
    //   const link = document.createElement('a');
    //   link.href = url;
    //   link.setAttribute('download', 'file.xls');
    //   document.body.appendChild(link);
    //   link.click();
    //   document.removeChild(link);
    // });
  }

  const hendlerEmailList = () => {
    // let url = 'https://mail.google.com/mail/?view=cm&fs=1';
    // let to = '&to=';
    // let bcc = '&bcc=';
    // if (auth) {
    //   bcc += auth.email
    // }
    // for (let elm of rowWarehouse) {
    //   if (selectionModel.includes(elm.uuid)) {
    //     to += elm.email + ','
    //   }
    // }
    // window.open(url + to + bcc);
  }

  const hendlerFavoritesList = () => {
    // let list: string[] = [];
    // for (let elm of rowWarehouse) {
    //   if (selectionModel.includes(elm.uuid)) {
    //     list.push(elm.uuid);
    //   }
    // }
    // dispatch(addFavoritesWarehouses(list));
  }

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const hendlerPdfReportList = () => {
    // let list: IResponseAutocomplete[] = [];
    // for (let elm of rowUsers) {
    //   if (selectionModel.includes(elm.uuid)) {

    //     let u: IResponseAutocomplete = {
    //       uuid: elm.uuid,
    //       firstName: elm.firstName,
    //       lastName: elm.lastName,
    //       email: elm.email,
    //       username: elm.username,
    //       avatar: elm.avatar,
    //       color: elm.color
    //     }
    //     list.push(u);
    //   }
    // }
    // setStatePdfSelect(list);
    // handleOpen();
  }

  const handlerRefresh = () => {
    dispatch(modifyWarehouseRefresh());
  }

  const handlerPage = (newPage: number) => {
    dispatch(modifyWarehousePage(newPage));
    setPageC(newPage);
  }

  const handlerPageSize = (newPageSize: number) => {
    dispatch(modifyWarehouseSizePage(newPageSize));
    setPageSizeC(newPageSize);
  }

  const handlerCheck = () => {
    setSelectionModel([]);
    setCheckState((v) => { return !v });
  }

  const handleClick = (cellValues: any) => {
    navigate('/routes/' + cellValues.row.id);
    dispatch(modifyRightFlip(true));
    if (!selectionModel.includes(cellValues.row.id.toString())) {
      // setSelectionModel(list => [cellValues.row.id, ...list])
    }
  }



  useEffect(() => {

    setLoading(true);

    (async () => {

      const [error, response] = await RouteServices.getRoutes(state, status, page, size);
      if (response) {

        let rs = response.data.body;
        setTtalRows(response.data.total);
        if (rs) {
          const st: IRouteDetails[] = rs.map((e: IRouteDetails) => (
            {
              ...e,
              id: e.uuid
            })
          )
          setRowRoute(st);
        }
      }
      if (error) { }
      setLoading(false);
    })()
  }, [status, state, page, size, refresh])

  useEffect(() => {
    if (id) {
      if (!selectionModel.includes(id.toString())) {
        setSelectionModel(list => [id, ...list]);
      }
    }
  }, [id])

  const columns: GridColDef[] = [
    {
      field: "2", headerName: '', width: 50, sortable: false, filterable: false, disableColumnMenu: true,
      renderCell: (cellValues: any) => {
        return (
          <>
            <div style={{ display: "flex", textAlign: "center", justifyContent: "center", padding: 0 }}>
              <IconButton
                size={'small'}
                onClick={(event: any) => { handleClick(cellValues); }}
              >
                <AutoAwesomeIcon color={"disabled"} />
              </IconButton>
              <ActivateDetailsComponent
                activate={() => handleClick(cellValues)}
              />

            </div>
          </>
        );
      }
    },
    {
      field: 'NAME', headerName: t('NAME'), width: 200,
      renderCell: (cellValues: any) => {
        return (
          <>
            <Typography variant={'body2'} fontSize={'11px'}>{cellValues.row.name} </Typography>
          </>
        );
      }
    },
    {
      field: 'START', headerName: t('START'), width: 120,
      renderCell: (cellValues: any) => {
        return (
          <>
            <Typography variant={'body2'} fontSize={'11px'}>{getDateFormat(cellValues.row.startDate, dateFormat)} </Typography>
          </>
        );
      }
    },
    {
      field: 'END', headerName: t('END'), width: 120,
      renderCell: (cellValues: any) => {
        return (
          <>
            <Typography variant={'body2'} fontSize={'11px'}>{getDateFormat(cellValues.row.endDate, dateFormat)} </Typography>
          </>
        );
      }
    },
    {
      field: 'STATUS', headerName: 'STATUS', width: 130,
      renderCell: (cellValues: any) => {
        return (
          <>
            <Typography variant={'body2'} fontSize={'11px'}>
              {(cellValues.row.status as string).split('_').join(' ')}
            </Typography>
          </>
        );
      }
    },
    {
      field: 'STATE', headerName: 'STATE', width: 150,
      renderCell: (cellValues: any) => {
        return (
          <>
            <Typography variant={'body2'} fontSize={'11px'}>
              {(cellValues.row.state as string).split('_').join(' ')}
            </Typography>
          </>
        );
      }
    },
    {
      field: 'AMOUNT', headerName: 'AMOUNT', width: 150,
      renderCell: (cellValues: any) => {
        return (
          <>
            <Typography variant={'body2'} fontSize={'11px'}>
              {cellValues.row.amount + ' ' + curency}
            </Typography>
          </>
        );
      }
    },
    {
      field: 'PROGRESS', headerName: 'PROGRESS', width: 100,
      renderCell: (cellValues: any) => {
        return (
          <>
            <Typography variant={'body2'} fontSize={'11px'}>
              {cellValues.row.progress.completed + ' of ' + cellValues.row.progress.total}
            </Typography>
          </>
        );
      }
    },
  ];
  const styled = {
    container: { display: "flex", gap: "20px" },
    item: { width: "40px" },
    box: { border: "1px solid silver", textAlign: "center", borderRadius: "3px" },
    icon: { fontSize: "28px", cursor: "pointer" },
    button: { border: "1px solid silver" }
  }

  return (
    <>
      <SCViewList alignCenter height="48px" >

        <div style={{ width: "400px", paddingLeft: "7px" }}>
          <SCViewList gap={10} >
            <Tooltip
              title={(hints) ? t("favorites") : ''}
              placement={"top"}
              arrow={true}
            >
              <IconButton onClick={handlerCheck} size='small'>
                <PlaylistAddCheckCircleIcon style={styled.icon} />
              </IconButton>
            </Tooltip>
            {(selectionModel.length > 0) &&
              <div>
                <Tooltip
                  title={(hints) ? t("favorites") : ''}
                  placement={"top"}
                  arrow={true}
                >
                  <IconButton
                    onClick={hendlerFavoritesList}
                  >
                    <StarsIcon style={styled.icon} color={"primary"} />
                  </IconButton>
                </Tooltip>


                <Tooltip
                  title={(hints) ? t("email to all selected") : ''}
                  placement={"top"}
                  arrow={true}
                >
                  <IconButton

                    onClick={hendlerEmailList}
                  >
                    <MarkEmailReadIcon style={styled.icon} color={'inherit'} />
                  </IconButton>
                </Tooltip>

                <Tooltip
                  title={(hints) ? t("pdf file whith list") : ''}
                  placement={"top"}
                  arrow={true}
                >

                  <IconButton

                    onClick={hendlerPdfReportList}
                  >
                    <PictureAsPdfIcon style={styled.icon} color={'inherit'} />
                  </IconButton>
                </Tooltip>

                <Tooltip
                  title={(hints) ? t("export to sheets") : ''}
                  placement={"top"}
                  arrow={true}
                  onClick={hendlerExcel}
                >
                  <IconButton >
                    <CalculateIcon style={styled.icon} color={'inherit'} />
                  </IconButton>
                </Tooltip>
              </div>
            }
            <Tooltip
              title={(hints) ? t("refresh") : ''}
              placement={"top"}
              arrow={true}
            >
              <IconButton onClick={handlerRefresh} size='small'>
                <ReplayCircleFilledIcon style={styled.icon} />
              </IconButton>
            </Tooltip>

          </SCViewList>

        </div>
      </SCViewList>


      <div style={{ height: "92%" }}>
        <DataGrid
          density={'compact'}
          paginationMode="server"
          loading={loading}
          rows={rowRoute}
          columns={columns}

          rowCount={totalRows}
          page={pageC}
          pageSize={pageSizeC}

          rowsPerPageOptions={[5, 10, 15, 20, 25]}
          onPageSizeChange={handlerPageSize}
          onPageChange={handlerPage}

          checkboxSelection={checkState}
          onSelectionModelChange={(newSelectionModel: any) => {
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
        />
      </div>

      {/* {<pre>
        {JSON.stringify(selectionModel, undefined, 2)}
      </pre>} */}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={'lg'}

      >
        <DialogTitle>{`Pdf Report`}</DialogTitle>
        <DialogContent>
          <ReportUserComponent user={statePdfSelect} />
        </DialogContent>
        <DialogActions>
          <div style={{ padding: "20px" }}>
            <SCViewList flexEnd>

              <Button
                startIcon={<CloseIcon />}
                color={'inherit'}
                variant={'contained'}
                size={'small'}
                onClick={handleClose}>Close</Button>
            </SCViewList>

          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}

const AnyPhoto = () => {
  return (
    <Avatar sx={{ width: "30px", height: "30px" }} src={randomPhoto()} />
  )
}