import { IconButton, Link, Rating, Typography } from '@mui/material';
import { Box } from '@mui/system';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLastError } from '../../store/reducers/cnfSlice';
import { RootState } from '../../store/store';
import { getDateFormat } from '../../helpers/dateHelper';
import SCViewList from '../../styledComponents/sharedComponent/SCViewList';
import { ICustomerDetails, IReviewModel } from '../../models/CustomerModels';
import CustomerService from '../../services/CustomerService';
import { useNavigate } from 'react-router-dom';

interface IProps {
    customer: ICustomerDetails | null
}
const ReviewCustomerComponent = (props: IProps) => {
    const { customer } = props
    const { dateFormat } = useSelector((state: RootState) => state.cnfSlice.interfaceState);

    const [state, setState] = useState<IReviewModel[]>([])
    const [actualView, setActualView] = useState<number>(0)
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            if (customer) {
                const [error, response] = await CustomerService.getCustomerReview(customer.uuid);
                if (response) { setState(response.data.body); }
                if (error) { dispatch(setLastError(error)) }
            }
        })()
    }, [customer]);

    const handlePreviusView = () => {
        setActualView(actualView - 1);
    }
    const handleForwardView = () => {
        setActualView(actualView + 1);
    }
    const handleRedirectLoad = (s: string) => {
        navigate('/load/' + s)
    }
    return (
        <>
            <Box sx={{ display: "flex" }}>
                {(actualView !== 0 && state.length >= 1) ?
                    <Box sx={{ display: "flex", justifyContent: "left", alignItems: "center", width: "50px" }}>
                        <IconButton onClick={handlePreviusView}>
                            <ArrowBackIosNewIcon />
                        </IconButton>
                    </Box>
                    : <Box sx={{ width: "50px" }} />
                }
                {(state.length >= 1)
                    ? <Box sx={{ display: "flex", flexDirection: "column", gap: "20px", width: "450px", padding: "30px" }}>
                        <SCViewList gap={10} spaceBetween>
                            <SCViewList column>
                                <Typography>{(state[actualView].email) ? state[actualView].email : ""}</Typography>
                                {
                                    (state[actualView].mark)
                                        ? <Rating value={state[actualView].mark} precision={0.5} readOnly></Rating>
                                        : <div></div>
                                }
                            </SCViewList>
                            <div style={{ display: "flex", flexDirection: "column", minWidth: "100px", paddingLeft: "10px", borderLeft: "1px solid silver" }}>
                                {
                                    (state[actualView].createdDate)
                                        ? getDateFormat(state[actualView].createdDate, dateFormat)
                                        : ''
                                }
                                <Link style={{ marginTop: '5px', fontSize: "13px" }} href='#' underline="hover" onClick={() => { handleRedirectLoad(state[actualView].loadId) }}>{(state[actualView].loadIdno) ? state[actualView].loadIdno : ""}</Link>
                            </div>
                        </SCViewList>
                        <div style={{ border: "1px solid silver", padding: "10px", borderRadius: "3px", minHeight: "50px", fontSize: "10px" }}>
                            {
                                (state[actualView].message)
                                    ? state[actualView].message
                                    : ''
                            }
                        </div>
                    </Box>
                    : <div style={{ border: "1px dashed silver", display: "flex", padding: "10px", borderRadius: "3px", minHeight: "50px", width: "500px", fontSize: "15px", textAlign: "center", alignItems: "center", justifyContent: "center" }}>There are no reviews.</div>

                }

                {((state.length - 1) > actualView) &&
                    <Box sx={{ display: "flex", justifyContent: "right", alignItems: "center", width: "50px" }}>
                        <IconButton onClick={handleForwardView}>
                            <ArrowForwardIosIcon />
                        </IconButton>
                    </Box>

                }
            </Box >
        </>
    )
}
export default ReviewCustomerComponent;