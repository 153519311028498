import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography, Link, Rating } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import StarsIcon from '@mui/icons-material/Stars';
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import { modifyLoadPage, modifyLoadRefresh, modifyLoadSizePage, modifyLeftFlip, modifyRightFlip } from "../../../store/reducers/cnfSlice";
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import ActivateDetailsComponent from "../../CustomComponentModule/ActivateDetailsComponent";
import { addFavoritesLoads } from "../../../store/reducers/favoritesSlice";
import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import LoadService from "../../../services/LoadService";
import { ILoadResponseDTO } from "../../../models/LoadModels";
import { getDateAndTimeFormat, getDateFormat, getTheHotOfDate } from "../../../helpers/dateHelper";
import SCViewGradient from "../../../styledComponents/sharedComponent/SCViewGradient";
import { curency } from "../../../helpers/textHelper";
import SCViewList from "../../../styledComponents/sharedComponent/SCViewList";
import GenericCRUDService from "../../../services/GenericCRUDService";
import DownloadIcon from '@mui/icons-material/Download';
import { IWarehouseIdNameAddressResponseDto } from "../../../models/WarehouseModels";
import WarehouseIcon from '@mui/icons-material/Warehouse';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import { generateUniqueId } from "../../../helpers/generalHelper";

export default function GridLoadComponent() {

  const { t } = useTranslation();
  const { id } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { refresh, page, size, part, statuses } = useSelector((state: RootState) => state.cnfSlice.load);
  const { hints, timeFormat, dateFormat } = useSelector((state: RootState) => state.cnfSlice.interfaceState);

  const [checkState, setCheckState] = useState(true);
  const [selectionModel, setSelectionModel] = useState<Array<string>>([]);

  const [open, setOpen] = React.useState(false);

  const [rowLoads, setRowLoads] = useState<ILoadResponseDTO[]>([]);
  const [pageSizeC, setPageSizeC] = useState(size);
  const [pageC, setPageC] = useState(page);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTtalRows] = useState(0);

  const hendlerExcel = () => {
    let criteria: any[] = [];

    if (statuses.length !== 0) {
      criteria.push({
        property: "status",
        value: statuses.join(', '),
        expression: "INOR"
      });
    }

    let body = {
      criteria: criteria,
      paging: {
        page: page,
        size: size
      }
    }

    GenericCRUDService.downloadReport('load/report', body)
  }

  const hendlerFavoritesList = () => {
    const list: string[] = [];
    for (let elm of rowLoads) {
      if (selectionModel.includes(elm.uuid)) {
        list.push(elm.uuid);
      }
    }
    dispatch(addFavoritesLoads(list));
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handlerRefresh = () => {
    dispatch(modifyLoadRefresh());
  }

  const handlerPage = (newPage: number) => {
    dispatch(modifyLoadPage(newPage));
    setPageC(newPage);
  }

  const handlerPageSize = (newPageSize: number) => {
    dispatch(modifyLoadSizePage(newPageSize));
    setPageSizeC(newPageSize);
  }

  const handlerCheck = () => {
    setSelectionModel([]);
    setCheckState((v) => { return !v });
  }

  const handleClick = (cellValues: any) => {
    navigate('/load/' + cellValues.row.id);
    dispatch(modifyRightFlip(true));
  }

  const hendleShowLeft = () => {
    dispatch(modifyLeftFlip());
  }

  useEffect(() => {
    setLoading(true);
    (async () => {
      let criteria: any[] = [];

      if (statuses.length !== 0) {
        criteria.push({
          property: "status",
          value: statuses.join(', '),
          expression: "INOR"
        });
      }

      let body = {
        criteria: criteria,
        paging: {
          page: page,
          size: size
        }
      }

      const [error, response] = await LoadService.getAllLoad(body);
      if (response) {
        let rs = response.data.body;
        setTtalRows(response.data.total);
        if (rs) {
          const st: ILoadResponseDTO[] = rs.map((e: ILoadResponseDTO) => ({ ...e, id: e.uuid }))
          setRowLoads(st);
        }
      }
      if (error) { }
      setLoading(false);
    })()
  }, [statuses, part, page, size, refresh])

  useEffect(() => {
    if (id) {
      if (!selectionModel.includes(id.toString())) {
        setSelectionModel(list => [id, ...list]);
      }
    }
  }, [id, refresh])

  const columns: GridColDef[] = [
    {
      field: "1", headerName: '', width: 50, sortable: false, filterable: false, disableColumnMenu: true,
      renderCell: (cellValues: any) => {
        return (
          <>
            <div style={{ display: "flex", textAlign: "center", justifyContent: "center", padding: 0 }}>
              <ActivateDetailsComponent
                activate={() => handleClick(cellValues)}
              />
            </div>
          </>
        );
      }
    },
    {
      field: 'idno', headerName: t('idno').toLocaleUpperCase(), width: 120, headerAlign: "center",
      renderCell: (cellValues: any) => {
        return (
          <>
            <Tooltip title={cellValues.row.idno.toUpperCase()} placement={"bottom"} arrow={true}>
              <Typography variant={'body2'} fontSize={'11px'}>
                {(cellValues.row.idno as string).toUpperCase()}
              </Typography>
            </Tooltip>
          </>
        );
      }
    },
    {
      field: "customer", headerName: t('customer').toLocaleUpperCase(), sortable: true, width: 200, headerAlign: "center",
      renderCell: (cellValues: any) => {
        const row: ILoadResponseDTO = cellValues.row;
        return (
          <>
            <Tooltip title={row.customer.name.toUpperCase()} placement={"bottom"} arrow={true} sx={{ cursor: 'pointer', }}>
              <Link style={{ marginTop: '5px', fontSize: "13px" }} onClick={() => { navigate('/customer/' + row.customer.uuid) }} underline="hover" >
                {row.customer.name}
              </Link>
            </Tooltip>
          </>
        );
      }
    },
    {
      field: "HOT", headerName: 'HOT', sortable: true, width: 160, headerAlign: "center",
      renderCell: (cellValues: any) => {
        let hotDetails = cellValues.row.hotDetails;
        let itemTemplate = (hotDetails.hotItems === 1) ? "item" : "items"
        let hot = getTheHotOfDate(hotDetails.hottestItemDate)
        if (hot > 130) { hot += 10; } else { hot -= 20 }
        return (
          <>
            <div>
              {(hotDetails.hot)
                ?
                <SCViewList center alignCenter gap={10}>

                  <SCViewGradient hot={hot} minWidth={40}>
                    <Tooltip
                      placement="bottom"
                      title={'Hot items ' + hotDetails.hotItems}
                    >
                      <Typography variant={'body2'} fontSize={'11px'} sx={{ fontWeight: "bold" }}>
                        {hotDetails.hotItems}
                      </Typography>
                    </Tooltip>

                    <Typography variant={'body2'} fontSize={'11px'} sx={{ fontWeight: "bold" }}>
                      {itemTemplate.toLocaleUpperCase()}
                    </Typography>

                  </SCViewGradient>
                  <Tooltip
                    placement="bottom"
                    title={'Hottest Item date ' + getDateFormat(hotDetails.hottestItemDate, dateFormat)}
                  >
                    <Typography variant={'body2'} fontSize={'11px'} sx={{ fontWeight: "bold" }} color={'red'}>
                      {`${getDateFormat(hotDetails.hottestItemDate, dateFormat)}`}
                    </Typography>
                  </Tooltip>
                </SCViewList>

                :
                <Typography variant={'body2'} fontSize={'11px'}>
                  {'NO'}
                </Typography>
              }
            </div >
          </>
        );
      }
    },
    {
      field: 'items', headerName: 'ITEMS', width: 170, headerAlign: "center",
      renderCell: (cellValues: any) => {
        return (
          <>
            <div style={{ display: "flex", gap: "5px", justifyContent: "center", alignItems: "center" }}>
              <Tooltip title={'TOTAL ' + cellValues.row.totalNumberOfItems} arrow={true}>
                <Typography variant={'body2'} fontSize={'11px'} color={"black"} sx={{ minWidth: "25px", textAlign: "center" }}>
                  {cellValues.row.totalNumberOfItems}
                </Typography>
              </Tooltip>
              |
              <Tooltip title={'LOADED ' + cellValues.row.numberOfLoadedItems} arrow={true}>
                <Typography variant={'body2'} fontSize={'11px'} color={"#F2840F"} sx={{ minWidth: "25px", textAlign: "center" }}>
                  {cellValues.row.numberOfLoadedItems}
                </Typography>
              </Tooltip>
              |
              <Tooltip title={'DELIVERED ' + cellValues.row.numberOfDeliveredItems} arrow={true}>
                <Typography variant={'body2'} fontSize={'11px'} color={"green"} sx={{ minWidth: "25px", textAlign: "center" }}>
                  {cellValues.row.numberOfDeliveredItems}
                </Typography>
              </Tooltip>
              |
              <Tooltip title={'PAYED ' + cellValues.row.numberOfPayedItems} arrow={true}>
                <Typography variant={'body2'} fontSize={'11px'} color={"#003663"} sx={{ minWidth: "25px", textAlign: "center" }}>
                  {cellValues.row.numberOfPayedItems}
                </Typography>
              </Tooltip>
            </div>
          </>
        );
      }
    },
    {
      field: 'AMOUNT', headerName: 'AMOUNT', width: 200, headerAlign: "center",
      renderCell: (cellValues: any) => {
        return (
          <>
            <div style={{ display: "flex", gap: "5px", justifyContent: "center", alignItems: "center" }}>
              <Tooltip
                title={`TOTAL ${cellValues.row.totalAmount} ${curency}`}
                arrow={true}
              >
                <Typography variant={'body2'} fontSize={'11px'} color={"black"} sx={{ minWidth: "70px", textAlign: "center" }}>
                  {`${cellValues.row.totalAmount} ${curency}`}
                </Typography>
              </Tooltip>
              |
              <Tooltip
                title={`PAYED ${cellValues.row.payedAmount} ${curency}`}
                arrow={true}
              >
                <Typography variant={'body2'} fontSize={'11px'} color={"#003663"} sx={{ minWidth: "70px", textAlign: "center" }}>
                  {`${cellValues.row.payedAmount} ${curency}`}
                </Typography>
              </Tooltip>
            </div>
          </>
        );
      }
    },
    {
      field: 'pickup', headerName: 'PICKUP', width: 380, headerAlign: "center",
      renderCell: (cellValues: any) => {
        const row: ILoadResponseDTO = cellValues.row,
          addresses: Array<String> = row.pickupAddresses || [],
          warehouses: Array<IWarehouseIdNameAddressResponseDto> = row.pickupWarehouses || [];

        const addressesSize: number = addresses.length,
          warehousesSize: number = warehouses.length;

        const onlyOneAddress: String | null = (addressesSize === 1) ? addresses[0] : null,
          onlyOneWarehouse: IWarehouseIdNameAddressResponseDto | null = (warehousesSize === 1) ? warehouses[0] : null;

        return (
          <>
            <SCViewList>
              {onlyOneAddress && !onlyOneWarehouse &&
                <Tooltip key={generateUniqueId()} title={hints ? onlyOneAddress : ''} arrow >
                  <Typography variant={'body2'} fontSize={'10px'}>
                    <IconButton style={{ marginLeft: 10 }} color={'primary'} size={'small'}>
                      <AddLocationAltIcon fontSize="small" />
                    </IconButton>
                    {onlyOneAddress}
                  </Typography>
                </Tooltip>
              }

              {onlyOneWarehouse && !onlyOneAddress &&
                <Tooltip key={generateUniqueId()} title={hints ? `${onlyOneWarehouse.name} [ ${onlyOneWarehouse.address} ]` : ''} arrow >
                  <Typography variant={'body2'} fontSize={'10px'}>
                    <Link style={{ marginLeft: 10 }} onClick={() => { navigate('/warehouse/' + onlyOneWarehouse.uuid) }} underline="hover" >
                      <IconButton color={'primary'} size={'small'}>
                        <WarehouseIcon fontSize="small" />
                      </IconButton>
                    </Link>
                    {onlyOneWarehouse.address}
                  </Typography>
                </Tooltip>
              }

              {(warehousesSize > 1 || (onlyOneWarehouse && onlyOneAddress)) && warehouses.map(item => (
                <Tooltip key={generateUniqueId()} title={hints ? `${item.name} [ ${item.address} ]` : ''} arrow >
                  <Link style={{ marginLeft: 10 }} onClick={() => { navigate('/warehouse/' + item.uuid) }} underline="hover" >
                    <IconButton color={'primary'} size={'small'}>
                      <WarehouseIcon fontSize="small" />
                    </IconButton>
                  </Link>
                </Tooltip>
              ))}

              {(addressesSize > 1 || (onlyOneWarehouse && onlyOneAddress)) && addresses.map(item => (
                <Tooltip key={generateUniqueId()} title={hints ? item : ''} arrow >
                  <IconButton style={{ marginLeft: 10 }} color={'primary'} size={'small'}>
                    <AddLocationAltIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              ))}
            </SCViewList >
          </>
        )
      }
    },
    {
      field: 'delivery', headerName: 'DELIVERY', width: 380, headerAlign: "center",
      renderCell: (cellValues: any) => {
        const row: ILoadResponseDTO = cellValues.row,
          addresses: Array<String> = row.deliveryAddresses || [],
          warehouses: Array<IWarehouseIdNameAddressResponseDto> = row.deliveryWarehouses || [];

        const addressesSize: number = addresses.length,
          warehousesSize: number = warehouses.length;

        const onlyOneAddress: String | null = (addressesSize === 1) ? addresses[0] : null,
          onlyOneWarehouse: IWarehouseIdNameAddressResponseDto | null = (warehousesSize === 1) ? warehouses[0] : null;

        return (
          <>
            <SCViewList>
              {onlyOneAddress && !onlyOneWarehouse &&
                <Tooltip key={generateUniqueId()} title={hints ? onlyOneAddress : ''} arrow >
                  <Typography variant={'body2'} fontSize={'10px'}>
                    <IconButton style={{ marginLeft: 10 }} color={'success'} size={'small'}>
                      <AddLocationAltIcon fontSize="small" />
                    </IconButton>
                    {onlyOneAddress}
                  </Typography>
                </Tooltip>
              }

              {onlyOneWarehouse && !onlyOneAddress &&
                <Tooltip key={generateUniqueId()} title={hints ? `${onlyOneWarehouse.name} [ ${onlyOneWarehouse.address} ]` : ''} arrow >
                  <Typography variant={'body2'} fontSize={'10px'}>
                    <Link style={{ marginLeft: 10 }} onClick={() => { navigate('/warehouse/' + onlyOneWarehouse.uuid) }} underline="hover" >
                      <IconButton color={'success'} size={'small'}>
                        <WarehouseIcon fontSize="small" />
                      </IconButton>
                    </Link>
                    {onlyOneWarehouse.address}
                  </Typography>
                </Tooltip>
              }

              {(warehousesSize > 1 || (onlyOneWarehouse && onlyOneAddress)) && warehouses.map(item => (
                <Tooltip key={generateUniqueId()} title={hints ? `${item.name} [ ${item.address} ]` : ''} arrow >
                  <Link style={{ marginLeft: 10 }} onClick={() => { navigate('/warehouse/' + item.uuid) }} underline="hover" >
                    <IconButton color={'success'} size={'small'}>
                      <WarehouseIcon fontSize="small" />
                    </IconButton>
                  </Link>
                </Tooltip>
              ))}

              {(addressesSize > 1 || (onlyOneWarehouse && onlyOneAddress)) && addresses.map(item => (
                <Tooltip key={generateUniqueId()} title={hints ? item : ''} arrow >
                  <IconButton style={{ marginLeft: 10 }} color={'success'} size={'small'}>
                    <AddLocationAltIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              ))}
            </SCViewList >
          </>
        )
      }
    },
    {
      field: 'status', headerName: t('status').toLocaleUpperCase(), width: 115, headerAlign: "center",
      renderCell: (cellValues: any) => {
        return (
          <>
            <div>
              {
                <Typography variant={'body2'} fontSize={'11px'}>
                  {(cellValues.row.status as string).split('_').join(' ')}
                </Typography>
              }
            </div>
          </>
        );
      }
    },
    {
      field: 'activeLoad', headerName: t('ACTIVE LOAD'), width: 115, headerAlign: "center",
      renderCell: (cellValues: any) => {
        return (
          <>
          </>
        );
      }
    },
    {
      field: "REVIEW", headerName: t('REVIEW'), sortable: true, width: 150, align: "center", headerAlign: "center",
      renderCell: (cellValues: any) => {
        return (
          <>
            {

              < Rating
                precision={0.5}
                readOnly
                value={cellValues.row.reviewMark}
              />
            }
          </>
        );
      }
    },
    {
      field: "created", headerName: 'Created'.toUpperCase(), sortable: true, width: 180, headerAlign: "center",
      renderCell: (cellValues: any) => {
        const date = getDateAndTimeFormat(cellValues.row.createdDate, dateFormat, timeFormat),
          author = cellValues.row.createdBy,
          tooltipValue = author + ' ' + date;
        return (
          <>
            <Tooltip title={tooltipValue} placement={"bottom"}>
              <Typography variant={'body2'} fontSize={'11px'}>
                {author} <br /> {date}
              </Typography>
            </Tooltip>
          </>
        );
      }
    },
    {
      field: "updated", headerName: 'Updated'.toUpperCase(), sortable: true, width: 180, headerAlign: "center",
      renderCell: (cellValues: any) => {
        const date = getDateAndTimeFormat(cellValues.row.updatedDate, dateFormat, timeFormat),
          author = cellValues.row.updatedBy,
          tooltipValue = author + ' ' + date;
        return (
          <>
            {cellValues.row.updatedDate &&
              <Tooltip title={tooltipValue} placement={"bottom"}>
                <Typography variant={'body2'} fontSize={'11px'}>
                  {author} <br /> {date}
                </Typography>
              </Tooltip>
            }
          </>
        );
      }
    }
  ];

  const styled = {
    container: { display: "flex", gap: "20px" },
    item: { width: "40px" },
    box: { border: "1px solid silver", textAlign: "center", borderRadius: "3px" },
    icon: { fontSize: "28px", cursor: "pointer" },
    button: { border: "1px solid silver" }
  }

  return (
    <>
      <div style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingBottom: "2px",
        height: "46px"
      }}>
        <div>
          <div style={{ width: "400px", paddingLeft: "7px", display: "flex", gap: "10px" }}>
            <Tooltip
              title={(hints) ? t("Show/Hide the selection column") : ''}
              placement={"top"}
              arrow={true}
            >
              <IconButton onClick={handlerCheck} size='small'>
                <PlaylistAddCheckCircleIcon style={styled.icon} />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={(hints) ? t("Refresh") : ''}
              placement={"top"}
              arrow={true}
            >
              <IconButton onClick={handlerRefresh} size='small'>
                <ReplayCircleFilledIcon style={styled.icon} />
              </IconButton>
            </Tooltip>
            {!(rowLoads.length < 1) && <Tooltip
              title={(hints) ? t("Download XLS report for load") : ''}
              placement={"top"}
              arrow={true}
              onClick={hendlerExcel}
              key={"key"}
            >
              <IconButton>
                <DownloadIcon style={styled.icon} />
              </IconButton>
            </Tooltip>}
            {(selectionModel.length > 0) &&
              <div>
                <Tooltip
                  title={(hints) ? t("Add selected load(s) to favorites") : ''}
                  placement={"top"}
                  arrow={true}
                >
                  <IconButton
                    onClick={hendlerFavoritesList}
                  >
                    <StarsIcon style={styled.icon} color={"primary"} />
                  </IconButton>
                </Tooltip>

              </div>
            }
          </div>
        </div>
        <div>
          {(id) &&
            <Tooltip
              title={(hints) ? t("staf") : ''}
              placement={"top"}
              arrow={true}
            >

              <IconButton onClick={hendleShowLeft} >
                <MultipleStopIcon style={styled.icon} color={"primary"} />
              </IconButton>
            </Tooltip>
          }
        </div>

      </div>

      <div style={{ height: "92%" }}>
        <DataGrid
          density={'compact'}
          paginationMode="server"
          loading={loading}
          rows={rowLoads}
          columns={columns}

          rowCount={totalRows}
          page={pageC}
          pageSize={pageSizeC}

          rowsPerPageOptions={[5, 10, 15, 20, 25]}
          onPageSizeChange={handlerPageSize}
          onPageChange={handlerPage}

          checkboxSelection={checkState}
          onSelectionModelChange={(newSelectionModel: any) => {
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
        />
      </div>


      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={'lg'}

      >
        <DialogTitle>{`Pdf Report`}</DialogTitle>
        <DialogContent>
        </DialogContent>
        <DialogActions>
          <div style={{ display: "flex", justifyContent: "right", width: "100%", padding: "20px" }}>
            <Button
              startIcon={<CloseIcon />}
              color={'inherit'}
              variant={'contained'}
              size={'small'}
              onClick={handleClose}>Close</Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}