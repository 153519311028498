interface IProps {
	width: number,
	heigth: number,
	color?: string
}
const YesPhotoComponent = (props: IProps) => {
	const st0 = {
		fill: "#FFFFFF",
		stroke: "#FFFFFF",
		strokeWidth: 10,
		strokeMiterlimit: 10
	}
	const st1 = { fill: "#5DAB5B" }

	return (
		<div style={{ width: props.width, height: props.heigth }}>
			<svg version="1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" >
				<circle fill="#4CAF50" cx="24" cy="24" r="21" />
				<polygon fill="#CCFF90" points="34.6,14.6 21,28.2 15.4,22.6 12.6,25.4 21,33.8 37.4,17.4" />
			</svg>
		</div>
	)
}

export default YesPhotoComponent;