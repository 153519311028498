import { Divider, Typography, useTheme } from "@mui/material";
import './../../SearchBar.scss';
import { IResponseRoutesAutocomplete } from "../../models/RoutesModels";
import { getDateFormat } from "../../helpers/dateHelper";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

interface IProps {
  item: IResponseRoutesAutocomplete;
  hendlerActiveElement: (item: any) => void;
}

export default function ItemRoutesInSearchListComponent(props: IProps) {
  const { dateFormat } = useSelector((state: RootState) => state.cnfSlice.interfaceState);
  const { hendlerActiveElement, item } = props;
  const routes: IResponseRoutesAutocomplete = props.item;
  const theme = useTheme();

  return (
    <>
      <div
        className="dataItem"
        style={{ marginTop: "4px", marginBottom: "4px", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "start", gap: "15px", paddingLeft: "10px" }}
        onClick={() => hendlerActiveElement(item)}
      >

        <Typography
          color={theme.palette.primary.main}
          variant={"caption"}>{routes.name + ' [ ' + getDateFormat(routes.startDate, dateFormat) + ' / ' + getDateFormat(routes.endDate, dateFormat) + ' ]'}  </Typography>
      </div>
      <Divider></Divider>
    </>
  );
}