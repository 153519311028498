import { Suspense, useEffect, useState } from "react";
import { Box, Button, CircularProgress, Paper, Snackbar, Step, StepButton, StepContent, Stepper, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AppDispatch } from "../../store/store";
import { useDispatch } from "react-redux";
import { addNotification, modifyRightFlip, modifyTransportRefresh } from "../../store/reducers/cnfSlice";
import GridViewIcon from '@mui/icons-material/GridView';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { INotification } from "../../models/NotificationModels";
import { EButtonColor, ENotificationType } from "../../models/EnumGeneral";
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CancelIcon from '@mui/icons-material/Cancel';
import SecureDialogComponent from "../CustomComponentModule/SecureDialogComponent";
import CoverFaceTransportComponent from "./CoverFaceTransportComponent";
import { IAutoDetails } from "../../models/TransportModels";
import TransportService from "../../services/TransportService";
import RegistrationTransportComponent from "./RegistrationTransportComponent";
import DocumentsTransportComponent from "./DocumentsTransportComponent";
import GppBadIcon from '@mui/icons-material/GppBad';

import MediaFileTransportComponent from "./MediaFileTransportComponent";
import EditStatusTransportComponent from "./EditStatusTransportComponent";
import ViewTransportTableReportComponent from "./ViewTransportTableReportComponent";
import TooltipedIconsHelper from "../CustomComponentModule/TooltipedIconsHelper";

export default function EditTransportComponent() {
  const [activeStep, setActiveStep] = useState(0);
  const [editMode, setEditMode] = useState('edit');
  const { id } = useParams();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [textSnackbar, setTextSnackbar] = useState('');
  const [reset, setReset] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation().pathname;

  const [notificationRedirect, setNotificationRedirect] = useState<boolean>(true)
  const [searchParams, setSearchParams] = useSearchParams();
  const [type, setType] = useState<ENotificationType>(ENotificationType.ADDITIONAL);



  const [auto, setAuto] = useState<IAutoDetails | null>(null);
  const refreshAfretSave = () => { setReset(!reset); }
  const snackMsg = (text: string, type?: ENotificationType) => {
    let t: ENotificationType = type ? type : ENotificationType.ADDITIONAL;
    let n: INotification = {
      userUUID: " ",
      dt: 0,
      text: [text],
      state: false,
      type: t,
      link: location + "?" + "step=" + activeStep.toString()
    }
    console.log(type);
    dispatch(addNotification(n))
    setTextSnackbar(text);
    setType(t);
    setOpenSnackbar(true);
  }

  const handleEditMode = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string | null,
  ) => {
    if (newValue) {
      setEditMode(newValue);
    }
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const hendlerDeleteTransport = () => {

    if (auto) {
      (async () => {
        const [error, response] = await TransportService.deleteTransport(auto?.uuid);
        if (response) {
          dispatch(modifyRightFlip(false));
          navigate('/transport');
          snackMsg(`Transport ${auto.brand} ${auto.model} ${auto.year} was succesfully deleted.`)
          dispatch(modifyTransportRefresh());
        }
        if (error) { }
      })()
    }
  }

  const handleCloseSnackbar = () => { setOpenSnackbar(false); }

  const hendlerToEdit = (step: number) => {
    setActiveStep(step);
    setEditMode('edit');
  }

  useEffect(() => {
    if (id) {
      const base = 10
      let step: string | null = searchParams.get("step");;
      if (step) {
        setNotificationRedirect(true);
        setActiveStep(parseInt(step, base));
      } else {
        setNotificationRedirect(false);
      }
      searchParams.delete("step");
      setSearchParams(searchParams);

      (async () => {
        const [error, response] = await TransportService.getDetailsAuto(id?.toString());
        if (response) {
          setAuto(response.data.body);
          dispatch(modifyRightFlip(true));
        }
        if (error) { }
      })()
    }
  }, [id, reset, dispatch])

  useEffect(() => {
    if (!notificationRedirect) {
      if (auto?.color === "N/A") {
        setActiveStep(Number(0))
      } else {
        if (!auto?.metadata.completed && auto?.metadata.step) {
          setActiveStep(Number(auto?.metadata.step) - 1)
        }
      }
    }
  }, [notificationRedirect, auto])

  return (
    <>
      <div>
        <Paper>
          {<CoverFaceTransportComponent auto={auto} />}
        </Paper>
        <div style={{ display: "flex" }}>
        </div>
      </div>

      {editMode === 'edit' &&
        <div>
          <div style={{ marginTop: "20px", marginLeft: "35px" }}>
            <Stepper nonLinear activeStep={activeStep} orientation="vertical">
              <Step completed={false}>
                <StepButton color="inherit" onClick={handleStep(0)}>
                  {'REGISTRATION'.toUpperCase()}
                </StepButton>
                <StepContent>
                  <div>
                    <Suspense fallback={<CircularProgress />}>
                      <Paper sx={{ marginTop: "10px" }}>
                        <Box sx={{ padding: 3, paddingRight: 0, marginBottom: "10px", marginTop: "1", display: "flex" }}>
                          <RegistrationTransportComponent
                            handleStep={(e) => { setActiveStep(e) }}
                            snackMsg={snackMsg}
                            refreshAfretSave={refreshAfretSave}
                            auto={auto}
                          />
                        </Box>
                      </Paper>
                    </Suspense>
                  </div>
                </StepContent>
              </Step>

              {auto?.color !== "N/A" &&
                <Step completed={false}>
                  <StepButton color="inherit" onClick={handleStep(1)}>
                    {'PHOTO GALLERY'.toUpperCase()}
                  </StepButton>
                  <StepContent>
                    <div>
                      <Suspense fallback={<CircularProgress />}>
                        <Paper sx={{ marginTop: "10px" }}>
                          <Box sx={{ padding: 3, paddingRight: 0, marginBottom: "10px", marginTop: "1", display: "flex" }}>
                            <div>
                              <MediaFileTransportComponent
                                handleStep={(e) => { setActiveStep(e) }}
                                snackMsg={snackMsg}
                                refreshAfretSave={refreshAfretSave}
                                auto={auto}
                              />
                            </div>
                          </Box>
                        </Paper>
                      </Suspense>
                    </div>
                  </StepContent>
                </Step>}

              {auto?.color !== "N/A" &&
                <Step completed={false}>
                  <StepButton color="inherit" onClick={handleStep(2)}>
                    {'DOCUMENTS'.toUpperCase()}
                  </StepButton>
                  <StepContent>

                    <Paper sx={{ marginTop: "10px", paddingBottom: "30px" }}>
                      <Box sx={{ padding: 3, paddingRight: 0, marginBottom: "10px", marginTop: "1", display: "flex" }}>
                        <div>
                          <DocumentsTransportComponent
                            handleStep={(e) => { setActiveStep(e) }}
                            auto={auto}
                            snackMsg={snackMsg}
                            refreshAfretSave={refreshAfretSave}
                          />
                        </div>
                      </Box>
                    </Paper>
                  </StepContent>
                </Step>}

              {auto?.color !== "N/A" && auto?.metadata.completed &&
                <Step completed={false}>
                  <StepButton color="inherit" onClick={handleStep(3)}>
                    {'STATUS'.toUpperCase()}
                  </StepButton>
                  <StepContent>
                    <Paper sx={{ marginTop: "10px" }}>
                      <Box sx={{ padding: 3, display: "flex" }}>
                        <div>
                          <EditStatusTransportComponent
                            refreshAfretSave={refreshAfretSave}
                            snackMsg={snackMsg}
                            auto={auto ? auto : null} />
                        </div>
                      </Box>
                    </Paper>
                  </StepContent>
                </Step>}
            </Stepper>
          </div>
        </div>}

      {editMode === 'view' && auto && <ViewTransportTableReportComponent toStep={hendlerToEdit} auto={auto} />}

      <Paper sx={{ padding: "15px", marginTop: "20px", display: "flex", gap: "10px", justifyContent: "space-between" }} >
        <Box sx={{ display: "flex", gap: "10px" }}>
          <ToggleButtonGroup
            size={'small'}
            value={editMode}
            exclusive
            onChange={handleEditMode}
            aria-label=""
          >
            <ToggleButton value="edit" aria-label="">
              <AutoAwesomeIcon fontSize={'small'} color={editMode === "edit" ? 'warning' : 'inherit'} />
            </ToggleButton>
            <ToggleButton value="view" aria-label="">
              <GridViewIcon fontSize={'small'} color={editMode === "view" ? 'warning' : 'inherit'} />
            </ToggleButton >
          </ToggleButtonGroup>
        </Box>
        <Box sx={{ display: "flex", gap: "10px" }}>
          {!auto?.readonly &&
            <SecureDialogComponent
              answerYES={hendlerDeleteTransport}
              text={''}
              icon={<DeleteForeverIcon fontSize='medium' sx={{ marginRight: "5px", marginLeft: "5px" }} />}
              color={EButtonColor.error}
              iconButton={false}
            />
          }
        </Box>
      </Paper>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message=""
        anchorOrigin={{ vertical: "top", horizontal: 'center' }}
        action={<Button color="secondary" size="small"> lorem ipsum dolorem </Button>}
      >

        <Paper style={{ display: "flex", padding: "10px", minWidth: "350px", gap: "20px", border: "1px solid silver" }}>
          <div style={{ display: "flex", alignItems: "center", paddingLeft: "15px" }}>
            {type === ENotificationType.ADDITIONAL &&
              <TooltipedIconsHelper
                text={"Success notification"}
                icon={<GppMaybeIcon color={'success'} />}
                tooltipPlacement="top"
              />
            }
            {type === ENotificationType.ERROR &&
              <TooltipedIconsHelper
                text={"Error notification"}
                icon={<GppBadIcon color={'error'} />}
                tooltipPlacement="top"
              />
            }
          </div>
          <div style={{
            display: "flex",
            alignItems: "center",
            minWidth: "250px",
            justifyContent: "center"
          }}>
            <div>
              <Typography variant='subtitle2' >
                {textSnackbar}
              </Typography>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button onClick={handleCloseSnackbar}><CancelIcon /></Button>
          </div>
        </Paper>
      </Snackbar>
    </>
  );
}