import { Checkbox, TextField } from "@mui/material";
import {style  } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import { dateToYMD } from "../../../helpers/dateHelper";
import { IResponseAutocomplete } from "../../../models/IUser";
import ReportListUserInfo from "../ReportListUserInfo";
import ReportHeader from "../User/ReportHeader";
import ReportUser from "../User/ReportUser";
import styles from "./ReportUserComponent.module.scss"


interface IProps {
    user: IResponseAutocomplete[]
}

interface Question {
    id: string;
    title: string;
    cheked: boolean;
    value: string;
    isText: boolean;
}
const ReportUserComponent = (props:IProps) => {

    const {user} = props

    const componentRef = useRef<HTMLDivElement>(null);

    const [state, setState] = useState<Question[]>([]);



    const handlerQuestions = (value: Question) => {
        if (state) {

            const index = state.findIndex(element => element.id === value.id)

            let copy: Question[] = [...state];
            let question: Question = copy[index];
            copy[index] = { ...question, cheked: value.cheked, value: value.value }



            setState(copy);

        }


    }
    

    useEffect(() => {
        let a = [];
        let n1: Question = {
            isText: false,
            id: "v1",
            title: "Header", cheked: true, value: ""
        }
        a.push(n1);
        let n2: Question = {
            isText: true,
            id: "v2",
            title: "Title", cheked: true, value: "Title"
        }
        a.push(n2);
        let n3: Question = {
            isText: true,
            id: "v3",
            title: "Text", cheked: true, value: "Text"
        }
        a.push(n3);
        let n4: Question = {
            isText: false,
            id: "v4",
            title: "Sign", cheked: true, value: ""
        }
        a.push(n4);
        let n5: Question = {
            isText: false,
            id: "v5",
            title: "Data", cheked: true, value: dateToYMD(Date.now())
        }
        a.push(n5);
        let n6: Question = {
            isText: false,
            id: "v6",
            title: "Footer", cheked: true, value: ""
        }
        a.push(n6);
        setState(a);

    }, [])





    return (
        <>
            <div className={styles.mainPage}>
                {/* {<pre>
                    {JSON.stringify(state, undefined, 2)}
                </pre>} */}



                <div
                    className={styles.mainQuestionare}
                >
                    {state.map((e, i: number) =>
                        <ReportQuestionsBand
                            e={e}
                            key={i}
                            getQuestion={handlerQuestions}
                        />

                    )
                    }


                </div>
                <div className={styles.mainExamplePDF} >
                    <div
                        className={styles.printButtonBackgroung} >
                        <ReactToPrint
                            trigger={() => <button className={styles.printButton}  >Print </button>}
                            content={() => componentRef.current}
                        />
                    </div>


                    {state.length > 0 && state[0].cheked && <ReportHeader />}


                    {state.length > 0 && state[1].cheked &&
                        <div className={styles.exampleTitle} >
                            {state.length > 0 && state[1].value}
                        </div>
                    }


                    {state.length > 0 && state[2].cheked &&
                        <div className={styles.exampleText} >
                            {state.length > 0 && state[2].value}
                        </div>
                    }


                    <ReportListUserInfo fontSize={16} user={user}/>


                    <div className={styles.exampleSignAndData}>

                        {state.length > 0 && state[3].cheked &&
                            <div  className={styles.exampleSign} >
                                Sign:____________

                            </div>}


                        {state.length > 0 && state[4].cheked &&
                            <div className={styles.exampleData}>
                                {state.length > 0 && state[4].value}

                            </div>}
                    </div>


                    <div style={{ width: "0px", height: "0px", padding: 0, margin: 0, overflow: "hidden" }}>

                        <div ref={componentRef}>
                        {/* <ReportUser/> */}
                            {state.length > 0 && state[0].cheked && <ReportHeader />}

                            {state.length > 0 && state[1].cheked &&
                                <div className={styles.reportTitle} >
                                    {state.length > 0 && state[1].value}
                                </div>
                            }

                            {state.length > 0 && state[2].cheked &&
                                <div className={styles.reportText} >
                                    {state.length > 0 && state[2].value}
                                </div>
                            }

                            <ReportListUserInfo fontSize={26} user={user}/>

                            <div className={styles.reportSignAndData} >
                                {state.length > 0 && state[3].cheked &&
                                    <div className={styles.reportSign} >
                                        Sign:____________

                                    </div>}


                                {state.length > 0 && state[4].cheked &&
                                    <div className={styles.reportData} >
                                        {state.length > 0 && state[4].value}

                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </>)
}

export default ReportUserComponent;

interface IPropsReportQuestionsBand {
    e: Question,
    getQuestion: (value: Question) => void,
}

const ReportQuestionsBand = (props: IPropsReportQuestionsBand) => {
    const { e, getQuestion } = props
    const [stateQuestion, setStateQuestion] = useState<Question>(e)

    const handleChangeCheck = (event: React.ChangeEvent<HTMLInputElement>) => {

        if (stateQuestion)
            setStateQuestion((st) => {
                let stQuestion: Question = { ...st, cheked: event.target.checked };

                return stQuestion;
            }
            );


    };

    const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {

        if (stateQuestion)
            setStateQuestion((st) => {
                let stQuestion: Question = { ...st, value: event.target.value };

                return stQuestion;
            }
            );


    };
    const handlerStartDate = (event: any) => {
        let value = event.target.value;
        setStateQuestion((st) => { return { ...st, value: value } })
    }
    useEffect(() => {
        getQuestion(stateQuestion);
    }, [stateQuestion])



    return (
        <>
            <div
                className={styles.questionare }>
                <div className={styles.rowsQuestionare }  >
                    <Checkbox checked={stateQuestion.cheked} onChange={handleChangeCheck} ></Checkbox><div style={{ marginTop: "10px" }}>{e.title}</div>
                </div>
                {e.isText && <TextField
                    value={stateQuestion.value}
                    onChange={handleChangeValue}
                    fullWidth
                    multiline
                    rows={stateQuestion.id === "v2" ? 1 : 3}
                    inputProps={{ sx: { fontSize: "14px", padding: "1px", lineHeight: "1.5" } }}
                />}
                {e.id === "v5"&& <TextField
                                size="small"
                                label={"data"}
                                type="date"
                                onChange={handlerStartDate}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{ sx: { fontSize: "11px", widht: "145px" } }}
                            /> }

            </div>

        </>

    )
}
