import React from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { TextField, Box, Button, Divider, ListItem, ListItemButton, ListItemText, Typography, Tab, Tabs, Tooltip, InputAdornment } from '@mui/material';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import LoadService from '../../services/LoadService';
import { modifyLoadTypesRefresh, setLastError } from '../../store/reducers/cnfSlice';
import SecureDialogComponent from '../CustomComponentModule/SecureDialogComponent';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { EButtonColor } from '../../models/EnumGeneral';
import ControlStateInCRUDComponent from '../CustomComponentModule/ControlStateInCRUDComponent';
import { useDebounce } from '../../hooks/useDebounce';
import { ELoadSettingsType } from '../../models/LoadModels';
import ReplyIcon from '@mui/icons-material/Reply';
import { isNumber } from '../../helpers/textHelper';



interface ItemOptionType {
  uuid: string,
  name?: string,
  caption?: string,
  inputValue?: string;
  itemUuid?: string;
  weight?: number;
  length?: number;
  height?: number;
  width?: number;
  readonly?: boolean
}

interface IProps {
  snackMess: (s: string) => void;
  getType: (s: ELoadSettingsType) => void
}

export default function RedactTypesItemsLoadComponent(props: IProps) {
  const { getType, snackMess } = props;
  const { measureSize, measureMass } = useSelector((state: RootState) => state.cnfSlice.globalPreferenceState);

  const { refreshTypes } = useSelector((state: RootState) => state.cnfSlice.load);
  const { hints } = useSelector((state: RootState) => state.cnfSlice.interfaceState);

  const [type, setType] = React.useState<ELoadSettingsType>(ELoadSettingsType.Fragility_Type);
  const [listItem, setListItem] = React.useState<ItemOptionType[]>([])
  const [value, setValue] = React.useState<ItemOptionType | null>(null);

  const [currentValue, setCurrentValue] = React.useState<ItemOptionType | null>(null);
  const [state, setState] = React.useState(0);

  const dispatch = useDispatch<AppDispatch>();
  const filter = createFilterOptions<ItemOptionType>();

  const hendlerDeleteItem = () => {
    if (currentValue) {
      (async () => {
        const [error, response] = (type === ELoadSettingsType.Fragility_Type)
          ? await LoadService.deleteFragilityType(currentValue?.itemUuid ? currentValue?.itemUuid : '')
          : await LoadService.deletePalletType(currentValue?.itemUuid ? currentValue?.itemUuid : '')
        if (response) {
          setListItem((st) => st.filter(e => e.uuid !== currentValue?.uuid));
          setCurrentValue(null);
          setValue(null);
          setState(0);
          snackMess(((type === ELoadSettingsType.Fragility_Type) ? 'Fragility type' : 'Pallet type') + ' with name ' + currentValue.name + ' was successfully' + ' deleted.')

        }
        if (error) {
          dispatch(setLastError(error))
        }
      })()
    }
  }

  const handleEditItem = () => { setState(1); }

  const handleClickList = (item: ItemOptionType) => {
    setValue({ ...item });
  }
  const hendlerNewItem = () => {
    setValue({ uuid: '' })
    setState(1);
  }

  const handleBackToList = () => {
    setState(0);
    setValue(null);
    setCurrentValue(null);
  }

  function renderRow(props: ListChildComponentProps) {
    const { index } = props;
    return (
      <div>
        <ListItem divider style={{ height: "45px", marginLeft: "5px", maxWidth: "250px" }} key={index} component="div" disablePadding>
          <ListItemButton
            selected={(currentValue?.itemUuid === listItem[index].uuid)}
            onClick={() => { handleClickList(listItem[index]) }}
            style={{ fontSize: "10px" }}
          >
            <ListItemText primary={<Typography sx={{ fontSize: "12px" }}>{listItem[index].name}</Typography>} />
          </ListItemButton>
        </ListItem>
      </div>
    );
  }

  React.useEffect(() => {
    (async () => {
      const [error, response] = (type === ELoadSettingsType.Fragility_Type)
        ? await LoadService.getAllFragilityType()
        : await LoadService.getAllPallete();
      if (response) {
        let arr = response.data.body as ItemOptionType[];
        let a: any = arr.map((e: ItemOptionType) => { return { ...e, itemUuid: e.uuid, uuid: e.name } })
        setListItem(a);
        setValue(null);
        setCurrentValue(null);
      }
      if (error) { dispatch(setLastError(error)) }
    })()
  }, [refreshTypes, type])


  React.useEffect(() => {
    if (value === null) {
      setState(0);
      setCurrentValue(null)
    }

    if (value !== null && !value.itemUuid) {
      setState(1);
      setCurrentValue({
        name: value.uuid,
        uuid: '#'
      })
    }

    if (value !== null && value.itemUuid) {
      setState(2);
      setCurrentValue(value)
    }
  }, [value])
  const handleChange = (event: React.SyntheticEvent, newValue: ELoadSettingsType) => {
    let b: ELoadSettingsType = newValue
    getType(b);
    setType(b);
  };

  return (
    <>
      {state !== 1 &&
        <Tabs
          value={type}
          onChange={handleChange}
          aria-label="wrapped label tabs example"
        >
          <Tab value={ELoadSettingsType.Fragility_Type} label={ELoadSettingsType.Fragility_Type.split('_').join(' ')} />
          <Tab value={ELoadSettingsType.Pallet_Type} label={ELoadSettingsType.Pallet_Type.split('_').join(' ')} />
        </Tabs>
      }
      <div style={{ display: "flex", flexDirection: "row", marginTop: "5px", marginBottom: "10px", maxWidth: "550px" }}>
        {state !== 1 &&
          <Autocomplete
            value={value}
            onChange={(event, newValue) => {
              if (typeof newValue === "string") {
                setValue({ uuid: newValue });
              } else if (newValue && newValue.inputValue) {
                setValue({ uuid: newValue.inputValue });
              } else {
                setValue(newValue);
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              const { inputValue } = params;
              const isExisting = options.some((option) => inputValue === option.uuid);
              if (inputValue !== "" && !isExisting) {
                setValue(null);
                filtered.push({ inputValue, uuid: `Add "${inputValue}"` });
              }
              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={listItem}
            getOptionLabel={(option) => {
              if (typeof option === "string") { return option; }
              if (option.inputValue) { return option.inputValue; }
              return option.uuid;
            }}
            renderOption={(props, option) =>
              <li {...props}>
                <Typography sx={{ minWidth: "220px", fontSize: "12px", fontWeight: "bold" }} >{option.uuid}</Typography>
                {(option.caption) &&
                  <Tooltip
                    title={option.caption}
                    placement='right'
                  >
                    <Typography sx={{ minWidth: "220px", fontSize: "12px", fontWeight: "bold" }} >{(option.caption.length > 50) ? option.caption.substring(0, 50) + ' ...' : option.caption}</Typography>
                  </Tooltip>
                }
                {isNumber(option.width) && <Typography sx={{ minWidth: "220px", fontSize: "12px", fontWeight: "bold" }} >{`${option.width} ${measureSize.toLocaleUpperCase()} x ${option.height} ${measureSize.toLocaleUpperCase()} x ${option.length} ${measureSize.toLocaleUpperCase()}`}</Typography>}
              </li>}
            sx={{ width: 550, marginTop: "10px" }}
            freeSolo
            renderInput={(params) => (
              <TextField {...params} label={(type === ELoadSettingsType.Fragility_Type) ? ("Fragility Types").toUpperCase() : ("Pallet Types").toUpperCase()} />
            )}
          />}
        {state === 1 && <Button variant="contained" size={"small"} sx={{ width: "150px", marginTop: "10px" }} onClick={handleBackToList} color={'warning'} >
          <ReplyIcon fontSize='small' sx={{ marginRight: "15px" }} /> <Typography variant="subtitle2"> Back To List</Typography>
        </Button>}
        {!value &&
          <Button
            variant={'contained'}
            size={'medium'} sx={{ marginLeft: "10px", marginTop: "10px" }}
            onClick={hendlerNewItem}
          >
            <PlaylistAddIcon fontSize={'medium'} />
          </Button>
        }

      </div>
      {(state !== 1) && <Divider />}


      <Box
        sx={{ width: '550px', height: 400, paddingTop: "10px", display: "flex", flexDirection: "row", marginBottom: "10px" }}
      >
        <div>
          {(state !== 1) &&
            <FixedSizeList
              height={400}
              width={260}
              itemSize={listItem.length - 1}
              itemCount={listItem.length}
              overscanCount={listItem.length}
            >
              {renderRow}
            </FixedSizeList>}
        </div>

        <div style={{
          width: state === 1 ? "100%" : "270px",
          height: "400px",
          border: "1px dashed silver",
          marginLeft: "5px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "3px"
        }}>

          {state === 0 && <Typography sx={{ color: "silver" }}> No selected Item </Typography>}
          {state === 1 && (type === ELoadSettingsType.Fragility_Type) && <div> {currentValue && <EditItemComponent snckMsg={snackMess} currentValue={currentValue} />}</div>}
          {state === 1 && (type === ELoadSettingsType.Pallet_Type) && <div> {currentValue && <EditPalletComponent snckMsg={snackMess} currentValue={currentValue} />}</div>}
          {state === 2 && <div style={{ display: "flex", flexDirection: "column", gap: "20px", justifyContent: "flex-start" }}>

            <TextField
              label="Name"
              disabled
              value={currentValue?.name}
              inputProps={{ sx: { fontSize: "12px" } }}
              sx={{ width: "240px" }}
            />
            {(type === ELoadSettingsType.Fragility_Type) &&
              <TextField
                multiline
                disabled
                rows={4}
                label="Caption"
                value={currentValue?.caption}
                inputProps={{ sx: { fontSize: "12px" } }}
                sx={{ width: "240px" }
                }
              />
            }
            {(type === ELoadSettingsType.Pallet_Type) &&
              <TextField
                disabled
                label="Weight"
                value={currentValue?.weight}
                inputProps={{ sx: { fontSize: "12px" } }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <div style={{ fontSize: "12px" }}>{measureMass.toLocaleUpperCase()}</div>
                    </InputAdornment>
                  ),
                }}
                sx={{ width: "240px" }}
              />
            }
            {(type === ELoadSettingsType.Pallet_Type) && <div style={{ display: "flex", gap: "5px" }}>
              <TextField
                disabled
                label="Width"
                value={currentValue?.width}
                inputProps={{ sx: { fontSize: "12px" } }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <div style={{ fontSize: "12px" }}>{measureSize.toLocaleUpperCase()}</div>
                    </InputAdornment>
                  ),
                }}
                sx={{ width: "76px" }}
              />
              <TextField
                disabled
                label="Height"
                value={currentValue?.height}
                inputProps={{ sx: { fontSize: "12px" } }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <div style={{ fontSize: "12px" }}>{measureSize.toLocaleUpperCase()}</div>
                    </InputAdornment>
                  ),
                }}
                sx={{ width: "76px" }}
              />
              <TextField
                disabled
                label="Length"
                value={currentValue?.length}
                inputProps={{ sx: { fontSize: "12px" } }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <div style={{ fontSize: "12px" }}>{measureSize.toLocaleUpperCase()}</div>
                    </InputAdornment>
                  ),
                }}
                sx={{ width: "77px" }}
              />
            </div>

            }

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Tooltip
                title={(hints) ? `Open the ${type.split('_').join(' ').toLocaleLowerCase()} for editing` : ""}
                arrow={true}
                placement={"top"}
              >
                <Button size={'small'} variant={'contained'} sx={{ marginRight: "10px" }} onClick={handleEditItem} ><AutoAwesomeIcon /></Button>
              </Tooltip>
              {!value?.readonly &&
                <SecureDialogComponent
                  answerYES={hendlerDeleteItem}
                  text={''}
                  icon={<DeleteForeverIcon fontSize='small' sx={{ marginRight: "5px", marginLeft: "5px" }} />}
                  color={EButtonColor.error}
                  tooltip
                  placement
                  tooltipText={`Remove ${type.split('_').join(' ').toLocaleLowerCase()}`}
                  iconButton={false}

                />
              }
            </div>

          </div>
          }
        </div>
      </Box>
    </>
  );
}

/** aditional component */
interface IEditItemComponent {
  currentValue: ItemOptionType;
  snckMsg: (s: string) => void;
}
const EditPalletComponent = (props: IEditItemComponent) => {
  const { currentValue } = props;

  const { measureSize, measureMass } = useSelector((state: RootState) => state.cnfSlice.globalPreferenceState);


  const [error, setError] = React.useState(0);
  const [save, setSave] = React.useState(false);
  const [textErrors, setTextErrors] = React.useState<string[]>([]);
  const [snepShot, setSnepShot] = React.useState<string>('');
  const [value, setValue] = React.useState<ItemOptionType>({ uuid: '', name: '', caption: '', readonly: false });
  const [isValidName, setIsValidName] = React.useState<boolean>(true);
  const debouncedName = useDebounce(value.name ? value.name : '', 300);
  const dispatch = useDispatch<AppDispatch>();


  const handlerReset = () => {
    let value = { ...currentValue }
    if (!currentValue.caption) { value.caption = '' }
    setValue({ ...value });
  }

  const handlerSaveItem = () => { setSave(true); }

  const handlerChangeName = (e: any) => {
    let value: string = (e.target.value as string);
    setValue(st => ({ ...st, name: value }))
  }

  const handlerChangeWidth = (e: any) => {
    let value: string = (e.target.value as string);
    setValue(st => ({ ...st, width: value && parseInt(value, 10) ? parseInt(value, 10) : 0 }))
  }
  const handlerChangeHeight = (e: any) => {
    let value: string = (e.target.value as string);
    setValue(st => ({ ...st, height: value && parseInt(value, 10) ? parseInt(value, 10) : 0 }))
  }
  const handlerChangeLength = (e: any) => {
    let value: string = (e.target.value as string);
    setValue(st => ({ ...st, length: value && parseInt(value, 10) ? parseInt(value, 10) : 0 }))
  }

  const handlerChangeWeight = (e: any) => {
    let value: string = (e.target.value as string);
    setValue(st => ({ ...st, weight: value && parseInt(value, 10) ? parseInt(value, 10) : 0 }))
  }

  React.useEffect(() => {
    if (currentValue) {
      setValue({ ...currentValue });
      setSnepShot(JSON.stringify(currentValue))
    }
  }, [currentValue])


  React.useEffect(() => {
    if (save) {
      (async () => {

        let body: any = {}
        let add: boolean = value.uuid === '#';


        body = (add)
          ? {
            name: value.name,
            width: value.width,
            length: value.length,
            height: value.height,
            weight: value.weight
          }
          : {
            uuid: value.itemUuid,
            name: value.name,
            width: value.width,
            length: value.length,
            height: value.height,
            weight: value.weight
          }

        const [error, response] = add
          ? await LoadService.addPalletType(body)
          : await LoadService.updatePalletType(body);

        if (response) {
          props.snckMsg(`Pallet type with name ${body.name} was successfully ${(add) ? ' created.' : ' updated.'}`)
          dispatch(modifyLoadTypesRefresh())
        }
        if (error) { dispatch(setLastError(error)) }
      })()
      setSave(false);
    }
  }, [save])
  React.useEffect(() => {
    if (debouncedName && value.name) {
      if (value.name.length >= 3 && value.name.toLowerCase().trim() !== currentValue.name?.toLowerCase().trim()) {
        (async () => {
          const [error, response] = await LoadService.isAvailablePalletName(value.name ? value.name : '');
          if (response) {
            const value: boolean = response.data.body as boolean;
            setIsValidName(value);
          }
          if (error) {
            dispatch(setLastError(error))
          }
        })()
      } else {
        setIsValidName(true);
      }
    }
  }, [debouncedName])

  React.useEffect(() => {


    let err: number = 0;
    let txErr: string[] = []

    if (value.name) {
      if (value.name.length < 1) {
        err = -1;
        txErr = ['* The name is required and length need by than 1 caracters']
      }
      if (value.name.length > 100) {
        err = -1;
        txErr = ['* The name max length is 100 symbols']
      }
      if (!isValidName) {
        err = -1;
        txErr = ['* This name is used in other item']
      }
    }
    if (!isNumber(value.height)) {
      err = -1;
      txErr = ['* The height is required']
    }
    if (!isNumber(value.length)) {
      err = -1;
      txErr = ['* The length is required']
    }
    if (!isNumber(value.width)) {
      err = -1;
      txErr = ['* The width is required']
    }
    if (!isNumber(value.weight)) {
      err = -1;
      txErr = ['* The weight is required']
    }
    setTextErrors(txErr);
    if (err === -1) { setError(-1); }
    if (JSON.stringify(value) !== snepShot && err !== -1) { setError(1) }
    if (JSON.stringify(value) === snepShot && err !== -1) { setError(0); }

  }, [value, isValidName])


  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "20px" }}>
      <div style={{ display: "flex", gap: "30px" }}>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "20px" }}>

          <TextField
            autoFocus
            autoComplete={'off'}
            error={!isValidName}
            value={value.name}
            label="Name"
            onChange={handlerChangeName}
            style={{ width: "340px" }}
            inputProps={{ sx: { fontSize: "13px" } }}
            InputLabelProps={{ sx: { fontSize: "17px" } }}
            helperText={!isValidName ? '* This name is used in other item' : ''}
          />

          <TextField
            autoFocus
            autoComplete={'off'}
            error={!value.weight || value.weight === 0}
            value={value.weight}
            label="Weight"
            onChange={handlerChangeWeight}
            style={{ width: "340px" }}
            inputProps={{ sx: { fontSize: "13px" } }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <div style={{ fontSize: "12px" }}>{measureMass.toLocaleUpperCase()}</div>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{ sx: { fontSize: "17px" } }}
          />

          <div style={{ display: "flex", gap: "20px" }}>

            <TextField
              autoFocus
              autoComplete={'off'}
              error={!value.width || value.width === 0}
              value={value.width}
              label="Width"
              onChange={handlerChangeWidth}
              style={{ width: "100px" }}
              inputProps={{ sx: { fontSize: "13px" } }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <div style={{ fontSize: "12px" }}>{measureSize.toLocaleUpperCase()}</div>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{ sx: { fontSize: "17px" } }}
            />

            <TextField
              autoFocus
              autoComplete={'off'}
              error={!value.height || value.height === 0}
              value={value.height}
              label="Height"
              onChange={handlerChangeHeight}
              style={{ width: "100px" }}
              inputProps={{ sx: { fontSize: "13px" } }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <div style={{ fontSize: "12px" }}>{measureSize.toLocaleUpperCase()}</div>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{ sx: { fontSize: "17px" } }}
            />

            <TextField
              autoFocus
              autoComplete={'off'}
              error={!value.length || value.length === 0}
              value={value.length}
              label="Length"
              onChange={handlerChangeLength}
              style={{ width: "100px" }}
              inputProps={{ sx: { fontSize: "13px" } }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <div style={{ fontSize: "12px" }}>{measureSize.toLocaleUpperCase()}</div>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{ sx: { fontSize: "17px" } }}
            />

          </div>


        </div>
        {error !== 0 &&
          <div style={{ display: "flex", minWidth: "53px" }} >
            <ControlStateInCRUDComponent
              error={error}
              textErrors={textErrors}
              handlerReset={handlerReset}
              handlerSave={handlerSaveItem}
            />
          </div>}
      </div>



    </div>

  )
}


const EditItemComponent = (props: IEditItemComponent) => {
  const { currentValue, snckMsg } = props;

  const [error, setError] = React.useState(0);
  const [save, setSave] = React.useState(false);
  const [textErrors, setTextErrors] = React.useState<string[]>([]);
  const [snepShot, setSnepShot] = React.useState<string>('');
  const [value, setValue] = React.useState<ItemOptionType>({ uuid: '', name: '', caption: '', readonly: false });
  const [isValidName, setIsValidName] = React.useState<boolean>(true);
  const debouncedName = useDebounce(value.name ? value.name : '', 300);
  const dispatch = useDispatch<AppDispatch>();

  const handlerReset = () => {
    let value = { ...currentValue }
    if (!currentValue.caption) { value.caption = '' }
    setValue({ ...value });
  }

  const handlerSaveItem = () => { setSave(true); }

  const handlerChangeName = (e: any) => {
    let value: string = (e.target.value as string);
    setValue(st => ({ ...st, name: value }))
  }

  const handlerChangeCaption = (e: any) => {
    let value: string = (e.target.value as string);
    setValue(st => ({ ...st, caption: value }))
  }

  React.useEffect(() => {
    if (currentValue) {
      setValue({ ...currentValue });
      setSnepShot(JSON.stringify(currentValue))
    }
  }, [currentValue])

  React.useEffect(() => {
    if (save) {
      (async () => {

        let body: any = {}
        let add: boolean = value.uuid === '#';
        body = (add)
          ? {
            name: value.name,
            caption: value.caption
          }
          : {
            uuid: value.itemUuid,
            name: value.name,
            caption: value.caption
          }

        const [error, response] = add
          ? await LoadService.addFragilityType(body)
          : await LoadService.updateFragilityType(body);

        if (response) {
          dispatch(modifyLoadTypesRefresh())
          props.snckMsg(`Fragility type with name ${body.name} was successfully ${(add) ? ' created.' : ' updated.'}`)

        }

        if (error) { dispatch(setLastError(error)) }

      })()
    }
    setSave(false);
  }, [save])


  React.useEffect(() => {

    if (debouncedName && value.name) {
      if (value.name.length >= 6 && value.name.toLowerCase().trim() !== currentValue.name?.toLowerCase().trim()) {
        (async () => {
          const [error, response] = await LoadService.isAviableFragilityType(value.name ? value.name : '');
          if (response) {
            const value: boolean = response.data.body as boolean;
            setIsValidName(value);
          }
          if (error) {
            dispatch(setLastError(error))
          }
        })()
      } else {
        setIsValidName(true);
      }
    }
  }, [debouncedName])

  React.useEffect(() => {
    if (value.name) {

      let err: number = 0;
      let txErr: string[] = []

      if (value.name.length < 1) {
        err = -1;
        txErr = ['* The name is required']
      }
      if (value.name.length > 100) {
        err = -1;
        txErr = ['* The name max length is 100 symbols']
      }
      if (value.caption) {

        if (value.caption.length > 250) {
          err = -1;
          txErr = ['* The caption max length is 250 symbols']
        }
      } else {
        err = -1;
        txErr = ['* The caption is required']
      }

      if (!isValidName) {
        err = -1;
        txErr = ['* This name is used in other item']
      }

      setTextErrors(txErr);

      if (err === -1) { setError(-1); }
      if (JSON.stringify(value) !== snepShot && err !== -1) { setError(1) }
      if (JSON.stringify(value) === snepShot && err !== -1) { setError(0); }

    } else {
      setError(-1);
      setTextErrors(['* The name is required'])
    }
  }, [value, isValidName])

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "20px" }}>
      <div style={{ display: "flex", gap: "30px" }}>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "20px" }}>

          <TextField
            autoFocus
            autoComplete={'off'}
            error={!isValidName}
            value={value.name}
            label="Name"
            onChange={handlerChangeName}
            style={{ width: "340px" }}
            inputProps={{ sx: { fontSize: "13px" } }}
            InputLabelProps={{ sx: { fontSize: "17px" } }}
            helperText={!isValidName ? '* This name is used in other item' : ''}
          />

          <TextField
            multiline
            rows={4}
            label="Caption"
            value={value.caption}
            onChange={handlerChangeCaption}
            inputProps={{ sx: { fontSize: "13px" } }}
            sx={{ width: "340px" }
            }
          />

        </div>
        {error !== 0 &&
          <div style={{ display: "flex", minWidth: "53px" }} >
            <ControlStateInCRUDComponent
              error={error}
              textErrors={textErrors}
              handlerReset={handlerReset}
              handlerSave={handlerSaveItem}
            />
          </div>}
      </div>



    </div>

  )
}