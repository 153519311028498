import { Suspense, useEffect, useState } from "react";
import { Box, Button, CircularProgress, Paper, Snackbar, Step, StepButton, StepContent, Stepper, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { modifyCustomerRefresh, modifyRightFlip } from "../../store/reducers/cnfSlice";
import GridViewIcon from '@mui/icons-material/GridView';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { EButtonColor } from "../../models/EnumGeneral";
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import CancelIcon from '@mui/icons-material/Cancel';
import CoverFaceCustomerComponent from "./CoverFaceCustomerComponent";
import { ICustomerDetails } from "../../models/CustomerModels";
import RegistrationCustomerComponent from "./RegistrationCustomerComponent";
import CustomerService from "../../services/CustomerService";
import ContactsCustomerComponent from "./ContactsCustomerComponent";
import DocumentsCustomerComponent from "./DocumentsCustomerComponent";
import ViewTableCustomerReport from "./ViewTableCustomerReport";
import CustomerLoadsComponent from "./CustomerLoadsComponent";
import ReviewCustomerComponent from "./ReviewCustomerComponent";
import SecureDialogComponent from "../CustomComponentModule/SecureDialogComponent";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import StatusCustomerComponent from "./StatusCustomerComponent";

interface IProps {
  snackMsg: (text: string, loc: string) => void;
}

export default function EditCustomerComponent(props: IProps) {
  const [activeStep, setActiveStep] = useState(0);
  const [editMode, setEditMode] = useState('edit');
  const { id } = useParams();
  const { rightFlip } = useSelector((state: RootState) => state.cnfSlice.interfaceState);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [textSnackbar, setTextSnackbar] = useState('');
  const [reset, setReset] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation().pathname;

  const [notificationRedirect, setNotificationRedirect] = useState<boolean>(true)
  const [searchParams, setSearchParams] = useSearchParams();
  const [maxStep, setMaxStep] = useState<number>(0);

  const [customer, setCustomer] = useState<ICustomerDetails | null>(null);
  const refreshAfretSave = () => { setReset(!reset); }

  const snackMsg = (text: string) => {
    props.snackMsg(text, location + "?" + "step=" + activeStep.toString());
  }

  const handleEditMode = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string | null,
  ) => {
    if (newValue) {
      setEditMode(newValue);
    }
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const hendlerDeleteCustomer = () => {
    if (customer) {
      (async () => {
        const [error, response] = await CustomerService.deleteCustomer(customer?.uuid);
        if (response) {
          dispatch(modifyRightFlip(false));
          navigate('/customer');
          snackMsg('Customer ' + customer.name + ' was successfully removed.')
          dispatch(modifyCustomerRefresh());
        }
        if (error) { }
      })()
    }
  }

  const handleCloseSnackbar = () => { setOpenSnackbar(false); }

  const hendlerToEdit = (step: number) => {
    setActiveStep(step);
    setEditMode('edit');
  }

  useEffect(() => {
    if (id) {
      const base = 10
      let step: string | null = searchParams.get("step");;
      if (step) {
        setNotificationRedirect(true);
        setActiveStep(parseInt(step, base));
      } else {
        setNotificationRedirect(false);
      }
      searchParams.delete("step");
      setSearchParams(searchParams);

      (async () => {
        const [error, response] = await CustomerService.getDetailsCustomer(id);
        if (response) {
          setCustomer(response.data.body);
        }
        if (error) { }
      })()
    }
  }, [id, reset, dispatch])

  useEffect(() => {
    if (!notificationRedirect) {
      if (!customer?.metadata.completed) {
        if (customer?.metadata.step) {
          setActiveStep(Number(customer?.metadata.step))
          setMaxStep(Number(customer?.metadata.step))
        } else {
          setActiveStep(0);
        }
      }
    }
  }, [notificationRedirect, customer])

  return (
    <>
      <div>
        <Paper>
          {<CoverFaceCustomerComponent customer={customer} />}
        </Paper>
        <div style={{ display: "flex" }}>
        </div>
      </div>

      {editMode === 'edit' &&
        <div>
          <div style={{ marginTop: "20px", marginLeft: "35px" }}>
            <Stepper nonLinear activeStep={activeStep} orientation="vertical">
              <Step completed={false}>
                <StepButton color="inherit" onClick={handleStep(0)}>
                  {'Registration'.toUpperCase()}
                </StepButton>
                <StepContent>
                  <div>
                    <Suspense fallback={<CircularProgress />}>
                      <Paper sx={{ marginTop: "10px" }}>
                        <Box sx={{ padding: 3, paddingRight: 0, marginBottom: "10px", marginTop: "1", display: "flex" }}>

                          <RegistrationCustomerComponent
                            handleStep={(e) => { setActiveStep(e) }}
                            snackMsg={snackMsg}
                            refreshAfretSave={refreshAfretSave}
                            customer={customer}
                          />
                        </Box>
                      </Paper>
                    </Suspense>
                  </div>
                </StepContent>
              </Step>
              {
                <Step completed={false}>
                  <StepButton color="inherit" onClick={handleStep(1)} disabled={(customer?.metadata.completed) ? false : (maxStep >= 1) ? false : true}>
                    {'Contacts'.toUpperCase()}
                  </StepButton>
                  <StepContent>
                    <div>
                      <Suspense fallback={<CircularProgress />}>
                        <Paper sx={{ marginTop: "10px" }}>
                          <Box sx={{ padding: 3, paddingRight: 0, marginBottom: "10px", marginTop: "1", display: "flex" }}>
                            <div>
                              <ContactsCustomerComponent
                                handleStep={(e) => { setActiveStep(e) }}
                                snackMsg={snackMsg}
                                refreshAfretSave={refreshAfretSave}
                                customer={customer}
                              />
                            </div>
                          </Box>
                        </Paper>
                      </Suspense>
                    </div>
                  </StepContent>
                </Step>}
              {
                <Step completed={false}>
                  <StepButton color="inherit" onClick={handleStep(2)}>
                    {'Documents '.toUpperCase()}
                  </StepButton>
                  <StepContent>

                    <Paper sx={{ marginTop: "10px", paddingBottom: "30px" }}>
                      <Box sx={{ padding: 3, paddingRight: 0, marginBottom: "10px", marginTop: "1", display: "flex" }}>
                        <div>
                          <DocumentsCustomerComponent
                            handleStep={(e) => { setActiveStep(e) }}
                            customer={customer}
                            snackMsg={snackMsg}
                            refreshAfretSave={refreshAfretSave}
                          />

                        </div>
                      </Box>
                    </Paper>

                  </StepContent>
                </Step>}
              <Step>
                <StepButton color="inherit" onClick={handleStep(3)}>
                  {'Review'.toUpperCase()}
                </StepButton>
                <StepContent>
                  <Paper sx={{ marginTop: "10px", paddingBottom: "30px" }}>
                    <Box sx={{ padding: 3, paddingRight: 0, marginBottom: "10px", marginTop: "1", display: "flex" }}>
                      <div>
                        <ReviewCustomerComponent customer={customer ? customer : null} />
                      </div>
                    </Box>
                  </Paper>
                </StepContent>
              </Step>

              <Step>
                <StepButton color="inherit" onClick={handleStep(4)}>
                  {'Loads'.toUpperCase()}
                </StepButton>
                <StepContent>
                  <Paper sx={{ marginTop: "10px", paddingBottom: "30px" }}>
                    <Box sx={{ padding: 3, paddingRight: 0, marginBottom: "10px", marginTop: "1", display: "flex" }}>
                      <div>
                        <CustomerLoadsComponent customer={customer ? customer : null} />
                      </div>
                    </Box>
                  </Paper>
                </StepContent>
              </Step>

              <Step>
                <StepButton color="inherit" onClick={handleStep(5)} disabled={!customer?.metadata.completed}>
                  {'Status and reputation'.toUpperCase()}
                </StepButton>
                <StepContent>
                  <Paper sx={{ marginTop: "10px", paddingBottom: "30px" }}>
                    <Box sx={{ padding: 3, paddingRight: 0, marginBottom: "10px", marginTop: "1", display: "flex" }}>
                      <div>
                        <StatusCustomerComponent
                          refreshAfretSave={refreshAfretSave}
                          snackMsg={snackMsg}
                          customer={customer ? customer : null} />
                      </div>
                    </Box>
                  </Paper>
                </StepContent>
              </Step>
            </Stepper>
          </div>
        </div>}

      {editMode === 'view' && customer && <ViewTableCustomerReport toStep={hendlerToEdit} customer={customer ? customer : null} />}

      <Paper sx={{ padding: "15px", marginTop: "20px", display: "flex", gap: "10px", justifyContent: "space-between" }} >
        <Box sx={{ display: "flex", gap: "10px" }}>
          <ToggleButtonGroup
            size={'small'}
            value={editMode}
            exclusive
            onChange={handleEditMode}
            aria-label=""
          >
            <ToggleButton value="edit" aria-label="">
              <AutoAwesomeIcon fontSize={'small'} color={editMode === "edit" ? 'warning' : 'inherit'} />
            </ToggleButton>
            <ToggleButton value="view" aria-label="">
              <GridViewIcon fontSize={'small'} color={editMode === "view" ? 'warning' : 'inherit'} />
            </ToggleButton >
          </ToggleButtonGroup>
        </Box>
        <Box sx={{ display: "flex", gap: "10px" }}>
          {!customer?.readonly &&
            <SecureDialogComponent
              answerYES={hendlerDeleteCustomer}
              text={''}
              icon={<DeleteForeverIcon fontSize='medium' sx={{ marginRight: "5px", marginLeft: "5px" }} />}
              color={EButtonColor.error}
              iconButton={false}
            />
          }
        </Box>
      </Paper>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message=""
        anchorOrigin={{ vertical: "top", horizontal: 'center' }}
        action={<Button color="secondary" size="small"> lorem ipsum dolorem </Button>}
      >
        <Paper style={{ display: "flex", padding: "10px", minWidth: "350px", gap: "20px", border: "1px solid silver" }}>
          <div style={{ display: "flex", alignItems: "center", paddingLeft: "15px" }}>
            <GppMaybeIcon color={'success'} fontSize={'large'} />
          </div>
          <div style={{
            display: "flex",
            alignItems: "center",
            minWidth: "250px",
            justifyContent: "center"
          }}>
            <div>
              <Typography variant='subtitle2' >
                {textSnackbar}
              </Typography>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button onClick={handleCloseSnackbar}><CancelIcon /></Button>
          </div>
        </Paper>
      </Snackbar>
    </>
  );
}