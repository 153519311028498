import { Button, Paper, Snackbar, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import CancelIcon from '@mui/icons-material/Cancel';
import RoutesButtonBarComponent from '../components/RoutesModule/RoutesButtonBarComponent';
import EditRouteComponent from '../components/RoutesModule/RouteEditComponent';
import GridRouteComponent from '../components/RoutesModule/GridRouteComponent';
import RoutesTabsFiltersComponent from '../components/RoutesModule/RoutesTabsFiltersComponent';
import RouteServices from '../services/RouteServices';
import { IRouteStatus } from '../models/RoutesModels';
import SCViewList from '../styledComponents/sharedComponent/SCViewList';
import ListStateComponent from '../components/RoutesModule/ListStateComponent';
import HotLoadComponent from '../components/RoutesModule/HotLoadComponent';
import { useParams } from 'react-router-dom';

export default function RoutePage() {
    const { refresh } = useSelector((state: RootState) => state.routesSlice);
    const { id } = useParams();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [textSnackbar] = useState('');
    const [countPar, setCountPar] = useState<IRouteStatus>(
        { active: 0, inactive: 0, incomplete: 0 }
    );

    const isValidId = (id: any) => {
        if (!id) { return false }
        return true;
    }

    const handleCloseSnackbar = () => { setOpenSnackbar(false); }

    useEffect(() => {
        (async () => {
            const [, data] = await RouteServices.getStatisticsStatus();
            if (data) {
                setCountPar(data)
            }
        })()
    }, [refresh])

    return (
        <div style={{ width: "100%", height: "calc(100vh - 140px)" }} >
            <SCViewList alignStretch gap={20} height='100%'>

                <Paper style={{ flex: 1, maxWidth: "240px", padding: "5px", paddingTop: "0px", }}>
                    <SCViewList column height='90%'>

                        <SCViewList column>
                            <RoutesButtonBarComponent />
                            <RoutesTabsFiltersComponent countPar={countPar} />
                            <ListStateComponent />
                        </SCViewList>
                        <SCViewList column flexEnd height='100%'>
                            <HotLoadComponent />

                        </SCViewList>
                    </SCViewList>
                </Paper>
                {!isValidId(id) &&

                    <Paper sx={{ flex: 1, padding: 2, width: "auto", paddingTop: "2px", paddingBottom: "2px", paddingRight: "10px", }}>
                        <GridRouteComponent />
                    </Paper>

                }
                {isValidId(id) &&
                    <div style={{ flex: 1, width: "auto", minWidth: "750px" }}>
                        <EditRouteComponent />
                    </div>
                }

                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={5000}
                    onClose={handleCloseSnackbar}
                    message=""
                    anchorOrigin={{ vertical: "top", horizontal: 'center' }}
                    action={<Button color="secondary" size="small"> lorem ipsum dolorem </Button>}
                >
                    <Paper style={{ display: "flex", padding: "10px", minWidth: "350px", gap: "20px", border: "1px solid silver" }}>
                        <div style={{ display: "flex", alignItems: "center", paddingLeft: "15px" }}>
                            <GppMaybeIcon color={'success'} fontSize={'large'} />
                        </div>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            minWidth: "250px",
                            justifyContent: "center"
                        }}>
                            <div>
                                <Typography variant='subtitle2' >
                                    {textSnackbar}
                                </Typography>

                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <Button onClick={handleCloseSnackbar}><CancelIcon /></Button>
                        </div>
                    </Paper>
                </Snackbar>
            </SCViewList>
        </div>
    );
}