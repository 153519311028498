import { Avatar } from "@mui/material";
import { randomPhoto } from "../../../../helpers/generalHelper";
import { IUserInfoResponseDto } from "../../../../models/IUser";
import styles from "./ReportUserInfo.module.scss"
interface IProps {
    user: IUserInfoResponseDto | null;
}
const ReportUserInfo = (props: IProps) => {
    const { user } = props;

    return (
        <>
            <div className={styles.mainReportUserInfo}>

                <div className={styles.mainAvatar}>
                    <div className={styles.avatarDiv} >
                        <Avatar className={styles.avatar} src={randomPhoto()} />

                    </div>

                </div>

                <div className={styles.info}>
                    <div>{user?.firstName}</div>
                    <div>{user?.lastName}</div>
                    <div>{user?.email}</div>
                </div>
            </div>
        </>
    )
}
export default ReportUserInfo;