import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import RouteIcon from '@mui/icons-material/Route';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import { useTranslation } from 'react-i18next';
import { Divider } from '@mui/material';
import { AuxMapComponent } from '../MapModule/AuxMapComponent';
import { useLocation } from 'react-router';
import FavoritesComponent from './FavoritesComponent';
import HistoryActionsComponent from './HistoryActionsComponent';

export default function HelperComponent() {
    const { t } = useTranslation();
    const [page, setPage] = React.useState('route');

    const location = useLocation();
    const locationPath = location.pathname;
    const [activ, setActive] = React.useState('');

    const handleChange = (event: any, newValue: any) => {
        setPage(newValue);
    };

    const hideCurrentPaths: Array<string> = ['/notification', '/settings/personalProfile',
        '/settings/globalProfile', '/settings/helpAndPrivacy', '/settings/quotes', '/'];

    let items = [{
        id: '1',
        disabled: false,
        label: 'HISTORY',
        value: 'history',
        icon: <SettingsBackupRestoreIcon />
    }, {
        id: '2',
        disabled: true,
        label: 'SHORTKEY',
        value: 'shortkey',
        icon: <KeyboardIcon />
    }, {
        id: '3',
        disabled: true,
        label: 'ROUTES',
        value: 'routes',
        icon: <RouteIcon />
    }];

    if (!hideCurrentPaths.includes(locationPath)) {
        items.unshift({
            id: '4',
            disabled: false,
            label: activ,
            value: 'current',
            icon: <WhereToVoteIcon />
        });
    }

    let activePage = hideCurrentPaths.includes(locationPath)
        ? 'history' : 'current';

    let activeBtn = hideCurrentPaths.includes(locationPath)
        ? 'history' : locationPath.split('/')[1];

    React.useEffect(() => {
        setActive(activeBtn);
        setPage(activePage);
    }, [location, activePage, activeBtn]);

    return (
        <div>
            <BottomNavigation value={page} onChange={handleChange}>
                {
                    items.map((item) => (
                        <BottomNavigationAction
                            key={item.id}
                            disabled={item.disabled}
                            label={t(item.label).toUpperCase()}
                            value={item.value}
                            icon={item.icon}
                        />
                    ))
                }
            </BottomNavigation>

            <Divider />
            {page === 'history' && <HistoryActionsComponent />}
            {page === 'routes' && <AuxMapComponent />}
            {page === 'shortkey' && <div></div>}
            {page === 'current' && <FavoritesComponent type={activ} />}
        </div>
    );
}