import { Box, Divider, List, ListItemButton, ListItemText, TextField } from "@mui/material"
import { MapBoxComponent } from "./MapBoxComponent"
import { useEffect, useState } from 'react'
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from "use-places-autocomplete";
import { ICurenAdress } from "../../models/MapModels";
import { AddressResponseDto } from "../../models/AddressModels";

const extractAddress = (place: any) => {

    const address = {
        country: "",
        state: "",
        city: "",
        zip: "",
        route: "",
        streetNumber: "",
        vilage: "",
        county: ""
    }

    if (!Array.isArray(place?.address_components)) {
        return address;
    }

    place.address_components.forEach((component: any) => {

        const types = component.types;
        const value = component.long_name;

        if (types.includes("locality")) {
            address.city = value;
        }

        if (types.includes("administrative_area_level_1")) {
            address.county = value;
        }

        if (types.includes("administrative_area_level_2")) {
            address.state = value;
        }

        if (types.includes("postal_code")) {
            address.zip = value;
        }

        if (types.includes("country")) {
            address.country = value;
        }

        if (types.includes('street_number')) {
            address.streetNumber = value;
        }

        if (types.includes('route')) {
            address.route = value;
        }

        if (types.includes('vilage')) {
            address.vilage = value;
        }
    });

    return address;
}

interface IPropsAuto {
    current: AddressResponseDto | null,
    getEdit?: (b: boolean) => void,
    getPlace: (p: AddressResponseDto) => void
}

const AddressComponent = (props: IPropsAuto) => {
    const [edit, setEdit] = useState(false);
    const [obj, setObj] = useState<AddressResponseDto>();
    const [isValidZip, setIsValidZip] = useState<boolean>(true);

    const hendlerEdit = (b: boolean) => { 
        setEdit(b); 
        if (props.getEdit) {
            props.getEdit(b);
        }
    }
    const hendlerObj = (ob: AddressResponseDto) => {
        let res = { ...ob }
        setIsValidZip(res.zipcode !== '')
        setObj(res);
        props.getPlace(res);
    }
    const handleZipCode = (event: any) => {
        setObj((ob) => {
            if (ob) {
                props.getPlace({ ...ob, zipcode: event.target.value });
                return { ...ob, zipcode: event.target.value }
            }
        })
    }
    const handleApt = (event: any) => {
        if (obj) {
            let txt = event.target.value.trim();
            let s = obj.street.split('**')[0];
            let f = obj.fullAddress.split('**')[0];
            s = (txt !== '') ? s + ' **' + txt : s;
            f = (txt !== '') ? f + ' **' + txt : f;

            setObj((ob) => {
                if (ob) {
                    props.getPlace({ ...ob, street: s });
                    return { ...ob, street: s, location: { ...ob.location, additional: txt }, fullAddress: f }
                }
            })
        }
    }

    useEffect(() => {
        if (obj) {
            setPoints([{
                lat: obj?.latitude,
                lng: obj.longitude
            }])
        }
    }, [obj]);

    useEffect(() => {
        if (props.current) {
            let add = (props.current.street.split('**')[1]);
            const v = {
                ...props.current,
                street: props.current.street.split('**')[0],
                location: { ...props.current.location, additional: add }
            }
            setObj(v)
        }
    }, [props.current])


    const [points, setPoints] = useState([{
        lat: 45.07628287127715,
        lng: -93.24481908541335
    }])

    return (
        <>
            <div style={{ width: "100%", marginTop: "10px", marginBottom: "10px" }}>
                <Search
                    edit={edit}
                    setEdit={hendlerEdit}
                    setObj={hendlerObj}
                />
            </div>
            {(!edit) &&
                <div>
                    <Divider></Divider>
                    <div style={{ marginTop: "10px", marginBottom: "10px", display: "flex" }}>
                        {obj &&
                            <Box sx={{ width: "250px", display: "flex", flexDirection: "column", gap: "15px" }}>

                                <TextField

                                    disabled
                                    multiline
                                    label={'City'}
                                    value={obj.location.city || ''}
                                    inputProps={{ sx: { width: "200px", fontSize: "13px" } }}
                                >
                                </TextField>

                                <TextField
                                    disabled
                                    multiline
                                    label={'Country'}
                                    value={obj.location.country || ''}
                                    inputProps={{ sx: { width: "200px", fontSize: "13px", } }}
                                >
                                </TextField>

                                <TextField
                                    disabled
                                    multiline
                                    label={'State'}
                                    value={obj.location.state || ''}
                                    inputProps={{ sx: { width: "200px", fontSize: "13px", } }}
                                >
                                </TextField>
                                <TextField
                                    disabled
                                    multiline
                                    label={'Street'}
                                    value={obj.street || ''}
                                    inputProps={{ sx: { width: "200px", fontSize: "13px", } }}
                                >
                                </TextField>
                                <div style={{ display: "flex", gap: "10px" }}>
                                    <TextField
                                        disabled={obj.uuid.trim() !== ''}
                                        label={'**'}
                                        value={obj.location.additional || ''}
                                        onChange={handleApt}
                                        inputProps={{ sx: { width: "80px", fontSize: "13px", } }}
                                    >
                                    </TextField>
                                    <TextField
                                        disabled={(obj.uuid.trim() !== '' && obj.zipcode.trim() !== '') || isValidZip}
                                        error={obj.zipcode.length < 4}
                                        label={'ZIP'}
                                        onChange={handleZipCode}
                                        value={obj.zipcode || ''}
                                        helperText={(obj.zipcode === '') ? '* required' : ""}
                                        inputProps={{ sx: { width: "80px", fontSize: "13px", } }}
                                    >
                                    </TextField>
                                </div>
                            </Box>
                        }
                        <Box sx={{ width: "100%", height: "340px", marginLeft: "10px", }}>
                            <MapBoxComponent points={points}></MapBoxComponent>
                        </Box>
                    </div>
                    <Divider></Divider>
                </div>}
        </>
    )
}

export default AddressComponent;

interface IProps {
    edit: boolean,
    setEdit: (b: boolean) => void,
    setObj: (ob: AddressResponseDto) => void
}

function Search(props: IProps) {

    const { edit, setEdit, setObj } = props;

    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete();

    const [newPlace, setNewPlace] = useState(false);

    useEffect(() => {
        if (newPlace) {
            let address = value;
            if (address) {
                setValue(address, false);
                clearSuggestions();
                const fetchData = async () => {
                    try {
                        const results = await getGeocode({ address });
                        let adr: ICurenAdress = extractAddress(results[0]);
                        const { lat, lng } = await getLatLng(results[0]);
                        let ob: AddressResponseDto = {
                            uuid: '',
                            location: {
                                country: adr.country,
                                state: adr.state,
                                county: adr.county,
                                city: adr.city,
                                village: adr.vilage,
                                additional: ''

                            },
                            street: adr.route + ' ' + adr.streetNumber,
                            zipcode: adr.zip,
                            latitude: lat,
                            longitude: lng,
                            fullAddress: adr.country + ', ' + adr.city + ', ' + adr.route + ', ' + adr.streetNumber,
                        }
                        setObj(ob)



                    } catch (error) { }
                }
                fetchData();
            }
            setEdit(false)
            setNewPlace(false)
        }
    }, [newPlace])

    const handleInput = (e: any) => {
        setValue(e.target.value);
        setEdit(true)
    };

    return (
        <div style={{ display: "flex" }}>
            <div style={{ width: "100%" }}>
                <TextField
                    autoComplete={'off'}
                    onChange={handleInput}
                    value={value || ''}
                    disabled={!ready}
                    label="Google Place Service ..."
                    style={{ width: "100%", fontSize: "14px" }}
                />
                {edit &&
                    <div style={{ minHeight: "340px" }}>
                        <List
                            sx={{ width: '100%', bgcolor: 'background.paper' }}
                        >

                            {data.map(({ description }, inx) =>
                                <ListItemButton key={inx} onClick={() => { setValue(description); setNewPlace(true); }}>
                                    <ListItemText primary={description} />
                                    <Divider></Divider>
                                </ListItemButton>)
                            }
                        </List>
                    </div>
                }
            </div>
        </div>
    );
}