
import React, { useEffect, useState } from "react";

import { MapComponent } from "./MapComponent";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

export const WrapMap = () => {

    const location = useLocation().pathname;
    const [hMap, setHMap] = useState("0px")

    useEffect(() => {
        if (location === '/') {
            setHMap("100%");
        } else {
            setHMap("0px");
        }
    }, [location])

    const { jsMapIsLoaded } = useSelector((state: RootState) => state.mapSlice);
    return (
        <>
            <div style={{ width: "100%", height: `${hMap}` }}>
                {jsMapIsLoaded ? <MapComponent></MapComponent> : <div>Loading...</div>}
            </div>
        </>
    )
}